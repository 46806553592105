import { getSemdTypesStringList, SEMD_TYPE, SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import { SemdArea } from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdArea'
import { ICatalog } from '@/_declarations/ICatalog'
import { Semd119 } from '@/vue_apps/Semds/entities/Semd119/Semd119'
import { Semd230 } from '@/vue_apps/Semds/entities/Semd230/Semd230'
import { Semd224 } from '@/vue_apps/Semds/entities/Semd224/Semd224'
import { extractSemdType } from '@/vue_apps/Semds/const/extractSemdType'
import { MSemdApi } from '@/vue_apps/Semds/entities/_SemdBase/MSemdApi'
import { TObject } from '@/_declarations/TObject'

export class SemdsUtils {
  private static config = {
    [SEMD_TYPE_ENUM[SEMD_TYPE.CONSULTATION]]: {
      title: t('semds.semd119.consultationSemdTitle'),
      Component: 'Semd119Form',
      Semd: Semd119,
    },
    [SEMD_TYPE_ENUM[SEMD_TYPE.ELMK_MEDICAL_ASSESSMENT]]: {
      title: t('semds.semd230.title'),
      Component: 'Semd230Form',
      Semd: Semd230,
    },
    [SEMD_TYPE_ENUM[SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL]]: {
      title: t('semds.semd224.title'),
      Component: 'Semd224Form',
      Semd: Semd224,
    },
  }

  /* METHODS */

  static getSemdTypesList () {
    return getSemdTypesStringList()
  }

  static extractSemdType (semdType: number | string): number {
    return extractSemdType(semdType)
  }

  static getTitle (semdType: number | string): string {
    return this.config[this.extractSemdType(semdType)]?.title || ''
  }

  static getComponent (semdType: number | string): string {
    return this.config[this.extractSemdType(semdType)]?.Component || ''
  }

  static getSemd (payload: { id: number; semdType: number }): MSemdApi<TObject<any>, TObject<any>> {
    return new this.config[this.extractSemdType(payload.semdType)].Semd(payload)
  }

  static validate (semd: MSemdApi<TObject<any>, TObject<any>>): string[] {
    return this.getDocumentDetailsInvalids(
      semd.semdsDocumentDetailsAttributes.data,
      semd.requiredEntities,
      semd.requiredAreas
    )
  }

  private static getDocumentDetailsInvalids (
    data: Record<string, ICatalog | string | boolean> = {} as Record<string, ICatalog<number>>,
    requiredValues: string[] = [],
    requiredAreas: string[] = []
  ) {
    const invalidValues = requiredValues
      .filter((requiredValue) => !(
        data[requiredValue] && data[requiredValue] !== true
      ))

    const invalidAreas = requiredAreas
      .filter((requiredArea) =>
        (data[requiredArea] && data[requiredArea] === SemdArea.getWrapper()) ||
        !data[requiredArea]
      )

    return invalidValues.concat(invalidAreas)
  }
}
