import { CHECKUP_KINDS } from '@/vue_apps/CheckupsModule/store/checkupsKinds'
import formatter from '@/lib/formatters/formatter'
import { PersonalCheckupModel } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupModel'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { WORK_PROFILE_NSI_CODE } from '@/vue_apps/CheckupsModule/store/vaccination/vaccinationConst'
import {
  PersonalCheckupCheckupEntriesApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupCheckupEntries/PersonalCheckupCheckupEntriesApi'
import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'

export class PersonalCheckupLogic extends PersonalCheckupModel {
  lastPersonalCheckup = null

  constructor (props) {
    super(props)

    this.checkupEntries = new PersonalCheckupCheckupEntriesApi(props)

    if (props.id) { this.initDocumentsFilters() }

    this._positionProfileFactorHandler()
  }

  get isPrior () {
    return this.kind === CHECKUP_KINDS.PRIOR
  }

  get isPeriodic () {
    return this.kind === CHECKUP_KINDS.PERIODIC
  }

  get canCalculateEntries () {
    return [
      this.client.birthdate,
      this.client.sex,
      this.factors.length,
    ].every(Boolean)
  }

  /**
   * @param {{ department: string, position: string, nsiProfession: ICatalog }}clientData
   * @private
   */
  _clientTrigger (clientData) {
    if (clientData.department && this.isPrior) {
      this.setValue('department', clientData.department)
    }

    if (clientData.nsiProfession) {
      this.setValue('nsiProfession', clientData.nsiProfession)

      return
    }

    if (clientData.position) {
      this.setValue('position', clientData.position)
    }
  }

  _factorsTrigger (value) {
    this._positionProfileFactorHandler(value)

    if (
      !this.id ||
      value?.length ||
      this.checkupEntries.entries.length
    ) { return }

    this.checkupEntries.destroyCheckupEntries(['factors'])
  }

  _positionProfileFactorHandler (factors = this.factors) {
    this.hasPositionProfileFactor = factors
      .findIndex((factor) => +factor.nsiCode === WORK_PROFILE_NSI_CODE) !== -1

    if (!this.hasPositionProfileFactor) {
      this.positionProfile = null
    }
  }

  setValue (key, value) {
    super.setValue(key, value)

    if (key === 'client' && typeof value !== 'string') { this._clientTrigger(value) }
    if (key === 'factors') { this._factorsTrigger(value) }
  }

  /**
   * @param {{
   *   id: number,
   *   surname: string,
   *   name: string,
   *   secondName: string,
   *   phone: string,
   *   sex: Object,
   *   birthdate: string,
   *   age: number
   *   position: string,
   *   nsiProfession: ICatalog
   *   medicalRecordId: number
   *   snils: string
   *   fullName: string
   * }} client
   */
  setClient (client) {
    this.lastPersonalCheckup = null

    this.client = {
      id: client.id,
      medicalRecordId: client.medicalRecordId,
      fullName: client.fullName || formatter.formatValue(client, ['client:fullName']),
      birthdate: client.birthdate,
      phone: client.phone,
      sex: client.sex,
      age: vueFilters.age(client.birthdate),
      position: client.position,
      nsiProfession: client.nsiProfession,
      snils: client.snils,
    }

    this.setValue('nsiProfession', client.nsiProfession)

    if (this.nsiProfession) { return }

    this.setValue('position', (!this.position && client.position) || this.position || '')
  }

  fillByLastCheckup () {
    if (!this.lastPersonalCheckup) { return }

    if (this.isPrior) {
      this.company = this.lastPersonalCheckup.company
      this.department = this.lastPersonalCheckup.department
    }

    if (!this.nsiProfession && !this.position) {
      this.nsiProfession = this.lastPersonalCheckup.nsiProfession
    }

    if (!this.nsiProfession && !this.position) {
      this.position = this.lastPersonalCheckup.position
    }

    this.factors = this.lastPersonalCheckup.factors

    this.positionProfile = this.lastPersonalCheckup.positionProfile

    this._positionProfileFactorHandler()
  }

  initDocumentsFilters () {
    this.documents.setFilters({
      ownerId: this.id,
      ownerType: DOCUMENT_OWNER_TYPES.PERSONAL_CHECKUP,
    })
  }

  getDiagnosesPersonalArr () {
    return this.diagnosesPersonal
      .nestedItems
      .getArr()
  }
}
