<template>
  <div class="variables-editor">
    <div
      v-if="editorTitle"
      class="variables-editor__title"
    >
      <div class="variables-editor__editorTitle">
        {{ editorTitle }}
      </div>
      <div
        v-if="editorTitleClarification"
        class="variables-editor__titleClarification"
      >
        {{ editorTitleClarification }}
      </div>
    </div>

    <slot name="templateSelector" />

    <m-textarea
      class="flex-grow-1"
      :value="editorValueToClient"
      :autosize="{ minRows: 5, maxRows: 32}"
      :required="required"
      :validator-name="validatorName"
      @registerValidator="$registerValidator($event)"
      @input="templateInput"
      @click.native="saveTemplateCaretPosition"
      @keyup.native="saveTemplateCaretPosition"
    />
    <div
      v-if="showButtons"
      class="variables-editor__buttons flex-grow-1"
    >
      <variables-modal
        :variables-list="variablesList"
        @insertVariable="insertVariable"
      />

      <m-button
        :text="t('view')"
        type="dark"
        icon="look"
        size="mini"
        @click="showPreview"
      />

      <templates-catalogs-modal
        :editor-value="editorValue"
        :template-type="templateType"
        @insertTemplate="insertTemplate"
      />
    </div>

    <m-textarea
      v-if="showButtons"
      :value="previewValue"
      :autosize="{ minRows: 5, maxRows: 32}"
      class="flex-grow-1"
      disabled
    />

    <div class="approximate-characters-and-messages-number mb-25">
      <div class="approximate-messages-number mb-3">
        {{ approximateMessagesNumber }}
      </div>
      <div class="approximate-characters-number">
        {{ approximateCharactersNumber }}
      </div>
    </div>
  </div>
</template>

<script>
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import VariablesModal from '@/vue_present/VariablesEditor/VariablesModal.vue'
import { insertToString } from '@/_medods_standart_library/msl'
import TemplatesCatalogsModal from '@/vue_present/VariablesEditor/TemplatesCatalogsModal.vue'
import TemplateConverter from '@/vue_present/VariablesEditor/consts/templateConverter'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { smsCount } from '@/vue_present/VariablesEditor/consts/variables.js'
import {
  smsTemplateVariablesLabels,
  smsTemplateVariablesValues,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/variables'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'

export default {
  name: 'VariablesEditor',
  components: {
    TemplatesCatalogsModal,
    VariablesModal,
    MButton,
    MTextarea,
  },

  mixins: [ValidationChildMixin],

  props: {
    editorTitle: PropsTypes.String(),
    editorTitleClarification: PropsTypes.String(),
    editorValue: PropsTypes.String(),
    labelsMap: PropsTypes.Object(smsTemplateVariablesLabels),
    valuesMap: PropsTypes.Object(smsTemplateVariablesValues),
    variablesList: PropsTypes.Array(),

    showButtons: { type: Boolean, default: true },

    previewRequest: PropsTypes.Function(),

    templateType: PropsTypes.String(),

    required: PropsTypes.Custom([Boolean, Function]),
    validatorName: PropsTypes.String(),
  },

  data () {
    return {
      templateCaretPosition: 0,
      previewValue: '',
    }
  },

  computed: {
    editorValueToClient () {
      const converter = new TemplateConverter(
        this.editorValue,
        this.labelsMap,
        this.valuesMap
      )

      return converter.toClient()
    },

    approximateCharactersNumber () {
      return `${t('whatsapp.approximateCharactersNumber')} ${this.previewValue?.length || 0}`
    },

    approximateMessagesNumber () {
      return `${t('whatsapp.approximateMessagesNumber')} ${smsCount(this.previewValue || '')}`
    },
  },

  methods: {
    saveTemplateCaretPosition ($event) {
      this.templateCaretPosition = $event?.target.selectionStart
    },

    templateInput (event) {
      if (!event) {
        this.$emit('changeEditorValue', '')

        return
      }

      const converter = new TemplateConverter(event, this.valuesMap, this.labelsMap)
      const convertedValue = converter.toClient()
      const valueToServer = convertedValue.replaceAll('Name', '_name')

      this.$emit('changeEditorValue', valueToServer)
    },

    insertVariable (variable) {
      if (variable.children) { return }

      const value = this.editorValue
      const position = this.templateCaretPosition
      const newVariable = `{{ ${[variable.parentSource, variable.source].join('.')} }}`

      const newValue = insertToString(
        value,
        newVariable,
        position
      )

      this.templateCaretPosition += newVariable.length
      this.$emit('changeEditorValue', newValue)
    },

    showPreview () {
      const notification = new MRequestNotification(
        'VariablesEditor:showPreview',
        'fetchMessage',
        'previewText'
      )

      this.previewRequest(this.editorValue)
        .then((text) => { this.previewValue = text })
        .catch(MRequestError.withDefault([], notification))
    },

    insertTemplate (text) {
      const valueToServer = text.replaceAll('Name', '_name')

      this.$emit('changeEditorValue', valueToServer)
    },
  },
}
</script>
