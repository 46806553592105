import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class PersonalCheckupEntryModalItem extends MListServiceItem {
  /**
   *
   * @param data {IPersonalCheckupEntryModalItem}
   */
  constructor (data) {
    super()

    this.id = data.id

    const title = data.entryTypes.at(0).entryTypeTitle

    this.title = new MTableCell(title)
      .addTooltip(title)
  }
}
