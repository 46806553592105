<template>
  <div class="stub-wrapper">
    <div
      class="stub-content"
      @click="onStubClick($event)"
    >
      <slot>
        <button
          type="button"
          class="btn btn-primary btn-xs"
          @click="$emit('changeVisibility', true)"
        >
          {{ t('introduction.connect_module') }}
        </button>
      </slot>
    </div>

    <div
      v-if="isVisible"
      class="stub-modal-wrapper"
    >
      <modal
        class="stub-modal"
        :class="modalStyles"
        :modal-visibility="isVisible"
        remove-footer
        @close="$emit('changeVisibility', false)"
      >
        <template #header>
          <i class="fad fa-fw fa-toggle-off" />
          <span>{{ t('introduction.actions_required') }}</span>
        </template>

        <template #body>
          <div class="introduction-container">
            <stub-item
              v-for="(reqName, index) in missingRequirements"
              :key="index"
              :is-available-settings="isAvailableSettings"
              v-bind="STUBS[reqName]"
            />
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from '@/vue_components/modal.vue'
import STUBS from './stubs_objects'
import StubItem from './stub_item'

export default {
  name: 'ElementStubWrapper',
  components: {
    Modal,
    StubItem,
  },

  props: {
    /**
     * @type {import('vue').PropOptions<{name: String, isEnabled: Boolean}[]>}
     *
     * name - module or option name
     */
    requirements: {
      type: Array,
      default: () => [],
    },

    isVisible: {
      type: Boolean,
      default: false,
    },

    /**
     * CSS класс(-ы) для изменения позиционирования модалки
     */
    modalStyles: {
      type: String,
      default: '',
    },

    isAvailableSettings: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({ STUBS }),

  computed: {
    missingRequirements () {
      return this.requirements
        .filter((req) => !req.isEnabled)
        .map((req) => req.name)
    },
  },

  methods: {
    onStubClick (event) {
      /* event не блокируется если все requirements false */
      if (!this.missingRequirements.length) return
      event.preventDefault()
      this.$emit('changeVisibility', true)
    },
  },
}
</script>
