<template>
  <div class="semd-created-updated-by-at">
    <div
      v-if="created"
      class="prompt-notice"
    >
      {{ created }}
    </div>

    <div
      v-if="updated"
      class="prompt-notice"
    >
      {{ updated }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { MSemdModel } from '@/vue_apps/Semds/entities/_SemdBase/MSemdModel'

export default defineComponent({
  name: 'SemdCreatedUpdatedByAt',
  props: {
    semd: MSemdModel,
  },

  computed: {
    created () {
      if (!this.semd || !this.semd.createdBy?.id) { return '' }

      return `${window.t('log_create')}: ${this.semd.createdBy.shortName}, ${this.semd.createdAt}`
    },

    updated () {
      if (!this.semd || !this.semd.updatedBy?.id) { return '' }

      return `${window.t('log_update')}: ${this.semd.updatedBy.shortName}, ${this.semd.updatedAt}`
    },
  },
})
</script>
