<template>
  <div class="flex-form">
    <template v-for="(field, index) in fields">
      <div
        v-if="!field.hide"
        :key="index"
        class="form-label"
      >
        <label>
          <slot :name="getSlotName(field.name, 'label')">
            {{ field.label }}
            <abbr
              v-if="field.required"
              v-tooltip="t('activerecord.required.text')"
            >
              *
            </abbr>
          </slot>
        </label>
      </div>

      <div
        v-if="!field.hide"
        :key="fields.length + index"
        class="form-value"
      >
        <slot :name="getSlotName(field.name, 'value')">
          {{ field.value }}
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FlexForm',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    slotPostfix: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getSlotName (field, postfix) {
      return this.slotPostfix ? `${field}-${postfix}` : field
    },
  },
}
</script>
