import { request } from '@/lib/transport/request'
import {
  egiszCaseGetAdapter,
  egiszCaseGetAllAdapter,
  egiszCasesSubmitAdapter,
} from '@/api_entities/egisz/cases/egisz_cases_adapters'
import { cloneDeep } from 'lodash'

export const egiszCasesEndpoint = {
  getAll (filters, adapter = egiszCaseGetAllAdapter) {
    const options = {
      url: Routes.list_egisz_medical_cases_path(),
      method: 'POST',
      data: { sort_params: adapter.toServer(cloneDeep(filters)) },
    }

    return request(options, adapter.toClient).promise
  },

  get (id) {
    const options = {
      type: 'get',
      url: Routes.medical_cases_ambulatory_path(id),
    }

    return request(options, egiszCaseGetAdapter.toClient).promise
  },

  submit (data) {
    return data.id
      ? egiszCasesEndpoint.update(data)
      : egiszCasesEndpoint.create(data)
  },

  create (data) {
    const options = {
      type: 'post',
      url: Routes.medical_cases_ambulatories_path(),
      data: JSON.stringify(egiszCasesSubmitAdapter.toServer(data)),
      contentType: 'application/json',
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'patch',
      url: Routes.medical_cases_ambulatory_path(data.id),
      data: JSON.stringify(egiszCasesSubmitAdapter.toServer(data)),
      contentType: 'application/json',
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'delete',
      url: Routes.medical_cases_ambulatory_path(id),
    }

    return request(options).promise
  },

  close (id) {
    const options = {
      type: 'patch',
      url: Routes.close_medical_cases_ambulatory_path(id),
    }

    return request(options).promise
  },

  reopen (id) {
    const options = {
      type: 'patch',
      url: Routes.reopen_medical_cases_ambulatory_path(id),
    }

    return request(options).promise
  },

  iemkAdd (medicalCaseId) {
    const options = {
      type: 'post',
      url: Routes.egisz_iemk_add_case_path(),
      data: {
        medical_case_id: medicalCaseId,
      },
    }

    return request(options).promise
  },

  iemkUpdate (medicalCaseId) {
    const options = {
      type: 'post',
      url: Routes.egisz_iemk_update_case_path(),
      data: {
        medical_case_id: medicalCaseId,
      },
    }

    return request(options).promise
  },
}
