import { ICatalog } from '@/_declarations/ICatalog'
import { TTinyMCEEditor } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/TTinyMCE'
import {
  generateTitle,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdAreaParts/generateTitle'
import {
  getElementAttributes,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/SemdAreaParts/getElementAttributes'
import {
  AbstractSemdEntity,
} from '@/vue_apps/Protocols/SemdProtocolEditor/_SemdEntities/_semdBaseEntities/AbstractSemdEntity'
import { SEMD_AREA_INTERNALS_ATTR } from '@/vue_apps/Protocols/const/const'

type SemdAreaElement = {
  start: Element
  end: Element
  bogus?: Element
}

export class SemdArea extends AbstractSemdEntity {
  static getWrapper (content = '') {
    return `<div class="semd-area-wrapper">${content}</div>`
  }

  static parseContent (id: string, templateHtml: string): string {
    /* @ts-ignore */
    const $element = $(`<div>${templateHtml}</div>`)

    const selectorsBetween = $element
      .find(`.${id}:first`)
      .nextUntil($element.find(`.${id}:last`))
      .toArray()

    const content = selectorsBetween
      .reduce((acc, item) => acc + item.outerHTML, '')

    const $content = $(SemdArea.getWrapper(content))

    // эти html-элементы хранят ненужный [в результате] текст
    $content
      .find('.diagnoses_constructor, .print_area_start, .print_area_end, .semd-area')
      .remove()

    // добавляем переносы строк для того, чтобы в конце слова не слипались
    $content.find('p').each((_, item) => {
      $(item).append('\n')
    })

    return $content
      .text()
      .replace(/[\n\s\r]+/g, ' ')
      .trim()
  }

  element: SemdAreaElement

  id: string

  title: string

  value: null

  isOptional = false

  constructor ({
    id,
    title,
    isOptional = false,
  }) {
    super()
    this.id = id
    this.title = title
    this.isOptional = Boolean(isOptional)
  }

  buildTemplate (editor: TTinyMCEEditor): SemdAreaElement {
    return {
      start: editor.dom.create(
        'p',
        getElementAttributes(this.id, 'start'),
        generateTitle(this.title, 'start')
      ),
      bogus: editor.dom.create(
        'p',
        { [SEMD_AREA_INTERNALS_ATTR]: this.id },
        '<br data-mce-bogus="1">'
      ),
      end: editor.dom.create(
        'p',
        getElementAttributes(this.id, 'end'),
        generateTitle(this.title, 'end')
      ),
    }
  }

  createElement (editor: TTinyMCEEditor): void {
    this.element = this.buildTemplate(editor)
    editor.insertContent([
      this.element.start.outerHTML,
      this.element.bogus.outerHTML,
      this.element.end.outerHTML,
    ].join(''))
  }

  destroy (editor: TTinyMCEEditor): void {
    this.linkWithElement(editor, false)

    if (!this.element) { return console.debug('Element not found') }
    this.element.start?.remove()
    this.element.end?.remove()
    this.element = null
  }

  linkWithElement (editor: TTinyMCEEditor, applyColor = true): void {
    const elements: Element[] = editor.dom.select(`.${this.id}`)
    if (!elements?.length) { return }

    this.element = { start: elements[0], end: elements[1] }
    this.colorBetween(editor, applyColor)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update (editor: TTinyMCEEditor, value: ICatalog): void {}

  getContent (options?: Record<string, unknown>): string {
    if (!this.element) { return '' }

    const templateHtml = options?.templateHtml as string || ''

    return SemdArea.parseContent(this.id, templateHtml)
  }

  isValid (options?: Record<string, unknown>) {
    if (this.isOptional) { return true }

    const content = this.getContent(options)
    const collapsedContent = SemdArea.getWrapper()
    const emptyLikeContent = SemdArea.getWrapper('&nbsp;')

    return content !== collapsedContent && content !== emptyLikeContent
  }

  colorBetween (editor: TTinyMCEEditor, applyColor = true) {
    if (!this.element) { return console.debug('Element not found') }

    const $range = $(editor.getBody())
      .find(this.element.start)
      .nextUntil(this.element.end)
      .filter(':not(.semd-area)')

    applyColor
      ? $range.attr(SEMD_AREA_INTERNALS_ATTR, this.id)
      : $range.filter(`[${SEMD_AREA_INTERNALS_ATTR}="${this.id}"]`).removeAttr(SEMD_AREA_INTERNALS_ATTR)
  }
}
