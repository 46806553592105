<template>
  <div class="m-stars">
    <el-rate
      :value="rating"
      :colors="colors"
      :disabled-void-color="disabledVoidColor"
      disabled
    />
  </div>
</template>

<script>
import { RATING_COLORS } from '@/vue_apps/FeedbackModule/const/const'

export default {
  name: 'MStars',

  props: {
    value: { type: Number, default: 0 },
    colors: { type: Array, default: () => RATING_COLORS.YELLOW },
    disabled: { type: Boolean, default: true },
    disabledVoidColor: { type: String, default: '' },
    divider: { type: Number, default: 2 },
  },

  computed: {
    rating () {
      return this.value / this.divider
    },
  },
}
</script>
