/**
 * Длительность анимации ElementUI (300) + 50 для устранения мерцания
 * @type {number}
 */
export const ELEMENT_UI_TRANSITION_TIME_MS = 350

/**
 * Отложенный запуск функции, примеры:
 * 1) Задержка очистки данных на время анимации скрытия модалки ElementUI
 *
 * @param {Function} callback
 * @param {number} timeoutMs
 * @return {number}
 */
export const callAfterTimeout = (callback, timeoutMs = ELEMENT_UI_TRANSITION_TIME_MS) => {
  return setTimeout(() => callback(), timeoutMs)
}
