import { request } from '@/lib/transport/request'
import { entryCreateAdapter, getEntriesWithoutStepsAdapter } from '@/api_entities/entries/entry_adapters'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 20

export const entryEndpoint = {
  getAll (clientId, page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE) {
    const options = {
      type: 'post',
      url: Routes.sort_entries_path(),
      data: {
        client_id: clientId,
        page,
        per_page: perPage,
      },
    }

    return request(options).promise
  },

  getEntriesWithoutSteps (data) {
    const options = {
      type: 'post',
      url: Routes.egisz_entries_list_path(),
      data: {
        sort_params: data,
      },
    }

    return request(options, (response) => getEntriesWithoutStepsAdapter.toClient(response.data)).promise
  },

  get (id) {
    /* реализация получения записи по id */
  },

  create (clientId, entryTypeId) {
    const options = {
      type: 'POST',
      url: Routes.entries_path(),
      data: {
        client_id: clientId,
        entry_type_id: entryTypeId,
      },
    }

    return request(options, entryCreateAdapter.toClient).promise
  },

  update (data) {
    /* реализация обновления записи с data.id */
  },

  destroy (id) {
    const options = {
      type: 'delete',
      url: Routes.entry_path(id),
    }

    return request(options).promise
  },

  restore (id) {
    /* реализация восстановления записи по id */
  },

  getPdfGeneratedByEntryId (id) {
    const options = {
      type: 'get',
      url: Routes.pdf_generated_by_id_entry_path(id),
    }

    const adapter = (response) => JSON.parse(response)

    return request(options, adapter).promise
  },
}
