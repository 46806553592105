export const CHECKUPS_ROUTE_NAMES = Object.freeze({
  MAIN: 'checkups-main-page',
  PERIODIC_CHECKUPS: 'periodic-checkups',
  PRIOR_CHECKUPS: 'prior-checkups',
  COMPANY_CHECKUP_NEW: 'company-checkup-editor-new',
  COMPANY_CHECKUP_EDIT: 'company-checkup-editor-edit',
  COMPANY_CHECKUP_SHOW: 'company-checkup-editor-show',
  PERSONAL_CHECKUP_NEW: 'personal-checkup-editor-new',
  PERSONAL_CHECKUP_EDIT: 'personal-checkup-editor-edit',
  PERSONAL_CHECKUP_SHOW: 'personal-checkup-editor-show',

  PERSONAL_PERIODIC_CHECKUP_NEW: 'personal-periodic-checkup-editor-new',
  PERSONAL_PERIODIC_CHECKUP_EDIT: 'personal-periodic-checkup-editor-edit',
  PERSONAL_PERIODIC_CHECKUP_SHOW: 'personal-periodic-checkup-editor-show',

  CHECKUP_DOCUMENT_NEW: 'checkup-document-editor-new',
  CHECKUP_DOCUMENT_EDIT: 'checkup-document-editor-edit',
  CHECKUP_DOCUMENT_SHOW: 'checkup-document-editor-show',

  CHECKUP_404: 'checkup-404',
})
