<template>
  <div class="step-form">
    <div class="row">
      <div class="col">
        <step-title
          :title.sync="tempStep.title"
          :is-new-mode="isNewMode"
          :is-title-generated.sync="isTitleGenerated"
          :step-validations="tempStepValidations"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-5">
        <step-fields
          :client="client"
          :clinic-name="clinicName"
          :doctor-id.sync="tempStep.doctor_id"
          :type.sync="tempStep.type"
          :start-date-time="tempStep.opened_at"
          :end-date-time.sync="tempStep.closed_at"
          :place-id.sync="tempStep.netrika_visit_place_id"
          :purpose-id.sync="tempStep.netrika_visit_purpose_id"
          :step-validations="tempStepValidations"
          @update:startDateTime="updateDateTimes"
        />
      </div>

      <div class="col-xs-7">
        <step-services
          :entries-list="tempStep.entries_list"
          :comment.sync="tempStep.comment"
          :loading="loading"
          :client="client"
          :read-only="readOnly"
          @ask-add="addEntry"
          @addExistingEntry="addExistingEntry"
          @ask-delete="deleteEntry"
        />
      </div>
    </div>

    <div
      v-if="!readOnly"
      class="step-form__footer margin-top-10"
    >
      <button
        class="btn btn-sm btn-success btn-with-icon"
        type="button"
        @click="onSubmit"
      >
        <i class="fad fa-save btn-with-icon_icon" />
        <span>{{ t('save') }}</span>
      </button>

      <popover
        v-if="!isNewMode"
        placement="top-end"
        @yes="$emit('deleteStep', tempStep.id)"
      >
        <template #message>
          <i class="fad fa-fw fa-question-circle red" />
          {{ t('egisz.steps.modal.destroy') }}
        </template>

        <button
          slot="reference"
          class="btn btn-sm btn-danger btn-with-icon"
          type="button"
        >
          <i class="fad fa-exclamation-circle btn-with-icon_icon" />
          <span>{{ t('delete') }}</span>
        </button>
      </popover>
    </div>
  </div>
</template>

<script>
import { createEmptyStep, DEFAULT_SHIFT } from '@/vue_components/egisz/egisz_module/const/egisz_entities'
import { cloneDeep } from 'lodash'
import Popover from '@/vue_components/common/popover/popover'
import StepFields
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardSteps/StepForm/StepFields'
import StepServices
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardSteps/StepForm/StepEntries'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { entryEndpoint } from '@/api_entities/entries/entry_endpoint'
import { egiszMedCaseStepValidations } from '@/vue_components/egisz/egisz_module/const/egisz_validations'
import { isValid } from '@/vue_components/egisz/egisz_module/helpers/steps_validation_helpers'
import { mapGetters } from 'vuex'
import StepTitle
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardSteps/StepForm/StepTitle'
import EgiszStepValidationMethods from '@/vue_components/egisz/egisz_module/mixins/EgiszStepValidationMethods'
import { getUserIdInDoctorList } from '@/vue_components/egisz/egisz_module/composables/helpers'
import { ACTIONS } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { entryCreateAdapter } from '@/api_entities/entries/entry_adapters'

export default {
  name: 'StepForm',
  components: { StepTitle, StepServices, StepFields, Popover },
  mixins: [SpinnerHolder, EgiszStepValidationMethods],

  props: {
    step: {
      type: Object,
      default: null,
    },

    client: {
      type: Object,
      default: null,
    },

    clinicName: {
      type: String,
      default: null,
    },

    userId: {
      type: Number,
      default: null,
    },

    userList: {
      type: Array,
      default: () => [],
    },

    stepValidations: {
      type: Object,
      default: null,
    },

    readOnly: Boolean,
  },

  data () {
    return {
      tempStep: null,
      tempStepValidations: egiszMedCaseStepValidations(),
      isTitleGenerated: false,
    }
  },

  computed: {
    ...mapGetters({
      getDateTimePickerFormat: 'GET_LOCALIZATION_DATE_TIME_FORMAT',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorById: 'getDoctorShortName',
      vxDoctors: 'getDoctors',
    }),

    ...mapGetters({
      currentDoctorId: 'GET_APP_CONF_CURRENT_USER_ID',
    }),

    ...mapGetters('egisz/egiszWs', {
      vxLastEntry: 'vxGetLastEntry',
    }),

    isNewMode () {
      return !(this.step && this.step.id)
    },
  },

  watch: {
    step (to) {
      to
        ? this.tempStep = cloneDeep(to)
        : this.resetTempStep()
    },

    isNewMode: {
      immediate: true,
      handler (to) {
        this.isTitleGenerated = to
      },
    },

    tempStep () {
      this.generateTitle()
    },

    'tempStep.doctor_id' () {
      this.tempStepValidations.doctor_id = []
      this.generateTitle()
    },

    isTitleGenerated (to) {
      if (!to) { return }
      this.generateTitle()
    },

    stepValidations (to) {
      this.tempStepValidations = { ...egiszMedCaseStepValidations(), ...to }
    },

    'tempStep.entries_list' (to = []) {
      this.tempStep.entry_ids = to.map((entry) => entry.id)
    },

    'tempStep.title' (to) {
      if (!to || !this.tempStepValidations.title.length) { return }
      this.tempStepValidations.title = []
    },

    'tempStep.opened_at' (to) {
      this.generateTitle()
      if (!to || !this.tempStepValidations.opened_at.length) { return }
      this.tempStepValidations.opened_at = []
    },

    'tempStep.netrika_visit_place_id' (to) {
      if (!to || !this.tempStepValidations.netrika_visit_place_id) { return }
      this.tempStepValidations.netrika_visit_place_id = []
    },

    'tempStep.netrika_visit_purpose_id' (to) {
      if (!to || !this.tempStepValidations.netrika_visit_purpose_id) { return }
      this.tempStepValidations.netrika_visit_purpose_id = []
    },

    tempStepValidations: {
      deep: true,
      handler (to) {
        if (!isValid(to)) { return }
        this.$emit('resetValidations')
      },
    },

    vxLastEntry (to) {
      if (to.action === ACTIONS.DELETED) {
        this.deleteEntry(to.id)
      }

      if (to.action === ACTIONS.UPDATED) {
        this.setEntryAttributes(entryCreateAdapter.toClient(to))
      }
    },
  },

  created () {
    this.resetTempStep()
    this.generateTitle()
  },

  methods: {
    resetTempStep () {
      const doctorId = getUserIdInDoctorList(this.vxDoctors, this.currentDoctorId)
      this.tempStep = createEmptyStep({ doctor_id: doctorId })
    },

    /**
     * Ручной вызов через refs в CardSteps.vue::stepModalOpen()
     */
    generateTitle () {
      if (!this.isTitleGenerated) { return }
      const date = moment(this.tempStep.opened_at).format(this.getDateTimePickerFormat)
      const doctor = this.getDoctorById(this.tempStep.doctor_id)
      const client = this.client.shortname
      this.tempStep.title = [
        `${doctor} ${date}`,
        `${t('egisz.steps.modal.step')} ${client}`,
      ].join(', ')
    },

    setEntryAttributes (newEntry) {
      const entry = this.tempStep.entries_list.find((item) => item.id === newEntry.id)
      if (!entry) { return }

      Object.keys(newEntry)
        .forEach((key) => { entry[key] = newEntry[key] })
    },

    addEntry (entryTypeId) {
      const promise = entryEndpoint.create(this.client.id, entryTypeId)
        .then((data) => {
          this.tempStep.entries_list.push(data)
        })
        .catch((err) => {
          Utils.reportError(
            'StepForm:createEntry()',
            t('utils_report_error.create_message', { entity: t('utils_report_error.entities.step') })
          )(err)
        })

      this.withSpinner(promise)
    },

    addExistingEntry (entry) {
      this.tempStep.entries_list.push(entry)
    },

    deleteEntry (entryId, hardDelete = false) {
      hardDelete
        ? this.hardDeleteEntry(entryId)
        : this.filterEntry(entryId)
    },

    filterEntry (entryId) {
      this.tempStep.entries_list = this.tempStep.entries_list
        .filter((entry) => entry.id !== entryId)
    },

    hardDeleteEntry (entryId) {
      const promise = entryEndpoint.destroy(entryId)
        .then(() => this.filterEntry(entryId))
        .catch((err) => {
          Utils.reportError(
            'StepForm:hardDeleteEntry()',
            t('utils_report_error.delete_message', { entity: t('utils_report_error.entities.step') })
          )(err)
        })

      this.withSpinner(promise)
    },

    onSubmit () {
      if (this.hasErrors()) { return }

      this.$emit('applyStep', cloneDeep(this.tempStep))
      this.resetTempStep()
    },

    updateDateTimes (startDateTime) {
      const addShift = (value) => moment(value).add(...DEFAULT_SHIFT).toDate()

      this.tempStep.opened_at = startDateTime

      if (startDateTime && (!this.tempStep.closed_at || this.tempStep.closed_at < addShift(startDateTime))) {
        this.tempStep.closed_at = addShift(startDateTime)
      }

      this.$forceUpdate()
    },
  },
}
</script>
