<template>
  <div class="document-square-field-date">
    <document-square-field-line
      :line-size="adaptedValue.day.length"
      :value="adaptedValue.day"
    />

    <document-bolt-dot />

    <document-square-field-line
      :line-size="adaptedValue.month.length"
      :value="adaptedValue.month"
    />

    <document-bolt-dot />

    <document-square-field-line
      :line-size="adaptedValue.year.length"
      :value="adaptedValue.year"
    />
  </div>
</template>

<script>
import DocumentSquareFieldLine
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLine/DocumentSquareFieldLine.vue'
import DocumentBoltDot from '@/vue_present/DocumentBase/DocumentBoltDot/DocumentBoltDot.vue'

export default {
  name: 'DocumentSquareFieldDate',
  components: { DocumentBoltDot, DocumentSquareFieldLine },

  props: {
    value: { type: [String, Object], default: null },
  },

  computed: {
    adaptedValue () {
      const date = moment(this.value)

      if (!date.isValid()) {
        return {
          day: '',
          month: '',
          year: '',
        }
      }

      return {
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY'),
      }
    },
  },
}
</script>
