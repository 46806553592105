<template>
  <m-search-input-lazy
    v-model="vmValue"
    class="flex-grow-1"
    :list="list"
    :required="required"
    :validator-name="validatorName"
    :m-fixed-height="mFixedHeight"
    :use-search-style="useSearchStyle"
    :label="!useSearchStyle && t('client')"
    :placeholder="t('full_name_or_phone_or_emk_or_card')"
    :disabled="disabled"
    :option-label="optionLabel"
    @registerValidator="$registerValidator($event)"
    @onItemClick="$emit('onItemClick', $event)"
  />
</template>

<script>
import MSearchInputLazy from '@/vue_present/_base/MSearchInputLazy/MSearchInputLazy.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { MListService } from '@/_api/_requests/MListService'
import { ClientsSearchList } from '@/vue_apps/CheckupsModule/components/ClientSearchCreateShort/ClientsSearchList'

export default {
  name: 'ClientSearchInput',
  components: { MSearchInputLazy },
  props: {
    // строка в поиске
    value: PropsTypes.String(),
    list: { type: MListService, default: () => new ClientsSearchList() },

    optionLabel: { type: String, default: 'shortInfo' },

    required: { type: [Boolean, Function], default: false },
    validatorName: { type: String, default: null },
    mFixedHeight: { type: Boolean, default: true },

    disabled: Boolean,
    useSearchStyle: Boolean,
  },

  emits: [
    'input',
    'onItemClick',
    'registerValidator',
  ],

  computed: {
    vmValue: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>
