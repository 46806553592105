import { MSemdLogic } from '@/vue_apps/Semds/entities/_SemdBase/MSemdLogic'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { ISemdApi } from '@/vue_apps/Semds/interfaces/ISemdApi'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { ISemd } from '@/_declarations/ISemd'
import { semdAdapter } from '@/_api/MSemdApi/semdAdapter'

export class MSemdApi<TMetaData, TSemdsDocumentDetailsAttributesData>
  extends MSemdLogic<TMetaData, TSemdsDocumentDetailsAttributesData>
  implements ISemdApi<TMetaData, TSemdsDocumentDetailsAttributesData> {
  presenter = new MSemdPresenter()

  requiredAreas: string[] = []

  requiredEntities: string[] = []

  ownerUrl: string = ''

  constructor ({ id, semdType } = { id: null, semdType: null }) {
    super()

    this.setValue('id', id)
    this.setValue('semdType', semdType)
  }

  destroy (): Promise<IApiErrors> {
    if (!this.id) { return }

    return this.presenter.destroy(this.id)
  }

  async load (): Promise<void> {
    if (!this.id) { return }
    const response = await this.presenter.fetch(this.id)
    if (response?.errors) { return }

    this.fillProps(response)
    this.generateOwnerUrlAfterLoad()
  }

  register (): Promise<IApiErrors> {
    if (!this.id) { return }

    return this.presenter.register(this.id)
  }

  async submit (
    data: ISemd<TMetaData, TSemdsDocumentDetailsAttributesData> = this.getAttributes() as ISemd<TMetaData, TSemdsDocumentDetailsAttributesData>
  ): Promise<IApiErrors> {
    const config = {
      toJson: true,
      toServerAdapter: (data: ISemd<TMetaData, TSemdsDocumentDetailsAttributesData>) => ({
        semd: semdAdapter.toServer(data),
      }),
    }

    const response = await this.presenter.submit(data, config)

    if (!response?.errors) {
      this.setValue('id', response.id)
      this.fillModelDBInfo(response)
    }

    return response
  }

  getCustomValidationMessageOrIsValid (): string | boolean { return true }

  protected generateOwnerUrlAfterLoad () { throw new Error('generateOwnerUrl is not implemented') }
}
