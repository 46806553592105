import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  calculatedSumAdapter,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/api/calculatedSumAdapter'
import {
  taxCertificate2024Adapter,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/api/taxCertificate2024Adapter'
import { MTaxCertificate2024Presenter } from '@/_api/MTaxCertificate2024/MTaxCertificate2024Presenter'
import {
  taxCertificate2024ListAdapter,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/api/taxCertificate2024ListAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'

export class TaxCertificate2024Presenter extends MTaxCertificate2024Presenter {
  constructor () {
    super()
    this.location = 'TaxCertificate2024Presenter'
  }

  fetchRepresentedPersons (id, options = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchAllMessage',
      'representedPersons'
    )

    return super.post({
      url: Routes.list_api_internal_documents_certificate_payments_clients_path(),
      data: { id },
      ...options,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }

  fetchCalculatedSum (payload, options = {}) {
    return super.post({
      url: Routes.form_289_sum_payments_path(),
      data: calculatedSumAdapter.toServer(payload),
      ...options,
    }).promise
      .then(calculatedSumAdapter.toClient)
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      toClientAdapter: taxCertificate2024Adapter.toClient,
      ...config,
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: taxCertificate2024ListAdapter.toServer,
      toJson: true,
      ...config,
    })
  }

  async submit (data, config = {}) {
    return super.submit(data, {
      toServerAdapter: taxCertificate2024Adapter.toServer,
      toClientAdapter: taxCertificate2024Adapter.toClient,
      toJson: true,
      ...config,
    }).then((payload) => {
      const duplicateError = payload.errors?.client_id?.[0]

      if (duplicateError) {
        Utils.reportError(this.location, duplicateError)()
      }

      return payload
    })
  }

  destroy (id, options = {}) {
    return super.destroy(id, {
      dataType: 'text',
      ...options,
    })
  }
}
