<template>
  <m-select-lazy
    :value="value"
    :fetch-method="({ searchQuery }) => search(searchQuery)"
    :multiple="multiple"
    :m-fixed-height="mFixedHeight"
    :placeholder="t('all_services')"
    :full-width="fullWidth"
    :label="label"
    :disabled="disabled"
    :additional-displayed-filter="additionalDisplayedFilter"
    :use-force-update="useForceUpdate"
    option-notice="info"
    filterable
    @changeByLazy="$emit('changeEntryTypes', $event)"
  />
</template>

<script>
import { GLOBAL_SEARCH_MIN_LENGTH } from '@/_global_scripts/GLOBAL_CONSTS'
import { camelToSnake } from '@/_api/_requests/helpers'
import { entryTypesEndpoint } from '@/api_entities/entry_types/entryTypesEndpoint'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { searchAdapter } from '@/vue_present/Reuse/Lists/EntryTypes/api/searchAdapter'

export default {
  name: 'ReusableEntryTypesList',
  components: { MSelectLazy },
  props: {
    value: { type: [Array, Object], default: null },
    label: { type: String, default: t('entry_services') },

    forCurrentUser: Boolean,
    forCurrentClinic: Boolean,
    currentClinicId: { type: Number, default: null },
    entryTypeKinds: { type: Array, default: undefined },

    useForceUpdate: Boolean,
    useEntryNumberFilter: Boolean,
    multiple: Boolean,
    mFixedHeight: Boolean,
    fullWidth: Boolean,
    disabled: Boolean,
  },

  emits: [
    'changeEntryTypes',
  ],

  computed: {
    additionalDisplayedFilter () {
      return this.useEntryNumberFilter
        ? this.entryNumberFilter
        : null
    },
  },

  methods: {
    /**
     * @deprecated
     * @param {string} title
     * @return {*}
     */
    search (title = '') {
      if (title.length < GLOBAL_SEARCH_MIN_LENGTH) { return }

      const data = camelToSnake({
        title,
        kinds: this.entryTypeKinds,
        forCurrentUser: this.forCurrentUser || undefined,
        clinicIds: this.forCurrentClinic
          ? [this.currentClinicId || gon.application.current_clinic.id]
          : (gon.application.current_user_clinics || []).map(({ id }) => id),
      })

      return entryTypesEndpoint.find(data)
        .then(searchAdapter.toClient)
        .then((items) => ({
          data: items,
          totalItems: items.length,
          totalPages: 1,
        }))
    },

    entryNumberFilter (searchQueryInLowerCase, item) {
      return item
        .number
        .toLowerCase()
        .indexOf(searchQueryInLowerCase) !== -1
    },
  },
}
</script>
