<template>
  <div class="documents-tree-modal">
    <m-modal
      :visible.sync="modalVisible"
      :dialog-title="dialogTitle"
      modal-title-icon="file"
      type="primary"
      @close="closeModal"
    >
      <div
        v-loading="loading"
        class="documents-tree-modal__body"
      >
        <m-prompt-notice
          v-if="showNotice"
          :text="t('choose_document_template')"
        />

        <m-buttons-group
          v-if="isMultiDocumentTypes"
          :value="documentType"
          :items="documentTypeItems"
          radio
          size="mini"
          :m-fixed-height="false"
          @change="onDocumentTypeChange"
        />

        <reusable-lazy-tree
          v-if="showTree"
          v-slot="{ node, data }"
          :tree="tree"
          :category-type="documentType.id"
          @onNodeClick="onNodeClick"
          @setTreeRef="treeRef = $event"
          @onLoadNode="onLoadNode"
        >
          <lazy-tree-node-component
            :data="data"
            :node="node"
          />
        </reusable-lazy-tree>
      </div>
    </m-modal>

    <slot :open-modal="openModal">
      <m-button
        v-tooltip="t('semds.semd119.formTitles.addFoundationDocument')"
        :disabled="disabled"
        plus-icon
        icon="file"
        type="success"
        @click="openModal"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'
import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import {
  DocumentTypesPresenter,
} from '@/vue_present/Reuse/DocumentTree/api/DocumentTypesPresenter/DocumentTypesPresenter'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { defineComponent, PropType } from 'vue'
import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import {
  IReusableDocumentTreeDocumentItem,
} from '@/vue_present/Reuse/DocumentTree/interfaces/reusableDocumentTreeDocumentItem'
import { ICatalog } from '@/_declarations/ICatalog'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { getTreeExtraAttributes } from '@/vue_present/Reuse/DocumentTree/store/treeExtraAttributes'
import {
  IReusableDocumentTreeExcludedNodes,
} from '@/vue_present/Reuse/DocumentTree/interfaces/IReusableDocumentTreeExcludedNodes'
import { extractItemId } from '@/vue_present/Reuse/DocumentTree/store/extractItemId'
import { DocumentsCategoriesPresenter } from '@/vue_present/Reuse/DocumentTree/api/DocumentCategoriesPresenter'

export default defineComponent({
  name: 'ReusableDocumentsTreeModal',

  components: {
    MButtonsGroup,
    MPromptNotice,
    LazyTreeNodeComponent,
    MButton,
    ReusableLazyTree,
    MModal,
  },

  mixins: [ModalMixin, SpinnerHolder],

  props: {
    disabled: Boolean,

    showNotice: Boolean,

    useDocumentTypes: { type: Boolean, default: true },
    useFixedDocumentTypes: Boolean,
    useProtocolDocumentTypes: Boolean,

    excludedNodes: {
      type: Object as PropType<IReusableDocumentTreeExcludedNodes>,
      default: () => ({}),
    },
  },

  emits: [
    'documentItemClick',
  ],

  data () {
    return {
      showTree: false,
      tree: null,
      treeRef: null,
      documentType: null,
    }
  },

  computed: {
    isMultiDocumentTypes () {
      return this.documentTypeItems.length > 1
    },

    dialogTitle () {
      return this.isMultiDocumentTypes
        ? window.t('document_types')
        : window.t('document_type')
    },

    documentTypeItems () {
      const items = []

      if (this.useDocumentTypes) {
        items.push({
          id: REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.DOCUMENT,
          title: window.t('document_types'),
        })
      }

      if (this.useFixedDocumentTypes) {
        items.push({
          id: REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED,
          title: window.t('fixed_document_types'),
        })
      }

      if (this.useProtocolDocumentTypes) {
        items.push({
          id: REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.PROTOCOL,
          title: window.t('templates_records'),
        })
      }

      return items
    },
  },

  created () {
    this.initDocumentType()
    this.initTree()

    this.showTree = true
  },

  methods: {
    onNodeClick (item: LazyTreeNode) {
      if (!item.isLeaf) { return }

      const documentItem: IReusableDocumentTreeDocumentItem = {
        id: extractItemId(item.id),
        title: item.title,
        documentType: this.documentType.id,
        extraAttributes: item.extraAttributes,
      }

      this.$emit('documentItemClick', documentItem)

      this.closeModal()
    },

    onDocumentTypeChange (value: ICatalog<string>) {
      if (this.documentType.id === value.id) { return }

      this.documentType = value

      this.withSpinner(this.updateTree())
    },

    async updateTree () {
      this.showTree = false

      this.initTree()

      await this.$nextTick()

      this.showTree = true

      await this.$nextTick()
    },

    closeModal () {
      this.onDocumentTypeChange(this.documentTypeItems[0])

      this.modalVisible = false
    },

    initDocumentType () {
      this.documentType = this.documentTypeItems[0]
    },

    initTree () {
      this.tree = new LazyTree(new DocumentTypesPresenter() as DocumentsCategoriesPresenter, {
        extraAttributes: getTreeExtraAttributes(this.documentType.id),
      })
    },

    onLoadNode () {
      const excludedNodes = this.excludedNodes[this.documentType.id]

      if (!excludedNodes || !excludedNodes.length) { return }

      excludedNodes.forEach((id: string | number) => this.treeRef.remove(id))
    },
  },
})
</script>
