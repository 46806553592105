import { HTTP_METHODS, MRequest } from '@/lib/transport/MRequest'
import { fileAdapter } from '@/api_entities/import_validator/import_validators_adapters'
import { request } from '@/lib/transport/request'

class ImportValidatorEndpoint extends MRequest {
  fileValidate ({ file }, fields) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.import_validations_import_path(),
      data: fileAdapter.toServer(file, fields),
      contentType: false,
      processData: false,
      dataType: 'text',
    }

    return request(options).promise
  }

  getImportKinds () {
    return Promise.resolve(
      [
        {
          value: 'analysis',
          title: t('analyzes'),
        },
        {
          value: 'clients',
          title: t('clients'),
        },
        {
          value: 'goods',
          title: t('goods'),
        },
        {
          value: 'users',
          title: t('users'),
        },
        {
          value: 'services',
          title: t('services'),
        },
      ])
  }

  getImportEncodings () {
    return Promise.resolve(
      [
        {
          value: 'windows-1251',
          title: t('import_validation.encoding_win'),
        },
        {
          value: 'utf-8',
          title: t('import_validation.encoding_utf'),
        },
      ]
    )
  }

  // todo: под удаление, но на всякий случай оставляю
  getImportSeparators () {
    return Promise.resolve(
      [
        {
          value: ',',
          title: t('localization.comma'),
        },
        {
          value: ';',
          title: t('localization.semicolon'),
        },
      ]
    )
  }
}

export const importValidatorEndpoint = new ImportValidatorEndpoint()
