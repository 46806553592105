<template>
  <div
    class="validation-wrapper"
    :class="{ 'fixed-height': fixedHeight, 'm-fixed-height': mFixedHeight }"
  >
    <div
      class="validation-entity position-relative"
      :class="switchClasses[Number(hasErrors)]"
    >
      <slot />
    </div>
    <slot name="under-entity">
      <div
        v-if="hasErrors"
        class="errors-content"
      >
        <span
          v-tooltip="showTooltip && tooltipText"
          class="messages"
        > {{ printErrors }} </span>
      </div>
    </slot>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

const MIN_TOOLTIP_LEN = 30

export default {
  name: 'ValidationWrapper',

  props: {
    errors: PropsTypes.Array(),
    switchClasses: PropsTypes.Array(['no-error', 'has-error']),
    fixedHeight: Boolean, // для старого фронта
    mFixedHeight: PropsTypes.Boolean(), // для нового фронта
    showTooltip: PropsTypes.Boolean(),
  },

  computed: {
    hasErrors () {
      return this.errors.length > 0
    },

    printErrors () {
      return this.errors.filter((err) => err).join(', ') || ''
    },

    tooltipText () {
      return this.printErrors.length >= MIN_TOOLTIP_LEN
        ? this.printErrors
        : ''
    },
  },
}
</script>
