import { props as baseProps } from '@/vue_present/_base/buttons/MButton/props'

const {
  buttonStyle,
} = baseProps

export const props = {
  buttonStyle,
  template: { type: String, default: 'delete' },
  popoverMessage: { type: String, default: t('delete_confirmation') },
  useButton: Boolean,
  useText: { type: Boolean, default: true },
  text: { type: String, default: t('delete') },
  size: { type: String, default: 'small' },
  useCloseIcon: Boolean,
}
