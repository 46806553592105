import { MListService } from '@/_api/_requests/MListService'
import { getInitialFilters } from '@/vue_apps/ClientsModule/components/ClientsList/store/clientListFilters'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'

export class ClientsList extends MListService {
  constructor () {
    super(
      getInitialFilters(),
      null,
      { cacheKey: 'clientsList', limit: DEFAULT_LIMIT }
    )
  }

  async fetchAll () {
    const letter = this.filters.letter
    const searchText = this.filters.searchQuery

    const promise = !searchText
      ? new MClientPresenter().legacyList({
        letter,
        page: this.currentPage,
        perPage: this.limit,
      })

      : new MClientPresenter().legacySearch({
        searchText,
        page: this.currentPage,
        perPage: this.limit,
      })

    const { errors, data, totalItems, totalPages } = await promise
    if (errors) { return }

    this.data = data
    this.totalItems = totalItems
    this.totalPages = totalPages

    this.__cache.save(this.filters)
  }
}
