<template>
  <div class="egisz-emd">
    <search-input
      class="w-100"
      :value="filters.search_string"
      :placeholder="t('egisz.components.egisz_clients_controls.search_ph')"
      @update:value="setSearch"
    />

    <egisz-emd-master
      :mode="EMD_MODES.REGISTER"
      :can-manage="canManage"
      :can-user-send-to-egisz="canUserSendToEgisz"
    />
  </div>
</template>

<script>
import SearchInput from '@/vue_components/common/search_input'
import { mapGetters, mapMutations, mapState } from 'vuex'
import EgiszEmdMaster from '@/vue_components/egisz/egisz_emd_tab/EgiszEmdMaster'
import { EMD_MODES } from '@/vue_components/egisz/egisz_emd_tab/const'

export default {
  name: 'EgiszMedDocuments',
  components: {
    EgiszEmdMaster,
    SearchInput,
  },

  props: {
    canManage: Boolean,
  },

  data () {
    return {
      EMD_MODES,
    }
  },

  computed: {
    ...mapGetters('egiszEmd/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapState('egiszEmd', [
      'selectedEmd',
      'emdList',
      'emdLoaderActive',
    ]),

    ...mapGetters('egisz/user', {
      canUserSendToEgisz: 'vxGetCanUserSendToEgiszValue',
    }),
  },

  methods: {
    ...mapMutations('egiszEmd/filtersBase', {
      setSearch: 'vxSetSearchValue',
    }),
  },
}
</script>
