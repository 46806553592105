import {
  getResearchResults,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInstrumentalResearchResultsPresenter/personalCheckupInstrumentalResearchResultsAdapter'

export const personalCheckupLaboratoryResearchResultsAdapter = {
  toServer ({ ownerId, laboratoryResearchResults }) {
    return {
      personalCheckup: {
        id: ownerId,
        laboratoryResearchResultsAttributes: getResearchResults(laboratoryResearchResults),
      },
    }
  },
}
