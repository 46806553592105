import { ISemd, ISemd as ISemdLike, ISemdOwner } from '@/_declarations/ISemd'
import { ItemBase } from '@/_api/_classes/ItemBase'
import { IHuman } from '@/_declarations/IHuman'
import { ICatalog } from '@/_declarations/ICatalog'
import { TDateString } from '@/_declarations/TDateString'
import { INetrikaError } from '@/_declarations/INetrikaError'
import { SEMD_STATE_ENUM, SEMD_STATES } from '@/vue_apps/Semds/SemdModal/const/const'
import { tryExtractNetrikaError } from '@/vue_apps/Semds/SemdModal/const/tryExtractNetrikaError'
import { getDateFormat } from '@/vue_apps/Semds/const/getDateFormat'
import { netrikaErrorParser } from '@/vue_apps/Semds/SemdModal/const/netrikaErrorParser'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const _permitType = <const> [
  'client',
  'clinic',
  'date',
  'id',
  'medicalCaseId',
  'metaData',
  'semdType',
  'semdsDocumentDetailsAttributes',
  'title',
  'version',
  'owner',
]

export class MSemdModel<TMetaData, TSemdsDocumentDetailsAttributesData> extends ItemBase
  implements ISemd<TMetaData, TSemdsDocumentDetailsAttributesData> {
  static DEFAULT_VERSION = 1

  protected _permit = Array.from(_permitType)

  client: IHuman = null

  clinic: ICatalog = null

  date: TDateString = null

  doctorSigner: IHuman = null

  id: number = null

  medicalCaseId: number = null

  metaData: TMetaData = {} as TMetaData

  netrikaErrors: INetrikaError[] = []

  organizationSigner: IHuman = null

  owner: ISemdOwner = null

  semdType: number = null

  semdsDocumentDetailsAttributes: {
    data: TSemdsDocumentDetailsAttributesData
    id: number
  } = { id: null, data: {} as TSemdsDocumentDetailsAttributesData }

  state: string = SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE

  title: string = ''

  version: number = 1

  createdAt: TDateString = null

  createdBy: IHuman = null

  updatedAt: TDateString = null

  updatedBy: IHuman = null

  constructor (data?: ISemdLike<TMetaData, TSemdsDocumentDetailsAttributesData>) {
    super()
    if (!data) { return }

    this.fillProps(data)
  }

  fillProps (data?: ISemdLike<TMetaData, TSemdsDocumentDetailsAttributesData>) {
    this._fillProps(data)

    this.owner = data.owner
    this.state = data.state
    this.doctorSigner = data.doctorSigner
    this.organizationSigner = data.organizationSigner

    this.fillModelDBInfo(data)

    this.tryFillErrors(data.netrikaErrors)
  }

  fillModelDBInfo (data?: ISemdLike<TMetaData, TSemdsDocumentDetailsAttributesData>) {
    if (!data) { return }

    if (data.createdAt) {
      this.createdAt = vueFilters.dateTime(data.createdAt)
    }

    if (data.createdBy) {
      this.createdBy = data.createdBy
    }

    if (data.updatedAt) {
      this.updatedAt = vueFilters.dateTime(data.updatedAt)
    }

    if (data.updatedBy) {
      this.updatedBy = data.updatedBy
    }
  }

  isOutdatedVersion () {
    return this.state === SEMD_STATE_ENUM.OUTDATED_VERSION
  }

  isSigned () {
    return this.state !== SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE
  }

  isSuccessfullySent () {
    return this.state === SEMD_STATE_ENUM.SUCCESSFULLY_SENT
  }

  isRegisteredInEgisz () {
    return this.state === SEMD_STATE_ENUM.REGISTERED_IN_EGISZ
  }

  setValue<Keys extends typeof _permitType[number] | 'doctorSigner' | 'organizationSigner'> (key: Keys | string, value: unknown) {
    super.setValue(key, value)
  }

  setMetaDataValue <Keys extends keyof TMetaData> (key: Keys | string, value: unknown) {
    this.setValue(`metaData.${key as string}`, value)
  }

  setDocumentDetailsDataValue <Keys> (key: Keys | string, value: unknown) {
    this.setValue(`semdsDocumentDetailsAttributes.data.${key}`, value)
  }

  setDocumentDetailsId (id: number) {
    this.setValue('semdsDocumentDetailsAttributes.id', id)
  }

  setDoctorSigner (signer: IHuman) {
    this.setValue('doctorSigner', signer)
    this.setValue('state', SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE)
  }

  setOrganizationSigner (signer: IHuman) {
    this.setValue('organizationSigner', signer)
    this.setValue('state', SEMD_STATE_ENUM.READY_TO_BE_SENT)
  }

  setState (state: string) {
    if (!SEMD_STATES[state]) { return }
    this.setValue('state', state)
  }

  appendError (error: string, timestamp = null) {
    const errorObj = {
      error: tryExtractNetrikaError(error),
      timestamp: timestamp || getDateFormat(),
    }

    this.netrikaErrors.unshift(errorObj)
  }

  increaseVersion () {
    this.setValue('version', this.version + 1)
  }

  protected tryFillErrors (errors: INetrikaError[]) {
    if (!errors?.length) { return }

    this.setValue(
      'netrikaErrors',
      errors.map(netrikaErrorParser).reverse()
    )
  }
}
