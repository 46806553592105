/**
 * Миксин для валидации, используется для дочерних компонентов.
 *
 * Принцип работы:
 * при создании эмитит событие 'registerValidator' с объектом вида: { validatorName, validator }
 * validatorName - пропса, уникальное название для идентификации откуда пришло и куда отправлять ошибки
 * validator - метод, для проверки значения некому условию в пропсе required.
 *             В это методе происходит сброс или переустановка ошибок (validationErrors)
 *             и проверка value на соответствие условия в required.
 *             Если required: Boolean, то value проверяется на "непустоту", в т.ч. и для ссылочных типов.
 *             Если required: Function, то value подставляется в эту функции и проверяется по условию.
 *             Сигнатура функции: function (value): string[]
 *
 * Особенности валидации:
 * Для полей ввод на @blur необходимо добавить вызов __validate()
 *
 * Примеры работы можно посмотреть тут:
 * app/javascript/src/vue_present/_base/inputs/MInput/MInput.vue
 */
export const ValidationChildMixin = {
  props: {
    mFixedHeight: { type: Boolean, default: true },

    required: { type: [Boolean, Function], default: null },
    requiredWhenValue: Boolean,

    validatorName: { type: String, default: null }, /* обязательно, если есть required */

    value: {
      type: [
        Number,
        String,
        Boolean,
        Array,
        Object,
      ],
      default: null,
    },
  },

  emits: ['registerValidator'],

  data () {
    return {
      validationErrors: [],
    }
  },

  computed: {
    isRequiredField () {
      if (this.requiredWhenValue) { return false }

      return Boolean(this.required)
    },
  },

  watch: {
    required () {
      this.__sendValidatorToParent()
    },

    value (to) {
      if (!to) {
        if (this.requiredWhenValue) { return (this.validationErrors = []) }

        return
      }

      if (!this.validationErrors.length) { return }
      this.__validate()
    },
  },

  created () {
    this.__sendValidatorToParent()
  },

  methods: {
    __resetValidation (value = []) {
      this.validationErrors = value

      return 0
    },

    __sendValidatorToParent () {
      if (this.isRequiredField && !this.validatorName) {
        console.info(
          t('validatorNameNotFound'),
          this.$vnode.tag
        )

        return
      }

      const validator = {
        validatorName: this.validatorName,
        validator: this.registerValidator(),
      }

      this.$emit('registerValidator', validator)
    },

    __validate () {
      if (typeof this.required === 'function') {
        if (this.requiredWhenValue && !this.value) { return (this.validationErrors = []) }

        this.validationErrors = this.required(this.value)

        return
      }

      if (this.required) {
        if (this.requiredWhenValue && !this.value) { return (this.validationErrors = []) }

        this.validationErrors = Utils.getValidationErrors(this.value, {
          validZero: typeof this.value === 'number',
        })

        return
      }

      this.validationErrors = []
    },

    /**
     * Возвращаемая функция после валидации возвращает количество ошибок
     * @return {(function(*): (Number))}
     */
    registerValidator () {
      const that = this

      return (resetValidationValue) => {
        if (resetValidationValue) { return that.__resetValidation(resetValidationValue) }
        that.__validate()

        return that.validationErrors.length
      }
    },
  },
}
