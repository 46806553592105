<template>
  <si>
    <search-input
      :placeholder="t('egisz.components.egisz_cases_filters.search_ph')"
      :value="filters.search_string"
      @update:value="vxSetSearchValue"
    />

    <div
      v-loading="loading"
      class="filtered-table-loader"
    >
      <egisz-clients-table
        v-if="clientsList.length"
        :clients-list="clientsList"
        :loading-clients-ids="loadingClientsIds"
        :current-clinic-id.sync="currentClinicId"
        :can-manage="canManage"
        :can-user-send-to-egisz="canUserSendToEgisz"
        @submitClient="submitClient"
      />
      <not-found-result
        v-else
        :filtered="isFiltered"
        small
        @reset-filters="resetFilters"
      />

      <si-footer>
        <pagination
          v-if="clientsList.length"
          :current-page="currentPage"
          :page-count="totalPages"
          layout="prev, pager, next"
          @current-change="vxSetPage"
        />
      </si-footer>
    </div>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si'
import EgiszClientsTable from '@/vue_components/egisz/egisz_module/components/EgiszClients/egisz_clients_table'
import { mapGetters, mapMutations } from 'vuex'
import NotFoundResult from '@/vue_components/common/not_found_result'
import Pagination from '@/vue_components/common/pagination'
import SiFooter from '@/vue_components/sort_items/si_footer'
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { getDefaultClientsFilters } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import SearchInput from '@/vue_components/common/search_input'
import { egiszErrorsNotify, reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { tScoped } from '@/helpers/i18nHelpers'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { NETRIKA_TIMEOUT_ERROR_TEXT } from '@/modules/utils/errors'

const ts = tScoped('semds.validationErrors')

export default {
  name: 'EgiszClients',

  components: {
    SearchInput,
    NotFoundResult,
    EgiszClientsTable,
    Si,
    Pagination,
    SiFooter,
  },

  mixins: [SpinnerHolder],

  props: {
    canManage: Boolean,
  },

  data () {
    return {
      /**
       * @type {TEgiszClient[]}
       */
      clientsList: [],
      pageCount: 0,
      loading: false,

      loadingClientsIds: [],
      currentClinicId: null,
    }
  },

  computed: {
    ...mapGetters('egisz/clients/filtersBase', {
      filters: 'vxGetFilters',
      isFiltered: 'vxGetIsFiltered',
      currentPage: 'vxGetCurrentPage',
      totalPages: 'vxGetTotalPages',
    }),

    ...mapGetters('globalCatalogs/clinicStore', {
      getClinicLpuIdByClinicId: 'vxGetClinicInFrmo',
    }),

    ...mapGetters('egisz/user', {
      canUserSendToEgisz: 'vxGetCanUserSendToEgiszValue',
    }),

    ...mapGetters('egisz/egiszWs', { vxGetLastClient: 'vxGetLastClient' }),
  },

  watch: {
    filters: {
      immediate: true,
      handler () {
        this.getClientsList()
      },
    },

    vxGetLastClient (to) {
      if (!to?.id) { return }
      if (to?.action !== ENTITIES.ADD_PATIENT_FAILURE &&
          to?.action !== ENTITIES.ADD_PATIENT) {
        return
      }

      const client = this.clientsList
        .find((item) => item.id === to.id)

      if (to.action === ENTITIES.ADD_PATIENT) {
        client.registration_updated_at = Utils.getFormattedDate(to.created_at, Utils.shortDateTimeFormat)
        client.clinic_title = to?.clinic_title

        return
      }

      client.registration_status = ENTITIES.SENDING_ERROR

      if (to?.error === NETRIKA_TIMEOUT_ERROR_TEXT) {
        Notificator.error(ts('_timeoutErrorTemplate', {
          id: to.full_name.trim(),
          error: t('egisz.messages.errors.request.netrika_tcp_address'),
        }))

        return
      }

      Notificator.error(this.extractError(to))
    },
  },

  created () {
    this.$store.dispatch('globalCatalogs/clinicStore/vxLoadClinicsInFrmo')
  },

  methods: {
    ...mapMutations('egisz/clients/filtersBase', {
      vxResetFilters: 'vxResetFilters',
      vxSetTotalPages: 'vxSetTotalPages',
      vxSetPage: 'vxSetPage',
      vxSetSearchValue: 'vxSetSearchValue',
    }),

    getClientsList () {
      const promise = clientsEndpoint.getEgiszReady(this.filters)
        .then(({ data, total_pages: totalPages }) => {
          this.clientsList = data
          this.vxSetTotalPages(totalPages)
        })
        .catch((err) => {
          Utils.reportError(
            'EgiszClients:findClients()',
            reportErrorText('list_message', 'clients')
          )(err)
        })

      this.withSpinner(promise)
    },

    extractError (error = '') {
      try {
        const data = JSON.parse(error.error)

        return ts('_sendingErrorsTemplate', {
          entity: ts(data.entity),
          id: error.full_name.trim(),
          errorType: ts(data.error_type),
          value: ts(data.value),
          messages: data.messages.join(', '),
        })
      } catch (e) {
        return error
      }
    },

    submitClient ({ client, currentClinicId, update = false }) {
      this.loadingClientsIds.push(client.id)

      const data = {
        client_id: client.id,
        clinic_id: currentClinicId,
        clinic_lpu_id: this.getClinicLpuIdByClinicId(currentClinicId).lpu_id,
      }

      const action = update
        ? clientsEndpoint.updatePatient
        : clientsEndpoint.addPatient

      action(data)
        .then(() => {
          this.clientsList
            .find((item) => item.id === client.id)
            .registration_status = ENTITIES.SUCCESSFULLY_SENT

          const message = update
            ? t('egisz.messages.success.client_updated')
            : t('egisz.messages.success.client_registered')
          Notificator.info(message)
        })
        .catch(egiszErrorsNotify(
          'EgiszClients:registerClient()',
          reportErrorText('submit_message', 'clients')
        ))
        .finally(() => { this.loadingClientsIds = this.loadingClientsIds.filter((id) => id !== client.id) })
    },

    resetFilters () {
      this.vxResetFilters(getDefaultClientsFilters())
    },
  },
}
</script>
