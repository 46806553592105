<template>
  <div class="egisz-semds flex flex-grow-1 h-100">
    <m-si-generator
      class="h-100"
      :search-placeholder="t('egisz.fields.semd_search_placeholder')"
      :items="list.data"
      :search-query="list.searchQuery"
      :si-generator-schema="schema"
      :current-page="list.currentPage"
      :page-count="list.totalPages"
      :nfr-filtered="list.isFiltered"
      nfr-can-reset-filters
      @update:searchQuery="onListSearch"
      @update:currentPage="list.fetchPage($event)"
      @onSetSelectedItems="selectedItems = $event"
      @onItemClick="onItemClick"
      @onItemDelete="onItemDelete"
      @onResetClick="$pubSub.emit('egiszSemdsResetFilters')"
    >
      <template #filters>
        <slot name="filters" />
      </template>

      <template #search>
        <span v-if="!useSearch" />
      </template>

      <template
        v-if="useSelectableRows"
        #tableFooter
      >
        <slot name="tableFooter">
          <div class="flex gap-indent-small mt-indent-small">
            <m-button-delete
              v-if="false"
              :disabled="canMassDelete"
              :popover-message="t('delete_selected_items')"
              use-button
              size="mini"
              @yes="onMassDelete"
            />

            <reusable-sign-popover @onThumbprintSelect="addToMassSignQueue">
              <template #reference>
                <m-button
                  icon="signed"
                  :text="t('signByClinic')"
                  :disabled="cantMassSign"
                  size="mini"
                />

                <m-button
                  type="success"
                  :disabled="cantMassActions"
                  :text="t('egisz.register')"
                  icon="send"
                  size="mini"
                  :loading="massRegisterLoading"
                  @click="onMassRegisterClick"
                />

                <m-button-delete
                  :loading="massDeleteLoading"
                  use-button
                  :disabled="cantMassActions"
                  size="mini"
                  :popover-message="t('delete_selected_items')"
                  @yes="onMassDelete"
                />
              </template>
            </reusable-sign-popover>
          </div>
        </slot>
      </template>
    </m-si-generator>

    <semd-modal-app
      :use-semd-submit="false"
      :is-semds-empty="false"
      :is-last-semd-outdated="false"
      :is-new-source="false"
      :is-source-changed="false"
      :has-semd-type="true"
      @registerOpenSemd="openSemd = $event"
      @onSemdStateUpdate="updateSemdStatus($event.id, $event.state)"
    >
      <template #semdSource />
    </semd-modal-app>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getSchema } from '@/vue_components/egisz/egisz_module/components/EgiszSemds/const/getSchema'
import { MListService } from '@/_api/_requests/MListService'
import { getDefaultFilters } from '@/vue_components/egisz/egisz_module/components/EgiszSemds/getDefaultFilters'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import {
  EgiszSemdListItem,
} from '@/vue_components/egisz/egisz_module/components/EgiszSemds/entities/list/EgiszSemdListItem'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import ReusableSignPopover from '@/vue_present/Reuse/Sign/ReusableSignPopover.vue'
import { extractId } from '@/_medods_standart_library/msl'
import { SEMD_STATE_ENUM } from '@/vue_apps/Semds/SemdModal/const/const.js'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { GLOBAL_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import SemdModalApp from '@/vue_apps/Semds/SemdModal/SemdModalApp.vue'

export default defineComponent({
  name: 'EgiszSemds',
  components: {
    SemdModalApp,
    ReusableSignPopover,
    MButton,
    MSiGenerator,
    MButtonDelete,
  },

  mixins: [SpinnerHolder],

  props: {
    useSelectableRows: { type: Boolean, default: true },
    useSearch: { type: Boolean, default: true },
  },

  data () {
    return {
      schema: getSchema(this.useSelectableRows),
      list: new MListService(
        getDefaultFilters(),
        new MSemdPresenter(),
        { listItemEntity: EgiszSemdListItem }
      ),

      selectedItems: [],

      massRegisterLoading: false,
      massDeleteLoading: false,

      // eslint-disable-next-line
      openSemd ({ id, source: { semdType } }) {},
    }
  },

  computed: {
    isSelectedEmpty () {
      const disabled = !this.selectedItems?.length

      return {
        disabled,
        tooltip: disabled && t('treatmentPlansEntriesTransfer.nothingSelected'),
      }
    },

    canMassDelete () {
      if (this.$security.canManageEgisz) {
        return 'Egisz'
      }

      return this.isSelectedEmpty
    },

    cantMassSign () {
      if (!this.$security.canManageEgisz || !this.$security.canManageOrganizationSign) {
        return { disabled: true, tooltip: t('insufficient_access_rights') }
      }

      return this.cantMassActions
    },

    cantMassActions () {
      if (!this.$security.canManageEgisz) {
        return 'Egisz'
      }

      return this.isSelectedEmpty
    },
  },

  created () {
    this.$pubSub.subscribe('egiszSemdsUpdateFilters', (filters) => {
      this.list.resetFilters(filters)
      this.list.fetchPage(1)
    })

    Services.wsSubscriptions.egisz.connect(({ data, action }) => {
      switch (action) {
        case ENTITIES.SENDING_ERROR: return this.updateSemdStatus(data?.id, SEMD_STATE_ENUM.SENDING_ERROR)
        case ENTITIES.ADDED_TO_QUEUE: return this.onAddedToQueue(data)
        case ENTITIES.SUCCESSFULLY_SENT: return this.onSuccessfullySent(data?.id)
        case ENTITIES.REGISTERED_IN_EGISZ: return this.onRegisteredInEgisz(data?.id)
        case ENTITIES.MASS_SIGN: return this.onMassSign(data?.ids)
        case ENTITIES.ALL_SIGNATURES_REMOVED: return this.updateSemdStatus(data?.id, SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE)
        case ENTITIES.ORGANIZATION_SIGNATURES_REMOVED: return this.updateSemdStatus(data?.id, SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE)
      }
    })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll('egiszSemdsUpdateFilters')
  },

  methods: {
    updateSemdStatus (id, status) {
      if (!id || !status) { return }
      /**  @type {EgiszSemdListItem} */
      const item = this.list.data.find((item) => item.id === id)
      if (!item) { return }
      item.setStatus(status)
    },

    onListSearch (query) {
      if (!query) {
        this.$pubSub.emit('egiszSemdsResetFilters')

        return
      }
      this.list.search(query)
    },

    onAddedToQueue (id) {
      this.updateSemdStatus(id, SEMD_STATE_ENUM.ADDED_TO_QUEUE)
    },

    onSuccessfullySent (id) {
      this.updateSemdStatus(id, SEMD_STATE_ENUM.SUCCESSFULLY_SENT)
    },

    onRegisteredInEgisz (id) {
      this.updateSemdStatus(id, SEMD_STATE_ENUM.REGISTERED_IN_EGISZ)
    },

    async onMassRegisterClick () {
      const selectedIds = this.selectedItems
        .filter((item) =>
          item.rawStatus === SEMD_STATE_ENUM.READY_TO_BE_SENT ||
          item.rawStatus === SEMD_STATE_ENUM.SENDING_ERROR
        )
        .map(extractId)

      if (!selectedIds.length) {
        Notificator.info(t('semds.errors.noSemdsForRegistration'))

        return
      }

      await this.withSpinner(new MSemdPresenter()
        .massRegistration(selectedIds), 'massRegisterLoading')

      selectedIds.forEach((id) => this.onAddedToQueue(id))
    },

    async onMassDelete () {
      const selectedIds = this.selectedItems
        .filter((item) => item.__deletable)
        .map(extractId)

      if (!selectedIds.length) {
        Notificator.info(t('semds.errors.noSemdsForDeleting'))

        return
      }

      await this.withSpinner(new MSemdPresenter()
        .massDelete(selectedIds), 'massDeleteLoading')

      setTimeout(() => this.list.fetchAll(), GLOBAL_DEBOUNCE_DEFAULT)
    },

    onMassSign (ids = []) {
      if (!ids) { return }
      Utils.reportSuccess(`Подписано документов: ${ids.length}`)()

      ids.forEach((id) => this.updateSemdStatus(id, SEMD_STATE_ENUM.READY_TO_BE_SENT))
    },

    async addToMassSignQueue (signature) {
      return await new MSemdPresenter()
        .massSignByClinic({ ids: this.selectedItems.map(extractId), signature })
    },

    /**
     * @param {EgiszSemdListItem} semdListItem
     * @returns {Promise<void>}
     */
    async onItemClick (semdListItem) {
      this.openSemd({
        id: semdListItem.id,
        source: { semdType: semdListItem.rawSemdType },
      })
    },

    /** @param {EgiszSemdListItem} semd */
    async onItemDelete (semd) {
      await new MSemdPresenter().destroy(semd.id)
      await this.list.fetchAll()
    },
  },
})
</script>
