import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { DateHelpers } from '@/helpers/DateHelpers'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class ExpensesListCellItem extends MListServiceItem {
  constructor (rawData) {
    super()
    this.id = rawData.id

    this.expenseGroupId = rawData.expenseGroup?.id

    this.date = { value: rawData.date }

    this.number = new MTableCell(rawData.id)

    const date = DateHelpers.getTodayTomorrowYesterday(rawData.date)

    this.formattedDate = new MTableCell(date.formattedDate).addClasses(date.dateCss)

    this.title = new MTableCell(rawData.title).addTooltip(rawData.title)

    this.price = new MTableCell(rawData.price)

    this.additional = new MTableCell(rawData.additional).addTooltip(rawData.additional)

    this.expenseGroup = new MTableCell(rawData.expenseGroup.title)

    this.orderId = rawData.orderId
      ? new MTableCell(rawData.orderId)
        .addTypeUrl(Routes.order_path(rawData.orderId))
      : new MTableCell()
  }
}
