import { FastCreateModel } from '@/vue_apps/CheckupsModule/classes/FastCreate/FastCreateModel'
import { FastCreatePresenter } from '@/vue_apps/CheckupsModule/api/FastCreatePresenter/FastCreatePresenter'

export class FastCreateApi extends FastCreateModel {
  _presenter = new FastCreatePresenter()

  _submit (clientKeys: string[]) {
    return this._presenter.submit(this.getAttributes(clientKeys))
  }
}
