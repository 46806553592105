<template>
  <div>
    <h1>MCheckbox</h1>
    <div class="flex flex-column">
      <m-checkbox
        id="checkboxSimple"
        v-model="checkboxSimple"
        label="Просто чекбокс"
      />

      <m-checkbox
        id="checkboxDisabled"
        label="Заблокированный чекбокс"
        disabled
      />

      <m-checkbox
        id="checkboxIndeterminate"
        label="Непонятный чекбокс"
        indeterminate
      />

      <div>
        <m-checkbox
          id="checkboxDifferent"
          v-model="checkboxDifferent"
          label="Чекбокс с не Boolean value"
          true-label="Я включен"
          false-label="Я выключен"
        />

        <span> {{ checkboxDifferent }} </span>
      </div>
    </div>

    <h2>Данные</h2>
    <h3>Вход</h3>
    <p>value: Boolean | String</p>
    <h3>Событие</h3>
    <p>@input</p>
    <p>@update:value</p>
  </div>
</template>

<script>
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox'

export default {
  name: 'FrontBookPageMCheckbox',
  components: { MCheckbox },
  data () {
    return {
      checkboxSimple: false,
      checkboxDifferent: null,
    }
  },
}
</script>
