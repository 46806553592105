
export const CLINICS_TABS = Object.freeze({
  MAIN: 'main',
  ADDRESS: 'address',
  LICENSE: 'license',
  MESSAGES: 'messages',
  ONLINE_ACCESS: 'online-access',
  LOGOTYPES: 'logotypes',
  EGISZ: 'egisz',
})
