import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  IPersonalElmkMedicalAssessment,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessment'
import {
  IPersonalElmkMedicalAssessmentDoctorResults,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessmentDoctorResults'
import {
  IPersonalElmkMedicalAssessmentResearch,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessmentResearch'
import {
  IPersonalElmkMedicalAssessmentVaccinations,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessmentVaccinations'
import { IDate } from '@/_declarations/IDate'
import { SEMD_TYPE, SEMD_TYPE_ENUM } from '@/vue_apps/Semds/const/semdTypes'
import {
  ISemd230SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd230/ISemd230SemdsDocumentDetailsAttributesData'

export class PersonalElmkMedicalAssessmentModel extends ItemBase {
  _permit = [
    'actual',
    'id',
    'checkupDate',
    'checkupDateText',
    'checkupType',
    'checkupTypeText',
    'doctorResultsTableData',
    'doctorResultsTableHeader',
    'doctorResultsTableTitle',
    'footerDate',
    'footerName',
    'footerText',
    'headerClinicAddress',
    'headerClinicEmail',
    'headerClinicLegalName',
    'headerClinicLicense',
    'headerClinicLicenseIssueDate',
    'headerClinicPhone',
    'headerText',
    'headerClinicId',
    'infectiousDiseasesData',
    'infectiousDiseasesText',
    'instrumentalResearchesTableData',
    'instrumentalResearchesTableHeader',
    'instrumentalResearchesTableTitle',
    'laboratoryResearchesTableData',
    'laboratoryResearchesTableHeader',
    'laboratoryResearchesTableTitle',
    'medicalAssessmentId',
    'medicalAssessmentText',
    'medicalAssessmentTitle',
    'medicalCardNumber',
    'medicalCardNumberText',
    'position',
    'positionFactorId',
    'positionFactorText',
    'positionFactorTitle',
    'structuralDepartment',
    'structuralDepartmentText',
    'titleDate',
    'titleText',
    'vaccinationsTableData',
    'vaccinationsTableHeader',
    'vaccinationsTableTitle',
    'workerFullName',
    'workerFullNameText',
    'semdType',
    'semdsDocumentDetailsAttributes',
    'newSemdApproved',
    'newSignatureApproved',
  ]

  actual: boolean = true

  id: number

  checkupDate: string

  checkupDateText: string

  checkupType: string

  checkupTypeText: string

  doctorResultsTableData: Array<
    Pick<
      IPersonalElmkMedicalAssessmentDoctorResults,
      'specialtyTitle' | 'date' | 'medicalAssessmentTitle'
    >
  >

  doctorResultsTableHeader: string[]

  doctorResultsTableTitle: string

  footerDate: string

  footerName: string

  footerText: string

  headerClinicAddress: string

  headerClinicEmail: string

  headerClinicLegalName: string

  headerClinicLicense: string

  headerClinicLicenseIssueDate: string | null

  headerClinicPhone: string

  headerText: string

  headerClinicId: number

  infectiousDiseasesData: string

  infectiousDiseasesText: string

  instrumentalResearchesTableData: Array<
    Pick<
      IPersonalElmkMedicalAssessmentResearch,
      'researchTitle' | 'date' | 'medicalAssessmentTitle'
    >
  >

  instrumentalResearchesTableHeader: string[]

  instrumentalResearchesTableTitle: string

  laboratoryResearchesTableData: Array<
    Pick<
      IPersonalElmkMedicalAssessmentResearch,
      'researchTitle' | 'date' | 'medicalAssessmentTitle'
    >
  >

  laboratoryResearchesTableHeader: string[]

  laboratoryResearchesTableTitle: string

  medicalAssessmentId: string | null

  medicalAssessmentText: string

  medicalAssessmentTitle: string

  medicalCardNumber: string

  medicalCardNumberText: string

  personalCheckupId: number

  position: string

  positionFactorId: number | null

  positionFactorText: string

  positionFactorTitle: string | null

  semdType: number = SEMD_TYPE_ENUM[SEMD_TYPE.ELMK_MEDICAL_ASSESSMENT]

  semdsDocumentDetailsAttributes = {
    id: null,
    data: {} as ISemd230SemdsDocumentDetailsAttributesData,
  }

  structuralDepartment: string

  structuralDepartmentText: string

  titleDate: IDate

  titleText: string

  vaccinationsTableData: IPersonalElmkMedicalAssessmentVaccinations[]

  vaccinationsTableHeader: string[]

  vaccinationsTableTitle: string

  workerFullName: string

  workerFullNameText: string

  newSemdApproved: boolean = false

  newSignatureApproved: boolean = false

  constructor (props?: Partial<IPersonalElmkMedicalAssessment>) {
    super()

    this.fillProps(props)
  }

  fillProps (props?: Partial<IPersonalElmkMedicalAssessment>) {
    this._fillProps(props)
    if (props && Object.hasOwn(props, 'actual')) {
      this.actual = props.actual
    }

    if (props && Object.hasOwn(props, 'personalCheckupId')) {
      this.personalCheckupId = props.personalCheckupId
    }
  }
}
