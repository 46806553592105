import { MListService } from '@/_api/_requests/MListService'
import {
  MGuaranteeLetterPresenter,
} from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/MGuaranteeLetterPresenter'
import {
  GuaranteeLetterListItem,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/list/GuaranteeLetterListItem'

export const getGuaranteeLettersList = (medicalPolicyId) => new MListService(
  { medicalPolicyId },
  new MGuaranteeLetterPresenter(),
  { listItemEntity: GuaranteeLetterListItem }
)
