<template>
  <div class="v-company-form">
    <epic-spinner :visibility="spinner" />
    <div class="box">
      <flex-form
        class="main-info"
        :fields="flexForms.commonInfo"
      >
        <template #suggestion-value>
          <dadata-select
            type="PARTY"
            :disabled="disabled || targetedDisable.title"
            :placeholder="t('search_by_title_inn_or_ogrn')"
            @change="setCompany"
          >
            <template #default="{ optionsList }">
              <el-option
                v-for="option in optionsList"
                :key="option.data.hid"
                :value="option"
                :label="option.unrestricted_value"
              />
            </template>
          </dadata-select>
        </template>
        <template #title-value>
          <validation-wrapper
            :errors="validationMessages.title"
          >
            <input
              class="form-control"
              type="text"
              :value="title"
              :disabled="disabled || targetedDisable.title"
              @input="$updateSync('title', $event.target.value)"
            >
          </validation-wrapper>
        </template>

        <template #company-types-value>
          <validation-wrapper
            :errors="validationMessages.types || validationMessages.company_types"
          >
            <items-list
              id-item-field="id"
              displayed-item-field="title"
              :items="types"
              :min-selected-items="1"
              :multi-select="true"
              :selected-items-ids="typeIds"
              :disabled="disabled || targetedDisable.types"
              :class="['company-types-list', {loading: spinnerTypes}]"
              @change="$updateSync('typeIds', $event)"
            />
          </validation-wrapper>
        </template>

        <template #ownership-value>
          <input
            class="form-control"
            type="text"
            :value="ownership"
            :disabled="disabled || targetedDisable.ownership"
            @input="$updateSync('ownership', $event.target.value)"
          >
        </template>

        <template #inn-value>
          <validation-wrapper :errors="validationMessages.inn || validationMessages.individual_tax_number">
            <input
              class="form-control"
              type="text"
              :value="inn"
              :disabled="disabled || targetedDisable.inn"
              @input="$updateSync('inn', $event.target.value)"
            >
          </validation-wrapper>
        </template>

        <template #kpp-label>
          {{ t('kpp') }}
          <abbr
            v-if="isKppRequired"
            v-tooltip="t('activerecord.required.text')"
          >
            *
          </abbr>
        </template>

        <template #kpp-value>
          <validation-wrapper :errors="validationMessages.kpp">
            <input
              class="form-control"
              type="text"
              :value="kpp"
              :disabled="disabled || targetedDisable.kpp"
              @input="$updateSync('kpp', $event.target.value)"
            >
          </validation-wrapper>
        </template>

        <template #ogrn-value>
          <input
            class="form-control"
            type="text"
            :value="ogrn"
            :disabled="disabled || targetedDisable.ogrn"
            @input="$updateSync('ogrn', $event.target.value)"
          >
        </template>

        <template #okved-value>
          <input
            class="form-control"
            type="text"
            :value="okved"
            :disabled="disabled || targetedDisable.okved"
            @input="$updateSync('okved', $event.target.value)"
          >
        </template>

        <template #personal-discount-value>
          <percent-input
            class="form-control"
            type="text"
            :value="discount"
            :disabled="disabled"
            @input="$updateSync('discount', $event)"
          />
        </template>

        <template #registration-date-value>
          <date-input
            class="form-control"
            type="text"
            :value="registrationDate"
            :disabled="disabled || targetedDisable.registrationDate"
            @input="$updateSync('registrationDate', $event)"
          />
        </template>

        <template #note-value>
          <input
            class="form-control"
            type="text"
            :value="note"
            :disabled="disabled || targetedDisable.additional"
            @input="$updateSync('note', $event.target.value)"
          >
        </template>
      </flex-form>
    </div>
    <div class="box form-tabs">
      <tabs
        type="card"
        :class="formStyle"
        :value="activeTab"
        @input="$updateSync('activeTab', $event)"
      >
        <el-tab-pane
          :name="TAB_ADDRESS_FORMAL"
          :label="t('address_formal')"
        >
          <flex-form
            :fields="flexForms.tabs.addressFormal"
          >
            <template #match-formal-value>
              <input
                v-model="matchesForm"
                class="checkbox"
                type="checkbox"
                @input="copyAddress('formalAddress', 'factAddress')"
              >
            </template>

            <template #index-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressIndex"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressIndex', 'factAddressIndex', $event)"
              >
            </template>

            <template #country-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressCountry"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressCountry', 'factAddressCountry', $event)"
              >
            </template>

            <template #region-value>
              <reusable-global-n-s-i-list
                v-if="useNSIAreas"
                :value="formalNsiRussianSubjectId"
                :placeholder="t('region')"
                full-width
                dictionary="russian_subjects"
                @change="inputAddressHandler('formalNsiRussianSubjectId', 'factNsiRussianSubjectId', { target: { value: $event } })"
                @changeItem="inputAddressHandler('formalAddressRegion', 'factAddressRegion', { target: { value: $event && $event.title } })"
              />
              <input
                v-else
                class="form-control"
                type="text"
                :value="formalAddressRegion"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressRegion', 'factAddressRegion', $event)"
              >
            </template>

            <template #area-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressArea"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressArea', 'factAddressArea', $event)"
              >
            </template>

            <template #city-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressCity"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressCity', 'factAddressCity', $event)"
              >
            </template>

            <template #street-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressStreet"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressStreet', 'factAddressStreet', $event)"
              >
            </template>

            <template #house-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressHouse"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressHouse', 'factAddressHouse', $event)"
              >
            </template>

            <template #flat-value>
              <input
                class="form-control"
                type="text"
                :value="formalAddressFlat"
                :disabled="disabled"
                @input="inputAddressHandler('formalAddressFlat', 'factAddressFlat', $event)"
              >
            </template>
          </flex-form>
        </el-tab-pane>

        <el-tab-pane
          :name="TAB_ADDRESS_FACT"
          :label="t('address_fact')"
        >
          <flex-form
            :fields="flexForms.tabs.addressFact"
          >
            <template #match-fact-value>
              <input
                v-model="matchesForm"
                class="checkbox"
                type="checkbox"
                @input="copyAddress('factAddress', 'formalAddress')"
              >
            </template>
            <template #index-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressIndex"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressIndex', 'formalAddressIndex', $event)"
              >
            </template>
            <template #country-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressCountry"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressCountry', 'formalAddressCountry', $event)"
              >
            </template>
            <template #region-value>
              <reusable-global-n-s-i-list
                v-if="useNSIAreas"
                :value="factNsiRussianSubjectId"
                :placeholder="t('region')"
                full-width
                dictionary="russian_subjects"
                @change="inputAddressHandler('factNsiRussianSubjectId', 'formalNsiRussianSubjectId', { target: { value: $event } })"
                @changeItem="inputAddressHandler('factAddressRegion', 'formalAddressRegion', { target: { value: $event && $event.title } })"
              />
              <input
                v-else
                class="form-control"
                type="text"
                :value="factAddressRegion"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressRegion', 'formalAddressRegion', $event)"
              >
            </template>

            <template #area-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressArea"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressArea', 'formalAddressArea', $event)"
              >
            </template>

            <template #city-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressCity"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressCity', 'formalAddressCity', $event)"
              >
            </template>

            <template #street-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressStreet"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressStreet', 'formalAddressStreet', $event)"
              >
            </template>

            <template #house-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressHouse"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressHouse', 'formalAddressHouse', $event)"
              >
            </template>

            <template #flat-value>
              <input
                class="form-control"
                type="text"
                :value="factAddressFlat"
                :disabled="disabled"
                @input="inputAddressHandler('factAddressFlat', 'formalAddressFlat', $event)"
              >
            </template>
          </flex-form>
        </el-tab-pane>

        <el-tab-pane
          :name="TAB_CONTACTS"
          :label="t('contacts')"
        >
          <flex-form
            :fields="flexForms.tabs.contacts"
          >
            <template #phone1-value>
              <validation-wrapper :errors="validationMessages.phone1">
                <phone-input
                  class="form-control"
                  type="text"
                  :value="phone1"
                  :disabled="disabled"
                  @input="$updateSync('phone1', $event)"
                />
              </validation-wrapper>
            </template>

            <template #phone2-value>
              <validation-wrapper :errors="validationMessages.phone2">
                <phone-input
                  class="form-control"
                  type="text"
                  :value="phone2"
                  :disabled="disabled"
                  @input="$updateSync('phone2', $event)"
                />
              </validation-wrapper>
            </template>

            <template #web-value>
              <input
                class="form-control"
                type="text"
                :value="web"
                :disabled="disabled"
                @input="$updateSync('web', $event.target.value)"
              >
            </template>

            <template #email-value>
              <input
                class="form-control"
                type="text"
                :value="email"
                :disabled="disabled"
                @input="$updateSync('email', $event.target.value)"
              >
            </template>
          </flex-form>
        </el-tab-pane>

        <el-tab-pane
          :name="TAB_HEAD"
          :label="t('head')"
        >
          <flex-form
            :fields="flexForms.tabs.head"
          >
            <template #head-surname-value>
              <input
                class="form-control"
                type="text"
                :value="headSurname"
                :disabled="disabled"
                @input="$updateSync('headSurname', $event.target.value)"
              >
            </template>

            <template #head-name-value>
              <input
                class="form-control"
                type="text"
                :value="headName"
                :disabled="disabled"
                @input="$updateSync('headName', $event.target.value)"
              >
            </template>

            <template #head-second-name-value>
              <input
                class="form-control"
                type="text"
                :value="headSecondName"
                :disabled="disabled"
                @input="$updateSync('headSecondName', $event.target.value)"
              >
            </template>

            <template #head-position-value>
              <input
                class="form-control"
                type="text"
                :value="headPosition"
                :disabled="disabled"
                @input="$updateSync('headPosition', $event.target.value)"
              >
            </template>

            <template #head-operates-by-value>
              <input
                class="form-control"
                type="text"
                :value="headOperatesBy"
                :disabled="disabled"
                @input="$updateSync('headOperatesBy', $event.target.value)"
              >
            </template>
          </flex-form>
        </el-tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash/string'
import DateInput from '@/vue_components/common/date_input.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'
import ItemsList from '@/vue_components/items_list.vue'
import PercentInput from '@/vue_components/common/percent_input.vue'
import PhoneInput from '@/vue_components/common/phone_input.vue'
import Tabs from '@/vue_components/common/tabs.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

import props from './props.js'
import { TAB_ADDRESS_FACT, TAB_ADDRESS_FORMAL, TAB_COMPANY, TAB_CONTACTS, TAB_HEAD, tabsAddress } from './consts.js'
import DadataSelect from '@/vue_components/common/select/dadata_select/dadata_select'
import formatter from '@/lib/formatters/formatter'
import { createPhoneValidator, notEmptyValidator, requiredValidator } from '@/lib/validators/validators'
import { concatHouseWithBlock } from '@/vue_present/Reuse/CommonAddressForm/helpers'
import ReusableGlobalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableGlobalNSIList.vue'
import { mapGetters } from 'vuex'
import { ValidationHolder } from '@/vue_components/mixins/validationHolder'
import { INSURANCE_COMPANY_TYPE_ID } from '@/vue_components/companies/components/consts'

export default {
  name: 'CompanyForm',

  components: {
    ReusableGlobalNSIList,
    DadataSelect,
    DateInput,
    EpicSpinner,
    FlexForm,
    ItemsList,
    PercentInput,
    PhoneInput,
    Tabs,
    ValidationWrapper,
  },

  mixins: [ValidationHolder],

  props,

  data () {
    return {
      matchesForm: false,
      companyPhoneMaskSettings: { ...Services.phoneMask.defaultMaskSettings },
      flexForms: {
        commonInfo: [
          { name: 'suggestion', label: '' },
          { name: 'title', label: t('title'), required: true },
          { name: 'company-types', label: t('company_types'), required: true },
          { name: 'ownership', label: t('type_of_company') },
          { name: 'inn', label: t('inn') },
          { name: 'kpp', label: t('kpp') },
          { name: 'ogrn', label: t('ogrn') },
          { name: 'okved', label: t('okved') },
          { name: 'personal-discount', label: t('personal_discount') },
          { name: 'registration-date', label: t('registration_date') },
          { name: 'note', label: t('additional') },
        ],
        tabs: {
          addressFormal: [
            { name: 'match-formal', label: t('matches_fact_address') },
            ...tabsAddress,
          ],
          addressFact: [
            { name: 'match-fact', label: t('matches_formal_address') },
            ...tabsAddress,
          ],
          contacts: [
            { name: 'phone1', label: t('phone1') },
            { name: 'phone2', label: t('phone2') },
            { name: 'web', label: t('web') },
            { name: 'email', label: t('email') },
          ],
          head: [
            { name: 'head-surname', label: t('head_surname') },
            { name: 'head-name', label: t('head_name') },
            { name: 'head-second-name', label: t('head_second_name') },
            { name: 'head-position', label: t('head_position') },
            { name: 'head-operates-by', label: t('head_operates_by') },
          ],
        },
      },
      TAB_ADDRESS_FACT,
      TAB_ADDRESS_FORMAL,
      TAB_CONTACTS,
      TAB_COMPANY,
      TAB_HEAD,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/nsiStore/russianSubjectsDictionaryStore', {
      getRussianSubject: 'vxGetRussianSubjectMethod',
    }),

    useNSIAreas () {
      return gon.application.nsi.russianSubjectsDictionary
    },

    isKppRequired () {
      return this.typeIds.includes(INSURANCE_COMPANY_TYPE_ID)
    },
  },

  watch: {
    title (newValue) {
      this.$updateSync('validationMessages', {
        ...this.validationMessages,
        title: requiredValidator(newValue),
      })
    },

    typeIds (newValue) {
      this.$updateSync('validationMessages', {
        ...this.validationMessages,
        company_types: notEmptyValidator(newValue),
      })

      if (newValue.includes(INSURANCE_COMPANY_TYPE_ID)) {
        this.kppValidator(true)

        return
      }

      this.kppValidator()
    },

    kpp (newValue) {
      if (this.isKppRequired && !newValue) {
        this.kppValidator(true)

        return
      }

      this.kppValidator()
    },

    phone1 (newValue) {
      if (newValue) {
        this.validate('phone1', newValue,
          createPhoneValidator(
            this.companyPhoneMaskSettings.length,
            t('telephony.uis.errors.wrong_phone_number')
          )
        )

        return
      }
      this.removeValidationMessage('phone1', t('telephony.uis.errors.wrong_phone_number'))
    },

    phone2 (newValue) {
      if (newValue) {
        this.validate('phone2', newValue,
          createPhoneValidator(
            this.companyPhoneMaskSettings.length,
            t('telephony.uis.errors.wrong_phone_number')
          )
        )

        return
      }
      this.removeValidationMessage('phone2', t('telephony.uis.errors.wrong_phone_number'))
    },
  },

  methods: {
    inputAddressHandler (targetAttr, matchedAttr, event) {
      this.$updateSync(targetAttr, event.target.value)
      if (this.matchesForm) {
        this.$updateSync(matchedAttr, event.target.value)
      }
    },

    kppValidator (validate = false) {
      this.$updateSync('validationMessages', {
        ...this.validationMessages,
        kpp: validate ? notEmptyValidator(this.kpp) : [],
      })
    },

    /**
     * @param {'formalAddress'|'factAddress'} from
     * @param {'formalAddress'|'factAddress'} to
     */
    copyAddress (from, to) {
      tabsAddress.forEach(({ name: part }) => {
        this.setAddressPart(to, part, this[this.getAddressPartName(from, part)])
      })

      // отдельное копирование для nsi
      this.useNSIAreas && to === 'formalAddress'
        ? this.$updateSync('formalNsiRussianSubjectId', this.factNsiRussianSubjectId)
        : this.$updateSync('factNsiRussianSubjectId', this.formalNsiRussianSubjectId)
    },

    /**
     * @param {Record<string, any> | null} suggestion
     */
    setCompany (suggestion) {
      if (!suggestion) {
        return
      }

      const { data } = suggestion
      this.$updateSync('title', data.name.full_with_opf)
      this.$updateSync('ownership', (data.opf && data.opf.short) || '')
      this.$updateSync('inn', data.inn || '')
      this.$updateSync('kpp', data.kpp || '')
      this.$updateSync('ogrn', data.ogrn || '')
      this.$updateSync('okved', data.okved || '')
      this.$updateSync(
        'registrationDate',
        formatter.formatValue(data.ogrn_date, ['date:localDate'])
      )
      this.$updateSync('note', '')

      if (data.address?.data) {
        const address = data.address.data
        const houseWithBlock = {
          house: address.house,
          blockTypeFull: address.block_type_full,
          block: address.block,
        }

        this.$updateSync('formalAddressIndex', address.postal_code || '')
        this.$updateSync('formalAddressCountry', address.country || '')
        this.$updateSync('formalAddressRegion', address.region_with_type || '')
        this.$updateSync('formalAddressArea', address.city_area || address.area_with_type || '')
        this.$updateSync('formalAddressCity', address.city_with_type || address.settlement_with_type || '')
        this.$updateSync('formalAddressStreet', address.street_with_type || '')
        this.$updateSync('formalAddressHouse', concatHouseWithBlock(houseWithBlock) || '')
        this.$updateSync('formalAddressFlat', address.flat || '')
        this.$updateSync('formalNsiRussianSubjectId', this.getRussianSubject(address.region)?.id || null)

        tabsAddress.forEach(({ name }) => {
          this.setAddressPart('factAddress', name, '')
        })
      } else {
        tabsAddress.forEach(({ name }) => {
          this.setAddressPart('formalAddress', name, '')
          this.setAddressPart('factAddress', name, '')
        })
      }

      const fio = data.management ? data.management.name.split(' ') : []

      this.$updateSync('headSurname', fio[0] || '')
      this.$updateSync('headName', fio[1] || '')
      this.$updateSync('headSecondName', fio[2] || '')
      this.$updateSync('headPosition', (data.management && data.management.post) || '')
      this.$updateSync('headOperatesBy', '')
    },

    /**
     * @param {'formalAddress'|'factAddress'} addressForm
     * @param {string} part
     * @param {string} value
     */
    setAddressPart (addressForm, part, value) {
      this.$updateSync(this.getAddressPartName(addressForm, part), value)
    },

    /**
     * @param {'formalAddress'|'factAddress'} addressForm
     * @param {string} part
     * @return {string}
     */
    getAddressPartName (addressForm, part) {
      return `${addressForm}${capitalize(part)}`
    },
  },
}
</script>
