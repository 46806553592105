import { IClientAdapter, TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'
import { wrapAPIError } from '@/_api/decorators/api/helpers/wrapAPIError'
import { IResponse } from '@/_api/decorators/api/interfaces/IResponse'

export function ClientAdapter<Data = unknown, AdaptedData = unknown> (adapter: IClientAdapter<Data, AdaptedData> | TAdapter<Data, AdaptedData> | string) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return async function (this: This, ...args: Args): Promise<Awaited<Return>> {
      const call = target.call(this, ...args)
      const result = await wrapAPIError(call)

      if (typeof result !== 'object' || (result as IResponse).errors) {
        return result as Awaited<Return>
      }

      return (
        typeof adapter === 'function'
          ? adapter(result as Data)
          : typeof adapter === 'string'
            ? this[adapter](result as Data)
            : adapter.toClient(result as Data)
      ) as Awaited<Return>
    }
  }
}
