<template>
  <div class="clinic-messages-tab pt-6">
    <!-- Название клиники для рассылки сообщений -->
    <m-input
      :label="t('whatsapp.messageTitle')"
      :value="clinic.messageTitle"
      :disabled="disabled"
      @input="clinic.setValue('messageTitle', $event)"
    />

    <!-- Адрес клиники для рассылки сообщений -->
    <m-input
      :label="t('whatsapp.messageAddress')"
      :value="clinic.messageAddress"
      :disabled="disabled"
      @input="clinic.setValue('messageAddress', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'

export default defineComponent({
  name: 'ClinicMessagesTab',
  components: { MInput },
  props: {
    clinic: { type: Clinic, required: true },
    disabled: { type: Boolean, default: false },
  },
})
</script>
