<template>
  <si v-loading="loading">
    <egisz-cases-search />
    <egisz-cases-table
      v-if="medCasesList.length"
      :cases-list="medCasesList"
      :loading-cases-ids="loadingCasesIds"
      :can-manage="canManage"
      @selectMedCase="selectMedCase"
    />
    <not-found-result
      v-else
      :filtered="isFiltered"
      small
      @reset-filters="resetFilters"
    />

    <si-footer>
      <pagination
        :current-page="currentPage"
        :page-count="totalPages"
        layout="prev, pager, next"
        @current-change="vxSetPage"
      />
    </si-footer>

    <modal
      :modal-visibility="Boolean(selectedMedCase)"
      modal-size="md"
      @close="selectMedCase(null)"
    >
      <template #header>
        <i class="fad fa-file-certificate" />
        <span>{{ t('egisz.components.egisz_cases.sign_register_modal.title') }}</span>
      </template>

      <template #body>
        <egisz-med-case-med-documents-table
          v-loading="medCaseMedDocumentsLoading"
          :med-documents="medCaseMedDocuments"
        />
      </template>

      <template #footer-left>
        <sign-popover
          :loading="signatureLoading"
          :disable-confirm-btn="!(signature.certificateId)"
          require-organization-sign
          :disabled="!canManage"
          @yes="signMedDocuments"
        >
          <template #body>
            <sign-popover-view
              v-bind.sync="signature"
              use-resign
            />
          </template>
        </sign-popover>
      </template>

      <template #footer-right>
        <close-button @close="selectMedCase(null)" />
      </template>
    </modal>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si'
import EgiszCasesTable from '@/vue_components/egisz/egisz_module/components/EgiszCases/egisz_cases_table'
import { mapGetters, mapMutations } from 'vuex'
import NotFoundResult from '@/vue_components/common/not_found_result'
import Pagination from '@/vue_components/common/pagination'
import SiFooter from '@/vue_components/sort_items/si_footer'
import { egiszCasesEndpoint } from '@/api_entities/egisz/cases/egisz_cases_endpoint'
import EgiszCasesSearch from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_search'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

import Modal from '@/vue_components/modal'
import { medDocumentEndpoint } from '@/api_entities/egisz/med_documents/med_document_endpoint'
import EgiszMedCaseMedDocumentsTable
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/EgiszMedCaseMedDocumentsTable'
import CloseButton from '@/vue_components/common/buttons/CloseButton'
import { cloneDeep } from 'lodash'
import SignPopover from '@/vue_components/SignPopover/SignPopover'
import SignPopoverView from '@/vue_components/SignPopover/components/SignPopoverView'
import {
  getAdaptedSign,
  getMedDocumentsForSign,
  setSuccessStatus,
  showResultNotification,
} from '@/helpers/med_document_signature'
import { getDefaultMedCasesFilters } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import { EGISZ_STATUSES } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'
import { egiszErrorsNotify, reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export default {
  name: 'EgiszCases',
  components: {
    SignPopoverView,
    SignPopover,
    CloseButton,
    EgiszMedCaseMedDocumentsTable,
    Modal,
    EgiszCasesSearch,
    NotFoundResult,
    EgiszCasesTable,
    Si,
    Pagination,
    SiFooter,
  },

  mixins: [SpinnerHolder],

  props: {
    canManage: Boolean,
  },

  data () {
    return {
      /**
       * @type {TEgiszClient[]}
       */
      medCasesList: [],
      selectedMedCase: null,
      medCaseMedDocuments: [],
      medCaseMedDocumentsLoading: false,
      medCaseMedDocumentsBtnLoading: false,
      allMedCaseMedDocumentsSigned: false,

      dataRequested: false,
      loading: false,

      loadingCasesIds: [],

      signatureLoading: false,
      signature: {
        workplaceId: null,
        certificateId: null,
        localMachine: false,
        resign: false,
      },
    }
  },

  computed: {
    ...mapGetters('medCases/filtersBase', {
      filters: 'vxGetFilters',
      isFiltered: 'vxGetIsFiltered',
      currentPage: 'vxGetCurrentPage',
      totalPages: 'vxGetTotalPages',
    }),

    ...mapGetters('egisz/user', {
      canUserSendToEgisz: 'vxGetCanUserSendToEgiszValue',
    }),

    isStatusRegistered () {
      return this.selectedMedCase &&
        this.selectedMedCase.netrika_status === EGISZ_STATUSES.SUCCESS
    },

    registerDisabledTooltipOrFalse () {
      if (!this.canManage) {
        return t('insufficient_access_rights')
      }

      if (!this.canUserSendToEgisz) {
        return t('egisz.cannotUserSendToEgisz')
      }

      if (!this.allMedCaseMedDocumentsSigned) {
        return t('egisz.components.egisz_cases.sign_register_modal.register_tooltip_disabled')
      }

      if (this.selectedMedCase && !this.selectedMedCase.closed) {
        return t('egisz.messages.errors.validation.texts.only_closed')
      }

      if (this.isStatusRegistered) {
        return t('egisz.already_registered')
      }

      return false
    },
  },

  watch: {
    filters: {
      immediate: true,
      handler () {
        this.getMedCasesList()
      },
    },

    medCaseMedDocuments: {
      deep: true,
      handler (to = []) {
        this.allMedCaseMedDocumentsSigned = to.reduce((acc, item) => {
          const status = item.attachment_signature_status

          return acc &&
              (status && status.personal_signed) &&
              (status && status.organization_signed)
        }, true)
      },
    },
  },

  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxResetFilters: 'vxResetFilters',
      vxSetTotalPages: 'vxSetTotalPages',
      vxSetPage: 'vxSetPage',
    }),

    getMedCasesList () {
      const promise = egiszCasesEndpoint.getAll(this.filters)
        .then(({ data, total_pages: totalPages }) => {
          this.medCasesList = data
          this.vxSetTotalPages(totalPages)
        })
        .catch((err) => {
          Utils.reportError(
            'EgiszClients:findCases()',
            reportErrorText('list_message', 'med_cases')
          )(err)
        })

      this.withSpinner(promise)
    },

    selectMedCase (medCase) {
      this.selectedMedCase = medCase
      if (medCase) {
        this.loadMedDocuments()
      }
    },

    loadMedDocuments () {
      if (!this.selectedMedCase.id) { return }

      const promise = medDocumentEndpoint.getAll({ medical_case_id: this.selectedMedCase.id })
        .then(({ data }) => { this.medCaseMedDocuments = data })
        .catch(Utils.reportError('EgiszCases:loadMedDocuments()'))

      this.withSpinner(promise, 'medCaseMedDocumentsLoading')
    },

    submitCase () {
      const medCase = cloneDeep(this.selectedMedCase)
      this.loadingCasesIds.push(medCase.id)

      const action = egiszCasesEndpoint.iemkAdd
      // // уточнить логику в дальнейшем
      // medCase.netrika_status === EGISZ_STATUSES.READY_TO_SEND
      //   ? egiszCasesEndpoint.iemkAdd
      //   : egiszCasesEndpoint.iemkUpdate

      const promise = action(medCase.id)
        .then(() => {
          this.selectedMedCase.netrika_status = EGISZ_STATUSES.SUCCESS
          this.selectMedCase(null)
          Notificator.success(t('egisz.messages.success.case_registered'))
        })
        .catch(egiszErrorsNotify(
          'EgiszCases:submitCase()',
          reportErrorText('submit_message', 'med_case')
        ))
        .finally(() => { this.loadingCasesIds = this.loadingCasesIds.filter((id) => id !== medCase.id) })

      this.withSpinner(promise, 'medCaseMedDocumentsBtnLoading')
    },

    signMedDocuments () {
      const signature = cloneDeep(this.signature)
      const medDocuments = this.medCaseMedDocuments
      const medDocumentsIdsForSign =
          getMedDocumentsForSign(medDocuments, signature.resign)
            .map((item) => item.id)

      const rejected = []
      const promises = Promise.allSettled(
        medDocumentsIdsForSign.map((id) =>
          medDocumentEndpoint.sign(id, getAdaptedSign(signature))
            .then(() => { setSuccessStatus(medDocuments, id, undefined, true) })
            .catch(() => {
              rejected.push(id)
              setSuccessStatus(medDocuments, id, undefined, false)
            })
        )
      )
        .then(() => { showResultNotification(rejected) })

      this.withSpinner(promises, 'signatureLoading')
    },

    resetFilters () {
      this.vxResetFilters(getDefaultMedCasesFilters())
    },
  },
}
</script>
