<template>
  <div>
    <m-input
      id="input"
      v-model="mInputValue"
      label="Законные представители"
    />

    <m-input
      id="input-disabled"
      v-model="mInputValue"
      label="Законные представители"
      disabled
    />

    <m-input
      id="input-required"
      v-model="mInputValue"
      label="Законные представители"
      validator-name="legal_representative"
      required
      @registerValidator="onRegisterValidator"
    />

    <m-input
      id="input-required"
      v-model="mInputValue"
      label="Законные представители (мин 3 симв)"
      validator-name="legal_representative1"
      :required="(value) => value.length < 3 ? ['Минимум 3 символа'] : []"
      @registerValidator="onRegisterValidator"
    />

    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="onSubmit"
    >
      {{ t('save') }}
    </button>

    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="resetValidations"
    >
      {{ t('clear') }}
    </button>

    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="resetValidations({
        legal_representative: ['Серверная валидация'],
        legal_representative1: ['Еще одна серверная валидация']
      })"
    >
      Серверная валидация
    </button>
  </div>
</template>

<script>
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MInput from '@/vue_present/_base/inputs/MInput/MInput'

export default {
  name: 'FrontBookPageMInput',
  components: { MInput },
  mixins: [ValidationParentMixin],

  data () {
    return {
      mInputValue: '',
    }
  },

  methods: {
    onSubmit () {
      if (this.hasErrors()) {
        Utils.reportError('here', 'Ошибки!')()

        return
      }

      Utils.reportSuccess('Нет ошибок!')()
    },
  },
}
</script>
