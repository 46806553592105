<template>
  <div class="missing-item">
    <div class="missing-item__title">
      <i
        class="title__icon fad"
        :class="icon"
      />
      <h4
        class="title__text"
        v-html="title"
      />
    </div>

    <div
      v-if="isModule"
      class="missing-item__body"
    >
      <el-collapse>
        <el-collapse-item>
          <template slot="title">
            <i class="fad fa-fw fa-list-ul" />
            <span>{{ t('introduction.capabilities') }}</span>
          </template>

          <div
            class="missing-item__capabilities"
            v-html="body"
          />

          <div class="introduction-request-button">
            <a
              class="btn btn-primary btn-with-icon"
              href="/module_request"
              target="_blank"
            >
              <i class="fad fa-fw fa-toggle-off btn-with-icon_icon" />
              <span>{{ t('introduction.connect_module') }}</span>
            </a>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div
      v-else
      class="missing-item__body"
    >
      <span v-html="body" />
      <a
        v-if="isAvailableSettings"
        class="btn btn-light-outline btn-xs btn-with-icon"
        :href="url"
        target="_blank"
      >
        <i class="fad fa-cog btn-with-icon_icon" />
        <span>{{ t('introduction.configurations.move_to_configuration') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StubItem',
  props: {
    isModule: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      required: true,
    },

    body: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      default: '',
    },

    isAvailableSettings: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
