<template>
  <div class="sidebar-filters__buttons">
    <m-si-generator-filters-buttons
      :disabled="loading"
      refresh-button-template="refresh"
      @onRefreshClick="$emit('refresh')"
      @onResetClick="$emit('reset')"
    />
  </div>
</template>

<script>
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'

export default {
  name: 'EgiszTableFilters',
  components: { MSiGeneratorFiltersButtons },
  props: {
    loading: Boolean,
  },

  emits: [
    'refresh',
    'reset',
  ],
}
</script>
