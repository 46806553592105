<template>
  <div class="filter">
    <span class="filter__label">
      <span class="label__text">{{ label }}</span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'FilterBase',
  props: {
    label: {
      type: String,
      default: null,
    },
  },
}
</script>
