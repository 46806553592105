import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class CheckupCalculatePricePresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () {},
        all () {},
        list () {},
      },
      entity: 'checkupCalculatePrice',
      location: 'CheckupCalculatePricePresenter',
    })
  }

  /**
   *
   * @param data {ICheckupCalculatePrice}
   * @param config
   * @returns {Promise<*>}
   */
  fetchCheckupPrice (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'calculateMessage',
      this.entity
    )

    return super.post({
      url: Routes.calculate_price_api_internal_checkups_personals_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .catch(MRequestError.onRequest(notification))
  }
}
