import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getCompanyCheckupPersonalCheckupsMsiSchema = (
  addPagination = true,
  addSearch = true
) => new MSiSchema()
  .addPagination(addPagination)
  .addSearch(addSearch)
  .addTableSchema(
    new MTableSchema({
      headers: {
        number: new MTableHeader().addFixedWidth('50px'),
        status: new MTableHeader().addFixedWidth('30px'),
        client: new MTableHeader(),
        age: new MTableHeader().addFixedWidth('80px'),
        position: new MTableHeader(),
        order: new MTableHeader().addFixedWidth('80px'),
      },
    })
      .addEditIcon(Services.security.canManageCheckupsCompany)
      .addDeleteIcon(Services.security.canManageCheckupsCompany)
  )
