import { concatHouseWithBlock } from '@/vue_present/Reuse/CommonAddressForm/helpers'

export const getDefaultCompany = (suggestCompany = {}) => {
  const managementName = (suggestCompany?.management?.name || '  ').split(' ')

  const houseWithBlock = {
    house: suggestCompany.address?.data?.house,
    blockTypeFull: suggestCompany.address?.data?.blockTypeFull,
    block: suggestCompany.address?.data?.block,
  }

  return {
    title: suggestCompany.name?.full || '',
    companyType: null,
    typeOfCompany: suggestCompany.opf?.short || '',
    individualTaxNumber: suggestCompany.inn || '',
    kpp: suggestCompany.kpp || '',
    ogrn: suggestCompany.ogrn || '',
    okved: suggestCompany.okved || '',
    registrationDate: (suggestCompany.state?.registrationDate ? new Date(suggestCompany.state.registrationDate) : null) || null,
    note: '',

    addressFormal: {
      index: suggestCompany.address?.data?.postalCode || '',
      country: suggestCompany.address?.data?.country || '',
      region: suggestCompany.address?.data?.regionWithType || '',
      area: suggestCompany.address?.data?.areaWithType || '',
      city: suggestCompany.address?.data?.cityWithType || '',
      street: suggestCompany.address?.data?.streetWithType || '',
      house: concatHouseWithBlock(houseWithBlock) || '',
      flat: suggestCompany.address?.data?.flat || '',
      nsiRussianSubjectId: null,
    },

    addressFact: {
      index: '',
      country: '',
      region: '',
      area: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      nsiRussianSubjectId: null,
    },

    phone1: '',
    phone2: '',
    web: '',
    email: '',
    headSurname: managementName[0] || '',
    headName: managementName[1] || '',
    headSecondName: managementName[2] || '',
    headPosition: suggestCompany.management?.post || '',
    operatesBy: '',
  }
}

export const getCompanyBySuggestData = (suggestCompany) =>
  getDefaultCompany(suggestCompany)
