<template>
  <div class="feedback-modal">
    <m-modal
      class="feedback-modal"
      :dialog-title="modalTitle"
      modal-title-icon="star"
      :visible.sync="modalVisible"
    >
      <template #body>
        <div class="feedback-modal__scores">
          <!-- Оценка врача -->
          <div class="feedback-modal__doctor-score  mb-indent-validation">
            <div class="feedback-modal__label">
              {{ t('feedback.tableTitles.doctorScore') }}
            </div>

            <m-stars :value="feedback.doctorScore" />
          </div>

          <!-- Оценка клиники -->
          <div class="feedback-modal__clinic-score  mb-indent-validation">
            <div class="feedback-modal__label">
              {{ t('feedback.tableTitles.clinicScore') }}
            </div>

            <m-stars
              :value="feedback.clinicScore"
              :colors="RATING_COLORS.GREEN"
            />
          </div>
        </div>

        <!-- Пациент -->
        <m-input
          :value="feedback.client?.fullName"
          class="feedback-modal__patient"
          :label="t('feedback.tableTitles.client')"
          disabled
        >
          <template #append>
            <m-icon
              v-if="feedback.client?.id"
              v-tooltip="t('open_new_tab')"
              color="primary"
              class="pointer"
              icon="link"
              @click="openClientPage(feedback.client.id)"
            />
          </template>
        </m-input>

        <!-- Телефон -->
        <div
          class="feedback-modal__phone mb-indent-validation position-relative"
          :data-client-id="feedback.client?.id"
        >
          <m-label
            :label="t('feedback.tableTitles.phone')"
            class="feedback-modal__phone-label pb-5"
          />

          <div class="feedback-modal__phone-value phone_number callable">
            {{ feedback.client?.phone }}
          </div>
        </div>

        <!-- Врач -->
        <m-input
          class="feedback-modal__doctor"
          :label="t('feedback.tableTitles.doctor')"
          :value="feedback.doctor?.fullName"
          disabled
        >
          <template #append>
            <m-icon
              v-if="feedback.doctor?.id"
              v-tooltip="t('open_new_tab')"
              color="primary"
              class="pointer"
              icon="link"
              @click="openDoctorPage(feedback.doctor.id)"
            />
          </template>
        </m-input>

        <!-- Отзыв -->
        <div class="feedback-modal__client-comment  mb-indent-validation">
          <div class="feedback-modal__client-comment-label">
            <m-label :label="t('feedback.tableTitles.clientComment')" />
          </div>
          <div class="feedback-modal__client-comment-text">
            {{ feedback.clientComment }}
          </div>
        </div>

        <!-- Статус -->
        <m-select
          :value="feedback.status"
          :items="FEEDBACK_STATUSES_LABELS"
          :label="t('feedback.tableTitles.status')"
          class="feedback-modal__status"
          full-width
          :clearable="false"
          :use-custom-result="DEFAULT_RESULT_TYPES.SIMPLE"
          @change="feedback.setValue('status', $event)"
        />

        <!-- Ответственный -->
        <reusable-doctors-list
          :value="feedback.responsible"
          fetch-method="users"
          :m-fixed-height="true"
          full-width
          class="feedback-modal__responsible"
          :user-group="USER_GROUP_IDS.CLINIC_PERSONNEL"
          :label="t('feedback.tableTitles.responsible')"
          option-label="fullName"
          use-first-time-fetch
          @onDoctorChange="feedback.setValue('responsible', $event)"
        />

        <!-- Внутренний комментарий -->
        <m-textarea
          :value="feedback.responsibleUserComment"
          class="feedback-modal__responsible-user-comment"
          :label="t('feedback.tableTitles.responsibleUserComment')"
          :clarification="t('feedback.tableTitles.responsibleUserCommentClarification')"
          @input="feedback.setValue('responsibleUserComment', $event)"
        />

        <div class="feedback-modal__change-info">
          <m-prompt-notice :text="changeInfo" />
        </div>
      </template>

      <template #footer-left>
        <m-button-submit
          @click="onSubmit"
        />
      </template>
    </m-modal>
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MStars from '@/vue_present/_base/MStars/MStars.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { FEEDBACK_STATUSES_LABELS, RATING_COLORS } from '@/vue_apps/FeedbackModule/const/const'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MLabel from '@/vue_present/_base/inputs/MLabel.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { FeedbackApi } from '@/vue_apps/FeedbackModule/entities/FeedbackApi'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'

export default {
  name: 'FeedbackModal',
  components: {
    MIcon,
    MPromptNotice,
    MLabel,
    MButtonSubmit,
    ReusableDoctorsList,
    MTextarea,
    MSelect,
    MStars,
    MInput,
    MModal,
  },

  mixins: [
    ModalMixin,
  ],

  emits: ['registerOpenModal', 'updateList'],

  data () {
    return {
      USER_GROUP_IDS,
      FEEDBACK_STATUSES_LABELS,
      DEFAULT_RESULT_TYPES,
      RATING_COLORS,
      feedback: new FeedbackApi(),
    }
  },

  computed: {
    modalTitle () {
      return `${t('feedback.patientFeedback')} ${this.feedback?.client?.fullName}`
    },

    changeDate () {
      return Utils.getFormattedDate(this.feedback?.updatedAt, Utils.shortDateTimeFormat)
    },

    changerName () {
      return this.feedback?.updatedByUser?.fullName || ''
    },

    changeInfo () {
      return `${t('feedback.changed')} ${this.changeDate}  ${this.changerName}`
    },
  },

  created () {
    const vm = this
    this.$emit('registerOpenModal', vm.openFeedbackModal)
  },

  methods: {
    async openFeedbackModal (id) {
      if (!id) return

      this.feedback = new FeedbackApi({ id })
      await this.feedback.load()
      this.openModal()

      this.$nextTick(() => {
        Services.telephony.reset()
      })
    },

    openClientPage (clientId) {
      Utils.openInNewTab(this.$routes.client_path(clientId))
    },

    openDoctorPage (doctorId) {
      Utils.openInNewTab(this.$routes.edit_user_path(doctorId))
    },

    async onSubmit () {
      await this.feedback.save()
      this.closeModal()
      this.$emit('updateList')
    },
  },
}
</script>
