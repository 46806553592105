<template>
  <div class="document-field-writable-date">
    <slot name="prefix" />

    <div class="document-field-writable-date__day">
      <span>«</span>

      <document-field-writable
        :value="date.day"
        :disabled="disabled"
        text-center
        width="30px"
        @input="onInput('day', $event)"
      />

      <span>»</span>
    </div>

    <document-field-writable
      :value="date.month"
      :disabled="disabled"
      text-center
      width="120px"
      @input="onInput('month', $event)"
    />

    <div class="document-field-writable-date__year">
      <span>{{ year.static }}</span>

      <document-field-writable
        :value="year.dynamic"
        :disabled="disabled"
        text-center
        width="30px"
        @input="onYearInput($event)"
      />

      <span>г.</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import { IDate } from '@/_declarations/IDate'

export default defineComponent({
  name: 'DocumentFieldWritableDate',

  components: { DocumentFieldWritable },

  props: {
    date: {
      type: Object as PropType<IDate<string | number>>,
      required: true,
    },

    disabled: Boolean,
  },

  emits: ['input'],

  computed: {
    year () {
      const year = this.date
        .year
        .toString()

      return {
        static: year.slice(0, 2) as string,
        dynamic: year.slice(2) as string,
      }
    },
  },

  methods: {
    onInput (key: string, value: string | number) {
      const date = {
        ...this.date,
        [key]: value,
      }

      this.$emit('input', date as IDate<string | number>)
    },

    onYearInput (value: string) {
      this.onInput('year', this.year.static + value)
    },
  },
})
</script>
