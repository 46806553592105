<template>
  <div class="fixed-document-print-footer">
    <m-button
      template="edit"
      @click="$emit('onEditClick')"
    />

    <m-button-delete
      use-button
      :popover-message="t('fixedDocument.base.deleteDocument')"
      @yes="$emit('onDestroyClick')"
    />

    <m-back-button
      class="fixed-document-print-footer__back"
      :on-click-handler="onBackClick"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MBackButton from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'
import { onBackClick } from '@/vue_apps/Documents/FixedDocuments/helpers/onBackClick'

export default {
  name: 'FixedDocumentPrintFooter',

  components: { MBackButton, MButtonDelete, MButton },

  emits: ['onEditClick', 'onDestroyClick'],

  methods: { onBackClick },
}
</script>
