<template>
  <div class="document-table">
    <table class="document-table__table">
      <tr class="document-table__tr">
        <th
          v-for="(header, key) in headers"
          :key="key"
          class="document-table__th"
        >
          {{ header }}
        </th>

        <th
          v-if="showDelete"
          class="document-table__header-delete-icon"
        >
          <m-icon
            icon="delete"
            color="dark"
          />
        </th>
      </tr>

      <slot name="body">
        <tr
          v-for="(row, idx) in data"
          :key="idx"
          class="document-table__tr"
        >
          <template v-if="editable">
            <td
              v-for="(cell, key) in row"
              :key="`${key}-${idx}`"
              class="background-primary"
              :style="`width: ${fixedWidthColumns[key] || 'initial'};`"
            >
              <document-field-writable
                class="w-100"
                :value="cell"
                :text-center="centerColumns.includes(key)"
                fill-available-width
                :multiline="multilineColumns.includes(key)"
                @input="onInput(idx, key, $event)"
              />
            </td>

            <td
              v-if="showDelete"
              class="document-table__delete-row"
            >
              <m-button-delete @yes="$emit('removeRow', idx)" />
            </td>
          </template>

          <template v-else>
            <td
              v-for="(cell, key) in row"
              :key="key"
              class="document-table__td"
              :class="{ 'text-center': centerColumns.includes(key) }"
            >
              {{ cell }}
            </td>
          </template>
        </tr>
      </slot>
    </table>

    <m-button
      v-if="useRowsManipulation"
      v-tooltip="t('add')"
      class="document-table__add-row"
      template="add"
      @click="$emit('addRow')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'DocumentTable',

  components: { MIcon, MButton, MButtonDelete, DocumentFieldWritable },

  props: {
    headers: {
      type: Array as PropType<string[] | readonly string[]>,
      default: () => [],
    },

    centerColumns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    multilineColumns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    fixedWidthColumns: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },

    data: {
      type: Array as PropType<object[]>,
      default: () => [],
    },

    editable: Boolean,

    // модификатор для editable. Позволяет добавлять\удалять строки в таблице.
    useRowsManipulation: Boolean,
  },

  emits: ['input', 'removeRow', 'addRow'],

  computed: {
    showDelete () {
      return Boolean(this.useRowsManipulation && this.data.length)
    },
  },

  methods: {
    onInput (idx: number, key: string, value: string | number) {
      this.$emit('input', {
        index: idx,
        key,
        value,
      })
    },
  },
})
</script>
