/*
  Moved from cu-nke0vt
 */

import { isRequestAbort } from '@/lib/transport/utils'

const SAFE_LOAD_TIME_MS = 250

export const SpinnerHolder = {
  data () {
    return {
      loading: false,
      runningRequest: null,
      loadingTimeout: null,
    }
  },

  computed: {
    hasRunningRequest () {
      return !!this.runningRequest
    },
  },

  methods: {
    /**
     * @param {Promise<any>} promise
     * @param {String} [loadingProp]
     * @param {Boolean|Number} withBounce
     */
    withSpinner (promise, loadingProp = 'loading', withBounce = SAFE_LOAD_TIME_MS) {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout)
        this.loadingTimeout = null
      }

      setTimeout(() => { this[loadingProp] = true })

      promise.finally(() => {
        this.loadingTimeout =
            setTimeout(() => { this[loadingProp] = false }, withBounce || 0)
      })

      return promise
    },

    /**
     * Для запросов, которые должны быть запущены в единственном экземпляре.
     * Каждый следующий запрос будет прерван, если текущий не завершён.
     *
     * @param {JQuery.jqXHR | { abort(): void }} ajax
     * @param {Promise<any>} requestPromise
     */
    runSpinnerWhileLoading (ajax, requestPromise) {
      if (this.runningRequest) {
        this.runningRequest.abort()
      }
      this.runningRequest = ajax
      requestPromise
        .then(() => {
          this.runningRequest = null
        })
    },

    /**
     * Пропускает ошибку, если она вызвана прерыванием запроса
     *
     * @param error
     */
    handleRequestAbort (error) {
      if (!isRequestAbort(error)) {
        this.runningRequest = null
        throw error
      }
    },
  },
}
