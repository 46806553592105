import { suggestionEndpoint } from '@/api_entities/suggestion/suggestionEndpoint'
import { suggestionDefaultAdapter } from '@/api_entities/suggestion/suggestionAdapters'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export const suggestionPresenter = {
  party (params) {
    return suggestionEndpoint.party(params)
      .then((data) => Promise.resolve(suggestionDefaultAdapter.toClient(data)))
      .catch((err) => {
        Utils.reportError(
          'suggestionPresenter::party()',
          reportErrorText('read_message', 'organization')
        )(err)
      })
  },

  address (params) {
    return suggestionEndpoint.address(params)
      .then((data) => Promise.resolve(suggestionDefaultAdapter.toClient(data)))
      .catch((err) => {
        Utils.reportError(
          'suggestionPresenter::address()',
          reportErrorText('read_message', 'address')
        )(err)
      })
  },

  fmsUnit (params) {
    return suggestionEndpoint.fmsUnit(params)
      .then((data) => Promise.resolve(suggestionDefaultAdapter.toClient(data)))
      .catch((err) => {
        Utils.reportError(
          'suggestionPresenter::fmsUnit()',
          reportErrorText('read_message', 'place_of_receipt_document')
        )(err)
      })
  },

  geoLocation () {
    return suggestionEndpoint.geoLocation()
      .then((response) => Promise.resolve([response?.location?.data]))
      .catch(() => Promise.resolve([]))
  },
}
