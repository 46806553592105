<template>
  <m-modal
    class="personal-checkup-instrumental-research-results-modal"
    :visible="visible"
    :dialog-title="t$('title')"
    modal-title-icon="search"
    width="max-content"
    @close="onClose"
  >
    <template #body>
      <div class="flex gap-indent-small">
        <m-icon
          v-tooltip="t$('questionTooltip')"
          class="personal-checkup-instrumental-research-results-modal__large-icon"
          icon="questionCircle"
          color="gray"
        />

        <!-- Обследование -->
        <m-select
          class="personal-checkup-instrumental-research-results-modal__research"
          :value="instrumentalResearchResults.research"
          :items="instrumentalResearchResults.researches"
          :label="t('checkups.laboratoryAndInstrumentalResearch.examination')"
          required
          validator-name="research"
          @registerValidator="onRegisterValidator($event)"
          @change="instrumentalResearchResults.setValue('research', $event)"
        />

        <!-- Дата -->
        <m-date-picker
          :value="instrumentalResearchResults.date"
          :label="t('common.date')"
          value-format="yyyy-MM-dd"
          :required="dateNotInFutureValidator"
          validator-name="date"
          @registerValidator="onRegisterValidator($event)"
          @change="instrumentalResearchResults.setValue('date', $event)"
        />

        <!-- Заключение -->
        <m-select
          :value="instrumentalResearchResults.medicalAssessment"
          :items="instrumentalResearchResults.medicalAssessments"
          :label="t('conclusion')"
          required
          validator-name="medicalAssessment"
          @registerValidator="onRegisterValidator($event)"
          @change="instrumentalResearchResults.setValue('medicalAssessment', $event)"
        />
      </div>

      <m-si-generator
        :items="instrumentalResearchResultsItems"
        :si-generator-schema="msiSchema"
        @onItemDelete="instrumentalResearchResults.destroy($event.id)"
      />
    </template>

    <template #footer-right-action>
      <m-button
        v-tooltip="addAndSaveButtonTooltip"
        template="addAndSave"
        :disabled="!!instrumentalResearchResultsItems.length"
        @click="onSave"
      />
    </template>
  </m-modal>
</template>

<script>
import { defineComponent } from 'vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import { dateNotInFutureValidator } from '@/lib/validators/dateNotInFutureValidator'
import {
  getPersonalCheckupResearchResultsMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupResearchResults/personalCheckupResearchResultsConsts'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'
import { personalCheckupQuestionMarkMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupQuestionMarkMixin'

export default defineComponent({
  name: 'PersonalCheckupInstrumentalResearchResultsModal',

  components: {
    MSiGenerator,
    MButton,
    MDatePicker,
    MSelect,
    MIcon,
    MModal,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
    personalCheckupQuestionMarkMixin,
  ],

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      msiSchema: getPersonalCheckupResearchResultsMsiSchema(),
    }
  },

  computed: {
    instrumentalResearchResults () {
      return this.personalCheckup
        .instrumentalResearchResults
    },

    instrumentalResearchResultsItems () {
      return this.instrumentalResearchResults.getArr()
    },

    addAndSaveButtonTooltip () {
      return this.instrumentalResearchResultsItems.length
        ? this.t$('saveDisableTooltip')
        : null
    },
  },

  created () {
    this.setI18nScope('checkups.laboratoryAndInstrumentalResearch.instrumental')
  },

  methods: {
    dateNotInFutureValidator,

    async onSave () {
      if (this.hasErrors()) { return }
      this.instrumentalResearchResults.append()

      if (!this.isNewMode) {
        const errors = await this.instrumentalResearchResults.submit()
        if (errors) { return }
      }

      this.onClose()
    },

    onClose () {
      this.instrumentalResearchResults.clear()
      this.resetValidations()
      this.$emit('close')
    },
  },
})
</script>
