/**
 * @param {TUserTableSchema | Object} userTableSchema
 * @return {TTableSchema}
 */
export const getTableSchema = (userTableSchema = null) => {
  /** @type {TTableSchema} tableSchema */
  const tableSchema = {
    headers: {},
    options: {},
  }

  if (!userTableSchema) { return tableSchema }
  if (typeof userTableSchema !== 'object') { return tableSchema }

  if (typeof userTableSchema.headers === 'object') {
    Object.keys(userTableSchema.headers).forEach((key) => {
      const value = userTableSchema.headers[key]

      tableSchema.headers[key] = typeof value === 'string'
        ? { type: 'struct', value }
        : value
    })
  }

  if (typeof userTableSchema.options === 'object') {
    Object.keys(userTableSchema.options).forEach((key) => {
      tableSchema.options[key] = userTableSchema.options[key]
    })
  }

  return tableSchema
}
