<template>
  <m-button
    :type="type"
    :button-style="buttonStyle"
    :text="text"
    :tooltip="tooltip"
    :template="template"
    :size="size"
    :plus-icon="plusIcon"
    :loading="loading"
    :disabled="disabled"
    @click="$refs.input.click()"
  >
    <input
      ref="input"
      class="hidden"
      type="file"
      :accept="accept.join(', ')"
      :multiple="multiple"
      @input="inputHandler"
    >

    <m-icon
      v-if="isButtonWithIconCssRequired"
      custom-class="button-icon"
      :icon="isButtonWithIconCssRequired"
      :color="iconColor"
      :no-use-fw="noUseFw"
      :use-brand="useBrandIcon"
      :use-solid="useSolidIcon"
    />

    <m-icon
      v-if="plusIcon"
      custom-class="button-icon"
      icon="add"
      :color="iconColor"
    />

    <span
      class="m-button__text"
      :class="{ 'use-indent': buttonText}"
    >{{ buttonText }}</span>
  </m-button>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { props } from '@/vue_present/_base/buttons/MButtonUpload/props'
import { computed } from '@/vue_present/_base/buttons/MButtonUpload/computed'

export default {
  name: 'MButtonUpload',

  components: { MIcon, MButton },

  props,

  emits: ['files'],

  computed,

  methods: {
    inputHandler (event) {
      this.$emit('files', event.target.files)
      event.target.value = null
    },
  },
}
</script>
