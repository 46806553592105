import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class MAdmissionAssessmentPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () { },
        all () { },
        list () { },
      },
      entity: 'admissionAssessment',
      location: 'MAdmissionAssessmentPresenter',
    })
  }

  fetchAssessments (data) {
    const options = {
      url: Routes.reports_create_admission_assessment_dynamics_path(),
      data: {
        report: camelToSnake(data),
      },
    }

    const notification = new MRequestNotification(
      'MAdmissionAssessmentPresenter:fetchAssessments',
      'fetchMessage',
      'admissionAssessment'
    )

    return this.post(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }
}
