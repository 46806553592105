import { MListService } from '@/_api/_requests/MListService'
import { MPaymentPresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/MPaymentPresenter'

export class PaymentsList extends MListService {
  constructor (initialFilters) {
    super(
      initialFilters,
      new MPaymentPresenter(),
      { cacheKey: 'finance-payments-list' }
    )
  }
}
