import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
import companiesRoutes from './modules/companies/routes.js'
import supportRoutes from './modules/support/routes.js'
import { routes as frontBookRoutes } from '@/vue_present/_FrontBook/router/routes'
import { routes as smsConfigurationRoutes } from '@/vue_apps/app_configuration/messages/router/routes'
import { referralRoutes } from '@/vue_apps/catalogs_root/Referrals/routes/referralRoutes'
import { checkupsModuleRoutes } from '@/vue_apps/CheckupsModule/router/checkupsModuleRoutes'
import { financeRoutes } from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/financeModuleRoutes'
import { checkupCatalogsRoutes } from '@/vue_apps/catalogs_root/CheckupCatalogs/router/checkupCatalogsRoutes'
import { entryPacksRoutes } from '@/vue_apps/catalogs_root/EntryPacks/router/routes'
import { fixedDocumentModuleRoutes } from '@/vue_apps/Documents/FixedDocuments/router/fixedDocumentModuleRoutes'
import { clinicsCatalogRoutes } from '@/vue_apps/catalogs_root/ClinicsCatalog/routes/clinicsCatalogRoutes'
import egiszRoutes from '@/vue_components/egisz/egisz_module/router/routes'
import { clientFeedbacksRoutes } from '@/vue_components/client_feedbacks/router/clientFeedbacksRoutes'

Vue.use(VueRouter)

const routes = [
  ...companiesRoutes,
  ...supportRoutes,
  ...egiszRoutes,
  ...frontBookRoutes,
  ...smsConfigurationRoutes,
  ...financeRoutes,

  ...referralRoutes,
  ...checkupsModuleRoutes,
  ...checkupCatalogsRoutes,
  ...entryPacksRoutes,
  ...fixedDocumentModuleRoutes,
  ...clinicsCatalogRoutes,
  ...clientFeedbacksRoutes,
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.afterEach((to, from) => {
  window.HistoryBrowser.updateVue(to.path)
})

export default router
