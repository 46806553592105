import { isEmpty } from 'lodash'

export const DATE_PICKER_BUTTONS = Object.freeze({
  MONTH: 'Month',
  WEEK: 'Week',
  TODAY: 'Day',
  YESTERDAY: 'Yesterday',
  TOMORROW: 'Tomorrow',
})

const beginningOfDay = () => moment().hour(0).minutes(0).seconds(0)
const endOfDay = () => moment().hour(23).minutes(59).seconds(59)

const beginningOfYesterday = () => beginningOfDay().subtract(1, 'days')
const endOfYesterday = () => endOfDay().subtract(1, 'days')

const startOfWeek = () => beginningOfDay().subtract(1, 'week')
const endOfWeek = endOfDay

const startOfMonth = () => beginningOfDay().subtract(1, 'month')
const endOfMonth = endOfDay

const beginningOfTomorrow = () => beginningOfDay().add(1, 'days')
const endOfTomorrow = () => endOfDay().add(1, 'days')

const isSameDates = (date1, date2) => moment(date1).isSame(moment(date2))

export const dateButtons = Object.freeze({
  [DATE_PICKER_BUTTONS.MONTH]: {
    id: DATE_PICKER_BUTTONS.MONTH,
    value: [startOfMonth().toDate(), endOfMonth().toDate()],
    title: 'Месяц',
  },

  [DATE_PICKER_BUTTONS.WEEK]: {
    id: DATE_PICKER_BUTTONS.WEEK,
    value: [startOfWeek().toDate(), endOfWeek().toDate()],
    title: 'Неделя',
  },

  [DATE_PICKER_BUTTONS.TODAY]: {
    id: DATE_PICKER_BUTTONS.TODAY,
    value: [beginningOfDay().toDate(), endOfDay().toDate()],
    title: 'Сегодня',
  },

  [DATE_PICKER_BUTTONS.YESTERDAY]: {
    id: DATE_PICKER_BUTTONS.YESTERDAY,
    value: [beginningOfYesterday().toDate(), endOfYesterday().toDate()],
    title: 'Вчера',
  },

  [DATE_PICKER_BUTTONS.TOMORROW]: {
    id: DATE_PICKER_BUTTONS.TOMORROW,
    value: [beginningOfTomorrow().toDate(), endOfTomorrow().toDate()],
    title: 'Завтра',
  },
})

export const datePickerButtonsAll = Object.values(dateButtons)

/**
 *
 * @param {[ Date, Date ]} period
 */
export const getIdByPeriod = (period) => {
  if (isEmpty(period)) { return null }

  return datePickerButtonsAll.find((item) => {
    const start =
      isSameDates(
        Utils.getBaseFormattedDate(item.value[0]),
        Utils.getBaseFormattedDate(moment(period[0]).toDate().getTime())
      )
    const end =
      isSameDates(
        Utils.getBaseFormattedDate(item.value[1]),
        Utils.getBaseFormattedDate(moment(period[1]).toDate().getTime())
      )

    return start && end
  })
}

export const getPeriodById = (id) => Utils.ternary(id, dateButtons[id].value)
