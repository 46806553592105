<template>
  <span class="egisz-med-document-modal">
    <modal
      :modal-visibility="modalVisibility"
      modal-size="md"
      @close="closeModal"
    >
      <template #header>
        <i class="fad fa-file-plus btn-with-icon_icon" />
        <span v-if="isEditMode">{{ t('egisz.components.egisz_med_documents.edit') }}</span>
        <span v-else>{{ t('egisz.components.egisz_med_documents.new') }}</span>
      </template>

      <template #body>
        <egisz-med-document-form
          v-bind.sync="medDocument"
          :validation-messages="validationMessages"
        />
      </template>

      <template #footer-left>
        <button
          v-loading="loading"
          type="button"
          class="btn btn-sm btn-with-icon"
          :class="isEditMode ? 'btn-warning' : 'btn-success'"
          @click="submitMedDocument()"
        >
          <i
            class="btn-with-icon_icon"
            :class="isEditMode ? 'fad fa-pencil' : 'fas fa-plus'"
          />
          <span>{{ t('save') }}</span>
        </button>

        <sign-popover
          v-if="medDocument.id"
          :loading="loading"
          :disable-confirm-btn="!sign.certificateId || !sign.workplaceId"
          require-personal-sign
          @yes="medDocumentSign"
          @onSignClick="$refs.signPopover.loadWorkplaceList()"
        >
          <template #body>
            <sign-popover-view
              ref="signPopover"
              :validation-messages.sync="validationMessages"
              :local-machine.sync="sign.localMachine"
              :workplace-id.sync="sign.workplaceId"
              :certificate-id.sync="sign.certificateId"
            />
          </template>
        </sign-popover>
      </template>

      <template #footer-right>
        <button
          type="button"
          class="btn btn-sm btn-primary btn-with-icon"
          @click="closeModal"
        >
          <i class="fas fa-times btn-with-icon_icon" />
          <span>{{ t('close') }}</span>
        </button>
      </template>
    </modal>

    <slot name="call-btn">
      <div
        v-tooltip="cantSubmitMedDocument && t('egisz.components.egisz_med_documents.protocol_file_not_found')"
        class="inline-block hidden-print"
      >
        <button
          v-tooltip="isEditMode ? t('egisz.components.egisz_med_documents.edit_tooltip') : t('egisz.components.egisz_med_documents.new_tooltip')"
          class="btn btn-sm mr-0 btn-with-icon"
          :class="isEditMode ? 'btn-warning' : 'btn-success'"
          :disabled="cantSubmitMedDocument"
          @click="openModal"
        >
          <template v-if="isEditMode">
            <i class="fad fa-pencil btn-with-icon_icon" />
            <span>{{ t('egisz.components.egisz_med_documents.edit') }}</span>
          </template>
          <template v-else>
            <i class="fad fa-file-plus btn-with-icon_icon" />
            <span>{{ t('egisz.components.egisz_med_documents.new') }}</span>
          </template>
        </button>
      </div>
      <button
        v-if="cannotUpdatePdfGenerated"
        v-tooltip="t('egisz.refresh_protocol')"
        class="btn btn-sm pdf-generated-refresh hidden-print"
        :class="isEditMode ? 'btn-warning' : 'btn-success'"
        @click="pdfGeneratedRefresh"
      >
        <i class="fad fa-sync mr-0" />
      </button>
    </slot>
  </span>
</template>

<script>
import { getDate } from '@/helpers/vanilla_date'
import { cutHeader } from '@/vue_components/egisz/egisz_med_document/const'
import EgiszMedDocumentForm from '@/vue_components/egisz/egisz_med_document/components/EgiszMedDocumentForm'
import Modal from '@/vue_components/modal'
import validationMixin from '@/vue_components/egisz/egisz_med_document/validation/validationMixin'
import { cloneDeep } from 'lodash'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { medDocumentEndpoint } from '@/api_entities/egisz/med_documents/med_document_endpoint'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { MED_DOCUMENT_HOLDER_SCOPE } from '@/api_entities/egisz/med_documents/med_document_holder_scope'
import SignPopover from '@/vue_components/SignPopover/SignPopover'
import SignPopoverView from '@/vue_components/SignPopover/components/SignPopoverView'
import { EGISZ_STATUSES } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'
import { entryEndpoint } from '@/api_entities/entries/entry_endpoint'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export default {
  name: 'EgiszMedDocument',
  components: { SignPopoverView, SignPopover, Modal, EgiszMedDocumentForm },
  mixins: [validationMixin, SpinnerHolder],

  props: {
    id: {
      type: Number,
      default: null,
    },

    entryId: {
      type: Number,
      default: null,
    },

    entryTitle: {
      type: String,
      default: '',
    },

    authorId: {
      type: Number,
      default: null,
    },

    authorFullName: {
      type: String,
      default: '',
    },

    date: {
      type: Date,
      default: () => getDate(),
    },

    clinicId: {
      type: Number,
      default: null,
    },

    typeId: {
      type: String,
      default: null,
    },

    status: {
      type: String,
      default: '',
    },

    modalVisibilityProp: Boolean,

    personalSign: Boolean,
    organizationSign: Boolean,

    pdfGenerated: Boolean,

    canManage: Boolean,
  },

  data () {
    return {
      medDocument: this.createMedDocument(),
      sign: this.createSign(),
      modalVisibility: false,
    }
  },

  computed: {
    ...mapGetters('egiszEmd', {
      getActiveEntryEmd: 'GET_ACTIVE_ENTRY_EMD',
    }),

    ...mapGetters('egisz/egiszWs', {
      lastPdfGenerated: 'vxGetLastPdf',
    }),

    isEditMode () {
      return Boolean(this.medDocument.id)
    },

    cantSubmitMedDocument () {
      return !this.medDocument.pdfGenerated
    },

    isStatusSuccess () {
      return this.status === EGISZ_STATUSES.SUCCESS
    },

    cannotUpdatePdfGenerated () {
      return !this.medDocument.pdfGenerated
    },
  },

  watch: {
    modalVisibilityProp (to) {
      this.modalVisibility = to
    },

    // если был удален активный Emd, то сразу меняем его состояние
    getActiveEntryEmd (to, from) {
      if (!(from && from.id === this.id && !to)) { return }
      this.medDocument.id = null
    },

    id () {
      this.medDocument = this.createMedDocument()
      this.sign = this.createSign()
    },

    lastPdfGenerated (to) {
      if (to.id !== this.entryId) { return }

      this.medDocument.pdfGenerated = true
    },
  },

  created () {
    this.loadMedDocumentTypes()
  },

  mounted () {
    this.pdfGeneratedRefresh()
  },

  methods: {
    ...mapActions('egiszEmd', {
      loadMedDocumentTypes: 'loadMedDocumentTypes',
    }),

    ...mapMutations('egiszEmd/filtersBase', {
      vxRefreshFilters: 'vxRefreshFilters',
    }),

    openModal () {
      this.modalVisibility = true
    },

    closeModal () {
      this.modalVisibility = false
      this.$emit('close')
    },

    createMedDocument () {
      return {
        id: this.id,
        authorId: this.authorId,
        authorFullName: this.authorFullName,
        date: this.date,
        header: cutHeader(this.entryTitle),
        typeId: this.typeId,
        pdfGenerated: this.pdfGenerated,
        entryId: this.entryId,
      }
    },

    createSign () {
      return {
        submitWithSign: false,
        personalSign: this.personalSign,
        organizationSign: this.organizationSign,
        workplaceId: null,
        certificateId: null,
        localMachine: false,
      }
    },

    setMedDocumentId (value = null) {
      this.medDocument.id = value
    },

    deleteMedDocument () {
      if (!this.medDocument.id) { return }

      const promise = medDocumentEndpoint.destroy(this.medDocument.id)
        .then(() => {
          this.vxRefreshFilters()
          this.setMedDocumentId()
          this.closeModal()
        })
        .catch(Utils.reportError(
          'EgiszMedDocument:deleteMedDocument()',
          reportErrorText('delete_message', 'emd')
        ))

      this.withSpinner(promise)
    },

    submitMedDocument () {
      const validatedData = {
        ...cloneDeep(this.medDocument),
        ...(this.sign.submitWithSign ? cloneDeep(this.sign) : {}),
      }

      if (!this.validate(validatedData)) { return }

      const data = {
        id: this.medDocument.id,
        author_id: this.authorId,
        header: cutHeader(this.medDocument.header),
        entry_id: this.entryId,
        clinic_id: this.clinicId,
        date: this.medDocument.date,
        type_id: this.medDocument.typeId,
      }

      const promise = medDocumentEndpoint.submit(data)
        .then(({ id }) => {
          Notificator.success(t('egisz.messages.success.document_submitted'))
          this.vxRefreshFilters()
          this.setMedDocumentId(id)
        })
        .catch(Utils.reportError(
          'EgiszMedDocument:submitMedDocument()',
          reportErrorText('submit_message', 'emd')
        ))

      this.withSpinner(promise)
    },

    medDocumentSign () {
      if (!this.medDocument) {
        Utils.reportError(
          'EgiszMedDocument:medDocumentSign()',
          t('egisz.messages.errors.other.med_document_not_found')
        )

        return
      }

      this.medDocumentSignByScope(MED_DOCUMENT_HOLDER_SCOPE.PERSONAL)
    },

    medDocumentSignByScope (holderScope) {
      const data = {
        workplace_id: this.sign.workplaceId,
        certificate_id: this.sign.certificateId,
        local_machine: this.sign.localMachine,
        holder_scope: holderScope,
      }

      const promise = medDocumentEndpoint.sign(this.medDocument, data)
        .then(() => {
          Notificator.success(t('egisz.messages.success.document_signed'))
          this.vxRefreshFilters()
          this.$emit('successSign')
        })
        .catch(Utils.reportError(
          'EgiszMedDocumentForm:medDocumentSign()',
          t('utils_report_error.sign_message')
        ))

      this.withSpinner(promise)
    },

    pdfGeneratedRefresh () {
      if (!this.entryId) { return }

      const promise = entryEndpoint.getPdfGeneratedByEntryId(this.entryId)
        .then((pdfGenerated) => { this.medDocument.pdfGenerated = pdfGenerated })
        .catch(Utils.reportError('EgiszMedDocument:pdfGeneratedRefresh()'))

      this.withSpinner(promise)
    },
  },
}
</script>
