<template>
  <div class="company-nav-container">
    <panel-heading-collapsed
      direction="right"
      icon="fad fa-address-card"
      :show="!GET_MENU_SHOW"
      :title="companyTitle"
      @panel-collapsed-toggle="showMenuHandler"
    />

    <panel-heading
      v-if="GET_MENU_SHOW"
      class="company-nav-panel"
      custom-class="company-title-print"
      icon="fad fa-address-card"
      :show-tooltip="true"
      :title="companyTitle"
    >
      <template v-slot:buttons>
        <span
          class="fad fa-angle-left clickable hidden-print"
          @click="showMenuHandler"
        />
      </template>
      <div class="tabs-nav hidden-print">
        <div
          v-for="tab in tabs"
          :key="tab.name"
          v-tooltip="!tab.enabled && { text: t('insufficient_access_rights'), placement: 'right' }"
          class="tab-nav"
          :class="{
            'is-active': tab.routes.includes($route.name),
            'is-disabled': !tab.enabled
          }"
          @click.stop="tab.enabled && redirect(tab.name)"
        >
          <div class="tab-ico">
            <span :class="tab.ico" />
          </div>

          <div class="tab-text">
            {{ tab.text }}
          </div>
        </div>
      </div>
    </panel-heading>
    <div
      :class="[
        'company-container',
        { 'company-container-collapsed': !GET_MENU_SHOW }
      ]"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import PanelHeadingCollapsed from '@/vue_components/common/panel_heading_collapsed.vue'

import { mapGetters, mapMutations } from 'vuex'
import { STORAGE_KEY } from './consts.js'
import {
  COMPANY_DOCUMENTS,
  COMPANY_ORDERS,
  COMPANY_ORDERS_REGISTRIES,
  COMPANY_ORDERS_REGISTRY,
  COMPANY_PAYMENTS,
  COMPANY_SHOW,
} from '@/vue_components/router/modules/companies/route_names.js'
import { generateUID, getCurrentStorage } from '../helpers/storage.js'

export default {
  name: 'Company',

  components: {
    PanelHeading,
    PanelHeadingCollapsed,
  },

  data () {
    const uid = generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    const localStore = getCurrentStorage(STORAGE_KEY, uid)
    const companyInfo = { title: '...' }

    if (localStore.companyInfo) {
      companyInfo.title = localStore.companyInfo.title
    }

    return {
      companyInfo,
      tabs: [
        {
          name: COMPANY_SHOW,
          text: t('data_company'),
          ico: 'fad fa-fw fa-user',
          routes: [COMPANY_SHOW],
          enabled: true,
        },

        {
          name: COMPANY_ORDERS,
          text: t('orders'),
          ico: 'fad fa-fw fa-shopping-cart',
          routes: [COMPANY_ORDERS],
          enabled: Services.security.canManageCompany,
        },

        {
          name: COMPANY_ORDERS_REGISTRIES,
          text: t('orders_registries'),
          ico: 'fad fa-fw fa-cabinet-filing',
          routes: [COMPANY_ORDERS_REGISTRIES, COMPANY_ORDERS_REGISTRY],
          enabled: Services.security.canManageCompany,
        },

        {
          name: COMPANY_PAYMENTS,
          text: t('payments'),
          ico: 'fad fa-fw fa-money-bill',
          routes: [COMPANY_PAYMENTS],
          enabled: Services.security.canManageCompany,
        },

        {
          name: COMPANY_DOCUMENTS,
          text: t('documents'),
          ico: 'fad fa-fw fa-copy',
          routes: [COMPANY_DOCUMENTS],
          enabled: Services.security.canManageCompany,
        },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'GET_COMPANY_TITLE',
      'GET_MENU_SHOW',
    ]),

    currentStorage () {
      return generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    },

    companyTitle () {
      return this.companyInfo.title
    },
  },

  watch: {
    GET_COMPANY_TITLE (newValue) {
      if (newValue) {
        this.companyInfo.title = newValue
      }
    },

    companyInfo: {
      deep: true,
      handler (newState) {
        const oldStore = window.store.get(STORAGE_KEY) || {}
        window.store.set(STORAGE_KEY, {
          ...oldStore,
          [this.currentStorage]: {
            ...oldStore[this.currentStorage],
            companyInfo: { ...newState },
          },
        })
      },
    },
  },

  methods: {
    ...mapMutations([
      'SET_MENU_SHOW',
    ]),

    showMenuHandler () {
      this.SET_MENU_SHOW(!this.GET_MENU_SHOW)
    },

    redirect (routeName) {
      if (routeName === this.$route.name) return

      this.$router.push({
        name: routeName, params: { companyId: this.$route.params.companyId },
      })
    },
  },
}
</script>
