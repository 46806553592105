
export const CommonInputMethods = {
  methods: {
    __syncValue (value) {
      this.$updateSync('value', value)
    },

    onInput ($event) {
      this.$emit('input', $event)
      this.__syncValue($event)
    },

    onChange ($event) {
      const value = $event === '' ? null : $event
      this.$emit('change', value)
      this.__syncValue(value)
    },

    onClear () {
      this.$emit('clear', null)

      setTimeout(() => {
        if (this.required) { return }

        typeof this.__validate === 'function' && this.__validate()
      })
    },
  },
}
