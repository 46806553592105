<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
  >
    <el-input
      :id="id"
      type="textarea"
      :autosize="autosize"
      :rows="rows"
      class="m-textarea"
      :value="value"
      :clearable="clearable"
      :disabled="canManage.disabled"
      :placeholder="placeholder"
      :label="label"
      @input="onInput"
      @change="onChange"
      @click.native="$emit('click.native', $event)"
      @keyup.native="$emit('keyup.native', $event)"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
    />
    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :disabled="disabled"
      :focused="focused"
      :clarification="clarification"
    />
  </validation-wrapper>
</template>

<script>
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import MLabel from '@/vue_present/_base/inputs/MLabel'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'

const DEFAULT_ROWS_VALUE = 3

export default {
  name: 'MTextarea',
  components: { MLabel, ValidationWrapper },
  mixins: [
    CommonInputProps,
    CommonInputMethods,
    ValidationChildMixin,
    DisabledMixin,
  ],

  props: {
    value: { type: String, default: '' },
    rows: { type: Number, default: DEFAULT_ROWS_VALUE },
    autosize: { type: [Boolean, Object], default: false },
    clarification: { type: String, default: '' },
  },

  data () {
    return {
      focused: false,
    }
  },
}
</script>
