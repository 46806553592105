import { GLOBAL_HOLDER_SCOPES } from '@/_global_scripts/GLOBAL_CONSTS'
import { cloneDeep } from 'lodash'
import { orUndefined } from '@/_medods_standart_library/msl'
import { extractSemdType } from '@/vue_apps/Semds/const/extractSemdType'

export const semdAdapter = {

  /**
   * @param {Omit<ISemd, 'doctorSigner' | 'organizationSigner'>} data
   * @return {Record<string, string | number, Record<string, string | ICatalog>>}
   */
  toServer (data) {
    return {
      id: orUndefined(data.id),
      title: data.title,
      clientId: data.client?.id,
      clinicId: data.clinic?.id,
      date: data.date,
      semdType: extractSemdType(data.semdType),
      semdsDocumentDetailId: data.semdsDocumentDetailsAttributes.id,

      medicalCaseId: data.medicalCaseId,
      version: data.version,
      metaData: cloneDeep(data.metaData),
    }
  },

  /**
   * @param {Omit<ISemd, 'semdType'> | { semdType: string }} response
   * @return {ISemd}
   */
  toClient (response) {
    const semdType = extractSemdType(response.semdType)

    return {
      ...response,
      semdType,
    }
  },

  /**
   * @typedef {{
   *   id: number,
   *   userId: number,
   *   semdType: string
   *   signature: ISignature,
   * }} SemdSignatureData
   *
   * @param {SemdSignatureData} data
   */
  toSign (data) {
    return {
      semdId: data.id,
      signerId: data.userId || gon.application.current_user.id,
      workplaceId: data.signature.workplaceId,
      localMachine: data.signature.localMachine,
      certificateId: data.signature.thumbprint,
      holderScope: data.signature.holderScope,
      semdType: data.semdType,
    }
  },

  /**
   * @typedef {{
   *   ids: number[],
   *   userId: number,
   *   signature: ISignature,
   * }} MassSemdSignatureData
   *
   * @param {MassSemdSignatureData} data
   */
  toMassSign (data) {
    return {
      semdIds: data.ids || [],
      signerId: data.userId || gon.application.current_user.id,
      workplaceId: data.signature.workplaceId,
      localMachine: data.signature.localMachine,
      certificateId: data.signature.thumbprint,
      holderScope: GLOBAL_HOLDER_SCOPES.ORGANIZATION,
    }
  },
}
