<template>
  <div class="appointment-remind">
    <div
      v-if="notificationsModuleEnabled"
      class="appointment-remind__module-enabled"
    >
      <div class="appointment-remind__notification-checkboxes messages-settings-notification-checkbox">
        <m-switch
          :value="appointmentRemindSwitches.remind"
          :active-text="t('whatsapp.switchTitles.appointmentReminderFewHoursBeforeAppointment')"
          @change="setAppointmentRemindSwitchesRemind($event)"
        />
        <m-switch
          v-if="appointmentRemindSwitches.remind"
          :value="appointmentRemindSwitches.remindDefault"
          :active-text="t('whatsapp.switchTitles.enableSendingRemindersInAppointmentWindow')"
          @change="setAppointmentRemindSwitchesRemindDefault($event)"
        />
      </div>

      <div
        v-if="appointmentRemindSwitches.remind"
        class="appointment-remind__settings"
      >
        <messages-priorities
          v-if="messagesPriorities.length"
          :messages-priorities="messagesPriorities"
          @updatePriorities="setMessagesPriorities"
        />

        <whatsapp-timeout-settings
          :delivery-waiting-time="deliveryWaitingTime"
          :read-waiting-time="readWaitingTime"
          :delivery-waiting-time-variables="REMIND_DELIVERY_WAITING_TIME_VARIABLES"
          :read-waiting-time-variables="REMIND_READ_WAITING_TIME_VARIABLES"
          @updateDeliveryWaitingTime="setAppointmentRemindDeliveredToClientDurationLimit"
          @updateReadWaitingTime="setAppointmentRemindReadByClientDurationLimit"
        />

        <div class="appointment-remind__remind-template">
          <variables-editor
            :editor-value="smsTemplateValue"
            :editor-title="t('whatsapp.notificationTemplate')"
            :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
            :preview-request="fetchTemplatePreview"
            :variables-list="variablesListForEditor"
            required
            template-type="appointment"
            validator-name="remindTemplate"
            @registerValidator="onRegisterValidator"
            @changeEditorValue="setAppointmentRemindSmsTemplate"
          />
        </div>

        <variables-editor-whatsapp
          v-if="isWabaActive"
          :editor-value="wabaTemplateText"
          :variables-list="variablesListForEditor"
          @changeWabaEditorValue="setWabaTemplateText"
        >
          <template #templateSelector>
            <m-select
              :value="wabaTemplateId"
              :items="wazzupTemplatesList"
              option-label="title"
              value-key="templateGuid"
              :placeholder="t('whatsapp.chooseValue')"
              @change="insertTemplate"
            />
          </template>
        </variables-editor-whatsapp>

        <between-text
          :title="t('whatsapp.dispatchTime')"
          :text-before="t('whatsapp.sendFor')"
          :text-after="t('whatsapp.hoursBeforeAppointment')"
        >
          <m-input
            :clearable="false"
            type="number"
            :precision="0"
            :min="MIN_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS"
            :max="MAX_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS"
            :value="remindBefore"
            @input="setAppointmentRemindBefore"
          />
        </between-text>

        <between-text
          :text-before="t('whatsapp.sendAfterTime')"
          :text-after="t('whatsapp.hoursAfterAppointmentCreated')"
        >
          <m-input
            :clearable="false"
            type="number"
            :precision="0"
            :min="MIN_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS"
            :max="MAX_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS"
            :value="remindAfter"
            @input="setAppointmentRemindAfter"
          />
        </between-text>

        <div class="remind-time-clarification explanation explanation_solo">
          {{ t('whatsapp.remindTimeClarification') }}
        </div>
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="t('introduction.messages')"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  PRIORITY_BUTTONS_ITEMS,
  REMIND_DELIVERY_WAITING_TIME_VARIABLES,
  REMIND_READ_WAITING_TIME_VARIABLES,
  MIN_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS,
  MAX_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS,
  MIN_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS,
  MAX_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import {
  APPOINTMENT_VARIABLES_LIST,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import { variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import Introduction from '@/vue_components/introduction/introduction.vue'
import BetweenText from '@/vue_apps/app_configuration/messages/pages/components/BetweenText.vue'
import {
  findAndExtractWabaTemplateTextWithVariablesLabels,
  extractWabaTemplateText,
  replaceVariablesLabelsByValues,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'remind'

export default {
  name: 'AppointmentRemind',
  components: {
    Introduction,
    MSwitch,
    MessagesPriorities,
    MInput,
    MButton,
    MSelect,
    VariablesEditorWhatsapp,
    VariablesEditor,
    WhatsappTimeoutSettings,
    BetweenText,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaTemplateText: '',
      PRIORITY_BUTTONS_ITEMS,
      APPOINTMENT_VARIABLES_LIST,
      REMIND_DELIVERY_WAITING_TIME_VARIABLES,
      REMIND_READ_WAITING_TIME_VARIABLES,
      MIN_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS,
      MAX_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS,
      MIN_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS,
      MAX_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS,
      fetchTemplatePreview,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      appointmentRemindSwitches: 'vxGetAppointmentRemindSwitches',
      appointmentRemindSmsTemplate: 'vxGetAppointmentRemindSmsTemplate',
      messagesPriorities: 'vxGetMessagesPriorities',
      remindAfter: 'vxGetAppointmentRemindAfter',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      appointmentRemindWabaTemplateId: 'vxGetAppointmentRemindWabaTemplateId',
      wabaTemplateVariables: 'vxGetAppointmentRemindWabaTemplateVariables',
      remindBefore: 'vxGetAppointmentRemindBefore',
      deliveryWaitingTime: 'vxGetAppointmentRemindDeliveredWaitingTime',
      readWaitingTime: 'vxGetAppointmentRemindReadWaitingTime',
      isWabaActive: 'vxIsWabaActive',
    }),

    smsTemplateValue () {
      return this.appointmentRemindSmsTemplate
    },

    wabaTemplateId () {
      return { templateGuid: this.appointmentRemindWabaTemplateId }
    },

    variablesListForEditor () {
      return APPOINTMENT_VARIABLES_LIST.filter((el) => !el.disabled)
    },

    messagesModuleEnabled () {
      return gon.application.sms_module_enabled || gon.application.whatsapp_module_enabled
    },
  },

  created () {
    const baseRequest = this.vxFetchMessagesConfigurations()

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
      this.vxFetchMessagesPriorities(CURRENT_PRIORITY_TYPE),
    ]

    Promise.all([baseRequest, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
      vxFetchMessagesPriorities: 'vxFetchMessagesPriorities',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      setAppointmentRemindSwitchesRemind: 'vxSetAppointmentRemindSwitchesRemind',
      setAppointmentRemindSwitchesRemindDefault: 'vxSetAppointmentRemindSwitchesRemindDefault',
      setAppointmentRemindSmsTemplate: 'vxSetAppointmentRemindSmsTemplate',
      setMessagesPriorities: 'vxSetMessagesConfigurationsPriorities',
      setAppointmentRemindAfter: 'vxSetAppointmentRemindAfter',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setAppointmentRemindWabaTemplateId: 'vxSetAppointmentRemindWabaTemplateId',
      setAppointmentRemindBefore: 'vxSetAppointmentRemindBefore',
      setAppointmentRemindDeliveredToClientDurationLimit: 'vxSetAppointmentRemindDeliveredWaitingTime',
      setAppointmentRemindReadByClientDurationLimit: 'vxSetAppointmentRemindReadWaitingTime',
    }),

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
    },

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialWabaTemplateText()
    },

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.appointmentRemindWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.appointmentRemindWabaTemplateId,
          templateVariablesValues: this.wabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setAppointmentRemindWabaTemplateId(template?.templateGuid)
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const whatsappEditorValueToServer = replaceVariablesLabelsByValues(this.wabaTemplateText || '')

      const messagesConfigurations = {
        remind: this.appointmentRemindSwitches.remind,
        remindDefault: this.appointmentRemindSwitches.remindDefault,
        remindTemplate: this.smsTemplateValue,
        remindBefore: this.remindBefore,
        remindAfterHours: this.remindAfter,
      }
      const whatsappConfigurations = {
        remindWabaTemplateVariables: variablesParserWithChild(whatsappEditorValueToServer),
        appointmentRemindWabaTemplateId: this.appointmentRemindWabaTemplateId,
        appointmentRemindDeliveredWaitingTime: this.deliveryWaitingTime,
        appointmentRemindReadWaitingTime: this.readWaitingTime,
      }
      const channelsPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.messagesPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(channelsPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
