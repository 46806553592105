import { navigation } from '@/vue_apps/app_configuration/messages/router/navigation'

export const routes = [
  navigation.general,
  navigation.generalSmsSettings,
  navigation.generalCallsSettings,
  navigation.appointmentNotifySettings,
  navigation.appointmentRemindSettings,
  navigation.appointmentConfirmationSettings,
  navigation.clientsBirthdaySettings,
  navigation.servicesStatusSettings,
  navigation.onlineRecord,
  navigation.clientsFeedback,
  navigation.index,
]
