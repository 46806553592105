export const MIN_AGE = 16

export const MAX_AGE = 65

export const CHECKUP_AGE_ITEMS = Object.freeze(
  Array.from(
    { length: MAX_AGE - MIN_AGE + 1 },
    (_, i) => ({
      id: i + MIN_AGE,
      title: `${i + MIN_AGE}`,
    })
  )
)
