import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export const CommonInputProps = {
  props: {
    id: { type: String, required: false },

    label: PropsTypes.String(),
    clearable: { type: Boolean, default: true },
    mFixedHeight: { type: Boolean, default: true },
    placeholder: PropsTypes.String(),
    requiredTooltip: PropsTypes.Custom([String, Object]),
  },
}
