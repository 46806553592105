import { tryExtractNetrikaError } from '@/vue_apps/Semds/SemdModal/const/tryExtractNetrikaError'
import { getDateFormat } from '@/vue_apps/Semds/const/getDateFormat'

export const netrikaErrorParser = (err) => {
  const timestamp = moment(+err.timestamp).isValid()
    ? getDateFormat(+err.timestamp * 1000)
    : err.timestamp

  return {
    error: tryExtractNetrikaError(err.error),
    timestamp,
  }
}
