<template>
  <div>
    <m-select
      id="users"
      :value.sync="value"
      :items="items"
      label="Врачи"
      option-label="full_name"
    />

    <m-select
      id="users1"
      :value.sync="value"
      :items="items"
      label="Врачи"
      disabled
      option-label="full_name"
    />

    <m-select
      id="users2"
      :value.sync="value"
      :items="displayedItems"
      filterable
      clearable
      label="Врачи ext"
      option-label="full_name"
      @filterValueChange="onFilterValueChange"
      @loadMore="onLoadMore"
    />

    <m-select
      id="users3"
      :value.sync="multipleValueCollapse"
      :items="displayedItems"
      filterable
      multiple
      collapse-tags
      clearable
      label="Врачи multiple collapse"
      option-label="full_name"
      @filterValueChange="onFilterValueChange"
      @loadMore="onLoadMore"
    />

    <m-select
      id="users3"
      :value.sync="multipleValue"
      :items="displayedItems"
      filterable
      multiple
      clearable
      label="Врачи multiple"
      option-label="full_name"
      @filterValueChange="onFilterValueChange"
      @loadMore="onLoadMore"
    />
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { cloneDeep } from 'lodash'

export default {
  name: 'FrontBookPageMSelect',
  components: { MSelect },

  data () {
    return {
      value: null,
      multipleValue: [],
      multipleValueCollapse: [],
      offset: 0,
      limit: 10,
      totalItems: 0,
      totalPages: 1,
      items: [],
      displayedItems: [],
    }
  },

  created () {
    this.fetchUsers()
  },

  methods: {
    async fetchUsers () {
      const findNewItems = (newItems = []) =>
        newItems.filter((item) =>
          this.items.findIndex((option) => option.id === item.id) < 0)

      const sortParams = {
        clinic_id: gon.application.current_clinic.id,
        offset: this.offset,
        limit: this.limit,
      }

      const response = await doctorPresenter.fetchAll(sortParams)
      const newItems = findNewItems(response.data)

      this.items = [...this.items, ...newItems]
      this.displayedItems = cloneDeep(this.items)
      this.totalPages = response.total_pages
      this.totalItems = response.total_items
    },

    onLoadMore () {
      const currentOffset = this.offset
      const nextOffset = this.offset + this.limit
      if (nextOffset > this.totalItems) { return }
      this.offset = nextOffset
      this.fetchUsers()
        .catch(() => { this.offset = currentOffset })
    },

    onFilterValueChange (filterValue) {
      if (!filterValue) {
        this.displayedItems = cloneDeep(this.items)

        return
      }

      this.displayedItems = this.items
        .filter((item) => item.full_name.toLowerCase().includes(filterValue.toLowerCase()))
    },
  },
}
</script>
