import * as STUB_CONST from '@/specific/stubs/_helpers/constants'

const getModuleTitle = (name = '') =>
  t('introduction.module_title_template').replace('{0}', name)

const getOptionTitle = (name = '') =>
  t('introduction.option_title_template').replace('{0}', name)

const getModuleBody = (name = '') => t(`introduction.${name}`)
const getOptionBody = (name = '') =>
  t('introduction.option_body_template').replace('{0}', name)

export default Object.freeze({
  /* Модули */
  [STUB_CONST.MODULES.MESSAGES]: {
    isModule: true,
    icon: STUB_CONST.SMS_ICON,
    title: getModuleTitle(t('whatsapp.messages')),
    body: getModuleBody([STUB_CONST.MODULES.MESSAGES]),
  },

  [STUB_CONST.MODULES.CLIENT_WIDGET]: {
    isModule: true,
    icon: STUB_CONST.CLIENT_WIDGET_ICON,
    title: getModuleTitle(t('client_widget.online_recording')),
    body: getModuleBody([STUB_CONST.MODULES.CLIENT_WIDGET]),
  },

  [STUB_CONST.MODULES.TELEPHONY]: {
    isModule: true,
    icon: STUB_CONST.TELEPHONY_ICON,
    title: getModuleTitle(t('telephony.module_name')),
    body: getModuleBody([STUB_CONST.MODULES.TELEPHONY]),
  },

  /* Настройки */
  [STUB_CONST.OPTIONS.SMS_REMIND]: {
    icon: STUB_CONST.SMS_ICON,
    title: getOptionTitle(t(`sms.${STUB_CONST.OPTIONS.SMS_REMIND}`)),
    body: getOptionBody(t('whatsapp.messages')),
    url: '/configuration/messages/appointment-remind-settings',
  },

  [STUB_CONST.OPTIONS.SMS_NOTIFY]: {
    icon: STUB_CONST.SMS_ICON,
    title: getOptionTitle(t(`sms.${STUB_CONST.OPTIONS.SMS_NOTIFY}`)),
    body: getOptionBody(t('whatsapp.messages')),
    url: '/configuration/messages/appointment-notify-settings',
  },

  [STUB_CONST.OPTIONS.SMS_CONFIRMATION]: {
    icon: STUB_CONST.SMS_ICON,
    title: getOptionTitle(t(`sms.${STUB_CONST.OPTIONS.SMS_CONFIRMATION}`)),
    body: getOptionBody(t('whatsapp.messages')),
    url: '/configuration/messages/appointment-confirmation-settings',
  },

  [STUB_CONST.OPTIONS.UIS_REMIND]: {
    icon: STUB_CONST.TELEPHONY_ICON,
    title: getOptionTitle(t(`telephony.uis.${STUB_CONST.OPTIONS.UIS_REMIND}`)),
    body: getOptionBody(t('telephony.module_name')),
    url: Routes.telephony_configurations_path(),
  },

  [STUB_CONST.OPTIONS.UIS_CONFIRM]: {
    icon: STUB_CONST.TELEPHONY_ICON,
    title: getOptionTitle(t(`telephony.uis.${STUB_CONST.OPTIONS.UIS_CONFIRM}`)),
    body: getOptionBody(t('telephony.module_name')),
    url: Routes.telephony_configurations_path(),
  },

  [STUB_CONST.OPTIONS.CLIENT_WIDGET_AVAILABLE]: {
    icon: STUB_CONST.CLIENT_WIDGET_ICON,
    title: getOptionTitle(t(`client_widget.${STUB_CONST.OPTIONS.CLIENT_WIDGET_AVAILABLE}`)),
    body: t('introduction.not_authorized'),
  },
})
