import {
  getNsiCodesFromFilteredFactorsByExistingSpecialFactors,
} from '@/vue_apps/CheckupsModule/store/getNsiCodesFromFilteredFactorsByExistingSpecialFactors'
import { PERSONAL_CHECKUP_FACTORS_NSI_CODES } from '@/vue_apps/CheckupsModule/store/personalCheckupFactorsNsiCodes'

export const personalCheckupSpecialFactorsMixin = {
  computed: {
    availableSpecialFactors (): number[] {
      return getNsiCodesFromFilteredFactorsByExistingSpecialFactors(
        this.personalCheckup.factors,
        PERSONAL_CHECKUP_FACTORS_NSI_CODES
      )
    },

    hasSpecialFactors (): boolean {
      return Boolean(this.availableSpecialFactors.length)
    },
  },
}
