import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { tScoped } from '@/helpers/i18nHelpers'

const t$ = tScoped('checkups.documents')

export const documentTypeConfig: Record<string, { component: string; css: string; title: string }> = {
  [DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT]: {
    component: 'personal-simple-medical-assessment-view',
    css: 'personal-simple-medical-assessment',
    title: t$('personalSimpleMedicalAssessment.title'),
  },
  [DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT]: {
    component: 'personal-elmk-medical-assessment-view',
    css: 'personal-elmk-medical-assessment',
    title: t$('personalElmkMedicalAssessment.title'),
  },
  [DOCUMENT_TYPES.CHECKUPS_COMPANY_FINAL_ACT]: {
    component: 'company-final-act-view',
    css: 'company-final-act',
    title: t$('companyFinalAct.title'),
  },
} as const
