/*
  Moved from cu-nke0vt
 */

const ABORT_STATUS_TEXT = 'abort'
export const isRequestAbort = (error) => error.statusText && error.statusText === ABORT_STATUS_TEXT

export const stubRequest = (returnValue, delay = 500) => {
  let rejectRequest
  const promise = new Promise((resolve, reject) => {
    rejectRequest = reject
    setTimeout(() => resolve(returnValue), delay)
  })
  const ajax = {
    abort () {
      const error = new Error()
      error.statusText = ABORT_STATUS_TEXT
      rejectRequest(error)
    },
  }

  return {
    promise,
    ajax,
  }
}
