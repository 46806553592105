import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { expensesAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/expensesAdapter'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'

export class MExpensePresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'expense',
      location: 'MExpensePresenter',
      routes: {
        one: Routes.expense_path,
        all: Routes.expenses_path,
        list: Routes.api_internal_finances_expenses_path,
      },
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: (data) => camelToSnake(expensesAdapter.toServer(data)),
      toJson: true,
      ...config,
    })
      .then((res) => expensesAdapter.toClient(res))
  }

  submit (data, config = {}) {
    return super.submit(data, {
      toServerAdapter: (data) => camelToSnake(expensesAdapter.submitExpense(data)),
    })
  }

  destroy (id, config = {}) {
    return super.destroy(id, config)
  }

  fetchExpensesGroups () {
    const options = {
      url: Routes.list_api_internal_finances_expense_groups_path(),
    }

    const notification = new MRequestNotification(
      'MExpensePresenter:fetchExpensesGroups',
      'fetchMessage',
      'expenseGroups'
    )

    return this.get(options).promise
      .then((response) => Promise.resolve(response))
      .catch(MRequestError.withDefault([], notification))
  }

  saveExpenseGroup (data, config = {}) {
    const options = {
      url: Routes.expense_groups_path(),
      data: camelToSnake(data),
    }

    const notification = new MRequestNotification(
      'MExpensePresenter:saveExpenseGroup',
      'createMessage',
      'expenseGroups'
    )

    return this.post(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault('', notification))
  }
}
