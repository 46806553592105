export const getBaseRoute = (url = '') => '/configuration/messages' + url

export const getBaseName = (name) => 'messages-configurations-' + name

export const MESSAGES_CONFIGURATIONS_ROUTES = Object.freeze({
  INDEX: getBaseName(''),
  GENERAL: getBaseName('general'),
  GENERAL_SMS_SETTINGS: getBaseName('general-sms-settings'),
  GENERAL_CALLS_SETTINGS: getBaseName('general-calls-settings'),
  APPOINTMENT_NOTIFY: getBaseName('appointment-notify'),
  APPOINTMENT_REMIND: getBaseName('appointment-remind'),
  APPOINTMENT_CONFIRMATION: getBaseName('appointment-confirmation'),
  CLIENTS_BIRTHDAY: getBaseName('clients-birthday'),
  SERVICES_STATUS: getBaseName('services-status'),
  ONLINE_RECORD: getBaseName('online-record'),
  CLIENTS_FEEDBACK: getBaseName('clients-feedback'),
})
