<template>
  <m-select-lazy
    v-bind="$props"
    :fetch-method="(params) => nsiPresenter.list({ ...params, dictionary, version, permittedValues })"
    :use-first-time-fetch-one-if404="fetchOneMethod"
    :additional-displayed-filter="nsiIdFilter()"
    use-first-time-fetch
    filterable
    :required="required"
    v-on="$listeners"
  />
</template>

<script>
import { defineComponent } from 'vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'

export default defineComponent({
  name: 'ReusableLocalNSIList',
  components: { MSelectLazy },
  extends: MSelectLazy,

  props: {
    fetchMethod: { type: Function, default: null },
    dictionary: { type: String, required: true },
    version: { type: String, default: undefined },
    placeholder: { type: String, default: t('choose') },
    permittedValues: { type: Array, default: undefined },
    useFetchOne: Boolean,
  },

  data () {
    return {
      nsiPresenter: new MNSIPresenter(),
    }
  },

  computed: {
    fetchOneMethod () {
      return this.useFetchOne
        ? (id) => this.nsiPresenter.fetch(id)
        : undefined
    },
  },

  methods: {
    nsiIdFilter () {
      return (searchQueryInLowerCase = '', filteredItem = {}) => {
        const filteredNsiIdLowerCase = filteredItem?.nsiId?.toLowerCase() || ''

        return filteredNsiIdLowerCase.includes(searchQueryInLowerCase)
      }
    },
  },
})
</script>
