import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'
import { lazyTreeLeafNodeIdPrefix } from '@/vue_present/Reuse/LazyTree/store/lazyTreeLeafNodeIdPrefix'

export const getCheckupDocumentsExcludedNodes = () => ({
  [REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED]: [
    `${lazyTreeLeafNodeIdPrefix}certificate_payments`,
  ],
})
