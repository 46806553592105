export const HAS_ERROR_CLASS = 'has-error'
export const UNDER_FIELD_CLASS = 'under_field_error'
export const TAB_CONTENT_SELECTOR_CLASS = '.tab-content'
export const TAB_PANE_CLASS = '.tab-pane'
export const TAB_HAS_ERROR_CLASS = 'tab-has-error'

/**
 * Вернет текущую вкладку (tab)
 * @param {Element} element
 * @returns {*|jQuery}
 */
const findCurrentTab = (element) => {
  const closestTabId = $(element)
    .parents(TAB_PANE_CLASS)
    .attr('id')

  return $(element)
    .parents(TAB_CONTENT_SELECTOR_CLASS)
    .siblings()
    .find(`[href="#${closestTabId}"]`)
    .parent()
}

/**
 * Отрисовка сообщения валидации для элемента DOM
 * @param {String} selector
 * @param {String[]} errors
 */
export const renderLegacyValidationWrapper = (selector, errors, withTab = false) => {
  const element = document.querySelector(selector)
  if (!element) { return }

  const parent = element.parentNode
  parent.classList.add(HAS_ERROR_CLASS)

  const messageElement = document.createElement('div')
  messageElement.classList.add(UNDER_FIELD_CLASS)
  messageElement.innerText = errors.join(', ')

  parent.append(messageElement)

  if (withTab) {
    findCurrentTab(element).addClass(TAB_HAS_ERROR_CLASS)
  }
}

/**
 * Удаление сообщения валидации
 * @param {String} selector
 */
export const removeLegacyValidationWrapper = (selector, withTab = false) => {
  const element = document.querySelector(selector)
  if (!element) { return }

  const parent = element.parentNode
  parent.classList.remove(HAS_ERROR_CLASS)
  if (parent.lastChild && parent.lastChild.classList.contains(UNDER_FIELD_CLASS)) {
    parent.removeChild(parent.lastChild)
  }

  if (withTab) {
    findCurrentTab(element).removeClass(TAB_HAS_ERROR_CLASS)
  }
}
