import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { diseaseAdapter } from '@/_api/catalogs/Disease/diseaseAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'

/**  @implements ILazyTreeApiPresenter */
export class MDiseasePresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'disease',
      routes: {
        one (id) {},
        all () {},
        list () {},
      },
      location: 'MDiseasePresenter',
    })
  }

  fetchNode (id, categoryType) {
    const notification = new MRequestNotification(
      'MDiseasePresenter',
      'fetchMessage',
      'disease'
    )

    return this.post(
      {
        url: Routes.diseases_categories_path(),
        data: camelToSnake({ id, categoryType }),
      },
      (data) => diseaseAdapter.toClient(snakeToCamel(data))
    )
      .promise
      .catch(MRequestError.withDefault([], notification))
  }
}
