<template>
  <div class="reusable-sign-form w-100 flex flex-column gap-indent-validation mb-indent-validation">
    <div
      v-loading="vxGetWorkplacesIsLoading"
      class="flex gap-indent-small"
    >
      <m-select
        :value="currentWorkplace"
        :label="t('egisz.components.egisz_med_documents.form.workplace')"
        :items="vxGetWorkplaces"
        :m-fixed-height="false"
        :disabled="vxGetWorkplacesIsLoading || vxGetCertificatesIsLoading"
        @change="onWorkplaceChange"
        @openOptions="fetchWorkplaces"
      />

      <m-button
        template="short_refresh"
        @click="vxFetchWorkplaces({ connected })"
      />
    </div>

    <div
      v-loading="vxGetCertificatesIsLoading"
      class="flex gap-indent-small"
    >
      <m-select
        :value="value"
        :items="vxGetCertificates"
        :disabled="!currentWorkplace || vxGetCertificatesIsLoading"
        :label="t('egisz.components.egisz_med_documents.form.certificate')"
        :m-fixed-height="false"
        @change="onThumbPrintChange"
      />

      <m-button
        :disabled="!currentWorkplace"
        template="short_refresh"
        @click="onCertificateReload"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  name: 'ReusableSignForm',
  components: { MButton, MSelect },

  model: {
    prop: 'value',
    event: 'setThumbprint',
  },

  props: {
    value: { type: Object, default: null },
    connected: { type: Boolean, default: true },
    localMachine: Boolean,
  },

  emit: ['setThumbprint'],

  data () {
    return {
      currentWorkplace: null,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/workplacesStore', {
      vxGetWorkplaces: 'vxGetWorkplaces',
      vxGetCertificates: 'vxGetCertificates',
      vxGetWorkplacesIsLoading: 'vxGetWorkplacesIsLoading',
      vxGetCertificatesIsLoading: 'vxGetCertificatesIsLoading',
    }),
  },

  methods: {
    ...mapActions('globalCatalogs/workplacesStore', {
      vxFetchWorkplaces: 'vxFetchWorkplaces',
      vxFetchCertificates: 'vxFetchCertificates',
    }),

    async fetchWorkplaces () {
      await this.vxFetchWorkplaces({ connected: this.connected })
    },

    onWorkplaceChange (workplace) {
      this.currentWorkplace = workplace
      if (!workplace) {
        this.$emit('setThumbprint', null)

        return
      }

      this.onCertificateReload()
    },

    async onCertificateReload () {
      if (!this.currentWorkplace?.id) { return }

      await this.vxFetchCertificates({
        localMachine: this.localMachine,
        workplaceId: this.currentWorkplace.id,
      })
    },

    onThumbPrintChange (value) {
      this.$emit('setThumbprint', {
        ...value,
        workplaceId: this.currentWorkplace.id,
        localMachine: this.localMachine,
      })
    },
  },
})
</script>
