import { FeedbackLogic } from '@/vue_apps/FeedbackModule/entities/FeedbackLogic'
import { MFeedbackPresenter } from '@/_api/MFeedback/MFeedbackPresenter'

export class FeedbackApi extends FeedbackLogic {
  presenter = new MFeedbackPresenter()

  async load () {
    const result = await this.presenter.fetch(this.id)

    if (!result?.errors) {
      this.fillProps(result)

      return
    }

    return result
  }

  async save () {
    const result = await this.presenter.submit(this.getAttributes())

    if (!result?.errors) {
      this.id = result.id

      return
    }

    return result
  }
}
