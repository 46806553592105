import { MRequest } from '@/lib/transport/MRequest'

class EntryTypes extends MRequest {
  find (data) {
    const options = {
      url: Routes.search_entry_types_path(),
      data,
    }

    return super.getAll(options).promise
  }

  fetchEntryTypesBreadcrumbs (data) {
    const options = {
      url: Routes.entry_type_category_breadcrumbs_path(data),
    }

    return super.get(options).promise
  }
}

export const entryTypesEndpoint = new EntryTypes()
