import { DATE_PICKER_BUTTONS, dateButtons } from '@/vue_present/_base/MDatePickerButtons/helpers'

export const getDefaultExpensesFilters = () => {
  return {
    expenseGroupIds: null,
    source: null,
    category: null,
    consumable: null,
    searchString: null,
    date: dateButtons[DATE_PICKER_BUTTONS.TODAY].value,
    clinicIds: [gon.application.current_clinic.id],
  }
}
