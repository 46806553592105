/** Полис ОМС единого образца, бессрочный */
export const OMS_SINGLE_SAMPLE_UNLIMITED_NSI_ID = '2'

export const isPolicyActive = ({
  active,
  dateStart = null,
  dateEnd = null,
  currentDate = new Date(),
  nsiOmsTypeNsiId = null,
}) => {
  if (!active) { return false }

  const isSingleSampleUnlimited = nsiOmsTypeNsiId === OMS_SINGLE_SAMPLE_UNLIMITED_NSI_ID

  if (active && !dateEnd && !isSingleSampleUnlimited) { return true }

  const momentCurrentDate = moment(currentDate)
  const momentDateStart = moment(dateStart)
  const momentDateEnd = moment(dateEnd)

  if (!momentDateStart.isValid() ||
    (!momentDateEnd.isValid() && !isSingleSampleUnlimited) ||
    !momentCurrentDate.isValid()
  ) {
    return false
  }

  const isCurrentAfterDateStart = momentCurrentDate.isAfter(dateStart)
  const isCurrentBeforeDateEnd = momentCurrentDate.isBefore(dateEnd)
  const isSameStartDate = momentDateStart.isSame(currentDate, 'day')
  const isSameEndDate = momentDateEnd.isSame(currentDate, 'day')

  /**
   * БЛ: Полис ОМС единого образца, бессрочный.
   *     Конечная дата не требуется
   */
  if (isSingleSampleUnlimited) {
    return active && (isCurrentAfterDateStart || isSameStartDate)
  }

  return active &&
    (isCurrentAfterDateStart || isSameStartDate) &&
    (isCurrentBeforeDateEnd || isSameEndDate)
}
