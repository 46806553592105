import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { customPropsValidators } from '@/vue_present/_base/buttons/MButton/CustomPropsValidators'

export const props = {
  id: { type: String, default: '' },

  /** @param {'fill' | 'plain' | 'round' | 'circle'} */
  buttonStyle: PropsTypes.String('plain', false, customPropsValidators.buttonStyleValidator),

  /** @param {'primary' | 'success' | 'warning' | 'danger' | 'info' | 'text' | 'default' | 'dark'} */
  type: PropsTypes.String('', false, customPropsValidators.typeValidator),

  /** @param {'medium | small | mini'} */
  size: PropsTypes.String('small'),

  loading: Boolean,

  text: PropsTypes.Custom([String, Number]),

  icon: PropsTypes.String(),

  noUseFw: { type: Boolean, default: true },

  plusIcon: Boolean,

  iconColor: PropsTypes.String(),

  template: PropsTypes.String(),

  tooltip: PropsTypes.String(),

  active: Boolean,

  autofocus: Boolean,

  useBrandIcon: Boolean,

  useSolidIcon: Boolean,
}
