import { ExpensesListCellItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/ExpensesListCellItem'
import { orUndefined } from '@/_medods_standart_library/msl'
import { DateHelpers } from '@/helpers/DateHelpers'
import { ExpensesListTotalItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/ExpensesListTotalItem'
import { strToBoolean } from '@/helpers/typeHelpers'

export const expensesAdapter = {
  toServer (data) {
    if (data.searchQuery) {
      return {
        searchString: data.searchQuery,
        clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
        limit: data.limit,
        offset: data.offset,
      }
    }

    return {
      expenseGroupIds: data.expenseGroupIds ? [data.expenseGroupIds] : undefined,
      source: orUndefined(data.source),
      category: orUndefined(data.category),
      consumable: data.consumable ? strToBoolean(data.consumable) : undefined,
      date: orUndefined(DateHelpers.toBasePeriod(data.date), true),
      clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
      limit: data.limit,
      offset: data.offset,
    }
  },

  toClient (response) {
    response.data = response.data.map((item) => new ExpensesListCellItem(item))

    response.total = new ExpensesListTotalItem(response.totals)

    return response
  },

  submitExpense (data) {
    return {
      expense: {
        ...data,
        id: data.number,
        date: moment(data.date).format('DD.MM.YYYY'),
        expenseGroupId: data.expenseGroup?.id,
      },
    }
  },
}
