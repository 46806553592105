<template>
  <input
    ref="date"
    type="text"
    v-bind="$attrs"
    v-on="$listeners"
  >
</template>

<script>

export default {
  mounted () {
    const vm = this
    const inputDate = $(this.$refs.date)

    inputDate.inputmask('date', {
      placeholder: '__.__.___',
    })

    inputDate.on('input', function () {
      vm.$emit('input', this.value)
    })
  },
}
</script>
