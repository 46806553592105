<template>
  <m-buttons-group
    v-model="vmLetter"
    :items="alphabet"
    :m-fixed-height="false"
    radio
    size="mini"
    use-custom-result="simple"
  />
</template>

<script>
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { getAlphabet } from '@/vue_apps/ClientsModule/components/ClientsList/store/clientListFilters'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { DEFAULT_FILTRATION_DEBOUNCE } from '@/vue_components/store/modules/filters_base'

const localBinding = (field) => ({
  get () { return this.filters[field] },
  set (value) { this.$emit('setFilter', { field, value }) },
})

export default {
  name: 'ClientsListFilters',
  components: { MButtonsGroup },

  props: {
    filters: PropsTypes.Object({}),
  },

  emits: [
    'setFilter',
    'filtersUpdated',
  ],

  computed: {
    alphabet () {
      return getAlphabet()
    },

    vmLetter: localBinding('letter'),
  },

  watch: {
    filters () {
      if (this.filtersTimeout) {
        clearTimeout(this.filtersTimeout)
        this.filtersTimeout = null
      }

      this.filtersTimeout = setTimeout(() => {
        this.$emit('filtersUpdated')
      }, DEFAULT_FILTRATION_DEBOUNCE)
    },
  },
}
</script>
