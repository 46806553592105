import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class ExpensesListTotalItem {
  constructor (rawData) {
    this.number = new MTableCell(t('total'))

    this.date = new MTableCell()

    this.title = new MTableCell()

    this.price = new MTableCell(vueFilters.currency(rawData.price))

    this.additional = new MTableCell()

    this.expenseGroupTitle = new MTableCell()

    this.orderId = new MTableCell()
  }
}
