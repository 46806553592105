<template>
  <m-panel
    :title="title"
    icon="fileAlt"
    class="fixed-document"
    :use-print-button="$route.name.endsWith('-show')"
    body-css-classes="fixed-document__body"
  >
    <div class="fixed-document__container">
      <router-view
        :client-id="clientId"
        :document-id="documentId"
        @notFound="$router.replace({ path: '/fixed-document/not-found' })"
      />
    </div>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'

export default {
  name: 'FixedDocumentApp',

  components: {
    MPanel,
  },

  computed: {
    title () {
      if (this.$route.name.endsWith('-new')) {
        return t('creating_document')
      }

      if (this.$route.name.endsWith('-edit')) {
        return t('editing_document')
      }

      return this.$route.meta.title
    },

    clientId () {
      return Number(this.$route.query.clientId) || null
    },

    documentId () {
      return Number(this.$route.params.documentId) || null
    },
  },
}
</script>
