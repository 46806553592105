<template>
  <span
    v-tooltip="{tooltip, placement: 'top-start'}"
    class="m-table-row-cell__struct"
    :class="css"
  >
    {{ value }}
  </span>
</template>

<script>

export default {
  name: 'TypeStruct',
  props: [
    'tooltip',
    'value',
    'css',
  ],
}
</script>
