<template>
  <div
    class="m-table-row cursor-pointer"
    :class="{
      'm-table-row_deleted': deleted,
      'm-table-row_sortable': useSortable,
      'is-active': isActive
    }"
    v-bind="dataAttributes"
    @click="onClick"
    @click.right="$emit('rightClick')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MTableRow',
  props: {
    deleted: Boolean,
    useIgnoreClick: Boolean,
    useSortable: Boolean,

    isActive: Boolean,
    dataAttributes: { type: Object, default: () => ({}) },
  },

  emits: [
    'rowClick',
    'rightClick',
  ],

  methods: {
    onClick ($event) {
      if (this.useIgnoreClick) { return }

      this.$emit('rowClick', $event)
    },
  },
}
</script>
