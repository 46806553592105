<template>
  <div
    v-loading="loading || !getCaseCatalogsLoaded"
    class="egisz-case"
  >
    <card-registration
      class="egisz-case__card egisz-case__card_registration"
      :register-in-egisz.sync="tempMedCase.register_in_egisz"
      :registration-status="tempMedCase.netrika_status"
      :read-only="readOnly"
    />

    <card-information
      class="egisz-case__card egisz-case__card_information"
      :client="tempClient"
      :legal-representative-id.sync="tempMedCase.legal_representative_id"
      :extended-legal-representatives="tempClientLegalRepresentatives"
      :netrika-payment-type-id.sync="tempMedCase.netrika_payment_type_id"
      :opened-at.sync="tempMedCase.opened_at"
      :closed-at.sync="tempMedCase.closed_at"
      :doctor-id.sync="tempMedCase.doctor_id"
      :netrika-visit-type-id.sync="tempMedCase.netrika_case_visit_type"
      :netrika-case-type-id.sync="tempMedCase.netrika_case_type_id"
      :netrika-confidentiality-id.sync="tempMedCase.netrika_confidentiality"
      :validation-messages="tempMedCaseValidations"
      :read-only="readOnly"
      :closed="tempMedCase.closed"
      @setRelationTypeId="setRelationTypeId"
    />

    <card-steps
      class="egisz-case__card egisz-case__card_steps"
      :steps="tempMedCase.steps"
      :client="tempClient"
      :steps-validations="tempMedCaseStepsValidations"
      :read-only="readOnly"
      :validation-messages="tempMedCaseValidations"
      @applyStep="applyStep"
      @deleteStep="deleteStep"
    />

    <card-conclusion
      class="egisz-case__card egisz-case__card_conclusion"
      :case-result-id.sync="tempMedCase.netrika_case_result_id"
      :diagnosis-id.sync="tempMedCase.diagnosis.disease_id"
      :diagnosis-date.sync="tempMedCase.diagnosis.date"
      :diagnosis-comment.sync="tempMedCase.diagnosis.comment"
      :diagnosis-type.sync="tempMedCase.diagnosis.diagnosis_type_id"
      :conclusion-text.sync="tempMedCase.comment"
      :validation-messages="tempMedCaseValidations"
      :read-only="readOnly"
    />

    <div
      v-if="canManage"
      class="egisz-case__footer"
    >
      <template v-if="!readOnly">
        <button
          type="button"
          class="btn btn-sm btn-success btn-with-icon mr-5"
          @click="onSubmit()"
        >
          <i class="fad fa-save btn-with-icon_icon" />
          <span>{{ t('save') }}</span>
        </button>

        <popover
          yes-style="success"
          :disable-confirm-btn="!canCloseMedCase"
          :disabled="!canCloseMedCase"
          @yes="onSubmit(true)"
        >
          <template #message>
            <i class="fad fa-fw fa-question-circle red" />
            {{ t('egisz.medical_cases.form.close_confirmation_text') }}
          </template>

          <span
            slot="reference"
            v-tooltip="!canCloseMedCase && t('egisz.medical_cases.has_empty_fields', [canCloseFieldsNames])"
          >
            <button
              type="button"
              :disabled="!canCloseMedCase"
              class="btn btn-sm btn-success btn-with-icon"
            >
              <i class="fad fa-check-circle btn-with-icon_icon" />
              <span>{{ t('egisz.medical_cases.form.close') }}</span>
            </button>
          </span>
        </popover>
      </template>

      <div
        v-else
        class="flex align-center"
      >
        <button
          type="button"
          class="btn btn-sm btn-primary btn-with-icon"
          @click="reopenCase"
        >
          <i class="fad fa-lock-open btn-with-icon_icon" />
          {{ t('egisz.medical_cases.form.re_open') }}
        </button>
        <span class="prompt-notice ml-5">
          {{ t('egisz.medical_cases.form.close_case_prompt') }}
        </span>
      </div>

      <div class="flex-grow-1" />

      <popover
        v-if="medCaseId && !isStatusRegistered"
        placement="top-end"
        @yes="deleteMedCase"
      >
        <template #message>
          <i class="fad fa-fw fa-question-circle red" />
          {{ t('egisz.medical_cases.form.delete_confirmation_text') }}
        </template>

        <button
          slot="reference"
          type="button"
          class="btn btn-sm btn-danger btn-with-icon mr-5"
        >
          <i class="fad fa-exclamation-circle btn-with-icon_icon" />
          <span>{{ t('delete') }}</span>
        </button>
      </popover>

      <button
        type="button"
        class="btn btn-sm btn-primary btn-with-icon"
        @click="$emit('close')"
      >
        <i class="fas fa-times btn-with-icon_icon" />
        <span>{{ t('close') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { createEmptyMedCase } from '@/vue_components/egisz/egisz_module/const/egisz_entities'
import CardRegistration
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardRegistration'
import CardInformation
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardInformation'
import CardSteps from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardSteps'
import CardEmd from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardEmd'
import CardConclusion
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardConclusion'
import Popover from '@/vue_components/common/popover/popover'
import { cloneDeep } from 'lodash'
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import { egiszCasesEndpoint } from '@/api_entities/egisz/cases/egisz_cases_endpoint'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { mapGetters, mapMutations } from 'vuex'
import EgiszCaseValidationMethods from '@/vue_components/egisz/egisz_module/mixins/EgiszCaseValidationMethods'
import { medDocumentEndpoint } from '@/api_entities/egisz/med_documents/med_document_endpoint'
import { CREATE_MED_CASE_INDEX } from '@/vue_components/egisz/egisz_medical_cases_tab/const'
import { softUpdateKeys } from '@/vue_components/egisz/egisz_module/helpers/case_helpers'
import {
  checkCaseFields,
  getTextOfEmptyFields,
} from '@/vue_components/egisz/egisz_module/composables/check_case_fields'
import { getUserIdInDoctorList } from '@/vue_components/egisz/egisz_module/composables/helpers'
import { EGISZ_STATUSES } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export default {
  name: 'EgiszCase',

  components: {
    CardEmd,
    Popover,
    CardConclusion,
    CardSteps,
    CardInformation,
    CardRegistration,
  },

  mixins: [EgiszCaseValidationMethods, SpinnerHolder],

  props: {
    medCaseId: {
      type: Number,
      default: null,
    },

    client: {
      type: Object,
      required: true,
    },

    canManage: Boolean,
  },

  data () {
    return {
      tempMedCase: createEmptyMedCase(),
      tempClient: cloneDeep(this.client),
      tempClientLegalRepresentatives: [],

      tempEntryIdsString: '',

      medDocumentsLoading: false,

      canCloseMedCase: false,
      canCloseFieldsNames: '',
    }
  },

  computed: {
    ...mapGetters('medCases', {
      getCaseCatalogsLoaded: 'GET_CASE_CATALOGS_LOADED',
      getRelativeTypeTitle: 'GET_RELATIVE_TYPE',
    }),

    ...mapGetters({
      getCurrentClinicId: 'GET_APP_CONF_CURRENT_CLINIC_ID',
      getCurrentUserId: 'GET_APP_CONF_CURRENT_USER_ID',
    }),

    ...mapGetters('egisz/egiszWs', {
      wsMedDocument: 'vxGetLastMedDocument',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      vxDoctors: 'getDoctors',
      vxDoctorsIsLoaded: 'getDoctorsIsLoaded',
    }),

    /**
     * @return {boolean}
     */
    readOnly () {
      return this.tempMedCase.closed || !this.canManage
    },

    isStatusRegistered () {
      return this.tempMedCase && this.tempMedCase.netrika_status === EGISZ_STATUSES.SUCCESS
    },
  },

  watch: {
    client (to) {
      this.tempClient = cloneDeep(to)
      this.loadClientLegalRepresentatives()
    },

    medCaseId (to) {
      to !== CREATE_MED_CASE_INDEX
        ? this.loadMedCase(to)
        : this.createMedCase(true)
    },

    // слишком глубоко происходят изменения, computed не отрабатавает
    'tempMedCase.steps': {
      deep: true,
      immediate: true,
      handler () {
        this.getMedDocumentsByEntriesIds(true)
      },
    },

    tempMedCase: {
      deep: true,
      handler (to) {
        const emptyFields = checkCaseFields(to)
        this.canCloseMedCase = !emptyFields.hasEmpty
        this.canCloseFieldsNames = getTextOfEmptyFields(emptyFields)
      },
    },

    wsMedDocument () {
      this.getMedDocumentsByEntriesIds(true)
    },

    vxDoctorsIsLoaded () {
      if (this.tempMedCase.doctor_id) { return }
      this.tempMedCase.doctor_id = this.getCurrentUserId
    },
  },

  created () {
    this.loadClientLegalRepresentatives()
  },

  methods: {
    ...mapMutations('egiszEmd/filtersBase', {
      refreshEMDFilters: 'vxRefreshFilters',
    }),

    setDefaultCaseData () {
      const injectedAttributes = {
        client_id: this.tempClient.id,
        clinic_id: this.getCurrentClinicId,
        netrika_history_number: this.tempClient.id,
        register_in_egisz: this.client.send_data_to_egisz,
      }

      this.tempMedCase = createEmptyMedCase(injectedAttributes)

      this.resetMedCaseStepsValidations()
      this.resetMedCaseValidations()
    },

    createMedCase () {
      this.setDefaultCaseData()

      const doctorId = getUserIdInDoctorList(this.vxDoctors, this.getCurrentUserId)

      const medCaseData = {
        clinic_id: this.getCurrentClinicId,
        client_id: this.tempClient.id,
        doctor_id: doctorId,
        author_id: doctorId,
      }

      const promise = egiszCasesEndpoint.create(medCaseData)
        .then((data) => {
          this.tempMedCase = softUpdateKeys(this.tempMedCase, data)
          this.tempMedCase.register_in_egisz = this.client.send_data_to_egisz
          this.$emit('updateList')
        })

      this.withSpinner(promise)
    },

    loadMedCase (medCaseId) {
      if (!medCaseId) { return }

      const promise = egiszCasesEndpoint.get(medCaseId)
        .then((data) => {
          this.tempMedCase = {
            ...this.tempMedCase,
            ...data,
            id: medCaseId,
            register_in_egisz: this.client.send_data_to_egisz,
          }
        })
        .catch((err) => {
          Utils.reportError(
            'EgiszCase:loadMedCase()',
            reportErrorText('read_message', 'med_case')
          )(err)
        })

      this.withSpinner(promise)
    },

    deleteMedCase () {
      if (!this.tempMedCase.id) { return }
      this.$emit('deleteCase', this.tempMedCase.id)
    },

    loadClientLegalRepresentatives () {
      clientsEndpoint.getRelations(this.tempClient.id)
        .then((data) => { this.tempClientLegalRepresentatives = data })
        .catch((err) => {
          Utils.reportError(
            'EgiszCase:loadClientLegalRepresentatives()',
            reportErrorText('read_message', 'legal_representatives')
          )(err)
        })
    },

    applyStep (step) {
      const existStep = this.tempMedCase.steps.find((item) => item.id === step.id)
      if (existStep) {
        Object.keys(step).forEach((key) => {
          existStep[key] = cloneDeep(step[key])
        })

        return
      }

      this.tempMedCase.steps.push(step)
    },

    deleteStep (stepId) {
      this.tempMedCase.steps = this.tempMedCase.steps.filter((item) => item.id !== stepId)
    },

    onSubmit (closeCase = false) {
      const medCase = cloneDeep(this.tempMedCase)
      medCase.closed = closeCase

      const promise = egiszCasesEndpoint.submit(medCase)
        .then(() => { this.reactOnSubmit() })
        .catch((err) => {
          if (err.status === 422) {
            this.prepareCaseValidations(err)

            return
          }

          Utils.reportError(
            'EgiszCase:onSubmit()',
            reportErrorText('submit_message', 'med_case')
          )(err)
        })

      this.withSpinner(promise)
    },

    reopenCase () {
      const promise = egiszCasesEndpoint.reopen(this.tempMedCase.id)
        .then(() => {
          this.tempMedCase.closed = false
          this.$emit('updateList')
        })
        .catch((err) => {
          Utils.reportError(
            'EgiszCase:reopenCase()',
            reportErrorText('open_message', 'med_case')
          )(err)
        })

      this.withSpinner(promise)
    },

    loadMedDocumentsByEntriesIds (entriesIds) {
      if (entriesIds && !entriesIds.length) {
        this.tempMedCase.medical_documents = []

        return
      }

      const data = {
        clinic_id: this.getCurrentClinicId,
        entry_ids: entriesIds,
      }

      const promise = medDocumentEndpoint.getAll(data)
        .then(({ data }) => { this.tempMedCase.medical_documents = data })
        .catch((err) => {
          Utils.reportError(
            'EgiszCase:loadMedDocumentsByEntriesIds()',
            reportErrorText('close_message', 'emds')
          )(err)
        })

      this.withSpinner(promise, 'medDocumentsLoading')
    },

    reactOnSubmit () {
      Notificator.success(t('egisz.messages.success.case_submitted'))
      this.refreshEMDFilters() // Необходимо, если ЭМД был добавлен/удален в кейс
      const successSubmit = true
      this.$emit('close', successSubmit)
      this.setDefaultCaseData()
    },

    getMedDocumentsByEntriesIds (ignoreCheck = false) {
      const entriesIds = this.getEntriesIds()
      const entriesIdsString = entriesIds.toString()
      if (!ignoreCheck && entriesIdsString === this.tempEntryIdsString) { return }
      this.tempEntryIdsString = entriesIdsString

      this.loadMedDocumentsByEntriesIds(entriesIds)
    },

    getEntriesIds () {
      return this.tempMedCase.steps.map((step) => step.entry_ids || []).flat()
    },

    setSignStatus (medDocumentId) {
      const medDocument = this.tempMedCase.medical_documents.find((item) => item.id === medDocumentId)
      if (!medDocument) { return }

      medDocument.attachment_signature_status.personal_signed = true
    },

    setRelationTypeId (relationTypeId) {
      const relationData = {
        legal_representative_id: this.tempMedCase.legal_representative_id,
        relation_type_id: relationTypeId,
        relation_type_title: this.getRelativeTypeTitle(relationTypeId, 'title', ''),
      }

      clientsEndpoint.setRelationRemote(this.client.id, relationData)
        .then(() => {
          this.updateLegalClientRepresentative(relationData)
          Utils.reportSuccess(t('egisz.success_change_relation_type'), t('egisz.title'))()
        })
        .catch(Utils.reportError(reportErrorText('update_message', 'relative_type')))
    },

    updateLegalClientRepresentative (relationData) {
      const legal = this.tempClientLegalRepresentatives
        .find((item) => item.legal_representative_id === relationData.legal_representative_id)

      if (!legal) { return }

      legal.relation_type_id = relationData.relation_type_id
      legal.relation_type_title = relationData.relation_type_title
      legal.full_name_with_relation = relationData.relation_type_id
        ? `${legal.full_name} (${relationData.relation_type_title})`
        : legal.full_name
    },
  },
}
</script>
