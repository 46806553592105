<template>
  <a
    v-tooltip="tooltip || t('download')"
    :href="url"
    target="_blank"
    class="m-table-cell__download"
    :class="[css]"
    :download="value"
    @click.stop=""
  >
    <m-icon
      icon="download"
      color="primary"
    />
  </a>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'TypeDownload',
  components: { MIcon },
  props: [
    'tooltip',
    'value',
    'css',
    'url',
  ],
}
</script>
