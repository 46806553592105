import { ICompanyFinalAct } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalAct'
import { CompanyFinalActModel } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActModel'
import { ICompanyFinalActUpdate } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActUpdate'
import {
  ICompanyFinalActWorkerWithDiagnosis,
  ICompanyFinalActWorkerWithDiagnosisStringContraindicationFactors,
} from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorkerWithDiagnosis'

const getMonth = (month: string):string => {
  const momentMonth = moment(month, 'M')

  return momentMonth.isValid()
    ? momentMonth.format('MMMM')
    : month
}

const extractWorkersWithDiagnosesTableData = (data: ICompanyFinalActWorkerWithDiagnosis[]) => data.map((item) => ({
  ...item,
  contraindicationFactors: item.contraindicationFactors.join(', '),
}))

const getJointDiseasesListTableData = (data: ICompanyFinalActWorkerWithDiagnosisStringContraindicationFactors[]) => data.map((item) => ({
  ...item,
  contraindicationFactors: item.contraindicationFactors.split(', '),
}))

export const companyFinalActAdapter = {
  toClient (data: ICompanyFinalAct) {
    return {
      ...data,
      titleDate: {
        day: data.titleDay,
        month: getMonth(data.titleMonth),
        year: data.titleYear,
      },

      workersWithDiagnosesTableData: extractWorkersWithDiagnosesTableData(data.workersWithDiagnosesTableData),
    }
  },

  toServer (data: CompanyFinalActModel): { document: ICompanyFinalActUpdate } {
    return {
      document: {
        ...data,

        titleDay: data.titleDate.day,
        titleMonth: data.titleDate.month,
        titleYear: data.titleDate.year,

        workersWithDiagnosesTableData: getJointDiseasesListTableData(data.workersWithDiagnosesTableData),
      },
    }
  },
}
