import { snakeToCamel } from '@/_api/_requests/helpers'
import { pick } from 'lodash'

export const CERTIFICATE_TYPE = {
  PRIMARY: 'primary',
  CORRECTIVE: 'corrective',
  ANNULLING: 'annulling',
}

export const getCertificateTypes = (scope) => [
  { id: CERTIFICATE_TYPE.PRIMARY, title: t(CERTIFICATE_TYPE.PRIMARY, { scope }) },
  { id: CERTIFICATE_TYPE.CORRECTIVE, title: t(CERTIFICATE_TYPE.CORRECTIVE, { scope }) },
  { id: CERTIFICATE_TYPE.ANNULLING, title: t(CERTIFICATE_TYPE.ANNULLING, { scope }) },
]

export const INN_LENGTH = {
  INDIVIDUAL: 12,
  ORGANIZATION: 10,
}

export const KPP_LENGTH = 9

export const MIN_CERTIFICATE_NUMBER = 1

export const MAX_CERTIFICATE_NUMBER = 999_999_999_999

export const MAX_SUM_NUMBER = 9_999_999_999_999

export const PRIMARY_CERTIFICATE_CORRECTION_NUMBER = 0

export const ANNULLING_CERTIFICATE_CORRECTION_NUMBER = 999

export const MAX_DATE_RANGE_IN_YEARS = 1

export const MIN_REPORTING_YEAR = 2024

export const extractClinicFromGon = (gonClinic) => {
  const clinic = snakeToCamel(
    pick(gonClinic, [
      'id',
      'legal_name',
      'inn',
      'kpp',
      'title',
    ])
  )

  clinic.maskedKpp = clinic.kpp

  clinic.kpp = Utils.extractDigits(clinic.kpp || '')

  return clinic
}
