<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
  >
    <el-input
      :id="id"
      ref="input"
      class="m-input"
      :value="displayedMaskedPhone"
      :clearable="clearable"
      :disabled="canManage.disabled"
      :placeholder="placeholder"
      :label="label"
      :name="$attrs.name"
      @input="input"
      @input.native="onInputNative"
      @keyup.native="setInputCaretPos"
      @mouseup.left.native="setInputCaretPos"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #append>
        <slot name="append" />
      </template>
    </el-input>
    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :disabled="disabled"
      :focused="focused"
    />
  </validation-wrapper>
</template>

<script>
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import {
  getAddTo,
  getCaretPos,
  getDefaultCaretPos,
  getRawPhone,
  INPUT_TYPES,
  isAllowedInputType,
  isInsertTextType,
  PREFIX,
  PREFIX_LEN,
} from '@/vue_present/_base/MPhoneInput/const'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import MLabel from '@/vue_present/_base/inputs/MLabel'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'MPhoneInput',
  components: { MLabel, ValidationWrapper },

  mixins: [
    CommonInputProps,
    CommonInputMethods,
    ValidationChildMixin,
    DisabledMixin,
  ],

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: PropsTypes.String(),
    disableHiddenMask: Boolean,
  },

  data () {
    return {
      focused: false,
      inputDOM: null,
      useUnhiddenMask: false,

      inputCaretPos: getCaretPos(),
      tempInputCaretPos: getCaretPos(),

      maskedPhone: '',
      displayedMaskedPhone: '',
    }
  },

  computed: {
    rawValue () { return getRawPhone(this.value) },
    maskSettings () { return Services.phoneMask.getMaskSettings(this.rawValue) },
    phoneMask () { return this.maskSettings.mask },

    hiddenPhoneMask () {
      if (this.disableHiddenMask) { return this.phoneMask }

      return this.maskSettings.hidden_mask
    },

    maskLength () { return this.maskSettings.mask.length },
  },

  watch: {
    rawValue () {
      this.setDisplayedPhone()
      this.setInputCaretPos(this.tempInputCaretPos)
    },
  },

  created () {
    this.setDisplayedPhone()
  },

  mounted () {
    this.inputDOM = this.$refs.input.$refs.input
    this.inputDOM.addEventListener('paste', this.__onPaste)
  },

  beforeDestroy () {
    this.inputDOM.removeEventListener('paste', this.__onPaste)
  },

  methods: {
    setDisplayedPhone () {
      const hiddenMask = this.useUnhiddenMask
        ? this.phoneMask
        : this.hiddenPhoneMask

      this.maskedPhone = Services.phoneMask.setMask(this.rawValue, this.phoneMask, this.phoneMask) || ''
      const hiddenMaskedPhone = Services.phoneMask.setMask(this.rawValue, this.phoneMask, hiddenMask)
      this.displayedMaskedPhone = hiddenMaskedPhone
        ? `${PREFIX}${hiddenMaskedPhone}`
        : ''
    },

    /**
     * @param {MouseEvent | KeyboardEvent | { start: number, end: number}} valueEvent
     */
    setInputCaretPos (valueEvent) {
      if (valueEvent.start && valueEvent.end) {
        this.inputCaretPos = valueEvent
        this.$nextTick(() => {
          this.inputDOM.setSelectionRange(valueEvent.start, valueEvent.end)
        })

        return
      }

      this.inputCaretPos = getCaretPos(valueEvent.target?.selectionStart, valueEvent.target?.selectionEnd)
    },

    /**
    * Перехват оригинального инпута с последующей ручной обработкой.
    * Необходимо для адекватной работы курсора в кастомном поле
     * @param {InputEvent} event
     */
    onInputNative ({ data, inputType }) {
      if (!isAllowedInputType(inputType)) { return }
      if (this.__cannotInsertText(inputType)) { return }

      switch (inputType) {
        case INPUT_TYPES.BACKSPACE_CONTENT: {
          this.__onBackspaceContent(); break
        }
        case INPUT_TYPES.DELETE_CONTENT_FORWARD: {
          this.__onDeleteContent(); break
        }
        case INPUT_TYPES.PASTE: {
          /** Реализовано через this.__onPaste с inputDOM */
          break
        }
        default: {
          this.__onInsertText(data)
        }
      }

      this.onInput(getRawPhone(this.maskedPhone))
    },

    __cannotInsertText (inputType) {
      return isInsertTextType(inputType) &&
        this.maskedPhone.length >= this.maskLength &&
        this.inputCaretPos.start === this.inputCaretPos.end
    },

    __onBackspaceContent () {
      if (this.displayedMaskedPhone === PREFIX) { return }

      const leftSideRightBorder = this.inputCaretPos.start - 1 - PREFIX_LEN >= 0
        ? this.inputCaretPos.start - 1 - PREFIX_LEN
        : 0

      const leftSide = this.maskedPhone.slice(0, leftSideRightBorder)
      const rightSide = this.maskedPhone.slice(this.inputCaretPos.end - PREFIX_LEN)

      this.maskedPhone = `${leftSide}${rightSide}`

      this.tempInputCaretPos = this.inputCaretPos.start <= 0
        ? getDefaultCaretPos()
        : getCaretPos(this.inputCaretPos.start - 1, this.inputCaretPos.start - 1)
    },

    __onDeleteContent () {
      const leftSideRightBorder = this.inputCaretPos.start
        ? this.inputCaretPos.start - PREFIX_LEN
        : this.inputCaretPos.start

      const leftSide = this.maskedPhone.slice(0, leftSideRightBorder)
      const rightSide = this.maskedPhone.slice(this.inputCaretPos.end + 1 - PREFIX_LEN)

      this.maskedPhone = `${leftSide}${rightSide}`

      this.tempInputCaretPos = this.inputCaretPos.start - PREFIX_LEN >= this.maskLength - 1
        ? getCaretPos(this.maskLength, this.maskLength)
        : getCaretPos(this.inputCaretPos.start, this.inputCaretPos.start)
    },

    __onPaste (event) {
      if (this.__isTextFullySelected()) { this.setUseUnhiddenMask() }

      this.__onInsertText(event?.clipboardData?.getData('text'))
      this.onInput(getRawPhone(this.maskedPhone))
    },

    __isTextFullySelected () {
      return this.inputCaretPos.start === 0 && this.inputCaretPos.end === this.displayedMaskedPhone.length
    },

    __onInsertText (data) {
      if (data === PREFIX && !this.displayedMaskedPhone) {
        this.displayedMaskedPhone = PREFIX

        return
      }

      const leftSideRightBorder = this.__isTextFullySelected()
        ? this.inputCaretPos.start
        : this.inputCaretPos.start - PREFIX_LEN

      const leftSide = this.maskedPhone.slice(0, leftSideRightBorder)
      const rightSide = this.maskedPhone.slice(this.inputCaretPos.end - PREFIX_LEN)

      this.maskedPhone = `${leftSide}${(data || '').replace(/\D/, '')}${rightSide}`

      const newCaretPos = this.inputCaretPos.start + this.__getCaretOffsetWhenInsert()
      this.tempInputCaretPos = getCaretPos(newCaretPos, newCaretPos)
    },

    __getCaretOffsetWhenInsert () {
      let offset = getAddTo(this.phoneMask[this.inputCaretPos.start - PREFIX_LEN])

      if (!this.displayedMaskedPhone || (this.maskedPhone && this.__isTextFullySelected())) {
        offset += PREFIX_LEN
      }

      return offset
    },

    /**
    * Частный случай для обнуления строки
    * @param value
    * @private
    */
    input (value) {
      if (value && value !== PREFIX) { return }
      /** Чтобы сработала очистка, когда в поле находится только введённый пользователем '+' */
      this.displayedMaskedPhone = ''

      this.setUseUnhiddenMask()
      this.onInput('')
    },

    setUseUnhiddenMask () {
      this.useUnhiddenMask = true
    },
  },
}
</script>
