class NameFormatter {
  constructor (fio) {
    const {
      name,
      surname,
      secondName,
    } = parse(fio)

    this.name = name
    this.surname = surname
    this.secondName = secondName
  }

  toFull () {
    return toFull(this)
  }

  toShort () {
    return toShort(this)
  }

  static toFull (fio) {
    return toFull(parse(fio))
  }

  static toShort (fio) {
    return toShort(parse(fio))
  }
}

const parse = (fio) => {
  const nameComponents = {}

  if (Array.isArray(fio)) {
    nameComponents.name = fio[0]
    nameComponents.surname = fio[1]
    nameComponents.secondName = fio[2]
  } else if (typeof fio === 'object') {
    nameComponents.name = fio.name
    nameComponents.surname = fio.surname
    nameComponents.secondName = fio.second_name || fio.secondName
  } else if (typeof fio === 'string') {
    nameComponents.surname = fio
  }

  nameComponents.name = nameComponents.name || ''
  nameComponents.surname = nameComponents.surname || ''
  nameComponents.secondName = nameComponents.secondName || ''

  return nameComponents
}

const toFull = (fio) => {
  return `${fio.surname} ${fio.name} ${fio.secondName}`.trim()
}

const toShort = (fio) => {
  let shortName = fio.surname

  const name = firstCharToUpperCase(fio.name)
  const secondName = firstCharToUpperCase(fio.secondName)

  if (name) {
    shortName += ` ${name}.`
  }

  if (secondName) {
    shortName += ` ${secondName}.`
  }

  return shortName
}

const firstCharToUpperCase = (str) => str.charAt(0).toUpperCase()

export default NameFormatter
