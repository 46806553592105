<template>
  <div class="m-si-generator-example flex flex-column gap-indent">
    <div>
      <a
        href="https://www.notion.so/medods/MSiGenerator-MTableGenerator"
        target="_blank"
      >Дока в ношоне</a>
    </div>

    <clients-list class="flex-grow-1" />
  </div>
</template>

<script>
import ClientsList from '@/vue_apps/ClientsModule/components/ClientsList/ClientsList.vue'

export default {
  name: 'FrontBookPageMSiGenerator',
  components: { ClientsList },
}
</script>
