import { MClinicModel } from '@/_baseModels/MClinicModel'
import { IClinic } from '@/_declarations/IClinic'
import { MClinicPresenter } from '@/_api/MClinicApi/MClinicPresenter'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class Clinic extends MClinicModel {
  submit (): Promise<IClinic | IApiErrors> {
    const data = this.getAttributes()

    return new MClinicPresenter()
      .submit(data, {
        toServerAdapter: (clinic) => ({ clinic }),
        toJson: true,
      })
      .then((clinic) => {
        if (clinic?.id) {
          this.setValue('id', clinic.id)
        }

        return clinic
      })
  }
}
