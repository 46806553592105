import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const $t = (variable) => {
  return t(variable, { scope: 'finance.expensesTableTitles' })
}

export const getExpensesSchema = () => {
  return new MSiSchema()
    .addSearch()
    .addCreateButton(Services.security.canManageExpense)
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addHeaders({
          number: new MTableHeader($t('number')).addFixedWidth('80px'),
          formattedDate: new MTableHeader($t('date')).addFixedWidth('100px'),
          title: new MTableHeader($t('title')),
          price: new MTableHeader($t('price')).addFixedWidth('110px'),
          additional: new MTableHeader($t('additional')),
          expenseGroup: new MTableHeader($t('expenseGroup')),
          orderId: new MTableHeader($t('order')),
        })
    )
}
