import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { fastCreateAdapter } from '@/vue_apps/CheckupsModule/api/FastCreatePresenter/fastCreateAdapter'

export class FastCreatePresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () {},
        all: Routes.personals_fast_create_api_internal_checkups_companies_path,
        list () {},
      },
      location: 'FastCreatePresenter',
      entity: 'personalCheckup',
    })
  }

  submit (data, config = {}) {
    return super.submit(data, {
      toServerAdapter: fastCreateAdapter.toServer,
      toJson: true,
      ...config,
    })
  }
}
