import { replaceVariables, variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'

export default class TemplateConverter {
  constructor (text, labelsMap, valuesMap) {
    this.text = text
    this.labels = labelsMap
    this.values = valuesMap
  }

  toClient () {
    return replaceVariables(
      this.text,
      variablesParserWithChild(this.text || ''),
      this.values
    )
  }

  toServer () {
    return replaceVariables(
      this.text,
      variablesParserWithChild(this.text || ''),
      this.labels
    )
  }
}
