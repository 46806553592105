<template>
  <m-modal
    class="personal-checkup-doctor-results-modal"
    :visible="visible"
    :dialog-title="t$('title')"
    width="max-content"
    modal-title-icon="doctors"
    @close="onClose"
  >
    <template #body>
      <m-prompt-notice :text="t$('notice')" />

      <div class="flex gap-indent-small">
        <!-- Специальность -->
        <m-select
          :value="doctorResults.specialty"
          :items="specialistsFiltered"
          :label="t('specialty')"
          required
          validator-name="specialty"
          @registerValidator="onRegisterValidator($event)"
          @change="doctorResults.setValue('specialty', $event)"
        />

        <!-- Сотрудник -->
        <reusable-doctors-list
          :value="doctorResults.user"
          :current-clinic="personalCheckup.clinic.id"
          :disabled="doctorResults.foreignUser"
          :required="!doctorResults.foreignUser"
          use-current-user
          validator-name="user"
          @registerValidator="onRegisterValidator($event)"
          @onDoctorChange="doctorResults.setValue('user', $event)"
          @tryCurrentUserSelect="tryCurrentUserSelect = $event"
        />

        <!-- Дата -->
        <m-date-picker
          :value="doctorResults.date"
          :label="t('common.date')"
          value-format="yyyy-MM-dd"
          :required="dateNotInFutureValidator"
          validator-name="date"
          @registerValidator="onRegisterValidator($event)"
          @change="doctorResults.setValue('date', $event)"
        />

        <!-- Заключение -->
        <m-select
          :value="doctorResults.medicalAssessment"
          :items="doctorResults.medicalAssessments"
          :label="t('conclusion')"
          :clearable="false"
          required
          validator-name="medicalAssessment"
          @registerValidator="onRegisterValidator($event)"
          @change="doctorResults.setValue('medicalAssessment', $event)"
        />

        <m-button
          template="add"
          :text="t('add')"
          @click="onAdd"
        />
      </div>

      <div class="flex gap-indent-small">
        <!-- Заключение от сторонней клиники -->
        <m-checkbox
          class="personal-checkup-doctor-results-modal__foreign-user"
          :value="doctorResults.foreignUser"
          :label="t$('foreignUser')"
          @input="doctorResults.setValue('foreignUser', $event)"
        />

        <!-- Фамилия -->
        <m-input
          :value="doctorResults.foreignUserSurname"
          :label="t('surname')"
          :disabled="!doctorResults.foreignUser"
          :required="doctorResults.foreignUser"
          validator-name="foreignUserSurname"
          @registerValidator="onRegisterValidator($event)"
          @input="doctorResults.setValue('foreignUserSurname', $event)"
        />

        <!-- Имя -->
        <m-input
          :value="doctorResults.foreignUserName"
          :label="t('name')"
          :disabled="!doctorResults.foreignUser"
          :required="doctorResults.foreignUser"
          validator-name="foreignUserName"
          @registerValidator="onRegisterValidator($event)"
          @input="doctorResults.setValue('foreignUserName', $event)"
        />

        <!-- Отчество -->
        <m-input
          :value="doctorResults.foreignUserSecondName"
          :label="t('second_name')"
          :disabled="!doctorResults.foreignUser"
          @input="doctorResults.setValue('foreignUserSecondName', $event)"
        />
      </div>

      <m-si-generator
        :items="items"
        :si-generator-schema="msiSchema"
        @onItemDelete="doctorResults.destroy($event.id)"
      />
    </template>

    <template #footer-right-action>
      <m-button
        template="save"
        @click="onSave"
      />
    </template>
  </m-modal>
</template>

<script>
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import {
  getPersonalCheckupDoctorResultsMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDoctorResults/personalCheckupDoctorResultsConst'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import { dateNotInFutureValidator } from '@/lib/validators/dateNotInFutureValidator'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export default defineComponent({
  name: 'PersonalCheckupDoctorResultsModal',

  components: {
    MPromptNotice,
    MSiGenerator,
    MButton,
    MCheckbox,
    MDatePicker,
    ReusableDoctorsList,
    MSelect,
    MModal,
    MInput,
  },

  mixins: [i18nScopeMixin, ValidationParentMixin, CheckupsPropsMixin],

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      msiSchema: getPersonalCheckupDoctorResultsMsiSchema(),
    }
  },

  computed: {
    doctorResults () {
      return this.personalCheckup.doctorResults
    },

    items () {
      return this.doctorResults.getArr()
    },

    specialistsFiltered () {
      return this.doctorResults.specialists
        .filter(({ id }) =>
          !this.items.some(({ specialty }) => specialty.id === id))
    },
  },

  created () {
    this.setI18nScope('checkups.doctorResults')
  },

  methods: {
    dateNotInFutureValidator,

    tryCurrentUserSelect () {}, // Функция прилетит из ReusableDoctorsList

    onAdd () {
      if (this.hasErrors()) { return }
      this.doctorResults.append()
      this.clear()
    },

    async onSave () {
      if (this.isNewMode) {
        this.personalCheckup.doctorResults.setKeep()

        return this.afterClose()
      }

      const errors = await this.doctorResults.submit()
      if (errors) { return }

      this.afterClose()
    },

    async onClose () {
      if (this.personalCheckup.doctorResults.getUnsaved().length) {
        const { cancel } = await MConfirm.warning(t('closeWithoutSaving?'))
        if (cancel) { return }

        this.personalCheckup.doctorResults.removeUnsaved()
      }

      this.afterClose()
    },

    clear () {
      this.doctorResults.clear()
      this.resetValidations()
      this.tryCurrentUserSelect()
    },

    afterClose () {
      this.clear()
      this.$emit('close')
    },
  },
})
</script>
