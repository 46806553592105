<template>
  <m-panel
    :title="checkupPanelTitle"
    icon="periodicCheckups"
  >
    <!-- Фильтры -->
    <checkups-filters
      v-loading="list.loading"
      :list="list"
    />

    <!-- Таблица со списком профосмотров -->
    <div class="checkups-list-wrapper">
      <m-si-generator
        v-loading="list.loading"
        class="checkups-list"
        :items="list.data"
        :current-page="list.currentPage"
        :page-count="list.totalPages"
        :si-generator-schema="tableSchema"
        :search-query="list.searchQuery"
        :search-placeholder="searchPlaceholder"
        :total="list.total"
        @onItemClick="onItemClick"
        @onItemEdit="onEditClick"
        @update:currentPage="list.fetchPage($event)"
        @update:searchQuery="onSearchQuery"
      >
        <template #add>
          <m-button
            v-if="isPriorCheckups"
            v-tooltip="t('front_book.button_text.bill')"
            icon="calc"
            type="warning"
            @click="openModal"
          />

          <m-button
            v-if="canManage"
            template="add"
            @click="onAddItem"
          />
        </template>
      </m-si-generator>
    </div>

    <checkup-calculate-price-modal
      :visible="modalVisible"
      @close="closeModal"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import CheckupsFilters from '@/vue_apps/CheckupsModule/components/CheckupsFilters.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getCheckupsCompanySchema } from '@/vue_apps/CheckupsModule/store/getCheckupsCompanySchema'
import { getCheckupsPersonalSchema } from '@/vue_apps/CheckupsModule/store/getCheckupsPersonalSchema'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'
import { CompanyCheckupList } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckupList'
import { PersonalCheckupList } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupList'
import {
  getDefaultCheckupsFilters,
  getDefaultPersonalCheckupsFilters,
} from '@/vue_apps/CheckupsModule/store/getDefaultCheckupFilters'
import { CHECKUP_KINDS } from '@/vue_apps/CheckupsModule/store/checkupsKinds'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import CheckupCalculatePriceModal from '@/vue_apps/CheckupsModule/components/CheckupCalculatePriceModal.vue'

export default {
  name: 'CheckupModuleMainPage',
  components: {
    CheckupCalculatePriceModal,
    MButton,
    MSiGenerator,
    CheckupsFilters,
    MPanel,
  },

  mixins: [ModalMixin],

  data () {
    return {
      loading: false,
      /** @type {MListService} */
      list: null,
      showCalculatePriceModal: false,
    }
  },

  computed: {
    checkupPanelTitle () {
      return this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS
        ? t('checkups.kinds.periodicCheckups')
        : t('checkups.kinds.priorCheckups')
    },

    tableSchema () {
      return this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS
        ? getCheckupsCompanySchema()
        : getCheckupsPersonalSchema()
    },

    searchPlaceholder () {
      switch (this.$route.name) {
        case CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS: return t('checkups.searchPeriodicPlaceholder')
        case CHECKUPS_ROUTE_NAMES.PRIOR_CHECKUPS: return t('checkups.searchPriorPlaceholder')
        default: return t('search')
      }
    },

    canManage () {
      return Services.security.canManageCheckupsCompany
    },

    isPriorCheckups () {
      return this.$route.name === CHECKUPS_ROUTE_NAMES.PRIOR_CHECKUPS
    },
  },

  watch: {
    $route () {
      this.listInit()
    },
  },

  created () {
    this.listInit()
  },

  methods: {
    listInit () {
      this.setList()
      this.list.fetchAll()
    },

    setList () {
      this.list = this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS
        ? new CompanyCheckupList(getDefaultCheckupsFilters(), { cacheKey: 'companyCheckupList' })
        : new PersonalCheckupList(
          getDefaultPersonalCheckupsFilters({ kind: CHECKUP_KINDS.PRIOR }),
          { cacheKey: 'personalCheckupList' }
        )
    },

    onAddItem () {
      this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS
        ? this.$router.push({ name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_NEW })
        : this.$router.push({ name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_NEW })
    },

    onItemClick (item) {
      this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS
        ? this.$router.push({ name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_SHOW, params: { id: item.id } })
        : this.$router.push({ name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_SHOW, params: { id: item.id } })
    },

    onEditClick (item) {
      this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS
        ? this.$router.push({ name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_EDIT, params: { id: item.id } })
        : this.$router.push({ name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_EDIT, params: { id: item.id } })
    },

    onSearchQuery (searchQuery) {
      if (searchQuery) {
        return this.list.searchWithoutFilters(searchQuery)
      }

      this.list.setSearchQuery(searchQuery)

      this.list.fetchAll()
    },
  },
}
</script>
