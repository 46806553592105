import { CompanyFinalActLogic } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActLogic'
import {
  CompanyFinalActPresenter,
} from '@/vue_apps/CheckupsModule/api/CompanyFinalActPresenter/CompanyFinalActPresenter'

export class CompanyFinalActApi extends CompanyFinalActLogic {
  private presenter = new CompanyFinalActPresenter()

  async load () {
    const data = await this.presenter.fetch(this.id)

    if (data.errors) { return }

    this._fillProps(data)
  }

  update () {
    return this.presenter.update(this.getAttributes())
  }

  destroy () {
    return this.presenter.destroy(this.id)
  }
}
