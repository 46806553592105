export const getCannyBoards = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.canny_list_all_boards_path(),
      data: {},
      success: resolve,
      error: reject,
    })
  })
}

export const getCannyChangelog = (data) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.canny_list_entries_path(),
      data,
      success: resolve,
      error: reject,
    })
  })
}

export const getSSOToken = (user) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      url: Routes.get_canny_token_path(),
      data: { user },
      success: resolve,
      error: reject,
    })
  })
}
