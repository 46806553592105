<template>
  <div class="step-title mx-15">
    <div class="form-label bold">
      {{ t('egisz.steps.modal.title') }}
      <required-mark />
    </div>
    <div class="form-value egisz-case__form-value">
      <validation-wrapper
        :errors="stepValidations.title || []"
        fixed-height
      >
        <el-input
          class="step-form__title el-input-style-fixer"
          :value="title"
          :placeholder="t('egisz.steps.modal.title_ph')"
          :rows="3"
          :autosize="{ minRows: 3, maxRows: 10 }"
          clearable
          @input="$updateSync('title', $event)"
        >
          <template slot="append">
            <el-switch
              v-tooltip="t('egisz.steps.modal.generate_title')"
              :value="isTitleGenerated"
              :active-text="t('egisz.steps.modal.generate_title_short')"
              @input="$updateSync('isTitleGenerated', $event)"
            />
          </template>
        </el-input>
      </validation-wrapper>
    </div>
  </div>
</template>

<script>
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'

export default {
  name: 'StepTitle',
  components: { ValidationWrapper, RequiredMark },
  props: {
    title: {
      type: String,
      default: null,
    },

    isNewMode: Boolean,
    isTitleGenerated: Boolean,

    stepValidations: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
