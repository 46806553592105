import { MCheckupEntryPresenter } from '@/vue_apps/CheckupsModule/api/MCheckupEntryPresenter/MCheckupEntryPresenter'
import {
  PersonalCheckupCheckupEntriesLogic,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupCheckupEntries/PersonalCheckupCheckupEntriesLogic'
import {
  PersonalCheckupCheckupEntriesPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupCheckupEntriesPresenter/PersonalCheckupCheckupEntriesPresenter'

export class PersonalCheckupCheckupEntriesApi extends PersonalCheckupCheckupEntriesLogic {
  _presenter = new PersonalCheckupCheckupEntriesPresenter()

  _presenterEntry = new MCheckupEntryPresenter()

  async patch ({ fields = [], values = {} } = {}) {
    return this._presenter.submit({
      ...this.getAttributes(['personalCheckupId', ...fields]),
      ...values,
    })
  }

  /**
   *
   * @param checkupEntry {ICheckupCalculatedEntry}
   */
  async saveCheckupEntry (checkupEntry) {
    if (checkupEntry.entry) { return }

    const personalCheckup = await this.patch({
      values: {
        checkupEntries: [checkupEntry],
      },
    })

    if (personalCheckup.errors) { return }

    // если есть id, то это не новая checkupEntry
    if (checkupEntry.id) { return }

    this.setCheckupEntries(personalCheckup.checkupEntries)

    this.patchCheckupEntries()
  }

  async saveCheckupEntries (additionalFields = []) {
    return this.patch({
      fields: ['checkupEntries', ...additionalFields],
    })
  }

  async appendEntries (checkupEntryIds = []) {
    if (!checkupEntryIds.length) { return }

    const entries = await this._presenterEntry.submit({
      checkupEntryIds,
      userId: gon.application.current_user.id,
    })

    if (entries.error) { return }

    this._appendEntries(entries)
  }

  async destroyCheckupEntries (additionalFields = []) {
    this._setDestroyToCheckupEntries()

    const personalCheckup = await this.saveCheckupEntries(additionalFields)

    if (personalCheckup.errors) { return personalCheckup }

    this.setCheckupEntries(personalCheckup.checkupEntries)

    return {}
  }

  /**
   *
   * @param {{
   *   ids: number[],
   *   userId?: number,
   *   state?: number
   * }} payload
   * @returns {Promise<*|RequestErrors>|null}
   */
  updateEntries (payload) {
    return this._presenterEntry.update(payload)
  }

  destroyEntries (entryIds) {
    return this._presenterEntry.destroy({
      ids: entryIds,
    })
  }
}
