export const CheckupsComputedMixin = {
  computed: {
    isNewMode () {
      return this.$route.name.endsWith('new')
    },

    isEditMode () {
      return this.$route.name.endsWith('edit')
    },

    isShowMode () {
      return this.$route.name.endsWith('show')
    },
  },
}
