<template>
  <div class="sidebar-filters">
    <egisz-clients-filter-period-select />
    <egisz-clients-filter-status />
    <egisz-table-filters
      @refresh="vxRefreshFilters"
      @reset="resetFilters"
    />

    <egisz-has-updates
      :watch-props="hasUpdatesProps"
      @refresh="vxRefreshFilters"
    />
  </div>
</template>

<script>
import EgiszClientsFilterPeriodSelect
  from '@/vue_components/egisz/egisz_module/components/EgiszClients/filters/egisz_clients_filter_period_select'
import EgiszClientsFilterStatus
  from '@/vue_components/egisz/egisz_module/components/EgiszClients/filters/egisz_clients_filter_status'
import EgiszTableFilters from '@/vue_components/egisz/egisz_module/components/egisz_table_fitlers'
import { mapMutations } from 'vuex'
import { getDefaultClientsFilters } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import EgiszHasUpdates from '@/vue_components/egisz/egisz_module/components/filters/EgiszHasUpdates'
import { ENTITIES, TRIGGERS_MAP } from '@/vue_components/store/modules/egisz/ws/egisz_ws'

export default {
  name: 'EgiszClientsFilters',
  components: {
    EgiszHasUpdates,
    EgiszTableFilters,
    EgiszClientsFilterStatus,
    EgiszClientsFilterPeriodSelect,
  },

  computed: {
    hasUpdatesProps () {
      return [
        TRIGGERS_MAP[ENTITIES.CLIENT],
      ]
    },
  },

  created () {
    this.resetFilters()
  },

  methods: {
    ...mapMutations('egisz/clients/filtersBase', {
      vxRefreshFilters: 'vxRefreshFilters',
      vxResetFilters: 'vxResetFilters',
    }),

    resetFilters () {
      this.vxResetFilters(getDefaultClientsFilters())
    },
  },
}
</script>
