export const FEEDBACK_STATUSES = Object.freeze({
  AWAITING_CLIENT_NOTIFICATION: 'awaiting_client_notification',
  FAILED_TO_NOTIFY_CLIENT: 'failed_to_notify_client',
  AWAITING_CLIENT_FEEDBACK: 'awaiting_client_feedback',
  CLIENT_FEEDBACK_RECEIVED: 'client_feedback_received',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
})

export const FEEDBACK_STATUSES_MODAL = [
  FEEDBACK_STATUSES.CLIENT_FEEDBACK_RECEIVED,
  FEEDBACK_STATUSES.PROCESSING,
  FEEDBACK_STATUSES.COMPLETED,
]

export const FEEDBACK_STATUSES_LABELS =
  FEEDBACK_STATUSES_MODAL.map((status) =>
    ({
      id: status,
      title: t(status, { scope: 'feedback.status' }),
    }))

export const FEEDBACK_STATUSES_APPEARANCE = Object.freeze({
  [FEEDBACK_STATUSES.AWAITING_CLIENT_NOTIFICATION]: { icon: 'awaitingClientNotification', color: 'info' },
  [FEEDBACK_STATUSES.FAILED_TO_NOTIFY_CLIENT]: { icon: 'failedToNotifyClient', color: 'danger' },
  [FEEDBACK_STATUSES.AWAITING_CLIENT_FEEDBACK]: { icon: 'awaitingClientFeedback', color: 'primary' },
  [FEEDBACK_STATUSES.CLIENT_FEEDBACK_RECEIVED]: { icon: 'clientFeedbackReceived', color: 'primary' },
  [FEEDBACK_STATUSES.PROCESSING]: { icon: 'processing', color: 'warning' },
  [FEEDBACK_STATUSES.COMPLETED]: { icon: 'completed', color: 'success' },
})

export const RATING_COLORS = Object.freeze({
  YELLOW: ['#f7ca61', '#f7ca61', '#f7ca61'],
  GREEN: ['#8BC34A', '#8BC34A', '#8BC34A'],
})

export const DEFAULT_SELECTOR_FEEDBACKS_STATUSES = ['client_feedback_received', 'processing', 'completed']

export const DEFAULT_SELECTOR_FEEDBACKS_STATUSES_LABELS = [
  { id: 'client_feedback_received', title: t('client_feedback_received', { scope: 'feedback.status' }) },
  { id: 'processing', title: t('processing', { scope: 'feedback.status' }) },
  { id: 'completed', title: t('completed', { scope: 'feedback.status' }) },
]

export const FEEDBACKS_EXTERNAL_LINK_CLIKS_FILTER_ITEMS = Object.freeze([
  {
    id: 'yes',
    title: t('yes'),
  },
  {
    id: 'no',
    title: t('no'),
  },
])
