import { orUndefined } from '@/_medods_standart_library/msl'
import { OrdersListCellItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/OrdersListCellItem'
import { OrdersListTotalItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/OrdersListTotalItem'
import { DateHelpers } from '@/helpers/DateHelpers'

export const ordersAdapter = {
  toServer (data) {
    if (data.searchQuery) {
      return {
        searchString: data.searchQuery,
        clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
        limit: data.limit,
        offset: data.offset,
      }
    }

    return {
      date: orUndefined(DateHelpers.toBasePeriod(data.date), true),
      paidStatuses: data.paidStatuses ? [data.paidStatuses] : undefined,
      paymentSpecies: orUndefined(data.paymentSpecies),
      creatorIds: data.creator?.id ? [data.creator.id] : undefined,
      performerId: orUndefined(data.performer?.id),
      customerType: orUndefined(data.customerType),
      clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
      limit: data.limit,
      offset: data.offset,
    }
  },

  toClient (response) {
    response.data = response.data.map((item) => new OrdersListCellItem(item))

    response.total = new OrdersListTotalItem(response.totals)

    return response
  },
}
