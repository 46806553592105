import { set } from 'lodash/object'
import { cloneDeep, pick } from 'lodash'

export class ItemBase {
  protected _permit: string[] = []

  setValue (key: string, value: unknown) {
    if (!key) { return }
    if (typeof key !== 'string') { return }
    if (key.includes('.')) {
      set(this, key, value)

      return
    }

    this[key] = value
  }

  getAttributes (attributes: string[] = this._permit): Record<string, any> {
    return pick(this, attributes)
  }

  _fillProps (props = {}) {
    if (!this?._permit) { return }

    this._permit.forEach((param) => {
      if (!Object.hasOwn(props, param)) { return }
      if (typeof props[param] === 'object') {
        this[param] = cloneDeep(props[param])

        return
      }

      this[param] = props[param]
    })
  }
}
