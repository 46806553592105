<template>
  <div class="feedback-module">
    <m-si-generator
      :items="list.data"
      :si-generator-schema="tableSchema"
      :page-count="list.totalPages"
      :current-page="list.currentPage"
      :total="list.total"
      @update:currentPage="list.fetchPage($event)"
      @onItemClick="onItemClick"
    >
      <template #filters>
        <feedback-filters
          :list="list"
          :hide-specialty-and-statuses-filter="hideSpecialtyAndStatusesFilter"
          @onFilterChange="fetchList()"
        />
      </template>

      <template #tr-doctorScore="{ doctorScore } ">
        <m-stars
          v-if="doctorScore.value"
          :value="doctorScore.value"
        />
      </template>

      <template #tr-clinicScore="{ clinicScore }">
        <m-stars
          v-if="clinicScore.value"
          :value="clinicScore.value"
          :colors="RATING_COLORS.GREEN"
        />
      </template>

      <template #tr-urls="{urls}">
        <template v-if="urls.value.length">
          <m-link
            v-for="url in urls.value"
            :key="url"
            :href="url"
            open-in-new-tab
          >
            <m-icon
              v-tooltip="url"
              icon="link"
              color="primary"
            />
          </m-link>
        </template>
        <span v-else />
      </template>

      <template #tf-clientComment>
        <div class="m-table-row-cell col col__clientComment">
          {{ feedbacksQuantity }} <br>
          {{ totalUrlClicks }}
        </div>
      </template>
    </m-si-generator>

    <feedback-modal
      @registerOpenModal="openFeedbackModal = $event"
      @updateList="list.fetchAll()"
    />
  </div>
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListService } from '@/_api/_requests/MListService'
import { MFeedbackPresenter } from '@/_api/MFeedback/MFeedbackPresenter'
import { getFeedbackTableSchema } from '@/vue_apps/FeedbackModule/const/getFeedbackTableSchema'
import FeedbackModal from '@/vue_apps/FeedbackModule/components/FeedbackModal.vue'
import FeedbackFilters from '@/vue_apps/FeedbackModule/components/FeedbackFilters.vue'
import { getDefaultFeedbackFilters } from '@/vue_apps/FeedbackModule/const/getDefaultFeedbackFilters'
import MStars from '@/vue_present/_base/MStars/MStars.vue'
import { RATING_COLORS } from '@/vue_apps/FeedbackModule/const/const'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'

export default {
  name: 'FeedbackModule',
  components: {
    MLink,
    MIcon,
    MStars,
    FeedbackFilters,
    FeedbackModal,
    MSiGenerator,
  },

  props: {
    clientId: { type: Number, default: null },
    cacheKey: { type: String, default: null },
  },

  data () {
    return {
      list: new MListService(
        getDefaultFeedbackFilters(this.clientId),
        new MFeedbackPresenter(),
        { cacheKey: this.cacheKey }
      ),
      tableSchema: getFeedbackTableSchema(),
      RATING_COLORS,

      //eslint-disable-next-line
      async openFeedbackModal (id) { },
    }
  },

  computed: {
    hideSpecialtyAndStatusesFilter () {
      return Boolean(this.clientId)
    },

    feedbacksQuantity () {
      return `${t('feedback.feedbacksQuantity')} ${this.list.total.rawData.total}`
    },

    totalUrlClicks () {
      return `${t('feedback.totalUrlClicks')} ${this.list.total.rawData.totalUrlClicks}`
    },
  },

  mounted () {
    this.list.filtersData.clientId = this.clientId
    this.fetchList()
  },

  methods: {
    async fetchList () {
      await this.list.fetchAll()
      this.$nextTick(() => {
        Services.telephony.reset()
      })
    },

    onItemClick (item) {
      this.openFeedbackModal(item.id)
    },
  },
}
</script>
