import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { tScoped } from '@/helpers/i18nHelpers'

const $t = tScoped('egisz.semds.tableHeader')

export const getSchema = (useSelectableRows) =>
  new MSiSchema()
    .addSearch()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          status: new MTableHeader().addFixedWidth('40px'),
          number: new MTableHeader($t('number')).addFixedWidth('80px'),
          date: new MTableHeader($t('date')).addFixedWidth('120px'),
          title: new MTableHeader($t('title')),
          client: new MTableHeader($t('client')),
          // author: new MTableHeader($t('author')),
          // doctor: new MTableHeader($t('doctor')),
          semdType: new MTableHeader($t('semdType')),
        })
        .addDeleteIcon(Services.security.canManageEgisz)
        .addCheckboxes(useSelectableRows)
        .addFooter(useSelectableRows)
    )
