<template>
  <m-panel
    :title="t$('title')"
    class="personal-checkup-files"
  >
    <m-si-generator
      v-if="!isNewMode"
      ref="msiGenerator"
      v-loading="loading"
      :items="list.data"
      :si-generator-schema="msiSchema"
      :page-count="list.totalPages"
      :current-page="list.currentPage"
      @update:currentPage="fetchAllWithInitAttachmentPreview"
      @onCellClick="onCellClick"
      @onItemDelete="onDeleteClick"
    >
      <template #add>
        <m-button-upload
          plus-icon
          template="shortUpload"
          :tooltip="t('uploadFile')"
          disabled="CheckupsCompany"
          @files="onFiles"
        />
      </template>
    </m-si-generator>

    <checkup-available-after-saving v-else />
  </m-panel>
</template>

<script lang="ts">
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import CheckupAvailableAfterSaving from '@/vue_apps/CheckupsModule/components/CheckupAvailableAfterSaving.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { defineComponent } from 'vue'
import {
  getPersonalCheckupFilesMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupFiles/personalCheckupFilesConst'
import MButtonUpload from '@/vue_present/_base/buttons/MButtonUpload/MButtonUpload.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { FileUploader } from '@/_api/_classes/FileUploader/FileUploader'
import {
  PersonalCheckupRemoteFilesPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupRemoteFilesPresenter/PersonalCheckupRemoteFilesPresenter'
import { MListService } from '@/_api/_requests/MListService'
import {
  MClientAttachmentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/MClientAttachmentsPresenter'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default defineComponent({
  name: 'PersonalCheckupFiles',

  components: {
    MButtonUpload,
    CheckupAvailableAfterSaving,
    MPanel,
    MSiGenerator,
  },

  mixins: [
    CheckupsPropsMixin,
    i18nScopeMixin,
    SpinnerHolder,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['updatePersonalCheckupIdFilter'],

  data () {
    return {
      clientAttachmentsPresenter: new MClientAttachmentsPresenter(),

      msiSchema: getPersonalCheckupFilesMsiSchema(),

      fileUploader: new FileUploader(
        new PersonalCheckupRemoteFilesPresenter()
      ),

      list: new MListService(
        { personalCheckupId: null },
        new PersonalCheckupRemoteFilesPresenter()
      ),
    }
  },

  created () {
    this.setI18nScope('checkups.personal.files')

    this.$emit('updatePersonalCheckupIdFilter', this.updatePersonalCheckupIdFilter)
  },

  methods: {
    async onFiles (fileList: FileList) {
      this.fileUploader.append(fileList)

      if (!this.fileUploader.hasFiles()) { return }

      await this.fileUploader.submit({
        personalCheckupId: this.personalCheckup.id,
      })

      await this.fetchAllWithInitAttachmentPreview()
    },

    async updatePersonalCheckupIdFilter (withFetchAll = false) {
      this.list.setFilterValue('personalCheckupId', this.personalCheckup.id)

      if (!withFetchAll) { return }

      await this.fetchAllWithInitAttachmentPreview()
    },

    onCellClick ({ cell, item, event }) {
      event.preventDefault()
      event.stopPropagation()

      switch (cell) {
        case 'download':
          return downloadFile(
            Routes.api_internal_checkups_personals_remote_file_path(item.id),
            item.name.value
          )

        case 'print':
          return printJS(
            Routes.show_client_attachment_path(item.id),
            item.printJSMimeType
          )
      }
    },

    async onDeleteClick ({ id }) {
      await this.clientAttachmentsPresenter.destroy(id)

      await this.fetchAllWithInitAttachmentPreview()
    },

    initAttachmentPreview () {
      const $rows = $(this.$refs.msiGenerator.$el).find('.m-table-row')
      $rows.each((index: number, currentTarget: HTMLElement) => {
        const dataSet = currentTarget.dataset
        $(currentTarget).attachmentPreview({
          type: dataSet.mimeType,
          fileType: dataSet.mimeType,
          headerText: dataSet.filename,
        })
      })
    },

    async fetchAllWithInitAttachmentPreview (page: number = this.list.currentPage) {
      await this.withSpinner(
        this.list.fetchPage(page, true)
      )

      this.initAttachmentPreview()
    },
  },
})
</script>
