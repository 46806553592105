<template>
  <div class="import-validator">
    <div
      class="import-validator__introduction"
      v-html="t('introduction.support_import_validation')"
    />
    <div class="validation_data">
      <div class="section section__import_details">
        <div class="details section__import-kind">
          <label for="import_kind">{{ t('import_validation.choose_import_kind') }}</label>
          <simple-select
            id="import_kind"
            :items="importKinds"
            :value="importKindSelected"
            value-attribute="value"
            @change="setImportKind($event)"
          />
        </div>
        <div class="details section__file-encoding">
          <label for="import_encoding">{{ t('import_validation.choose_import_encoding') }}</label>
          <simple-select
            id="import_encoding"
            :items="importEncodings"
            :value="importEncodingSelected"
            value-attribute="value"
            @change="setImportEncoding($event)"
          />
        </div>

        <div class="warning">
          <i class="fad fa-fw fa-exclamation-triangle" />
          <span>{{ t('comma_is_main_separator') }}</span>
        </div>
      </div>
      <div class="section section__file_uploader">
        <files-loader
          id="fileLoader"
          ref="fileLoader"
          :disabled="status !== FILE_EMPTY"
          :multiple="false"
          :ajax-loader="ajaxFileLoader"
          action="$routes.import_validations_import_path()"
          drag
          @setUploadFiles="setUploadFiles"
        >
          <div slot="upload-icon">
            <i
              v-if="status === FILE_EMPTY"
              class="el-icon-upload"
            />
            <i
              v-else-if="status === FILE_SELECTED"
              class="fad fa-file-alt"
            />
            <i
              v-else-if="status === FILE_VALIDATION_SUCCESS"
              class="fad fa-check"
            />
            <i
              v-else
              class="fad fa-times red"
            />
          </div>
          <div slot="upload-text">
            <div
              v-if="status === FILE_EMPTY"
              class="el-upload__text"
            >
              {{ t('files_loader.message') }}
              <em>{{ t('files_loader.message_em') }}</em>
            </div>
            <span
              v-tooltip="file.file && t('import_validation.another_import_file')"
              class="pointer"
              @click="resetState"
            >
              <span class="prompt-notice cut import-file-name">
                {{ fileName }}
                <i
                  v-if="file.file"
                  class="fas fa-times danger"
                />
              </span>
            </span>
          </div>
        </files-loader>
      </div>
    </div>
    <button
      v-loading="loading"
      type="button"
      class="btn btn-sm btn-with-icon btn-primary"
      @click="$refs.fileLoader.submitUpload()"
    >
      <i class="fad fa-check btn-with-icon_icon" />
      {{ t('check') }}
    </button>
  </div>
</template>

<script>
import FilesLoader from '@/vue_components/common/FilesLoader/FilesLoader'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { importValidatorEndpoint } from '@/api_entities/import_validator/import_validator_endpoint'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { getFileNameExtension, isCSV } from '@/vue_components/EntryTypes/PricesImport/composables/helpers'
import {
  FILE_EMPTY,
  FILE_SELECTED,
  FILE_VALIDATION_ERROR,
  FILE_VALIDATION_SUCCESS,
} from '@/vue_components/support/const/const'

export default {
  name: 'SimpleImportValidator',
  components: { FilesLoader, SimpleSelect },

  mixins: [SpinnerHolder],

  data () {
    return {
      ajaxFileLoader: this.getValidationFileLoader(),
      file: {},
      fileList: [],
      successValidation: false,
      importKinds: [],
      importEncodings: [],
      importSeparators: [],
      importKindSelected: null,
      importEncodingSelected: null,
      importSeparatorSelected: null,
      status: FILE_EMPTY,
      FILE_EMPTY,
      FILE_SELECTED,
      FILE_VALIDATION_SUCCESS,
      FILE_VALIDATION_ERROR,
    }
  },

  computed: {
    fileName () {
      return (this.file && this.file.file && this.file.file.name) ||
          t('files_loader.not_selected_one')
    },
  },

  async created () {
    this.importKinds = await importValidatorEndpoint.getImportKinds()
    this.importEncodings = await importValidatorEndpoint.getImportEncodings()
    this.importSeparators = await importValidatorEndpoint.getImportSeparators()
  },

  methods: {
    getValidationFileLoader () {
      return (file) => {
        this.file = file
        const fields = {
          importKindSelected: this.importKindSelected,
          importEncodingSelected: this.importEncodingSelected,
        }

        this.withSpinner(this.getValidationPromise(file, fields))
      }
    },

    getValidationPromise (file, fields) {
      const fileName = file.file.name.substr(0, file.file.name.lastIndexOf('.'))

      return importValidatorEndpoint.fileValidate(file, fields)
        .then((data) => {
          this.successValidation = true
          this.status = FILE_VALIDATION_SUCCESS
          this.$alert(t('import_validation.success_message'), t('files_loader.validation'), {
            confirmButtonText: 'OK',
            callback: (action) => this.resetState(),
          })

          Utils.downloadExportFile(data, fileName)
        })
        .catch((err) => {
          if (err && err.status === 422) {
            Utils.downloadExportFile(err.responseText, fileName)
            this.status = FILE_VALIDATION_ERROR
            this.$alert(t('files_loader.errors_found'), t('files_loader.validation'), {
              confirmButtonText: 'OK',
              callback: (action) => this.resetState(),
            })

            return
          }

          Utils.reportError('FileImportValidation:getAjaxFileLoader()', t('import_validation.error_message'))(err)
        })
    },

    resetState () {
      this.file = {}
      this.$refs.fileLoader.clearFiles()
      this.successValidation = false
      this.loading = false
      this.status = FILE_EMPTY
    },

    setUploadFiles (files) {
      if (!files.length) { return }

      const file = files[0].raw
      if (!isCSV(getFileNameExtension(file.name))) {
        Notificator.error(t('files_loader.csv_only'), t('files_loader.file-check'))
        this.resetState()

        return
      }

      this.setFile(file)
    },

    setFile (file) {
      this.status = FILE_SELECTED
      this.file = { file }
    },

    setImportKind (value) {
      this.importKindSelected = value
    },

    setImportEncoding (value) {
      this.importEncodingSelected = value
    },
  },

}
</script>
