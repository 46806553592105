import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getClientsListSchema = () => {
  return new MSiSchema()
    .addCreateButton()
    .addSearch()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addEditIcon(Services.security.canManageClient)
        .addHeaders({
          tableEmk: new MTableHeader(t('emk')).addFixedWidth('150px'),
          tableFullName: new MTableHeader(t('full_name')),
          tableBirthdate: new MTableHeader(t('birthdate')),
          tablePhone: new MTableHeader(t('phone')),
          tableGroups: new MTableHeader(t('clients_groups')),
        })
    )
}
