import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getPersonalCheckupDiseasesPersonalMsiSchema = ({
  addDeleteIcon = Services.security.canManageCheckupsCompany,
  addCreateButton = false,
} = {}) => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        msiDiagnosisCodeString: new MTableHeader().addFixedWidth('60px'),
        msiDiagnosisTitle: new MTableHeader().addFixedWidth('200px'),
        diagnosisDate: new MTableHeader().addFixedWidth('120px'),
        msiDiagnosisType: new MTableHeader(),
      })
      .addDeleteIcon(addDeleteIcon)
  )
  .addCreateButton(addCreateButton)

export const getPersonalCheckupDiseasesPersonalAddButtonConfig = () => ({
  color: 'primary',
  text: t('addDiagnosis'),
  canManage: 'CheckupsCompany',
})
