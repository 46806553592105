const clientWidgetEnabled = gon.application.client_widget_enabled

export const MESSAGE_VARIABLES_LIST = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'bonus_points',
        parentSource: 'client',
        label: t('sms.variables_list.bonus_points'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'feedback_url',
        label: t('sms.variables_list.feedback_url'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'user',
    label: t('sms.variables_list.user'),
    children: [
      {
        source: 'name',
        parentSource: 'user',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'surname',
        parentSource: 'user',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'short_name',
        parentSource: 'user',
        label: t('sms.variables_list.short_name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'specialty',
        parentSource: 'user',
        label: t('sms.variables_list.specialty'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'feedback_url',
        label: t('sms.variables_list.feedback_url'),
        parentSource: 'user',
        parentLabel: t('sms.variables_list.user'),
      },
    ],
  },
  {
    source: 'appointment',
    label: t('sms.variables_list.appointment'),
    children: [
      {
        source: 'date_dd_mm',
        parentSource: 'appointment',
        label: t('sms.variables_list.date_dd_mm'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'time',
        parentSource: 'appointment',
        label: t('sms.variables_list.time'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'confirmation_url',
        parentSource: 'appointment',
        label: t('sms.variables_list.confirmation_url'),
        parentLabel: t('sms.variables_list.appointment'),
      },
    ],
  },
  {
    source: 'client_feedback',
    label: t('sms.variables_list.client_feedback'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'feedback_url',
        parentSource: 'client_feedback',
        label: t('sms.variables_list.feedback_url'),
        parentLabel: t('sms.variables_list.client_feedback'),
      },
    ],
  },
  {
    source: 'order',
    label: t('sms.variables_list.order'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'id',
        parentSource: 'order',
        label: t('sms.variables_list.id'),
        parentLabel: t('sms.variables_list.order'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const APPOINTMENT_VARIABLES_LIST = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'bonus_points',
        parentSource: 'client',
        label: t('sms.variables_list.bonus_points'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'user',
    label: t('sms.variables_list.user'),
    children: [
      {
        source: 'name',
        parentSource: 'user',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'surname',
        parentSource: 'user',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'short_name',
        parentSource: 'user',
        label: t('sms.variables_list.short_name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'specialty',
        parentSource: 'user',
        label: t('sms.variables_list.specialty'),
        parentLabel: t('sms.variables_list.user'),
      },
    ],
  },
  {
    source: 'appointment',
    label: t('sms.variables_list.appointment'),
    children: [
      {
        source: 'date_dd_mm',
        parentSource: 'appointment',
        label: t('sms.variables_list.date_dd_mm'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'time',
        parentSource: 'appointment',
        label: t('sms.variables_list.time'),
        parentLabel: t('sms.variables_list.appointment'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const CLIENTS_DISPATCH_VARIABLES_LIST = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'bonus_points',
        parentSource: 'client',
        label: t('sms.variables_list.bonus_points'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const CLIENTS_BIRTHDAY_MESSAGE_VARIABLES_LIST = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'bonus_points',
        parentSource: 'client',
        label: t('sms.variables_list.bonus_points'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const SERVICES_STATUS_MESSAGE_VARIABLES_LIST = [
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'order',
    label: t('sms.variables_list.order'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'id',
        parentSource: 'order',
        label: t('sms.variables_list.id'),
        parentLabel: t('sms.variables_list.order'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const SERVICES_STATUS_MESSAGE_VARIABLES_LIST_BY_ENTRY = [
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const CLIENTS_FEEDBACK_VARIABLES_LIST_FIRST_MESSAGE = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'bonus_points',
        parentSource: 'client',
        label: t('sms.variables_list.bonus_points'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'user',
    label: t('sms.variables_list.user'),
    children: [
      {
        source: 'name',
        parentSource: 'user',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'surname',
        parentSource: 'user',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'short_name',
        parentSource: 'user',
        label: t('sms.variables_list.short_name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'specialty',
        parentSource: 'user',
        label: t('sms.variables_list.specialty'),
        parentLabel: t('sms.variables_list.user'),
      },
    ],
  },
  {
    source: 'appointment',
    label: t('sms.variables_list.appointment'),
    children: [
      {
        source: 'date_dd_mm',
        parentSource: 'appointment',
        label: t('sms.variables_list.date_dd_mm'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'time',
        parentSource: 'appointment',
        label: t('sms.variables_list.time'),
        parentLabel: t('sms.variables_list.appointment'),
      },
    ],
  },
  {
    source: 'client_feedback',
    label: t('sms.variables_list.client_feedback'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'feedback_url',
        parentSource: 'client_feedback',
        label: t('sms.variables_list.feedback_url'),
        parentLabel: t('sms.variables_list.client_feedback'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'bonus_points',
        parentSource: 'client',
        label: t('sms.variables_list.bonus_points'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'feedback_url',
        label: t('sms.variables_list.feedback_url'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'user',
    label: t('sms.variables_list.user'),
    children: [
      {
        source: 'name',
        parentSource: 'user',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'surname',
        parentSource: 'user',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'short_name',
        parentSource: 'user',
        label: t('sms.variables_list.short_name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'specialty',
        parentSource: 'user',
        label: t('sms.variables_list.specialty'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'feedback_url',
        label: t('sms.variables_list.feedback_url'),
        parentSource: 'user',
        parentLabel: t('sms.variables_list.user'),
      },
    ],
  },
  {
    source: 'appointment',
    label: t('sms.variables_list.appointment'),
    children: [
      {
        source: 'date_dd_mm',
        parentSource: 'appointment',
        label: t('sms.variables_list.date_dd_mm'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'time',
        parentSource: 'appointment',
        label: t('sms.variables_list.time'),
        parentLabel: t('sms.variables_list.appointment'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]

export const APPOINTMENT_CONFIRMATION_VARIABLES_LIST = [
  {
    source: 'client',
    label: t('sms.variables_list.client'),
    children: [
      {
        source: 'full_name',
        parentSource: 'client',
        label: t('sms.variables_list.full_name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'name',
        parentSource: 'client',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'surname',
        parentSource: 'client',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.client'),
      },
      {
        source: 'second_name',
        parentSource: 'client',
        label: t('sms.variables_list.second_name'),
        parentLabel: t('sms.variables_list.client'),
      },
    ],
  },
  {
    source: 'clinic',
    label: t('sms.variables_list.clinic'),
    children: [
      {
        source: 'message_title',
        label: t('sms.variables_list.sms_title'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'message_address',
        label: t('sms.variables_list.sms_address'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone1',
        label: t('sms.variables_list.formatted_phone1'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
      {
        source: 'formatted_phone2',
        label: t('sms.variables_list.formatted_phone2'),
        parentSource: 'clinic',
        parentLabel: t('sms.variables_list.clinic'),
      },
    ],
  },
  {
    source: 'user',
    label: t('sms.variables_list.user'),
    children: [
      {
        source: 'name',
        parentSource: 'user',
        label: t('sms.variables_list.name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'surname',
        parentSource: 'user',
        label: t('sms.variables_list.surname'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'short_name',
        parentSource: 'user',
        label: t('sms.variables_list.short_name'),
        parentLabel: t('sms.variables_list.user'),
      },
      {
        source: 'specialty',
        parentSource: 'user',
        label: t('sms.variables_list.specialty'),
        parentLabel: t('sms.variables_list.user'),
      },
    ],
  },
  {
    source: 'appointment',
    label: t('sms.variables_list.appointment'),
    children: [
      {
        source: 'date_dd_mm',
        parentSource: 'appointment',
        label: t('sms.variables_list.date_dd_mm'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'time',
        parentSource: 'appointment',
        label: t('sms.variables_list.time'),
        parentLabel: t('sms.variables_list.appointment'),
      },
      {
        source: 'confirmation_url',
        parentSource: 'appointment',
        label: t('sms.variables_list.confirmation_url'),
        parentLabel: t('sms.variables_list.appointment'),
      },
    ],
  },
  {
    source: 'client_widget_configuration',
    label: t('sms.variables_list.client_widget_configuration'),
    disabled: !clientWidgetEnabled,
    children: [
      {
        source: 'widget_url',
        parentSource: 'client_widget_configuration',
        label: t('sms.variables_list.widget_url'),
        parentLabel: t('sms.variables_list.client_widget_configuration'),
      },
    ],
  },
]
