import { ALPHABET, DEFAULT_FILTER_LETTER } from '@/vue_apps/ClientsModule/components/ClientsList/const/const'

export const getAlphabet = () =>
  ALPHABET.split('').map((letter) => ({
    id: letter,
    title: letter,
  }))

export const getInitialFilters = () => ({
  letter: DEFAULT_FILTER_LETTER,
})
