export const documentMassDestroyAdapter = {
  toServer (ids) {
    const formData = new FormData()

    ids.forEach((id) => {
      formData.append('ids[]', id)
    })

    formData.append('actions', 'delete')

    return formData
  },
}
