import { MListService } from '@/_api/_requests/MListService'
import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'

export class PersonalCheckupList extends MListService {
  constructor (initialFilters, config) {
    super(
      initialFilters,
      new MPersonalCheckupPresenter(),
      config
    )
  }
}
