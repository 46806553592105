import { DATE_PICKER_BUTTONS, dateButtons } from '@/vue_present/_base/MDatePickerButtons/helpers'

export const getDefaultOrdersFilters = () => {
  return {
    date: dateButtons[DATE_PICKER_BUTTONS.TODAY].value,
    paidStatuses: null,
    paymentSpecies: null,
    creator: null,
    performer: null,
    customerType: null,
    clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
    searchString: null,
  }
}
