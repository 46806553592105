<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
    class="position-relative"
  >
    <el-date-picker
      v-if="type === DATEPICKER_TYPES.DATE"
      v-model="dateRangeProxy"
      v-mask="'##.##.####'"
      class="m-date-picker"
      :class="{
        'w-100': fullWidth
      }"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :disabled="canManage.disabled"
      :format="dateFormat"
      :value-format="valueFormat"
      :picker-options="pickerOptions"
      :type="type"
      :clearable="clearable"
      placement="bottom-start"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
    >
      <slot />
    </el-date-picker>

    <!-- v-mask проблема-->
    <el-date-picker
      v-if="type === DATEPICKER_TYPES.DATE_RANGE"
      v-model="dateRangeProxy"
      class="m-date-picker m-date-picker_range"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :disabled="canManage.disabled"
      :format="dateFormat"
      :value-format="valueFormat"
      :picker-options="pickerOptions"
      :type="type"
      :clearable="clearable"
      placement="bottom-start"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
    >
      <slot />
    </el-date-picker>

    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :disabled="disabled"
      :focused="focused"
    />
  </validation-wrapper>
</template>

<script>
import { CALENDAR_RANGE, DEFAULT_CALENDAR_RANGE_NAMES } from '@/vue_components/calendar_ranges'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { DATEPICKER_TYPES, setDateRange } from '@/vue_present/_base/inputs/MDatePicker/const'
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import MLabel from '@/vue_present/_base/inputs/MLabel'

export default {
  name: 'MDatePicker',
  components: { MLabel, ValidationWrapper },
  mixins: [
    CommonInputProps,
    CommonInputMethods,
    ValidationChildMixin,
    DisabledMixin,
  ],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    type: PropsTypes.String(DATEPICKER_TYPES.DATE),
    value: PropsTypes.Custom([Array, String, Object, Date]),

    dateFormat: PropsTypes.String(Utils.elDateFormat),

    startPlaceholder: PropsTypes.String(t('start')),
    endPlaceholder: PropsTypes.String(t('end')),
    fallbackValue: PropsTypes.Array(),
    valueFormat: { type: String, default: undefined },
    shortcuts: PropsTypes.Array(DEFAULT_CALENDAR_RANGE_NAMES),
    firstDayOfWeek: PropsTypes.Number(gon.localization.day_week_start),
    fullWidth: { type: Boolean, default: true },
  },

  data () {
    return {
      focused: false,
      DATEPICKER_TYPES,
    }
  },

  computed: {
    pickerOptions () {
      return {
        shortcuts: this.type !== DATEPICKER_TYPES.DATE && this.shortcuts.map((shortcut) => CALENDAR_RANGE[shortcut]),
        firstDayOfWeek: this.firstDayOfWeek,
      }
    },

    dateRangeProxy: {
      get () { return this.value },

      set (value) {
        if (this.type === DATEPICKER_TYPES.DATE) {
          return this.onChange(value)
        }

        if (this.type === DATEPICKER_TYPES.DATE_RANGE && this.valueFormat) {
          return this.onChange(value)
        }

        const __value = Array.isArray(value) && !this.valueFormat
          ? setDateRange(value)
          : this.fallbackValue

        this.onChange(__value)
      },
    },
  },
}
</script>
