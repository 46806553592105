import { ItemBase } from '@/_api/_classes/ItemBase'
import { IPersonalSimpleMedicalAssessment } from '@/vue_apps/CheckupsModule/interfaces/IPersonalSimpleMedicalAssessment'
import { IDate } from '@/_declarations/IDate'

export class PersonalSimpleMedicalAssessmentModel extends ItemBase {
  _permit = [
    'actual',
    'id',
    'companyTitle',
    'companyTitleText',
    'factorsData',
    'factorsText',
    'footerDate',
    'footerName',
    'harmfulFactorsData',
    'harmfulFactorsText',
    'headerClinicAddress',
    'headerClinicEmail',
    'headerClinicLegalName',
    'headerClinicLicense',
    'headerClinicLicenseIssueDate',
    'headerClinicPhone',
    'headerClinicId',
    'headerText',
    'medicalAssessmentData',
    'medicalAssessmentText',
    'titleDate',
    'workerAge',
    'workerAgeText',
    'workerDepartment',
    'workerDepartmentText',
    'workerFullName',
    'workerFullNameText',
    'workerPosition',
    'workerPositionText',
    'workerSex',
    'workerSexText',
  ]

  actual: boolean = true

  id: number

  companyTitle: string

  companyTitleText: string

  factorsData: string

  factorsText: string

  footerDate: string

  footerName: string

  harmfulFactorsData: string

  harmfulFactorsText: string

  headerClinicAddress: string

  headerClinicEmail: string

  headerClinicLegalName: string

  headerClinicLicense: string

  headerClinicLicenseIssueDate: string

  headerClinicPhone: string

  headerClinicId: number

  headerText: string

  medicalAssessmentData: string | null

  medicalAssessmentText: string

  titleDate: IDate<string | number>

  workerAge: string

  workerAgeText: string

  workerDepartment: string | null

  workerDepartmentText: string

  workerFullName: string

  workerFullNameText: string

  workerPosition: string | null

  workerPositionText: string

  workerSex: string

  workerSexText: string

  constructor (props?: Partial<IPersonalSimpleMedicalAssessment>) {
    super()

    this._fillProps(props)
    if (props && Object.hasOwn(props, 'actual')) {
      this.actual = props.actual
    }
  }

  fillProps (props?: Partial<IPersonalSimpleMedicalAssessment>) {
    this._fillProps(props)
  }
}
