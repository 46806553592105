import {
  personalCheckupEntryWithEntryTypesAdapter,
} from '@/vue_apps/CheckupsModule/api/CheckupEntryPresenter/personalCheckupEntryWithEntryTypesAdapter'
import { MCheckupEntryPresenter } from '@/_api/MCheckupEntryPresenter/MCheckupEntryPresenter'

export class CheckupEntryPresenter extends MCheckupEntryPresenter {
  constructor () {
    super()

    this.location = 'CheckupEntryPresenter'
  }

  list (data, config = {}) {
    return super.list(data, {
      toClientAdapter: personalCheckupEntryWithEntryTypesAdapter.toClient,
      ...config,
    })
  }
}
