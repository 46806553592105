<template>
  <div class="company-final-act-view__header">
    <span v-html="t$('header.ministryOfHealth')" />

    <!-- Название клиники -->
    <document-field-writable
      :value="document.headerClinicName"
      width="400px"
      @input="document.setValue('headerClinicName', $event)"
    />

    <!-- Адрес клиники -->
    <document-field-writable
      :value="document.headerClinicAddress"
      width="400px"
      multiline
      @input="document.setValue('headerClinicAddress', $event)"
    />

    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ t$('header.ogrn') }}
      </span>

      <!-- ОГРН клиники -->
      <document-field-writable
        :value="document.headerClinicOgrn"
        width="110px"
        @input="document.setValue('headerClinicOgrn', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import { CompanyFinalActApi } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'CompanyFinalActViewHeader',

  components: { DocumentFieldWritable },

  mixins: [i18nScopeMixin],

  props: {
    document: CompanyFinalActApi,
  },

  created () {
    this.setI18nScope('checkups.documents.companyFinalAct')
  },

})
</script>
