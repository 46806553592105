<template>
  <div class="tax-certificate-2024-printable-page-two">
    <tax-certificate2024-printable-page-header
      class="mb-indent"
      :certificate="certificate"
      :page="SECOND_PAGE"
    />

    <!--  Даннные физ. лица (пациент)  -->
    <tax-certificate2024-printable-personal-information :certificate="certificate" />

    <div class="tax-certificate-2024-printable-page-two__footer">
      <span>{{ t$('secondPageFooter') }}</span>
      <span class="mb-indent-mid">{{ t$('innExplanation') }}</span>

      <div class="flex flex-column align-items-center">
        <span class="mb-indent-mid">{{ t$('employeeSecondPageSignatureTitle') }}</span>
        <span>{{ t$('secondPageFooterPlaceholder') }}</span>
      </div>

      <div class="flex justify-content-between">
        <document-black-square />
        <document-black-square />
      </div>
    </div>
  </div>
</template>

<script>
import TaxCertificate2024PrintablePageHeader
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintablePageHeader.vue'
import {
  SECOND_PAGE,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024PrintableConsts'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import TaxCertificate2024PrintablePersonalInformation
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintablePersonalInformation.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import DocumentBlackSquare from '@/vue_present/DocumentBase/DocumentBlackSquare/DocumentBlackSquare.vue'

export default {
  name: 'TaxCertificate2024PrintablePageTwo',

  components: {
    DocumentBlackSquare,
    TaxCertificate2024PrintablePersonalInformation,
    TaxCertificate2024PrintablePageHeader,
  },

  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  data () {
    return {
      SECOND_PAGE,
    }
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.print')
  },
}
</script>
