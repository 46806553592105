<template>
  <div class="semd-document-attributes-container">
    <template v-for="(value, key) in attributesView">
      <div
        v-if="value"
        :key="key"
        class="mb-indent"
        :class="`semd-document-attributes-container__${key}`"
      >
        <span class="bold">{{ t$(key) }}:</span>
        <span>{{ value }}</span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ISemd224SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd224/ISemd224SemdsDocumentDetailsAttributesData'
import { SEMD224_ENTITY_NAMES } from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd224/semd224EntityNames'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'Semd224DocumentAttributes',

  mixins: [i18nScopeMixin],

  props: {
    attributes: {
      type: Object as PropType<ISemd224SemdsDocumentDetailsAttributesData>,
      default () {},
    },
  },

  data () {
    return {
      SEMD224_ENTITY_NAMES,
    }
  },

  computed: {
    attributesView () {
      return {
        [SEMD224_ENTITY_NAMES.DATE_START]: this.$filters.date(this.attributes[SEMD224_ENTITY_NAMES.DATE_START]),
        [SEMD224_ENTITY_NAMES.DATE_END]: this.attributes[SEMD224_ENTITY_NAMES.DATE_END]
          ? this.$filters.date(this.attributes[SEMD224_ENTITY_NAMES.DATE_END])
          : '',
        [SEMD224_ENTITY_NAMES.TIME_START]: this.attributes[SEMD224_ENTITY_NAMES.TIME_START],
        [SEMD224_ENTITY_NAMES.TIME_END]: this.attributes[SEMD224_ENTITY_NAMES.TIME_END],
        [SEMD224_ENTITY_NAMES.RADIATION_EXPOSURE]: `${this.attributes[SEMD224_ENTITY_NAMES.RADIATION_EXPOSURE] || ''} ${this.attributes.nsiRadiationExposureUnit?.title || ''}`.trim(),
        [SEMD224_ENTITY_NAMES.DIRECTIONAL_MKB_DIAGNOSIS_GROUP]: this.attributes[SEMD224_ENTITY_NAMES.DIRECTIONAL_MKB_DIAGNOSIS_GROUP]?.title,
        [SEMD224_ENTITY_NAMES.CASE_VISIT_TYPE]: this.attributes[SEMD224_ENTITY_NAMES.CASE_VISIT_TYPE]?.title,
        [SEMD224_ENTITY_NAMES.MEDICAL_CARE_PLACE]: this.attributes[SEMD224_ENTITY_NAMES.MEDICAL_CARE_PLACE]?.title,
        [SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH]: this.attributes[SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH]?.title,
        [SEMD224_ENTITY_NAMES.ULTRASOUND_MODE]: this.attributes[SEMD224_ENTITY_NAMES.ULTRASOUND_MODE]?.title,
        [SEMD224_ENTITY_NAMES.ECHO_IMAGE_QUALITY]: this.attributes[SEMD224_ENTITY_NAMES.ECHO_IMAGE_QUALITY]?.title,
        [SEMD224_ENTITY_NAMES.DETECTED_PATHOLOGY]: this.attributes[SEMD224_ENTITY_NAMES.DETECTED_PATHOLOGY]?.map(({ title }) => title).join(', '),
        [SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH_PROTOCOL_TEXT]: this.attributes[SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH_PROTOCOL_TEXT],
        [SEMD224_ENTITY_NAMES.CONCLUSION]: this.attributes[SEMD224_ENTITY_NAMES.CONCLUSION],
        [SEMD224_ENTITY_NAMES.RECOMMENDATIONS]: this.attributes[SEMD224_ENTITY_NAMES.RECOMMENDATIONS],
      }
    },
  },

  created () {
    this.setI18nScope('semds.semd224')
  },
})
</script>
