<template>
  <panel-heading
    :title="t('new_company')"
    icon="fad fa-building"
  >
    <div class="v-company-edit">
      <company-form
        :title.sync="form.company.title"
        :type-ids.sync="form.company.company_type_ids"
        :ownership.sync="form.company.type_of_company"
        :inn.sync="form.company.individual_tax_number"
        :kpp.sync="form.company.kpp"
        :ogrn.sync="form.company.ogrn"
        :okved.sync="form.company.okved"
        :discount.sync="form.company.discount"
        :registration-date.sync="form.company.registration_date"
        :note.sync="form.company.note"
        :phone1.sync="form.company.phone1"
        :phone2.sync="form.company.phone2"
        :fax.sync="form.company.fax"
        :web.sync="form.company.web"
        :email.sync="form.company.email"
        :head-name.sync="form.company.head_name"
        :head-surname.sync="form.company.head_surname"
        :head-second-name.sync="form.company.head_second_name"
        :head-position.sync="form.company.head_position"
        :head-operates-by.sync="form.company.head_operates_by"
        :formal-address-index.sync="form.company.address_formal.index"
        :formal-address-country.sync="form.company.address_formal.country"
        :formal-address-region.sync="form.company.address_formal.region"
        :formal-address-area.sync="form.company.address_formal.area"
        :formal-address-city.sync="form.company.address_formal.city"
        :formal-address-street.sync="form.company.address_formal.street"
        :formal-address-house.sync="form.company.address_formal.house"
        :formal-address-flat.sync="form.company.address_formal.flat"
        :formal-nsi-russian-subject-id.sync="form.company.address_formal.nsi_russian_subject_id"
        :fact-address-index.sync="form.company.address_fact.index"
        :fact-address-country.sync="form.company.address_fact.country"
        :fact-address-region.sync="form.company.address_fact.region"
        :fact-address-area.sync="form.company.address_fact.area"
        :fact-address-city.sync="form.company.address_fact.city"
        :fact-address-street.sync="form.company.address_fact.street"
        :fact-address-house.sync="form.company.address_fact.house"
        :fact-address-flat.sync="form.company.address_fact.flat"
        :fact-nsi-russian-subject-id.sync="form.company.address_fact.nsi_russian_subject_id"
        :types="form.companyTypes"
        :validation-messages.sync="form.validationMessages"
        :loading="form.loading"
        :loading-types="form.loadingTypes"
      />
      <button
        type="submit"
        class="btn btn-primary btn-with-icon"
        @click="createCompany"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-save" />
        <span class="btn-with-icon_text">{{ t('save') }}</span>
      </button>
      <back-button pattern="companies" />
    </div>
  </panel-heading>
</template>

<script>
import BackButton from '@/vue_components/common/buttons/back_button.vue'
import CompanyForm from '@/vue_components/companies/components/company_form/company_form.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'

import { getRegistrationDate } from '../helpers/get_registration_date.js'
import { nameToCapitalize } from '../helpers/name_to_capitalize.js'
import { getDefaultCompanyData } from './consts.js'
import { COMPANIES, COMPANY_SHOW } from '@/vue_components/router/modules/companies/route_names'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'

export default {
  name: 'CompanyNew',

  components: {
    BackButton,
    CompanyForm,
    PanelHeading,
  },

  data: () => ({
    form: {
      loading: false,
      loadingTypes: true,
      company: getDefaultCompanyData(),

      companyTypes: [
        { id: -1, title: t('loading_1') },
      ],

      validationMessages: {
        title: [],
        company_types: [],
      },
    },
    COMPANIES,
  }),

  created () {
    this.fetchCompanyTypes()
  },

  methods: {
    fetchCompanyTypes () {
      companiesEndpoint.getCompanyTypes()
        .then((companyTypes) => {
          if (companyTypes.length < 2) {
            console.debug('Array length companyTypes should be more 2')
          }
          this.form.companyTypes =
                companyTypes.map((type) => ({ id: type.id, title: type.title }))
          this.form.loadingTypes = false
        })
    },

    createCompany () {
      this.form.loading = true

      this.form.company.registration_date = getRegistrationDate(this.form.company.registration_date)

      this.form.company.head_name = nameToCapitalize(this.form.company.head_name)
      this.form.company.head_surname = nameToCapitalize(this.form.company.head_surname)
      this.form.company.head_second_name = nameToCapitalize(this.form.company.head_second_name)

      companiesEndpoint.create({
        ...this.form.company,
        address_formal: {
          ...this.form.company.address_formal,
          nsi_russian_subject_id: (typeof this.form.company.address_formal.nsi_russian_subject_id === 'object'
            ? this.form.company.address_formal.nsi_russian_subject_id?.id
            : this.form.company.address_formal.nsi_russian_subject_id) || '',
        },
        address_fact: {
          ...this.form.company.address_fact,
          nsi_russian_subject_id: (typeof this.form.company.address_fact.nsi_russian_subject_id === 'object'
            ? this.form.company.address_fact.nsi_russian_subject_id?.id
            : this.form.company.address_fact.nsi_russian_subject_id) || '',
        },
      })
        .then((company) => {
          this.$router.push({ name: COMPANY_SHOW, params: { companyId: company.id } })
          Notificator.success(t('record_successfully_created'))
        })
        .catch((res) => {
          const errors = res.responseJSON

          this.form.company.registration_date =
            getRegistrationDate(this.form.company.registration_date)

          this.form.validationMessages.title = errors.title || []
          this.form.validationMessages.types = errors.company_types || []
          this.form.validationMessages.inn = errors.individual_tax_number
        })
        .finally(() => {
          this.form.loading = false
        })
    },
  },
}
</script>
