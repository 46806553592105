<template>
  <div class="flex flex-column">
    <span
      class="text-center mb-indent-mid"
      v-html="t$('employeeTitle')"
    />

    <!-- Фамилия -->
    <document-square-field
      class="mb-indent-small"
      :value="certificate.employee.surname"
      :line-size="20"
    />

    <!-- Имя -->
    <document-square-field
      class="mb-indent-small"
      :value="certificate.employee.name"
      :line-size="20"
    />

    <!-- Отчество -->
    <document-square-field
      :value="certificate.employee.secondName"
      :line-size="20"
    />

    <span class="text-center mb-indent-mid">
      {{ t$('employeeExplanation') }}
    </span>

    <document-square-field-labeled style="gap: 18px">
      <!-- Место для подписи -->
      <template #label>
        <span>{{ t$('employeeSignaturePlaceholder') }}</span>
      </template>

      <!-- Дата подписания -->
      <template #field>
        <document-square-field-date
          :value="certificate.dateOfSignatureByEmployee"
        />
      </template>
    </document-square-field-labeled>
  </div>
</template>

<script>
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import DocumentSquareField from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareField.vue'
import DocumentSquareFieldLabeled
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLabeled/DocumentSquareFieldLabeled.vue'
import DocumentSquareFieldDate
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldDate/DocumentSquareFieldDate.vue'

export default {
  name: 'TaxCertificate2024PrintableEmployee',
  components: { DocumentSquareFieldDate, DocumentSquareFieldLabeled, DocumentSquareField },

  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.print')
  },
}
</script>
