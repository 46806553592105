import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'

export const getTreeExtraAttributes = (documentTypes: REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES): string[] => {
  switch (documentTypes) {
    case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.DOCUMENT:
      return []

    case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED:
      return [
        'new',
      ]

    case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.PROTOCOL:
      return []

    default:
      return []
  }
}
