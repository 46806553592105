export const PAYMENT_PARAMS = Object.freeze({
  PAYER_TYPE: { COMPANY: 'company', CLIENT: 'client' },
  BASE_KIND: { FUND: 'fund', REFUND: 'refund' },
  DISTRIBUTION: { AUTO_DISTRIBUTION: 'auto_distribution' },
  KIND_PAYMENT: {
    CREDIT: 'order_in_credit',
    FULL: 'order_full',
    INITIAL: 'order_initial',
    SUBSEQUENT: 'order_subsequent',
    CASH_IN: 'cash_in',
    CASH_OUT: 'cash_out',
    ORDER_REFUND: 'order_refund',
    CASH_IN_REFUND: 'cash_in_refund',

  },
  KIND_ENTRY: {
    CREDIT: 'credit',
    FULL: 'full',
    INITIAL: 'initial',
    SUBSEQUENT: 'subsequent',
    EMPTY: 'empty',
  },
})

export const ORDER_PAID_STATUS = Object.freeze({
  NOT_PAID: 'not_paid',
  PAID_CREDIT: 'paid_by_credit',
})

export const PAYMENT_ERRORS = Object.freeze({
  TYPES: {
    CLIENT: {
      ORDER_IN_CREDIT: { CODE: -1 },
    },
    NETWORK: {
      INSUFFICIENT_FUNDS_BALANCE: { CODE: 0 },
      INVALID_PAYMENT: { CODE: 1 },
    },
  },
  MESSAGES: {
    INVALID_PAYMENT_FOR_FUND: t('invalid_payment_for_fund'),
    INVALID_PAYMENT_FOR_REFUND: t('invalid_payment_for_refund'),
    SUM_NEGATIVE: t('payment_negative_sum'),
    SUM_LESS_BALANCE: t('payment_less_balance'),
    SUM_LESS_BALANCE_MASSPAY: t('payment_less_balance_masspay'),
    SUM_BY_BALANCE_LESS_ORDER_SUM: t('payment_by_balance_less_order_sum'),
    INSUFFICIENT_FUNDS_ON_BALANCE: t('insufficient_funds_on_the_personal_account'),
    INSUFFICIENT_FUNDS_FOR_ORDERS: t('insufficient_funds_for_orders'),
    INSUFFICIENT_FUNDS_FOR_REFUND: t('insufficient_funds_for_refund'),
    ORDER_IN_CREDIT: t('order_in_credit'),
    SPECIES_MUST_DEFINE: t('payment_spec_defined'),
  },
})
