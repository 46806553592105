import { IDiseasesList } from '@/_declarations/IDiseasesList'

export const personalCheckupDiagnosesPersonalSearchAdapter = {
  toServer (data) {
    return {
      sortParams: data,
    }
  },

  toClient (list: IDiseasesList) {
    return {
      data: list.data.map((item) => ({
        diagnosisId: item.id,
        diagnosisTitle: item.title,
        diagnosisCodeString: item.codeString,
        categoryId: item.categoryId,
        classCode: item.classCode,
        className: item.className,
      })),

      totalItems: list.totalItems,
      totalPages: list.totalPages,
    }
  },
}
