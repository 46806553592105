import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getPersonalCheckupFilesMsiSchema = () =>
  new MSiSchema()
    .addTableSchema(
      new MTableSchema({
        headers: {
          date: new MTableHeader().addFixedWidth('100px'),
          title: new MTableHeader(),
          download: new MTableHeader().addFixedWidth('40px'),
          print: new MTableHeader().addFixedWidth('40px'),
        },
      })
        .addDeleteIcon(Services.security.canManageCheckupsCompany)
    )
    .addPagination()
