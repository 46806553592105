import { createVueApp } from '@/vue_components/create_vue_app'
import FeedbackModal from '@/vue_apps/FeedbackModule/components/FeedbackModal.vue'

class ClientFeedbacks {
  vueApp = null

  // eslint-disable-next-line
  openModal = async (id) => { }

  subscribeToWebSockets () {
    Services.wsSubscriptions.feedback.connect((packet) => {
      const { data: { event, resource } } = packet

      if (event === 'updated') {
        Services.pubSub.emitAsync('CLIENT_FEEDBACKS.UPDATED_FEEDBACK', resource)
      }

      if (event === 'HEAD_DESKTOP_NEW_FEEDBBACK') {
        Services.pubSub.emitAsync('CLIENT_FEEDBACKS.HEAD_DESKTOP_NEW_FEEDBBACK', resource)
      }
    })
  }

  appendClientFeedbackModal () {
    if (this.vueApp?.$destroy) { this.vueApp.$destroy() }

    const that = this
    this.vueApp = createVueApp({
      el: '#vue_client_feedbacks',
      name: 'ClientFeedbacksRoot',
      render: (h) => h(FeedbackModal, {
        on: {
          registerOpenModal ($event) {
            that.openModal = $event
          },
        },
      }),
    })

    Services.pubSub.subscribe('CLIENT_FEEDBACKS_MODAL.OPEN', (id) => that.openModal(id))
  }

  init () {
    this.subscribeToWebSockets()
    this.appendClientFeedbackModal()
  }
}

const instance = new ClientFeedbacks()
export default instance
