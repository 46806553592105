<template>
  <div class="tax-certificate-2024-editor-sum">
    <span class="tax-certificate-2024-editor-sum__label">
      {{ t$('amountOfExpensesForMedicalServices', { serviceCode }) }}
    </span>

    <!-- Сумма расходов -->
    <m-input
      type="number"
      :min="0"
      :max="MAX_SUM_NUMBER"
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  MAX_SUM_NUMBER,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'

export default {
  name: 'TaxCertificate2024EditorSum',

  components: { MInput },

  mixins: [i18nScopeMixin],

  props: {
    serviceCode: { type: Number, required: true },
    disabled: Boolean,
    value: { type: Number, default: 0 },
  },

  data () {
    return {
      MAX_SUM_NUMBER,
    }
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },
}
</script>
