import { IPersonalCheckupVaccinationItem } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupVaccinationItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { tScoped } from '@/helpers/i18nHelpers'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

const ts = tScoped('checkups.vaccinationsAndDisease.vaccination')

export class PersonalCheckupVaccinationListItem extends MListServiceNestedItemsItem {
  date: string

  year: MTableCell

  mibpDiseasesGroupTitle: MTableCell

  number: number

  mibpDiseasesGroupId: number

  constructor (id: number, item: IPersonalCheckupVaccinationItem) {
    super(id, item)

    this.date = item.date

    this.year = new MTableCell(
      moment(item.date)
        .year()
    ).addTooltip(true)

    this.number = item.number

    this.mibpDiseasesGroupId = item.mibpDiseasesGroupId

    this.__setMibpDiseasesGroupTitle(item)
  }

  __setMibpDiseasesGroupTitle ({ mibpDiseasesGroupTitle, number }: IPersonalCheckupVaccinationItem) {
    const title = [
      mibpDiseasesGroupTitle,
      number > 1
        ? ts('revaccination', { number })
        : ts('vaccination'),
    ].join(', ')

    this.mibpDiseasesGroupTitle = new MTableCell(title)
      .addTooltip(true)
      .addCut()
  }
}
