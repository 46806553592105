import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  IPersonalCheckupDiagnosesPersonalItem,
} from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupDiagnosesPersonalItem'
import {
  PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDiagnosesPersonalType'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

export class PersonalCheckupDiagnosesPersonalListItem extends MListServiceNestedItemsItem {
  diagnosisId: number

  diagnosisCodeString: string

  diagnosisTitle: string

  msiDiagnosisCodeString: MTableCell

  msiDiagnosisTitle: MTableCell

  diagnosisType: string

  msiDiagnosisType: MTableCell

  diagnosisDate: MTableCell

  constructor (id: number, item: IPersonalCheckupDiagnosesPersonalItem) {
    super(id, item)

    this.diagnosisId = item.diagnosisId

    this.diagnosisCodeString = item.diagnosisCodeString

    this.diagnosisTitle = item.diagnosisTitle

    this.msiDiagnosisCodeString = new MTableCell(this.diagnosisCodeString)
      .addTooltip(true)

    this.msiDiagnosisTitle = new MTableCell(this.diagnosisTitle)
      .addTooltip(true)
      .addCut()

    this.diagnosisType = item.diagnosisType

    this.msiDiagnosisType = new MTableCell(t(`checkups.diagnosesPersonal.type.${item.diagnosisType}`))
      .addTooltip(true)
      .addCut()
      .addClasses(this.getDiagnosisTypeColor(item.diagnosisType))

    this.diagnosisDate = new MTableCell(Utils.getFormattedDate(item.diagnosisDate))
      .addTooltip(true)
      .addCut()
  }

  private getDiagnosisTypeColor (diagnosisType: PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE) {
    switch (diagnosisType) {
      case PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.PRELIMINARY: return 'success'
      case PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.CHRONIC: return 'warning'
      case PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.INFECTIOUS: return 'danger'
    }
  }
}
