<template>
  <m-panel
    :title="t('semds.semd119.formSections.medicalInformation')"
    :type="panelType"
    icon="clipboardUser"
    :use-type-by-route="false"
  >
    <medical-case-select-add
      v-if="semd.client"
      :value="semd.metaData._medicalCaseModel"
      :client-id="semd.client.id"
      :disabled="isShowMode"
      @registerValidator="$registerValidator($event)"
      @change="onMedicalCaseChange"
      @firstTimeFetched="trySetFirstCaseIfNotSelected"
    />

    <slot name="documentAttributes" />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MedicalCaseSelectAdd from '@/vue_apps/EgiszModule/MedicalCaseSelectAdd.vue'
import { Semd119 } from '@/vue_apps/Semds/entities/Semd119/Semd119'

export default {
  name: 'ConsultationFormMedicalInfoArea',
  components: {
    MedicalCaseSelectAdd,
    MPanel,
  },

  props: {
    semd: Semd119,
    panelType: { type: String, default: 'success' },
    isShowMode: { type: Boolean, default: false },
  },

  methods: {
    trySetFirstCaseIfNotSelected (cases = []) {
      if (this.semd.id) { return }
      if (this.semd.medicalCaseId || this.semd.metaData._medicalCaseModel) { return }
      if (!cases.length) { return }

      this.onMedicalCaseChange(cases[0])
    },

    onMedicalCaseChange (value) {
      this.semd.setMetaDataValue('_medicalCaseModel', value)
      this.semd.setValue('medicalCaseId', value?.id || null)
    },
  },
}
</script>
