export default class NumberDeclension {
  constructor (titles, locale = 'ru-RU') {
    this.titles = titles
    this.locale = locale
  }

  declaration (num) {
    return this.titles[this._pluralKey(num)]
  }

  _pluralKey (num) {
    return new Intl.PluralRules(this.locale).select(num)
  }
}
