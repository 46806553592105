<template>
  <span
    v-tooltip="tooltip"
    :class="css"
    class="cut"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'TypeDateTime',
  props: [
    'tooltip',
    'value',
    'css',
  ],
}
</script>
