import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  personalElmkMedicalAssessmentAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalElmkMedicalAssessmentPresenter/personalElmkMedicalAssessmentAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { camelToSnake } from '@/_api/_requests/helpers'

export class PersonalElmkMedicalAssessmentPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.api_internal_checkups_documents_personals_elmk_medical_assessment_path,
        all: Routes.api_internal_checkups_documents_personals_elmk_medical_assessments_path,
        list () {},
      },
      entity: 'personalElmkMedicalAssessment',
      location: 'PersonalElmkMedicalAssessmentPresenter',
    })
  }

  validatePersonalCheckup (personalCheckupId, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'validateMessage',
      'personalCheckup'
    )

    return super.post({
      url: Routes.validate_api_internal_checkups_documents_personals_elmk_medical_assessments_path(),
      data: JSON.stringify(camelToSnake({
        document: {
          personalCheckupId,
        },
      })),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .catch(MRequestError.withDefault(false, notification))
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      toClientAdapter: personalElmkMedicalAssessmentAdapter.toClient,
      ...config,
    })
  }

  create (personalCheckupId, config = {}) {
    return super.create({
      document: {
        personalCheckupId,
      },
    }, {
      toJson: true,
      ...config,
    })
  }

  update (data, config = {}) {
    return super.update(data, {
      toServerAdapter: personalElmkMedicalAssessmentAdapter.toServer,
      toJson: true,
      ...config,
    })
  }

  refresh (id, data) {
    if (!id) { return }

    return super.post({
      url: Routes.refresh_api_internal_checkups_documents_personals_elmk_medical_assessment_path(id),
      data: camelToSnake(data),
    })
      .promise
      .then(personalElmkMedicalAssessmentAdapter.toClient)
      .catch(MRequestError.onRequest(null))
  }
}
