import { IDocumentsOwner } from '@/_declarations/documents/IDocumentsOwner'
import { orUndefined } from '@/_medods_standart_library/msl'

export const documentPresenterCreateDocumentAdapter = {
  toServer (data: IDocumentsOwner) {
    return {
      document: {
        clientId: orUndefined(data.clientId),
        companyId: orUndefined(data.companyId),
        documentTypeId: data.documentTypeId,
        documentsOwnersAttributes: data.documentsOwnersAttributes,
      },
    }
  },
}
