import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { guaranteeLetterAdapter } from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/guaranteeLetterAdapter'
import { guaranteeLettersAdapter } from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/guaranteeLettersAdapter'

export class MGuaranteeLetterPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'guaranteeLetter',
      location: 'MGuaranteeLetterPresenter',
      routes: {
        all: Routes.api_internal_finances_guarantee_letters_path,
        one: Routes.api_internal_finances_guarantee_letter_path,
        list: Routes.list_api_internal_finances_guarantee_letters_path,
      },
    })
  }

  list (listData, config = {}) {
    return super.list(listData, config)
      .then((data) => guaranteeLettersAdapter.toClient(data))
  }

  /**
   * @param {IGuaranteeLetter} data
   * @param config
   * @return {Promise<*|RequestErrors>}
   */
  submit (data, config = {}) {
    return super.submit(data, {
      ...config,
      toServerAdapter: (data) => ({
        guaranteeLetter: guaranteeLetterAdapter.toServer(data),
      }),
      toJson: true,
    })
  }
}
