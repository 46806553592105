const requiredCaseFields = [
  'netrika_payment_type_id',
  'opened_at',
  'closed_at',
  'doctor_id',
  'netrika_case_visit_type',
  'netrika_case_type_id',
  'netrika_confidentiality',
  'steps',
  'netrika_case_result_id',
  'comment',
]

const requiredDiagnosisFields = [
  'diagnosis_type_id',
  'disease_id',
  'date',
  'comment',
]

const isEmpty = (field) => Boolean(
  !field ||
  (field.hasOwnProperty('length') && !field.length)
)

/**
 * @typedef {{
 *   hasEmpty: Boolean,
 *   emptyCaseFields: String[],
 *   emptyCaseDiagnosisFields: String[]
 * }} EmptyCaseObject
 *
 * @param medCase
 * @return EmptyCaseObject
 */
export const checkCaseFields = (medCase) => {
  const emptyCaseFields = []
  const emptyCaseDiagnosisFields = []

  requiredCaseFields.forEach((field) => {
    if (!isEmpty(medCase[field])) { return }
    emptyCaseFields.push(field)
  })

  requiredDiagnosisFields.forEach((field) => {
    if (!isEmpty(medCase.diagnosis[field])) { return }
    emptyCaseDiagnosisFields.push(field)
  })

  return {
    emptyCaseFields,
    emptyCaseDiagnosisFields,
    hasEmpty: Boolean(emptyCaseFields.length || emptyCaseDiagnosisFields.length),
  }
}

/**
 * @param {EmptyCaseObject} emptyCaseObject
 * @return String
 */
export const getTextOfEmptyFields = (emptyCaseObject) => {
  const { emptyCaseFields, emptyCaseDiagnosisFields, hasEmpty } = emptyCaseObject
  if (!hasEmpty) { return '' }

  const emptyCaseFieldsTexts = emptyCaseFields
    .map((item) => t(`egisz.medical_cases.form.${item}`))

  const emptyCaseDiagnosisFieldsTexts = emptyCaseDiagnosisFields
    .map((item) => t(`egisz.med_records.modal.diagnosis.${item}`))

  return [...emptyCaseFieldsTexts, ...emptyCaseDiagnosisFieldsTexts].join(', ')
}
