import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const $t = (variable) => {
  return t(variable, { scope: 'finance.paymentsTableTitles' })
}

const workplaceEnabled = gon.application.workplaces_module_enabled

export const getPaymentsSchema = () => {
  return new MSiSchema()
    .addSearch()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addHeaders({
          number: new MTableHeader($t('number')).addFixedWidth('70px'),
          kind: new MTableHeader()
            .addTypeIcon('ready')
            .addTooltip(t('finance.paymentKind'))
            .addFixedWidth('30px'),
          date: new MTableHeader($t('date')).addFixedWidth('100px'),
          customer: new MTableHeader($t('customer')),
          byCash: new MTableHeader($t('by_cash')),
          byCashless: new MTableHeader($t('by_cashless'))
            .addClasses('cut')
            .addTooltip($t('by_cashless')),
          byCard: new MTableHeader($t('by_card')),
          byBalance: new MTableHeader($t('by_balance'))
            .addClasses('cut')
            .addTooltip($t('by_balance')),
          byCredit: new MTableHeader($t('by_credit')),
          totalIncome: new MTableHeader($t('total_income'))
            .addTooltip(t('finance.paymentsTableTitlesTooltips.totalIncome')),
          totalPaid: new MTableHeader($t('total_paid'))
            .addTooltip(t('finance.paymentsTableTitlesTooltips.totalPaid')),
          order: new MTableHeader($t('order'))
            .addTooltip(t('finance.paymentsTableTitlesTooltips.order')),
          receipt: workplaceEnabled ? new MTableHeader($t('receipt')).addFixedWidth('60px') : undefined,
        })
    )
}
