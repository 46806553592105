import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

const workplaceEnabled = gon.application.workplaces_module_enabled

export class PaymentsListTotalItem {
  constructor (rawData) {
    this.number = new MTableCell(t('finance.totalWithAsterisk'))
      .addTooltip(t('finance.paymentsTotalTooltip'))
    this.kind = new MTableCell()
    this.date = new MTableCell()
    this.customer = new MTableCell()
    this.byCash = new MTableCell(vueFilters.currency(rawData.byCash))
    this.byCashless = new MTableCell(vueFilters.currency(rawData.byCashless))
    this.byCard = new MTableCell(vueFilters.currency(rawData.byCard))
    this.byBalance = new MTableCell(vueFilters.currency(rawData.byBalance))
    this.byCredit = new MTableCell(vueFilters.currency(rawData.byCredit))
    this.totalIncome = new MTableCell(vueFilters.currency(rawData.totalIncome))
    this.totalPaid = new MTableCell(vueFilters.currency(rawData.totalPaid))
    this.order = new MTableCell('-*').addTooltip(t('finance.paymentOrdersTotalTooltip'))
    if (workplaceEnabled) {
      this.receipt = new MTableCell()
    }
  }
}
