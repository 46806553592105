import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export const MInputsWrapperMiddlewareMixin = {
  props: {
    mFixedHeight: { type: Boolean, default: true },
    validatorName: PropsTypes.String(),
    required: { type: [Boolean, Function], default: false },

    label: PropsTypes.String(),
  },

  emits: [
    'registerValidator',
  ],
}
