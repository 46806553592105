<template>
  <div class="flex flex-grow-1 priority-card">
    <m-switch
      :value="Boolean(messagesPriority.priority)"
      class="mr-5"
      @change="$emit('change', $event)"
    />

    <div
      class="flex-grow-1"
      :class="{ 'prompt-notice': !messagesPriority.priority }"
    >
      {{ messagesPriority.deliveryType }}
    </div>

    <div
      v-if="messagesPriority.priority !== 0"
      class="flex gap-indent-small"
    >
      <m-icon
        class="pointer"
        icon="up"
        :color="upArrowIconColor"
        @click="$emit('onArrowClickUp', messagesPriority, 'up', index)"
      />
      <m-icon
        class="pointer"
        icon="down"
        :color="downArrowIconColor"
        @click="$emit('onArrowClickDown', messagesPriority, 'down', index)"
      />
    </div>
  </div>
</template>

<script>
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'MessagePriorityCard',
  components: {
    MIcon,
    MSwitch,
  },

  props: {
    messagesPriority: PropsTypes.Object(),
    index: PropsTypes.Number(),
    countOfSwitchedPriorities: PropsTypes.Number(),
  },

  computed: {
    upArrowIconColor () {
      return this.messagesPriority.priority && this.index !== 0 ? 'primary' : ''
    },

    downArrowIconColor () {
      return this.messagesPriority.priority && this.index !== this.countOfSwitchedPriorities - 1 ? 'primary' : ''
    },
  },
}
</script>
