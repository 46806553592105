<template>
  <div class="fixed-document-block">
    <m-prompt-notice :text="t$('informationAboutThePerformingClinic')" />

    <div class="flex justify-content-between gap-indent">
      <!-- ИНН Клиники -->
      <m-input
        :value="certificate.clinic.inn"
        :label="t('inn')"
        :required="innValidator"
        :max-length="innLength"
        validator-name="clinic.inn"
        :disabled="!certificate.individualEntrepreneur || disabled"
        @registerValidator="$registerValidator($event)"
        @input="certificate.setValue('clinic.inn', $event)"
      />

      <!-- КПП Клиники -->
      <m-input
        v-show="!certificate.individualEntrepreneur"
        :value="certificate.clinic.maskedKpp"
        :label="t('kpp')"
        placeholder="__ __ __ ___"
        :max-length="KPP_LENGTH"
        :required="isKppRequired"
        validator-name="clinic.kpp"
        :disabled="!certificate.individualEntrepreneur || disabled"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Название клиники -->
      <m-input
        v-if="!certificate.individualEntrepreneur"
        :label="t('clinic')"
        :value="certificate.clinic.legalName"
        disabled
      />

      <template v-else>
        <!-- Фамилия -->
        <m-input
          :label="t('surname')"
          :value="certificate.individualEntrepreneurFullName.surname"
          :required="requiredIfIndividualEntrepreneur"
          :disabled="disabled"
          validator-name="individualEntrepreneurFullName.surname"
          @input="certificate.setValue('individualEntrepreneurFullName.surname', $event)"
          @registerValidator="$registerValidator($event)"
        />

        <!-- Имя -->
        <m-input
          :label="t('name')"
          :value="certificate.individualEntrepreneurFullName.name"
          :required="requiredIfIndividualEntrepreneur"
          :disabled="disabled"
          validator-name="individualEntrepreneurFullName.name"
          @input="certificate.setValue('individualEntrepreneurFullName.name', $event)"
          @registerValidator="$registerValidator($event)"
        />

        <!-- Отчество -->
        <m-input
          :label="t('second_name')"
          :value="certificate.individualEntrepreneurFullName.secondName"
          :disabled="disabled"
          validator-name="individualEntrepreneurFullName.secondName"
          @input="certificate.setValue('individualEntrepreneurFullName.secondName', $event)"
          @registerValidator="$registerValidator($event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import {
  TaxCertificate2024EditorPerformingClinicValidationMixin,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/mixins/validationMixins/TaxCertificate2024EditorPerformingClinicValidationMixin'
import {
  INN_LENGTH,
  KPP_LENGTH,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'

export default {
  name: 'TaxCertificate2024EditorPerformingClinic',

  components: { MPromptNotice, MInput },

  mixins: [
    i18nScopeMixin,
    TaxCertificate2024EditorPerformingClinicValidationMixin,
  ],

  props: {
    certificate: TaxCertificate2024,
    disabled: Boolean,
  },

  data () {
    return {
      KPP_LENGTH,
    }
  },

  computed: {
    innLength () {
      return this.certificate.individualEntrepreneur
        ? INN_LENGTH.INDIVIDUAL
        : INN_LENGTH.ORGANIZATION
    },

    isKppRequired () {
      return !this.certificate.individualEntrepreneur
        ? this.kppValidator
        : false
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },
}
</script>
