import { ISemdEntity } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/ISemdEntity'
import { TTinyMCEEditor } from '@/vue_apps/Protocols/SemdProtocolEditor/interfaces/TTinyMCE'
import { ICatalog } from '@/_declarations/ICatalog'

export class AbstractSemdEntity implements ISemdEntity {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static parseContent (id: string, templateHtml: string): string | ICatalog {
    throw new Error('Method not implemented.')
  }

  id: string | number

  isOptional: boolean

  title: string

  value: unknown

  element: unknown

  disabled: boolean = false

  defaultValueTitle: string

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildTemplate (value: unknown | undefined): unknown {
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createElement (editor: TTinyMCEEditor): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  destroy (editor: TTinyMCEEditor): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getContent (options: Record<string, unknown> | undefined): unknown {
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid (options: Record<string, unknown> | undefined): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  linkWithElement (editor: TTinyMCEEditor): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update (editor: TTinyMCEEditor, value: ICatalog): void {
  }
}
