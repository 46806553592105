<template>
  <m-modal
    class="fast-create-modal"
    width="max-content"
    modal-title-icon="checkups"
    :dialog-title="t('checkups.fastCreate.title')"
    :visible="visible"
    @close="$emit('close')"
  >
    <template #body>
      <m-prompt-notice :text="t('checkups.fastCreate.notice')" />

      <fast-create-modal-client
        v-for="client in clientData"
        :key="client.key"
        :ref="`client-${client.key}`"
        v-loading="loading"
        :client="client"
        @onClientRemove="fastCreate.removeClient([$event])"
      />
    </template>

    <template #footer-left>
      <m-button
        template="add"
        :tooltip="t('checkups.fastCreate.add')"
        :disabled="loading"
        @click="fastCreate.appendClient()"
      />

      <fast-create-modal-copy-popover
        :client-data="clientData"
        :disabled="loading"
        @onClientCopy="fastCreate.copyClient($event)"
      />
    </template>

    <template #footer-right>
      <m-button
        :text="t('checkups.fastCreate.saveAll')"
        template="save"
        :disabled="loading"
        @click="onSave"
      />

      <m-button
        :text="t('checkups.fastCreate.cancelAll')"
        icon="close"
        type="warning"
        :disabled="loading"
        @click="$emit('close')"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { CompanyCheckup } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckup'
import { CHECKUP_STATUS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import { CHECKUP_KINDS } from '@/vue_apps/CheckupsModule/store/checkupsKinds'
import { FastCreateLogic } from '@/vue_apps/CheckupsModule/classes/FastCreate/FastCreateLogic'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import FastCreateModalCopyPopover
  from '@/vue_apps/CheckupsModule/components/CompanyCheckup/FastCreateModal/FastCreateModalCopyPopover.vue'
import FastCreateModalClient
  from '@/vue_apps/CheckupsModule/components/CompanyCheckup/FastCreateModal/FastCreateModalClient.vue'

export default {
  name: 'FastCreateModal',

  components: {
    FastCreateModalCopyPopover,
    FastCreateModalClient,
    MPromptNotice,
    MButton,
    MModal,
  },

  mixins: [SpinnerHolder],

  props: {
    companyCheckup: CompanyCheckup,
    visible: Boolean,
  },

  emits: ['close', 'onSubmit'],

  data () {
    return {
      fastCreate: null as FastCreateLogic,
    }
  },

  computed: {
    clientData () {
      return this.fastCreate.clientData
    },
  },

  created () {
    this.fastCreate = new FastCreateLogic({
      responsibleId: this.companyCheckup.responsible.id,
      clinicId: this.companyCheckup.clinic.id,
      companyId: this.companyCheckup.company.id,
      department: this.companyCheckup.department,
      companyCheckupId: this.companyCheckup.id,
      kind: this.companyCheckup.id
        ? CHECKUP_KINDS.PERIODIC
        : CHECKUP_KINDS.PRIOR,
      status: CHECKUP_STATUS.IN_PROGRESS,
    })

    this.fastCreate.appendClient()
  },

  methods: {
    async onSave () {
      const hasErrors = Object.values(this.$refs)
        .map(([clientRef]) => clientRef?.hasErrors())
        .some(Boolean)

      if (hasErrors) { return }

      const result = await this.withSpinner(this.fastCreate.save())
      if (result?.errors) { return }

      this.$pubSub.emit('updatePersonalCheckupsList', null, true)

      if (this.hasClients()) { return }

      this.$emit('onSubmit')

      this.$emit('close')
    },

    hasClients () {
      return !!Object.keys(this.clientData).length
    },
  },
}
</script>
