<template>
  <div class="sidebar-filters">
    <egisz-cases-filter-clinic />
    <egisz-cases-filter-date-open />
    <egisz-cases-filter-date-closed />
    <egisz-cases-filter-doctor />
    <egisz-cases-filter-open />
    <egisz-cases-filter-register />
    <egisz-table-filters
      @refresh="vxRefreshFilters"
      @reset="resetFilters"
    />

    <egisz-has-updates
      :watch-props="hasUpdatesProps"
      @refresh="vxRefreshFilters"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import EgiszCasesFilterRegister
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_filter_register'
import EgiszCasesFilterOpen
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_filter_open'
import EgiszCasesFilterClinic
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_filter_clinic'
import EgiszCasesFilterDoctor
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_filter_doctor'
import EgiszCasesFilterDateOpen
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_filter_date_open'
import EgiszCasesFilterDateClosed
  from '@/vue_components/egisz/egisz_module/components/EgiszCases/filters/egisz_cases_filter_date_closed'
import EgiszTableFilters from '@/vue_components/egisz/egisz_module/components/egisz_table_fitlers'
import { getDefaultMedCasesFilters } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import EgiszHasUpdates from '@/vue_components/egisz/egisz_module/components/filters/EgiszHasUpdates'
import { ENTITIES, TRIGGERS_MAP } from '@/vue_components/store/modules/egisz/ws/egisz_ws'

export default {
  name: 'EgiszCasesFilters',
  components: {
    EgiszHasUpdates,
    EgiszTableFilters,
    EgiszCasesFilterDateClosed,
    EgiszCasesFilterDateOpen,
    EgiszCasesFilterDoctor,
    EgiszCasesFilterClinic,
    EgiszCasesFilterOpen,
    EgiszCasesFilterRegister,
  },

  data () {
    return {
      TRIGGERS_MAP,
      ENTITIES,
    }
  },

  computed: {
    hasUpdatesProps () {
      return [
        TRIGGERS_MAP[ENTITIES.MED_CASE],
      ]
    },
  },

  created () {
    this.resetFilters()
  },

  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxRefreshFilters: 'vxRefreshFilters',
      vxResetFilters: 'vxResetFilters',
    }),

    resetFilters () {
      this.vxResetFilters(getDefaultMedCasesFilters())
    },
  },
}
</script>
