<template>
  <div class="sidebar__filter filter">
    <label class="filter__label">
      {{ t('status') }}
    </label>
    <simple-select
      class="filter__input"
      :value="filters[status]"
      :items="statuses"
      :fixed-height="false"
      value-attribute="value"
      clearable
      @change="vxSetFilterValue({ filter: status, value: $event })"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { CLIENTS_FILTERS_FIELDS } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import { EGISZ_CLIENTS_STATUSES_LIST } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export default {
  name: 'EgiszClientsFilterStatus',
  components: { SimpleSelect },
  data () {
    return {
      statuses: EGISZ_CLIENTS_STATUSES_LIST,
      status: CLIENTS_FILTERS_FIELDS.STATUS,
    }
  },

  computed: {
    ...mapGetters('egisz/clients/filtersBase', {
      filters: 'vxGetFilters',
    }),
  },

  methods: {
    ...mapMutations('egisz/clients/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
    }),
  },
}
</script>
