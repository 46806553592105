<template>
  <m-select
    v-bind="$props"
    :value="adaptedValue"
    :items="itemsDisplayed"
    :filterable="filterable"
    :min-filter-value-length="minFilterValueLength"
    :required="required"
    :disabled="disabled"
    :validator-name="validatorName"
    v-on="$listeners"
    @change="onChangeWithLazy($event)"
    @changeItem="$emit('changeItem', $event)"
    @filterValueChange="onFilterValueChange"
    @loadMore="onLoadMoreDebounce()"
    @hideOptions="onHideOptions"
    @openOptions="onOpenOptions"
  >
    <template #prefix>
      <slot name="prefix">
        <user-avatar
          v-if="useUserMode"
          :user-id="__extractUserId(value)"
          :params="{ version: 'thumb16', tag: value && value.avatarTag }"
          :style="{ marginTop: '1px', marginLeft: '1px' }"
        />

        <template v-if="useIcon">
          <m-icon
            v-if="value && typeof value.icon === 'string'"
            :icon="value.icon"
            :color="value.color"
            :use-brand="value.useBrand"
            class="option-icon"
          />
          <m-icon
            v-else-if="value && typeof value.icon === 'object'"
            v-tooltip="value.icon.tooltip"
            :icon="value.icon.icon"
            :color="value.icon.color || value.icon.css"
            :use-brand="value.useBrand"
            class="option-icon"
          />
        </template>
      </slot>
    </template>

    <template #options>
      <slot
        name="options"
        :value-key="valueKey"
        :option-label="optionLabel"
      >
        <el-option
          v-for="(option, index) in itemsDisplayed"
          :key="`userModeUser:${option[valueKey]}:${index}`"
          :value="option"
          :label="option[optionLabel]"
          class="m-option flex flex-column"
        >
          <div class="m-option__container">
            <user-avatar
              v-if="useUserMode"
              class="mr-5"
              :user-id="__extractUserId(option)"
              :params="{ version: 'thumb16', tag: option.avatarTag }"
            />

            <template v-if="useIcon">
              <m-icon
                v-if="typeof option.icon === 'string'"
                :icon="option.icon"
                :color="option.color"
                :use-brand="option.useBrand"
                class="option-icon"
              />
              <m-icon
                v-else-if="typeof option.icon === 'object'"
                :icon="option.icon.icon"
                :color="option.icon.color || option.icon.css"
                :use-brand="option.useBrand"
                class="option-icon"
              />
            </template>

            <span class="m-option__label">{{ option[optionLabel] }}</span>
          </div>

          <span
            v-if="optionNotice && option[optionNotice]"
            class="m-option__notice prompt-notice"
          >{{ option[optionNotice] }}</span>
        </el-option>
      </slot>
    </template>
  </m-select>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { MSelectProps } from '@/vue_present/_base/inputs/MSelect/MSelectProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { cloneDeep, debounce } from 'lodash'
import { getSearchValue } from '@/vue_present/_base/MSelectLazy/helpers'
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DEFAULT_SEARCH_VALUE,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
} from '@/vue_components/store/modules/filters_base'
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import UserAvatar from '@/vue_components/user_avatar.vue'
import { isArray, isStringOrNumber } from '@/helpers/typeofHelpers'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { M_SELECT_LAZY_DEBOUNCE } from '@/vue_present/_base/MSelectLazy/consts'
import { orUndefined } from '@/_medods_standart_library/msl'

/**
 * @typedef {{
 *   removeItem: (item: Object) => void
 *   addItem: (item: Object) => void
 *   filterItems: () => void
 *   rewriteItem: (item: Object) => void
 * }} MSelectLazyApi
 */

export default {
  name: 'MSelectLazy',
  components: { MIcon, UserAvatar, MSelect },
  mixins: [CommonInputMethods, CommonInputProps],
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    ...MSelectProps,

    fetchMethod: { type: Function, required: true },
    fetchLimit: PropsTypes.Number(DEFAULT_LIMIT),
    searchMethod: PropsTypes.Function(), // обязательно при filterable, если он отличается от fetchMethod!

    /**
     * @type {(searchQueryInLowerCase: string, filteredItem: Object) => boolean}
     */
    additionalDisplayedFilter: { type: Function, default: null },

    /**
     * При наличии этой функции отображаемые опции всегда будут отфильтрованы по ней
     * @type {(searchQueryInLowerCase: string, filteredItem: Object) => boolean}
     */
    permanentDisplayedFilter: { type: Function, default: null },

    useUserMode: Boolean,
    useFirstTimeFetch: Boolean,
    /**
     * useFirstTimeFetchOnOpen - модификатор для useFirstTimeFetch
     * Отправляет запрос при открытии списка, а не при создании компонента.
     * флаг firstTimeFetched отслеживает первое открытие списка,
     * для избежания дублирующих запросов
     */
    useFirstTimeFetchOnOpen: Boolean,

    fetchOnOpen: Boolean,

    /** Вызов метода fetchById, если value не найден в itemsStored */
    useFirstTimeFetchOneIf404: { type: Function, default: null },

    // нужна для подгрузки новых результатов в начало списка
    useReverseOrder: Boolean,

    //очищает старые результаты в itemsDisplayed списке
    useNewResultsOnly: Boolean,

    required: Boolean,
    validatorName: { type: String, default: null },

    disabled: { type: [Boolean, Object], default: false },

    useIcon: Boolean,

    useSortingOnHideOptions: Boolean,
  },

  emits: [
    'change',
    'changeItem',
    'update:value',
    'syncItems',
    'syncItemsStored',
    'changeByLazy',
    'hideOptions',
    'openOptions',
    'firstTimeFetched',
    'registerApi',
  ],

  data () {
    return {
      itemsStored: [],
      itemsDisplayed: [],

      searchQuery: DEFAULT_SEARCH_VALUE,
      offset: DEFAULT_OFFSET,
      totalItems: DEFAULT_TOTAL_ITEMS,
      totalPages: DEFAULT_TOTAL_PAGES,

      previousQueryParams: {
        offset: DEFAULT_OFFSET,
        totalItems: DEFAULT_TOTAL_ITEMS,
        totalPages: DEFAULT_TOTAL_PAGES,
      },

      firstTimeFetched: false,
    }
  },

  computed: {
    adaptedValue () {
      if (!this.value || typeof this.value === 'object') { return this.value }
      if (this.useCustomResult === DEFAULT_RESULT_TYPES.OBJECT) { return this.value }

      const item = this.items.find((item) => item[this.valueKey] === this.value)
      if (item) { return item }

      return { [this.valueKey]: this.value }
    },

    lowCaseSearchQuery () {
      return this.searchQuery.toLowerCase()
    },
  },

  watch: {
    items (to) {
      this.__mergeItems(to)
    },

    value (to) {
      if (!to) { return }

      this.__mergeItems(this.__getNewItemsByTypeof(to))
    },
  },

  async created () {
    this.$emit('registerApi', this.__getApi.bind(this)())

    this.onLoadMoreDebounce = debounce(() => this.onLoadMore(), M_SELECT_LAZY_DEBOUNCE)

    this.searchQuery = DEFAULT_SEARCH_VALUE
    this.offset = DEFAULT_OFFSET
    this.totalItems = DEFAULT_TOTAL_ITEMS

    this.itemsStored = cloneDeep(this.items || [])

    if (this.useFirstTimeFetch && !this.useFirstTimeFetchOnOpen) {
      await this.__firstTimeFetch()
    }

    const value = this.value
      ? isArray(this.value)
        ? this.value
        : [this.value]
      : []

    this.__mergeItems(value)
  },

  methods: {
    /**
     * @return {MSelectLazyApi}
     * @private
     */
    __getApi () {
      return {
        removeItem: this.removeItem,
        addItem: this.addItem,
        rewriteItem: this.rewriteItem,
        filterItems: this.__filterItems,
      }
    },

    onLoadMoreDebounce () {}, // чекай created

    async __firstTimeFetch () {
      await this.onLoadMore(true)

      this.firstTimeFetched = true

      const value = this.value && typeof this.value === 'object'
        ? this.value[this.valueKey]
        : this.value

      if (
        value &&
        this.useFirstTimeFetchOneIf404 &&
          !this.itemsStored.find((item) => item[this.valueKey] === value)
      ) { await this.__appendNotFoundItem(value) }

      this.$emit('firstTimeFetched', this.itemsDisplayed)
    },

    __getNewItemsByTypeof (to) {
      // value - массив
      if (isArray(to)) { return to }

      // value - строка/число
      if (isStringOrNumber(to)) {
        const findValue =
            (this.items || []).find((item) => item[this.valueKey] === to[this.valueKey])

        return findValue
          ? [findValue]
          : []
      }

      // value - объект (не null)
      return [to]
    },

    __extractUserId (user) {
      if (this.useCustomResult === 'simple') {
        return user || undefined
      }

      if (!user || typeof user !== 'object') { return null }

      const camelUser = snakeToCamel(user)

      return Utils.ternary(
        camelUser.userId !== undefined,
        camelUser.userId,
        camelUser.id
      )
    },

    __mergeItems (newItems) {
      const uniqueItems = this.__findUniqueItems(newItems)
      this.itemsStored = this.useNewResultsOnly
        ? uniqueItems
        : this.useReverseOrder
          ? [...uniqueItems, ...this.itemsStored]
          : [...this.itemsStored, ...uniqueItems]

      this.itemsDisplayed = cloneDeep(this.itemsStored)
      this.$emit('syncItemsStored', this.itemsStored)
      this.$emit('syncItems', this.itemsDisplayed)
    },

    /**
     * Поиск уникальных элементов
     * @param {{ [this.valueKey]: Number | String, [this.optionLabel]: String | any }}newItems
     * @return {*[]}
     * @private
     */
    __findUniqueItems (newItems = []) {
      return newItems
        .filter((item) => item[this.valueKey] && this.itemsStored.findIndex((option) => option[this.valueKey] === item[this.valueKey]) < 0)
    },

    /**
     * Загрузка элемента по this.value  методом this.useFirstTimeFetchOneIf404 (async)
     * @return {Promise<void>}
     * @private
     */
    async __appendNotFoundItem (value = this.value) {
      const item = await this.useFirstTimeFetchOneIf404(value)

      if (!item || item.errors) { return }

      this.__mergeItems([item])

      this.onChangeWithLazy(item)
    },

    /**
     * Точка входа для вызова this.fetchMethod с параметрами:
     * @param {{ searchQuery?: String, limit?: Number, offset?: Number }} params
     * @param {Boolean} withReset - для сброса старых значений в items
     * @return {Promise<*>}
     */
    fetch (params, withReset = false) {
      return this.fetchMethod(params)
        .then(
          /**  @param {{ data: Array, totalItems: Number, totalPages: Number }} response */
          (response) => {
            const data = response?.data ? response.data : response

            if (withReset) {
              this.itemsStored = []
              this.itemsDisplayed = []
            }

            this.__mergeItems(data)
            this.totalItems = response.totalItems || response.total_items || DEFAULT_TOTAL_ITEMS
            this.totalPages = response.totalPages || response.total_pages || DEFAULT_TOTAL_PAGES
          })
    },

    /**
     * Поиск
     * @param {{ searchQuery: string }} params
     * @returns {*|Promise<*>}
     */
    search (params) {
      if (!this.searchMethod) { return this.fetch(params) }

      return this.searchMethod(params)
        .then((response) => {
          const data = response?.data ? response.data : response
          this.__mergeItems(data)
        })
    },

    __cloneDisplayedItems () {
      this.itemsDisplayed = cloneDeep(this.itemsStored)
      this.__applyPermanentDisplayedFilter()
    },

    __applyPermanentDisplayedFilter () {
      if (!this.permanentDisplayedFilter) { return }

      this.itemsDisplayed = this.itemsDisplayed
        .filter((item) => this.permanentDisplayedFilter(this.lowCaseSearchQuery, item))
    },

    __filterItems () {
      this.itemsDisplayed = this.itemsStored
        .filter((item) =>
          item[this.optionLabel]?.toLowerCase()?.includes(this.lowCaseSearchQuery) ||
          (this.additionalDisplayedFilter && this.additionalDisplayedFilter(this.lowCaseSearchQuery, item))
        )

      this.__applyPermanentDisplayedFilter()
      this.$emit('syncItems', this.itemsDisplayed)
    },

    /**
     * Поиск по  [this.optionLabel]: String
     * @param {String} filterValue
     * @return {Promise<void>}
     */
    async onFilterValueChange (filterValue = DEFAULT_SEARCH_VALUE) {
      const oldSearchQuery = this.searchQuery
      this.searchQuery = getSearchValue(filterValue, this.minFilterValueLength)

      if (this.searchQuery.length < this.minFilterValueLength) {
        this.__cloneDisplayedItems()
        if (oldSearchQuery) {
          this.restorePreviousQueryParams()
        }

        return
      }

      if (this.filterable) {
        if (!oldSearchQuery) {
          this.savePreviousQueryParams()
        }

        await this.search({ searchQuery: this.searchQuery })
      }

      this.__filterItems()
    },

    savePreviousQueryParams () {
      this.previousQueryParams = {
        offset: this.offset,
        totalItems: this.totalItems,
        totalPages: this.totalPages,
      }
    },

    restorePreviousQueryParams () {
      this.offset = this.previousQueryParams.offset
      this.totalItems = this.previousQueryParams.totalItems
      this.totalPages = this.previousQueryParams.totalPages
    },

    /**
     * Реализация ленивой загрузки
     */
    async onLoadMore (firstTime) {
      const currentOffset = this.offset

      const nextOffset = this.offset + this.fetchLimit
      if (!firstTime && nextOffset > this.totalItems) { return }

      if (!firstTime) {
        this.offset = nextOffset
      }

      const params = {
        limit: this.fetchLimit,
        offset: this.offset,
        searchQuery: orUndefined(this.searchQuery),
      }

      return this.fetch(params)
        .catch(() => { this.offset = currentOffset })
    },

    __sortItems () {
      this.itemsStored = this.itemsStored.sort((a, b) => a[this.optionLabel] > b[this.optionLabel] ? 1 : -1)
      this.__cloneDisplayedItems()
    },

    onHideOptions () {
      if (this.useSortingOnHideOptions) { this.__sortItems() }

      this.$emit('hideOptions')
    },

    __onChange (value) {
      if (this.useCustomResult === DEFAULT_RESULT_TYPES.SIMPLE) {
        this.onChange(value ? value[this.valueKey] : null)

        return
      }

      this.onChange(value)
    },

    onChangeWithLazy (value) {
      this.__onChange(value)

      this.$emit('changeByLazy', value)
    },

    async onOpenOptions () {
      this.$emit('openOptions')

      if (this.fetchOnOpen) {
        this.itemsDisplayed = []
        this.itemsStored = []
        this.offset = DEFAULT_OFFSET
        this.totalPages = DEFAULT_TOTAL_PAGES
        this.totalItems = DEFAULT_TOTAL_ITEMS
        await this.onLoadMore(true)

        return
      }

      if (this.firstTimeFetched) { return }
      if (!(this.useFirstTimeFetch && this.useFirstTimeFetchOnOpen)) { return }

      await this.__firstTimeFetch()
    },

    removeItem (item) {
      this.itemsStored = this.itemsStored
        .filter((option) => option[this.valueKey] !== item[this.valueKey])

      this.__cloneDisplayedItems()
    },

    addItem (item) {
      this.__mergeItems([item])
    },

    rewriteItem (item) {
      const itemIndex = this.itemsStored
        .findIndex((itemStored) => itemStored[this.valueKey] === item[this.valueKey])

      this.itemsStored = this.itemsStored.with(itemIndex, item)
      this.__cloneDisplayedItems()
    },
  },
}
</script>
