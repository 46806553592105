<template>
  <span class="trend-arrow">
    <span
      v-if="sign === -1"
      :class="downClass"
    />
    <span
      v-else-if="sign === 1"
      :class="upClass"
    />
  </span>
</template>

<script>
export default {
  name: 'TrendArrow',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    upClass: {
      type: String,
      required: true,
    },
    downClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    sign () {
      return Math.sign(Number(this.value))
    },
  },
}
</script>
