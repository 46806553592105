export class MTableHeader {
  value = null

  /** @type {'struct' | 'icon'}*/
  type = 'struct'

  /** @type {TTooltip} */
  tooltip = ''

  css = ''

  width = ''

  sorting = null

  /**
   * @param {string | TTableSchemaHeader} [header]
   * @return {MTableHeader}
   */
  constructor (header) {
    if (!header) { return }

    if (typeof header === 'object') {
      this.value = header.value
      this.type = header.type
      this.tooltip = header.tooltip
      this.css = header.css
      this.width = header.width

      return
    }

    this.value = header
  }

  /**
   * @param {TTooltip | Boolean} tooltip - если true - то value
   * @return {MTableHeader}
   */
  addTooltip (tooltip) {
    this.tooltip = tooltip === true
      ? this.value
      : tooltip

    return this
  }

  /**
   * @param {string} icon
   * @return {MTableHeader}
   */
  addTypeIcon (icon) {
    this.type = 'icon'
    this.value = icon

    return this
  }

  /**
   * @param {TVueCss} css
   * @return {MTableHeader}
   */
  addClasses (css = '') {
    this.css = css

    return this
  }

  addFixedWidth (width = '') {
    this.width = width

    return this
  }

  addSorting () {
    this.sorting = true

    return this
  }

  removeSorting () {
    this.sorting = false

    return this
  }
}
