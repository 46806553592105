<template>
  <el-dropdown
    class="m-dropdown"
    :class="{
      'm-dropdown_is-button': splitButton
    }"
    trigger="click"
    :split-button="splitButton"
    :placement="placement"
    :disabled="disabled"
    :type="type"
    @click="$emit('click', $event)"
    @command="$emit('itemClick', $event)"
  >
    <template #default>
      <slot />
    </template>

    <template #dropdown>
      <el-dropdown-menu class="m-dropdown__menu">
        <el-dropdown-item
          v-if="useSearch && searchItemText"
          disabled
        >
          {{ searchItemText }}
        </el-dropdown-item>

        <el-dropdown-item
          v-for="item in items"
          :key="`dropdownItem:${item[valueKey]}`"
          class="m-dropdown__item"
          :class="{ 'm-dropdown__item_active': activeId === item[valueKey] }"
          :command="item"
        >
          <m-icon
            v-if="item.icon"
            :icon="item.icon"
            class="mr-5 m-dropdown__icon"
            :class="item.iconCss"
            color=""
          />

          <span class="m-dropdown__item-text">{{ item[optionLabel] }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { GLOBAL_SEARCH_MIN_LENGTH } from '@/_global_scripts/GLOBAL_CONSTS'

export default {
  name: 'MDropDown',
  components: { MIcon },

  props: {
    type: { type: String, default: undefined },
    activeId: { type: [String, Number], default: null },
    items: { type: Array, default: () => [] },
    valueKey: { type: String, default: 'id' },
    optionLabel: { type: String, default: 'title' },
    placement: { type: String, default: 'bottom' },
    disabled: Boolean,
    splitButton: Boolean,

    useSearch: {
      type: Object,
      default: () => ({
        searchQuery: '',
        loading: false,
      }),
    },
  },

  emits: [
    'click',
    'itemClick',
  ],

  computed: {
    searchItemText () {
      if (!this.useSearch) { return '' }
      if (this.useSearch.searchQuery.length < GLOBAL_SEARCH_MIN_LENGTH) {
        return t('min3symbols')
      }

      if (this.useSearch.loading) {
        return t('search')
      }

      if (this.useSearch.searchQuery && !this.useSearch.loading && !this.items.length) {
        return t('nothing_has_found')
      }

      return ''
    },
  },
}
</script>
