import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { PSEUDO_ROOT_NODE_ID } from '@/vue_present/Reuse/LazyTree/const'
import { cloneDeep } from 'lodash'

/** @implements {ILazyTree} */
export default class LazyTree {
  /** @type {ILazyTreeApiPresenter} */
  _presenter = null

  /** @type {Record<number, ILazyTreeNode>} */
  treeMap = {
    0: new LazyTreeNode(this, { id: 0, title: '...' }),
  }

  /** @type {Set<number>} */
  _selectedNodesIds = new Set()

  /** @param {DocumentsCategoriesPresenter} presenter
   * @param {{
   *   extraAttributes?: string[]
   * }} config
   */
  constructor (presenter, config = {}) {
    if (!presenter) {
      throw new Error('Не указан презентер')
    }

    this._presenter = presenter

    if (config.extraAttributes) {
      this.extraAttributes = cloneDeep(config.extraAttributes)
    }
  }

  get selectedNodesIds () {
    return Array.from(this._selectedNodesIds)
  }

  /**
   * @param {number} id
   * @param {ECategoryType} categoryType
   * @param {{ data?: Record<string, unknown> }}config
   * @return {Promise<ILazyTreeNode[]>}
   */
  fetchNode (id, categoryType, config = {}) {
    return this._presenter.fetchNode(id, categoryType, config)
      .then((data) => {
        const result = []
        const catalogs = data.catalogs || data.categories || []

        catalogs.forEach((catalog) => {
          const node = new LazyTreeNode(this, catalog)
          result.push(node)
          this.treeMap[catalog.id] = node
        })

        data.items.forEach((item) => {
          const node = new LazyTreeNode(this, item, true, this.extraAttributes)
          result.push(node)
          this.treeMap[node.id] = node
        })

        if (this.treeMap[id]) {
          this.treeMap[id].children = result
        }

        return Promise.resolve(result)
      })
  }

  selectNode (id, selected = false) {
    selected
      ? this._selectedNodesIds.add(id)
      : this._selectedNodesIds.delete(id)

    this._selectedNodesIds = new Set(this._selectedNodesIds)
  }

  /**
   * @public
   * @param {LazyTreeNode} root
   */
  setPseudoRoot (root) {
    this.treeMap[PSEUDO_ROOT_NODE_ID] = root
    this.treeMap[0].children.push(root)
  }
}
