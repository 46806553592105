import { IFixedDocumentTypesList } from '@/_declarations/documents/IFixedDocumentTypesList'

export const fixedDocumentTypesAdapter = {
  toClient (data: IFixedDocumentTypesList[]) {
    return {
      items: data.map((item) => ({
        id: item.documentType,
        title: item.dataTitle,
        new: !!item.new,
      })),
    }
  },
}
