import { pick } from 'lodash'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { CHECKUP_STATUS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import { CheckupDocumentsList } from '@/vue_apps/CheckupsModule/store/CheckupDocumentsList'
import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  PersonalCheckupCheckupEntriesApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupCheckupEntries/PersonalCheckupCheckupEntriesApi'
import {
  PersonalCheckupVaccinationApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupVaccination/PersonalCheckupVaccinationApi'
import {
  PersonalCheckupInfectiousDiseasesApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupInfectiousDiseases/PersonalCheckupInfectiousDiseasesApi'
import {
  PersonalCheckupDoctorResultsApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDoctorResults/PersonalCheckupDoctorResultsApi'
import {
  PersonalCheckupLaboratoryResearchResultsApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupLaboratoryResearchResults/PersonalCheckupLaboratoryResearchResultsApi'
import {
  PersonalCheckupInstrumentalResearchResultsApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupInstrumentalResearchResults/PersonalCheckupInstrumentalResearchResultsApi'
import {
  PersonalCheckupDiagnosesPersonalApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDiagnosesPersonal/PersonalCheckupDiagnosesPersonalApi'
import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'

export class PersonalCheckupModel extends ItemBase {
  _permit = [
    'attachments',
    'checkupEntries',
    'client',
    'clinic',
    'comment',
    'company',
    'companyCheckupId',
    'conclusion',
    'contraindicationsFactors',
    'createdAt',
    'creator',
    'dateStart',
    'dateEnd',
    'department',
    'documents',
    'factors',
    'positionProfile',
    'healthGroup',
    'id',
    'kind',
    'nextDate',
    'orderId',
    'medicalAssessment',
    'position',
    'nsiProfession',
    'recommendations',
    'responsible',
    'status',
  ]

  attachments = []

  checkupEntries = new PersonalCheckupCheckupEntriesApi()

  vaccinations: PersonalCheckupVaccinationApi = null

  infectiousDiseases: PersonalCheckupInfectiousDiseasesApi = null

  doctorResults: PersonalCheckupDoctorResultsApi = null

  laboratoryResearchResults: PersonalCheckupLaboratoryResearchResultsApi = null

  instrumentalResearchResults: PersonalCheckupInstrumentalResearchResultsApi = null

  diagnosesPersonal: PersonalCheckupDiagnosesPersonalApi = null

  get entries () {
    return this.checkupEntries
      .checkupEntries
      .map(({ entry }) => entry)
      .filter(Boolean)
  }

  client = {
    id: null,
    fullName: '',
    sex: null,
    birthdate: null,
    phone: '79000000000',
    age: '',
    position: '',
    nsiProfession: null,
  }

  clinic = snakeToCamel(pick(window.gon.application.current_clinic, ['id', 'title']))

  comment = ''

  company = null

  companyCheckupId = null

  conclusion = null

  contraindicationsFactors = []

  createdAt = ''

  creator = null

  dateStart = window.Utils.getBaseFormattedDate(window.moment())

  dateEnd = window.Utils.getBaseFormattedDate(window.moment().add('1', 'day'))

  department = ''

  documents = new CheckupDocumentsList()

  factors = []

  positionProfile = null

  healthGroup = null

  id = null

  kind = null

  nextDate = window.Utils.getBaseFormattedDate(window.moment().add('1', 'year'))

  orderId = null

  medicalAssessment = null

  position = ''

  nsiProfession = null

  recommendations = []

  responsible = null

  status = CHECKUP_STATUS.IN_PROGRESS

  hasPositionProfileFactor = false

  constructor (props: IPersonalCheckup) {
    super()

    this.vaccinations = new PersonalCheckupVaccinationApi(props)

    this.infectiousDiseases = new PersonalCheckupInfectiousDiseasesApi(props)

    this.doctorResults = new PersonalCheckupDoctorResultsApi(props)

    this.laboratoryResearchResults = new PersonalCheckupLaboratoryResearchResultsApi(props)

    this.instrumentalResearchResults = new PersonalCheckupInstrumentalResearchResultsApi(props)

    this.diagnosesPersonal = new PersonalCheckupDiagnosesPersonalApi(props)

    this._fillProps(props)
    this.fillDates(props)

    this.client.position = props.position || props?.client?.position || ''
  }

  getAttributes (attributes = this._permit) {
    return {
      ...super.getAttributes(attributes),
      ...pick(this.checkupEntries.getAttributes(attributes), 'checkupEntries'),
      vaccinations: this.vaccinations.nestedItems.getValues(),
      infectiousDiseases: this.infectiousDiseases.nestedItems.getValues(),
      doctorResults: this.doctorResults.nestedItems.getValues(),
      laboratoryResearchResults: this.laboratoryResearchResults.nestedItems.getValues(),
      instrumentalResearchResults: this.instrumentalResearchResults.nestedItems.getValues(),
      diagnosesPersonal: this.diagnosesPersonal.nestedItems.getValues(),
    }
  }

  protected fillDates ({ dateStart, dateEnd }) {
    this.dateStart = dateStart || window.Utils.getBaseFormattedDate(window.moment())
    this.dateEnd = dateEnd || window.Utils.getBaseFormattedDate(window.moment(dateStart).add('1', 'day'))
  }
}
