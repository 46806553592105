<template>
  <div
    ref="el"
    :title="T.delete"
    class="delete-button"
    v-on="$listeners"
  >
    <div class="fad fa-times" />
  </div>
</template>

<script>

export default {
  name: 'DeleteButton',
  mounted () {
    Utils.updateTooltipsInContainer(this.$refs.el.parentElement)
  },
}
</script>
