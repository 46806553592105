<template>
  <m-input
    disabled
    :label="label"
    :value="value"
  >
    <template #append>
      <m-link
        v-if="id"
        :href="url"
      >
        <m-icon
          v-tooltip="t('open_new_tab')"
          icon="link"
          color="primary"
        />
      </m-link>
    </template>
  </m-input>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'

export default defineComponent({
  name: 'ReusableClientLink',
  components: { MInput, MLink, MIcon },
  props: {
    clientType: { type: String as PropType<'client' | 'company'>, default: 'client' },
    value: { type: String, default: null },
    id: { type: Number, default: null },
  },

  computed: {
    label () {
      return this.clientType === 'client'
        ? t('client')
        : t('company')
    },

    url (): string {
      if (!this.id) { return '' }

      if (this.clientType === 'client') {
        return this.$routes.client_path(this.id)
      }

      return this.$routes.company_path(this.id)
    },
  },
})
</script>
