<template>
  <panel-heading
    :title="`${t('info_company')} - ${t('payment_orders')}`"
    icon="fad fa-money-bill"
    class="company-router-container"
  >
    <div class="company-orders company-payments">
      <si show-tooltips>
        <si-control
          :disabled="fetched.payments.loading"
          @reset="resetFilters"
        >
          <div class="si-control-item">
            <button
              v-tooltip="t('cash_in')"
              class="btn btn-with-icon btn-success btn-sm hidden-print clients-options-btn"
              @click="modal.paymentCashIn.visibility = true"
            >
              <span class="btn-with-icon_icon fad fa-fw fa-money-bill" />
              <span class="fas fa-fw fa-plus" />
            </button>

            <button
              v-tooltip="t('cash_out')"
              class="btn btn-with-icon btn-default btn-sm hidden-print clients-options-btn"
              :disabled="accessBalance <= 0"
              @click="modal.paymentCashOut.visibility = true"
            >
              <span class="btn-with-icon_icon fad fa-fw fa-money-bill" />
              <span class="fas fa-fw fa-minus" />
            </button>
          </div>

          <date-range-picker-btn
            v-model="filterState.selectedDateRange"
            class="si-control-item"
            :disabled="fetched.payments.loading"
            :fallback-value="[]"
            :unpressed="true"
          />
          <date-range-picker
            v-model="filterState.selectedDateRange"
            class="si-control-item"
            :disabled="fetched.payments.loading"
            :fallback-value="[]"
            :format="GET_LOCALIZATION_DATEPICKER_FORMAT"
          />

          <base-select
            v-model="filterState.selectedPaymentKind"
            class="si-control-item"
            clearable
            reset-value="all"
            :disabled="fetched.payments.loading"
          >
            <el-option
              value="all"
              :label="t('all_payment_kinds')"
            />
            <el-option
              v-for="(paymentKindsTranslate, paymentKind) in t('_payment_kind')"
              :key="paymentKind"
              :value="paymentKind"
              :label="paymentKindsTranslate"
            />
          </base-select>

          <search-select
            v-model="filterState.selectedRegistryId"
            clearable
            class="si-control-item"
            :search-query.sync="fetched.registries.searchQuery"
            :reset-value="DEFAULT_FILTER_STATE.selectedRegistryId"
            :allow-search="allowSearch"
            :not-results="notResults"
            :loading="fetched.registries.loading"
            :disabled="fetched.payments.loading"
            :placeholder="t('search_orders_registries')"
            @clearSelect="clearSelectRegistry"
          >
            <el-option
              :value="-1"
              :label="t('all_orders_registries')"
            />
            <el-option
              v-for="registry in fetched.registries.sliced"
              :key="registry.id"
              :label="registry.title"
              :value="registry.id"
            />
          </search-select>

          <template #refresh>
            <span />
          </template>
          <template #toolsControl />
        </si-control>

        <si-table
          :spinner="fetched.payments.loading"
          :error="fetched.payments.error"
          :not-found="!fetched.payments.sliced.length"
        >
          <template #header>
            <si-field
              :title="!allowRefund ? t('select_registry') : ''"
              :disabled="!allowRefund"
              :checked="
                fetched.payments.list.length > 0 &&
                  objArrEqMap(
                    fetched.payments.list,
                    fetched.payments.paymentsMap
                  )
              "
              @checkbox-change="
                $event.target.checked
                  ? paymentsToMap(
                    fetched.payments.paymentsMap,
                    fetched.payments.list
                  )
                  : fetched.payments.paymentsMap = Object.create(null)
              "
            >
              <div
                v-tooltip="t('common.number')"
                class="number"
              >
                {{ t('common.number') }}
              </div>

              <div class="tbl-icon" />

              <div class="date">
                {{ t('common.date') }}
              </div>
              <div class="registry">
                {{ t('registry') }}
              </div>
              <div
                v-tooltip="t('by_cash')"
              >
                {{ t('by_cash') }}
              </div>
              <div
                v-tooltip="t('by_cashless')"
              >
                {{ t('by_cashless') }}
              </div>
              <div>
                {{ t('by_card') }}
              </div>

              <div
                v-tooltip="t('by_balance_short')"
              >
                {{ t('by_balance_short') }}
              </div>
              <div
                v-tooltip="t('by_credit')"
              >
                {{ t('by_credit') }}
              </div>

              <div>
                {{ t('made_payment') }}
                <span
                  v-tooltip="t('income_footnote')"
                  class="tooltip-bottom asterisk"
                >*</span>
              </div>

              <div>
                {{ t('payment') }}
                <span
                  v-tooltip="t('total_paid_footnote')"
                  class="asterisk"
                >*</span>
              </div>
            </si-field>
          </template>

          <template #body>
            <si-field
              v-for="payment in fetched.payments.sliced"
              :key="payment.id"
              :disabled="!allowRefund"
              :checked="payment.id in fetched.payments.paymentsMap"
              @checkbox-change="
                $event.target.checked
                  ? $set(fetched.payments.paymentsMap, payment.id, payment)
                  : $delete(fetched.payments.paymentsMap, payment.id)
              "
            >
              <div class="number">
                <a
                  :href="$routes.payment_path(payment.id)"
                  target="_blank"
                >
                  {{ payment.id }}
                </a>
              </div>
              <div class="tbl-icon">
                <span
                  v-tooltip="paymentIconKind(payment.kind).title"
                  :class="paymentIconKind(payment.kind).class"
                />
              </div>

              <div
                v-tooltip="$filters.date(payment.date)"
                class="date"
              >
                {{ $filters.date(payment.date) }}
              </div>
              <div
                v-tooltip="paymentRegistryById(payment)"
                class="registry"
              >
                {{ paymentRegistryById(payment) }}
              </div>
              <div>
                {{ payment.by_cash | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ payment.by_cashless | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ payment.by_card | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ payment.by_balance | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ payment.by_credit | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>

              <div>
                {{ payment.total_income | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ payment.total_paid | signPayment(payment.kind) | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
            </si-field>
          </template>
        </si-table>

        <si-footer class="footer-with-total">
          <div class="si-table table footer-total">
            <div class="si-field">
              <div class="options">
                Итого
                <span
                  v-tooltip="t('total_with_refund')"
                  class="asterisk"
                >*</span>
              </div>
              <div class="number" />
              <div class="tbl-icon" />
              <div class="date" />
              <div />
              <div>
                {{ totalPaymentsCash | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ totalPaymentsCashless | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ totalPaymentsCard | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ totalPaymentsBalance | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ totalPaymentsCredit | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>

              <div>
                {{ totalPaymentsIncome | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
              <div>
                {{ totalPaymentsPaid | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
            </div>
          </div>
          <div class="footer-pagination">
            <pagination
              layout="prev, pager, next"
              :page-size="PAGINATION_PAGE_SIZE"
              :page-count="paginationPaymentsPageCount"
              :current-page.sync="fetched.payments.pagination.currentPage"
            />
            <button
              v-show="allowRefund && !fetched.payments.loading"
              class="btn btn-warning btn-sm hidden-print btn-with-icon payments-btn-refund"
              @click="modal.massRefund.visibility = true"
            >
              <span class="btn-with-icon_icon fad fa-reply" />
              <span class="btn-with-icon_text">
                {{ t('make_refund') }}
              </span>
            </button>
          </div>
        </si-footer>
      </si>
    </div>
    <company-payments-cash-in-modal
      :visibility="modal.paymentCashIn.visibility"
      :loading="modal.paymentCashIn.loading"
      :disabled="modal.paymentCashIn.disabled"
      :customer="customer"
      :by-cash.sync="modal.paymentCashIn.form.byCash"
      :by-card.sync="modal.paymentCashIn.form.byCard"
      :by-cashless.sync="modal.paymentCashIn.form.byCashless"
      :comment.sync="modal.paymentCashIn.form.comment"
      :total-money="totalMoneyCashIn"
      :validation-messages="modal.paymentCashIn.validationMessages"
      @close-modal-in="closeModalCashIn"
      @submit-form="createCashInPayment"
    />
    <company-payments-cash-out-modal
      :visibility="modal.paymentCashOut.visibility"
      :loading="modal.paymentCashOut.loading"
      :disabled="modal.paymentCashOut.disabled"
      :customer="customer"
      :payment-spec.sync="modal.paymentCashOut.form.paymentSpec"
      :summary-input-user.sync="modal.paymentCashOut.form.summaryInputUser"
      :access-balance="accessBalance"
      :comment.sync="modal.paymentCashOut.form.comment"
      :validation-messages="modal.paymentCashOut.validationMessages"
      @close-modal-out="closeModalCashOut"
      @submit-form="createCashOutPayment"
    />
    <company-payments-mass-refund-modal
      :visibility.sync="modal.massRefund.visibility"
      :loading="modal.massRefund.loading"
      :disabled="modal.massRefund.disabled"
      :customer="customer"
      :sum-to-refund="sumToRefund"
      :comment.sync="modal.massRefund.form.comment"
      :validation-messages="modal.massRefund.validationMessages"
      @close-modal-refund="closeModalMassRefund"
      @submit-form="createRefundPayments"
    />

    <alert-modal
      :modal-visibility="modal.massRefund.alert.visibility"
      :header-message="modal.massRefund.alert.header"
      :message="alertMessage"
      @ok="alertClose"
      @close="alertClose"
    />
  </panel-heading>
</template>

<script>
import AlertModal from '@/vue_components/alert_modal.vue'
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import CompanyPaymentsCashInModal
  from '@/vue_components/companies/components/company_payments/company_payments_cash_in_modal.vue'
import CompanyPaymentsCashOutModal
  from '@/vue_components/companies/components/company_payments/company_payments_cash_out_modal.vue'
import CompanyPaymentsMassRefundModal
  from '@/vue_components/companies/components/company_payments/company_payments_mass_refund_modal.vue'
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import DateRangePickerBtn from '@/vue_components/common/select/date_range_picker_btn.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import SearchSelect from '@/vue_components/common/select/search_select/search_select.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'

import watchers from './company_payments_watchers.js'
import methods from './company_payments_methods.js'

import { mapGetters } from 'vuex'
import { currencyFormatter, dateFormatter } from '@/vue_components/mixins/formatters.js'
import {
  DEFAULT_FILTER_STATE,
  DEFAULT_VALIDATION_FORM_CASH_IN,
  DEFAULT_VALIDATION_FORM_CASH_OUT,
  KIND_ICONS,
} from './consts.js'
import { DATE_ISO_FORMAT, STORAGE_KEY } from '../consts.js'
import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { PAYMENT_ERRORS, PAYMENT_PARAMS } from '@/lib/payments/consts.js'
import { generateUID, getCurrentStorage } from '../../helpers/storage.js'

export default {
  name: 'CompanyPayments',

  components: {
    AlertModal,
    BaseSelect,
    CompanyPaymentsCashInModal,
    CompanyPaymentsCashOutModal,
    CompanyPaymentsMassRefundModal,
    DateRangePicker,
    DateRangePickerBtn,
    Pagination,
    PanelHeading,
    SearchSelect,
    Si,
    SiControl,
    SiField,
    SiFooter,
    SiTable,
  },

  filters: {
    signPayment (number, kind) {
      const num = parseFloat(number)
      if ((kind === 'cash_out' || kind === 'order_refund') && num !== 0) return -Math.abs(num)

      return num
    },
  },

  mixins: [dateFormatter, currencyFormatter],

  data () {
    const uid = generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    const localStore = getCurrentStorage(STORAGE_KEY, uid)
    const filterState = { ...DEFAULT_FILTER_STATE }
    const companyInfo = { title: '...' }

    if (localStore.paymentsFilter) {
      const { paymentsFilter } = localStore
      if (Array.isArray(paymentsFilter.selectedDateRange)) {
        filterState.selectedDateRange = paymentsFilter.selectedDateRange.map((date) => new Date(date))
      }

      if (localStore.paymentsFilter.selectedPaymentKind) {
        filterState.selectedPaymentKind = paymentsFilter.selectedPaymentKind
      }
    }
    if (localStore.companyInfo) {
      companyInfo.title = localStore.companyInfo.title
    }

    return {
      filterState,
      customer: companyInfo.title,
      fetched: {
        payments: {
          list: [],
          sliced: [],
          error: false,
          loading: false,
          paymentsMap: Object.create(null),
          pagination: {
            currentPage: 1,
          },
        },
        registries: {
          list: [],
          sliced: [],
          error: false,
          loading: false,
          searchQuery: '',
        },
      },

      modal: {
        paymentCashIn: {
          visibility: false,
          disabled: true,
          loading: false,
          form: { ...this.defaultFormCashIn() },
          validationMessages: { ...DEFAULT_VALIDATION_FORM_CASH_IN },
        },
        paymentCashOut: {
          visibility: false,
          disabled: true,
          loading: false,
          form: { ...this.defaultFormCashOut() },
          validationMessages: { ...DEFAULT_VALIDATION_FORM_CASH_OUT },
        },
        massRefund: {
          visibility: false,
          disabled: false,
          loading: false,
          form: { ...this.defaultFormMassRefund() },
          validationMessages: { ...this.defaultValidationFormMassRefund() },
          alert: { visibility: false, header: t('payments_refund_error') },
        },
      },

      PAGINATION_PAGE_SIZE,
      DEFAULT_FILTER_STATE,
    }
  },

  computed: {
    ...mapGetters([
      'GET_APP_CONF_CURRENT_CLINIC_ID',
      'GET_APP_CONF_CURRENT_USER_ID',
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
      'GET_LOCALIZATION_CURRENCY_PARAMS',
      'GET_COMPANY_FINANCE',
    ]),

    currentStorage () {
      return generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    },

    allowSearch () {
      return this.fetched.registries.searchQuery.length >= 3
    },

    notResults () {
      return this.allowSearch && this.fetched.registries.sliced.length < 1
    },

    allowRefund () {
      return this.filterState.selectedRegistryId > 0 && this.fetched.payments.list.length > 0
    },

    alertMessage () {
      const { paymentsErrorValid, paymentsErrorCodes } = this.modal.massRefund.validationMessages
      const messages = []

      paymentsErrorValid.forEach((payment) => {
        for (const [id, errors] of Object.entries(payment)) {
          messages.push(`${t('payment_with_number')} ${id} - ${errors.join(', \n')}`)
        }
      })

      if (paymentsErrorCodes.includes(PAYMENT_ERRORS.TYPES.NETWORK.INSUFFICIENT_FUNDS_BALANCE.CODE)) {
        messages.push(PAYMENT_ERRORS.MESSAGES.INSUFFICIENT_FUNDS_FOR_REFUND)
      }

      if (paymentsErrorCodes.includes(PAYMENT_ERRORS.TYPES.NETWORK.INVALID_PAYMENT.CODE)) {
        messages.push(PAYMENT_ERRORS.MESSAGES.INVALID_PAYMENT_FOR_REFUND)
      }

      return messages.join(' ')
    },

    paymentsFilters () {
      const {
        selectedDateRange,
        selectedPaymentKind,
        selectedRegistryId,
      } = this.filterState

      let dateParams = {
        start_date: undefined,
        end_date: undefined,
      }

      if (Array.isArray(selectedDateRange)) {
        const dateRange = selectedDateRange.map((date) => moment(date).format(DATE_ISO_FORMAT))
        dateParams = {
          start_date: !dateRange.length ? undefined : dateRange[0],
          end_date: !dateRange.length ? undefined : dateRange[1],
        }
      }

      return {
        ...dateParams,
        kind: selectedPaymentKind === 'all' ? undefined : selectedPaymentKind,
        orders_registry_id: selectedRegistryId < 0 ? undefined : selectedRegistryId,
      }
    },

    reqPaymentsParams () {
      return {
        ...this.paymentsFilters,
      }
    },

    reqRegistriesParams () {
      if (this.filterState.selectedRegistryId < 0 && this.fetched.registries.searchQuery === '') {
        return {}
      }

      return {
        search_query: this.fetched.registries.searchQuery,
      }
    },

    paginationPaymentsPageCount () {
      return Math.ceil(this.fetched.payments.list.length / PAGINATION_PAGE_SIZE)
    },

    selectedPaymentsIds () {
      return Object.keys(this.fetched.payments.paymentsMap)
        .map((id) => parseInt(id))
    },

    selectedPaymentsArray () {
      return this.selectedPaymentsIds
        .map((paymentId) => this.fetched.payments.paymentsMap[paymentId])
    },

    selectedPaymentsToRefund () {
      const arr = this.selectedPaymentsArray
        .filter((payment) => !(
          payment.kind === PAYMENT_PARAMS.KIND_PAYMENT.CASH_OUT ||
          payment.kind === PAYMENT_PARAMS.KIND_PAYMENT.ORDER_REFUND ||
          payment.kind === PAYMENT_PARAMS.KIND_PAYMENT.CASH_IN_REFUND ||
          payment.refund))

      return arr
    },

    kindIcons () {
      const translates = t('_payment_kind')

      return {
        order_initial: { title: translates.order_initial, class: KIND_ICONS.ORDER_INITIAL },
        order_refund: { title: translates.order_refund, class: KIND_ICONS.ORDER_REFUND },
        order_subsequent: { title: translates.order_subsequent, class: KIND_ICONS.ORDER_SUBSEQUENT },
        cash_in: { title: translates.cash_in, class: KIND_ICONS.CASH_IN },
        cash_in_refund: { title: translates.cash_in_refund, class: KIND_ICONS.CASH_IN_REFUND },
        cash_out: { title: translates.cash_out, class: KIND_ICONS.CASH_OUT },
        order_full: { title: translates.order_full, class: KIND_ICONS.ORDER_FULL },
        order_in_credit: { title: translates.order_in_credit, class: KIND_ICONS.ORDER_IN_CREDIT },
      }
    },

    sumToRefund () {
      return Object.values(this.selectedPaymentsToRefund)
        .reduce((acc, payment) => {
          let paid = parseFloat(payment.total_paid)

          if (Math.sign(paid) === -1) paid = 0

          return acc + paid
        }, 0)
    },

    totalMoneyCashIn () {
      const { byCash, byCard, byCashless } = this.modal.paymentCashIn.form

      return byCash + byCard + byCashless
    },

    accessBalance () {
      return parseFloat(this.GET_COMPANY_FINANCE)
    },

    paymentSpecCashOut () {
      return this.modal.paymentCashOut.form.paymentSpec[0]
    },

    totalPaymentsCash () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.by_cash, p.kind)
      }, 0)
    },
    totalPaymentsCashless () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.by_cashless, p.kind)
      }, 0)
    },
    totalPaymentsCard () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.by_card, p.kind)
      }, 0)
    },
    totalPaymentsBalance () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.by_balance, p.kind)
      }, 0)
    },
    totalPaymentsCredit () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.by_credit, p.kind)
      }, 0)
    },
    totalPaymentsIncome () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.total_income, p.kind)
      }, 0)
    },
    totalPaymentsPaid () {
      return this.fetched.payments.list.reduce((acc, p) => {
        return acc + this.signPayment(p.total_paid, p.kind)
      }, 0)
    },
  },

  watch: { ...watchers },

  mounted () {
    this.fetchPayments()
    this.fetchRegistries()
  },

  created () {
    this.LOAD_COMPANY(this.$route.params.companyId)
  },

  methods,
}
</script>
