<template>
  <el-tabs
    v-model="currentTab"
    class="flex-grow-1 company-wrapper"
    tab-position="left"
  >
    <el-tab-pane
      :label="t('company')"
      name="company"
    >
      <company-main-info
        class="mt-10"
        v-bind.sync="company"
        :company-types="companyTypes"
        @registerValidator="$registerValidator($event)"
        @suggestCompany="onSuggestCompany"
      />
    </el-tab-pane>

    <el-tab-pane :label="t('address_formal')">
      <company-address
        class="mt-10"
        v-bind.sync="company.addressFormal"
      />
    </el-tab-pane>

    <el-tab-pane :label="t('address_fact')">
      <company-address
        class="mt-10"
        v-bind.sync="company[address]"
      >
        <template #matching>
          <m-checkbox
            v-model="addressMatch"
            :label="t('matches_formal_address')"
            class="mb-indent-validation"
          />
        </template>
      </company-address>
    </el-tab-pane>

    <el-tab-pane
      :label="t('contacts')"
      name="contacts"
    >
      <company-contacts
        class="mt-10"
        v-bind.sync="company"
        @registerValidator="$registerValidator($event)"
      />
    </el-tab-pane>

    <el-tab-pane :label="t('head')">
      <company-head
        class="mt-10"
        v-bind.sync="company"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import CompanyMainInfo
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/components/CompanyMainInfo.vue'
import CompanyAddress from '@/vue_present/Reuse/Address/AddressForm.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import CompanyContacts
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/components/CompanyContacts.vue'
import CompanyHead from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/components/CompanyHead.vue'
import { companiesPresenter } from '@/api_entities/companies/companiesPresenter'
import { getCompanyBySuggestData, getDefaultCompany } from '@/api_entities/companies/getDefaultCompany'
import { DEFAULT_TAB } from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/const'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyWrapper',
  components: {
    CompanyHead,
    CompanyContacts,
    MCheckbox,
    CompanyAddress,
    CompanyMainInfo,
  },

  props: {
    companyType: { type: String, default: undefined },
  },

  data () {
    return {
      currentTab: DEFAULT_TAB,

      company: getDefaultCompany(),
      companyTypes: [],
      addressMatch: false,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/nsiStore/russianSubjectsDictionaryStore', {
      getRussianSubject: 'vxGetRussianSubjectMethod',
    }),

    address () {
      return this.addressMatch ? 'addressFormal' : 'addressFact'
    },
  },

  created () {
    this.fetchCompanyTypes()
    this.$emit('saveDataCallback', this.companyDataCallback())
    this.$emit('companyTabsCallback', this.companyTabsCallback())
  },

  methods: {
    async fetchCompanyTypes () {
      this.companyTypes = await companiesPresenter.fetchCompanyTypes()

      if (this.companyType) {
        const companyType = this.companyTypes.find((type) => type.systemName === this.companyType)
        this.company.companyType = companyType ? [companyType] : []
      }
    },

    onSuggestCompany (companyData) {
      this.company = getCompanyBySuggestData(companyData)
      if (!companyData?.address?.data?.region) { return }
      this.company.addressFormal.nsiRussianSubjectId =
        this.getRussianSubject(companyData.address.data.region)?.id || null
    },

    companyDataCallback () {
      const that = this

      return () => ({ ...that.company, addressMatch: that.addressMatch })
    },

    companyTabsCallback () {
      const that = this

      return (tab) => { that.currentTab = tab }
    },
  },
}
</script>
