<template>
  <div class="changelog-container">
    <changelog-list
      v-if="isSmallDevice && !changelogEntry.id || !isSmallDevice"
      :changelog-entries="changelogEntries"
      :changelog-entry="changelogEntry"
      :is-loading="changelogIsLoading"
      @redirectToHash="redirectToHash"
    />

    <changelog-viewer
      v-if="isSmallDevice && changelogEntry.id || !isSmallDevice"
      :close-btn-visible="isSmallDevice && !!changelogEntry.id"
      v-bind="changelogEntry"
      @redirectBack="redirectBack"
    />
  </div>
</template>

<script>
import { CANNY_CHANGELOG } from '@/vue_components/router/modules/support/route_names.js'
import { SMALL_DEVICE_WIDTH } from '@/vue_components/support/const/const'
import { getHash } from '@/vue_components/support/const/methods'
import { getCannyChangelog } from '@/vue_components/support/rest/canny_endpoint'
import ChangelogViewer from '@/vue_components/support/components/changelog/ChangelogViewer'
import ChangelogList from '@/vue_components/support/components/changelog/ChangelogList'

const DEFAULT_CHANGELOG_ENTRY = {
  created: '',
  id: '',
  labels: [],
  lastSaved: '',
  markdownDetails: '',
  plaintextDetails: '',
  posts: [],
  publishedAt: '',
  scheduledFor: null,
  status: '',
  title: '',
  types: [],
  url: '',
}

export default {
  name: 'CannyChangelog',
  components: {
    ChangelogList,
    ChangelogViewer,
  },

  data: () => ({
    changelogIsLoading: true,

    changelogEntries: [],
    changelogEntry: { ...DEFAULT_CHANGELOG_ENTRY },

    appWidth: window.innerWidth,
  }),

  computed: {
    isSmallDevice () {
      return this.appWidth < SMALL_DEVICE_WIDTH
    },
  },

  watch: {
    /* только для SMALL_DEVICE_WIDTH */
    '$route.hash': 'isSmallDeviceLoadRouteByHash',
  },

  created () {
    window.addEventListener('resize', this.onResize)

    this.changelogIsLoading = true
    getCannyChangelog({})
      .then((data) => {
        this.changelogEntries = data.entries.filter((item) => item.publishedAt)
      })
      .catch((e) => {
        console.error('Get Canny Changelog error: ', e)
      })
      .finally(() => { this.changelogIsLoading = false })
  },

  mounted () {
    this.loadRouteByHash()
  },

  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize () {
      this.appWidth = window.innerWidth

      // закрывается md-viewer если ширина девайса стала меньше SMALL_DEVICE_WIDTH
      if (this.isSmallDevice && this.changelogEntry.id) {
        this.redirectToHash()
      }
    },

    // на SMALL_DEVICE_WIDTH
    redirectBack () {
      this.$router.go(-1)
    },

    isSmallDeviceLoadRouteByHash () {
      if (this.isSmallDevice) {
        this.loadRouteByHash()
      }
    },

    loadRouteByHash () {
      const routeHash = this.$route.hash
      const entryHash = getHash(this.changelogEntry.id)

      if (entryHash !== routeHash) {
        this.redirectToHash(
          this.changelogEntries.find((item) => getHash(item.id) === routeHash)
        )
      }
    },

    redirectToHash (entryItem = null) {
      if (!entryItem) {
        this.changelogEntry = { ...DEFAULT_CHANGELOG_ENTRY }
        if (this.isSmallDevice) {
          this.routerPush({ name: CANNY_CHANGELOG })
        }

        return
      }

      this.changelogEntry = entryItem
      if (this.isSmallDevice) {
        this.routerPush({ name: CANNY_CHANGELOG, hash: getHash(entryItem.id) })
      }
    },

    routerPush (params) {
      this.$router.push(params).catch((error) => {
        if (error.name !== 'NavigationDuplicated') { throw error }
      })
    },
  },
}
</script>
