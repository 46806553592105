<template>
  <el-checkbox
    :id="id"
    class="m-checkbox"
    :disabled="canManage.disabled"
    :value="value"
    :indeterminate="indeterminate"
    :true-label="trueLabel"
    :false-label="falseLabel"
    :label="label"
    :border="border"
    @input="onInput"
    @click="$emit('click', $event)"
  />
</template>

<script>
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'MCheckbox',

  mixins: [
    DisabledMixin,
    CommonInputProps,
    CommonInputMethods,
  ],

  props: {
    value: PropsTypes.Custom([Boolean, String]),

    trueLabel: PropsTypes.Custom([String, Number]),
    falseLabel: PropsTypes.Custom([String, Number]),
    border: Boolean,
    indeterminate: Boolean,
  },
}
</script>
