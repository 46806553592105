<template>
  <div class="contacts-sms flex flex-column w-100">
    <div class="mb-indent">
      <span class="mr-5">{{ t('receiver') }}:</span>
      <span>{{ maskedPhone }}</span>

      <template v-if="clientId && clientFullName">
        <span v-skeleton="skeleton">
          -
          <a
            :href="$routes.client_path(clientId)"
            target="_blank"
          >
            {{ clientFullName }}
          </a>
        </span>
      </template>
    </div>

    <m-textarea
      v-model="message"
      :label="t('message')"
      :placeholder="t('sms.enter_message')"
      :autosize="{ minRows: 2, maxRows: 10 }"
      required
      validator-name="whatsapp_message"
      @registerValidator="onRegisterValidator"
    />

    <div class="prompt-notice">
      {{ `${t('sms.symbol_count')}: ${message.length}` }}
    </div>
  </div>
</template>

<script>
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { smsCount } from '@/modules/sms/utils'
import { MDeliveryPresenter } from '@/_api/Deliveries/MDeliveryPresenter'

export default {
  name: 'Whatsapp',
  components: { MTextarea },
  mixins: [SpinnerHolder, ValidationParentMixin],

  props: {
    phoneNumber: PropsTypes.String(),
    clientId: PropsTypes.Number(),
    clientFullName: PropsTypes.String(),
  },

  data () {
    return {
      message: '',
    }
  },

  computed: {
    skeleton () {
      return this.clientId && !this.clientFullName
    },

    messageCount () {
      return smsCount(this.message)
    },

    maskedPhone () {
      const rawPhone = Services.phoneMask.removeMask(this.phoneNumber)

      return `+${Services.phoneMask.setMask(rawPhone)}`
    },
  },

  created () {
    this.$emit('setSendCallback', this.send())
  },

  methods: {
    send () {
      const vm = this

      return async () => {
        if (vm.loading) { return }
        if (vm.hasErrors()) { return }

        const data = {
          clientId: vm.clientId || undefined,
          clinicId: gon.application.current_clinic.id,
          recipient: vm.clientId
            ? undefined
            : Services.phoneMask.removeMask(vm.phoneNumber),
          text: vm.message,
        }

        const messagePresenter = new MDeliveryPresenter()

        const { errors } = await vm.withSpinner(messagePresenter.sendCustomWhatsappMessage(data))

        if (errors) {
          return Utils.reportError('MDeliveryPresenter:sendCustomWhatsappMessage', errors.error)()
        }

        vm.resetValidations()
        vm.$emit('close')
      }
    },
  },
}
</script>
