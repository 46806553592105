
export const isString = (value: unknown): value is string => typeof value === 'string'

export const isNumber = (value: unknown): value is number => typeof value === 'number'

export const isStringOrNumber = (value: unknown): value is string | number => isString(value) || isNumber(value)

export const isArray = <T>(value: T | T[]): value is T[] => Array.isArray(value)

export const isObject = <T = object>(value: unknown): value is T => value !== null && typeof value === 'object'
