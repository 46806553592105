<template>
  <m-panel
    :title="t$('title')"
    class="personal-checkup-vaccinations-and-diseases"
  >
    <m-si-generator
      class="personal-checkup-vaccinations-and-diseases__item"
      :items="vaccinations"
      :si-generator-schema="vaccinationMsiSchema"
      :add-button-config="vaccinationAddButtonConfig"
      :nfr-text="t$('vaccination.nfrText')"
      @onAddItem="showVaccinationModal = true"
      @onItemDelete="personalCheckup.vaccinations.destroy($event.id)"
    />

    <m-si-generator
      class="personal-checkup-vaccinations-and-diseases__item"
      :items="infectiousDiseases"
      :si-generator-schema="infectiousDiseasesMsiSchema"
      :add-button-config="infectiousDiseasesAddButtonConfig"
      :nfr-text="t$('infectiousDiseases.nfrText')"
      @onAddItem="showInfectiousDiseasesModal = true"
      @onItemDelete="personalCheckup.infectiousDiseases.destroy($event.id)"
    />

    <personal-checkup-vaccination-modal
      v-if="showVaccinationModal"
      :visible="showVaccinationModal"
      :personal-checkup="personalCheckup"
      :is-new-mode="isNewMode"
      :is-show-mode="isShowMode"
      :is-edit-mode="isEditMode"
      @close="showVaccinationModal = false"
    />

    <personal-checkup-infectious-diseases-modal
      v-if="showInfectiousDiseasesModal"
      :visible="showInfectiousDiseasesModal"
      :personal-checkup="personalCheckup"
      :is-new-mode="isNewMode"
      :is-show-mode="isShowMode"
      :is-edit-mode="isEditMode"
      @close="showInfectiousDiseasesModal = false"
    />
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import PersonalCheckupVaccinationModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupVaccinationModal.vue'
import { getVaccinationMsiSchema } from '@/vue_apps/CheckupsModule/store/vaccination/vaccinationConst'
import {
  getInfectiousDiseasesMsiSchema,
} from '@/vue_apps/CheckupsModule/store/InfectiousDiseases/InfectiousDiseasesConst'
import PersonalCheckupInfectiousDiseasesModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupInfectiousDiseasesModal.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import {
  getVaccinationAndInfectiousDiseasesAddButtonConfig,
} from '@/vue_apps/CheckupsModule/store/personalCheckupVaccinationAndInfectiousDiseases/personalCheckupVaccinationAndInfectiousDiseasesConsts'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'

export default defineComponent({
  name: 'PersonalCheckupVaccinationsAndDiseases',

  components: {
    PersonalCheckupInfectiousDiseasesModal,
    PersonalCheckupVaccinationModal,
    MSiGenerator,
    MPanel,
  },

  mixins: [
    i18nScopeMixin,
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
  },

  data () {
    return {
      showVaccinationModal: false,
      showInfectiousDiseasesModal: false,
      vaccinationMsiSchema: getVaccinationMsiSchema({ addCreateButton: true }),
      infectiousDiseasesMsiSchema: getInfectiousDiseasesMsiSchema({ addCreateButton: true }),
    }
  },

  computed: {
    vaccinations () {
      return this.personalCheckup
        .vaccinations
        .getArr()
    },

    infectiousDiseases () {
      return this.personalCheckup
        .infectiousDiseases
        .getArr()
    },

    vaccinationAddButtonConfig () {
      return getVaccinationAndInfectiousDiseasesAddButtonConfig(
        'vaccination.title',
        this.hasSpecialFactors
      )
    },

    infectiousDiseasesAddButtonConfig () {
      return getVaccinationAndInfectiousDiseasesAddButtonConfig(
        'infectiousDiseases.pastInfectionDiseases',
        this.hasSpecialFactors
      )
    },
  },

  created () {
    this.setI18nScope('checkups.vaccinationsAndDisease')
  },
})
</script>
