<template>
  <component
    :is="documentComponent"
    :id="id"
    :is-edit-mode="isEditMode"
    :is-show-mode="isShowMode"
    :document-type="documentType"
    class="checkup-document-wrapper"
    @destroy="onDestroy"
  />
</template>

<script lang="ts">
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import CompanyBladeRunner
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/CompanyDocuments/CompanyBladeRunner/CompanyBladeRunner.vue'
import CheckupDocument from '@/vue_apps/CheckupsModule/components/CheckupDocument/CheckupDocument.vue'
import PersonalBladeRunner
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/PersonalDocuments/PersonalBladeRunner/PersonalBladeRunner.vue'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { defineComponent } from 'vue'
import { TPubSubRemoveDocuments } from '@/_declarations/pubSub/interfaces/pubSubRemoveDocuments'
import { PUB_SUB_EVENTS } from '@/_declarations/pubSub/pubSubEvents'
import { PUB_SUB_BROADCAST_EVENTS } from '@/_declarations/pubSub/pubSubBroadcastEvents'

export default defineComponent({
  name: 'CheckupDocumentPage',
  components: {
    MButton,
    MButtonSubmit,
    MPanel,
    CompanyBladeRunner,
    PersonalBladeRunner,
    CheckupDocument,
  },

  props: {
    id: { type: Number, default: null },

    isEditMode: Boolean,
    isShowMode: Boolean,

    documentType: {
      type: String,
      required: true,
    },
  },

  computed: {
    documentComponent () {
      switch (this.documentType) {
        case DOCUMENT_TYPES.CHECKUPS_PERSONAL_ROUTES_LIST: return 'PersonalBladeRunner'
        case DOCUMENT_TYPES.CHECKUPS_COMPANY_ROUTES_LIST: return 'CompanyBladeRunner'

        case DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT:
        case DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT:
        case DOCUMENT_TYPES.CHECKUPS_COMPANY_FINAL_ACT:
          return 'CheckupDocument'

        default: return ''
      }
    },
  },

  created () {
    this.subscribeToRemoveDocuments()
  },

  methods: {
    onDestroy () {
      this.$pubSub.emit(PUB_SUB_EVENTS.REMOVE_DOCUMENTS, [{
        documentId: this.id,
        documentType: this.documentType,
      }], true)

      window.close()
    },

    subscribeToRemoveDocuments () {
      this.$pubSub.subscribe(
        PUB_SUB_BROADCAST_EVENTS.REMOVE_DOCUMENTS,
        (event: TPubSubRemoveDocuments) => {
          const isExits = event.some(({ documentType, documentId }) =>
            documentType === this.documentType &&
              documentId === this.id)

          if (!isExits) { return }

          window.close()
        })
    },
  },
})
</script>
