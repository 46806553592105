<template>
  <div class="si-not-found">
    <div class="si-not-found__message">
      {{ message }}
    </div>
    <div
      v-if="controlConstraints"
      class="si-not-found__controls"
    >
      <button
        class="btn btn-warning btn-sm"
        @click="$emit('remove-constraints')"
      >
        {{ removeConstraintsText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiNotFound',
  props: {
    message: {
      type: String,
      default: t('si_table.not_found_message'),
    },
    controlConstraints: Boolean,
    removeConstraintsText: {
      type: String,
      default: t('search_without_constraints'),
    },
  },
}
</script>
