import { NestedItemsModel } from '@/_api/_classes/NestedItems/classes/NestedItemsModel'
import { extractId } from '@/_medods_standart_library/msl'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

export class NestedItemsLogic<ItemClass extends MListServiceNestedItemsItem, ItemProps>
  extends NestedItemsModel<ItemClass, ItemProps> {
  getUnsaved () {
    return this.getArr()
      .filter((item) => !(item.dbId || item._keep))
  }

  removeUnsaved () {
    this.nestedItems.removeMany(
      this.getUnsaved()
        .map(extractId)
    )
  }

  setKeep (toKeep = this.getArr()) {
    toKeep.forEach((item) => {
      item._keep = true
    })
  }

  getArr () {
    return this.nestedItems.getArr()
  }
}
