import { tScoped } from '@/helpers/i18nHelpers'
import { parseBetweenQuotes } from '@/helpers/parseBetweenQuotes'

const ts = tScoped('semds.validationErrors')

export const tryExtractNetrikaError = (error) => {
  if (typeof error === 'object') {
    if (error.idPatientMis) { return ts('idPatientMis') }
    if (error.MedDocument) { return error.MedDocument }

    return error
  }

  try {
    const data = JSON.parse(error)

    return ts('_sendingErrorsTemplate', {
      entity: ts(data.entity),
      id: data.id,
      errorType: ts(data.error_type),
      value: ts(data.value),
      messages: data.messages.join(', '),
    })
  } catch (e) {
    if (error.includes('idPatientMis')) { return ts('idPatientMis') }

    const errorBetweenQuotes = parseBetweenQuotes(error)
    if (errorBetweenQuotes) { return errorBetweenQuotes }

    return error
  }
}
