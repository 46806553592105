import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import {
  IPersonalCheckupDoctorResultsItem,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupDoctorResults/IPersonalCheckupDoctorResultsItem'
import {
  IPersonalCheckupDoctorResultsUser,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupDoctorResults/IPersonalCheckupDoctorResultsUser'
import {
  IPersonalCheckupDoctorResultsSpecialty,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupDoctorResults/IPersonalCheckupDoctorResultsSpecialty'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { IUser } from '@/_declarations/IUser'
import {
  PERSONAL_CHECKUP_DOCTOR_RESULTS_HAS_MEDICAL_CONTRAINDICATIONS_NSI_ID,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDoctorResults/personalCheckupDoctorResultsConst'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

export class PersonalCheckupDoctorResultsListItem extends MListServiceNestedItemsItem {
  date: MTableCell

  foreignUser: boolean

  foreignUserName: string | null

  foreignUserSurname: string | null

  foreignUserSecondName: string | null

  specialty: IPersonalCheckupDoctorResultsSpecialty

  specialtyTitle: MTableCell

  user: IPersonalCheckupDoctorResultsUser | IUser

  userShortName: MTableCell

  medicalAssessment: INsiEntry

  medicalAssessmentTitle: MTableCell

  constructor (id: number, item: IPersonalCheckupDoctorResultsItem) {
    super(id, item)

    this.date = new MTableCell(vueFilters.date(item.date))
      .addTooltip(true)

    this.foreignUser = item.foreignUser

    this.foreignUserName = item.foreignUserName

    this.foreignUserSurname = item.foreignUserSurname

    this.foreignUserSecondName = item.foreignUserSecondName

    this.specialty = item.specialty

    this.specialtyTitle = new MTableCell(item.specialty.title)
      .addTooltip(true)
      .addCut()

    this.user = item.user

    this.medicalAssessment = item.medicalAssessment

    this.medicalAssessmentTitle = new MTableCell(item.medicalAssessment.title)
      .addClasses(
        +item.medicalAssessment.nsiId === PERSONAL_CHECKUP_DOCTOR_RESULTS_HAS_MEDICAL_CONTRAINDICATIONS_NSI_ID
          ? 'warning'
          : 'success'
      )
      .addTooltip(true)
      .addCut()

    this.setUserShortName()
  }

  private setUserShortName () {
    const user = this.foreignUser
      ? {
          surname: this.foreignUserSurname,
          name: this.foreignUserName,
          secondName: this.foreignUserSecondName,
        }
      : this.user

    this.userShortName = new MTableCell(vueFilters.shortName(user))
      .addTooltip(true)
      .addCut()
  }
}
