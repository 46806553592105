import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  IPersonalCheckupInfectiousDiseasesItem,
} from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupInfectiousDiseasesItem'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'

export class PersonalCheckupInfectiousDiseasesListItem extends MListServiceNestedItemsItem {
  date: string

  year: MTableCell

  nsiDiagnosisTitle: MTableCell

  nsiDiagnosisId: number

  nsiDiagnosisNsiId: MTableCell

  constructor (id: number, item: IPersonalCheckupInfectiousDiseasesItem) {
    super(id, item)

    this.date = item.date

    this.year = new MTableCell(
      moment(item.date)
        .year()
    ).addTooltip(true)

    this.nsiDiagnosisId = item.nsiDiagnosisId

    this.nsiDiagnosisTitle = new MTableCell(item.nsiDiagnosisTitle)
      .addTooltip(true)
      .addCut()

    this.nsiDiagnosisNsiId = new MTableCell(item.nsiDiagnosisNsiId)
      .addTooltip(true)
  }
}
