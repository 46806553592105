import {
  PersonalSimpleMedicalAssessmentPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalSimpleMedicalAssessmentPresenter/PersonalSimpleMedicalAssessmentPresenter'
import {
  PersonalElmkMedicalAssessmentPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalElmkMedicalAssessmentPresenter/PersonalElmkMedicalAssessmentPresenter'
import { BladeRunner } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunner'
import {
  MCompanyBladeRunnerDocumentPresenter,
} from '@/vue_apps/CheckupsModule/api/MCompanyBladeRunnerDocumentPresenter/MCompanyBladeRunnerDocumentPresenter'
import {
  CompanyFinalActPresenter,
} from '@/vue_apps/CheckupsModule/api/CompanyFinalActPresenter/CompanyFinalActPresenter'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import {
  MDocumentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/MDocumentsPresenter'

export const CHECKUP_DOCUMENT_API_MAP = {
  [DOCUMENT_TYPES.CHECKUPS_PERSONAL_ROUTES_LIST]: new BladeRunner(),
  [DOCUMENT_TYPES.CHECKUPS_COMPANY_ROUTES_LIST]: new MCompanyBladeRunnerDocumentPresenter(),
  [DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT]: new PersonalSimpleMedicalAssessmentPresenter(),
  [DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT]: new PersonalElmkMedicalAssessmentPresenter(),
  [DOCUMENT_TYPES.CHECKUPS_COMPANY_FINAL_ACT]: new CompanyFinalActPresenter(),
  [DOCUMENT_TYPES.OLD_DOCUMENT]: new MDocumentsPresenter(),
}
