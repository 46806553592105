const PREFIX = 'can'
const ALL = 'all'
const ACCESS_MANAGE = 'Manage'

class SecurityContextHolder {
  load (models, actions, currentUserPermissions) {
    this.models = new Set(models)
    this.actions = actions
    this.permissions = currentUserPermissions
  }

  tryApplySecurityCommand (securityCommand) {
    if (!securityCommand.startsWith(PREFIX)) throw new Error(`the command must begin with the prefix "${PREFIX}"`)

    const commandBody = securityCommand.slice(PREFIX.length)
    const suitableActions = this.actions.filter((action) => commandBody.startsWith(action))
    if (suitableActions.length === 0) throw new Error(`unsupported security action: ${securityCommand}`)

    const securityAction = suitableActions.sort((a1, a2) => a2.length - a1.length).shift()
    const model = commandBody.slice(securityAction.length)
    if (!this.models.has(model)) {
      console.error(`unsupported security model: ${securityCommand}`)

      return false
    }

    const can =
      (this.permissions[ALL] && this.permissions[ALL].includes(ACCESS_MANAGE)) ||
      (this.permissions[model] && (this.permissions[model].includes(ACCESS_MANAGE) ||
        this.permissions[model].includes(securityAction)))

    return can
  }
}

const instance = new SecurityContextHolder()

export default new Proxy(Object.create(null), {
  get (_, securityCommand) {
    if (securityCommand === 'load') return instance.load.bind(instance)

    return instance.tryApplySecurityCommand(securityCommand)
  },
  set () {
    throw new Error('unsupported operation')
  },
})
