<template>
  <m-select
    :value="value"
    :use-custom-result="useCustomResult"
    :m-fixed-height="mFixedHeight"
    :full-width="fullWidth"
    :disabled="disabled"
    :placeholder="placeholder"
    :required="required"
    :label="label"
    :items="fnsEntries"
    :value-key="valueKey"
    :validator-name="validatorName"
    @change="$emit('change', $event)"
    @changeItem="$emit('changeItem', $event)"
    @registerValidator="$registerValidator($event)"
  />
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { getFNSDictEntries } from '@/vue_present/Reuse/Lists/FNS/getFNSDictEntries'

export default {
  name: 'ReusableFNSList',

  components: { MSelect },

  mixins: [DisabledMixin],

  props: {
    value: { type: [String, Number, Object], default: null },
    useCustomResult: { type: String, default: DEFAULT_RESULT_TYPES.OBJECT },
    placeholder: { type: String, default: t('choose') },
    label: { type: String, default: t('documentType') },
    valueKey: { type: String, default: 'title' },
    required: { type: [Boolean, Function], default: null },
    validatorName: { type: String, default: null },
    fullWidth: Boolean,
    mFixedHeight: Boolean,
  },

  emits: [
    'change',
    'changeItem',
    'registerValidator',
  ],

  data () {
    return {
      fnsEntries: getFNSDictEntries(),
    }
  },
}
</script>
