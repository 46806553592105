<template>
  <div class="checkup-entry-type-cabinet-clinic">
    <div class="checkup-entry-type-cabinet-clinic__clinic-title">
      {{ clinic.title }}
    </div>

    <div class="checkup-entry-type-cabinet-clinic__cabinet">
      <m-select-lazy
        ref="cabinetSelect"
        :value="clinicsCabinets.cabinet"
        :label="t('cabinet')"
        :fetch-method="(sortParams) => cabinetsPresenter.list(sortParams)"
        :placeholder="t('select')"
        :m-fixed-height="false"
        full-width
        @openOptions="fetchCabinets"
        @changeByLazy="onCabinetChanger"
      />

      <reusable-doctors-list
        :value="clinicsCabinets.users"
        :use-user-mode="false"
        :current-clinic="clinic.id"
        :disabled="!clinicsCabinets.cabinet"
        use-first-time-fetch-on-open
        multiple
        full-width
        @onDoctorChange="$updateSync('clinicsCabinets', { ...clinicsCabinets, users: $event })"
      />

      <reusable-entry-types-list
        :value="clinicsCabinets.entryType"
        :label="t('catalog_entry_type')"
        :disabled="!clinicsCabinets.cabinet"
        :current-clinic-id="clinic.id"
        :entry-type-kinds="entryTypesKinds"
        use-entry-number-filter
        for-current-clinic
        full-width
        @changeEntryTypes="$updateSync('clinicsCabinets', { ...clinicsCabinets, entryType: $event })"
      />
    </div>
  </div>
</template>

<script>
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MCabinetPresenter } from '@/_api/Cabinet/MCabinetPresenter/MCabinetPresenter'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { MCheckupEntryTypePresenter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/MCheckupEntryTypePresenter'
import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import ReusableEntryTypesList from '@/vue_present/Reuse/Lists/EntryTypes/ReusableEntryTypesList.vue'

export default {
  name: 'CheckupEntryTypeClinicCabinet',
  components: { ReusableEntryTypesList, ReusableDoctorsList, MSelectLazy },
  props: {
    clinic: { type: Object, default: null },
    clinicsCabinets: { type: Object, default: null },
  },

  data () {
    return {
      cabinetsPresenter: new MCabinetPresenter(),
      entryTypesPresenter: new MCheckupEntryTypePresenter(),

      cabinetsLoaded: false,
      entryTypesKinds: [
        GLOBAL_ENTRY_TYPES_KINDS.SERVICE,
        GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS,
        GLOBAL_ENTRY_TYPES_KINDS.COMPLEX,
      ],
    }
  },

  methods: {
    fetchCabinets () {
      if (this.cabinetsLoaded) { return }
      this.cabinetsLoaded = true
      this.$refs.cabinetSelect.fetch({ clinicId: this.clinic.id, offset: 0, limit: DEFAULT_LIMIT })
    },

    onCabinetChanger (cabinet) {
      this.$updateSync('clinicsCabinets', {
        cabinet,
        users: [],
        entryType: null,
      })
    },
  },
}
</script>
