class Workplace {
  init () {
    this.subscribeToWebSockets()
    this.redrawUi()
    this.addPubSubListeners()
  }

  reset () {

  }

  subscribeToWebSockets () {
    this.subscription = Services.wsSubscriptions.user.advancedConnection({
      received: this.receiveMessage,
      connected: this.checkModules,
    })
  }

  receiveMessage (msg) {
    if (msg.meta.type === 'CLINIC_SWITCH.CHANGED_CURRENT_CLINIC') {
      Services.clinicSwitch.enqueueRefresh(
        msg.data.changed_current_clinic_id, gon.application.current_clinic.id
      )
    }

    Services.pubSub.emitAsync(`workplace.${msg.meta.type}`, msg)
  }

  addPubSubListeners () {
    Services.pubSub.subscribe('workplace.update_module_statuses', (msg) => {
      this.redrawUi(msg.data.modules)
    })
  }

  redrawUi (modules = {}) {
    const indicatorElements = document.getElementsByClassName('we')
    for (const indicatorElement of indicatorElements) {
      indicatorElement.classList.add('we-disabled')
    }

    for (const module in modules) {
      if (modules[module] !== 'enabled') continue

      const moduleIndicators = document.getElementsByClassName(`we-${module}`)
      for (const moduleIndicator of moduleIndicators) {
        moduleIndicator.classList.remove('we-disabled')
      }
    }
  }

  baseMessage (type, params = {}) {
    return {
      meta: {
        type,
      },
      ...params,
    }
  }

  checkModules () {
    Services.workplace.sendMessage('check_module_statuses')
  }

  sendMessage (messageType, params = {}) {
    this.subscription.send(this.baseMessage(messageType, params))
  }

  printReport (type, workplaceId = null, params = {}) {
    if (workplaceId) {
      return this.sendMessage('print_report', { data: { type, ...params }, workplace_id: workplaceId })
    }

    return this.sendMessage('print_report', { data: { type, ...params } })
  }
}

const instance = new Workplace()

export default {
  init: () => instance.init(),
  reset: () => instance.reset(),
  beep: () => instance.beep(),
  printReport: (type, workplaceId, params) => instance.printReport(type, workplaceId, params),
  sendMessage: (msgType, params) => instance.sendMessage(msgType, params),
}
