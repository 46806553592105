<template>
  <div
    v-loading="loading"
    class="workplace-certificates-list-wrapper gap-indent-small"
  >
    <simple-select
      :value="value"
      :validation-messages="validationMessages"
      :items="certificatesList"
      :disabled="!workplaceId"
      clearable
      @change="$emit('change', $event)"
    />

    <button
      v-tooltip="t('reload')"
      type="button"
      class="btn btn-sm btn-primary btn-with-icon max-h-30"
      :disabled="!workplaceId"
      @click="loadCertificatesList"
    >
      <i class="fad fa-sync mr-0" />
    </button>
  </div>
</template>

<script>
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { workplaceEndpoint } from '@/api_entities/workplaces/workplace_endpoint'

export default {
  name: 'WorkplaceCertificatesList',
  components: { SimpleSelect },

  mixins: [SpinnerHolder],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    workplaceId: {
      type: Number,
      default: null,
    },

    localMachine: Boolean,

    value: {
      type: String,
      default: null,
    },

    validationMessages: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      certificatesList: [],
    }
  },

  watch: {
    workplaceId () {
      this.loadCertificatesList()
    },

    localMachine () {
      this.loadCertificatesList()
    },
  },

  methods: {
    loadCertificatesList () {
      if (!this.workplaceId) { return }

      const promise = workplaceEndpoint.getCertificates(this.workplaceId, this.localMachine)
        .then((data) => { this.certificatesList = data })
        .catch((err) => {
          Utils.reportError('WorkplaceCertificatesList:loadCertificatesList()')(err)
          if (err.status !== 401) { return }
          Notificator.error(t('egisz.messages.access.cant_read_certificate'))
        })

      this.withSpinner(promise)
    },
  },
}
</script>
