<template>
  <m-panel
    class="semd-registration-area"
    :title="t('semds.semd119.formSections.signaturesAndRegistration')"
    :type="panelType"
    icon="sign"
    :use-type-by-route="false"
    m-panel-class=""
  >
    <slot name="validationNotice">
      <div class="signature-clarification mb-indent-validation prompt-notice">
        {{ t('semds.semd119.formTitles.signatureClarification') }}
      </div>
    </slot>

    <!--    Подпись врача-->
    <m-input
      class="flex-grow-1"
      :value="doctorSigner"
      :label="doctorSignerLabel"
      :placeholder="t('semds.semd119.formTitles.doctorSignaturePlaceholder')"
      disabled
    >
      <template #append>
        <reusable-sign-popover
          v-if="!hideSignatureButtons && !doctorSigner"
          :disabled="doctorSignDisabled"
          @onThumbprintSelect="$emit('onSign', {
            ...$event,
            holderScope: 'personal'
          })"
        />

        <m-button-delete
          v-if="doctorSigner"
          :disabled="{ disabled: !doctorSigner || disabled, tooltip: t('removeSignature') }"
          @yes="$emit('onRemoveSign', {
            ...$event,
            holderScope: 'personal'
          })"
        />
      </template>
    </m-input>

    <!--    Подпись клиники-->
    <m-input
      class="flex-grow-1"
      :value="organizationSigner"
      :label="t('semds.semd119.formTitles.clinicSignature')"
      :placeholder="t('semds.semd119.formTitles.clinicSignaturePlaceholder')"
      disabled
    >
      <template #append>
        <reusable-sign-popover
          v-if="!hideSignatureButtons && !organizationSigner"
          :disabled="organizationSignDisabled"
          @onThumbprintSelect="$emit('onSign', {
            ...$event,
            holderScope: 'organization'
          })"
        />

        <m-button-delete
          v-if="organizationSigner"
          :disabled="{ disabled: !organizationSigner || disabled, tooltip: t('removeSignature') }"
          @yes="$emit('onRemoveSign', {
            ...$event,
            holderScope: 'organization'
          })"
        />
      </template>
    </m-input>

    <!--    Ошибки регистрации-->
    <div class="registration-errors">
      <div class="registration-errors__title prompt-notice">
        {{ t('semds.semd119.formTitles.registrationErrors') }}
      </div>

      <div class="registration-errors__errors-list">
        <template v-if="!errors.length">
          <span class="prompt-notice">
            {{ t('semds.errors.noErrors') }}
          </span>
        </template>
        <template v-else>
          <div
            v-for="(error, index) in errors"
            :key="`${error.timestamp}:${index}`"
          >
            {{ error.timestamp }}: {{ getErrorText(error) }}
          </div>
        </template>
      </div>
    </div>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import ReusableSignPopover from '@/vue_present/Reuse/Sign/ReusableSignPopover.vue'
import { getForbiddenConfig } from '@/helpers/getForbiddenConfig'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'

export default {
  name: 'SemdRegistrationArea',
  components: {
    MButtonDelete,
    ReusableSignPopover,
    MInput,
    MPanel,
  },

  props: {
    panelType: { type: String, default: 'success' },
    errors: { type: Array, default: () => [] },
    doctorSigner: { type: String, default: '' },
    doctorSignerLabel: { type: String, default: t('semds.semd119.formTitles.doctorSignature') },
    organizationSigner: { type: String, default: '' },
    disabled: Boolean,
    hideSignatureButtons: Boolean,
  },

  emits: [
    'onSign',
    'onRemoveSign',
  ],

  computed: {
    doctorSignDisabled () {
      if (this.disabled) { return this.disabled }

      if (!this.$security.canManageSelfEgiszSign) {
        return getForbiddenConfig()
      }

      const disabled = Boolean(this.doctorSigner)

      return {
        disabled,
        tooltip: disabled && t('semds.errors.semdSigned'),
      }
    },

    organizationSignDisabled () {
      if (this.disabled) { return this.disabled }

      if (!this.doctorSigner) {
        return {
          disabled: true,
          tooltip: t('semds.errors.noDoctorSignature'),
        }
      }

      if (!this.$security.canManageSelfEgiszSign) {
        return getForbiddenConfig()
      }

      if (!this.$security.canManageOrganizationSign) {
        return 'OrganizationSign'
      }

      const disabled = Boolean(this.organizationSigner)

      return {
        disabled,
        tooltip: disabled && t('semds.errors.semdSigned'),
      }
    },
  },

  methods: {
    getErrorText (error) {
      if (typeof error.error === 'string') {
        if (error.error.includes('timed out')) {
          return t('semds.validationErrors.timedOut')
        }

        if (error.error.includes('idLpu')) {
          return t('semds.validationErrors.IdLpu')
        }

        if (error.error.includes('Common')) {
          return t('semds.validationErrors.IdGuid')
        }

        if (error.error.includes('IdPatientMis')) {
          return t('semds.validationErrors.IdPatientMis')
        }
      }

      if (typeof error.error === 'object') {
        if (error.error?.IdPatientMis) { return t('semds.validationErrors.IdPatientMis') }

        if (error.error?.idLpu) { return t('semds.validationErrors.IdLpu') }

        if (error.error?.Common) { return t('semds.validationErrors.IdGuid') }
      }

      return error.error
    },
  },
}
</script>
