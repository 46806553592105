<template>
  <div
    class="m-table-row-cell"
    :class="rowClasses"
    :style="style"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>

import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'MTableRowCell',
  props: {
    name: { type: String, required: true },
    width: PropsTypes.String(),
    useCut: Boolean,
  },

  computed: {
    rowClasses () {
      return [
        `col col__${this.name}`,
        { cut: this.useCut },
      ]
    },

    style () {
      return this.width
        ? {
            flexGrow: 0,
            flexBasis: this.width,
          }
        : {}
    },
  },
}
</script>
