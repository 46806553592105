import { CHECKUP_KINDS } from '@/vue_apps/CheckupsModule/store/checkupsKinds'

export const PersonalCheckupEditorTitleMixin = {
  computed: {
    title () {
      const kind = t('genitive', { scope: `checkups.personal.kind.${this.checkupKind}` })
      const number = this.id

      const getExtraTitle = (title) =>
          `${title} (${t('checkups.company.showTitle', { number: this.companyCheckup?.id || '' })}, ${this.companyCheckup?.title || ''})`

      const title = this.isShowMode
        ? t('checkups.personal.showTitle', {
          kind: t('nominative', { scope: `checkups.personal.kind.${this.checkupKind}` }),
          number,
        })
        : this.isNewMode
          ? t('checkups.personal.addTitle', { kind })
          : t('checkups.personal.editTitle', { kind, number })

      if (this.checkupKind === CHECKUP_KINDS.PERIODIC) {
        return getExtraTitle(title)
      }

      return title
    },
  },
}
