export const loadCannySDK = () => {
  /* eslint-disable */
  (function (w, d, i, s) {
    function l () {
      if (!d.getElementById(i)) {
        var f = d.getElementsByTagName(s)[0], e = d.createElement(s)
        e.type = 'text/javascript', e.async = !0, e.src = 'https://canny.io/sdk.js', f.parentNode.insertBefore(e, f)
      }
    }

    if (typeof w.Canny !== 'function') {
      var c = function () { c.q.push(arguments) }
      c.q = [], w.Canny = c, d.readyState === 'complete' ? l() : w.attachEvent ? w.attachEvent('onload', l) : w.addEventListener('load', l, !1)
    }
  })(window, document, 'canny-jssdk', 'script')
  /* eslint-enable */
}

export const getUserAvatarFullPath = (userId) => {
  return document.location.protocol + '//' + document.location.host +
    Routes.user_avatar_path(userId, {version: 'thumb40'})
}

export const getBadgeBackgroundColorByName = (name) => {
  if (!name) return '#ffffff'

  if (name === 'new') {
    return '#8BC34A'
  } else if (name === 'improved') {
    return '#3FAECA'
  } else if (name === 'fixed') {
    return '#9c27b0'
  }

  return '#bdbdbd'
}

export const getHash = (value) => {
  return '#' + value
}
