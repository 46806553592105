import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { clientLegacyAdapter } from '@/_api/MClient/clientLegacyAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake } from '@/_api/_requests/helpers'
import { clientSearchAdapter } from '@/_api/MClient/clientSearchAdapter'
import { MListService } from '@/_api/_requests/MListService'
import { clientFetchAdapter } from '@/_api/MClient/clientFetchAdapter'

export class MClientPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MClientPresenter',
      entity: 'client',
      routes: { one: Routes.api_internal_client_path, all: Routes.api_internal_clients_path, list () {} },
    })
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      ...config,
      url: Routes.find_client_path(id),
    })
      .then((response) => {
        return clientFetchAdapter.toClient(response)
      })
  }

  count () {
    const options = {
      url: Routes.count_clients_path(),
    }

    return this.get(options).promise
      .then((count) => Promise.resolve({ count }))
      .catch(MRequestError.withDefault(null, null))
  }

  /* todo: удалить после переписывания бэка */
  legacyList ({ letter, page, perPage }) {
    const options = {
      url: Routes.sort_clients_path(),
      data: {
        letter,
        page,
        per_page: perPage,
      },
    }

    const notification = new MRequestNotification(
      'MClientPresenter',
      'listMessageShort',
      'clients'
    )

    return this.post(options).promise
      .then((response) => Promise.resolve(clientLegacyAdapter.toClient(response)))
      .catch(() => Promise.resolve(
        MRequestError.withDefault(clientLegacyAdapter.defaultList(), notification)
      ))
  }

  /* todo: удалить после переписывания бэка */
  legacySearch ({ searchText, page, perPage }) {
    const options = {
      url: Routes.search_clients_path(),
      data: {
        search_text: searchText,
        page,
        per_page: perPage,
      },
    }

    const notification = new MRequestNotification(
      'MClientPresenter',
      'listMessageShort',
      'clients'
    )

    return this.post(options).promise
      .then((response) => Promise.resolve(clientLegacyAdapter.toClient(response)))
      .catch(() => Promise.resolve(
        MRequestError.withDefault(clientLegacyAdapter.defaultList(), notification)
      ))
  }

  checkDebt (clientId) {
    const options = {
      url: Routes.check_debts_calculation_client_path(clientId),
    }

    const notification = new MRequestNotification(
      'MClientPresenter',
      'checkMessage',
      'debtCorrect'
    )

    return this.get(options).promise
      .catch(MRequestError.onRequest(notification))
  }

  checkupSearch (queryParams) {
    const notification = new MRequestNotification(
      'MClientPresenter',
      'fetchAllMessage',
      'clients'
    )

    const options = {
      url: Routes.search_api_internal_checkups_clients_path(),
      data: camelToSnake({ sortParams: queryParams }),
    }

    return this.post(options).promise
      .then((response) => Promise.resolve(clientSearchAdapter.toClient(response)))
      .catch(MRequestError.withDefault(MListService.getDefaultList(), notification))
  }

  fetchFullName (clientId) {
    const options = {
      url: Routes.full_name_client_path(clientId),
    }

    return this.get(options).promise
      .catch(MRequestError.withDefault('', null))
  }
}
