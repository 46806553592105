import {
  KPP_LENGTH,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'

export const TaxCertificate2024EditorPerformingClinicValidationMixin = {
  methods: {
    requiredIfIndividualEntrepreneur (value) {
      if (!this.certificate.individualEntrepreneur) { return [] }

      return Utils.getValidationErrors(value)
    },

    innValidator (value) {
      return this.__baseDigitsOnlyValidator(value, t('inn'), this.innLength)
    },

    kppValidator (value) {
      return this.__baseDigitsOnlyValidator(Utils.extractDigits(value || ''), t('kpp'), KPP_LENGTH)
    },

    __baseDigitsOnlyValidator (value, field, length) {
      const errors = Utils.getValidationErrors(value)

      if (errors.length) { return errors }

      const digits = Utils.extractDigits(value)

      if (value !== digits || digits.length < length) {
        return [
          t('validation.errors.format?', { field }),
        ]
      }

      return []
    },
  },
}
