import {
  PersonalCheckupInstrumentalResearchResultsLogic,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupInstrumentalResearchResults/PersonalCheckupInstrumentalResearchResultsLogic'
import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'

export class PersonalCheckupInstrumentalResearchResultsApi extends PersonalCheckupInstrumentalResearchResultsLogic {
  constructor (props?: Pick<IPersonalCheckup, 'id' | 'instrumentalResearchResults'>) {
    super(props)

    this.fetchResearches()

    this.fetchMedicalAssessments().then(() => {
      this.setDefaultMedicalAssessment()
    })
  }

  async fetchResearches () {
    this.researches = await this.presenter.fetchResearches()
  }

  async fetchMedicalAssessments () {
    this.medicalAssessments = await this.presenter.fetchMedicalAssessments()
  }
}
