import {
  PersonalCheckupLaboratoryResearchResultsLogic,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupLaboratoryResearchResults/PersonalCheckupLaboratoryResearchResultsLogic'
import {
  IFetchPersonalCheckupLaboratoryResearchResultsResearchesDTO,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupLaboratoryResearchResults/IPersonalCheckupLaboratoryResearchResultsResearchesDTO'

export class PersonalCheckupLaboratoryResearchResultsApi extends PersonalCheckupLaboratoryResearchResultsLogic {
  async fetchResearches (data: IFetchPersonalCheckupLaboratoryResearchResultsResearchesDTO) {
    this.researches = await this.presenter.fetchResearches(data)
  }

  async fetchMedicalAssessments () {
    this.medicalAssessments = await this.presenter.fetchMedicalAssessments()

    this.setDefaultMedicalAssessment()
  }
}
