<template>
  <div class="m-button-page flex flex-wrap gap-indent">
    <div>
      <h1>MButton</h1>
      <h2>Основные типы (buttonStyle) и цвета (type)</h2>
      <h3>Стандартные (plain)</h3>
      <div>
        <m-button
          text="text"
          type="text"
        />
        <m-button text="default" />
        <m-button
          text="primary"
          type="primary"
        />
        <m-button
          text="success"
          type="success"
        />
        <m-button
          text="warning"
          type="warning"
        />
        <m-button
          text="danger"
          type="danger"
        />
        <m-button
          text="dark"
          type="dark"
        />
      </div>

      <h3>Непрозрачные (fill)</h3>
      <div>
        <m-button
          text="text"
          type="text"
          button-style="fill"
        />
        <m-button text="default" />
        <m-button
          text="primary"
          type="primary"
          button-style="fill"
        />
        <m-button
          text="success"
          type="success"
          button-style="fill"
        />
        <m-button
          text="warning"
          type="warning"
          button-style="fill"
        />
        <m-button
          text="danger"
          type="danger"
          button-style="fill"
        />
        <m-button
          text="dark"
          type="dark"
          button-style="fill"
        />
      </div>

      <h3>Закруглённые (round)</h3>
      <div>
        <m-button
          text="text"
          type="text"
          button-style="round"
        />
        <m-button
          text="default"
          button-style="round"
        />
        <m-button
          text="primary"
          type="primary"
          button-style="round"
        />
        <m-button
          text="success"
          type="success"
          button-style="round"
        />
        <m-button
          text="warning"
          type="warning"
          button-style="round"
        />
        <m-button
          text="danger"
          type="danger"
          button-style="round"
        />
        <m-button
          text="dark"
          type="dark"
          button-style="round"
        />
      </div>

      <h3>Круглые (circle)</h3>
      <div>
        <m-button
          icon="copy"
          type="text"
          button-style="circle"
        />
        <m-button
          icon="send"
          button-style="circle"
        />
        <m-button
          icon="clear"
          type="primary"
          button-style="circle"
        />
        <m-button
          icon="add"
          type="success"
          button-style="circle"
        />
        <m-button
          icon="edit"
          type="warning"
          button-style="circle"
        />
        <m-button
          icon="delete"
          type="danger"
          button-style="circle"
        />
        <m-button
          icon="print"
          type="dark"
          button-style="circle"
        />
      </div>

      <h2>Кнопки с иконками и текстом</h2>
      <div class="elements-section">
        <m-button
          template="save"
        />

        <m-button
          text="Сохранить"
          type="warning"
          icon="save"
        />

        <m-button
          text="Редактировать"
          type="warning"
          icon="change"
        />

        <m-button
          text="Удалить"
          type="danger"
          icon="delete"
        />

        <m-button
          text="Закрыть"
          type="primary"
          icon="close"
        />
      </div>
      <div>
        <m-button
          text="Сохранить и записать на прием"
          type="warning"
          icon="download"
        />

        <m-button
          text="Очистить"
          type="primary"
          icon="clear"
        />

        <m-button
          text="Обновить"
          type="primary"
          icon="reset"
        />

        <m-button
          text="Печать"
          type="dark"
          icon="print"
        />
      </div>

      <h2>Кнопки с иконками</h2>
      <div>
        <m-button
          template="short_save"
          plus-icon
        />

        <m-button
          template="short_edit"
        />

        <m-button
          type="danger"
          tooltip="Удалить"
          icon="delete"
        />

        <m-button
          type="primary"
          tooltip="Закрыть"
          icon="close"
        />

        <m-button
          type="primary"
          tooltip="Отправить"
          icon="send"
        />

        <m-button
          type="primary"
          tooltip="Очистить"
          icon="clear"
        />

        <m-button
          type="default"
          tooltip="Сбросить"
          no-use-fw
          icon="no-filter"
          icon-color="grey-dark"
        />

        <m-button
          type="default"
          tooltip="Обновить"
          no-use-fw
          icon="reset"
          icon-color="grey"
        />

        <m-button
          type="dark"
          tooltip="Печать"
          no-use-fw
          icon="print"
        />
      </div>

      <h2>Кнопки mini</h2>
      <div class="elements-section">
        <m-button
          text="text"
          type="text"
          size="mini"
        />
        <m-button
          text="default"
          size="mini"
        />
        <m-button
          text="primary"
          type="primary"
          size="mini"
        />
        <m-button
          text="success"
          type="success"
          size="mini"
        />
        <m-button
          text="warning"
          type="warning"
          size="mini"
        />
        <m-button
          text="danger"
          type="danger"
          size="mini"
        />
        <m-button
          text="dark"
          type="dark"
          size="mini"
        />
      </div>

      <div class="elements-section">
        <m-button
          text="text"
          type="text"
          size="mini"
          button-style="fill"
        />
        <m-button
          text="default"
          size="mini"
          button-style="fill"
        />
        <m-button
          text="primary"
          type="primary"
          size="mini"
          button-style="fill"
        />
        <m-button
          text="success"
          type="success"
          size="mini"
          button-style="fill"
        />
        <m-button
          text="warning"
          type="warning"
          size="mini"
          button-style="fill"
        />
        <m-button
          text="danger"
          type="danger"
          size="mini"
          button-style="fill"
        />
        <m-button
          text="dark"
          type="dark"
          size="mini"
          button-style="fill"
        />
      </div>

      <div class="elements-section">
        <m-button
          text="text"
          type="text"
          size="mini"
          button-style="round"
        />
        <m-button
          text="default"
          size="mini"
          button-style="round"
        />
        <m-button
          text="primary"
          type="primary"
          size="mini"
          button-style="round"
        />
        <m-button
          text="success"
          type="success"
          size="mini"
          button-style="round"
        />
        <m-button
          text="warning"
          type="warning"
          size="mini"
          button-style="round"
        />
        <m-button
          text="danger"
          type="danger"
          size="mini"
          button-style="round"
        />
        <m-button
          text="dark"
          type="dark"
          size="mini"
          button-style="round"
        />
      </div>

      <div class="elements-section">
        <m-button
          template="save"
          size="mini"
        />

        <m-button
          text="Сохранить"
          type="warning"
          icon="save"
          size="mini"
        />

        <m-button
          text="Редактировать"
          type="warning"
          icon="change"
          size="mini"
        />

        <m-button
          text="Удалить"
          type="danger"
          icon="delete"
          size="mini"
        />

        <m-button
          text="Закрыть"
          type="primary"
          icon="close"
          size="mini"
        />
      </div>

      <div class="mb-15">
        <m-button
          text="Сохранить и записать на прием"
          type="warning"
          icon="download"
          size="mini"
        />

        <m-button
          text="Очистить"
          type="primary"
          icon="clear"
          size="mini"
        />

        <m-button
          text="Обновить"
          type="primary"
          icon="reset"
          size="mini"
        />

        <m-button
          text="Печать"
          type="dark"
          icon="print"
          size="mini"
        />
      </div>

      <div>
        <m-button
          template="short_save"
          plus-icon
          size="mini"
        />

        <m-button
          template="short_edit"
          size="mini"
        />

        <m-button
          type="danger"
          tooltip="Удалить"
          icon="delete"
          size="mini"
        />

        <m-button
          type="primary"
          tooltip="Закрыть"
          icon="close"
          size="mini"
        />

        <m-button
          type="primary"
          tooltip="Отправить"
          icon="send"
          size="mini"
        />

        <m-button
          type="primary"
          tooltip="Очистить"
          icon="clear"
          size="mini"
        />

        <m-button
          type="default"
          tooltip="Сбросить"
          no-use-fw
          icon="no-filter"
          icon-color="grey"
          size="mini"
        />

        <m-button
          type="default"
          tooltip="Обновить"
          no-use-fw
          icon="reset"
          icon-color="grey"
          size="mini"
        />

        <m-button
          type="dark"
          tooltip="Печать"
          no-use-fw
          icon="print"
          size="mini"
        />
      </div>
    </div>

    <div class="flex-grow-1">
      <h1>Данные</h1>
      <h2>Основные пропсы</h2>
      <pre class="width-fit-content">
disabled: [Boolean, String, Object] // см. DisabledMixin.js
buttonStyle: PropsTypes.String()  // доступные стили: 'plain', 'round', 'circle', 'fill'
type: PropsTypes.String()  // список доступных типов ниже
size: PropsTypes.String('small')
loading: Boolean
text: PropsTypes.String()
icon: PropsTypes.String()
noUseFw: Boolean  // для кастомных иконок
plusIcon: Boolean  //  добавляет иконку '+'
iconColor: PropsTypes.String()
template: PropsTypes.String()  //  добавляет кнопку по готовому шаблону,
не нужно указывать type, text, icon
tooltip: PropsTypes.String()
active: Boolean</pre>

      <h2>Типы кнопок</h2>
      <pre class="width-fit-content">
'primary'
'success'
'warning'
'danger'
'info'
'text'
'default'
'dark'</pre>

      <h2>Шаблоны кнопок</h2>
      <pre class="width-fit-content">
save
save_orange
edit
delete
close
save_and_appointment
clear
refresh
short_edit
short_delete
short_close
short_save_and_appointment
short_clear
short_refresh
      </pre>
    </div>
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton'

export default {
  name: 'FrontBookPageMButton',
  components: { MButton },
}
</script>
