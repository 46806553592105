<template>
  <div class="fixed-document-block">
    <div class="flex gap-indent-small">
      <!-- Период -->
      <m-date-picker
        :value="certificate.period"
        :label="t('period')"
        :disabled="isAnnulling"
        type="daterange"
        :required="isDateRequired"
        value-format="yyyy-MM-dd"
        validator-name="period"
        @change="setValue('period', $event)"
        @registerValidator="onRegisterValidator($event); $registerValidator($event);"
      />

      <!-- Клиника -->
      <reusable-doctor-clinics-list
        v-tooltip="certificate.clinic.legalName"
        :value="certificate.clinic.id"
        :disabled="!isPrimary"
        :required="isPrimary"
        :clearable="false"
        use-custom-result="simple"
        validator-name="clinic"
        @onClinicChange="setValue('clinic', $event)"
        @registerValidator="onRegisterValidator($event); $registerValidator($event);"
      />

      <!-- Пациент -->
      <m-select-lazy
        v-tooltip="clientValue.shortInfo"
        :value="clientValue"
        :label="t('client')"
        :fetch-method="(params) => certificate.clientSearch(params)"
        filterable
        option-label="shortInfo"
        :disabled="!isPrimary"
        :clearable="false"
        :required="isPrimary"
        validator-name="client"
        @changeByLazy="setValue('client', $event)"
        @registerValidator="onRegisterValidator($event); $registerValidator($event);"
        @syncItems="clientSyncItems = $event"
      >
        <template #options="{ valueKey, optionLabel }">
          <el-option-group :label="t$('clientsWithOrders')">
            <el-option
              v-for="(option) in clientItems"
              :key="option[valueKey]"
              :label="option[optionLabel]"
              :value="option"
            />
          </el-option-group>

          <el-option-group
            v-if="clientItemsDisplayed.length"
            :label="t$('otherClients')"
          >
            <el-option
              v-for="(option) in clientItemsDisplayed"
              :key="option[valueKey]"
              :label="option[optionLabel]"
              :value="option"
            />
          </el-option-group>
        </template>
      </m-select-lazy>

      <!-- Тип справки -->
      <m-select
        :value="selectedCertificateType"
        :items="certificateTypes"
        :clearable="false"
        :disabled="isEdit"
        use-custom-result="simple"
        @change="certificate.setValue('type', $event)"
      />
    </div>

    <!-- Исходная справка -->
    <m-select
      :value="certificate.id"
      :label="t$('originalCertificate')"
      :disabled="isPrimary || isEdit"
      :items="certificate.certificates"
      :required="!isPrimary && !isEdit"
      validator-name="originalCertificate"
      full-width
      use-custom-result="simple"
      @change="setValue('id', $event)"
      @registerValidator="onRegisterValidator($event); $registerValidator($event);"
    />

    <div class="flex justify-content-between">
      <div>
        <!-- Не учитывать платежи без распечатанных чеков -->
        <m-checkbox
          :label="t$('ignorePaymentsWithoutPrintedReceipts')"
          :value="certificate.ignorePaymentsWithoutPrintedReceipts"
          :disabled="isAnnulling"
          @input="setValue('ignorePaymentsWithoutPrintedReceipts', $event)"
        />

        <!-- Услуги были оказаны индивидуальным предпринимателем -->
        <m-checkbox
          :label="t$('servicesProvidedByAnIndividualEntrepreneur')"
          :value="certificate.individualEntrepreneur"
          :disabled="!isPrimary"
          @input="certificate.setValue('individualEntrepreneur', $event)"
        />
      </div>

      <m-button
        :disabled="certificate.isCertificateLoaded"
        template="confirm"
        @click="confirm"
      />
    </div>
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  CERTIFICATE_TYPE,
  getCertificateTypes,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  TaxCertificate2024EditorMainBlockValidationMixin,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/mixins/validationMixins/TaxCertificate2024EditorMainBlockValidationMixin'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { extractId } from '@/_medods_standart_library/msl'

export default {
  name: 'TaxCertificate2024EditorMainBlock',

  components: {
    MSelectLazy,
    ReusableDoctorClinicsList,
    MDatePicker,
    MSelect,
    MCheckbox,
    MButton,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    TaxCertificate2024EditorMainBlockValidationMixin,
  ],

  props: {
    certificate: TaxCertificate2024,

    isPrimary: Boolean,
    isCorrective: Boolean,
    isAnnulling: Boolean,
    isEdit: Boolean,
  },

  data () {
    return {
      CERTIFICATE_TYPE,
      certificateTypes: [],
      clientSyncItems: [],
    }
  },

  computed: {
    isDateRequired () {
      return !this.isAnnulling
        ? this.periodValidator
        : false
    },

    selectedCertificateType () {
      return {
        id: this.certificate.type,
        title: this.t$(CERTIFICATE_TYPE[this.certificate.type]),
      }
    },

    clientValue () {
      return this.certificate.taxpayerIsClient
        ? this.certificate.taxpayer
        : this.certificate.client
    },

    clientItems () {
      return [
        this.certificate.taxpayer,
        ...(this.certificate.representedPersons ?? []),
      ]
    },

    clientItemsIds () {
      return this.clientItems.map(extractId)
    },

    clientItemsDisplayed () {
      return this.clientSyncItems.reduce((acc, item) =>
        this.clientItemsIds.includes(item.id)
          ? acc
          : [...acc, item]
      , [])
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')

    this.certificateTypes = getCertificateTypes(this.i18nScope)
  },

  methods: {
    async confirm () {
      if (this.hasErrors()) { return }

      if (this.isPrimary || this.isEdit) {
        return await this.certificate.fetchCalculatedSum()
      }

      await this.certificate.fetchDocument()
    },

    setValue (name, value) {
      this.certificate.setValue('isCertificateLoaded', false)

      this.certificate.setValue(name, value)
    },
  },
}
</script>
