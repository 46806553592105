export const baseValidationMixin = {
  data () {
    return {
      validationMessages: {
        // override
      },
    }
  },

  methods: {
    validate () {
      throw new Error('Override required')
    },

    generateWatchers (parentObjectName) {
      const getField = (field) =>
        parentObjectName
          ? `${parentObjectName}.${field}`
          : field

      Object.keys(this.validationMessages).forEach((key) => {
        const field = getField(key)

        this.$watch(
          field,
          (to) => {
            if (!to) { return }
            this.clearMessages(key)
          }
        )
      })
    },

    clearMessages (key) {
      if (key) {
        this.validationMessages[key] = []

        return
      }

      Object.keys(this.validationMessages).forEach((key) => {
        this.validationMessages[key] = []
      })
    },

    allCorrect (validations = []) {
      this.clearMessages()

      return validations.reduce((allCorrect, item) => {
        if (!item.rule) {
          this.validationMessages[item.key].push(item.falseMessage)
        }

        return allCorrect && item.rule
      }, true)
    },
  },
}
