<template>
  <div class="medical-policy-editor__form">
    <!-- Прикрепление  -->
    <m-switch
      class="mb-indent-validation"
      :value="medicalPolicy.active"
      :active-text="t('activerecord.attributes.medical_policy.active')"
      @change="medicalPolicy.setValue('active', $event)"
    />

    <!-- Серия + номер -->
    <div class="series-with-number">
      <m-input
        class="series-with-number__series"
        :label="t('series')"
        :value="medicalPolicy.omsSeries"
        @input="medicalPolicy.setValue('omsSeries', $event)"
      />
      <m-input
        class="series-with-number__number"
        :value="medicalPolicy.number"
        :label="t('common.number')"
        required
        validator-name="number"
        @registerValidator="$registerValidator($event)"
        @input="medicalPolicy.setValue('number', $event)"
      />
    </div>

    <!-- Программа страхования -->
    <m-input
      :label="t('activerecord.attributes.medical_policy.program')"
      :value="medicalPolicy.program"
      @input="medicalPolicy.setValue('program', $event)"
    />

    <!-- Страховая компания -->
    <company-search-create
      :value="medicalPolicy.insuranceCompany"
      :label="t('activerecord.attributes.medical_policy.insurance_company')"
      company-type="insurance"
      use-first-time-fetch
      required
      validator-name="insurance_company"
      @registerValidator="$registerValidator($event)"
      @change="medicalPolicy.setValue('insuranceCompany', $event)"
      @onCreateCompany="medicalPolicy.setValue('insuranceCompany', $event)"
    />

    <!-- Организация-страхователь -->
    <company-search-create
      use-first-time-fetch
      :label="t('activerecord.attributes.medical_policy.company')"
      :value="medicalPolicy.company"
      @change="medicalPolicy.setValue('company', $event)"
      @onCreateCompany="medicalPolicy.setValue('company', $event)"
    />

    <!-- Тип полиса + нси -->
    <div class="policy-types">
      <m-buttons-group
        :value="medicalPolicy.policyType"
        :items="omsTypes"
        radio
        @change="onMedicalPolicyTypeChange"
      />

      <div class="policy-types__nsi">
        <reusable-local-n-s-i-list
          v-if="egiszEnabled && medicalPolicy.policyType === GLOBAL_MEDICAL_POLICY_KINDS.OMS"
          class="oms-type__nsi"
          dictionary="oms_types"
          version="1.3"
          :value="medicalPolicy.nsiOmsType"
          :label="t('activerecord.attributes.medical_policy.omsType')"
          :placeholder="t('activerecord.attributes.medical_policy.omsTypePlaceholder')"
          full-width
          :required="egiszEnabled"
          validator-name="oms_type"
          @registerValidator="$registerValidator($event)"
          @changeByLazy="onChangeNsiOmsType"
        />

        <!-- Период -->
        <div class="policy-types__dates">
          <m-date-picker
            v-show="isUnlimited"
            value-format="yyyy-MM-dd"
            :value="medicalPolicy.dateStart"
            :label="t('date_of_start')"
            :full-width="false"
            required
            validator-name="date_start"
            @change="medicalPolicy.setValue('dateStart', $event)"
            @registerValidator="$registerValidator($event)"
          />
          <m-date-picker
            v-if="!isUnlimited"
            v-model="policyPeriod"
            type="daterange"
            :label="t('period')"
            :required="dateValidator()"
            class="w-100"
            validator-name="date_end"
            value-format="yyyy-MM-dd"
            @registerValidator="$registerValidator($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { MedicalPolicyLogic } from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/MedicalPolicyLogic'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import CompanySearchCreate
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanySearchCreate.vue'
import { getMedicalPolicyTypes } from '@/vue_apps/ClientsModule/components/MedicalPolices/const/getMedicalPolicyTypes'
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import { GLOBAL_MEDICAL_POLICY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { isUnlimited } from '@/vue_apps/ClientsModule/components/MedicalPolices/const/isUnlimited'

export default defineComponent({
  name: 'MedicalPolicyForm',
  components: {
    MDatePicker,
    MSwitch,
    ReusableLocalNSIList,
    MButtonsGroup,
    CompanySearchCreate,
    MInput,
  },

  props: {
    medicalPolicy: MedicalPolicyLogic,
  },

  emits: [
    'changePolicyType',
  ],

  data () {
    return {
      omsTypes: getMedicalPolicyTypes(),
      egiszEnabled: gon.application.egisz_module_enabled,
      GLOBAL_MEDICAL_POLICY_KINDS,
    }
  },

  computed: {
    policyPeriod: {
      get () {
        if (!this.medicalPolicy.dateStart) { return null }

        return [
          this.medicalPolicy.dateStart,
          this.medicalPolicy.dateEnd,
        ]
      },

      set (value) {
        this.medicalPolicy.setValue('dateStart', value?.[0])
        this.medicalPolicy.setValue('dateEnd', value?.[1])
      },
    },

    isUnlimited () {
      return isUnlimited(this.medicalPolicy.nsiOmsType?.title)
    },

    showPeriod () {
      const oldIndefiniteOms = this.medicalPolicy.indefinite &&
        this.medicalPolicy.policyType === GLOBAL_MEDICAL_POLICY_KINDS.OMS

      return !this.medicalPolicy.indefinite || oldIndefiniteOms
    },
  },

  methods: {
    onChangeNsiOmsType (value) {
      const oldNsiOmsType = this.medicalPolicy.nsiOmsType?.title
      this.medicalPolicy.setValue('nsiOmsType', value)

      if (isUnlimited(oldNsiOmsType)) {
        this.medicalPolicy.setValue('dateStart', null)
        this.medicalPolicy.setValue('dateEnd', null)
      }

      if (!isUnlimited(this.medicalPolicy.nsiOmsType?.title)) {
        this.medicalPolicy.setValue('indefinite', false)

        return
      }

      this.medicalPolicy.setIndefinite()
    },

    dateValidator () {
      const vm = this

      return (value) => {
        if (!vm.showPeriod) {
          return Utils.getValidationErrors(value)
        }

        if (vm.medicalPolicy.indefinite) { return [] }

        return Utils.getValidationErrors(value)
      }
    },

    onMedicalPolicyTypeChange (value) {
      this.medicalPolicy.setValue('policyType', value.id)
      this.$emit('changePolicyType', value.id)
    },
  },
})
</script>
