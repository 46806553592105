<template>
  <panel-heading
    class="egisz-case__card egisz-case__card_conclusion"
    :title="t('egisz.medical_cases.form.conclusion_title')"
    icon="fad fa-fw fa-clipboard-list-check"
  >
    <div class="row">
      <div class="col-xs-6">
        <div class="flex-form flex-form__35-65 egisz-case__flex-form">
          <div class="form-label egisz-case__form-label reset-paddings">
            {{ t('egisz.conclusion.disease_result') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value reset-paddings pb-0">
            <simple-select
              :items="getDiseaseResults"
              :value="caseResultId"
              :set-first="DEFAULT_FIRST.CASE_RESULT"
              :validation-messages="validationMessagesDiagnosis.result || []"
              @change="$updateSync('caseResultId', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.med_records.modal.diagnosis.disease_id') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <mkb10
              :value="diagnosisId"
              :validation-messages="validationMessagesDiagnosis.disease || []"
              @change="$updateSync('diagnosisId', $event)"
              @setInfo="$updateSync('diagnosisInfo', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.med_records.modal.diagnosis.diagnosis_type_id') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <simple-select
              :value="diagnosisType"
              :items="getMedRecordDiagnosisTypes"
              :set-first="DEFAULT_FIRST.CASE_MAIN_DIAGNOSIS_TYPE"
              @change="$updateSync('diagnosisType', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.med_records.modal.diagnosis.date') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <validation-wrapper
              :errors="validationMessagesDiagnosis.date || []"
              fixed-height
            >
              <date-picker
                class="conclusion__diagnosis-date"
                :value="diagnosisDate"
                @input="$updateSync('diagnosisDate', $event)"
              />
            </validation-wrapper>
          </div>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="flex-form flex-form__35-65 egisz-case__flex-form">
          <div class="form-label egisz-case__form-label">
            {{ t('egisz.med_records.modal.diagnosis.comment') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value pt-15">
            <validation-wrapper
              :errors="validationMessagesDiagnosis.comment || []"
              fixed-height
            >
              <el-input
                type="textarea"
                :value="diagnosisComment"
                :rows="3"
                :maxlength="MAX_DIAGNOSIS_COMMENT_LENGTH"
                :autosize="{ minRows: 3, maxRows: 30}"
                @input="$updateSync('diagnosisComment', $event.substring(0, MAX_DIAGNOSIS_COMMENT_LENGTH))"
              />
            </validation-wrapper>
          </div>
        </div>
      </div>
    </div>

    <label class="flex flex-column conclusion__conclusion-text">
      <span class="bold">{{ t('egisz.conclusion.title') }}<required-mark /></span>
      <validation-wrapper :errors="validationMessages.comment || []">
        <el-input
          type="textarea"
          :value="conclusionText"
          :rows="3"
          :placeholder="t('egisz.conclusion.conclusion_text')"
          :autosize="{ minRows: 3, maxRows: 12}"
          @input="$updateSync('conclusionText', $event)"
        />
      </validation-wrapper>
    </label>
  </panel-heading>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import DatePicker from '@/vue_components/common/select/date_picker'
import Mkb10 from '@/vue_components/Mkb10/Mkb-10'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import { mapGetters } from 'vuex'
import { DEFAULT_FIRST } from '@/vue_components/egisz/egisz_module/const/set_first_const'
import { MAX_DIAGNOSIS_COMMENT_LENGTH } from '@/vue_components/egisz/egisz_module/const/const'

export default {
  name: 'CardConclusion',
  components: { RequiredMark, ValidationWrapper, Mkb10, DatePicker, SimpleSelect, PanelHeading },
  props: {
    caseResultId: {
      type: String,
      default: null,
    },

    diagnosisId: {
      type: [String, Number],
      default: null,
    },

    diagnosisType: {
      type: String,
      default: null,
    },

    diagnosisDate: {
      type: Date,
      default: null,
    },

    diagnosisComment: {
      type: String,
      default: null,
    },

    conclusionText: {
      type: String,
      default: null,
    },

    validationMessages: {
      type: Object,
      default: () => ({}),
    },

    readOnly: Boolean,
  },

  data () {
    return {
      DEFAULT_FIRST,
      MAX_DIAGNOSIS_COMMENT_LENGTH,
    }
  },

  computed: {
    ...mapGetters('medCases', {
      getDiseaseResults: 'GET_DISEASE_RESULTS',
      getMedRecordDiagnosisTypes: 'GET_MED_RECORD_DIAGNOSIS_TYPES',
    }),

    validationMessagesDiagnosis () {
      return (this.validationMessages && this.validationMessages.diagnosis) || {}
    },
  },
}
</script>
