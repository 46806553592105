<template>
  <div class="nps-section">
    <si>
      <si-control
        :disabled="waitingLoadNPSDynamics"
        @reset="resetFilters"
      >
        <date-range-picker
          v-model="selectedDateRange"
          class="component"
          :disabled="waitingLoadNPSDynamics"
          :application-date-format="dateFormat"
        />

        <base-select
          v-model="selectedNpsCalculationStep"
          class="component"
          :disabled="waitingLoadNPSDynamics"
          :placeholder="T.client_feedbacks.NPS.calculation_step"
        >
          <el-option
            v-for="range in npsCalculationSteps"
            :key="range.value"
            :value="range.value"
            :label="range.title"
          />
        </base-select>

        <reusable-doctor-clinics-list
          :value="selectedClinic"
          :disabled="waitingLoadNPSDynamics"
          class="mr-5"
          label=""
          :m-fixed-height="false"
          @onClinicChange="setClinic"
        />

        <template #refresh>
          <span />
        </template>
      </si-control>

      <div class="nps-dynamics">
        <epic-spinner :visibility="waitingLoadNPSDynamics" />
        <div id="chart_container" />
      </div>
    </si>
  </div>
</template>

<script>
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'

import { mapActions, mapGetters } from 'vuex'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'

const LOCAL_STATE_STORAGE_KEY = 'nps_dynamics_state'

const localState = window.store.get(LOCAL_STATE_STORAGE_KEY) || {}

const NPS_CACLCULATION_STEPS = {
  MONTH: 2,
  QUARTER: 4,
  YEAR: 6,
}

const DEFAULT_LOCAL_STATE = {
  SELECTED_CLINIC_ID: null,
  SELECTED_NPS_CALCULATION_STEP: NPS_CACLCULATION_STEPS.MONTH,
  SELECTED_DATE_RANGE: [
    moment().subtract(1, 'years').startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ],
}

export default {
  name: 'Nps',
  components: {
    ReusableDoctorClinicsList,
    Si,
    SiControl,
    DateRangePicker,
    EpicSpinner,
    BaseSelect,
  },

  data () {
    return {
      localState: {
        selectedNpsCalculationStep:
            localState.selectedNpsCalculationStep === undefined
              ? DEFAULT_LOCAL_STATE.SELECTED_NPS_CALCULATION_STEP
              : localState.selectedNpsCalculationStep,

        selectedClinic:
            localState.selectedClinic === undefined
              ? DEFAULT_LOCAL_STATE.SELECTED_CLINIC_ID
              : localState.selectedClinic,

        selectedDateRange:
            Array.isArray(localState.selectedDateRange)
              ? [moment(localState.selectedDateRange[0]).toDate(),
                  moment(localState.selectedDateRange[1]).toDate()]
              : localState.selectedDateRange,
      },
      npsCalculationSteps: [
        { value: NPS_CACLCULATION_STEPS.MONTH, title: T.month },
        { value: NPS_CACLCULATION_STEPS.QUARTER, title: T.quarter },
        { value: NPS_CACLCULATION_STEPS.YEAR, title: window.capitaliseFirstLetter(T.year) },
      ],

      waitingLoadNPSDynamics: false,
      dateFormat: gon.localization.date_format,
    }
  },

  computed: {
    ...mapGetters([
      'GET_CURRENT_USER_CLINICS',
      'GET_NPS_DYNAMICS',
    ]),

    selectedClinic () {
      return this.localState.selectedClinic
    },

    selectedNpsCalculationStep: {
      set (step) {
        this.localState.selectedNpsCalculationStep = step
      },
      get () {
        return this.localState.selectedNpsCalculationStep
      },
    },

    selectedDateRange: {
      set (dateRange) {
        this.localState.selectedDateRange = dateRange
      },
      get () {
        return this.localState.selectedDateRange
      },
    },

    sortParams () {
      const params = {}

      if (this.selectedClinic?.id) {
        params.clinic_id = this.selectedClinic.id
      }

      if (this.selectedDateRange) {
        params.start_date = this.selectedDateRange[0]
        params.end_date = this.selectedDateRange[1]
      }

      if (this.selectedNpsCalculationStep) {
        params.nps_calculation_step = this.selectedNpsCalculationStep
      }

      return params
    },
  },

  watch: {
    localState: {
      deep: true,
      handler (newValue) {
        store.set(LOCAL_STATE_STORAGE_KEY, newValue)
      },
    },

    sortParams () {
      this.loadNPSDynamics()
    },
  },

  mounted () {
    if (this.GET_NPS_DYNAMICS.length > 0) {
      this.buildNPSDynamics()

      return
    }

    this.loadNPSDynamics()
  },

  methods: {
    ...mapActions({
      LOAD_NPS_DYNAMICS: 'LOAD_NPS_DYNAMICS',
    }),

    setClinic (clinic) {
      this.localState.selectedClinic = clinic
    },

    loadNPSDynamics () {
      this.waitingLoadNPSDynamics = true

      return this
        .LOAD_NPS_DYNAMICS(this.sortParams)
        .catch(console.error)
        .then(this.buildNPSDynamics)
        .finally(() => {
          this.waitingLoadNPSDynamics = false
        })
    },

    buildNPSDynamics () {
      Report.drawChart.npsDynamics(this.GET_NPS_DYNAMICS)
    },

    resetFilters () {
      this.selectedNpsCalculationStep =
          DEFAULT_LOCAL_STATE.SELECTED_NPS_CALCULATION_STEP

      this.setClinic(DEFAULT_LOCAL_STATE.SELECTED_CLINIC_ID)

      this.selectedDateRange =
          DEFAULT_LOCAL_STATE.SELECTED_DATE_RANGE
    },
  },
}

</script>
