<template>
  <div class="emd-form remove-margin">
    <div class="flex-form flex-form__35-65 validated__flex-form">
      <div class="form-label validated__form-label reset-paddings">
        {{ t('egisz.components.egisz_med_documents.form.doctor') }}
      </div>
      <div class="form-value validated__form-value reset-paddings">
        <span>{{ authorFullName }}</span>
      </div>

      <div class="form-label validated__form-label">
        {{ t('egisz.components.egisz_med_documents.form.type') }}
        <required-mark />
      </div>
      <div class="form-value validated__form-value">
        <simple-select
          :items="getMedDocumentTypes"
          :validation-messages="validationMessages.typeId || []"
          :value="typeId"
          clearable
          filterable
          @change="$updateSync('typeId', $event)"
        />
      </div>

      <div class="form-label validated__form-label">
        {{ t('egisz.components.egisz_med_documents.form.created_at') }}
        <required-mark />
      </div>
      <div class="form-value validated__form-value">
        <validation-wrapper
          :errors="validationMessages.date || []"
          fixed-height
        >
          <date-picker
            :value="date"
            @input="$updateSync('date', $event)"
          />
        </validation-wrapper>
      </div>

      <div class="form-label validated__form-label">
        {{ t('egisz.components.egisz_med_documents.form.header') }}
        <required-mark />
      </div>
      <div class="form-value validated__form-value">
        <validation-wrapper
          :errors="validationMessages.header || []"
          fixed-height
        >
          <el-input
            type="textarea"
            :value="header"
            :rows="2"
            :placeholder="t('egisz.components.egisz_med_documents.form.header_limit')"
            :maxlength="HEADER_LIMIT"
            :autosize="{ minRows: 2, maxRows: 6 }"
            @input="$updateSync('header', $event)"
          />
        </validation-wrapper>
      </div>

      <div class="form-label validated__form-label" />
      <div class="form-value validated__form-value">
        <a
          v-if="entryRoute"
          :href="entryRoute"
          target="_blank"
        >{{ t('egisz.move_to_entry') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/vue_components/common/select/date_picker'
import { HEADER_LIMIT } from '@/vue_components/egisz/egisz_med_document/const'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { mapGetters } from 'vuex'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'EgiszMedDocumentForm',
  components: { SimpleSelect, RequiredMark, ValidationWrapper, DatePicker },
  mixins: [SpinnerHolder],

  props: {
    entryId: {
      type: Number,
      default: null,
    },

    id: {
      type: Number,
      default: null,
    },

    authorId: {
      type: Number,
      default: null,
    },

    authorFullName: {
      type: String,
      default: '',
    },

    date: {
      type: Date,
      default: null,
    },

    header: {
      type: String,
      default: '',
    },

    typeId: {
      type: String,
      default: null,
    },

    validationMessages: {
      type: Object,
      default: () => ({}),
    },
  },

  data () {
    return {
      HEADER_LIMIT,
    }
  },

  computed: {
    ...mapGetters('egiszEmd', {
      getMedDocumentTypes: 'GET_MED_DOCUMENT_TYPES',
    }),

    entryRoute () {
      return this.entryId
        ? this.$routes.entry_path(this.entryId)
        : ''
    },
  },
}
</script>
