import { MSemdModel } from '@/vue_apps/Semds/entities/_SemdBase/MSemdModel'
import { ISemd } from '@/_declarations/ISemd'
import { ISemd119MetaData } from '@/vue_apps/Semds/entities/Semd119/ISemd119MetaData'
import {
  ISemd119SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd119/ISemd119SemdsDocumentDetailsAttributesData'
import { getCurrentDate, SEMD_STATE_ENUM } from '@/vue_apps/Semds/SemdModal/const/const'
import { ISemdSource } from '@/vue_apps/Semds/interfaces/ISemdSource'
import { pick } from 'lodash'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class MSemdLogic <TMetaData, TSemdsDocumentDetailsAttributesData>
  extends MSemdModel<TMetaData, TSemdsDocumentDetailsAttributesData> {
  fillBySource (source: ISemdSource) {
    if (!source?.sourceType) {
      throw new Error('sourceType is required')
    }

    this.setTitle(source)
    this.setValue('date', getCurrentDate())
    this.setValue('owner', {
      id: source.id,
      title: source.title,
      type: source.sourceType,
    })
    this.setValue('client', pick(source.client, ['id', 'fullName']))
    this.setValue('clinic', pick(source.clinic, ['id', 'title']))
    this.setMetaDataValue('doctor', pick(source.doctor, ['id', 'fullName']))
    this.setDocumentDetailsId(source.semdsDocumentDetailsAttributes.id)
    this.setValue('semdsDocumentDetailsAttributes.data', source.semdsDocumentDetailsAttributes.data || {})
  }

  fillByLastSemd (semd: ISemd<ISemd119MetaData, ISemd119SemdsDocumentDetailsAttributesData>) {
    this._fillProps({
      ...semd,
      id: null,
      state: SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE,
      doctorSigner: null,
      organizationSigner: null,
      netrikaErrors: [],
    })

    this.increaseVersion()
  }

  protected setTitle (source: ISemdSource) {
    const date = source.date
      ? vueFilters.date(source.date)
      : ''

    this.setValue(
      'title',
      window.Utils.joinTruthy([source.title, `№ ${source.id}`, date], ', ')
    )
  }
}
