import { validate } from '@/lib/validators'

/**
 * JSDoc не может в рекурсивные объявления в таких случаях, тут вложенность может быть любая
 * @typedef {Record<string, string[]|Record<string, string[]>>} ValidationMessages
 *
 * Эта миксина позволяет установить сообщения с ошибками валидации в компоненте.
 * Чтобы не забыть установить дефолтное значение для валидаций при обработке
 * ошибок с сервера, нужно пользоваться методом `setValidationMessages`.
 * В него передаётся объект, хранящий валидации в компоненте (обычно поле validationMessages),
 * и объект с ошибками с сервера.
 *
 * В компоненте можно объявить поле или computed `skipValidation` для пропуска валидации.
 * Для пропуска валидации полей, объявленных через watch, в методе очистки полей формы нужно использовать метод `skipNextValidations`.
 *
 * Пример использования миксины в waiting_edit_form.vue.
 */
export const ValidationHolder = {
  methods: {
    /**
     * @param {ValidationMessages} target
     * @param {Record<string, string[]>} errors
     */
    setValidationMessages (target, errors) {
      Object.keys(target)
        .forEach((key) => {
          if (Array.isArray(target[key])) {
            target[key] = errors[key] || []
          } else {
            this.setValidationMessages(target[key], errors)
          }
        })
    },

    /**
     * @param {string} key Имя поля в validationMessages
     * @param {any} value Обрабатываемое значение
     * @param {Validator} validator
     * @param {ValidationMessages} messagesStore Ссылка на объект с валидациями
     */
    validate (key, value, validator, messagesStore = this.validationMessages) {
      if (this.skipValidation) return

      this.$set(messagesStore, key, validator(value))
    },

    /**
     * @param {string} key Имя поля в validationMessages
     * @param {any} value Обрабатываемое значение
     * @param {ValidationRule<any>[]} rules Массив правил валидации
     * @param {Record<string, string>} messages Словарь ошибок валидации
     * @param {ValidationMessages} messagesStore Ссылка на объект с валидациями
     */
    validateRules (key, value, rules, messages, messagesStore = this.validationMessages) {
      if (this.skipValidation) return

      this.$set(messagesStore, key, validate(value, rules, messages))
    },

    hasErrors (validationMessages = this.validationMessages) {
      if (!validationMessages) {
        console.error(`Validation messages object is invalid.
      Given: ${JSON.stringify(validationMessages)}, needed object instead`)
      } else {
        return Object
          .values(validationMessages)
          .flatMap((messages) => {
            return Array.isArray(messages)
              ? messages
              : Object.values(messages)
          })
          .some((el) => el.length > 0)
      }
    },

    removeValidationMessage (key, message, validationMessages = this.validationMessages) {
      if (!validationMessages[key].length) return

      const index = validationMessages[key].indexOf(message)
      if (index >= 0) {
        validationMessages[key].splice(index, 1)
      }
    },

    skipNextValidations () {
      this.skipValidation = true
      this.$nextTick(() => {
        this.skipValidation = false
      })
    },
  },
}
