<template>
  <div class="appoinment-notify">
    <div
      v-if="notificationsModuleEnabled"
      class="appoinment-notify__module-enabled"
    >
      <div class="appointment-notify__notification-checkboxes messages-settings-notification-checkbox">
        <m-switch
          :value="appointmentNotifySwitches.notify"
          :active-text="t('whatsapp.switchTitles.notifyAfterAppointmentRegistration')"
          @change="setAppointmentNotifySwitchesNotify($event)"
        />
        <m-switch
          v-if="appointmentNotifySwitches.notify"
          :value="appointmentNotifySwitches.notifyDefault"
          :active-text="t('whatsapp.switchTitles.enableNotificationAppointmentWindow')"
          @change="setAppointmentNotifySwitchesNotifyDefault($event)"
        />
      </div>

      <div
        v-if="appointmentNotifySwitches.notify"
        class="appointment-notify__settings"
      >
        <messages-priorities
          v-if="messagesPriorities.length"
          :messages-priorities="messagesPriorities"
          @updatePriorities="setMessagesPriorities"
        />

        <whatsapp-timeout-settings
          :delivery-waiting-time="deliveryWaitingTime"
          :read-waiting-time="readWaitingTime"
          :delivery-waiting-time-variables="NOTIFY_DELIVERY_WAITING_TIME_VARIABLES"
          :read-waiting-time-variables="NOTIFY_READ_WAITING_TIME_VARIABLES"
          @updateDeliveryWaitingTime="setAppointmentNotifyDeliveredToClientDurationLimit"
          @updateReadWaitingTime="setAppointmentNotifyReadByClientDurationLimit"
        />

        <div class="appointment-notify__notify-template">
          <variables-editor
            :editor-value="smsTemplateValue"
            :editor-title="t('whatsapp.notificationTemplate')"
            :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
            :preview-request="fetchTemplatePreview"
            :variables-list="variablesListForEditor"
            required
            validator-name="smsTemplate"
            template-type="appointment"
            @registerValidator="onRegisterValidator"
            @changeEditorValue="setAppointmentNotifySmsTemplate"
          />
        </div>

        <variables-editor-whatsapp
          v-if="isWabaActive"
          :editor-value="wabaTemplateText"
          :variables-list="variablesListForEditor"
          @changeWabaEditorValue="setWabaTemplateText"
        >
          <template #templateSelector>
            <m-select
              :value="wabaTemplateId"
              :items="wazzupTemplatesList"
              value-key="templateGuid"
              :placeholder="t('whatsapp.chooseValue')"
              @change="insertTemplate"
            />
          </template>
        </variables-editor-whatsapp>
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="t('introduction.messages')"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import {
  APPOINTMENT_VARIABLES_LIST,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import { variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  NOTIFY_DELIVERY_WAITING_TIME_VARIABLES,
  NOTIFY_READ_WAITING_TIME_VARIABLES,
  PRIORITY_BUTTONS_ITEMS,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import Introduction from '@/vue_components/introduction/introduction.vue'
import {
  findAndExtractWabaTemplateTextWithVariablesLabels,
  extractWabaTemplateText,
  replaceVariablesLabelsByValues,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'notify'

export default {
  name: 'AppointmentNotify',
  components: {
    Introduction,
    MSwitch,
    MessagesPriorities,
    WhatsappTimeoutSettings,
    VariablesEditorWhatsapp,
    MSelect,
    MButton,
    VariablesEditor,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaTemplateText: '',
      PRIORITY_BUTTONS_ITEMS,
      APPOINTMENT_VARIABLES_LIST,
      NOTIFY_DELIVERY_WAITING_TIME_VARIABLES,
      NOTIFY_READ_WAITING_TIME_VARIABLES,
      unwatchTemplateList: null,
      fetchTemplatePreview,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      appointmentNotifySwitches: 'vxGetAppointmentNotifySwitches',
      appointmentNotifySmsTemplate: 'vxGetAppointmentNotifySmsTemplate',
      messagesPriorities: 'vxGetMessagesPriorities',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      wabaTemplateVariables: 'vxGetAppointmentNotifyWabaTemplateVariables',
      appointmentNotifyWabaTemplateId: 'vxGetAppointmentNotifyWabaTemplateId',
      deliveryWaitingTime: 'vxGetAppointmentNotifyDeliveredWaitingTime',
      readWaitingTime: 'vxGetAppointmentNotifyReadWaitingTime',
      isWabaActive: 'vxIsWabaActive',
    }),

    smsTemplateValue () {
      return this.appointmentNotifySmsTemplate
    },

    wabaTemplateId () {
      return { templateGuid: this.appointmentNotifyWabaTemplateId }
    },

    variablesListForEditor () {
      return APPOINTMENT_VARIABLES_LIST.filter((el) => !el.disabled)
    },
  },

  created () {
    const baseRequest = this.vxFetchMessagesConfigurations()

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
      this.vxFetchMessagesPriorities(CURRENT_PRIORITY_TYPE),
    ]

    Promise.all([baseRequest, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
      vxFetchMessagesPriorities: 'vxFetchMessagesPriorities',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      setAppointmentNotifySwitchesNotify: 'vxSetAppointmentNotifySwitchesNotify',
      setAppointmentNotifySwitchesNotifyDefault: 'vxSetAppointmentNotifySwitchesNotifyDefault',
      setAppointmentNotifySmsTemplate: 'vxSetAppointmentNotifySmsTemplate',
      setMessagesPriorities: 'vxSetMessagesConfigurationsPriorities',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setAppointmentNotifyWabaTemplateId: 'vxSetAppointmentNotifyWabaTemplateId',
      setAppointmentNotifyDeliveredToClientDurationLimit: 'vxSetAppointmentNotifyDeliveredWaitingTime',
      setAppointmentNotifyReadByClientDurationLimit: 'vxSetAppointmentNotifyReadWaitingTime',
    }),

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
    },

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialWabaTemplateText()
    },

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.appointmentNotifyWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.appointmentNotifyWabaTemplateId,
          templateVariablesValues: this.wabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setAppointmentNotifyWabaTemplateId(template?.templateGuid)
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const whatsappEditorValueToServer = replaceVariablesLabelsByValues(this.wabaTemplateText || '')

      const messagesConfigurations = {
        notify: this.appointmentNotifySwitches.notify,
        notifyDefault: this.appointmentNotifySwitches.notifyDefault,
        notifyTemplate: this.smsTemplateValue,
      }

      const whatsappConfigurations = {
        notifyWabaTemplateVariables: variablesParserWithChild(whatsappEditorValueToServer),
        appointmentNotifyWabaTemplateId: this.appointmentNotifyWabaTemplateId,
        appointmentNotifyDeliveredWaitingTime: this.deliveryWaitingTime,
        appointmentNotifyReadWaitingTime: this.readWaitingTime,
      }

      const channelsPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.messagesPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(channelsPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
