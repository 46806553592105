<template>
  <div>
    <div
      v-show="popoverVisibility"
      ref="popover"
      :style="popoverStyles"
      class="contacts-popover communications_phone_popover"
      tabindex="-1"
      @blur="onBlur"
    >
      <div class="arrow-left" />
      <div class="buttons-container gap-indent-small">
        <m-button
          template="callOut"
          button-style="fill"
          circle
          use-solid-icon
          @click="callOut"
        />

        <m-button
          v-if="messageVisibility"
          template="smsOut"
          button-style="fill"
          circle
          use-solid-icon
          icon-color=""
          @click="onMessageClick('sms')"
        />

        <m-button
          v-if="messageVisibility"
          template="whatsappOut"
          button-style="fill"
          circle
          use-brand-icon
          @click="onMessageClick('whatsapp')"
        />
      </div>
    </div>

    <introduction-modal
      ref="messagesPopoverModal"
      :modal-icon="modalWindow.icon"
      :modal-inner="modalWindow.inner"
      :modal-title="modalWindow.title"
      :request-button="modalWindow.requestButton"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MODULES, PERMISSIONS } from '@/vue_present/_base/MIntroductionWrapper/stubs/_helpers/constants'
import { mapGetters } from 'vuex'
import { MTelephonyPresenter } from '@/_api/MTelephony/MTelephonyPresenter'
import IntroductionModal from '@/vue_components/modal_windows/introduction/introduction_modal.vue'
import { propsForModalWindow } from '@/vue_apps/Contacts/const'

const BLUR_CRUTCH_TIMEOUT = 200

export default {
  name: 'ContactsPopover',
  components: { IntroductionModal, MButton },
  data () {
    return {
      popoverVisibility: false,
      popoverStyles: {
        top: 0,
        left: 0,
      },

      formData: {
        phoneNumber: undefined,
        clientId: undefined,
        userId: undefined,
        noSms: undefined,
      },

      /* messageVisibility для скрытия кнопок отправки сообщений, например для звонков юзерам */
      messageVisibility: true,

      modalWindow: {
        icon: '',
        inner: '',
        title: '',
        requestButton: true,
      },
    }
  },

  computed: {
    ...mapGetters({
      vxTelephonyModuleEnabled: 'GET_APP_CONF_TELEPHONY_MODULE_ENABLED',
      vxSmsModuleEnabled: 'GET_APP_CONF_SMS_MODULE_ENABLED',
      vxGetAppConfWhatsappModuleEnabled: 'vxGetAppConfWhatsappModuleEnabled',
    }),

    telephonyRequirements () {
      return {
        moduleAvailability: { name: MODULES.TELEPHONY, isEnabled: this.vxTelephonyModuleEnabled },
        userRights: { name: PERMISSIONS, isEnabled: this.$security.canManageCall },
      }
    },

    smsRequirements () {
      return {
        moduleAvailability: { name: MODULES.SMS, isEnabled: this.vxSmsModuleEnabled },
        userRights: { name: PERMISSIONS, isEnabled: this.$security.canManageMessage },
      }
    },

    whatsappRequirements () {
      return {
        moduleAvailability: { name: MODULES.WHATSAPP, isEnabled: this.vxGetAppConfWhatsappModuleEnabled },
        userRights: { name: PERMISSIONS, isEnabled: this.$security.canManageMessage },
      }
    },
  },

  created () {
    this.subscribeToEvents()
  },

  methods: {
    subscribeToEvents () {
      this.$pubSub.subscribe('PHONE_POPOVER.SHOW', this.onPopoverShow)
    },

    showPopover () {
      this.popoverVisibility = true
    },

    hidePopover () {
      this.popoverVisibility = false
    },

    modalShow () {
      this.$refs.messagesPopoverModal.modalShow()
    },

    /** @param {TContactsBaseData} data */
    __saveData (data) {
      this.messageVisibility = !data.noSms
      this.formData.phoneNumber = data.phoneNumber
      this.formData.clientId = data.clientId
      this.formData.userId = data.userId
    },

    /** @param {Element} element */
    __calculatePosition (element) {
      const popover = this.$refs.popover
      const popoverHeight = popover.clientHeight
      const { top, right, height } = element.getBoundingClientRect()

      const calcTop = top - (popoverHeight - height) / 2
      const calcLeft = right

      this.popoverStyles.top = `${calcTop}px`
      this.popoverStyles.left = `${calcLeft}px`

      this.$nextTick(() => popover.focus())
    },

    /** @param {Element} element */
    __draw (element) {
      this.showPopover()
      this.$nextTick(() => { this.__calculatePosition(element) })
    },

    /** @param {TContactsBaseData} data */
    onPopoverShow (data) {
      this.__saveData(data)
      this.__draw(data.element)
    },

    onBlur () {
      setTimeout(() => { this.hidePopover() }, BLUR_CRUTCH_TIMEOUT)
    },

    callOut () {
      if (!this.telephonyRequirements.moduleAvailability.isEnabled) {
        this.modalWindow = propsForModalWindow.telephonyModuleDisabled
        this.modalShow()

        return
      }

      if (!this.telephonyRequirements.userRights.isEnabled) {
        this.modalWindow = propsForModalWindow.telephonyModuleNoPermission
        this.modalShow()

        return
      }

      new MTelephonyPresenter().callOut({
        phoneNumber: this.formData.phoneNumber,
        clientId: this.formData.clientId,
      })
    },

    messageModuleDisabled (provider, module) {
      return provider === module.moduleAvailability.name && !module.moduleAvailability.isEnabled
    },

    canManageMessageModule (provider, module) {
      return provider === module.moduleAvailability.name && !module.userRights.isEnabled
    },

    /** @param {TMessageProvider} messageProvider */
    onMessageClick (messageProvider) {
      if (this.messageModuleDisabled(messageProvider, this.smsRequirements) || this.messageModuleDisabled(messageProvider, this.whatsappRequirements)) {
        this.modalWindow = propsForModalWindow.messagesModuleDisabled
        this.modalShow()

        return
      }

      if (this.canManageMessageModule(messageProvider, this.smsRequirements) || this.canManageMessageModule(messageProvider, this.whatsappRequirements)) {
        this.modalWindow = propsForModalWindow.messagesModuleNoPermission
        this.modalShow()

        return
      }

      const editorData = {
        messageProvider,
        phoneNumber: this.formData.phoneNumber,
        clientId: parseInt(this.formData.clientId) || undefined,
        userId: parseInt(this.formData.userId) || undefined,
      }

      this.$pubSub.emitAsync('CUSTOM_MESSAGE_EDITOR.SHOW', editorData)
    },
  },
}
</script>
