import { camelToSnake } from '@/_api/_requests/helpers'

export const documentTypesAdapter = {
  toServer (payload) {
    return JSON.stringify(
      camelToSnake({
        sortParams: payload,
      })
    )
  },
}
