<template>
  <si-table class="emd__table">
    <template #header>
      <si-field>
        <div class="col col__status" />

        <div class="col col__number">
          {{ t('egisz.components.egisz_med_records.table.header.id') }}
        </div>

        <div class="col col__created-at">
          {{ t('egisz.components.egisz_med_records.table.header.created_at') }}
        </div>

        <div
          v-if="!hideAdministratorModeCols"
          class="col col__client"
        >
          {{ t('egisz.components.egisz_med_records.table.header.client_full_name') }}
        </div>

        <div class="col col__doctor">
          {{ t('egisz.components.egisz_med_records.table.header.doctor_full_name') }}
        </div>

        <div
          v-tooltip="t('egisz.components.egisz_cases.sign_register_modal.table.personal_sign_tooltip')"
          class="col col__status"
        >
          <i class="fad fa-file-certificate font-size-17 grey" />
        </div>

        <div
          v-tooltip="t('egisz.components.egisz_cases.sign_register_modal.table.organization_sign_tooltip')"
          class="col col__status"
        >
          <i class="fad fa-file-certificate font-size-17 grey" />
        </div>

        <div class="col col__options">
          <i class="fad" />
        </div>
      </si-field>
    </template>

    <template #body>
      <si-field
        v-for="medDocument in medDocuments"
        :key="`medRecord:${medDocument.id}`"
        @safe-click="$emit('editMedDocument', medDocument)"
      >
        <div
          v-tooltip="t(`egisz.common_statuses.${medDocument.status}`)"
          class="col col__status"
        >
          <i
            class="font-size-17"
            :class="EGISZ_STATUSES_EMD_CSS(medDocument.status)"
          />
        </div>

        <div class="col col__number">
          {{ medDocument.id }}
        </div>

        <div class="col col__created-at">
          {{ medDocument.formatted_date }}
        </div>

        <div
          v-if="!hideAdministratorModeCols"
          class="col col__client"
        >
          <a
            :href="$routes.client_path(getClientInfo(medDocument).id)"
            target="_blank"
            @click.stop="() => {}"
          >
            {{ getClientInfo(medDocument).clientFullName }}
          </a>
        </div>

        <div class="col col__doctor">
          {{ medDocument.author_full_name }}
        </div>

        <div
          class="col col__status"
        >
          <i
            class="fad fa-file-certificate font-size-17"
            :class="{
              red: !medDocument.attachment_signature_status?.personal_signed,
              success: medDocument.attachment_signature_status?.personal_signed,
            }"
          />
        </div>

        <div
          class="col col__status"
        >
          <i
            class="fad fa-file-certificate font-size-17"
            :class="{
              red: !medDocument.attachment_signature_status?.organization_signed,
              success: medDocument.attachment_signature_status?.organization_signed,
            }"
          />
        </div>

        <div
          class="col col__options"
          @click.stop="() => {}"
        >
          <popover
            v-if="EGISZ_STATUSES.SUCCESS !== medDocument.status"
            :disabled="!canManage"
            @yes="$emit('deleteMedDocument', medDocument.id)"
          >
            <template slot="reference">
              <i
                v-if="canManage"
                class="fad fa-trash"
              />
            </template>
          </popover>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import Popover from '@/vue_components/common/popover/popover'
import { EGISZ_STATUSES, EGISZ_STATUSES_EMD_CSS } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export default {
  name: 'EgiszEmdTabTable',
  components: { Popover, SiField, SiTable },
  props: {
    medDocuments: {
      type: Array,
      default: () => [],
    },

    hideAdministratorModeCols: Boolean,
    canManage: Boolean,
  },

  data () {
    return {
      EGISZ_STATUSES,
      EGISZ_STATUSES_EMD_CSS,
    }
  },

  methods: {
    getClientInfo (medDocument) {
      const client = medDocument && medDocument.entry_client

      if (client) {
        return {
          id: client.id,
          clientFullName: client.full_name,
        }
      }

      return {
        id: 0,
        clientFullName: null,
      }
    },
  },
}
</script>
