var render = function render(){var _vm=this,_c=_vm._self._c;return _c('m-panel',{staticClass:"semd-registration-area",attrs:{"title":_vm.t('semds.semd119.formSections.signaturesAndRegistration'),"type":_vm.panelType,"icon":"sign","use-type-by-route":false,"m-panel-class":""}},[_vm._t("validationNotice",function(){return [_c('div',{staticClass:"signature-clarification mb-indent-validation prompt-notice"},[_vm._v("\n      "+_vm._s(_vm.t('semds.semd119.formTitles.signatureClarification'))+"\n    ")])]}),_vm._v(" "),_c('m-input',{staticClass:"flex-grow-1",attrs:{"value":_vm.doctorSigner,"label":_vm.doctorSignerLabel,"placeholder":_vm.t('semds.semd119.formTitles.doctorSignaturePlaceholder'),"disabled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.hideSignatureButtons && !_vm.doctorSigner)?_c('reusable-sign-popover',{attrs:{"disabled":_vm.doctorSignDisabled},on:{"onThumbprintSelect":function($event){return _vm.$emit('onSign', {
          ...$event,
          holderScope: 'personal'
        })}}}):_vm._e(),_vm._v(" "),(_vm.doctorSigner)?_c('m-button-delete',{attrs:{"disabled":{ disabled: !_vm.doctorSigner || _vm.disabled, tooltip: _vm.t('removeSignature') }},on:{"yes":function($event){return _vm.$emit('onRemoveSign', {
          ...$event,
          holderScope: 'personal'
        })}}}):_vm._e()]},proxy:true}])}),_vm._v(" "),_c('m-input',{staticClass:"flex-grow-1",attrs:{"value":_vm.organizationSigner,"label":_vm.t('semds.semd119.formTitles.clinicSignature'),"placeholder":_vm.t('semds.semd119.formTitles.clinicSignaturePlaceholder'),"disabled":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.hideSignatureButtons && !_vm.organizationSigner)?_c('reusable-sign-popover',{attrs:{"disabled":_vm.organizationSignDisabled},on:{"onThumbprintSelect":function($event){return _vm.$emit('onSign', {
          ...$event,
          holderScope: 'organization'
        })}}}):_vm._e(),_vm._v(" "),(_vm.organizationSigner)?_c('m-button-delete',{attrs:{"disabled":{ disabled: !_vm.organizationSigner || _vm.disabled, tooltip: _vm.t('removeSignature') }},on:{"yes":function($event){return _vm.$emit('onRemoveSign', {
          ...$event,
          holderScope: 'organization'
        })}}}):_vm._e()]},proxy:true}])}),_vm._v(" "),_c('div',{staticClass:"registration-errors"},[_c('div',{staticClass:"registration-errors__title prompt-notice"},[_vm._v("\n      "+_vm._s(_vm.t('semds.semd119.formTitles.registrationErrors'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"registration-errors__errors-list"},[(!_vm.errors.length)?[_c('span',{staticClass:"prompt-notice"},[_vm._v("\n          "+_vm._s(_vm.t('semds.errors.noErrors'))+"\n        ")])]:_vm._l((_vm.errors),function(error,index){return _c('div',{key:`${error.timestamp}:${index}`},[_vm._v("\n          "+_vm._s(error.timestamp)+": "+_vm._s(_vm.getErrorText(error))+"\n        ")])})],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }