<template>
  <validation-wrapper
    :errors="validationErrors"
    :m-fixed-height="mFixedHeight"
    :class="specialClasses"
    class="m-suggestion-select"
  >
    <el-select
      :value="value"
      v-bind="$props"
      class="m-select"
      :class="{
        'w-100': fullWidth,
        'm-select_search-style': useSearchStyle
      }"
      filterable
      remote
      :remote-method="onFilterValueChange"
      :value-key="id"
      @change="onChange"
    >
      <el-option
        v-for="item in displayedItems"
        :key="item.id"
        :class="{
          companySearchItem: useCompanySearch
        }"
        :label="item.title"
        :value="item"
      >
        <template v-if="useCompanySearch">
          <div>{{ item.title }}</div>
          <div class="companySearchItem__address prompt-notice">
            <div class="cut">
              {{ item.data.address.value }}
            </div>
            <div>{{ t('inn') }}:  {{ item.data.inn }}</div>
            <div>{{ t('ogrn') }}: {{ item.data.ogrn }}</div>
          </div>
        </template>
      </el-option>

      <m-label
        v-if="label"
        :id="id"
        :label="label"
        :disabled="canManage.disabled"
        :focused="focused"
      />
    </el-select>
  </validation-wrapper>
</template>

<script>
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { suggestionPresenter } from '@/api_entities/suggestion/suggestionPresenter'
import { snakeToCamel } from '@/_api/_requests/helpers'
import MLabel from '@/vue_present/_base/inputs/MLabel.vue'
import { MSelectProps } from '@/vue_present/_base/inputs/MSelect/MSelectProps'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'

export default {
  name: 'MSuggestionSelect',
  components: { ValidationWrapper, MLabel },
  mixins: [
    CommonInputProps,
    CommonInputMethods,
    DisabledMixin,
    ValidationChildMixin,
  ],

  props: {
    ...MSelectProps,
    fullWidth: PropsTypes.Boolean(true),
    type: PropsTypes.String('party'),
    deferRequestBy: PropsTypes.Number(300),

    /** Используется для установки значения из поиска */
    useValue: Boolean,
    value: PropsTypes.Object(),

    useCompanySearch: Boolean,
  },

  data () {
    return {
      focused: false,

      displayedItems: [],
      locationsBoost: [],
    }
  },

  computed: {
    specialClasses () {
      return {
        'w-100': this.fullWidth,
        'el-select-multi': this.multiple && this.collapseTags,
        'more-than-1': this.multiple && this.collapseTags && this.value?.length > 1,
      }
    },
  },

  watch: {
    value: {
      immediate: true,
      handler (to) { this.appendValue(to) },
    },
  },

  mounted () {
    this.fetchGeoLocation()
  },

  methods: {
    appendValue (to) {
      if (!this.useValue) { return }
      if (!to) { return }
      this.displayedItems = [...this.displayedItems, to]
    },

    async fetchGeoLocation () {
      try {
        this.locationsBoost = await suggestionPresenter.geoLocation()
      } catch (err) {}
    },

    async onFilterValueChange (filterValue) {
      if (!filterValue) { return }

      const __params = {
        deferRequestBy: this.deferRequestBy,
        query: filterValue,
        locations_boost: this.locationsBoost,
      }

      this.displayedItems = await suggestionPresenter[this.type](__params)
    },

    onChange (event) {
      if (this.useValue) {
        this.$updateSync('value', snakeToCamel(event?.data))
      }

      this.$emit('change', snakeToCamel(event?.data))
    },
  },
}
</script>
