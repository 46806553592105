import { BUTTONS_TEMPLATES } from '@/vue_present/_base/buttons/MButton/MButtonTemplates'

export const computed = {
  isButtonWithIconCssRequired () {
    if (this.icon) { return this.icon }

    return this.template
      ? BUTTONS_TEMPLATES[this.template]?.icon
      : this.icon
  },

  buttonType () {
    return this.template
      ? BUTTONS_TEMPLATES[this.template]?.type
      : this.type
  },

  buttonText () {
    if (typeof this.text === 'string' || this.text) { return this.text }

    return this.template
      ? BUTTONS_TEMPLATES[this.template]?.text
      : this.text
  },

  buttonTooltip () {
    return this.template && !this.tooltip
      ? BUTTONS_TEMPLATES[this.template]?.tooltip
      : this.tooltip
  },

  isPlain () {
    return this.buttonStyle !== 'fill'
  },

  isDark () {
    return this.type === 'dark'
  },
}
