import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MCompanyBladeRunnerDocumentPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        all: Routes.checkups_documents_companies_routes_lists_path,
        one: Routes.checkups_documents_companies_routes_list_path,
      },
      entity: 'companyBladeRunnerDocument',
      location: 'MCompanyBladeRunnerDocumentPresenter',
    })
  }

  submit (data, config = {}) {
    return super.submit(data, {
      toServerAdapter: (document) => ({ document }),
    })
  }
}
