var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dropdown',{attrs:{"trigger":"click","size":"mini","hide-on-click":false},on:{"command":function($event){return _vm.$emit('onSelect', $event)},"visible-change":_vm.resetList}},[_c('button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"btn btn-sm btn-success btn-with-icon",attrs:{"type":"button"},on:{"click":_vm.onButtonClick}},[_c('i',{staticClass:"fad fa-plus btn-with-icon_icon"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.t('egisz.steps.modal.services.add_from_history')))])]),_vm._v(" "),_c('el-dropdown-menu',{staticClass:"m-dropdown m-dropdown_fixed-sizes",attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.entryHistoryList),function(entry){return _c('el-dropdown-item',{key:`entry:${entry.id}`,attrs:{"command":entry}},[_c('div',{staticClass:"bold"},[_vm._v("\n        "+_vm._s(entry.title)+"\n      ")]),_vm._v(" "),(!entry.disabled)?_c('div',{staticClass:"prompt-notice"},[_vm._v("\n        "+_vm._s(_vm.t('egisz.components.entries_history.subtitle', {
          id: entry.id,
          createdAt: entry.created_at,
          fullName: _vm.getDoctor(entry.user_id),
          price: _vm.currencyFormat(entry.final_sum),
          status: _vm.t('egisz.common_statuses')[entry.state]
        }))+"\n      ")]):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }