<template>
  <div class="introduction-container">
    <div class="introduction-icon-container">
      <i
        v-if="icon && !small"
        :class="[icon, { 'module-disabled': disabled }]"
      />
    </div>

    <div
      class="introduction-description-container"
      :style="{ fontSize: small && '14px' }"
    >
      <div
        class="introduction-description"
        v-html="introductionText"
      />
    </div>

    <slot name="reference">
      <div
        v-if="!hideButton"
        class="introduction-request-button"
      >
        <a
          class="btn btn-primary btn-with-icon"
          :class="{ 'btn-sm': small }"
          :href="button.url || '/module_request'"
          target="_blank"
        >
          <i class="fad fa-fw fa-toggle-off btn-with-icon_icon" />
          <span>{{ button.text || t('introduction.connect_module') }}</span>
        </a>
      </div>

      <div
        v-if="hideButton && !canView"
        class="prompt-notice mt-10"
      >
        {{ t('introduction.module_connected_by_not_authorized') }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Introduction',
  props: {
    icon: {
      type: String,
      default: null,
    },

    introductionText: {
      type: String,
      required: true,
    },

    disabled: Boolean,

    button: {
      type: Object,
      default () {
        return {
          text: '',
          url: '',
        }
      },
    },

    hideButton: Boolean,

    small: Boolean,

    canView: Boolean,
  },
}
</script>
