<template>
  <div class="personal-elmk-medical-assessment-view">
    <slot name="actuality" />

    <base-header v-bind="document" />

    <b
      class="flex justify-content-center center mb-indent-mid"
      v-html="t$('titleText')"
    />

    <!-- Дата "от" -->
    <div class="flex justify-content-center gap-indent-small mb-indent-validation">
      <span>{{ t('from').toLowerCase() }}</span>

      <document-field-writable-date
        :disabled="isShowMode"
        :date="document.titleDate"
        @input="document.setValue('titleDate', $event)"
      />
    </div>

    <!--  Номер личной мед. книжки  -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.medicalCardNumberText }}
      </span>

      <document-field-writable
        class="bold"
        :value="document.medicalCardNumber"
        :disabled="isShowMode"
        fill-available-width
        @input="document.setValue('medicalCardNumber', $event)"
      />
    </div>

    <!--  ФИО  -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.workerFullNameText }}
      </span>

      <document-field-writable
        :value="document.workerFullName"
        fill-available-width
        disabled
      />
    </div>

    <!--  Вид медицинского осмотра  -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.checkupTypeText }}
      </span>

      <document-field-writable
        :value="document.checkupType"
        fill-available-width
        disabled
      />
    </div>

    <!--  Структурное подразделение (филиал)  -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.structuralDepartmentText }}
      </span>

      <document-field-writable
        :value="document.structuralDepartment"
        fill-available-width
        multiline
        disabled
      />
    </div>

    <!--  Наименование вида работы  -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.positionFactorText }}
      </span>

      <document-field-writable
        :value="document.positionFactorTitle"
        fill-available-width
        multiline
        disabled
      />
    </div>

    <!--  Сведения о перенесенных инфекционных заболеваниях  -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.infectiousDiseasesText }}
      </span>

      <document-field-writable
        :value="document.infectiousDiseasesData"
        fill-available-width
        multiline
        disabled
      />
    </div>

    <!-- Заключение по результатам медицинского осмотра -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.medicalAssessmentText }}
      </span>

      <document-field-writable
        :value="document.medicalAssessmentTitle"
        fill-available-width
        multiline
        disabled
      />
    </div>

    <!-- Дата проведения очередного периодического медицинского осмотра -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.checkupDateText }}
      </span>

      <document-field-writable
        :value="document.checkupDate"
        fill-available-width
        disabled
      />
    </div>

    <div class="checkup-document-table-container">
      <b class="checkup-document-header">
        {{ document.vaccinationsTableTitle }}
      </b>

      <!--  Сведения о профилактических прививках  -->
      <document-table
        v-if="document.vaccinationsTableData.length"
        class="mb-indent-mid"
        :headers="document.vaccinationsTableHeader"
      >
        <template #body>
          <tr
            v-for="({date, text}, idx) in document.vaccinationsTableData"
            :key="idx"
          >
            <td>{{ date }}</td>

            <td class="background-primary">
              <document-field-writable
                class="w-100"
                :value="text"
                fill-available-width
                @input="document.setVaccineText(idx, $event)"
              />
            </td>
          </tr>
        </template>
      </document-table>

      <p v-else>
        {{ t$('noVaccination') }}
      </p>
    </div>

    <div class="checkup-document-table-container">
      <b class="checkup-document-header">
        {{ document.doctorResultsTableTitle }}
      </b>

      <!-- Осмотры врачей-специалистов -->
      <document-table
        class="mb-indent-mid"
        :headers="document.doctorResultsTableHeader"
        :data="document.doctorResultsTableData"
      />
    </div>

    <div class="checkup-document-table-container">
      <b class="checkup-document-header">
        {{ document.laboratoryResearchesTableTitle }}
      </b>

      <!-- Лабораторные исследования -->
      <document-table
        class="mb-indent-mid"
        :headers="document.laboratoryResearchesTableHeader"
        :data="document.laboratoryResearchesTableData"
      />
    </div>

    <div class="checkup-document-table-container">
      <b class="checkup-document-header">
        {{ document.instrumentalResearchesTableTitle }}
      </b>

      <!-- Инструментальные исследования -->
      <document-table
        class="mb-indent-validation"
        :headers="document.instrumentalResearchesTableHeader"
        :data="document.instrumentalResearchesTableData"
      />
    </div>

    <span class="mb-indent-validation">
      {{ t$('footerText') }}
    </span>

    <div class="personal-elmk-medical-assessment-view__footer">
      <div class="flex gap-indent-small">
        <span>{{ t$('footerSpeciality') }}</span>

        <!-- Врач-профпатолог -->
        <div class="checkup-document-field_column align-items-center">
          <document-field-writable
            :value="document.footerName"
            :disabled="isShowMode"
            width="160px"
            @input="document.setValue('footerName', $event)"
          />

          <span class="checkup-document-field__title">
            ({{ t('full_name') }})
          </span>
        </div>
      </div>

      <span class="personal-elmk-medical-assessment-view__place-of-printing">
        {{ t('placeOfPrinting') }}
      </span>

      <div class="flex flex-column align-items-center">
        <span>_________________</span>
        <span>({{ t('signature') }})</span>
      </div>
    </div>

    <!-- Дата выдачи -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ t('issue_date') }}
      </span>

      <document-field-writable
        :value="document.footerDate"
        :disabled="isShowMode"
        width="85px"
        @input="document.setValue('footerDate', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import BaseHeader from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/Base/BaseHeader.vue'
import {
  PersonalElmkMedicalAssessmentApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalElmkMedicalAssessment/PersonalElmkMedicalAssessmentApi'
import DocumentFieldWritableDate
  from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritableDate/DocumentFieldWritableDate.vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import DocumentTable from '@/vue_present/DocumentBase/DocumentTable/DocumentTable.vue'

export default defineComponent({
  name: 'PersonalElmkMedicalAssessmentView',

  components: {
    DocumentTable,
    DocumentFieldWritable,
    DocumentFieldWritableDate,
    BaseHeader,
  },

  mixins: [i18nScopeMixin],

  props: {
    document: PersonalElmkMedicalAssessmentApi,
    isEditMode: Boolean,
    isShowMode: Boolean,
  },

  created () {
    this.setI18nScope('checkups.documents.personalElmkMedicalAssessment')
  },
})
</script>
