<template>
  <i
    class="m-icon"
    :class="finalClass"
    @click="$emit('click', $event)"
  />
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { iconsMap } from '@/modules/utils/medods_ui/iconsMap'

export default {
  name: 'MIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },

    customClass: PropsTypes.String(''),

    color: PropsTypes.String('default'),

    noUseFw: Boolean,
    useBrand: Boolean,
    useSolid: Boolean,
  },

  computed: {
    finalClass () {
      const makeFAClass = () => {
        if (this.useBrand) { return 'fab' }
        if (this.useSolid) { return 'fas' }
        if (this.icon === 'no-filter') { return 'fas' }

        return 'fad'
      }

      const fw = this.noUseFw ? '' : 'fa-fw'

      return [
        iconsMap[this.icon],
        this.color,
        this.customClass,
        makeFAClass(),
        fw,
      ].join(' ')
    },
  },
}
</script>
