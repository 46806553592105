import { ItemBase } from '@/_api/_classes/ItemBase'

export class PersonalCheckupCheckupEntriesModel extends ItemBase {
  _permit = [
    'personalCheckupId',
    'checkupEntries',
  ]

  personalCheckupId = null

  checkupEntries = []

  // В компонентах не использовать. В компонентах юзать computed
  get entries () {
    return this.checkupEntries
      .map(({ entry }) => entry)
      .filter(Boolean)
  }

  constructor (props) {
    super()

    if (props.id) {
      this.personalCheckupId = props.id
    }

    if (props.checkupEntries) {
      this.checkupEntries = props.checkupEntries
    }
  }
}
