<template>
  <m-input
    :label="t('semds.version')"
    :value="value"
    disabled
  />
</template>

<script>
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'

export default defineComponent({
  name: 'SemdVersion',
  components: { MInput },
  props: {
    value: { type: [String, Number], default: null },
  },
})
</script>
