<template>
  <m-panel
    class="expenses-page"
    :title="panelTitle"
    icon="expense"
  >
    <!-- Список расходов -->
    <m-si-generator
      v-if="canView"
      :items="expensesList.data"
      :si-generator-schema="expensesSchema"
      :current-page="expensesList.currentPage"
      :page-count="expensesList.totalPages"
      :search-query="expensesList.searchQuery"
      :total="expensesList.total"
      :search-placeholder="t('finance.searchPlaceholderExpenses')"
      :add-button-config="addButtonConfig"
      @onAddItem="onAddItem"
      @onItemClick="onItemClick"
      @update:currentPage="expensesList.fetchPage($event)"
      @update:searchQuery="expensesList.search($event)"
    >
      <template #filters>
        <!-- Фильтры -->
        <expenses-filters
          :list="expensesList"
          :groups="expensesGroups"
        />
      </template>
    </m-si-generator>

    <introduction
      v-else
      :introduction-text="t('introduction.not_authorized')"
      hide-button
      icon="fad fa-dollar-sign"
    />

    <!-- Модалка -->
    <expense-modal
      v-if="modalVisible"
      :visible.sync="modalVisible"
      :mode="modalMode"
      :item="modalExpenseItem"
      :groups="expensesGroups"
      :can-manage="canManage"
      @resetList="listInit"
      @resetExpensesGroupsList="fetchExpensesGroups"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import ExpensesFilters from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/ExpensesFilters.vue'
import { ExpensesList } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/ExpensesList'
import { getExpensesSchema } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getExpensesSchema'
import {
  getDefaultExpensesFilters,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getDefaultExpensesFilters'
import { getDefaultExpenseItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getDefaultExpenseItem'
import ExpenseModal from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/ExpenseModal.vue'
import { MExpensePresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/MExpensePresenter'
import { cloneDeep } from 'lodash'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const.js'
import Introduction from '@/vue_components/introduction/introduction.vue'

export default {
  name: 'ExpensesPage',
  components: { Introduction, ExpenseModal, ExpensesFilters, MSiGenerator, MPanel },

  data () {
    return {
      expensesList: new ExpensesList(getDefaultExpensesFilters()),
      expensesSchema: getExpensesSchema(),
      expensesGroups: [],
      modalVisible: false,
      MODES,
      modalMode: MODES.EDIT,
      modalExpenseItem: getDefaultExpenseItem(),
    }
  },

  computed: {
    panelTitle () {
      return `${t('finance.expenses')} (${this.expensesList.totalItems})`
    },

    addButtonConfig () {
      return { tooltip: t('finance.addExpense') }
    },

    canView () {
      return Services.security.canViewExpense
    },

    canManage () {
      return Services.security.canManageExpense
    },
  },

  created () {
    this.listInit()
    this.fetchExpensesGroups()
  },

  methods: {
    listInit () {
      this.expensesList.fetchAll()
    },

    onAddItem () {
      this.modalMode = MODES.CREATE
      this.modalExpenseItem = getDefaultExpenseItem()

      this.modalVisible = true
    },

    onItemClick (expense) {
      this.modalMode = MODES.EDIT
      this.modalExpenseItem = this.fillObjectValues(this.modalExpenseItem, expense)

      this.modalVisible = true
    },

    fillObjectValues (destination, source) {
      const res = {}

      for (const key in destination) {
        if (Object.hasOwn(source, key) && source[key] !== null) {
          res[key] = source[key].value
        }
      }

      return res
    },

    onSearchQueryUpdate (searchQuery) {
      if (searchQuery === this.expensesList.searchQuery) {
        return
      }

      this.expensesList.setSearchQuery(searchQuery)
      this.expensesList.fetchAll()
    },

    fetchExpensesGroups () {
      new MExpensePresenter().fetchExpensesGroups()
        .then((groups) => {
          this.expensesGroups = cloneDeep(groups)
        })
    },
  },
}
</script>
