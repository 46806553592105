import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { compareCabinetsTitles } from '@/vue_components/catalogs/cabinets/helpers'

export class MCabinetPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'cabinet',
      location: 'MCabinetPresenter',
      routes: {
        one () {},
        all () {},
        list: Routes.search_api_internal_reception_cabinets_path,
      },
    })
  }

  list (listData, config = {}) {
    return super.list(listData, config)
      .then((response) => {
        response.data = (response.data || []).sort(compareCabinetsTitles)

        return response
      })
  }
}
