<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
  >
    <el-input
      v-if="isTextType && mask"
      :id="id"
      ref="input"
      v-mask="mask"
      :type="type"
      class="m-input"
      :value="value"
      :clearable="clearable"
      :disabled="canManage.disabled"
      :placeholder="placeholder"
      :label="label"
      :minlength="minLength"
      :maxlength="maxLength"
      :show-word-limit="showWordLimit"
      :size="size"
      @keydown.native="useNumbersOnly && onKeydown($event)"
      @input="onInput"
      @change="onChange"
      @clear="onClear"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
      @click="$emit('click', $event)"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>

      <template #suffix>
        <slot name="suffix" />
      </template>

      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #append>
        <slot name="append" />
      </template>
    </el-input>
    <el-input
      v-else-if="isTextType"
      :id="id"
      ref="input"
      :type="type"
      class="m-input"
      :value="value"
      :clearable="clearable"
      :disabled="canManage.disabled"
      :placeholder="placeholder"
      :label="label"
      :minlength="minLength"
      :maxlength="maxLength"
      :show-word-limit="showWordLimit"
      :size="size"
      @keydown.native="useNumbersOnly && onKeydown($event)"
      @input="onInput"
      @change="onChange"
      @clear="onClear"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
      @click="$emit('click', $event)"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>

      <template #suffix>
        <slot name="suffix" />
      </template>

      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #append>
        <slot name="append" />
      </template>
    </el-input>
    <el-input-number
      v-else
      :id="id"
      ref="input"
      class="m-input"
      :value="value"
      :clearable="clearable"
      :disabled="canManage.disabled"
      :placeholder="placeholder"
      :label="label"
      :controls="controls"
      :precision="precision"
      :min="min"
      :max="max"
      :size="size"
      controls-position="right"
      @keydown.native="onKeydown"
      @keyup.native="onKeyup"
      @input="onInput"
      @change="onChange"
      @focus="focused = true"
      @blur="focused = false; $emit('blur', $event); __validate()"
      @clear="onClear"
      @click="$emit('click', $event)"
    />

    <m-label
      v-if="label"
      :id="id"
      :label="label"
      :required="isRequiredField && !requiredWhenValue"
      :required-tooltip="requiredTooltip"
      :disabled="disabled"
      :focused="focused"
    />
  </validation-wrapper>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { CommonInputProps } from '@/vue_present/_base/inputs/mixins/CommonInputProps'
import { CommonInputMethods } from '@/vue_present/_base/inputs/mixins/CommonInputMethods'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import MLabel from '@/vue_present/_base/inputs/MLabel'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { INPUT_TYPES } from '@/vue_present/_base/inputs/MInput/const'

export default {
  name: 'MInput',
  components: { MLabel, ValidationWrapper },

  mixins: [
    CommonInputProps,
    CommonInputMethods,
    ValidationChildMixin,
    DisabledMixin,
  ],

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: PropsTypes.Custom([String, Number]),
    type: PropsTypes.String('text'),
    /** @param {'medium | small | mini'} */
    size: PropsTypes.String('small'),

    controls: Boolean,
    showWordLimit: Boolean,
    precision: PropsTypes.Number(2),

    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },

    minLength: { type: Number, default: undefined },
    maxLength: { type: Number, default: undefined },

    mask: { type: String, default: undefined },
    useNumbersOnly: Boolean,
  },

  data () {
    return {
      focused: false,
    }
  },

  computed: {
    isTextType () {
      return this.type !== INPUT_TYPES.NUMBER
    },
  },

  methods: {
    onKeydown (event) {
      if (!Utils.onlyNumbersKeydownTemplate.includes(event.key)) { event.preventDefault() }
    },

    /**
    * Только для type="number"
    * @param {KeyboardEvent} event
    */
    onKeyup (event) {
      this.onKeydown(event)

      const input = this.$refs.input
      if (input.userInput) {
        input.userInput = input.userInput?.replace(/[.,]/, '.')
      }
    },
  },
}
</script>
