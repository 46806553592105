<template>
  <m-buttons-group
    class="sex-input"
    :value="value"
    :items="sexes"
    :label="t('sex')"
    :disabled="disabled"
    radio
    :required="required"
    validator-name="sex"
    @change="$emit('change', $event)"
    @registerValidator="$registerValidator($event)"
  />
</template>

<script>
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import { GLOBAL_GET_DEFAULT_SEXES } from '@/_global_scripts/GLOBAL_CONSTS'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'ReusableSex',
  components: { MButtonsGroup },
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    required: { type: [Boolean, Function], default: false },
    value: PropsTypes.Object(),
    disabled: Boolean,
  },

  computed: {
    sexes () {
      return GLOBAL_GET_DEFAULT_SEXES()
    },
  },
}
</script>
