import { rule } from '@/lib/validators'

/**
 * @type {ValidationRule<string>}
 */
export const required = rule('required', (value) => {
  return !!value
})

/**
 * @param {Moment|Date|Array|string|number} max
 * @return {ValidationRule<Moment|Date|Array|string|number>}
 */
export const maxDate = (max) => rule('maxDate', (value) => {
  const momentValue = moment(value)

  return momentValue.isSame(max) || momentValue.isBefore(max)
})

/**
 * @param {number} min
 * @return {ValidationRule<string|any[]>}
 */
export const minLength = (min) => rule('minLength', (value) => value.length >= min)

export const birthdate = rule('birthdate', (value) => {
  return !value || maxDate(moment())(value).valid
})

export const email = rule('email', (value = '') => {
  const re = /^[a-zA-Zа-яА-ЯёЁ0-9._%+-]+@[a-zA-Zа-яА-ЯёЁ0-9.-]+\.[a-zA-Zа-яА-ЯёЁ0-9-]{2,}$/

  return re.test(value)
})
