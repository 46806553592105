import { cloneDeep } from 'lodash'

export const softUpdateKeys = (oldData = {}, newData = {}) => {
  const resultData = cloneDeep(oldData)
  Object.keys(newData).forEach((key) => {
    if (!(oldData.hasOwnProperty(key) && newData[key])) { return }
    resultData[key] = newData[key]
  })

  return resultData
}
