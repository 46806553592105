import { ICRUDConfig } from '@/_api/decorators/api/interfaces/ICRUDConfig'
import { crudBase } from '@/_api/decorators/api/crud/crudBase'
import { TRoute } from '@/_declarations/TRoute'
import { HTTP_METHODS } from '@/_api/_requests/const'
import { orUndefined } from '@/_medods_standart_library/msl'

export function List (routeOrAttribute: TRoute | string, config: ICRUDConfig = {}) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return function (this: This, ...args: Args): Return {
      const { routeArgs, payload = {} } = target.call(this, ...args) ?? {}

      const _route = typeof routeOrAttribute === 'string'
        ? () => this[routeOrAttribute]
        : routeOrAttribute

      return crudBase<Return>({
        httpMethod: HTTP_METHODS.POST,

        payload: {
          sortParams: {
            ...payload,
            searchString: orUndefined(payload.searchQuery || payload.search_query),
          },
        },

        route: _route,
        routeArgs,

        config: {
          toJson: true,
          ...config,
        },
      })
    }
  }
}
