export const documentPresenterUpdateDocumentAdapter = {
  toServer (data: Record<string, string | number | boolean>) {
    const formData = new FormData()

    Object.entries(data.attributes)
      .forEach(([key, value]: [string, unknown]) =>
        formData.append(key, value?.toString() || ''))

    return formData
  },
}
