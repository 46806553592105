const LOCK_UI_EVENT_NAME = 'CLINIC_SWITCH.LOCK_UI'
const UNLOCK_UI_EVENT_NAME = 'CLINIC_SWITCH.UNLOCK_UI'
const CLINIC_CHANGED_NOTIFY_FLAG_NAME = 'CLINIC_SWITCH.CLINIC_CHANGED_NOTIFY_FLAG'
const SWITCH_CLINIC = 'SWITCH_CLINIC'

class ClinicSwitch {
  init () {
    this.localStorage = window.localStorage
    this.sessionStorage = window.sessionStorage
    this.UIlockTarget = $('body')
    this.notificator = Notificator
    this.refreshing = false
    this.pubSub = Services.pubSub
    this.enqueuedId = null

    this.listenStorageEvents()
    this.notifyUser()
    this.listenVisibilityChange()
  }

  listenVisibilityChange () {
    const refreshAction = () => {
      if (document.visibilityState === 'visible') {
        if (this.enqueuedId && this.enqueuedId !== gon.application.current_clinic.id) {
          this.enqueuedId = null
          this.refreshPage()
          document.removeEventListener('visibilitychange', refreshAction)
        }
      }
    }
    document.addEventListener('visibilitychange', refreshAction)
  }

  listenStorageEvents () {
    window.addEventListener('storage', (e) => {
      switch (e.key) {
        case SWITCH_CLINIC:
          this.enqueueRefresh(+e.newValue, gon.application.current_clinic.id)
          break
        case LOCK_UI_EVENT_NAME:
          this.UIlock()
          break
        case UNLOCK_UI_EVENT_NAME:
          this.UIunlock()
      }
    })
  }

  dispatchClinicSwitch (clinicId) {
    this.localStorage.setItem(SWITCH_CLINIC, clinicId)
  }

  setNotifyFlag (value) {
    this.sessionStorage.setItem(CLINIC_CHANGED_NOTIFY_FLAG_NAME, value)
  }

  getNotifyFlag () {
    return this.sessionStorage.getItem(CLINIC_CHANGED_NOTIFY_FLAG_NAME) === 'true'
  }

  UIlock () {
    this.UIlockTarget.loadSpin('start')
  }

  UIunlock () {
    this.UIlockTarget.loadSpin('stop')
  }

  refreshPage () {
    if (this.refreshing) return

    this.UIlock()
    this.setNotifyFlag(true)
    this.refreshing = true
    this.pubSub.emit('CLINIC_SWITCH.BEFORE_PAGE_REFRESH')
    window.location.reload()
  }

  /**
   * @param {number} newClinicId
   * @param {number} oldClinicId
   */
  enqueueRefresh (newClinicId, oldClinicId) {
    if (newClinicId !== oldClinicId) {
      if (document.visibilityState === 'visible' && this.enqueuedId !== newClinicId) {
        this.enqueuedId = null
        this.refreshPage()
      } else {
        this.enqueuedId = newClinicId
      }
    } else {
      this.enqueuedId = null
    }
  }

  notifyUser () {
    if (this.getNotifyFlag()) {
      this.notificator.success(T.clinic_switched)
      this.setNotifyFlag(false)
    }
  }
}

const instance = new ClinicSwitch()
export default instance
