export const getAssembledEntryTypes = (entryType) => ({
  id: entryType.id,
  title: entryType.title,
  number: entryType.number,
  info: Utils.joinTruthy([
    entryType.number,
    entryType.analysisLaboratory?.title,
    entryType.price,
  ], ', '),
})
