<template>
  <m-panel
    :title="t('semds.semd119.formSections.document')"
    :type="panelType"
    icon="document"
    :use-type-by-route="false"
  >
    <slot name="state" />

    <slot name="semdSource" />

    <!--    Заголовок-->
    <div class="consultation-form__document-title">
      <m-input
        :value="semd.title"
        :label="t('semds.semd119.formTitles.documentTitle')"
        :disabled="isShowMode"
        required
        validator-name="title"
        @registerValidator="$emit('registerValidator', $event)"
        @input="semd.setValue('title', $event)"
      />
    </div>

    <!--    Протокол-->
    <m-input
      :value="semd.owner?.title"
      :label="t('semds.semd119.formTitles.protocolTitle')"
      disabled
    />

    <!--    Версия-->
    <m-input
      :value="semd.version"
      :label="t('version')"
      disabled
    />

    <!--    Уровень конфиденциальности документа-->
    <semd-privacy-level
      :value="semd.metaData.nsiPrivacyLevel"
      :disabled="isShowMode"
      @change="semd.setMetaDataValue('nsiPrivacyLevel', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />

    <!--    Дата создания-->
    <semd-date
      :value="semd.date"
      :disabled="isShowMode"
      @change="semd.setValue('date', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { Semd119 } from '@/vue_apps/Semds/entities/Semd119/Semd119'
import SemdPrivacyLevel from '@/vue_apps/Semds/SemdModal/components/shared/SemdPrivacyLevel.vue'
import SemdDate from '@/vue_apps/Semds/SemdModal/components/shared/SemdDate.vue'

export default {
  name: 'ConsultationFormDocumentArea',
  components: {
    SemdDate,
    SemdPrivacyLevel,
    MInput,
    MPanel,
  },

  props: {
    semd: Semd119,
    isShowMode: { type: Boolean, default: false },
    panelType: { type: String, default: 'success' },
  },
}
</script>
