import { FNS_CODES_DICT, ID_CARD_TO_FNS_DICT } from '@/vue_present/Reuse/Lists/FNS/const'

export const getFNSByDict = (documentId, documentDict = ID_CARD_TO_FNS_DICT) => {
  const fnsCode = documentDict[documentId]
  if (!fnsCode) { return }

  return {
    id: fnsCode,
    title: FNS_CODES_DICT[fnsCode],
  }
}
