import { MedicalPolicyLogic } from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/MedicalPolicyLogic'
import { MMedicalPolicesPresenter } from '@/_api/MClient/MMedicalPoliceApi/MMedicalPolicesPresenter'
import { IMedicalPolicy } from '@/_declarations/IMedicalPolicy'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class MedicalPolicyApi extends MedicalPolicyLogic {
  _presenter = new MMedicalPolicesPresenter()

  submit (): Promise<IMedicalPolicy | IApiErrors> {
    return this._presenter.submit(this.getAttributes())
      .then((data) => {
        if (data.id) { this.setValue('id', data.id) }

        return data
      })
  }

  delete (): Promise<void | IApiErrors> {
    return this._presenter.destroy(this.id)
  }
}
