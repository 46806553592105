<template>
  <panel-heading
    v-loading="loading"
    class="egisz-case__card egisz-case__card_med-documents"
    :title="t('egisz.medical_cases.form.emd_title')"
    icon="fad fa-fw fa-clipboard-list-check"
  >
    <si
      class="med-documents"
    >
      <si-control class="med-documents__controls controls">
        <template #refresh>
          <span class="empty-slot" />
        </template>
        <template #reset>
          <span class="empty-slot" />
        </template>
      </si-control>

      <si-table
        v-if="medDocumentList.length"
        class="med-documents__table"
      >
        <template #header>
          <si-field>
            <div class="col__options">
              <i
                v-tooltip="t('egisz.components.egisz_med_documents.signed_by_pers')"
                class="fad fa-file-certificate success"
              />
            </div>
            <div class="col__created-at">
              {{ t('egisz.med_records.table.header.created_at') }}
            </div>
            <div class="col__doctor">
              {{ t('egisz.med_records.table.header.user') }}
            </div>
            <div class="col__mkb">
              <span>{{ t('egisz.med_records.table.header.emd_name') }}</span>
            </div>
          </si-field>
        </template>

        <template #body>
          <si-field
            v-for="(medDocument) in medDocumentList"
            :key="`medDocument:${medDocument.id}`"
            @click="selectMedDocument(medDocument)"
          >
            <div class="col__options">
              <i
                class="fad fa-file-certificate"
                :class="{ success: medDocument.attachment_signature_status &&
                  medDocument.attachment_signature_status.personal_signed }"
              />
            </div>
            <div class="col__created-at">
              {{ medDocument.formatted_date || '-' }}
            </div>
            <div class="col__doctor">
              {{ getDoctor(medDocument.author_id) }}
            </div>
            <div class="col__mkb">
              <span v-tooltip="medDocument.title">
                {{ medDocument.header }}
              </span>
            </div>
          </si-field>
        </template>
      </si-table>
      <span
        v-else
        class="prompt-notice"
      >
        {{ t('egisz.no_steps_emd') }}
      </span>
    </si>

    <egisz-med-document
      :id="selectedMedDocument.id"
      :entry-id="selectedMedDocument.entry_id"
      :entry-title="selectedMedDocument.header"
      :author-id="selectedMedDocument.author_id"
      :author-full-name="getDoctor(selectedMedDocument.author_id)"
      :clinic-id="selectedMedDocument.clinic_id"
      :type-id="selectedMedDocument.type_id"
      :date="selectedMedDocument.date"
      :modal-visibility-prop="medDocumentModalVisibility"
      :can-manage="canManage"
      @close="medDocumentModalVisibility = false"
      @successSign="$emit('successSign', selectedMedDocument.id)"
    >
      <template #call-btn>
        <span />
      </template>
    </egisz-med-document>
  </panel-heading>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading'
import Si from '@/vue_components/sort_items/si'
import SiControl from '@/vue_components/sort_items/si_control'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import { mapGetters } from 'vuex'
import EgiszMedDocument from '@/vue_components/egisz/egisz_med_document/EgiszMedDocument'

export default {
  name: 'CardEmd',
  components: { EgiszMedDocument, SiField, SiTable, SiControl, Si, PanelHeading },

  props: {
    loading: Boolean,

    medDocumentList: {
      type: Array,
      default: () => [],
    },

    clientId: {
      type: Number,
      required: true,
    },

    readOnly: Boolean,
    canManage: Boolean,
  },

  data () {
    return {
      selectedMedDocument: {},
      medDocumentModalVisibility: false,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctor: 'getDoctorShortName',
    }),

    ...mapGetters({
      getCurrentClinicId: 'GET_APP_CONF_CURRENT_CLINIC_ID',
    }),
  },

  methods: {
    selectMedDocument (medDocument) {
      this.selectedMedDocument = medDocument
      this.medDocumentModalVisibility = true
    },
  },
}
</script>
