<template>
  <popover
    placement="top-end"
    yes-style="primary"
    :cancel-text="t('egisz.components.egisz_clients_table.cancel')"
    :confirm-text="t('egisz.components.egisz_clients_table.send')"
    :disable-confirm-btn="!currentClinicId"
    :disabled="disabled"
    @yes="$emit(
      'submitClient',
      {
        client,
        currentClinicId,
        registrationStatus
      }
    )"
  >
    <template #message>
      <span class="font-size-12">
        {{ t('egisz.components.egisz_clients_table.select_clinic') }}
      </span>
      <simple-select
        :items="egiszReadyClinics"
        :fixed-height="false"
        :value="currentClinicId"
        @change="$updateSync('currentClinicId', $event)"
      />
    </template>

    <template #body>
      <slot name="body" />
    </template>

    <template slot="reference">
      <slot name="reference" />
    </template>
  </popover>
</template>

<script>
import Popover from '@/vue_components/common/popover/popover'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'

export default {
  name: 'SelectClinicPopover',
  components: { SimpleSelect, Popover },
  props: {
    registrationStatus: Boolean,

    currentClinicId: {
      type: Number,
      default: null,
    },

    client: {
      type: Object,
      default: null,
    },

    egiszReadyClinics: {
      type: Array,
      default: () => [],
    },

    disabled: Boolean,
  },
}
</script>
