<template>
  <div class="document-square-field">
    <document-square-field-multiline
      v-if="multiline"
      :value="adaptedValue"
      :line-size="lineSize"
      :min-lines="minLines"
      :max-lines="maxLines"
      :fill-blanks-at-end="fillBlanksAtEnd"
      :fill-symbol="fillSymbol"
    />

    <document-square-field-line
      v-else
      :value="adaptedValue"
      :line-size="lineSize"
      :fill-blanks-at-end="fillBlanksAtEnd"
      :fill-symbol="fillSymbol"
    />
  </div>
</template>

<script>

import { DEFAULT_FILL_SYMBOL, DEFAULT_LINE_SIZE } from '@/vue_present/DocumentBase/DocumentSquareField/const'
import DocumentSquareFieldLine
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLine/DocumentSquareFieldLine.vue'
import DocumentSquareFieldMultiline
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldMultiline/DocumentSquareFieldMultiline.vue'

export default {
  name: 'DocumentSquareField',

  components: { DocumentSquareFieldMultiline, DocumentSquareFieldLine },

  props: {
    value: { type: [String, Number], default: null },
    lineSize: { type: Number, default: DEFAULT_LINE_SIZE },
    multiline: Boolean,

    minLines: { type: Number, default: null },
    maxLines: { type: Number, default: null },

    fillBlanksAtEnd: Boolean,
    fillSymbol: { type: String, default: DEFAULT_FILL_SYMBOL },
  },

  computed: {
    adaptedValue () {
      return this.value?.toString() ?? ''
    },
  },
}
</script>
