import { NestedItemsLogic } from '@/_api/_classes/NestedItems/classes/NestedItemsLogic'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'
import { INestedItemsConfig } from '@/_api/_classes/NestedItems/interfaces/INestedItemsConfig'

export class NestedItemsApi<
  ItemClass extends MListServiceNestedItemsItem,
  ItemProps,
  Presenter extends NestedItemsPresenter = NestedItemsPresenter,
>
  extends NestedItemsLogic<ItemClass, ItemProps> {
  protected presenter: Presenter

  constructor (config: INestedItemsConfig<ItemClass, ItemProps, Presenter>) {
    super(config)

    if (config.presenter) {
      this.presenter = config.presenter

      return
    }

    this.presenter = new NestedItemsPresenter(config.presenterConfig) as Presenter
  }

  async submit () {
    const data = await this.presenter.submit(this.getAttributes())

    if (data.errors) { return data.errors }

    this.nestedItems.set(data[this.nestedItemsPropName])
  }

  async destroy (id: number) {
    const item = this.nestedItems.getItem(id)

    if (!item) { return }

    if (!item.dbId) {
      this.nestedItems.remove(id)

      return
    }

    const { errors } = await this.presenter.destroy({
      ownerId: this.ownerId,
      [this.nestedItemsPropName]: [
        {
          ...item.getValues(),
          _destroy: true,
        },
      ],
    })

    if (errors) { return }

    this.nestedItems.remove(id)
  }
}
