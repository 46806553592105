<template>
  <m-popover
    :cancel-text="t('close')"
    :confirm-text="t('add')"
    yes-type="success"
    cancel-type="primary"
    reverse-buttons-order
    inherited-visibility
    :visibility.sync="visibility"
    @hide="onHide"
    @yes="onYes"
    @no="onNo"
  >
    <template #message>
      <span class="mb-indent">
        {{ t('checkups.fastCreate.addCopyTitle') }}
      </span>
    </template>

    <template #body>
      <m-select
        v-model="client"
        :label="t('client')"
        :items="clientItems"
        full-width
        required
        validator-name="client"
        @registerValidator="onRegisterValidator"
      />

      <m-select
        v-model="fields"
        :items="fieldItems"
        :label="t('checkups.fastCreate.fields')"
        multiple
        full-width
        required
        validator-name="fields"
        @registerValidator="onRegisterValidator"
      />
    </template>

    <template #reference>
      <m-button
        icon="copy"
        type="success"
        :tooltip="t('checkups.fastCreate.addCopyTooltip')"
        :disabled="disabled"
        plus-icon
      />
    </template>
  </m-popover>
</template>

<script>
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  getFastCreateModalFieldItems,
} from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/getFastCreateModalFieldItems'
import { extractId } from '@/_medods_standart_library/msl'
import {
  getFastCreateModalDefaultData,
} from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/getFastCreateModalDefaultData'

export default {
  name: 'FastCreateModalCopyPopover',
  components: {
    MButton,
    MSelect,
    MPopover,
  },

  mixins: [ValidationParentMixin],

  props: {
    clientData: { type: Object, required: true },
    disabled: Boolean,
  },

  emits: ['onClientCopy'],

  data () {
    return {
      ...getFastCreateModalDefaultData(),
      fieldItems: getFastCreateModalFieldItems(),
      visibility: true,
    }
  },

  computed: {
    clientItems () {
      return Object.values(this.clientData).reduce((acc, client) => {
        if (!client.name || !client.surname || !client.birthdate) { return acc }

        return [
          ...acc,
          {
            id: client.key,
            title: `${client.surname} ${client.name} ${Utils.getFormattedDate(client.birthdate)}`,
          },
        ]
      }, [])
    },
  },

  methods: {
    onHide () {
      Object.assign(this, getFastCreateModalDefaultData())

      this.resetValidations()
    },

    onYes () {
      if (this.hasErrors()) { return }

      this.$emit('onClientCopy', {
        key: this.client.id,
        fields: this.fields.map(extractId),
      })
    },

    onNo () {
      this.visibility = false
    },
  },
}
</script>
