import { CLINICS_TABS } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/const'

const FIELDS_TO_TABS_MAP = {
  title: CLINICS_TABS.MAIN,
  legal_name: CLINICS_TABS.MAIN,
  inn: CLINICS_TABS.MAIN,
  time_zone: CLINICS_TABS.MAIN,
  email: CLINICS_TABS.MAIN,
  phone1: CLINICS_TABS.MAIN,
  phone2: CLINICS_TABS.MAIN,

  region: CLINICS_TABS.ADDRESS,
  city: CLINICS_TABS.ADDRESS,
  street: CLINICS_TABS.ADDRESS,
  house: CLINICS_TABS.ADDRESS,

  netrika_guid: CLINICS_TABS.EGISZ,
  frmo_oid: CLINICS_TABS.EGISZ,
  frmo_department_oid: CLINICS_TABS.EGISZ,
}

/**
 * @param {string[]} validators
 * @returns {{ message: string, tabs: string[] }}
 */
export const getValidationInfo = (validators) => {
  const message = validators.map((v) => t(v, { scope: 'activerecord.attributes.clinic' })).join(', ')
  const tabs = Object.keys(
    validators.reduce((acc, item) => {
      acc[FIELDS_TO_TABS_MAP[item]] = true

      return acc
    }, {})
  )

  return {
    message,
    tabs,
  }
}

export const feedbackUrlValidation = (feedbackUrl) => {
  // eslint-disable-next-line
  const urlRegex =/^(https?:\/\/[-a-zA-Z0-9@:%._\/+~#=]{1,256}\.[a-zA-Z0-9]{1,6}(:\d+)?([-a-zA-Z0-9@:%._\/+~#?&//=]*)?)$/

  return () => urlRegex.test(feedbackUrl)
    ? []
    : [t('activerecord.attributes.clinic.feedback_url_invalid')]
}
