import { ItemBase } from '@/_api/_classes/ItemBase'
import { NestedItemsItem } from '@/_api/_classes/NestedItems/classes/NestedItemsItem'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'
import { INestedItemsConfig } from '@/_api/_classes/NestedItems/interfaces/INestedItemsConfig'

export class NestedItemsModel<
  ItemClass extends MListServiceNestedItemsItem,
  ItemProps
> extends ItemBase {
  _permit = [
    'ownerId',
  ]

  ownerId: INestedItemsConfig<ItemClass, ItemProps>['ownerId'] = null

  nestedItems: NestedItemsItem<ItemClass, ItemProps>

  protected nestedItemsPropName: INestedItemsConfig<ItemClass, ItemProps>['nestedItemsPropName']

  constructor ({
    ownerId,
    nestedItemsProps,
    nestedItemsPropName,
    nestedItemsClass,
  }: INestedItemsConfig<ItemClass, ItemProps>) {
    super()

    this.nestedItemsPropName = nestedItemsPropName

    this.nestedItems = new NestedItemsItem<ItemClass, ItemProps>(nestedItemsClass)

    if (ownerId) {
      this.ownerId = ownerId
    }

    if (nestedItemsProps) {
      this.nestedItems.set(nestedItemsProps)
    }
  }

  getAttributes (attributes: string[] = this._permit): Record<string, unknown> {
    return {
      ...super.getAttributes(attributes),
      [this.nestedItemsPropName]: this.nestedItems.getValues(),
    }
  }
}
