<template>
  <m-select
    :value="value"
    :use-custom-result="useCustomResult"
    :m-fixed-height="mFixedHeight"
    :full-width="fullWidth"
    :disabled="disabled"
    :placeholder="placeholder"
    :label="label"
    :items="items"
    filterable
    :custom-filter="defaultSearchFilter"
    :validator-name="validatorName"
    :required="required"
    @registerValidator="$registerValidator($event)"
    @change="$emit('change', $event)"
    @changeItem="$emit('changeItem', $event)"
  />
</template>

<script>
import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'
import { createStore } from '@/vue_components/store/store'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { defaultSearchFilter } from '@/helpers/lambda'

export default {
  name: 'ReusableGlobalNSIList',
  components: { MSelect },

  store: createStore(),

  props: {
    dictionary: { type: String, required: true },
    disabled: Boolean,
    fullWidth: Boolean,
    label: { type: String, default: '' },
    mFixedHeight: Boolean,
    placeholder: { type: String, default: t('choose') },
    required: { type: [Boolean, Function], default: false },
    useCustomResult: { type: String, default: 'simple' },
    validatorName: { type: String, default: null },
    value: { type: [String, Number, Object], default: null },
  },

  emits: [
    'change',
    'changeItem',
  ],

  data () {
    return {
      defaultSearchFilter,
      presenter: new MNSIPresenter(),
    }
  },

  computed: {
    items () {
      switch (this.dictionary) {
        case NSI_DICTIONARIES.RUSSIAN_SUBJECTS:
          return this.$store.getters['globalCatalogs/nsiStore/russianSubjectsDictionaryStore/vxGetRussianSubjects']
        case NSI_DICTIONARIES.ID_CARD_TYPES:
          return this.$store.getters['globalCatalogs/nsiStore/idCardTypesDictionaryStore/vxGetNSIIdCardTypes']
        default:
          return []
      }
    },
  },

  created () {
    if (!this.dictionary) {
      throw new Error('Не указан справочник НСИ')
    }

    this.loadDictionary()
  },

  methods: {
    loadDictionary () {
      switch (this.dictionary) {
        case NSI_DICTIONARIES.RUSSIAN_SUBJECTS:
          return this.$store.dispatch('globalCatalogs/nsiStore/russianSubjectsDictionaryStore/vxLoadRussianSubjects')
        case NSI_DICTIONARIES.ID_CARD_TYPES:
          return this.$store.dispatch('globalCatalogs/nsiStore/idCardTypesDictionaryStore/vxLoadNSIIdCardTypes')
        default:
          return null
      }
    },
  },
}
</script>
