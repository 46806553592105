export const CLIENTS_FILTERS_FIELDS = Object.freeze({
  PERIOD: 'period',
  STATUS: 'status',
})

export const getDefaultClientsFilters = () => ({
  [CLIENTS_FILTERS_FIELDS.PERIOD]: null,
  [CLIENTS_FILTERS_FIELDS.STATUS]: null,
})

export const CASE_FILTERS_FIELDS = Object.freeze({
  NSI_SPECIALTY_ID: 'nsi_speciality_id',
  OPENED_AT: 'opened_at',
  CLOSED_AT: 'closed_at',
  DOCTOR_ID: 'doctor_id',
  CLINIC_ID: 'clinic_id',
  CLIENT_ID: 'client_id',
  CLOSED: 'closed',
  STATUS: 'status',
})

export const getDefaultMedCasesFilters = () => ({
  [CASE_FILTERS_FIELDS.NSI_SPECIALTY_ID]: null, // --> Number
  [CASE_FILTERS_FIELDS.OPENED_AT]: null, // --> [Date, Date]
  [CASE_FILTERS_FIELDS.CLOSED_AT]: null, // --> [Date, Date]
  [CASE_FILTERS_FIELDS.DOCTOR_ID]: null, // --> Number
  [CASE_FILTERS_FIELDS.CLINIC_ID]: null, // --> Number
  [CASE_FILTERS_FIELDS.CLIENT_ID]: null, // --> Number
  [CASE_FILTERS_FIELDS.CLOSED]: null, //  --> Boolean
  [CASE_FILTERS_FIELDS.STATUS]: null, // --> Number?
})

export const MED_DOCUMENTS_FILTERS_FIELDS = Object.freeze({
  DATE: 'date',
  NSI_SPECIALITY_ID: 'nsi_speciality_id',
  AUTHOR_ID: 'author_id',
  STATUS: 'status',
})

export const getDefaultMedDocumentsFilters = () => ({
  [MED_DOCUMENTS_FILTERS_FIELDS.DATE]: null, // --> [Date, Date]
  [MED_DOCUMENTS_FILTERS_FIELDS.NSI_SPECIALTY_ID]: null, // --> Number
  [MED_DOCUMENTS_FILTERS_FIELDS.AUTHOR_ID]: null, // --> Number
  [MED_DOCUMENTS_FILTERS_FIELDS.STATUS]: null, // --> Number?
})
