<template>
  <div
    class="document-square-field-labeled"
    :style="gapStyle"
  >
    <div class="document-square-field-labeled__label">
      <slot name="label" />
    </div>

    <div class="document-square-field-labeled__field">
      <slot name="field" />
    </div>

    <div
      v-if="$slots['post-label']"
      class="document-square-field-labeled__post-label"
    >
      <slot name="post-label" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentSquareFieldLabeled',

  props: {
    gap: { type: Number, default: 5 },
  },

  computed: {
    gapStyle () {
      return `gap: ${this.gap}px`
    },
  },
}
</script>
