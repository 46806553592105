<template>
  <div class="catalog-tree-wrapper">
    <input
      :id="id"
      type="hidden"
      :name="id"
      :value="value"
    >

    <div class="w-100">
      <el-input
        v-tooltip="catalogItemName"
        class="el-input-style-fixer"
        :value="catalogItemName"
        :placeholder="placeholder"
        :clearable="clearable"
        @clear="clearInput"
        @focus="modalOpen"
      >
        <el-button
          slot="prepend"
          class="fad fa-list pointer"
          @click="modalOpen"
        />
      </el-input>
    </div>

    <modal
      :modal-visibility="modalVisibility"
      full-height
      @close="modalClose"
    >
      <template #header>
        <i class="fad fa-list" />
        <span>{{ t('catalog_positions') }}</span>
      </template>

      <template #body>
        <el-input
          v-model="filterText"
          :placeholder="t('search')"
          clearable
        />

        <el-tree
          ref="tree"
          class="margin-top-10"
          :data="treeData"
          :props="defaultProps"
          :node-key="nodeKey"
          :filter-node-method="filterNode"
          :default-checked-keys="[value]"
          auto-expand-parent
          show-checkbox
          check-on-click-node
          @check="onCheck"
        />
      </template>

      <template #footer-left>
        <button
          class="btn btn-sm btn-link text-decoration-none"
          type="button"
          @click="uncheckAll"
        >
          {{ t('egisz.components.tree_list.remove_selection') }}
        </button>

        <button
          class="btn btn-sm btn-link text-decoration-none"
          type="button"
          @click="collapseAll"
        >
          {{ t('egisz.components.tree_list.collapse_all') }}
        </button>
      </template>

      <template #footer-right>
        <button
          type="button"
          class="btn btn-sm btn-primary btn-with-icon"
          @click="modalClose"
        >
          <i class="fad fa-times btn-with-icon_icon" />
          <span> {{ t('close') }} </span>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from '@/vue_components/modal'

export default {
  name: 'CatalogTreeModal',
  components: { Modal },
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },

    defaultProps: {
      type: Object,
      default: () => ({
        id: 'id',
        children: 'children',
        label: 'title',
        leaf: 'leaf',
      }),
    },

    nodeKey: {
      type: String,
      default: 'id',
    },

    value: {
      type: [String, Number],
      default: null,
    },

    // <input :id="id">
    id: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: t('select_from_catalog'),
    },

    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      tree: null,
      modalVisibility: false,
      catalogItemName: '',
      filterText: '',
    }
  },

  watch: {
    filterText (value) {
      this.tree.filter(value)
    },

    treeData () {
      setTimeout(this.setCatalogItemName, 0)
    },

    value (to) {
      if (to) { return }
      this.resetTreeValues()
    },
  },

  mounted () {
    this.tree = this.$refs.tree
  },

  methods: {
    modalOpen () {
      this.modalVisibility = true
    },

    modalClose () {
      this.modalVisibility = false
    },

    setCatalogItemName () {
      const checkedKeys = this.tree.getCheckedNodes()
      if (!checkedKeys.length) { return }

      this.catalogItemName = checkedKeys[0][this.defaultProps.label]
    },

    onCheck (node, treeResults) {
      const nodes = treeResults.checkedNodes.length
        ? [node.id]
        : []

      this.tree.setCheckedKeys(nodes)

      this.catalogItemName = treeResults.checkedNodes.length
        ? node[this.defaultProps.label]
        : ''

      const emittedValue = treeResults.checkedNodes.length
        ? node.id
        : null

      this.$updateSync('value', emittedValue)
      this.modalClose()
    },

    filterNode (value, data) {
      if (!value) { return true }

      const categoryCondition = data.children && data.children.length > 1
      const itemCondition = data.leaf || data.children.length === 0
      const textFound = data[this.defaultProps.label].toLowerCase().indexOf(value.toLowerCase()) !== -1

      return (categoryCondition || itemCondition) && textFound
    },

    clearInput () {
      this.uncheckAll()
    },

    resetTreeValues () {
      this.tree.setCheckedKeys([])
      this.catalogItemName = ''
    },

    uncheckAll () {
      this.resetTreeValues()
      this.$updateSync('value', null)
    },

    collapseAll () {
      this.tree.store.root.childNodes.forEach((node) => {
        node.collapse()
      })
    },
  },
}
</script>
