import { get, set } from 'lodash/object'
import { SerializableParams } from '@/lib/storage/serializable_params'

/**
 * @see {SerializableParams}
 * Пример:
 * ```
 * class Params extends NestedSerializableParams {
 *   constructor () {
 *     // Будет искать данные в localStorage в структуре { path: { to: { params: object } } }
 *     // и сохранять их туда же. При записи все отсутствующие по пути объекты будут созданы.
 *     super('some_key', 'path.to.params')
 *   }
 *
 *   getDefaults () {
 *     return {
 *       foo: 42,
 *       bar: 'baz',
 *     }
 *   }
 * }
 * ```
 * @class NestedSerializableParams
 * @abstract
 */
export class NestedSerializableParams extends SerializableParams {
  /**
   * @param {string} localStorageKey
   * @param {string} path
   */
  constructor (localStorageKey, path) {
    super(localStorageKey)
    this._path = path
    this.restore()
  }

  save () {
    const stored = store.get(this.localStorageKey, {})
    set(stored, this._path, this.state)
    store.set(this.localStorageKey, stored)
  }

  restore () {
    this.state = this.getStored()
  }

  getStored () {
    const stored = store.get(this.localStorageKey)

    return get(stored, this._path, this.getDefaults())
  }
}
