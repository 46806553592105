<template>
  <div class="m-date-picker-page">
    <h1>MDatePicker</h1>
    <h2>Стандартный</h2>
    <m-date-picker
      v-model="selectedDateRange"
      :date-format="dateFormat"
    />
    <h2>DatePicker с кнопками</h2>
    <m-picker-buttons
      :selected-date-range.sync="selectedDateRange"
    />
    <m-date-picker
      v-model="selectedDateRange"
      :date-format="dateFormat"
    />
  </div>
</template>

<script>
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker'
import MPickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'FrontBookPageMDatePicker',
  components: { MPickerButtons, MDatePicker },
  mixins: [
    ValidationParentMixin,
  ],

  data () {
    return {
      selectedDateRange: [],
      dateFormat: Utils.elDateFormat,
    }
  },
}
</script>
