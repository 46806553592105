import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { companyFinalActAdapter } from '@/vue_apps/CheckupsModule/api/CompanyFinalActPresenter/CompanyFinalActAdapter'

export class CompanyFinalActPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_documents_companies_final_act_path,
        all: Routes.checkups_documents_companies_final_acts_path,
        list () {},
      },
      entity: 'companyCheckupFinalAct',
      location: 'CompanyFinalActPresenter',
    })
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      toClientAdapter: companyFinalActAdapter.toClient,
      ...config,
    })
  }

  create (companyCheckupId, config = {}) {
    return super.create({
      document: {
        companyCheckupId,
      },
    }, {
      toJson: true,
      ...config,
    })
  }

  update (data, config = {}) {
    return super.update(data, {
      toServerAdapter: companyFinalActAdapter.toServer,
      toJson: true,
      ...config,
    })
  }
}
