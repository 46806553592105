import { BUTTON_STYLE, BUTTON_TYPE } from '@/vue_present/_base/buttons/MButton/const'

export const customPropsValidators = {
  buttonStyleValidator (value) {
    return BUTTON_STYLE.includes(value) || !value
  },

  typeValidator (value) {
    return BUTTON_TYPE.includes(value)
  },
}
