import { TAB_ADDRESS_FORMAL } from './consts.js'

export default {
  activeTab: {
    type: String,
    default: TAB_ADDRESS_FORMAL,
  },

  title: {
    type: String,
    required: true,
  },

  typeIds: {
    type: Array,
    required: true,
  },

  types: {
    type: Array,
    required: true,
  },

  ownership: {
    type: String,
    required: true,
  },

  inn: {
    type: String,
    required: true,
  },

  kpp: {
    type: String,
    required: true,
  },

  ogrn: {
    type: String,
    required: true,
  },

  okved: {
    type: String,
    required: true,
  },

  registrationDate: {
    type: String,
    required: true,
  },

  discount: {
    type: String,
    required: true,
  },

  note: {
    type: String,
    required: true,
  },

  formalAddressIndex: {
    type: String,
    required: true,
  },

  formalAddressCountry: {
    type: String,
    required: true,
  },

  formalAddressRegion: {
    type: String,
    required: true,
  },

  formalNsiRussianSubjectId: {
    type: [Object, Number],
    default: null,
  },

  formalAddressArea: {
    type: String,
    required: true,
  },

  formalAddressCity: {
    type: String,
    required: true,
  },

  formalAddressStreet: {
    type: String,
    required: true,
  },

  formalAddressHouse: {
    type: String,
    required: true,
  },

  formalAddressFlat: {
    type: String,
    required: true,
  },

  factAddressIndex: {
    type: String,
    required: true,
  },

  factAddressCountry: {
    type: String,
    required: true,
  },

  factAddressRegion: {
    type: String,
    required: true,
  },

  factNsiRussianSubjectId: {
    type: [Object, Number],
    default: null,
  },

  factAddressArea: {
    type: String,
    required: true,
  },

  factAddressCity: {
    type: String,
    required: true,
  },

  factAddressStreet: {
    type: String,
    required: true,
  },

  factAddressHouse: {
    type: String,
    required: true,
  },

  factAddressFlat: {
    type: String,
    required: true,
  },

  phone1: {
    type: String,
    required: true,
  },

  phone2: {
    type: String,
    required: true,
  },

  web: {
    type: String,
    required: true,
  },

  email: {
    type: String,
    required: true,
  },

  headName: {
    type: String,
    required: true,
  },

  headSurname: {
    type: String,
    required: true,
  },

  headSecondName: {
    type: String,
    required: true,
  },

  headPosition: {
    type: String,
    required: true,
  },

  headOperatesBy: {
    type: String,
    required: true,
  },

  validationMessages: {
    type: Object,
    required: true,
  },

  targetedDisable: {
    type: Object,
    default: () => ({
      title: false,
    }),
  },

  formStyle: {
    type: String,
    default: 'turquoise',
  },

  suggestion: Boolean,
  disabled: Boolean,
  spinner: Boolean,
  spinnerTypes: Boolean,
}
