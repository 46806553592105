import { CheckupClientPresenter } from '@/vue_apps/CheckupsModule/api/CheckupClientPresenter/CheckupClientPresenter'
import { FastCreateClientModel } from '@/vue_apps/CheckupsModule/classes/FastCreateClient/FastCreateClientModel'
import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'
import { ICheckupClient } from '@/vue_apps/CheckupsModule/interfaces/ICheckupClientSearch'
import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'
import { SNILS_THE_MASK } from '@/specific/clients/methods/snils'

export class FastCreateClientApi extends FastCreateClientModel {
  _presenter = new CheckupClientPresenter()

  _personalCheckPresenter = new MPersonalCheckupPresenter()

  async searchClient (params: object = {}) {
    return await this._presenter.searchClient({
      ...params,
      searchQuery: Utils.joinTruthy([this.surname, this.name]),
      birthdate: this.birthdate,
    })
  }

  async tryLoadDataFromLastPersonalCheckup (id: ICheckupClient['id']) {
    const { errors, checkup }
      : { errors: unknown; checkup: IPersonalCheckup } =
      await this._personalCheckPresenter.lastCheckup(id)

    if (errors) { return }

    this.snils = Utils.applyMask(checkup.client.snils, SNILS_THE_MASK)
    this.position = checkup.position
    this.nsiProfession = checkup.nsiProfession
    this.factors = checkup.factors
  }
}
