/**
 * Фильтр нецифр
 * @param {String} value
 * @returns {String}
 */
const getNumbersFromString = (value) => value.replace(/\D/g, '')

/**
 * Проверка номера СНИЛС
 * @param {String} rawSnilsString
 * @returns {Boolean}
 *
 * @description Алгоритм формирования контрольного числа Страхового номера <br/>
 * https://www.consultant.ru/document/cons_doc_LAW_124607/68ac3b2d1745f9cc7d4332b63c2818ca5d5d20d0/ <br/>
 *
 * 0) Проверка контрольного числа Страхового номера проводится только для номеров больше номера 001-001-998
 * 1) Вычислить сумму произведений цифр СНИЛС (с 1-й по 9-ю) на следующие коэффициенты — 9, 8, 7, 6, 5, 4, 3, 2, 1 (т.е. номера цифр в обратном порядке). <br/>
 * 2) Вычислить контрольное число от полученной суммы следующим образом:
 * если она меньше 100, то контрольное число равно этой сумме;
 * если равна 100, то контрольное число равно 0;
 * если больше 100, то вычислить остаток от деления на 101 и далее:
 * если остаток от деления равен 100, то контольное число равно 0;
 * в противном случае контрольное число равно вычисленному остатку от деления. <br/>
 * 3) Сравнить полученное контрольное число с двумя младшими разрядами СНИЛС. Если они равны, то СНИЛС верный. <br/>
 *
 * Некоторые частные случаи: <br/>
 * 99 / !101! = 99 <br/>
 * 100 / !101! = 00 <br/>
 * 101 / !101! = 00 <br/>
 * 102 / !101! = 01 <br/>
 */
export const checkSnils = (rawSnilsString) => {
  const SNILS_LENGTH = 11
  const CHARACTER_SEPARATOR = ''
  const STRING_START = 0
  const REAL_PART_LENGTH = 9
  const CHECK_NUMBER_PART_LENGTH = 2
  const SNILS_NUMBER_PRODUCT_LIMIT = 100
  const SNILS_MODULO_AFTER_DIVISION_ON_SNILS_NUMBER_PRODUCT_LIMIT = 0
  const SNILS_DIVIDER_GREATER_THAN_SNILS_NUMBER_PRODUCT_LIMIT = 101
  const SNILS_MIN_NUMBER = 1001998 + 1 // 1_001_999

  const numbersOnly = getNumbersFromString(rawSnilsString)
  if (!numbersOnly.length) { return false }
  if (numbersOnly.length !== SNILS_LENGTH) { return false }

  const realNumbersString = numbersOnly.substr(STRING_START, REAL_PART_LENGTH)
  if (parseInt(realNumbersString) < SNILS_MIN_NUMBER) { return false }

  const realNumbers = realNumbersString
    .split(CHARACTER_SEPARATOR)
    .map((character) => parseInt(character))

  const checkNumberValue = parseInt(numbersOnly.substr(REAL_PART_LENGTH, CHECK_NUMBER_PART_LENGTH))

  const productOfNumbers = realNumbers
    .reduce((sum, numeric, index, arr) => sum + numeric * (REAL_PART_LENGTH - index), 0)

  if (productOfNumbers < SNILS_NUMBER_PRODUCT_LIMIT) {
    return checkNumberValue === productOfNumbers
  }

  const modulo = productOfNumbers % SNILS_DIVIDER_GREATER_THAN_SNILS_NUMBER_PRODUCT_LIMIT
  if (modulo === SNILS_NUMBER_PRODUCT_LIMIT || modulo === SNILS_DIVIDER_GREATER_THAN_SNILS_NUMBER_PRODUCT_LIMIT) {
    return checkNumberValue === SNILS_MODULO_AFTER_DIVISION_ON_SNILS_NUMBER_PRODUCT_LIMIT
  }

  return checkNumberValue === modulo
}
