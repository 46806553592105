import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class MTelephonyPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'telephony',
      location: 'MTelephonyPresenter',
      routes: { one () {}, all () {} },
    })
  }

  callOut ({ phoneNumber, clientId }) {
    const options = {
      url: Routes.start_call_out_telephony_telephony_route_index_path(),
      data: camelToSnake({
        outcomingCall: { phoneNumber, clientId },
      }),
    }

    const notification = new MRequestNotification(
      'callOut',
      'telephonyCallOutMessage',
      this.entity
    )

    return this.post(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch((err) => {
        MRequestError.onRequest(notification)(err)
        const selectedProvider = gon.application.telephony.selected_provider
        Utils.reportError(
          'MTelephonyPresenter::callOut',
          t('telephony.' + selectedProvider + '.errors.' + err.responseJSON)
        )(err)
      })
  }
}
