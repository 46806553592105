<template>
  <div class="clinic-online-access-tab">
    <!-- Онлайн-запись -->
    <m-switch
      :active-text="ts('accessible_from_client_widget')"
      :value="clinic.accessibleFromClientWidget"
      :disabled="disabled"
      @change="clinic.setValue('accessibleFromClientWidget', $event)"
    />

    <!-- Запись по услуге -->
    <m-switch
      :active-text="ts('services_available_in_client_widget')"
      :value="clinic.servicesAvailableInClientWidget"
      :disabled="disabled"
      @change="clinic.setValue('servicesAvailableInClientWidget', $event)"
    />

    <!-- Запись по специалисту -->
    <m-switch
      :active-text="ts('specialists_available_in_client_widget')"
      :value="clinic.specialistsAvailableInClientWidget"
      :disabled="disabled"
      @change="clinic.setValue('specialistsAvailableInClientWidget', $event)"
    />

    <!-- Отображать страницу с информацией о клинике -->
    <m-switch
      :active-text="ts('show_online_recording_information')"
      :value="clinic.showOnlineRecordingInformation"
      :disabled="disabled"
      @change="clinic.setValue('showOnlineRecordingInformation', $event)"
    />

    <!-- Информация о клинике -->
    <m-textarea
      class="mt-indent"
      :label="ts('online_recording_information')"
      :autosize="{ minRows: 4 }"
      :value="clinic.onlineRecordingInformation"
      :disabled="disabled"
      @input="clinic.setValue('onlineRecordingInformation', $event)"
    />

    <!-- Ссылка для отзывов -->
    <m-input
      v-tooltip="ts('feedback_url_requirement')"
      :label="ts('feedback_url_label')"
      :value="clinic.feedbackUrl"
      :disabled="disabled"
      :required="isFeedbackUrlRequired"
      validator-name="feedback_url"
      @registerValidator="$emit('registerValidator', $event)"
      @input="clinic.setValue('feedbackUrl', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { ClinicsI18nMixin } from '@/vue_apps/catalogs_root/ClinicsCatalog/mixins/clinicsI18nMixin'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { feedbackUrlValidation } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/getValidationInfo'

export default defineComponent({
  name: 'ClinicOnlineAccessTab',
  components: { MInput, MTextarea, MSwitch },
  mixins: [ClinicsI18nMixin],
  props: {
    clinic: { type: Clinic, required: true },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    isFeedbackUrlRequired () {
      return this.clinic.feedbackUrl
        ? feedbackUrlValidation(this.clinic.feedbackUrl)
        : false
    },
  },
})
</script>
