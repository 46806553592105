import { isPlainObject } from 'lodash'
import {
  canManageBoolean,
  canManageFalsy,
  canManageSecurity,
  canManageStructured,
} from '@/vue_present/mixins/DisabledMixinLogic/DisabledMixinLogic'

/**
 *
 * @type {{
 *  props: {
 *    disabled: {type: [Boolean, String, Object], value: null}
 *  },
 *  computed: {
 *    canManage(): {tooltip: String, disabled: Boolean}
 *  },
 * }}
 */
export const DisabledMixin = {
  props: {
    /**
     * @param {String} disabled - название модели для проверки в Services.security
     * @param {Boolean} disabled - заблокирован или нет
     * @param {{ disabled: Boolean, tooltip: String }} disabled - заблокирован или нет
     */
    disabled: {
      type: [
        Boolean,
        Number,
        String,
        Object,
      ],
      default: null,
    },
  },

  computed: {
    /** @return {{tooltip: String, disabled: Boolean}} */
    canManage () {
      if (typeof this.disabled === 'boolean' || typeof this.disabled === 'number') {
        return canManageBoolean(this.disabled)
      }

      if (!this.disabled) {
        return canManageFalsy()
      }

      if (isPlainObject(this.disabled)) {
        return canManageStructured(this.disabled)
      }

      return canManageSecurity(this.disabled)
    },
  },
}
