<template>
  <popover
    :placement="placement"
    yes-style="primary"
    :confirm-text="t('egisz.components.egisz_med_documents.form.to_sign')"
    :cancel-text="t('close')"
    :disable-confirm-btn="disableConfirmBtn"
    :disabled="Boolean(cannotSignTooltipTextOrFalse)"
    @yes="$emit('yes')"
  >
    <template #title>
      <span class="empty-slot" />
    </template>

    <template #message>
      <span class="empty-slot" />
    </template>

    <template #body>
      <slot name="body">
        <!-- f.e. use SignPopoverView.vue in parent -->
      </slot>
    </template>

    <template slot="reference">
      <div
        v-tooltip="cannotSignTooltipTextOrFalse"
        class="inline-block"
      >
        <slot name="reference">
          <button
            v-loading="loading"
            type="button"
            class="btn btn-sm btn-link"
            :disabled="loading || cannotSignTooltipTextOrFalse || disabled"
            @click="$emit('onSignClick')"
          >
            {{ t('egisz.components.egisz_med_documents.form.to_sign') }}
          </button>
        </slot>
      </div>
    </template>
  </popover>
</template>

<script>
import Popover from '@/vue_components/common/popover/popover'
import { BASE } from '@/vue_components/egisz/egisz_module/router/route_names'

export default {
  name: 'SignPopover',
  components: { Popover },
  props: {
    placement: {
      type: String,
      default: 'top-end',
    },

    loading: Boolean,
    disableConfirmBtn: Boolean,
    requireOrganizationSign: Boolean,
    requirePersonalSign: Boolean,
    disabled: Boolean,
  },

  computed: {
    isEgiszModule () {
      return (this.$route && this.$route.fullPath.includes(BASE)) || false
    },

    isNotEgiszModule () {
      return !this.isEgiszModule
    },

    canSignPersonal () {
      return Utils.can(Utils.ACTIONS.MANAGE_SELF, 'EgiszSign') ||
          Utils.can(Utils.ACTIONS.MANAGE, 'EgiszSign')
    },

    canSignOrganization () {
      return Utils.can(Utils.ACTIONS.MANAGE, 'OrganizationSign')
    },

    cannotSignByPersonal () {
      return !(this.isNotEgiszModule && this.canSignPersonal)
    },

    cannotSignByOrganization () {
      return !(this.isEgiszModule && this.canSignOrganization)
    },

    cannotSignTooltipTextOrFalse () {
      if (this.disabled) {
        return t('insufficient_access_rights')
      }

      if (this.requireOrganizationSign && this.cannotSignByOrganization) {
        return t('egisz.messages.access.cant_sign_organization')
      }

      if (this.requirePersonalSign && this.cannotSignByPersonal) {
        return t('egisz.messages.access.cant_sign_personal')
      }

      return false
    },
  },
}
</script>
