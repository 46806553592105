<template>
  <div class="m-textarea-page">
    <h1>MTextarea</h1>
    <h2>Обычная</h2>
    <m-textarea
      id="textarea-simple"
      v-model="mTextareaValue"
      validator-name="not_autosize_textarea"
      label="Какой-то текст"
      :required="(value) => value.length < 10 ? ['Минимум 10 символов'] : []"
      @registerValidator="onRegisterValidator"
    />

    <h2>Autosize</h2>
    <m-textarea
      id="textarea-autosize"
      v-model="mTextareaValue"
      validator-name="autosize_textarea"
      label="Ещё какой-то текст"
      :autosize="{ minRows: 3, maxRows: 32}"
      :required="true"
      @registerValidator="onRegisterValidator"
    />

    <h2>Disabled</h2>
    <m-textarea
      id="textarea-disabled"
      v-model="mTextareaValue"
      label="И ещё какой-то текст"
      disabled
    />

    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="onSubmit"
    >
      {{ t('save') }}
    </button>

    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="resetValidations"
    >
      {{ t('clear') }}
    </button>

    <button
      type="button"
      class="btn btn-primary btn-sm"
      @click="resetValidations({
        not_autosize_textarea: ['Что-то пошло не так'],
        autosize_textarea: ['Ещё что-то пошло не так'],
      })"
    >
      Серверная валидация
    </button>
  </div>
</template>

<script>

import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'FrontBookPageMTextarea',
  components: { MTextarea },
  mixins: [ValidationParentMixin],

  data () {
    return {
      mTextareaValue: '',
    }
  },

  methods: {
    onSubmit () {
      if (this.hasErrors()) {
        Utils.reportError('here', 'Ошибки валидации!')()

        return
      }

      Utils.reportSuccess('Нет ошибок, всё заполнено корректно')()
    },
  },
}
</script>
