import { request } from '@/lib/transport/request'
import { getCertificatesAdapter } from '@/api_entities/workplaces/workplace_adapters'

export const workplaceEndpoint = {
  name: 'workplaceEndpoint',
  getAll (data) {
    const options = {
      type: 'get',
      url: Routes.list_workplaces_path(),
      data,
    }

    return request(options).promise
  },

  getAllConnected () {
    return workplaceEndpoint.getAll({ connected: true })
  },

  getCertificates (workplaceId, localMachine = false) {
    const options = {
      type: 'get',
      url: Routes.certificate_list_workplace_path(workplaceId),
      data: {
        local_machine: localMachine,
      },
    }

    return request(options, getCertificatesAdapter.toClient).promise
  },

  get (id) {
    /* реализация получения записи по id */
  },

  submit (data) {
    return data.id
      ? workplaceEndpoint.update(data)
      : workplaceEndpoint.create(data)
  },

  create (data) {
    /* реализация создания записи */
  },

  update (data) {
    /* реализация обновления записи с data.id */
  },

  destroy (id) {
    /* реализация удаления записи по id */
  },

  restore (id) {
    /* реализация восстановления записи по id */
  },
}
