<template>
  <m-table-row :use-sortable="useSortable">
    <!-- Чекбокс -->
    <m-table-row-cell
      v-if="useCheckboxes"
      name="tableCheckbox"
    >
      <m-checkbox
        v-model="vmMassCheckBoxValue"
        :indeterminate="indeterminate"
      />
    </m-table-row-cell>

    <!-- Колонки -->
    <slot
      v-for="header in headers"
      :name="`tf-${header}`"
    >
      <m-table-row-cell
        v-if="tableHeaders[header]"
        :key="`footerRow:${header}`"
        :name="header"
        :width="tableHeaders[header].width"
      >
        <span
          v-tooltip="total[header] && total[header].tooltip"
          :class="total[header] && total[header].css"
        >
          {{ extractValue(header) }}
        </span>
      </m-table-row-cell>
    </slot>

    <!-- Редактирование -->
    <m-table-row-cell
      v-if="useEdit"
      name="optionEdit"
    />

    <!-- Удаление -->
    <m-table-row-cell
      v-if="useDelete"
      name="optionDelete"
    />
  </m-table-row>
</template>

<script>
import MTableRow from '@/vue_present/_base/Tables/MTable/MTableRow.vue'
import MTableRowCell from '@/vue_present/_base/Tables/MTable/MTableRowCell.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { MTableRowSharedProps } from '@/vue_present/_base/Tables/MTableGenerator/props/MTableRowSharedProps'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'MTableGeneratorTableFooterRow',
  components: { MCheckbox, MTableRow, MTableRowCell },
  props: {
    ...MTableRowSharedProps,

    /** @type {TTableSchemaHeaders} items */
    tableHeaders: {
      type: Object,
      required: true,
    },

    /** @type {TTableSchemaItem} */
    total: PropsTypes.Object({}),

    indeterminate: Boolean,
    massCheckBoxValue: Boolean,
  },

  emits: [
    'update:massCheckBoxValue',
  ],

  data () {
    return {
      activeSortableName: '',
    }
  },

  computed: {
    /** @returns {string[]} */
    headers () {
      return Object.keys(this.tableHeaders)
    },

    vmMassCheckBoxValue: {
      get () { return this.massCheckBoxValue },
      set (value) { this.$updateSync('massCheckBoxValue', value) },
    },
  },

  methods: {
    extractValue (header) {
      return this.total[header]?.value || null
    },
  },
}
</script>
