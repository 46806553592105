import { baseValidationMixin } from '@/vue_components/mixins/base_validation_mixin/baseValidationMixin'
import {
  getMedDocumentGroupValidations,
  getSignatureGroupValidations,
  isMedDocumentGroup,
  isSignatureGroup,
} from '@/vue_components/egisz/egisz_med_document/validation/validation_composables'

export default {
  mixins: [baseValidationMixin],

  data () {
    return {
      validationMessages: {
        date: [],
        header: [],
        typeId: [],
        workplaceId: [],
        certificateId: [],
      },
    }
  },

  created () {
    this.generateWatchers('medDocument')
    this.generateWatchers('sign')
  },

  methods: {
    validate (validatedData) {
      let validations = []

      if (isMedDocumentGroup(validatedData)) {
        validations = [ ...validations, ...getMedDocumentGroupValidations(validatedData) ]
      }

      if (isSignatureGroup(validatedData)) {
        validations = [ ...validations, ...getSignatureGroupValidations(validatedData) ]
      }

      return this.allCorrect(validations)
    },
  },
}
