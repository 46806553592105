<template>
  <filter-base :label="label">
    <simple-select
      class="filter__input"
      :value="value"
      :items="items"
      :clearable="clearable"
      :filterable="filterable"
      :fixed-height="fixedHeight"
      :value-attribute="valueAttribute"
      :label-attribute="labelAttribute"
      need-empty-option
      @change="$emit('change', $event)"
    />
  </filter-base>
</template>

<script>
import FilterBase from '@/vue_components/egisz/egisz_module/components/filters/FilterBase'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'

export default {
  name: 'SelectFilter',
  components: { SimpleSelect, FilterBase },

  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: [Number, String],
      default: null,
    },

    items: {
      type: Array,
      required: true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    filterable: {
      type: Boolean,
      default: true,
    },

    valueAttribute: {
      type: String,
      default: 'id',
    },

    labelAttribute: {
      type: String,
      default: 'title',
    },

    fixedHeight: Boolean,
  },
}
</script>
