import { ItemBase } from '@/_api/_classes/ItemBase'
import { ICompanyFinalAct } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalAct'
import {
  ICompanyFinalActWorkerWithCompletedCheckup,
} from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorkerWithCompletedCheckup'
import { ICompanyFinalActWorker } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorker'
import {
  ICompanyFinalActWorkerWithDiagnosisStringContraindicationFactors,
} from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorkerWithDiagnosis'
import { IDate } from '@/_declarations/IDate'
import { ICompanyFinalActDiseases } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActDiseasis'

export class CompanyFinalActModel extends ItemBase {
  _permit = [
    'id',
    'headerClinicName',
    'headerClinicAddress',
    'headerClinicOgrn',
    'titleDate',
    'companyTitle',
    'companyValue',
    'departmentTitle',
    'departmentValue',
    'totalWorkersCount',
    'totalFemaleWorkersCount',
    'totalUnderEighteenWorkersCount',
    'totalWorkersWithDisabilityCount',
    'workersWithHarmfulWorkCount',
    'workersWithRequiredCheckupsCount',
    'femaleWorkersWithRequiredCheckupsCount',
    'underEighteenWorkersWithRequiredCheckupsCount',
    'workersWithDisabilityWithRequiredCheckupsCount',
    'totalCheckupWorkersCount',
    'totalCheckupFemaleWorkersCount',
    'totalCheckupUnderEighteenWorkersCount',
    'totalCheckupWorkersWithDisabilityCount',
    'totalWorkersWithCompletedCheckupCount',
    'totalFemaleWorkersWithCompletedCheckupCount',
    'totalUnderEighteenWorkersWithCompletedCheckupCount',
    'totalDisabledWorkersWithCompletedCheckupCount',
    'percentageCoverageValue',
    'workersWithCompletedCheckupTableText',
    'workersWithCompletedCheckupTableHeader',
    'workersWithCompletedCheckupTableData',
    'workersWithUncompletedCheckupValue',
    'femaleWorkersWithUncompletedCheckupValue',
    'workersWithUncompletedCheckupTableText',
    'workersWithUncompletedCheckupTableHeader',
    'workersWithUncompletedCheckupTableData',
    'workersWithoutCheckupValue',
    'femaleWorkersWithoutCheckupValue',
    'workersWithoutCheckupTableText',
    'workersWithoutCheckupTableHeader',
    'workersWithoutCheckupTableData',
    'workersWithoutContraindicationsCount',
    'workersWithContraindicationsCount',
    'workersInNeedOfExtraMedicalExaminationCount',
    'workersInNeedOfExaminationInOccupational',
    'workersInNeedOfOutpatientExamination',
    'workersInNeedOfInpatientExamination',
    'workersInNeedOfSanatoriumExamination',
    'workersInNeedOfDispensary',
    'workersWithDiagnosesText',
    'workersWithDiagnosesTableHeader',
    'workersWithDiagnosesTableData',
    'chronicDiseasesListText',
    'chronicDiseasesListTableData',
    'professionalDiseasesListText',
    'professionalDiseasesListTableData',
    'infectiousDiseasesListText',
    'infectiousDiseasesListTableData',
    'lastActResultText',
    'lastActResultValue',
    'companyRecommendationsText',
    'companyRecommendationsValue',
    'responsibleFullName',
  ]

  id: number

  headerClinicName: string

  headerClinicAddress: string

  headerClinicOgrn: string

  titleDate: IDate

  companyTitle: string

  companyValue: string

  departmentTitle: string | null

  departmentValue: string | null

  totalWorkersCount: number | null

  totalFemaleWorkersCount: number | null

  totalUnderEighteenWorkersCount: number | null

  totalWorkersWithDisabilityCount: number | null

  workersWithHarmfulWorkCount: number | null

  workersWithRequiredCheckupsCount: number | null

  femaleWorkersWithRequiredCheckupsCount: number | null

  underEighteenWorkersWithRequiredCheckupsCount: number | null

  workersWithDisabilityWithRequiredCheckupsCount: number | null

  totalCheckupWorkersCount: number | null

  totalCheckupFemaleWorkersCount: number | null

  totalCheckupUnderEighteenWorkersCount: number | null

  totalCheckupWorkersWithDisabilityCount: number | null

  totalWorkersWithCompletedCheckupCount: number | null

  totalFemaleWorkersWithCompletedCheckupCount: number | null

  totalUnderEighteenWorkersWithCompletedCheckupCount: number | null

  totalDisabledWorkersWithCompletedCheckupCount: number | null

  percentageCoverageValue: number | null

  workersWithCompletedCheckupTableText: string

  workersWithCompletedCheckupTableHeader: string[]

  workersWithCompletedCheckupTableData: ICompanyFinalActWorkerWithCompletedCheckup[]

  workersWithUncompletedCheckupValue: number | null

  femaleWorkersWithUncompletedCheckupValue: number | null

  workersWithUncompletedCheckupTableText: string

  workersWithUncompletedCheckupTableHeader: string[]

  workersWithUncompletedCheckupTableData: ICompanyFinalActWorker[]

  workersWithoutCheckupValue: number | null

  femaleWorkersWithoutCheckupValue: number | null

  workersWithoutCheckupTableText: string

  workersWithoutCheckupTableHeader: string[]

  workersWithoutCheckupTableData: ICompanyFinalActWorker[]

  workersWithoutContraindicationsCount: number | null

  workersWithContraindicationsCount: number | null

  workersInNeedOfExtraMedicalExaminationCount: number | null

  workersInNeedOfExaminationInOccupational: number | null

  workersInNeedOfOutpatientExamination: number | null

  workersInNeedOfInpatientExamination: number | null

  workersInNeedOfSanatoriumExamination: number | null

  workersInNeedOfDispensary: number | null

  workersWithDiagnosesText: string

  workersWithDiagnosesTableHeader: string[]

  workersWithDiagnosesTableData: ICompanyFinalActWorkerWithDiagnosisStringContraindicationFactors[]

  chronicDiseasesListText: string

  chronicDiseasesListTableData: ICompanyFinalActDiseases[]

  professionalDiseasesListText: string

  professionalDiseasesListTableData: ICompanyFinalActDiseases[]

  infectiousDiseasesListText: string

  infectiousDiseasesListTableData: ICompanyFinalActDiseases[]

  lastActResultText: string

  lastActResultValue: string

  companyRecommendationsText: string

  companyRecommendationsValue: string

  responsibleFullName: string

  constructor (props?: Partial<ICompanyFinalAct>) {
    super()

    this._fillProps(props)
  }
}
