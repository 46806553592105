<template>
  <div class="tax-certificate-2024-printable-page-one">
    <tax-certificate2024-printable-page-header
      class="mb-indent-mid"
      :certificate="certificate"
      :page="FIRST_PAGE"
    />

    <!--  Форма по КНД  -->
    <b>{{ t$('kndTitle') }}</b>

    <!--  Заголовок  -->
    <b
      class="tax-certificate-2024-printable-page-one__header mb-indent-mid"
      v-html="t$('certificateTitle')"
    />

    <!--  Номер сравки\Номер корректировки\Отчетный год  -->
    <tax-certificate2024-printable-certificate-identification
      class="mb-indent-mid"
      :certificate="certificate"
    />

    <!--  Наименование клинки или ИП  -->
    <tax-certificate2024-printable-clinic
      class="mb-indent-mid"
      :certificate="certificate"
    />

    <!--  Даннные физ. лица (налогоплательщик)  -->
    <tax-certificate2024-printable-personal-information
      class="mb-indent-mid"
      :certificate="certificate"
      is-taxpayer
    />

    <!--  Налогоплательщик и пациент являются одним лицом  -->
    <document-square-field-labeled class="mb-indent-mid">
      <template #label>
        <span>{{ t$('taxpayerIsClient') }}</span>
      </template>

      <template #field>
        <document-square-field
          style="margin-left: 117px"
          :value="Number(certificate.taxpayerIsClient)"
          :line-size="1"
        />
      </template>

      <template #post-label>
        <div class="tax-certificate-2024-printable-page-one__taxpayer-is-client-field">
          <span>{{ `0 - ${t('no')}` }}</span>
          <span>{{ `1 - ${t('yes')}` }}</span>
        </div>
      </template>
    </document-square-field-labeled>

    <!--  Сумма расходов по коду 1  -->
    <tax-certificate2024-printable-sum
      class="mb-indent-mid"
      :service-code="1"
      :value="certificate.sumOne"
    />

    <!--  Сумма расходов по коду 2  -->
    <tax-certificate2024-printable-sum
      class="mb-indent-mid"
      :service-code="2"
      :value="certificate.sumTwo"
    />

    <div class="tax-certificate-2024-printable-page-one__separated-block">
      <div class="tax-certificate-2024-printable-page-one__separated-block-employee">
        <!--  Сотрудник  -->
        <tax-certificate2024-printable-employee
          class="mb-indent-mid"
          :certificate="certificate"
        />

        <!--  Справка составляется на N страницах  -->
        <document-square-field-labeled style="gap: 20px">
          <template #label>
            <span>{{ t$('certificateCountPagesTitle') }}</span>
          </template>

          <template #field>
            <document-square-field
              :value="pageCount"
              :line-size="3"
            />
          </template>

          <template #post-label>
            <span>{{ t('pages') }}</span>
          </template>
        </document-square-field-labeled>
      </div>

      <div class="flex justify-content-center flex-basis-50 pt-indent-small">
        <span>{{ t$('qrTitle') }}</span>
      </div>
    </div>

    <div class="flex flex-column">
      <span>{{ t$('secondNameExplanation') }}</span>
      <span>{{ t$('innExplanation') }}</span>
    </div>

    <div class="flex justify-content-between">
      <document-black-square />
      <document-black-square />
    </div>
  </div>
</template>

<script>
import TaxCertificate2024PrintablePageHeader
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintablePageHeader.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import {
  FIRST_PAGE,
  SECOND_PAGE,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024PrintableConsts'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import TaxCertificate2024PrintableCertificateIdentification
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintableCertificateIdentification.vue'
import TaxCertificate2024PrintableClinic
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintableClinic.vue'
import TaxCertificate2024PrintablePersonalInformation
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintablePersonalInformation.vue'
import DocumentSquareFieldLabeled
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLabeled/DocumentSquareFieldLabeled.vue'
import DocumentSquareField from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareField.vue'
import TaxCertificate2024PrintableSum
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintableSum.vue'
import TaxCertificate2024PrintableEmployee
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintableEmployee.vue'
import DocumentBlackSquare from '@/vue_present/DocumentBase/DocumentBlackSquare/DocumentBlackSquare.vue'

export default {
  name: 'TaxCertificate2024PrintablePageOne',

  components: {
    DocumentBlackSquare,
    TaxCertificate2024PrintableEmployee,
    TaxCertificate2024PrintableSum,
    DocumentSquareField,
    DocumentSquareFieldLabeled,
    TaxCertificate2024PrintablePersonalInformation,
    TaxCertificate2024PrintableClinic,
    TaxCertificate2024PrintableCertificateIdentification,
    TaxCertificate2024PrintablePageHeader,
  },

  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  data () {
    return {
      FIRST_PAGE,
    }
  },

  computed: {
    pageCount () {
      return this.certificate.taxpayerIsClient
        ? FIRST_PAGE
        : SECOND_PAGE
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.print')
  },
}
</script>
