<template>
  <m-button
    class="mt-10"
    type="primary"
    :size="size"
    @click="onClick()"
  >
    {{ text }}
  </m-button>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'MButtonBack',

  components: { MButton },

  props: {
    onClickHandler: { type: Function, default: null },
    size: { type: String, default: 'mini' },
    text: { type: String, default: t('back') },
  },

  methods: {
    onClick () {
      return this.onClickHandler
        ? this.onClickHandler()
        : this.$router.back()
    },
  },
}

</script>
