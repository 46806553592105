const X_GAP = 15
const Y_GAP = 15

/**
 * @param {HTMLElement} menu
 * @param {number} x
 * @param {number} y
 * @private
 */
export const setMenuXY = (menu, x = 0, y = 0) => {
  menu.style.left = `${x}px`
  menu.style.top = `${y}px`
}

/**
 * @param {HTMLElement} menu
 * @param {number} x
 * @param {number} y
 * @private
 */
export const calculateXY = (menu, x = 0, y = 0) => {
  const { clientHeight: menuHeight, clientWidth: menuWidth } = menu
  const { clientHeight, clientWidth } = document.documentElement

  const finalX = x + menuWidth + X_GAP > clientWidth
    ? clientWidth - menuWidth - X_GAP
    : x

  const finalY = y + menuHeight + Y_GAP > clientHeight
    ? clientHeight - menuHeight - Y_GAP
    : y

  return { x: finalX, y: finalY }
}
