<template>
  <m-select-lazy
    class="reusable-specialties-list"
    :value="value"
    :label="label"
    :fetch-method="(params) => specialtiesPresenter.list(params)"
    :m-fixed-height="mFixedHeight"
    :placeholder="placeholder"
    use-first-time-fetch
    use-first-time-fetch-on-open
    :use-custom-result="useCustomResult"
    :filterable="filterable"
    :clearable="clearable"
    :required="required"
    :disabled="disabled"
    :multiple="multiple"
    :full-width="fullWidth"
    :validator-name="validatorName"
    @change="$emit('onSpecialtyChange', $event)"
    @registerValidator="$registerValidator($event)"
  />
</template>

<script>
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MSpecialtyPresenter } from '@/_api/MSpecialty/MSpecialtyPresenter'

export default {
  name: 'ReusableSpecialtiesList',

  components: { MSelectLazy },

  props: {
    value: { type: [Number, Object, Array], default: null },
    label: { type: String, default: t('specialty') },
    placeholder: { type: String, default: t('all_specialties') },
    validatorName: { type: String, default: null },
    useCustomResult: { type: String, default: null },
    mFixedHeight: { type: Boolean, default: true },
    filterable: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    required: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    fullWidth: Boolean,
  },

  emits: [
    'onSpecialtyChange',
    'registerValidator',
  ],

  data () {
    return {
      specialtiesPresenter: new MSpecialtyPresenter(),
    }
  },
}
</script>
