<template>
  <m-select
    :items="vxGetDoctorClinics"
    :value="value"
    :label="label"
    :m-fixed-height="mFixedHeight"
    :placeholder="placeholder"
    :required="required"
    :validator-name="validatorName"
    :disabled="disabled"
    :clearable="clearable"
    :multiple="multiple"
    :use-custom-result="useCustomResult"
    :full-width="fullWidth"
    @change="$emit('onClinicChange', $event)"
    @registerValidator="$registerValidator($event)"
  />
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { createStore } from '@/vue_components/store/store'
import { mapGetters } from 'vuex'

export default {
  name: 'ReusableDoctorClinicsList',
  components: { MSelect },
  store: createStore(),
  model: {
    prop: 'value',
    event: 'onClinicChange',
  },

  props: {
    value: { type: [Number, Object, Array], default: null },
    label: { type: String, default: t('clinic') },
    placeholder: { type: String, default: t('all_clinics') },
    validatorName: { type: String, default: null },
    useCustomResult: { type: String, default: null },

    mFixedHeight: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    required: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    fullWidth: Boolean,
  },

  emits: [
    'onClinicChange',
    'registerValidator',
  ],

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxGetDoctorClinics: 'vxGetDoctorClinics',
    }),
  },
}
</script>
