import { navigation } from '@/vue_present/_FrontBook/router/navigation'

export const routes = [
  navigation.intro,
  navigation.mIcon,
  navigation.mButton,
  navigation.mTimePicker,
  navigation.mDatePicker,
  navigation.mSiGenerator,
  navigation.mInput,
  navigation.mTextarea,
  navigation.mSelect,
  navigation.mCheckbox,
  navigation.mButtonsGroup,
  navigation.mFormShow,

  navigation.disabledMixin,
  navigation.paginationMixin,
  navigation.validationMixins,

  navigation.introAll,
]
