<template>
  <div class="date-range-picker-btn">
    <items-list
      v-model="selectedItemIds"
      v-bind="$attrs"
      id-item-field="id"
      displayed-item-field="title"
      item-class="vue-list-item date-range-btn"
      :multi-select="false"
      :items="buttons"
      :disabled="disabled"
      placement="bottom-start"
    />
  </div>
</template>

<script>
import ItemsList from '../../items_list.vue'

const beginningOfDay = () => moment().hour(0).minutes(0).seconds(0)
const endOfDay = () => moment().hour(23).minutes(59).seconds(59)

const beginningOfYesterday = () => beginningOfDay().subtract(1, 'days')
const endOfYesterday = () => endOfDay().subtract(1, 'days')

const startOfWeek = () => beginningOfDay().subtract(1, 'week')
const endOfWeek = endOfDay

const startOfMonth = () => beginningOfDay().subtract(1, 'month')
const endOfMonth = endOfDay

const DEFAULT_BUTTONS = () => Object.freeze([
  { range: () => [startOfMonth().toDate(), endOfMonth().toDate()], id: 4, title: T.month },
  { range: () => [startOfWeek().toDate(), endOfWeek().toDate()], id: 3, title: T.week },
  { range: () => [beginningOfDay().toDate(), endOfDay().toDate()], id: 1, title: T.today },
  { range: () => [beginningOfYesterday().toDate(), endOfYesterday().toDate()], id: 2, title: T.yesterday },
])

export default {
  name: 'DateRangePickerBtn',

  components: { ItemsList },

  model: {
    prop: 'dateRange',
    event: 'change',
  },

  props: {
    dateRange: {
      type: Array,
      default: undefined,
    },

    buttons: {
      type: Array,
      default: DEFAULT_BUTTONS,
    },

    fallbackValue: {
      type: Array,
      default: undefined,
    },

    disabled: Boolean,
  },

  computed: {
    selectedItemIds: {
      set (ids) {
        const button = this.buttons.find((button) => button.id === ids[0])
        this.$emit('change', button ? button.range() : this.fallbackValue)
      },

      get () {
        if (!Array.isArray(this.dateRange) || !this.dateRange.length) return []

        const button = this.buttons.find((button) => {
          const [start1, end1] = button.range()
          const [start2, end2] = this.dateRange

          return moment(start1).isSame(start2, 'day') &&
            moment(end1).isSame(end2, 'day')
        })

        return button ? [button.id] : []
      },
    },
  },
}
</script>
