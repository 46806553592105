import { removeLegacyValidationWrapper, renderLegacyValidationWrapper } from '@/helpers/legacy_validation_wrapper'
import { checkSnils } from '@/specific/clients/methods/snils/checkSnils'

export const SNILS_MASK = '999-999-999 99'
export const SNILS_THE_MASK = '###-###-### ##'
export const SNILS_MASK_PLACEHOLDER = '___-___-___ __'

/**
 * Флаг для исключения постоянных вызовов removeLegacyValidationWrapper в snilsInput.inputmask
 * @type {boolean}
 */
let validationCanBeRemoved = false

/**
 * @param {String} querySelector - .some-class, #some-id, button
 */
export const snilsAddMask = (querySelector) => {
  const snilsInput = $(querySelector)
  snilsInput.inputmask(SNILS_MASK, {
    placeholder: SNILS_MASK_PLACEHOLDER,
    removeMaskOnSubmit: true,
    onBeforeWrite () {
      if (!validationCanBeRemoved) { return }
      removeLegacyValidationWrapper(querySelector, true)
      validationCanBeRemoved = false
    },
  })
}

/**
 * @param {String} querySelector - .some-class, #some-id, button
 */
export const snilsRemoveMask = (querySelector) => {
  const snilsInput = $(querySelector)
  $(snilsInput).inputmask('remove')
}

/**
 * @param {String} querySelectorBtn - .some-class, #some-id - submit button
 * @param {String} querySelectorCB - .some-class, #some-id - checkbox input
 * @param {String} querySelectorSnils - .some-class, #some-id - snils input
 */
export const setSubmitClickHook = (querySelectorBtn, querySelectorCB, querySelectorSnils) => {
  const egiszEnabled = gon.application.egisz_module_enabled

  const submitBtn = document.querySelector(querySelectorBtn)
  if (!submitBtn) { return }

  const cbSendDataToEgiszSelector = document.querySelector(querySelectorCB)
  if (!cbSendDataToEgiszSelector) { return }

  const snilsInput = document.querySelector(querySelectorSnils)
  if (!snilsInput) { return }

  submitBtn.addEventListener('click', (e) => {
    removeLegacyValidationWrapper(querySelectorSnils, true)

    const sendDataToEgisz = cbSendDataToEgiszSelector.checked
    const snils = snilsInput.value

    if (!egiszEnabled) { return }
    if (!sendDataToEgisz) { return }
    if (checkSnils(snils)) { return }

    e.preventDefault()

    const errorText = t('egisz.snils.incorrect')
    Utils.reportError('setSubmitClickHook', errorText)()

    renderLegacyValidationWrapper(querySelectorSnils, [errorText], true)
    validationCanBeRemoved = true
  })
}

export const subscribeToSnilsValidationError = () => {
  PubSub.on('page.form.appointmentClient.submitError', (target, { errors }) => {
    if (!errors) { return }
    if (errors.snils) {
      Utils.reportError('subscribeToSnilsValidationError', t('egisz.snils.incorrect'))()
      $('.switch_right_container_btn_on').trigger('click')
    }
  })
}
