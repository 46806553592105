<template>
  <div
    v-if="show"
    :class="`panel panel-primary panel-collapsed panel-collapsed--${direction}`"
    @click="$emit('panel-collapsed-toggle')"
  >
    <div class="panel-heading panel-collapsed-heading">
      <span :class="`fad fa-angle-${direction}`" />
    </div>
    <div class="panel-body panel-collapsed-body">
      <div class="panel-collapsed-content panel-heading-title">
        <span :class="[icon, 'panel-heading-icon']" />
        <h3
          class="panel-title cut with-icon"
        >
          {{ title }} <span
            v-if="count"
            :class="['panel-collapsed-count', {'count-more-zero': count > 0}]"
          >({{ count }})</span>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelHeadingCollapsed',
  props: {
    direction: {
      type: String,
      default: 'right',
    },
    icon: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    count: {
      default: null,
      type: Number,
    },
  },
}
</script>
