import { orUndefined } from '@/_medods_standart_library/msl'

export const guaranteeLetterAdapter = {
  toServer (data) {
    return {
      id: orUndefined(data.id),
      issueDate: data.issueDate,
      number: data.number,
      medicalPolicyId: data.medicalPolicyId,
      sum: data.sum,
    }
  },
}
