import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { customPropsValidators } from '@/vue_present/_base/buttons/MButton/CustomPropsValidators'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'

export const MButtonsGroupPropsMixin = {
  props: {
    value: PropsTypes.Custom([Array, String, Number, Object]),
    items: PropsTypes.Array(),
    max: PropsTypes.Number(),

    valueKey: PropsTypes.String('id'),
    labelAttribute: PropsTypes.String('title'),

    radio: { type: [Boolean, String], default: false },

    buttonsType: PropsTypes.String('', false, customPropsValidators.typeValidator),
    size: PropsTypes.String('small'),

    useCustomResult: PropsTypes.String(DEFAULT_RESULT_TYPES.OBJECT),
    useTScope: { type: [String, Boolean], default: null },

    hideButtonMb: Boolean,
    icon: { type: String, default: '' },
  },
}
