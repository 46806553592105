<template>
  <div class="services-status">
    <div
      v-if="notificationsModuleEnabled"
      class="services-status__module-enabled"
    >
      <div class="services-status__notification-checkbox messages-settings-notification-checkbox">
        <m-switch
          :value="servicesStatusSwitch"
          :active-text="t('sms.services_status_settings')"
          @change="setServicesStatusSwitch"
        />
      </div>

      <div
        v-if="servicesStatusSwitch"
        class="services-status__settings"
      >
        <messages-priorities
          v-if="messagesPriorities.length"
          :messages-priorities="messagesPriorities"
          @updatePriorities="setMessagesPriorities"
        />

        <whatsapp-timeout-settings
          :delivery-waiting-time="deliveryWaitingTime"
          :read-waiting-time="readWaitingTime"
          :delivery-waiting-time-variables="REMIND_DELIVERY_WAITING_TIME_VARIABLES"
          :read-waiting-time-variables="SERVICE_STATUS_READ_WAITING_TIME_VARIABLES"
          @updateDeliveryWaitingTime="setServicesStatusDeliveredWaitingTime"
          @updateReadWaitingTime="setServicesStatusReadWaitingTime"
        />

        <div class="services-status__services-status-sms-template">
          <variables-editor
            :editor-value="smsTemplateValue"
            :editor-title="t('whatsapp.notificationTemplate')"
            :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
            :preview-request="fetchTemplatePreview"
            :variables-list="messageVariablesList"
            required
            validator-name="smsTemplate"
            template-type="services_status"
            @registerValidator="onRegisterValidator"
            @changeEditorValue="setServicesStatusSmsTemplate"
          />
        </div>

        <variables-editor-whatsapp
          v-if="isWabaActive"
          :editor-value="wabaTemplateText"
          :variables-list="messageVariablesList"
          @changeWabaEditorValue="setWabaTemplateText"
        >
          <template #templateSelector>
            <m-select
              :value="wabaTemplateId"
              :items="wazzupTemplatesList"
              option-label="title"
              value-key="templateGuid"
              :placeholder="t('whatsapp.chooseValue')"
              @change="insertTemplate"
            />
          </template>
        </variables-editor-whatsapp>

        <div class="services-status__dispatch-method">
          <div class="title mb-15">
            {{ t('whatsapp.dispatchMethod') }}
          </div>
          <div class="dispatch-method__value-buttons">
            <m-buttons-group
              :value="servicesStatusTriggerType"
              :items="SERVICES_STATUS_DISPATCH_METHODS"
              radio
              size="mini"
              @change="setServicesStatusTriggerType"
            />
          </div>
        </div>
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="t('introduction.messages')"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup.vue'
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import {
  PRIORITY_BUTTONS_ITEMS,
  REMIND_DELIVERY_WAITING_TIME_VARIABLES,
  SERVICE_STATUS_READ_WAITING_TIME_VARIABLES,
  SERVICES_STATUS_DISPATCH_METHODS,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import {
  SERVICES_STATUS_MESSAGE_VARIABLES_LIST,
  SERVICES_STATUS_MESSAGE_VARIABLES_LIST_BY_ENTRY,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import { variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import Introduction from '@/vue_components/introduction/introduction.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import {
  findAndExtractWabaTemplateTextWithVariablesLabels,
  extractWabaTemplateText,
  replaceVariablesLabelsByValues,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'services_status'
const BY_ORDER_TRIGGER_TYPE = 'by_order'

export default {
  name: 'ServicesStatus',
  components: {
    Introduction,
    MessagesPriorities,
    MSelect,
    MButton,
    WhatsappTimeoutSettings,
    VariablesEditorWhatsapp,
    VariablesEditor,
    MButtonsGroup,
    MSwitch,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaTemplateText: '',
      PRIORITY_BUTTONS_ITEMS,
      SERVICES_STATUS_MESSAGE_VARIABLES_LIST,
      SERVICES_STATUS_DISPATCH_METHODS,
      REMIND_DELIVERY_WAITING_TIME_VARIABLES,
      SERVICE_STATUS_READ_WAITING_TIME_VARIABLES,
      fetchTemplatePreview,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      servicesStatusSwitch: 'vxGetServicesStatusSwitch',
      servicesStatusSmsTemplate: 'vxGetServicesStatusSmsTemplate',
      messagesPriorities: 'vxGetMessagesPriorities',
      servicesStatusTriggerType: 'vxGetServicesStatusTriggerType',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      wabaTemplateVariables: 'vxGetServicesStatusWabaTemplateVariables',
      servicesStatusWabaTemplateId: 'vxGetServicesStatusWabaTemplateId',
      deliveryWaitingTime: 'vxGetServicesStatusDeliveredWaitingTime',
      readWaitingTime: 'vxGetServicesStatusReadWaitingTime',
      isWabaActive: 'vxIsWabaActive',
    }),

    smsTemplateValue () {
      return this.servicesStatusSmsTemplate
    },

    messageVariablesList () {
      const variables = this.servicesStatusTriggerType === BY_ORDER_TRIGGER_TYPE
        ? SERVICES_STATUS_MESSAGE_VARIABLES_LIST
        : SERVICES_STATUS_MESSAGE_VARIABLES_LIST_BY_ENTRY

      return variables.filter((el) => !el.disabled)
    },

    wabaTemplateId () {
      return { templateGuid: this.servicesStatusWabaTemplateId }
    },
  },

  created () {
    const baseRequest = this.vxFetchMessagesConfigurations()

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
      this.vxFetchMessagesPriorities(CURRENT_PRIORITY_TYPE),
    ]

    Promise.all([baseRequest, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
      vxFetchMessagesPriorities: 'vxFetchMessagesPriorities',
      vxFetchSmsConfigurations: 'vxFetchSmsConfigurations',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      setServicesStatusSwitch: 'vxSetServicesStatusSwitch',
      setServicesStatusSmsTemplate: 'vxSetServicesStatusSmsTemplate',
      setMessagesPriorities: 'vxSetMessagesConfigurationsPriorities',
      setServicesStatusTriggerType: 'vxSetServicesStatusTriggerType',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setServicesStatusWabaTemplateId: 'vxSetServicesStatusWabaTemplateId',
      setServicesStatusDeliveredWaitingTime: 'vxSetServicesStatusDeliveredWaitingTime',
      setServicesStatusReadWaitingTime: 'vxSetServicesStatusReadWaitingTime',
    }),

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
    },

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialWabaTemplateText()
    },

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.servicesStatusWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.servicesStatusWabaTemplateId,
          templateVariablesValues: this.wabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setServicesStatusWabaTemplateId(template?.templateGuid)
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const whatsappEditorValueToServer = replaceVariablesLabelsByValues(this.wabaTemplateText || '')

      const messagesConfigurations = {
        servicesStatus: this.servicesStatusSwitch,
        servicesStatusTriggerType: this.servicesStatusTriggerType,
        servicesStatusTemplate: this.smsTemplateValue,
      }
      const whatsappConfigurations = {
        servicesStatusWabaTemplateVariables: variablesParserWithChild(whatsappEditorValueToServer),
        servicesStatusWabaTemplateId: this.servicesStatusWabaTemplateId,
        servicesStatusDeliveredWaitingTime: this.deliveryWaitingTime,
        servicesStatusReadWaitingTime: this.readWaitingTime,
      }
      const channelsPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.messagesPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(channelsPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
