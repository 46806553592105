<template>
  <div class="document-square-field-money">
    <document-square-field-line
      :value="adaptedValue.integerPart"
      :line-size="lineSize"
    />

    <document-bolt-dot />

    <document-square-field-line
      :value="adaptedValue.floatPart"
      :line-size="precision"
      fill-blanks-at-end
      fill-symbol="0"
    />
  </div>
</template>

<script>
import DocumentSquareFieldLine
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLine/DocumentSquareFieldLine.vue'
import DocumentBoltDot from '@/vue_present/DocumentBase/DocumentBoltDot/DocumentBoltDot.vue'

export default {
  name: 'DocumentSquareFieldMoney',

  components: { DocumentBoltDot, DocumentSquareFieldLine },

  props: {
    value: { type: [String, Number], default: null },
    lineSize: { type: Number, default: 13 },
    precision: { type: Number, default: 2 },
  },

  computed: {
    adaptedValue () {
      const separatedMoney = Utils
        .toMoney(this.value)
        .toString()
        .split('.')

      return {
        integerPart: separatedMoney[0],
        floatPart: separatedMoney[1] ?? '',
      }
    },
  },
}
</script>
