<template>
  <modal
    v-if="visible"
    :modal-visibility="visible"
    modal-size="md"
    @close="$emit('close')"
  >
    <template #header>
      <i class="fad fa-fw fa-calendar-day" />
      <span>{{ `${t('egisz.med_records.title_singular')} № ${medDocument.id}` }}</span>
    </template>

    <template #body>
      <div class="flex-form mb-0">
        <div class="form-label">
          {{ t('client') }}
        </div>
        <div class="form-value">
          <a
            :href="$routes.client_path(client.id)"
            target="_blank"
          >
            {{ clientInfo }}
          </a>
        </div>

        <div class="form-label">
          {{ t('clinic') }}
        </div>
        <div class="form-value">
          {{ medDocument.clinic_title }}
        </div>

        <div class="form-label">
          {{ t('created_at') }}
        </div>
        <div class="form-value">
          {{ medDocument.formatted_date }}
        </div>

        <div class="form-label">
          {{ t('egisz.components.egisz_med_records.title') }}
        </div>
        <div class="form-value">
          <a
            :href="$routes.entry_path(medDocument.entry_id)"
            target="_blank"
          >
            {{ medDocumentInfo }}
          </a>
        </div>

        <div class="form-label">
          {{ t('egisz.registration_status') }}
        </div>
        <div class="form-value">
          {{ t(`egisz.common_statuses.${medDocument.status}`) }}
        </div>

        <div class="form-label">
          {{ t('egisz.components.egisz_med_documents.signed_by_pers') }}
        </div>
        <div class="form-value">
          <i
            class="font-size-17"
            :class="getSignIconByValue(personalSigned)"
          />
          <slot name="personal-sign" />
        </div>

        <div class="form-label">
          {{ t('egisz.components.egisz_med_documents.signed_by_org') }}
        </div>
        <div class="form-value">
          <i
            class="font-size-17"
            :class="getSignIconByValue(organizationSigned)"
          />
          <slot name="organization-sign" />
        </div>
      </div>
    </template>

    <template #footer-left>
      <div
        v-tooltip="disabledTooltipOrFalse"
        class="inline-block"
      >
        <button
          v-if="canRegister && personalSigned && organizationSigned"
          v-loading="loading"
          type="button"
          class="btn btn-sm btn-success btn-with-icon"
          :disabled="disabledTooltipOrFalse"
          @click="$emit('registerMedDocument', { medDocumentId: medDocument.id, clientId: client.id, typeId: medDocument.type_id })"
        >
          <i class="fad fa-share btn-with-icon_icon" />
          <span>{{ t('egisz.register') }}</span>
        </button>
      </div>
    </template>

    <template #footer-right>
      <popover
        v-if="canDelete"
        placement="top-end"
        :disabled="!canManage"
        @yes="$emit('deleteMedDocument', medDocument.id)"
      >
        <div
          slot="reference"
          v-tooltip="!canManage && t('insufficient_access_rights')"
          class="inline-block"
        >
          <button
            v-loading="loading"
            type="button"
            :disabled="!canManage"
            class="btn btn-sm btn-danger btn-with-icon"
          >
            <i class="fad fa-trash btn-with-icon_icon" />
            <span>{{ t('delete') }}</span>
          </button>
        </div>
      </popover>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal'
import Popover from '@/vue_components/common/popover/popover'

/**
 * @description
 * emit:
 * 1) deleteMedDocument, medDocument.id
 * 2) registerMedDocument, medDocument.id
 * 3) close
 */
export default {
  name: 'EgiszEmdModal',
  components: { Popover, Modal },
  props: {
    visible: Boolean,
    canRegister: Boolean,
    loading: Boolean,

    medDocument: {
      type: Object,
      default: () => ({}),
    },

    personalSigned: Boolean,
    organizationSigned: Boolean,
    canManage: Boolean,
    canDelete: Boolean,
    canUserSendToEgisz: Boolean,
  },

  computed: {
    client () {
      return (this.medDocument && this.medDocument.entry_client) || {}
    },

    clientInfo () {
      return [
        this.client.full_name,
        this.client.age,
      ].join(', ')
    },

    medDocumentInfo () {
      return [
        this.medDocument.header,
        this.medDocument.formatted_date,
        this.medDocument.author_full_name,
      ].join(', ')
    },

    disabledTooltipOrFalse () {
      if (!this.canUserSendToEgisz) {
        return t('egisz.cannotUserSendToEgisz')
      }

      if (!this.canManage) {
        return t('insufficient_access_rights')
      }

      return false
    },
  },

  methods: {
    getSignIconByValue (value) {
      return value ? 'fad fa-check success' : 'fas fa-times red'
    },
  },
}
</script>
