export const CATEGORIES = Object.freeze({
  MAIN: 'main',
})

export const BASE = '/egisz'
export const INTRO = 'egisz-intro'

export const CLIENTS = Object.freeze({
  INDEX: 'clients-menu-item',
  TABLE: 'clients-table',
})

export const CASES = Object.freeze({
  INDEX: 'cases-menu-index',
  TABLE: 'cases-table',
  NEW: 'cases-new',
  EDIT: 'cases-edit',
})

export const EGISZ_MED_DOCUMENTS = Object.freeze({
  INDEX: 'stand-alone-med-documents-menu-index',
  TABLE: 'stand-alone-med-documents-table',
  NEW: 'stand-alone-med-documents-new',
  EDIT: 'stand-alone-med-documents-edit',
})

export const EGISZ_SEMDS = Object.freeze({
  INDEX: 'semd-menu-index',
  TABLE: 'semd-table',
  NEW: 'semd-new',
  EDIT: 'semd-edit',
})
