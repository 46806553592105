/**
 * Возвращает дату с установленным временем (по умолчанию 00:00:00)
 * @param {Number[]} [time] [hour, min, sec, ms]
 * @returns {Date}
 */
export const getDate = (time = [0, 0, 0, 0]) => {
  const date = new Date()
  date.setHours(...time)

  return date
}
