import { GLOBAL_HOLDER_SCOPES } from '@/_global_scripts/GLOBAL_CONSTS'

export const getSemdSignParams = (holderScope) => {
  return {
    setValueMethod: holderScope === GLOBAL_HOLDER_SCOPES.ORGANIZATION
      ? 'setOrganizationSigner'
      : 'setDoctorSigner',
    signMethod: holderScope === GLOBAL_HOLDER_SCOPES.ORGANIZATION
      ? 'signByOrganization'
      : 'signByDoctor',
  }
}
