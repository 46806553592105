export default class Payment {
  constructor (attr) {
    this.clinic_id = attr.clinicId
    this.user_id = attr.userId
    this.payer_client_id = attr.payerClientId || null
    this.payer_company_id = parseInt(attr.payerCompanyId) || null
    this.distribution = attr.distribution || null

    this.by_cash = attr.byCash || 0
    this.by_card = attr.byCard || 0
    this.by_cashless = attr.byCashless || 0
    this.by_balance = attr.byBalance || 0
    this.by_credit = attr.byCredit || 0
    this.surrender = attr.surrender || 0
    this.deposit = attr.deposit || 0

    if (attr.summaryInputUser && attr.paymentSpec) {
      let payInField = attr.paymentSpec

      this[payInField] = attr.summaryInputUser > 0
        ? attr.summaryInputUser - this.surrender
        : 0
    }

    this.comment = attr.comment || ''

    this.destination_order_id = attr.destinationOrderId || null
    this.destination_client_id = attr.destinationClientId || null

    this.payment_entries_attributes = []

    this.kind = attr.kind
    this.base_kind = attr.baseKind

    Object.defineProperties(this, {
      total_paid: {
        get () { return this.totalPaid },
        enumerable: true,
      },
      total_income: {
        get () { return this.totalIncome },
        enumerable: true,
      },
      errors: {
        value: [],
        enumerable: false,
        writable: true,
      },
    })
  }

  get totalIncome () {
    return this._summary([this.by_cash, this.by_card, this.by_cashless, this.deposit])
  }

  get totalPaid () {
    return this._summary([this.total_income, this.by_balance])
  }

  set byCard (value) {
    this.by_credit = value
  }

  set byCash (value) {
    this.by_credit = value
  }

  set byCashless (value) {
    this.by_credit = value
  }

  set byBalance (value) {
    this.by_credit = value
  }

  set byCredit (value) {
    this.by_credit = value
  }

  get _summaryInput () {
    return this._summary([this.by_cash, this.by_card, this.by_cashless, this.by_balance])
  }

  _withDeposit (n) {
    return n + this.deposit
  }

  _numToFix (value) {
    return typeof value === 'number' ? value.toFixed(2) : value
  }

  _summary (arr) {
    return arr.reduce((acc, current) => acc + Utils.toMoney(current), 0)
  }
}
