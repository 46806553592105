import UI from './ui.js'
import Telephony from './telephony.js'
import WSSubscriptionManager from './ws_subscription_manager.js'
import SecurityContextHolder from './security_context_holder.js'
import ClientFeedbacks from './client_feedbacks.js'
import EpicPubSub from './epic_pub_sub.js'
import Workplace from './workplace.js'
import Storage from './storage.js'
import PhoneMask from './phone_mask.js'
import ClinicSwitch from './clinic_switch.js'

export class ServiceRepository {
  ui: Record<any, any>

  wsSubscriptions: Record<any, any>

  telephony: Record<any, any>

  security: Record<any, any>

  pubSub: Record<any, any>

  workplace: Record<any, any>

  storage: Record<any, any>

  clientFeedbacks: Record<any, any>

  phoneMask: Record<any, any>

  clinicSwitch: Record<any, any>

  constructor () {
    this.ui = UI
    this.wsSubscriptions = WSSubscriptionManager
    this.telephony = Telephony
    this.security = SecurityContextHolder
    this.pubSub = EpicPubSub
    this.workplace = Workplace
    this.storage = Storage
    this.clientFeedbacks = ClientFeedbacks
    this.phoneMask = PhoneMask
    this.clinicSwitch = ClinicSwitch
  }
}

const instance = new ServiceRepository()

export default instance
