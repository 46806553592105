<template>
  <div
    class="flex flex-column"
    v-bind="$attrs"
  >
    <!-- Поиск по организациям dadata-->
    <m-suggestion-select
      type="party"
      :placeholder="t('search_by_title_inn_or_ogrn')"
      use-company-search
      @change="$emit('suggestCompany', $event)"
    />

    <!-- Название -->
    <m-input
      :value="title"
      :label="t('title')"
      required
      validator-name="title"
      @input="$updateSync('title', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />

    <!-- Тип компании -->
    <m-select
      :value="companyType"
      full-width
      :label="t('company_type')"
      required
      multiple
      filterable
      :custom-filter="defaultSearchFilter"
      validator-name="company_type"
      :items="companyTypes"
      @change="$updateSync('companyType', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />

    <!-- Форма собственности -->
    <m-input
      :label="t('type_of_company')"
      :value="typeOfCompany"
      @input="$updateSync('typeOfCompany', $event)"
    />

    <!-- ИНН -->
    <m-input
      :label="t('inn')"
      :value="individualTaxNumber"
      validator-name="individual_tax_number"
      @input="$updateSync('individualTaxNumber', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />

    <!-- КПП -->
    <m-input
      :label="t('kpp')"
      :value="kpp"
      validator-name="kpp"
      @input="$updateSync('kpp', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />

    <!-- ОГРН -->
    <m-input
      :label="t('ogrn')"
      :value="ogrn"
      validator-name="ogrn"
      @input="$updateSync('ogrn', $event)"
      @registerValidator="$emit('registerValidator', $event)"
    />

    <!-- ОКВЕД -->
    <m-input
      :label="t('okved')"
      :value="okved"
      @input="$updateSync('okved', $event)"
    />

    <!-- Дата регистрации -->
    <m-date-picker
      :label="t('registration_date')"
      :value="registrationDate"
      @change="$updateSync('registrationDate', $event)"
    />

    <!-- Дополнительно -->
    <m-textarea
      :label="t('additional')"
      :rows="2"
      class="pb-0"
      :value="note"
      @input="$updateSync('note', $event)"
    />
  </div>
</template>

<script>
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MSuggestionSelect from '@/vue_present/_base/MSuggestionSelect/MSuggestionSelect.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { defaultSearchFilter } from '@/helpers/lambda'

export default {
  name: 'CompanyMainInfo',
  components: { MSuggestionSelect, MDatePicker, MTextarea, MInput, MSelect },

  props: {
    companyTypes: PropsTypes.Array(),

    title: PropsTypes.String(),
    companyType: PropsTypes.Array(),
    typeOfCompany: PropsTypes.String(),
    individualTaxNumber: PropsTypes.String(),
    kpp: PropsTypes.String(),
    ogrn: PropsTypes.String(),
    okved: PropsTypes.String(),
    registrationDate: PropsTypes.Custom(Date),
    note: PropsTypes.String(),
  },

  data () {
    return {
      defaultSearchFilter,
    }
  },
}
</script>
