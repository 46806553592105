export const UNKNOWN_DOCUMENT_FNS_CODE = '91'

export const FNS_CODES = {
  PASSPORT: '21',
  BIRTH_CERTIFICATE: '03',
  MILITARY_ID: '07',
  TEMP_MILITARY_ID: '08',
  FOREIGN_PASSPORT: '10',
  REFUGEE_IN_RF: '11',
  RESIDENCE_PERMIT: '12',
  REFUGEE_CARD_ID: '13',
  TEMP_CARD_ID: '14',
  TEMP_RF_AUTHORIZATION: '15',
  TEMPORARY_ASYLUM_CERTIFICATE: '19',
  FOREIGN_BIRTH_CERTIFICATE: '23',
  OFFICER_ID: '24',
  VETERAN_ID: '27',
  OTHER: '91',
}

export const FNS_CODES_DICT = Object.freeze({
  [FNS_CODES.PASSPORT]: 'Паспорт гражданина Российской Федерации',
  [FNS_CODES.BIRTH_CERTIFICATE]: 'Свидетельство о рождении',
  [FNS_CODES.MILITARY_ID]: 'Военный билет',
  [FNS_CODES.TEMP_MILITARY_ID]: 'Временное удостоверение, выданное взамен военного билета',
  [FNS_CODES.FOREIGN_PASSPORT]: 'Паспорт иностранного гражданина',
  [FNS_CODES.REFUGEE_IN_RF]: 'Свидетельство о рассмотрении ходатайства о признании лица беженцем на территории Российской Федерации по существу',
  [FNS_CODES.RESIDENCE_PERMIT]: 'Вид на жительство в Российской Федерации',
  [FNS_CODES.REFUGEE_CARD_ID]: 'Удостоверение беженца',
  [FNS_CODES.TEMP_CARD_ID]: 'Временное удостоверение личности гражданина Российской Федерации',
  [FNS_CODES.TEMP_RF_AUTHORIZATION]: 'Разрешение на временное проживание в Российской Федерации',
  [FNS_CODES.TEMPORARY_ASYLUM_CERTIFICATE]: 'Свидетельство о предоставлении временного убежища на территории Российской Федерации',
  [FNS_CODES.FOREIGN_BIRTH_CERTIFICATE]: 'Свидетельство о рождении, выданное уполномоченным органом иностранного государства',
  [FNS_CODES.OFFICER_ID]: 'Удостоверение личности военнослужащего Российской Федерации',
  [FNS_CODES.VETERAN_ID]: 'Военный билет офицера запаса',
  [FNS_CODES.OTHER]: 'Иные документы',
})

export const ID_CARD_TO_FNS_DICT = Object.freeze({
  1: FNS_CODES.PASSPORT,
  4: FNS_CODES.BIRTH_CERTIFICATE,
  10: FNS_CODES.MILITARY_ID,
  9: FNS_CODES.FOREIGN_PASSPORT,
  12: FNS_CODES.RESIDENCE_PERMIT,
  11: FNS_CODES.REFUGEE_CARD_ID,
  13: FNS_CODES.TEMP_RF_AUTHORIZATION,
  14: FNS_CODES.OTHER,
})

export const NSI_TO_FNS_DICT = Object.freeze({
  1: FNS_CODES.PASSPORT,
  6: FNS_CODES.BIRTH_CERTIFICATE,
  28: FNS_CODES.TEMP_MILITARY_ID,
  10: FNS_CODES.FOREIGN_PASSPORT,
  14: FNS_CODES.REFUGEE_IN_RF,
  11: FNS_CODES.RESIDENCE_PERMIT,
  12: FNS_CODES.REFUGEE_CARD_ID,
  5: FNS_CODES.TEMP_CARD_ID,
  17: FNS_CODES.TEMP_RF_AUTHORIZATION,
  13: FNS_CODES.TEMPORARY_ASYLUM_CERTIFICATE,
  32: FNS_CODES.FOREIGN_BIRTH_CERTIFICATE,
  8: FNS_CODES.OFFICER_ID,
  29: FNS_CODES.VETERAN_ID,
  19: FNS_CODES.OTHER,
})
