<template>
  <m-button
    :disabled="createSemdButtonDisabled"
    :icon="icon"
    :text="buttonConfig.text"
    :type="buttonConfig.type"
    @click="$emit('click')"
  />
</template>

<script>
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'SemdSubmit',
  components: { MButton },
  props: {
    hasSemdType: { type: Boolean, required: true },
    isSourceChanged: { type: Boolean, required: true },
    isNewSource: { type: Boolean, required: true },
    isLastSemdOutdated: { type: Boolean, required: true },
    isSemdsEmpty: { type: Boolean, required: true },
  },

  emit: [
    'click',
  ],

  computed: {
    icon () {
      return this.isSourceChanged
        ? 'save'
        : 'modal'
    },

    canCreateSemd () {
      return this.isSemdsEmpty ||
          this.isLastSemdOutdated ||
          this.isNewSource
    },

    buttonConfig () {
      if (this.canCreateSemd) {
        return {
          type: 'success',
          text: this.isSourceChanged
            ? t('semds.saveAndCreateSemd')
            : t('semds.createSemd'),
        }
      }

      return {
        type: 'warning',
        text: this.isSourceChanged
          ? t('semds.saveAndUpdateSemd')
          : t('semds.openSemd'),
      }
    },

    createSemdButtonDisabled () {
      if (!this.$security.canManageEgisz) {
        return 'Egisz'
      }

      const disabled = !this.hasSemdType

      return {
        disabled,
        tooltip: disabled && t('semds.createSemdTooltip'),
      }
    },
  },
})
</script>
