import { isEmpty } from 'lodash'

/**
 * Миксин для валидации, используется для родительского компонентов.
 *
 * Принцип работы:
 *    требуется отловить валидатор @registerValidator="onRegisterValidator"
 *    в onRegisterValidator валидатор сохранится таким образом: this.validators[validatorName] = validator
 *
 * Другие методы:
 * 1) resetValidations - используется в двух вариантах
 *    1) сброс валидации (внезапно :D), если вызвать без параметров
 *    2) установить значения, передав объек с названием валидатора и массивом ошибок: { someValidatorName: ['Ошибка'] }
 * 2) hasErrors - вызывает все валидаторы, вернёт флаг наличия ошибок (true/false)
 */
export const ValidationParentMixin = {
  data () {
    return {
      validators: {},
    }
  },

  methods: {
    /**
     * @param {Object|null} validations
     * @param {Boolean} cleanAllBefore
     * @return {String[]} - список названий валидаторов с ошибками от "сервера" или ничего
     */
    resetValidations (validations = null, cleanAllBefore = false) {
      // eslint-disable-next-line no-prototype-builtins
      const isEvent = validations instanceof Event || validations?.hasOwnProperty('pointerType')
      const noNeedValidation = isEvent || !validations

      if (noNeedValidation || cleanAllBefore) { this.cleanAllErrors() }

      if (noNeedValidation) { return [] }

      Object.keys(validations || {}).forEach((key) => {
        if (!this.validators[key]) { return }
        this.validators[key](validations[key])
      })

      return Object.keys(validations || {})
    },

    cleanAllErrors () {
      Object
        .values(this.validators)
        .forEach((validator) => validator([]))
    },

    onRegisterValidator ({ validatorName, validator }) {
      if (!validatorName) { return }

      this.validators[validatorName] = validator
    },

    removeValidator (validatorName) {
      if (!this.validators[validatorName]) { return }
      this.validators[validatorName]([])
      delete this.validators[validatorName]
    },

    hasErrors () {
      return Object.values(this.validators)
        .map((validator) => validator())
        .some(Boolean)
    },

    /**
     * hasErrors маминой подруги
     * @param {Boolean} withOutput - при true выведет объект у которого ключи - сфейленные валидации
     * @return {false|Record<string, 1>}
     */
    hasValidationErrors (withOutput = false) {
      if (!withOutput) { return this.hasErrors() }

      const errors = Object.keys(this.validators).reduce((acc, key) => {
        const result = this.validators[key]()
        if (!result) { return acc }
        acc[key] = result

        return acc
      }, {})

      return !isEmpty(errors) && errors
    },
  },
}
