import { GuaranteeLetterLogic } from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/GuaranteeLetterLogic'
import {
  MGuaranteeLetterPresenter,
} from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/MGuaranteeLetterPresenter'
import { IGuaranteeLetter } from '@/_declarations/IGuaranteeLetter'
import { IApiErrors } from '@/_declarations/IApiErrors'

export class GuaranteeLetterApi extends GuaranteeLetterLogic {
  _presenter = new MGuaranteeLetterPresenter()

  save (): Promise<IGuaranteeLetter | IApiErrors> {
    return this._presenter.submit(this.getAttributes())
      .then((data) => {
        if (data.id) { this.setValue('id', data.id) }

        return data
      })
  }

  delete (): Promise<void | IApiErrors> {
    return this._presenter.destroy(this.id)
  }
}
