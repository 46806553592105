import {
  PersonalCheckupDiagnosesPersonalLogic,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDiagnosesPersonal/PersonalCheckupDiagnosesPersonalLogic'
import { IListServiceBaseParams } from '@/_declarations/IListServiceBaseParams'

export class PersonalCheckupDiagnosesPersonalApi extends PersonalCheckupDiagnosesPersonalLogic {
  fetchDiseases (params: Partial<IListServiceBaseParams>) {
    return this.presenter.fetchDiseases(params)
  }
}
