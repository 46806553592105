import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import {
  IPersonalCheckupDiagnosesPersonalItem,
} from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupDiagnosesPersonalItem'
import { extractItemId } from '@/vue_apps/catalogs_root/EntryPacks/const/extractItemId'

export const extractDataFromNode = (node: LazyTreeNode): Pick<
  IPersonalCheckupDiagnosesPersonalItem,
  'diagnosisId' | 'diagnosisTitle' | 'diagnosisCodeString'
> => {
  const separator = ' '

  const diagnosisId = +extractItemId(node.id)

  const [diagnosisCodeString, ...diagnosisTitle] = node.title.split(separator)

  return {
    diagnosisId,
    diagnosisCodeString,
    diagnosisTitle: diagnosisTitle.join(separator),
  }
}
