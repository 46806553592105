
export const defaultCheckupEntryTypeAdapter = {
  toClient (response) {
    const finalSpecialist = response.title
    const finalCabinet = (response.clinicsCabinets || [])
      .find(({ clinicId }) => clinicId === gon.application.current_clinic.id)?.cabinetTitle || ''

    return { finalCabinet, finalSpecialist }
  },
}
