<template>
  <div
    style="position:relative"
    class="logo-not-found"
  >
    <epic-spinner
      :visibility="epicSpinner"
    />
    <upload-button v-if="$security.canManageClinic">
      <slot>
        <input
          ref="input"
          style="display: none"
          accept="image/jpeg, image/png, image/gif"
          type="file"
          @input="handleInput"
        >
      </slot>
    </upload-button>
    <span class="fact prompt-notice">
      {{ t('clinic_logo_not_found') }}
    </span>
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import UploadButton from '@/vue_components/common/upload_button.vue'

export default {
  name: 'LogotypeNotFound',
  components: {
    EpicSpinner,
    UploadButton,
  },
  props: {
    epicSpinner: Boolean,
  },
  methods: {
    handleInput (event) {
      this.$emit('input', event)
      this.$refs.input.value = ''
    },
  },
}
</script>
