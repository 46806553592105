import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { CHECKUP_ENTRY_TYPES_KINDS } from '@/vue_apps/catalogs_root/CheckupCatalogs/const/checkupEntryTypesKinds'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'

export class CheckupEntryTypeListItemRow extends MListServiceItem {
  constructor (checkupEntryType) {
    super()

    this.id = checkupEntryType.id

    this.title = new MTableCell(checkupEntryType.title)
      .addCut()
      .addTooltip(checkupEntryType.title)

    this.icon = new MTableCell({
      type: 'icon',
      css: 'primary',
      value: checkupEntryType.kind === CHECKUP_ENTRY_TYPES_KINDS.SPECIALIST
        ? 'doctor'
        : 'analysis',
    })
  }
}
