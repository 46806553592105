<template>
  <si-table
    class="egisz-clients-table"
    :not-found="clientsList.length === 0"
  >
    <template #header>
      <si-field class="egisz-clients-table__header clients-col">
        <div
          v-if="false"
          class="clients-col clients-col_cb"
        >
          <el-checkbox />
        </div>

        <div class="clients-col clients-col_status-btn" />
        <div class="clients-col clients-col_status" />

        <div class="clients-col clients-col_date">
          <span>{{ t('egisz.components.egisz_clients_table.date') }}</span>
        </div>

        <div class="clients-col clients-col_fio">
          <span>{{ t('egisz.components.egisz_clients_table.fio') }}</span>
        </div>

        <div class="clients-col clients-col_clinic">
          <span>{{ t('egisz.components.egisz_clients_table.clinic') }}</span>
        </div>

        <div class="clients-col clients-col_phone">
          <span>{{ t('egisz.components.egisz_clients_table.phone') }}</span>
        </div>

        <div class="clients-col clients-col_snils">
          <span>{{ t('egisz.components.egisz_clients_table.snils') }}</span>
        </div>

        <div class="clients-col clients-col_comment">
          <i
            v-if="isSmallWindow"
            v-tooltip="t('comment')"
            class="fad fa-comment-alt"
          />
          <span v-else>
            {{ t('egisz.components.egisz_clients_table.comment') }}
          </span>
        </div>
      </si-field>
    </template>

    <template #body>
      <si-field
        v-for="(client, index) in clientsList"
        :key="`${client.id}:${index}`"
        class="egisz-clients-table__header clients-body"
      >
        <div
          v-if="false"
          class="clients-col clients-col_cb"
        >
          <el-checkbox />
        </div>

        <div class="clients-col clients-col_status-btn">
          <select-clinic-popover
            :registration-status="Boolean(client.registration_status)"
            :current-clinic-id="currentClinicId"
            :client="client"
            :egisz-ready-clinics="egiszReadyClinics"
            :disabled="Boolean(disabledTooltipOrFalse)"
            @submitClient="$emit('submitClient', $event)"
            @update:currentClinicId="$updateSync('currentClinicId', $event)"
          >
            <template #body>
              <span class="prompt-notice font-size-12">
                * {{ t('egisz.components.egisz_client_sender.frmo_clinics') }}
              </span>
            </template>

            <template #reference>
              <div
                v-tooltip="disabledTooltipOrFalse"
                class="inline-block"
              >
                <button
                  v-if="sendButtonVisible(client)"
                  v-tooltip="t('egisz.components.egisz_clients_table.send')"
                  v-loading="loadingClientsIds.includes(client.id)"
                  type="button"
                  class="btn btn-xs btn-primary ml-5 min-w-35"
                  :disabled="Boolean(disabledTooltipOrFalse)"
                >
                  <i class="fad fa-share" />
                </button>
                <button
                  v-else
                  v-loading="loadingClientsIds.includes(client.id)"
                  v-tooltip="t('egisz.components.egisz_clients_table.update')"
                  type="button"
                  class="btn btn-xs btn-warning ml-5 min-w-35"
                  :disabled="Boolean(disabledTooltipOrFalse)"
                >
                  <i class="fad fa-sync" />
                </button>
              </div>
            </template>
          </select-clinic-popover>
        </div>

        <div class="clients-col clients-col_status flex">
          <i
            v-tooltip="getRegistrationStatusText(client.registration_status)"
            class="font-size-17"
            :class="EGISZ_STATUSES_CLIENT_CSS(client.registration_status)"
          />
        </div>

        <div class="clients-col clients-col_date">
          <span
            v-tooltip="client.exp && t('egisz.exp')"
            :class="{ red: client.exp }"
          >
            {{ getLastChangeDate(client.registration_updated_at) }}
          </span>
        </div>

        <div
          v-tooltip="client.full_name"
          class="clients-col clients-col_fio"
        >
          <a
            :href="$routes.client_path(client.id)"
            target="_blank"
          >
            {{ client.full_name }}
          </a>
        </div>

        <div
          v-tooltip="client.clinic_title"
          class="clients-col clients-col_clinic"
        >
          <span>
            {{ client.clinic_title }}
          </span>
        </div>

        <div class="clients-col clients-col_phone">
          <phone
            :raw-phone="client.phone"
            :client-id="client.id"
          />
        </div>

        <div class="clients-col clients-col_snils">
          <span>{{ formatSnils(client.snils) }}</span>
        </div>

        <div
          v-tooltip="!isSmallWindow && client.additional"
          class="clients-col clients-col_comment"
        >
          <i
            v-if="isSmallWindow && client.additional"
            v-tooltip="client.additional"
            class="fad fa-comment-alt"
          />
          <template v-else>
            {{ client.additional }}
          </template>
        </div>
      </si-field>
    </template>
  </si-table>
</template>

<script>
/**
 * @typedef {{
 *   id: Number | null,
 *   patient: {
 *     id: Number,
 *     snils?: String,
 *     name: String,
 *     surname: String,
 *     second_name?: String
 *   },
 *   registration_status: Number,
 *   registration_updated_at: String,
 *   comment?: String,
 *   errors?: Array,
 *
 *   selected: Boolean,
 *   selectable: Boolean
 * }} TEgiszClient
 */

/**
 * @typedef {{
 *   id: Number | null,
 *   patient: {
 *     id: Number,
 *     snils?: String,
 *     name: String,
 *     surname: String,
 *     second_name?: String
 *   },
 *   registration_status: Number,
 *   registration_updated_at: String,
 *   comment?: String,
 *   errors?: Array,
 *
 *   selected: Boolean,
 *   selectable: Boolean
 * }} TEgiszClient
 */
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import Phone from '@/vue_components/common/phone'
import formatter from '@/lib/formatters/formatter'
import { mapGetters } from 'vuex'
import SelectClinicPopover
  from '@/vue_components/egisz/egisz_module/components/EgiszClients/EgiszClientsTable/SelectClinicPopover'
import {
  EGISZ_CLIENTS_STATUSES_LIST,
  EGISZ_STATUSES,
  EGISZ_STATUSES_CLIENT_CSS,
} from '@/vue_components/egisz/egisz_module/const/egisz_statuses'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'

export default {
  name: 'EgiszClientsTable',
  components: {
    SelectClinicPopover,
    Phone,
    SiField,
    SiTable,
  },

  props: {
    /**
     * @type {TEgiszClient[]}
     */
    clientsList: {
      type: Array,
      required: true,
      default: () => [],
    },

    hasFilters: Boolean,

    loadingClientsIds: {
      type: Array,
      default: () => [],
    },

    currentClinicId: {
      type: Number,
      default: null,
    },

    canManage: Boolean,
    canUserSendToEgisz: Boolean,
  },

  data () {
    return {
      EGISZ_CLIENTS_STATUSES_LIST,
      EGISZ_STATUSES,
      EGISZ_STATUSES_CLIENT_CSS,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      egiszReadyClinics: 'vxGetDoctorClinicsEgiszReady',
    }),

    ...mapGetters('windowStore', {
      isSmallWindow: 'vxIsSmallWindow',
    }),

    disabledTooltipOrFalse () {
      if (!this.canManage) {
        return t('egisz.messages.access.read_only')
      }

      return false
    },
  },

  methods: {
    getRegistrationStatusText (status) {
      if (status === null) { return t('egisz.common_statuses.not_registered') }

      return t(`egisz.common_statuses.${status}`)
    },

    getLastChangeDate (date) {
      if (date === null) {
        return t('egisz.common_statuses.not_change')
      }

      return date
    },

    formatSnils (snils) {
      return formatter.formatValue(snils, ['client:snils'])
    },

    sendButtonVisible (client) {
      return !client.registration_status ||
          client.registration_status === ENTITIES.SENDING_ERROR
    },
  },
}
</script>
