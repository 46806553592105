<template>
  <div class="clients-birthday">
    <div
      v-if="notificationsModuleEnabled"
      class="clients-birthday__module-enabled"
    >
      <div class="clients-birthday__notification-checkbox messages-settings-notification-checkbox">
        <m-switch
          :value="clientsBirthdaySwitch"
          :active-text="t('whatsapp.switchTitles.birthdayCongratulation')"
          @change="setClientsBirthdaySwitch"
        />
      </div>

      <div
        v-if="clientsBirthdaySwitch"
        class="clients-birthday__settings"
      >
        <messages-priorities
          v-if="messagesPriorities.length"
          :messages-priorities="messagesPriorities"
          @updatePriorities="setMessagesPriorities"
        />

        <whatsapp-timeout-settings
          :delivery-waiting-time="deliveryWaitingTime"
          :read-waiting-time="readWaitingTime"
          :delivery-waiting-time-variables="BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES"
          :read-waiting-time-variables="BIRTHDAY_READ_WAITING_TIME_VARIABLES"
          @updateDeliveryWaitingTime="setClientsBirthdayDeliveredWaitingTime"
          @updateReadWaitingTime="setClientsBirthdayReadWaitingTime"
        />

        <div class="clients-birthday__birthday-sms-template">
          <variables-editor
            :editor-value="smsTemplateValue"
            :editor-title="t('whatsapp.notificationTemplate')"
            :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
            :preview-request="fetchTemplatePreview"
            required
            validator-name="smsTemplate"
            template-type="clients_birthday"
            :variables-list="variablesListForEditor"
            @registerValidator="onRegisterValidator"
            @changeEditorValue="setClientsBirthdaySmsTemplate"
          />
        </div>

        <variables-editor-whatsapp
          v-if="isWabaActive"
          :editor-value="wabaTemplateText"
          :variables-list="variablesListForEditor"
          @changeWabaEditorValue="setWabaTemplateText"
        >
          <template #templateSelector>
            <m-select
              :value="wabaTemplateId"
              :items="wazzupTemplatesList"
              option-label="title"
              value-key="templateGuid"
              :placeholder="t('whatsapp.chooseValue')"
              @change="insertTemplate"
            />
          </template>
        </variables-editor-whatsapp>

        <div class="clients-birthday__age">
          <div class="title mb-indent-validation">
            {{ t('whatsapp.recipientsAge') }}
          </div>
          <div class="clients-age__inputs">
            <span class="age-from">
              {{ t('from') }}
            </span>
            <m-input
              class="clients-age__from"
              :clearable="false"
              :value="clientsBirthdayAgeFrom"
              type="number"
              :precision="0"
              :min="CLIENT_MIN_AGE_FOR_BIRTHDAY_MESSAGE"
              :max="clientsBirthdayAgeTo"
              @input="setClientsBirthdayAgeFrom"
            />
            <span class="to">{{ t('to') }}</span>
            <m-input
              class="clients-age__to"
              :clearable="false"
              :value="clientsBirthdayAgeTo"
              type="number"
              :precision="0"
              :min="clientsBirthdayAgeFrom || CLIENT_MIN_AGE_FOR_BIRTHDAY_MESSAGE"
              :max="CLIENT_MAX_AGE_FOR_BIRTHDAY_MESSAGE"
              @input="setClientsBirthdayAgeTo"
            />
          </div>
        </div>
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="t('introduction.messages')"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import {
  BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES,
  BIRTHDAY_READ_WAITING_TIME_VARIABLES,
  PRIORITY_BUTTONS_ITEMS,
  CLIENT_MIN_AGE_FOR_BIRTHDAY_MESSAGE,
  CLIENT_MAX_AGE_FOR_BIRTHDAY_MESSAGE,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import {
  CLIENTS_BIRTHDAY_MESSAGE_VARIABLES_LIST,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import { variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import Introduction from '@/vue_components/introduction/introduction.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import {
  findAndExtractWabaTemplateTextWithVariablesLabels,
  extractWabaTemplateText,
  replaceVariablesLabelsByValues,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'clients_birthday'

export default {
  name: 'ClientsBirthday',
  components: {
    Introduction,
    MessagesPriorities,
    MInput,
    MSelect,
    MButton,
    MSwitch,
    WhatsappTimeoutSettings,
    VariablesEditorWhatsapp,
    VariablesEditor,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaTemplateText: '',
      PRIORITY_BUTTONS_ITEMS,
      CLIENTS_BIRTHDAY_MESSAGE_VARIABLES_LIST,
      BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES,
      BIRTHDAY_READ_WAITING_TIME_VARIABLES,
      CLIENT_MIN_AGE_FOR_BIRTHDAY_MESSAGE,
      CLIENT_MAX_AGE_FOR_BIRTHDAY_MESSAGE,
      fetchTemplatePreview,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      clientsBirthdaySwitch: 'vxGetClientsBirthdaySwitch',
      clientsBirthdaySmsTemplate: 'vxGetClientsBirthdaySmsTemplate',
      messagesPriorities: 'vxGetMessagesPriorities',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      clientsBirthdayWabaTemplateId: 'vxGetClientsBirthdayWabaTemplateId',
      wabaTemplateVariables: 'vxGetClientsBirthdayWabaTemplateVariables',
      deliveryWaitingTime: 'vxGetClientsBirthdayDeliveredWaitingTime',
      readWaitingTime: 'vxGetClientsBirthdayReadWaitingTime',
      clientsBirthdayAgeFrom: 'vxGetClientsBirthdayAgeFrom',
      clientsBirthdayAgeTo: 'vxGetClientsBirthdayAgeTo',
      isWabaActive: 'vxIsWabaActive',
    }),

    smsTemplateValue () {
      return this.clientsBirthdaySmsTemplate
    },

    variablesListForEditor () {
      return CLIENTS_BIRTHDAY_MESSAGE_VARIABLES_LIST.filter((el) => !el.disabled)
    },

    wabaTemplateId () {
      return { templateGuid: this.clientsBirthdayWabaTemplateId }
    },
  },

  created () {
    const baseRequest = this.vxFetchMessagesConfigurations()

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
      this.vxFetchMessagesPriorities(CURRENT_PRIORITY_TYPE),
    ]

    Promise.all([baseRequest, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
      vxFetchMessagesPriorities: 'vxFetchMessagesPriorities',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      setClientsBirthdaySwitch: 'vxSetClientsBirthdaySwitch',
      setClientsBirthdaySmsTemplate: 'vxSetClientsBirthdaySmsTemplate',
      setMessagesPriorities: 'vxSetMessagesConfigurationsPriorities',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setClientsBirthdayWabaTemplateId: 'vxSetClientsBirthdayWabaTemplateId',
      setClientsBirthdayDeliveredWaitingTime: 'vxSetClientsBirthdayDeliveredWaitingTime',
      setClientsBirthdayReadWaitingTime: 'vxSetClientsBirthdayReadWaitingTime',
      setClientsBirthdayAgeFrom: 'vxSetClientsBirthdayAgeFrom',
      setClientsBirthdayAgeTo: 'vxSetClientsBirthdayAgeTo',
    }),

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
    },

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialWabaTemplateText()
    },

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.clientsBirthdayWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.clientsBirthdayWabaTemplateId,
          templateVariablesValues: this.wabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setClientsBirthdayWabaTemplateId(template?.templateGuid)
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const whatsappEditorValueToServer = replaceVariablesLabelsByValues(this.wabaTemplateText || '')

      const messagesConfigurations = {
        clientsBirthday: this.clientsBirthdaySwitch,
        clientsBirthdayTemplate: this.smsTemplateValue,
        clientsBirthdayAgeFrom: this.clientsBirthdayAgeFrom,
        clientsBirthdayAgeTo: this.clientsBirthdayAgeTo,
      }
      const whatsappConfigurations = {
        clientsBirthdayWabaTemplateVariables: variablesParserWithChild(whatsappEditorValueToServer),
        clientsBirthdayWabaTemplateId: this.clientsBirthdayWabaTemplateId,
        clientsBirthdayDeliveredWaitingTime: this.deliveryWaitingTime,
        clientsBirthdayReadWaitingTime: this.readWaitingTime,
      }
      const channelsPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.messagesPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(channelsPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
