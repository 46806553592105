import {
  PersonalCheckupDiagnosesPersonalModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDiagnosesPersonal/PersonalCheckupDiagnosesPersonalModel'
import {
  PersonalCheckupDiagnosesPersonalListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDiagnosesPersonal/PersonalCheckupDiagnosesPersonalListItem'

export class PersonalCheckupDiagnosesPersonalLogic extends PersonalCheckupDiagnosesPersonalModel {
  isExists (diagnosisId: number, diagnosisType: string) {
    return this.getArr()
      .some((item) =>
        item.diagnosisId === diagnosisId &&
        item.diagnosisType === diagnosisType &&
        !item._destroy
      )
  }

  changeTrigger (
    diagnosesPersonal: PersonalCheckupDiagnosesPersonalListItem[],
    diagnosisType: string
  ) {
    const {
      newDiagnoses,
      existingDiagnoses,
    } = this.sortByNewAndExisting(diagnosesPersonal, diagnosisType)

    const toRemove = this.nestedItems
      .getArr()
      .reduce((acc, item) => {
        if (item.diagnosisType !== diagnosisType) { return acc }

        const isExists = existingDiagnoses
          .find(({ diagnosisId }) => item.diagnosisId === diagnosisId)

        if (isExists) { return acc }

        if (item.dbId) {
          item._destroy = true

          return acc
        }

        acc.push(item.id)

        return acc
      }, [])

    this.nestedItems.appendMany(newDiagnoses)
    this.nestedItems.removeMany(toRemove)
  }

  getUnsavedAndDestroy () {
    return this.nestedItems
      .getArr()
      .filter((item) => (!item.dbId || item._destroy) && !item._keep)
  }

  removeUnsavedAndRestore () {
    const toRemoveIds = this.nestedItems
      .getArr()
      .reduce((acc, item) => {
        if (!item.dbId && !item._keep) {
          acc.push(item.id)

          return acc
        }

        item._destroy = false

        return acc
      }, [])

    this.nestedItems.removeMany(toRemoveIds)
  }

  private sortByNewAndExisting (
    diagnosesPersonal: PersonalCheckupDiagnosesPersonalListItem[],
    diagnosisType: string
  ) {
    const date = Utils.getBaseFormattedDate()

    return diagnosesPersonal
      .reduce((acc, item) => {
        if (item instanceof PersonalCheckupDiagnosesPersonalListItem &&
            this.isExists(item.diagnosisId, diagnosisType)) {
          acc.existingDiagnoses.push(item)

          return acc
        }

        acc.newDiagnoses.push({
          diagnosisId: item.diagnosisId,
          diagnosisCodeString: item.diagnosisCodeString,
          diagnosisType,
          diagnosisDate: date,
          diagnosisTitle: item.diagnosisTitle,
        })

        return acc
      }, { newDiagnoses: [], existingDiagnoses: [] })
  }
}
