import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'
import { PersonalCheckupLogic } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupLogic'
import { PersonalCheckupModel } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupModel'

export class PersonalCheckupApi extends PersonalCheckupLogic {
  _presenter = new MPersonalCheckupPresenter()

  async loadLastCheckup () {
    const {
      errors,
      /** @type {IPersonalCheckup} checkup*/
      checkup,
    } = await this._presenter.lastCheckup(this.client.id)

    if (errors) { return }

    this.lastPersonalCheckup = new PersonalCheckupModel(checkup)
  }

  async calculateFactors () {
    const { data, errors } = await this._presenter.calculateFactors(
      this.getAttributes([...this._permit, 'factors'])
    )

    if (errors) { return ({ errors }) }

    this.checkupEntries.setCheckupEntries(data)

    return {}
  }

  async calculateFactorsWithForceUpdate () {
    const destroy = await this.checkupEntries.destroyCheckupEntries()
    if (destroy.errors) { return destroy }

    const calculatedFactors = await this.calculateFactors()
    if (calculatedFactors.errors) { return calculatedFactors }

    const savedCheckupEntries = await this.checkupEntries.saveCheckupEntries(['factors'])
    if (savedCheckupEntries.errors) { return savedCheckupEntries }
    this.checkupEntries.setCheckupEntries(savedCheckupEntries.checkupEntries)

    this.checkupEntries.patchCheckupEntries()

    return {}
  }

  async save (fields = [...this._permit, 'factors']) {
    return this._presenter.submit(this.getAttributes(['id', ...fields]))
      .then((pc) => {
        this.id = pc.id

        this.checkupEntries.personalCheckupId = pc.id

        this.vaccinations.ownerId = pc.id
        this.vaccinations.nestedItems.set(pc.vaccinations)

        this.infectiousDiseases.ownerId = pc.id
        this.infectiousDiseases.nestedItems.set(pc.infectiousDiseases)

        this.doctorResults.ownerId = pc.id
        this.doctorResults.nestedItems.set(pc.doctorResults)

        this.laboratoryResearchResults.ownerId = pc.id
        this.laboratoryResearchResults.nestedItems.set(pc.laboratoryResearchResults)

        this.instrumentalResearchResults.ownerId = pc.id
        this.instrumentalResearchResults.nestedItems.set(pc.instrumentalResearchResults)

        this.diagnosesPersonal.ownerId = pc.id
        this.diagnosesPersonal.nestedItems.set(pc.diagnosesPersonal)

        if (pc.client) { this.client.age = pc.client.age }

        return pc
      })
  }

  async delete () {
    return this._presenter.destroy(this.id)
  }
}
