import { CHECKUP_CATALOGS_ROUTES } from '@/vue_apps/catalogs_root/CheckupCatalogs/router/routeNames'
import CheckupCatalogsListPage from '@/vue_apps/catalogs_root/CheckupCatalogs/pages/CheckupCatalogsListPage.vue'
import CheckupCatalogsListEditor from '@/vue_apps/catalogs_root/CheckupCatalogs/pages/CheckupCatalogsListEditorPage.vue'
import { CHECKUP_ENTRY_TYPES_KINDS } from '@/vue_apps/catalogs_root/CheckupCatalogs/const/checkupEntryTypesKinds'

export const getDefaultRoute = (path = '*') => `/checkup-catalogs${path}`

export const checkupSpecialistRoute = {
  path: getDefaultRoute('/specialist'),
  name: CHECKUP_CATALOGS_ROUTES.CHECKUP_CATALOGS_SPECIALISTS_SHOW,
  props: (route) => ({ kind: route.params.kind || CHECKUP_ENTRY_TYPES_KINDS.SPECIALIST }),
  component: CheckupCatalogsListPage,
  meta: {
    label: t('checkups.checkupCatalogs.specialists'),
    icon: 'user-md',
  },
}

export const checkupTestRoute = {
  path: getDefaultRoute('/test'),
  name: CHECKUP_CATALOGS_ROUTES.CHECKUP_CATALOGS_TESTS_SHOW,
  component: CheckupCatalogsListPage,
  props: (route) => ({ kind: route.params.kind || CHECKUP_ENTRY_TYPES_KINDS.TEST }),
  meta: {
    label: t('checkups.checkupCatalogs.tests'),
    icon: 'flask',
  },
}

export const checkupCatalogsRoutes = [
  {
    path: getDefaultRoute('/:kind/:id/edit'),
    name: CHECKUP_CATALOGS_ROUTES.CHECKUP_CATALOGS_ENTRY_TYPE_EDIT,
    props: (route) => ({ id: parseInt(route.params.id), kind: route.params.kind }),
    components: {
      default: CheckupCatalogsListPage,
      editor: CheckupCatalogsListEditor,
    },
  },

  checkupSpecialistRoute,
  checkupTestRoute,

  {
    path: getDefaultRoute(),
    name: CHECKUP_CATALOGS_ROUTES.CHECKUP_CATALOGS,
  },
]
