import { getParsedDate } from '@/vue_apps/CheckupsModule/store/getParsedDate'
import {
  IPersonalElmkMedicalAssessment,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessment'
import {
  PersonalElmkMedicalAssessmentModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalElmkMedicalAssessment/PersonalElmkMedicalAssessmentModel'
import {
  IPersonalElmkMedicalAssessmentDoctorResults,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessmentDoctorResults'
import {
  IPersonalElmkMedicalAssessmentResearch,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalElmkMedicalAssessment/IPersonalElmkMedicalAssessmentResearch'

const getDoctorResultsTableData = (doctorResultsTableData: IPersonalElmkMedicalAssessmentDoctorResults[]) =>
  doctorResultsTableData
    .map(({ specialtyTitle, date, medicalAssessmentTitle }) => ({
      specialtyTitle,
      date: window.Utils.getFormattedDate(date),
      medicalAssessmentTitle,
    }))

const getMedicalAssessmentResearch = (medicalAssessmentResearch: IPersonalElmkMedicalAssessmentResearch[]) =>
  medicalAssessmentResearch.map(({ researchTitle, date, medicalAssessmentTitle }) => ({
    researchTitle,
    date: window.Utils.getFormattedDate(date),
    medicalAssessmentTitle,
  }))

export const personalElmkMedicalAssessmentAdapter = {
  toServer (data: PersonalElmkMedicalAssessmentModel) {
    return {
      document: {
        titleDate: `${data.titleDate.year}-${data.titleDate.month}-${data.titleDate.day}`,
        medicalCardNumber: data.medicalCardNumber,
        vaccinationsTableData: data.vaccinationsTableData,
        footerName: data.footerName,
        footerDate: window.Utils.getBaseFormattedDate(data.footerDate, window.Utils.momentDateFormat),
        newSemdApproved: data.newSemdApproved,
        newSignatureApproved: data.newSignatureApproved,
      },
    }
  },

  toClient (data: IPersonalElmkMedicalAssessment) {
    return {
      ...data,
      titleDate: getParsedDate(data.titleDate),

      headerClinicLicenseIssueDate: data.headerClinicLicenseIssueDate
        ? window.Utils.getFormattedDate(data.headerClinicLicenseIssueDate)
        : '',

      headerClinicPhone: data.headerClinicPhone
        ? `+${window.Services.phoneMask.setMask(data.headerClinicPhone)}`
        : '',

      infectiousDiseasesData: data.infectiousDiseasesData
        .map(({ text }) => text)
        .join(', ') || window.t('checkups.documents.personalElmkMedicalAssessment.noInfectiousDiseases'),

      medicalAssessmentTitle: data.medicalAssessmentTitle || '',

      checkupDate: window.Utils.getFormattedDate(data.checkupDate),

      doctorResultsTableData: getDoctorResultsTableData(data.doctorResultsTableData),

      laboratoryResearchesTableData: getMedicalAssessmentResearch(data.laboratoryResearchesTableData),

      instrumentalResearchesTableData: getMedicalAssessmentResearch(data.instrumentalResearchesTableData),

      footerDate: window.Utils.getFormattedDate(data.footerDate, window.Utils.momentDateFormat),
    }
  },
}
