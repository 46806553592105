import { MListService } from '@/_api/_requests/MListService'
import { MExpensePresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/MExpensePresenter'

export class ExpensesList extends MListService {
  constructor (initialFilters) {
    super(
      initialFilters,
      new MExpensePresenter(),
      { cacheKey: 'finance-expenses-list' }
    )
  }
}
