/**
 * @param {Record<String, any>|undefined} formFieldsData
 * @return {FormData}
 */
export const createFormData = (formFieldsData) => {
  const formData = new FormData()

  if (formFieldsData) {
    Object.keys(formFieldsData).forEach((key) => {
      formData.append(key, formFieldsData[key])
    })
  }

  return formData
}
