import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'
import { IServerAdapter, TAdapter } from '@/_api/decorators/api/interfaces/IAdapter'

export function ServerAdapter<Data = unknown, AdaptedData = unknown> (adapter: IServerAdapter<Data, AdaptedData> | TAdapter<Data, AdaptedData> | string) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return function (this: This, ...args: Args): Return {
      const result: ICRUDReturn = target.call(this, ...args) ?? {}

      return {
        ...result,
        payload: typeof adapter === 'function'
          ? adapter(result.payload as Data)
          : typeof adapter === 'string'
            ? this[adapter](result.payload as Data)
            : adapter.toServer(result.payload as Data),
      } as Return
    }
  }
}
