export const FAST_CREATE_NO_DUPLICATES = 1

export const FAST_CREATE_NEXT_DATE_FROM_NOW_IN_YEARS = 1

export const FAST_CREATE_DEFAULT_PHONE = '79000000000'

export const FAST_CREATE_DISABLED_FIELDS = Object.freeze([
  'name',
  'surname',
  'secondName',
  'sex',
  'birthdate',
  'snils',
])

export const FAST_CREATE_MAX_AGE = 150

/**
 * Возвращает минимальную возможную дату с указанным количеством лет от текущей
 */
export const getMinDateFromNow = (amountOfYears: number) => {
  const now = moment()

  return now.year(now.year() - amountOfYears)
}
