<template>
  <m-date-picker
    :value="value"
    :label="t('semds.creationDate')"
    value-format="yyyy-MM-dd"
    :disabled="disabled"
    :required="dateNotInFutureValidator"
    validator-name="semd_date"
    @registerValidator="$emit('registerValidator', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { defineComponent } from 'vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { dateNotInFutureValidator } from '@/lib/validators/dateNotInFutureValidator'

export default defineComponent({
  name: 'SemdDate',
  components: { MDatePicker },
  props: {
    value: { type: String, default: null },
    disabled: Boolean,
  },

  methods: {
    dateNotInFutureValidator,
  },
})
</script>
