<template>
  <modal
    v-if="modalVisibility"
    id="alert-modal"
    :modal-class="modalClass"
    :modal-visibility="modalVisibility"
    @close="$emit('close')"
  >
    <span slot="header">{{ headerMessage }}</span>
    <span slot="body">{{ message }}</span>
    <template slot="footer-right">
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-save"
        @click="$emit('ok')"
      >
        <span class="btn-with-icon_icon fad fa-check" />
        <span class="btn-with-icon_text">{{ T.close }}</span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from './modal.vue'

/**
 * В основном используется в связке с миксиной `AlertHolder`.
 * При изменении пропсов нужно отразить это в миксине, так как возможно использование
 * дефолтного объекта пропсов через v-bind.
 *
 * @see {AlertHolder}
 */
export default {
  name: 'AlertModal',
  components: {
    Modal,
  },
  props: {
    modalVisibility: {
      type: Boolean,
      default: false,
    },
    headerMessage: {
      type: String,
      default: '',
    },
    modalClass: {
      type: String,
      default: 'alert-modal',
    },
    message: {
      type: String,
      default: '',
    },
  },
}
</script>
