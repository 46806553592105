<template>
  <address-form
    :index="address.index"
    :country="address.country"
    :region="address.region"
    :area="address.area"
    :city="address.city"
    :street="address.street"
    :house="address.house"
    :flat="address.flat"
    :nsi-russian-subject-id="address.nsiRussianSubjectId"
    :disabled="disabled"
    :required-fields="requiredFields"
    @update:index="address.setValue('index', $event)"
    @update:country="address.setValue('country', $event)"
    @update:region="address.setValue('region', $event)"
    @update:area="address.setValue('area', $event)"
    @update:city="address.setValue('city', $event)"
    @update:street="address.setValue('street', $event)"
    @update:house="address.setValue('house', $event)"
    @update:flat="address.setValue('flat', $event)"
    @update:nsiRussianSubjectId="address.setValue('nsiRussianSubjectId', $event)"
    @registerValidator="$registerValidator($event)"
  >
    <template #area>
      <span />
    </template>
  </address-form>
</template>

<script>
import { defineComponent } from 'vue'
import AddressForm from '@/vue_present/Reuse/Address/AddressForm.vue'
import { MAddressModel } from '@/_baseModels/MAddressModel'

export default defineComponent({
  name: 'ClinicAddressTab',
  components: { AddressForm },

  props: {
    address: { type: MAddressModel, required: true },
    disabled: { type: Boolean, default: false },
  },

  data () {
    return {
      requiredFields: {
        region: true,
        city: true,
        street: true,
        house: true,
      },
    }
  },
})
</script>
