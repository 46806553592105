import { pick } from 'lodash'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { CHECKUP_STATUS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import { CheckupDocumentsList } from '@/vue_apps/CheckupsModule/store/CheckupDocumentsList'
import { ItemBase } from '@/_api/_classes/ItemBase'

/**
 * @implements ICompanyCheckup
 */
export class CompanyCheckupBase extends ItemBase {
  _permit = [
    'agreementEndDate',
    'agreementNumber',
    'agreementStartDate',
    'checkupEntries',
    'clinic',
    'comment',
    'company',
    'createdAt',
    'creator',
    'department',
    'documents',
    'employersRepresentative',
    'id',
    'personalCheckups',
    'plannedEndDate',
    'plannedStartDate',
    'responsible',
    'status',
    'title',
    'stats',
  ]

  agreementEndDate = null

  agreementNumber = null

  agreementStartDate = null

  checkupEntries = []

  clinic = snakeToCamel(pick(gon.application.current_clinic, ['id', 'title']))

  comment = ''

  company = null

  createdAt = null

  creator = null

  department = ''

  documents = new CheckupDocumentsList()

  employersRepresentative = ''

  id = null

  personalCheckups = []

  plannedEndDate = Utils.getBaseFormattedDate(moment().add('days', 7))

  plannedStartDate = Utils.getBaseFormattedDate()

  responsible = null

  status = CHECKUP_STATUS.PLANNED

  title = ''

  stats = {
    total: 0,
    planned: 0,
    inProgress: 0,
    ready: 0,
    canceled: 0,
  }

  constructor (props = {}) {
    super()
    this._fillProps(props)
  }
}
