<template>
  <m-panel
    class="guarantee-letters"
    :title="t('finance.guarantee_letters')"
    icon="fileAlt"
    type="warning"
    :use-type-by-route="false"
  >
    <m-si-generator
      :items="list.data"
      :si-generator-schema="schema"
      :current-page="list.currentPage"
      :page-count="list.totalPages"
      @update:currentPage="list.fetchPage($event)"
      @onItemClick="onItemClick"
      @onItemDelete="onItemDelete"
    >
      <template #filters>
        <guarantee-letters-editor
          :medical-policy-id="medicalPolicyId"
          :is-dms="isDms"
          @registerOpenModal="openModal = $event"
          @updateList="list.fetchPage(1)"
        />
      </template>
    </m-si-generator>
  </m-panel>
</template>

<script>
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import {
  getGuaranteeLettersSchema,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/const/getGuaranteeLettersSchema'
import {
  MGuaranteeLetterPresenter,
} from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/MGuaranteeLetterPresenter'
import GuaranteeLettersEditor
  from '@/vue_apps/ClientsModule/components/MedicalPolices/components/GuaranteeLetters/GuaranteeLettersEditor.vue'
import {
  getGuaranteeLettersList,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/list/getGuaranteeLettersList'

export default defineComponent({
  name: 'GuaranteeLetters',
  components: { GuaranteeLettersEditor, MSiGenerator, MPanel },
  props: {
    medicalPolicyId: { type: Number, required: true },
    isDms: { type: Boolean, default: false },
  },

  data () {
    return {
      schema: getGuaranteeLettersSchema(this.isDms),
      list: getGuaranteeLettersList(this.medicalPolicyId),

      openModal () {},
    }
  },

  watch: {
    isDms () {
      this.schema = getGuaranteeLettersSchema(this.isDms)
    },
  },

  created () {
    setTimeout(() => {
      this.list.fetchAll()
    })
  },

  methods: {
    /**
     * @param {GuaranteeLetterListItem} letter
     */
    onItemClick (letter) {
      const letterValues = letter.getValues()

      this.openModal({
        ...letterValues,
        issueDate: letter.originalIssueDate,
        medicalPolicyId: this.medicalPolicyId,
      })
    },

    async onItemDelete (letter) {
      const result = await new MGuaranteeLetterPresenter().destroy(letter.id)
      if (result?.errors) { return }

      await this.list.fetchPage(1)
    },
  },
})
</script>
