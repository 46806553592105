import { isRequired } from '@/vue_components/mixins/base_validation_mixin/const/base_rules'
import { BASE_REQUIRED_TEXT } from '@/vue_components/mixins/base_validation_mixin/const/base_texts'

export function isMedDocumentGroup (validatedData) {
  return validatedData.hasOwnProperty('date') &&
    validatedData.hasOwnProperty('header') &&
    validatedData.hasOwnProperty('typeId')
}

export function isSignatureGroup (validatedData) {
  return validatedData.hasOwnProperty('workplaceId') &&
    validatedData.hasOwnProperty('certificateId')
}

export function getMedDocumentGroupValidations ({ date, header, typeId }) {
  return [
    { key: 'date', rule: isRequired(date), falseMessage: BASE_REQUIRED_TEXT },
    { key: 'header', rule: isRequired(header), falseMessage: BASE_REQUIRED_TEXT },
    { key: 'typeId', rule: isRequired(typeId), falseMessage: BASE_REQUIRED_TEXT },
  ]
}

export function getSignatureGroupValidations ({ workplaceId, certificateId }) {
  return [
    { key: 'workplaceId', rule: isRequired(workplaceId), falseMessage: BASE_REQUIRED_TEXT },
    { key: 'certificateId', rule: isRequired(certificateId), falseMessage: BASE_REQUIRED_TEXT },
  ]
}
