<template>
  <div class="payment-cash-in-form">
    <epic-spinner
      :visibility="loading"
    />
    <flex-form
      :fields="flexForms.cashIn"
    >
      <template v-slot:customer-value>
        {{ customer }}
      </template>

      <template v-slot:payment-kind-value>
        {{ T._payment_kind.cash_in }}
      </template>

      <template v-slot:by-cash-value>
        <validation-wrapper
          :errors="validationMessages.byCash"
        >
          <currency-input
            :value="byCash"
            icon="fad fa-money-bill"
            @input="update('byCash', $event)"
          />
        </validation-wrapper>
      </template>

      <template v-slot:by-card-value>
        <validation-wrapper
          :errors="validationMessages.byCard"
        >
          <currency-input
            :value="byCard"
            icon="fad fa-credit-card"
            @input="update('byCard', $event)"
          />
        </validation-wrapper>
      </template>

      <template v-slot:by-cashless-value>
        <validation-wrapper
          :errors="validationMessages.byCashless"
        >
          <currency-input
            :value="byCashless"
            icon="fad fa-university"
            @input="update('byCashless', $event)"
          />
        </validation-wrapper>
      </template>

      <template v-slot:to-balance-value>
        <currency-input
          :value="totalMoney"
          disabled
          icon="fad fa-user-plus"
        />
      </template>

      <template v-slot:comment-value>
        <el-input
          type="textarea"
          :value="comment"
          :rows="2"
          :placeholder="T.comment"
          @input="update('comment', $event)"
        />
      </template>
    </flex-form>
  </div>
</template>

<script>
import CurrencyInput from '@/vue_components/common/currency_input.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

export default {
  name: 'PaymentsCashInForm',
  components: {
    CurrencyInput,
    FlexForm,
    EpicSpinner,
    ValidationWrapper,
  },

  props: {
    customer: {
      type: String,
      required: true,
    },
    byCash: {
      type: Number,
      required: true,
    },
    byCard: {
      type: Number,
      required: true,
    },
    byCashless: {
      type: Number,
      required: true,
    },
    totalMoney: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    validationMessages: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    flexForms: {
      cashIn: Object.freeze([
        { name: 'customer', label: t('cuctomer_signature') },
        { name: 'payment-kind', label: t('payment_kind') },
        { name: 'by-cash', label: t('by_cash') },
        { name: 'by-card', label: t('by_card') },
        { name: 'by-cashless', label: t('by_cashless') },
        { name: 'to-balance', label: t('to_balance') },
        { name: 'comment', label: t('comment') },
      ]),
    },
  }),

  methods: {
    update (prop, value) {
      this.$emit(`update:${prop}`, value)
    },
  },
}
</script>
