<template>
  <div
    class="checkup-doctors-and-tests-modal"
  >
    <m-modal
      :visible="modalVisible"
      :dialog-title="title"
      :modal-title-icon="icon"
      @close="closeModal"
    >
      <template #body>
        <m-si-generator
          v-loading="list.loading"
          :items="list.data"
          :current-page="list.currentPage"
          :page-count="list.totalPages"
          :si-generator-schema="msiSchema"
          :search-query="list.searchQuery"
          @onItemClick="onItemClick"
          @update:currentPage="list.fetchPage($event)"
          @update:searchQuery="list.search($event)"
        />
      </template>
    </m-modal>

    <m-button
      class="mb-indent-small"
      type="warning"
      :icon="icon"
      plus-icon
      size="mini"
      :tooltip="tooltip"
      @click="updateExcludeFilter(); openModal()"
    />
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MCheckupEntryTypePresenter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/MCheckupEntryTypePresenter'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MListService } from '@/_api/_requests/MListService'
import { CHECKUP_ENTRY_TYPES_KINDS } from '@/vue_apps/catalogs_root/CheckupCatalogs/const/checkupEntryTypesKinds'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { getCustomCheckupEntryType } from '@/vue_apps/CheckupsModule/store/checkupCalculatedEntries'

const msiSchema = new MSiSchema()
  .addPagination()
  .addSearch()
  .addTableSchema(
    new MTableSchema({
      headers: {
        icon: new MTableHeader(),
        title: new MTableHeader(),
      },
    })
  )

export default {
  name: 'CheckupDoctorsAndTestsModal',

  components: { MSiGenerator, MButton, MModal },

  mixins: [ModalMixin],

  props: {
    personalCheckup: PersonalCheckupApi,
    useSpecialist: Boolean,
  },

  data () {
    return {
      msiSchema,
      list: new MListService(
        {
          kind: null,
          excludedIds: [],
        },
        new MCheckupEntryTypePresenter(),
        { limit: 50 }
      ),
    }
  },

  computed: {
    title () {
      if (this.useSpecialist) {
        return this.t('checkups.doctorsAndTests.addDoctorModalTitle')
      }

      return this.t('checkups.doctorsAndTests.addTestModalTitle')
    },

    icon () {
      if (this.useSpecialist) {
        return 'doctor'
      }

      return 'analysis'
    },

    tooltip () {
      if (this.useSpecialist) {
        return t('checkups.doctorsAndTests.addDoctorTooltip')
      }

      return t('checkups.doctorsAndTests.addTestTooltip')
    },
  },

  created () {
    this.list.setFilterValueWithFetchAll('kind', this.useSpecialist
      ? CHECKUP_ENTRY_TYPES_KINDS.SPECIALIST
      : CHECKUP_ENTRY_TYPES_KINDS.TEST
    )
  },

  methods: {
    async onItemClick (item) {
      this.list.loading = true

      const checkupEntry = getCustomCheckupEntryType({
        id: item.id,
        kind: this.list.filtersData.kind,
        title: item.title.value,
      })

      await this.personalCheckup.checkupEntries.saveCheckupEntry(checkupEntry)

      await this.updateExcludeFilter()
    },

    async updateExcludeFilter () {
      const excludedIds = this.personalCheckup.checkupEntries.checkupEntries.reduce((acc, checkupEntry) => {
        const isSpecialist = checkupEntry.checkupEntryType.kind ===
            CHECKUP_ENTRY_TYPES_KINDS.SPECIALIST

        return isSpecialist === this.useSpecialist
          ? [...acc, checkupEntry.checkupEntryType.id]
          : acc
      }, [])

      await this.list.setFilterValueWithFetchAll('excludedIds', excludedIds)
    },
  },
}
</script>
