import {
  MCompanyBladeRunnerDocumentPresenter,
} from '@/vue_apps/CheckupsModule/api/MCompanyBladeRunnerDocumentPresenter/MCompanyBladeRunnerDocumentPresenter'
import { BladeRunner } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunner'
import { pick } from 'lodash'
import { set } from 'lodash/object'

export class BladeRunners {
  _presenter = new MCompanyBladeRunnerDocumentPresenter()

  _permit = [
    'companyCheckupId',
    'finalCabinet',
    'finalSpecialist',
    'footerData',
  ]

  constructor (id, companyCheckupId = null) {
    this.id = id
    this.finalCabinet = ''
    this.finalSpecialist = ''
    this.footerData = ''
    this.companyCheckupId = companyCheckupId

    /** @type {BladeRunner[]} */
    this.bladeRunners = []
  }

  _fillAttributes ({ createdAt, id, data = [] }) {
    this.id = id
    this.bladeRunners = data.map((item) => new BladeRunner(Utils.newGUID())._fillAttributes(item))
  }

  setFieldValue (field, value) {
    set(this, field, value)
  }

  async save (pickedAttributes = this._permit) {
    const data = pick(this, ['id', 'companyCheckupId', ...pickedAttributes])

    return this._presenter.submit(data)
  }

  destroy () {
    return this._presenter.destroy(this.id)
  }

  async load () {
    await this._presenter.fetch(this.id)
      .then((data) => { this._fillAttributes(data) })

    return this
  }

  async updateFinals () {
    const params = [
      'finalCabinet',
      'finalSpecialist',
      'footerData',
    ]

    const response = await this.save(params)

    if (response?.errors) { return response }

    this.id = response.id

    const finals = pick(this, params)
    this.bladeRunners.forEach((bladeRunner) => {
      bladeRunner.updateFinals(finals)
    })

    return response
  }
}
