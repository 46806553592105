import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  personalCheckupDiagnosesPersonalAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDiagnosesPersonalPresenter/personalCheckupDiagnosesPersonalAdapter'
import {
  personalCheckupDiagnosesPersonalSearchAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDiagnosesPersonalPresenter/personalCheckupDiagnosesPersonalSearchAdapter'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'

export class PersonalCheckupDiagnosesPersonalPresenter extends NestedItemsPresenter {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
      },

      adapter: personalCheckupDiagnosesPersonalAdapter,

      searchAdapter: 'disease',
      entity: 'diagnoses',
      location: 'personalCheckupDiagnosesPresenter',
    })
  }

  fetchDiseases (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'diseases'
    )

    return super.post({
      url: Routes.search_api_internal_diseases_path(),
      data: JSON.stringify(
        camelToSnake(
          personalCheckupDiagnosesPersonalSearchAdapter.toServer(data)
        )
      ),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .then(personalCheckupDiagnosesPersonalSearchAdapter.toClient)
      .catch(MRequestError.withDefault([], notification))
  }
}
