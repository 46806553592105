import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MClinicPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MClinicPresenter',
      entity: 'clinic',
      routes: {
        all: Routes.clinics_path,
        one: Routes.clinic_path,
        list: Routes.catalogs_clinics_list_path,
      },
    })
  }
}
