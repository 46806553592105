/**
 * @param {TAddressBasic} address
 * @returns {string}
 */
export const concatHouseWithBlock = (address) => [
  address.house,
  address.blockTypeFull,
  address.block,
].join(' ')

/**
 * @param {TAddressBasic} address
 * @return {TAddress}
 */
export const extractAddressFromSuggestionAddress = (address) => {
  return {
    index: address.postalCode,
    country: address.country,
    region: address.regionWithType,
    area: address.areaWithType,
    city: address.cityWithType,
    street: address.street,
    house: concatHouseWithBlock(address),
    flat: address.flat,
  }
}
