import { MListService } from '@/_api/_requests/MListService'
import {
  MCompanyCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MCompanyCheckupPresenter/MCompanyCheckupPresenter'

export class CompanyCheckupList extends MListService {
  constructor (initialFilters, config) {
    super(
      initialFilters,
      new MCompanyCheckupPresenter(),
      config
    )
  }
}
