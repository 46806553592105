<template>
  <m-panel
    :title="t('checkups.personal.info.title')"
    class="personal-checkup-info"
  >
    <template #headExtra>
      <m-button
        v-if="isNewMode && personalCheckup.lastPersonalCheckup"
        v-tooltip="t('checkups.fillByLastCheckupTooltip')"
        :disabled="!personalCheckup.lastPersonalCheckup"
        :text="t('checkups.fillByLastCheckup')"
        icon="transfer"
        size="mini"
        type="dark"
        button-style="fill"
        @click="personalCheckup.fillByLastCheckup()"
      />
    </template>

    <div class="flex gap-indent">
      <div class="personal-checkup-info__left-side">
        <div class="position-relative mb-indent-validation">
          <!--   Клиент   -->
          <client-search-create
            ref="createSearchCreate"
            v-model="vmClient"
            class="flex-grow-1"
            :disabled="!!personalCheckup.client.id"
            :required="isNewMode"
            :use-editing="{ visible: true, disabled: readonly }"
            :default-client="personalCheckup.client"
            validator-name="client"
            @registerValidator="$registerValidator($event)"
            @onClientCreate="personalCheckup.setClient($event)"
          />

          <div class="personal-checkup-info__link-container">
            <m-link
              v-if="personalCheckup.client?.id"
              :text="t('infocard')"
              :href="$routes.client_path(personalCheckup.client.id)"
              open-in-new-tab
            />

            <m-link
              v-if="personalCheckup.client?.medicalRecordId"
              :text="t('medcard')"
              :href="$routes.medical_record_path(personalCheckup.client.medicalRecordId)"
              open-in-new-tab
            />
          </div>
        </div>

        <!--      Телефон      -->
        <m-phone-input
          :value="personalCheckup.client.phone"
          :label="t('checkups.personal.info.phone')"
          disabled
          @input="personalCheckup.setValue('client.phone', $event)"
        />

        <!-- СНИЛС -->
        <m-input
          :value="personalCheckup.client.snils"
          :label="t('snils')"
          :mask="SNILS_THE_MASK"
          :placeholder="SNILS_MASK_PLACEHOLDER"
          disabled
        />

        <div class="flex position-relative">
          <!--      Дата рождения     -->
          <m-date-picker
            class="flex-grow-1"
            :value="personalCheckup.client.birthdate"
            :label="t('checkups.personal.info.dob')"
            disabled
            value-format="yyyy-MM-dd"
            required
            validator-name="client.birthdate"
            @change="personalCheckup.setValue('client.birthdate', $event)"
            @registerValidator="$registerValidator($event)"
          />

          <span
            class="position-absolute"
            style="right: 7px; top: 5px"
          >{{ personalCheckup.client.age }}</span>
        </div>

        <!--      Пол       -->
        <reusable-sex
          :value="personalCheckup.client.sex"
          disabled
          required
          validator-name="client.sex"
          @change="personalCheckup.setValue('client.sex', $event)"
          @registerValidator="$registerValidator($event)"
        />

        <!--      Комментарий     -->
        <m-textarea
          class="personal-checkup-info__comment"
          :class="{ 'personal-checkup-info__comment_with-position-profile': personalCheckup.hasPositionProfileFactor}"
          :value="personalCheckup.comment"
          :label="t('checkups.personal.conclusion.comment')"
          :autosize="{ minRows: 3 }"
          :disabled="isShowMode"
          @input="personalCheckup.setValue('comment', $event)"
        />

        <span
          v-if="isNewMode && personalCheckup.client.id"
          class="underlined width-fit-content pointer prompt-notice"
          @click="personalCheckup.setClient(getDefaultPersonalCheckupClient())"
        >
          {{ t('checkups.personal.resetClient') }}
        </span>
      </div>

      <div class="personal-checkup-info__right-side">
        <div class="position-relative mb-indent-validation">
          <!--      организация-работодатель      -->
          <company-search-create
            :value="personalCheckup.company"
            :disabled="readonly || personalCheckup.isPeriodic"
            required
            validator-name="company"
            @change="personalCheckup.setValue('company', $event)"
            @onCreateCompany="personalCheckup.setValue('company', $event)"
            @registerValidator="$registerValidator($event)"
          />

          <div class="personal-checkup-info__link-container">
            <m-link
              v-if="personalCheckup.company"
              :text="t('infoCompanyShort')"
              :href="$routes.company_path(personalCheckup.company.id)"
              open-in-new-tab
            />
          </div>
        </div>

        <!--      Структурное подразделение      -->
        <m-input
          :label="t('checkups.personal.info.department')"
          :value="personalCheckup.department"
          :disabled="readonly || personalCheckup.isPeriodic"
          :placeholder="t('enums.unselected.it')"
          @input="personalCheckup.setValue('department', $event)"
        />

        <!-- Должность / Профессия по НСИ -->
        <reusable-local-n-s-i-list
          :value="personalCheckup.nsiProfession"
          :label="t('checkups.personal.info.nsiPosition')"
          :placeholder="t('checkups.personal.info.nsiPositionPlaceholder')"
          :disabled="readonly"
          :dictionary="NSI.PROFESSIONS"
          @change="personalCheckup.setValue('nsiProfession', $event)"
        />

        <!-- Должность / Профессия -->
        <m-input
          :value="personalCheckup.position"
          :label="t('checkups.personal.info.position')"
          :disabled="readonly"
          :placeholder="t('positionPlaceholder')"
          @input="personalCheckup.setValue('position', $event)"
        />

        <!-- Профиль работы -->
        <m-select
          v-if="personalCheckup.hasPositionProfileFactor"
          :value="personalCheckup.positionProfile"
          :items="PERSONAL_CHECKUP_POSITION_PROFILE_ITEMS"
          :label="t('checkups.personal.info.positionProfile')"
          :placeholder="t('checkups.personal.info.positionProfilePlaceholder')"
          :disabled="readonly"
          @change="personalCheckup.setValue('positionProfile', $event)"
        />

        <!--      Дата проведения      -->
        <m-date-picker
          v-model="vmPeriod"
          type="daterange"
          :label="t('checkups.personal.info.date')"
          :disabled="readonly"
          value-format="yyyy-MM-dd"
          required
          validator-name="date_start"
          @registerValidator="$registerValidator($event)"
        />

        <!--      Следующий профосмотр      -->
        <m-date-picker
          :label="t('checkups.personal.info.nextDate')"
          :value="personalCheckup.nextDate"
          value-format="yyyy-MM-dd"
          :disabled="readonly"
          required
          validator-name="next_date"
          @registerValidator="$registerValidator($event)"
          @change="personalCheckup.setValue('nextDate', $event)"
        />

        <!--      Статус      -->
        <m-select
          :value="personalCheckup.status"
          use-custom-result="simple"
          :label="t('checkups.personal.info.status')"
          :items="vxGetStatuses"
          :clearable="false"
          :disabled="readonly"
          @change="personalCheckup.setValue('status', $event)"
        />
      </div>
    </div>
  </m-panel>
</template>

<script>
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import CompanySearchCreate
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanySearchCreate.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import ReusableSex from '@/vue_present/Reuse/Lists/Sex/ReusableSex.vue'
import ClientSearchCreate
  from '@/vue_apps/CheckupsModule/components/ClientSearchCreateShort/ClientSearchEditorShort.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { mapGetters } from 'vuex'
import {
  MCheckupFactorsPresenter,
} from '@/vue_apps/CheckupsModule/api/MCheckupFactorsPresenter/MCheckupFactorsPresenter'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import { getDefaultPersonalCheckupClient } from '@/vue_apps/CheckupsModule/store/getDefaultPersonalCheckupClient'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { SNILS_MASK_PLACEHOLDER, SNILS_THE_MASK } from '@/specific/clients/methods/snils'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import {
  PERSONAL_CHECKUP_POSITION_PROFILE_ITEMS,
} from '@/vue_apps/CheckupsModule/store/personalCheckupPositionProfileItems'

export default {
  name: 'PersonalCheckupInfo',
  components: {
    ReusableLocalNSIList,
    MLink,
    MTextarea,
    MButton,
    ClientSearchCreate,
    ReusableSex,
    MPanel,
    MInput,
    CompanySearchCreate,
    MDatePicker,
    MSelect,
    MPhoneInput,
  },

  mixins: [
    CheckupsPropsMixin,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
    readonly: Boolean,
  },

  data () {
    return {
      PERSONAL_CHECKUP_POSITION_PROFILE_ITEMS,
      NSI: NSI_DICTIONARIES,
      factorsList: new MCheckupFactorsPresenter(),
      SNILS_THE_MASK,
      SNILS_MASK_PLACEHOLDER,
    }
  },

  computed: {
    ...mapGetters('checkupsStore', {
      vxGetStatuses: 'vxGetStatuses',
    }),

    vmClient: {
      get () { return this.personalCheckup.client.fullName },
      set (value) {
        if (typeof value === 'string') { return }
        if (!value) {
          this.personalCheckup.setValue('client.id', null)
          this.personalCheckup.setValue('client.fullName', null)

          return
        }

        this.personalCheckup.setValue('client', value)
        this.personalCheckup.loadLastCheckup().then()
      },
    },

    vmPeriod: {
      get () {
        if (!this.personalCheckup.dateStart || !this.personalCheckup.dateEnd) {
          return null
        }

        return [this.personalCheckup.dateStart, this.personalCheckup.dateEnd]
      },
      set (value) {
        if (!value || !Array.isArray(value)) {
          this.personalCheckup.setValue('dateStart', null)
          this.personalCheckup.setValue('dateEnd', null)

          return
        }

        this.personalCheckup.setValue('dateStart', value[0])
        this.personalCheckup.setValue('dateEnd', value[1])
      },
    },
  },

  methods: {
    getDefaultPersonalCheckupClient,

    triggerClientTooltip () {
      this.$refs.createSearchCreate.triggerClientTooltip()
    },
  },
}
</script>
