<template>
  <div class="fixed-document-block">
    <!-- Наименование -->
    <m-input
      :value="certificate.title"
      :label="t('title')"
      required
      validator-name="title"
      :clearable="false"
      :disabled="disabled"
      @input="certificate.setValue('title', $event)"
      @registerValidator="$registerValidator($event)"
    />

    <div class="flex justify-content-between mb-indent">
      <!-- Номер справки -->
      <m-input
        type="number"
        :precision="0"
        :min="MIN_CERTIFICATE_NUMBER"
        :max="MAX_CERTIFICATE_NUMBER"
        :value="certificate.certificateNumber"
        :label="t$('certificateNumber')"
        :disabled="!isPrimary || disabled"
        required
        validator-name="certificateNumber"
        @input="certificate.setValue('certificateNumber', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Номер корректировки -->
      <m-input
        v-tooltip="correctionNumberTooltip"
        type="number"
        :precision="0"
        :min="correctionNumberMin"
        :max="correctionNumberMax"
        :value="certificate.correctionNumber"
        :label="t$('correctionNumber')"
        :disabled="!isCorrective || disabled"
        required
        validator-name="correctionNumber"
        @input="certificate.setValue('correctionNumber', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Отчётный год -->
      <m-select
        :value="certificate.reportingYear"
        :items="certificate.availableReportingYears"
        :label="t$('reportingYear')"
        :disabled="!isPrimary || disabled"
        :clearable="false"
        :required="reportingYearValidator"
        use-custom-result="simple"
        validator-name="reportingYear"
        @change="certificate.setValue('reportingYear', $event)"
        @registerValidator="$registerValidator($event)"
      />
    </div>
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  ANNULLING_CERTIFICATE_CORRECTION_NUMBER,
  CERTIFICATE_TYPE,
  MAX_CERTIFICATE_NUMBER,
  MIN_CERTIFICATE_NUMBER,
  PRIMARY_CERTIFICATE_CORRECTION_NUMBER,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import {
  TaxCertificate2024EditorSecondBlockValidationMixin,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/mixins/validationMixins/TaxCertificate2024EditorSecondBlockValidationMixin'

export default {
  name: 'TaxCertificate2024EditorSecondBlock',

  components: { MSelect, MInput },

  mixins: [
    i18nScopeMixin,
    TaxCertificate2024EditorSecondBlockValidationMixin,
  ],

  props: {
    certificate: TaxCertificate2024,
    disabled: Boolean,

    isPrimary: Boolean,
    isCorrective: Boolean,
    isAnnulling: Boolean,
  },

  emits: ['certificateTitle'],

  data () {
    return {
      MIN_CERTIFICATE_NUMBER,
      MAX_CERTIFICATE_NUMBER,
      CERTIFICATE_TYPE,
    }
  },

  computed: {
    correctionNumberMin () {
      return PRIMARY_CERTIFICATE_CORRECTION_NUMBER + +this.isCorrective
    },

    correctionNumberMax () {
      return ANNULLING_CERTIFICATE_CORRECTION_NUMBER - +this.isCorrective
    },

    correctionNumberTooltip () {
      return this.isCorrective
        ? ''
        : this.t$('correctionNumberTooltip')
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },
}
</script>
