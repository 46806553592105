<template>
  <label
    class="m-label label-bordered"
    :class="{
      'is-disabled': canManage.disabled,
      'is-focused': focused
    }"
    :for="id"
  >
    {{ label }}<required-mark
      v-if="required"
      :text="requiredTooltip || undefined"
    />

    <m-icon
      v-if="clarification"
      v-tooltip="clarification"
      class="m-label__clarification"
      icon="questionCircle"
      color="dark"
    />
  </label>
</template>

<script>
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'MLabel',
  components: { MIcon, RequiredMark },
  mixins: [DisabledMixin],
  props: {
    focused: Boolean,
    required: Boolean,
    requiredTooltip: { type: [String, Object], default: null },
    id: { type: String, default: null },
    label: { type: String, default: null },
    clarification: { type: String, default: null },
  },
}
</script>
