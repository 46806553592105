import { isPolicyActive } from '@/vue_apps/ClientsModule/components/MedicalPolices/const/isPolicyActive'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

/**
 * @param {medicalPolicyListItemRaw | { isNew: boolean }} medicalPolicy
 * @return {Promise<void | { cancel?: boolean, active?: boolean } >}
 */
export const isNewInactiveMedicalPolicyNotify = (medicalPolicy) => {
  const isMedicalPolicyActive = isPolicyActive({
    active: medicalPolicy.active,
    dateStart: medicalPolicy.dateStart,
    dateEnd: medicalPolicy.dateEnd,
    nsiOmsTypeNsiId: medicalPolicy.nsiOmsType?.nsiId,
  })

  const isNewInactiveMedicalPolicy =
    medicalPolicy.isNew && !isMedicalPolicyActive

  if (!isNewInactiveMedicalPolicy) {
    return { active: true }
  }

  return MConfirm.error(t('policy_been_created_but_not_used_account'), {
    confirmButtonText: 'OK',
    showCancelButton: false,
  })
}
