<template>
  <div class="flex-form flex-form__35-65 flex-form__centered egisz-case__flex-form step-form">
    <div class="form-label egisz-case__form-label step-form__client-label">
      {{ t('client') }}
    </div>
    <div class="form-value egisz-case__form-value step-form__client-value">
      <a
        :href="$routes.client_path(client.id)"
        target="_blank"
      >
        {{ client.fullname }}
      </a>
    </div>

    <div class="form-label egisz-case__form-label step-form__clinic-label">
      {{ t('clinic') }}
    </div>
    <div class="form-value egisz-case__form-value step-form__clinic-value">
      {{ clinicName }}
    </div>

    <div class="form-label egisz-case__form-label">
      {{ t('doctor') }} <required-mark />
    </div>
    <div class="form-value egisz-case__form-value">
      <simple-select
        :items="getDoctorsInCurrentClinic"
        :value="doctorId"
        label-attribute="full_name"
        :validation-messages="stepValidations.doctor_id"
        filterable
        @change="$updateSync('doctorId', $event)"
      />
    </div>

    <div class="form-label egisz-case__form-label">
      {{ t('egisz.steps.modal.type') }}
    </div>
    <div class="form-value egisz-case__form-value">
      <simple-select
        :items="GET_STEP_TYPES"
        :value="type"
        set-first
        @change="$updateSync('type', $event)"
      />
    </div>

    <div class="form-label egisz-case__form-label">
      {{ t('egisz.steps.modal.start_dt') }}
      <required-mark />
    </div>
    <div class="form-value egisz-case__form-value">
      <native-date-time-picker
        :value="startDateTime"
        :validation-messages="stepValidations.opened_at || []"
        fixed-height
        @input="$updateSync('startDateTime', $event)"
      />
    </div>

    <div class="form-label egisz-case__form-label">
      {{ t('egisz.steps.modal.end_dt') }}
    </div>
    <div class="form-value egisz-case__form-value">
      <native-date-time-picker
        :value="endDateTime"
        fixed-height
        @input="$updateSync('endDateTime', $event)"
      />
    </div>

    <div class="form-label egisz-case__form-label">
      {{ t('egisz.steps.modal.place') }}
      <required-mark />
    </div>
    <div class="form-value egisz-case__form-value">
      <simple-select
        :items="GET_STEP_PLACES"
        :value="placeId"
        :validation-messages="stepValidations.netrika_visit_place_id || []"
        :set-first="DEFAULT_FIRST.STEP_PLACE"
        @change="$updateSync('placeId', $event)"
      />
    </div>

    <div class="form-label egisz-case__form-label">
      {{ t('egisz.steps.modal.purpose') }}
      <required-mark />
    </div>
    <div class="form-value egisz-case__form-value">
      <simple-select
        :items="GET_STEP_PURPOSES"
        :value="purposeId"
        :validation-messages="stepValidations.netrika_visit_purpose_id || []"
        :set-first="DEFAULT_FIRST.STEP_PURPOSE"
        @change="$updateSync('purposeId', $event)"
      />
    </div>
  </div>
</template>

<script>
import NativeDateTimePicker from '@/vue_components/common/date/native_date_time_picker/NativeDateTimePicker'
import { mapGetters } from 'vuex'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import { DEFAULT_FIRST } from '@/vue_components/egisz/egisz_module/const/set_first_const'

export default {
  name: 'StepFields',
  components: { RequiredMark, SimpleSelect, NativeDateTimePicker },
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },

    clinicName: {
      type: String,
      default: null,
    },

    doctorId: {
      type: Number,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    startDateTime: {
      type: Date,
      default: null,
    },

    endDateTime: {
      type: Date,
      default: null,
    },

    placeId: {
      type: String,
      default: null,
    },

    purposeId: {
      type: String,
      default: null,
    },

    stepValidations: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      DEFAULT_FIRST,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorsInCurrentClinic: 'getDoctorsInCurrentClinic',
    }),

    ...mapGetters('medCases', [
      'GET_STEP_TYPES',
      'GET_STEP_PLACES',
      'GET_STEP_PURPOSES',
    ]),
  },

}
</script>
