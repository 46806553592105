import { camelToSnake } from '@/_api/_requests/helpers'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'
import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  fixedDocumentTypesAdapter,
} from '@/vue_present/Reuse/DocumentTree/api/DocumentTypesPresenter/fixedDocumentTypesAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class DocumentTypesPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        all () {},
        one () {},
        list () {},
      },
      entity: 'documentType',
      location: 'DocumentTypesPresenter',
    })
  }

  fetchDocumentTypes (id) {
    return this.post({
      url: Routes.document_types_categories_path(),
      data: camelToSnake({
        id,
        categoryType: GLOBAL_CATEGORY_KINDS.DOCUMENT_TYPES,
      }),
    }).promise
  }

  fetchFixedDocumentTypes () {
    return this.post({
      url: Routes.fixed_document_types_list_api_internal_documents_path(),
    }).promise
      .then(fixedDocumentTypesAdapter.toClient)
  }

  fetchProtocolDocumentTypes () {
    // TODO: Ждёт открыва протоколов
  }

  fetchNode (id, categoryType) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      this.entity
    )

    return this._fetchDocumentType(id, categoryType)
      .catch(MRequestError.withDefault({
        catalogs: [],
        items: [],
      }, notification))
  }

  _fetchDocumentType (id, categoryType) {
    switch (categoryType) {
      case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.DOCUMENT:
        return this.fetchDocumentTypes(id)
      case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED:
        return this.fetchFixedDocumentTypes()
      case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.PROTOCOL:
        return this.fetchProtocolDocumentTypes()
    }
  }
}
