<template>
  <m-table-row :use-sortable="useSortable">
    <!-- Чекбокс -->
    <m-table-row-cell
      v-if="useCheckboxes"
      name="tableCheckbox"
    >
      <m-checkbox
        v-model="vmMassCheckBoxValue"
        :indeterminate="indeterminate"
      />
    </m-table-row-cell>

    <!-- Колонки -->
    <slot
      v-for="header in headers"
      :name="`th-${header}`"
    >
      <component
        :is="getHeaderComponent(tableHeaders[header])"
        v-if="tableHeaders[header]"
        :key="`header:${header}`"
        :name="header"
        :active-sortable-name="activeSortableName"
        :width="tableHeaders[header].width"
        @sortChange="onSortChange"
      >
        <!-- Ячейка иконка -->
        <m-icon
          v-if="tableHeaders[header].type === 'icon'"
          v-tooltip="tableHeaders[header].tooltip"
          :icon="tableHeaders[header].value"
          :color="tableHeaders[header].css"
        />

        <!-- Ячейка дефолт -->
        <span
          v-else
          v-tooltip="tableHeaders[header].tooltip"
          :class="tableHeaders[header].css"
        >
          {{ tableHeaders[header].value }}
        </span>
      </component>
    </slot>

    <!-- Редактирование -->
    <m-table-row-cell
      v-if="useEdit"
      name="optionEdit"
    />

    <!-- Удаление -->
    <m-table-row-cell
      v-if="useDelete"
      name="optionDelete"
    />
  </m-table-row>
</template>

<script>
import MTableRow from '@/vue_present/_base/Tables/MTable/MTableRow.vue'
import MTableRowCellSortable from '@/vue_present/_base/Tables/MTable/MTableRowCellSortable.vue'
import MTableRowCell from '@/vue_present/_base/Tables/MTable/MTableRowCell.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { MTableRowSharedProps } from '@/vue_present/_base/Tables/MTableGenerator/props/MTableRowSharedProps'

export default {
  name: 'MTableGeneratorTableHeaderRow',
  components: { MCheckbox, MIcon, MTableRow, MTableRowCellSortable, MTableRowCell },
  props: {
    ...MTableRowSharedProps,

    /** @type {TTableSchemaHeaders} items */
    tableHeaders: {
      type: Object,
      required: true,
    },

    indeterminate: Boolean,
    massCheckBoxValue: Boolean,
  },

  emits: [
    'sortChange',
    'update:massCheckBoxValue',
  ],

  data () {
    return {
      activeSortableName: '',
    }
  },

  computed: {
    /* @returns {'m-table-row-cell-sortable' | 'm-table-row-cell'} */
    tableHeaderComponent () {
      return this.useSortable
        ? 'm-table-row-cell-sortable'
        : 'm-table-row-cell'
    },

    /** @returns {string[]} */
    headers () {
      return Object.keys(this.tableHeaders)
    },

    vmMassCheckBoxValue: {
      get () { return this.massCheckBoxValue },
      set (value) { this.$updateSync('massCheckBoxValue', value) },
    },
  },

  methods: {
    /** @param {TSortOrder} payload */
    onSortChange (payload) {
      this.activeSortableName = payload.name || ''
      this.$emit('sortChange', payload)
    },

    getHeaderComponent (header) {
      if (typeof header?.sorting === 'boolean') {
        return header.sorting
          ? 'm-table-row-cell-sortable'
          : 'm-table-row-cell'
      }

      return this.tableHeaderComponent
    },
  },
}
</script>
