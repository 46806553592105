<template>
  <div class="v-tabs">
    <el-tabs
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
}
</script>
