import { CompanyCheckupBase } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckupBase'
import {
  MCompanyCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MCompanyCheckupPresenter/MCompanyCheckupPresenter'
import { pick } from 'lodash'
import { BladeRunners } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunners'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'

export class CompanyCheckup extends CompanyCheckupBase {
  _presenter = new MCompanyCheckupPresenter()

  /**
   * Включение/выключение генерации названия медосмотра
   * @type {boolean}
   * @private
   */
  _canGenerateTitle = true

  /**
   * @type {BladeRunners}
   */
  bladeRunners = null

  constructor (props = {}) {
    super(props)
    this.bladeRunners = new BladeRunners(null, props.id || null)
  }

  _generateTitle () {
    if (!this._canGenerateTitle) { return }

    this.title = [
      this.company?.title,
      this.plannedStartDate && vueFilters.date(this.plannedStartDate),
      this.department,
    ].filter(Boolean).join(', ')
  }

  _titleHandler (value) {
    this._canGenerateTitle = !value
  }

  setValue (key, value) {
    super.setValue(key, value)

    if (key === 'title') { this._titleHandler(value) }

    if (['company', 'plannedStartDate', 'department'].includes(key)) {
      this._generateTitle()
    }
  }

  async save () {
    const data = pick(this, this._permit)

    return this._presenter.submit(data)
      .then((data) => {
        this.id = data.id
        this.initDocumentsFilters()
        this.bladeRunners.setFieldValue('companyCheckupId', data.id)

        return Promise.resolve(data)
      })
  }

  async delete () {
    return this._presenter.destroy(this.id)
  }

  async loadAggregateCheckupEntries () {
    const { data, errors } = await this._presenter.fetchAggregateCheckupEntries(this.id)
    if (errors) { return }
    this.checkupEntries = data
  }

  async createBladeRunner () {
    return this.bladeRunners.save()
  }

  initDocumentsFilters () {
    this.documents.setFilters({
      ownerId: this.id,
      ownerType: DOCUMENT_OWNER_TYPES.COMPANY_CHECKUP,
    })
  }
}
