import { ItemBase } from '@/_api/_classes/ItemBase'
import { FNS_CODES_DICT, ID_CARD_TO_FNS_DICT } from '@/vue_present/Reuse/Lists/FNS/const'
import { getFNSByDict } from '@/vue_present/Reuse/Lists/FNS/getFNSByDict'
import { getFNSByNSI } from '@/vue_present/Reuse/Lists/FNS/getFNSByNSI'
import formatter from '@/lib/formatters/formatter'

/**
 * @implements ITaxCertificate2024Client
 */
export class TaxCertificate2024Client extends ItemBase {
  _permit = [
    'id',
    'name',
    'secondName',
    'surname',
    'shortName',
    'fullName',
    'phone',
    'inn',
    'birthdate',
    'idCard',
    'fnsIdCard',
  ]

  id = null

  name = null

  secondName = null

  surname = null

  shortName = null

  fullName = null

  shortInfo = null

  phone = null

  inn = null

  birthdate = null

  idCard = {}

  fnsIdCard = null

  constructor (props) {
    super()

    if (!props) { return }

    this._fillProps(props)

    this.shortInfo = formatter.formatValue(props, ['client:shortInfo'])

    if (this.inn) {
      this.innTrigger()

      return
    }

    this.setFnsIdCard(this.idCard).then()
  }

  async setFnsIdCard (idCard) {
    if (idCard?.fnsKind) {
      this.fnsIdCard = {
        id: idCard.fnsKind,
        title: FNS_CODES_DICT[idCard.fnsKind],
      }

      return
    }

    this.fnsIdCard = await getFNSByNSI(idCard.nsiDocumentId) ||
      getFNSByDict(idCard.idCardTitleId, ID_CARD_TO_FNS_DICT)
  }

  /**
   * @private
   */
  innTrigger () {
    this.fnsIdCard = null

    if (!this.idCard) { return }

    this.idCard.series = null
    this.idCard.number = null
    this.idCard.issueDate = null
  }
}
