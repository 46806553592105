import {
  PersonalCheckupVaccinationPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupVaccinationPresenter/PersonalCheckupVaccinationPresenter'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { NestedItemsApi } from '@/_api/_classes/NestedItems/classes/NestedItemsApi'
import {
  PersonalCheckupVaccinationListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupVaccination/PersonalCheckupVaccinationListItem'
import { IPersonalCheckupVaccinationItem } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupVaccinationItem'
import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'

export class PersonalCheckupVaccinationApi extends NestedItemsApi<
  PersonalCheckupVaccinationListItem,
  IPersonalCheckupVaccinationItem,
  PersonalCheckupVaccinationPresenter
> {
  constructor (props?: Pick<IPersonalCheckup, 'id' | 'vaccinations'>) {
    super({
      ownerId: props?.id,
      nestedItemsProps: props?.vaccinations,
      nestedItemsPropName: 'vaccinations',
      nestedItemsClass: PersonalCheckupVaccinationListItem,
      presenter: new PersonalCheckupVaccinationPresenter(),
    })
  }

  async fetchMibpDiseasesGroups (nsiCodes: number[]): Promise<INsiEntry[]> {
    return this.presenter.fetchMibpDiseasesGroups({
      nsiCodes,
    })
  }
}
