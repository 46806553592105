import { render, staticRenderFns } from "./ExpensesPage.vue?vue&type=template&id=f6b3d04a"
import script from "./ExpensesPage.vue?vue&type=script&lang=js"
export * from "./ExpensesPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports