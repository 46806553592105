<template>
  <div class="fixed-document-printable-container">
    <tax-certificate2024-printable-page-one :certificate="certificate" />

    <template v-if="!certificate.taxpayerIsClient">
      <document-page-break />

      <tax-certificate2024-printable-page-two
        v-if="!certificate.taxpayerIsClient"
        :certificate="certificate"
      />
    </template>
  </div>
</template>

<script>

import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  FIRST_PAGE,
  SECOND_PAGE,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024PrintableConsts'
import { DisablePrintClinicHeaderMixin } from '@/vue_present/mixins/DisablePrintClinicHeaderMixin'
import DocumentPageBreak from '@/vue_present/DocumentBase/DocumentPageBreak/DocumentPageBreak.vue'
import TaxCertificate2024PrintablePageOne
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintablePageOne.vue'
import TaxCertificate2024PrintablePageTwo
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/components/TaxCertificate2024Printable/TaxCertificate2024PrintablePageTwo.vue'

export default {
  name: 'TaxCertificate2024Printable',

  components: {
    TaxCertificate2024PrintablePageTwo,
    TaxCertificate2024PrintablePageOne,
    DocumentPageBreak,
  },

  mixins: [i18nScopeMixin, DisablePrintClinicHeaderMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  emits: ['notFound'],

  data () {
    return {
      FIRST_PAGE,
      SECOND_PAGE,
    }
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },

  mounted () {
    setTimeout(() => {
      if (this.$route.query.immediatePrint) {
        print()
      }
    })
  },
}
</script>
