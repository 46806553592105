<template>
  <si class="emd">
    <egisz-emd-tab-controls
      v-if="isEditEmdMode"
      class="mb-10"
    />

    <egisz-emd-tab-table
      v-if="emdList && emdList.length"
      :med-documents="emdList"
      :hide-administrator-mode-cols="isEditEmdMode"
      :can-manage="canManage"
      @editMedDocument="setSelectedMedDocument"
      @deleteMedDocument="deleteMedDocument"
    />
    <not-found-result
      v-else
      :filtered="isFiltered"
      small
      @reset-filters="resetFilters"
    />

    <egisz-emd-tab-footer v-if="emdList && emdList.length" />

    <egisz-emd-modal
      :loading="emdLoaderActive"
      :visible="Boolean(selectedEmd)"
      :med-document="selectedEmd"
      :can-register="isRegisterEmdMode"
      :personal-signed="personalSigned"
      :organization-signed="organizationSigned"
      :can-manage="canManage"
      :can-delete="!isStatusSuccess"
      :can-user-send-to-egisz="canUserSendToEgisz"
      @deleteMedDocument="deleteMedDocument"
      @registerMedDocument="registerMedDocument"
      @close="setSelectedMedDocument"
    >
      <template #personal-sign>
        <sign-popover
          v-if="isEditEmdMode"
          :loading="loading"
          require-personal-sign
          @yes="onSign"
        >
          <template #body>
            <sign-popover-view
              :validation-messages.sync="validationMessages"
              :local-machine.sync="localMachine"
              :workplace-id.sync="workplaceId"
              :certificate-id.sync="certificateId"
            />
          </template>
        </sign-popover>
      </template>

      <template #organization-sign>
        <sign-popover
          v-if="isRegisterEmdMode"
          :loading="loading"
          require-organization-sign
          @yes="onSign"
        >
          <template #body>
            <sign-popover-view
              :validation-messages.sync="validationMessages"
              :local-machine.sync="localMachine"
              :workplace-id.sync="workplaceId"
              :certificate-id.sync="certificateId"
            />
          </template>
        </sign-popover>
      </template>
    </egisz-emd-modal>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si'
import EgiszEmdTabControls from '@/vue_components/egisz/egisz_emd_tab/components/EgiszEmdTabControls'
import EgiszEmdTabTable from '@/vue_components/egisz/egisz_emd_tab/components/EgiszEmdTabTable'
import EgiszEmdTabFooter from '@/vue_components/egisz/egisz_emd_tab/components/EgiszEmdTabFooter'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EgiszEmdModal from '@/vue_components/egisz/egisz_emd_tab/components/EgiszEmdModal'
import NotFoundResult from '@/vue_components/common/not_found_result'
import { EMD_MODES } from '@/vue_components/egisz/egisz_emd_tab/const'
import { cloneDeep } from 'lodash'
import { MED_DOCUMENT_HOLDER_SCOPE } from '@/api_entities/egisz/med_documents/med_document_holder_scope'
import { VALIDATED_FIELDS } from '@/vue_components/store/modules/egiszEmd/const'
import ValidationMixin from '@/vue_components/egisz/egisz_med_document/validation/validationMixin'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import SignPopover from '@/vue_components/SignPopover/SignPopover'
import SignPopoverView from '@/vue_components/SignPopover/components/SignPopoverView'
import { getDefaultMedDocumentsFilters } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import { EGISZ_STATUSES } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export default {
  name: 'EgiszEmdMaster',

  components: {
    SignPopoverView,
    SignPopover,
    NotFoundResult,
    EgiszEmdModal,
    EgiszEmdTabFooter,
    EgiszEmdTabTable,
    EgiszEmdTabControls,
    Si,
  },

  mixins: [ValidationMixin, SpinnerHolder],

  props: {
    mode: {
      type: String,
      default: EMD_MODES.EDIT,
    },

    clientId: {
      type: Number,
      default: null,
    },

    medicalRecordId: {
      type: Number,
      default: null,
    },

    canManage: Boolean,
    canUserSendToEgisz: Boolean,
  },

  computed: {
    ...mapState('egiszEmd', {
      emdList: 'emdList',
      selectedEmd: 'selectedEmd',
      emdLoaderActive: 'emdLoaderActive',
      sign: 'sign',
    }),

    ...mapGetters('egiszEmd/filtersBase', {
      filters: 'vxGetFilters',
      isFiltered: 'vxGetIsFiltered',
    }),

    ...mapGetters({
      getClinicId: 'GET_APP_CONF_CURRENT_CLINIC_ID',
      getCurrentUserId: 'GET_APP_CONF_CURRENT_USER_ID',
    }),

    certificateId: {
      get () { return this.sign.certificateId },
      set (value) { this.vxSetSignAttribute({ field: VALIDATED_FIELDS.CERTIFICATE_ID, value }) },
    },

    workplaceId: {
      get () { return this.sign.workplaceId },
      set (value) { this.vxSetSignAttribute({ field: VALIDATED_FIELDS.WORKPLACE_ID, value }) },
    },

    localMachine: {
      get () { return this.sign.localMachine },
      set (value) { this.vxSetSignAttribute({ field: VALIDATED_FIELDS.LOCAL_MACHINE, value }) },
    },

    attachmentSignatureStatus () {
      return (this.selectedEmd && this.selectedEmd.attachment_signature_status) || {}
    },

    personalSigned () {
      return Boolean(
        this.attachmentSignatureStatus &&
        this.attachmentSignatureStatus.personal_signed
      )
    },

    organizationSigned () {
      return Boolean(
        this.attachmentSignatureStatus &&
        this.attachmentSignatureStatus.organization_signed
      )
    },

    canOrganizationSign () {
      return Utils.can('manage', 'OrganizationSign')
    },

    canPersonalSign () {
      return Utils.can(Utils.ACTIONS.MANAGE_SELF, 'EgiszSign') ||
          Utils.can(Utils.ACTIONS.MANAGE, 'EgiszSign') ||
          Utils.can(Utils.ACTIONS.MANAGE, 'SignForSelf')
    },

    isEditEmdMode () {
      return this.mode === EMD_MODES.EDIT
    },

    isRegisterEmdMode () {
      return this.mode === EMD_MODES.REGISTER
    },

    isStatusSuccess () {
      return (this.selectedEmd && this.selectedEmd.status) === EGISZ_STATUSES.SUCCESS
    },
  },

  watch: {
    filters (to) {
      this.getMedDocuments({
        filters: { ...to, ...this.getExtendedFilters() },
        standAlone: true,
      })
    },
  },

  created () {
    this.resetFilters()
  },

  methods: {
    ...mapMutations('egiszEmd/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
      vxResetFilters: 'vxResetFilters',
    }),

    ...mapMutations('egiszEmd', {
      setSelectedMedDocument: 'SET_SELECTED_MED_DOCUMENT',
      vxSetSignAttribute: 'vxSetSignAttribute',
    }),

    ...mapActions('egiszEmd', {
      getMedDocuments: 'getMedDocuments',
      deleteMedDocument: 'deleteMedDocument',
      registerMedDocument: 'registerMedDocument',
      setFiltersDefault: 'resetFilters',
      signMedDocument: 'signMedDocument',
    }),

    getExtendedFilters () {
      const commonFilters = {
        without_medical_cases: true,
      }

      return this.isRegisterEmdMode
        ? commonFilters
        : {
          ...commonFilters,
          client_id: this.clientId,
          clinic_id: this.getClinicId,
          medical_record_id: this.medicalRecordId,
        }
    },

    resetFilters () {
      this.vxResetFilters(getDefaultMedDocumentsFilters())
    },

    onSign () {
      const medDocument = cloneDeep(this.selectedEmd)

      const validatedData = {
        workplaceId: this.workplaceId,
        certificateId: this.certificateId,
      }
      if (!this.validate(validatedData)) { return }

      const medDocumentId = medDocument.id
      const holderScope = this.isRegisterEmdMode
        ? MED_DOCUMENT_HOLDER_SCOPE.ORGANIZATION
        : MED_DOCUMENT_HOLDER_SCOPE.PERSONAL

      const payload = {
        workplace_id: this.sign.workplaceId,
        certificate_id: this.sign.certificateId,
        local_machine: this.sign.localMachine,
        holder_scope: holderScope,
      }

      this.withSpinner(this.signMedDocument({ medDocumentId, payload }))
    },
  },
}
</script>
