<template>
  <m-panel
    class="h-100"
    icon="checkups"
    :title="t('pageNotFound')"
  >
    <e404>
      <template #action>
        <router-link :to="{ name: CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS }">
          {{ t('checkups.redirect') }}
        </router-link>
      </template>
    </e404>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import E404 from '@/vue_present/ErrorPages/E404.vue'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'

export default {
  name: 'Checkup404',
  components: { E404, MPanel },
  computed: {
    CHECKUPS_ROUTE_NAMES () {
      return CHECKUPS_ROUTE_NAMES
    },
  },
}
</script>
