<template>
  <m-modal
    :dialog-title="t('finance.createExpenseGroup')"
    modal-title-icon="tasks"
    type="success"
    :visible="visible"
    @update:visible="$updateSync('visible', $event)"
  >
    <m-input
      v-model="modalExpenseGroupTitle"
      :label="t('title')"
      required
      validator-name="expenseGroupTitle"
      @registerValidator="onRegisterValidator"
    />

    <template #footer-right-action>
      <m-button
        class="expense-modal__save"
        template="save"
        @click="saveExpenseGroup"
      />
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MExpensePresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/MExpensePresenter'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'ExpensesGroupAddModal',
  components: { MButton, MInput, MModal },

  mixins: [ValidationParentMixin],

  props: {
    visible: Boolean,
  },

  data () {
    return {
      modalExpenseGroupTitle: '',
    }
  },

  methods: {
    saveExpenseGroup () {
      if (this.hasErrors()) { return }

      new MExpensePresenter().saveExpenseGroup({
        expenseGroup: { title: this.modalExpenseGroupTitle },
      })
        .then((data) => {
          this.modalExpenseGroupTitle = null

          this.$updateSync('visible', false)
          this.$emit('expensesGroupSaved', data)
        })
    },
  },
}
</script>
