<template>
  <m-panel
    icon="fileAlt"
    :title="t('pageNotFound')"
  >
    <e404 class="fixed-document-not-found" />
  </m-panel>
</template>

<script>
import E404 from '@/vue_present/ErrorPages/E404.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'

export default {
  name: 'FixedDocumentNotFoundPage',
  components: { MPanel, E404 },
}
</script>
