import {
  MedicalPolicyListItem,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/list/MedicalPolicyListItem'

export const medicalPoliciesListAdapter = {
  toClient (response) {
    const data = response.data || []

    return {
      data: data.map((item) => new MedicalPolicyListItem(item)),
      totalPages: response.totalPages,
      totalItems: response.totalItems,
    }
  },
}
