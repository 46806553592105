import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import {
  personalCheckupCheckupEntriesAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupCheckupEntriesPresenter/personalCheckupCheckupEntriesAdapter'

export class PersonalCheckupCheckupEntriesPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () {},
        all () {},
        list () {},
      },
      entity: 'personalCheckupCheckupEntries',
      location: 'MPersonalCheckupPresenter',
    })
  }

  submit (data, config = {}) {
    return super.patch({
      url: Routes.checkups_personal_path(data.personalCheckupId),
      data: JSON.stringify(camelToSnake(personalCheckupCheckupEntriesAdapter.toServer(data))),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
  }
}
