<template>
  <validation-wrapper
    :errors="validationMessages"
    :fixed-height="fixedHeight"
    :class="{ 'w-100': fullWidth }"
  >
    <el-select
      ref="simpleSelect"
      v-loading="loading"
      v-tooltip="itemTitle"
      :value="value"
      :clearable="clearable"
      :filterable="filterable"
      :filter-method="filterMethod"
      :placeholder="placeholder"
      :disabled="disabled"
      :remote="remote"
      :remote-method="remoteMethod"
      :value-key="valueKey"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :class="{ 'w-100': fullWidth }"
      :popper-class="popperClass"
      @change="$emit('change', clearableResult($event))"
      @blur="$emit('blur', $event)"
      @visible-change="$emit('visibleChange', $event)"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>

      <slot name="emptyOption">
        <el-option
          v-if="needEmptyOption"
          :label="emptyOptionLabel"
          :value="needEmptyOption"
        />
      </slot>

      <slot
        v-for="(item, index) in items"
        name="option"
        :item="item"
      >
        <el-option
          :key="`el-option:${item[valueAttribute]}:${index}`"
          :value="valueKey ? item : item[valueAttribute]"
          :label="item[labelAttribute]"
        />
      </slot>
    </el-select>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'SimpleSelect',
  components: { ValidationWrapper },
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    validationMessages: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [Number, String, Boolean, Object, Array],
      default: null,
    },

    valueAttribute: {
      type: String,
      default: 'id',
    },

    labelAttribute: {
      type: String,
      default: 'title',
    },

    fixedHeight: {
      type: Boolean,
      default: true,
    },

    clearable: Boolean,

    filterable: Boolean,
    filterMethod: {
      type: Function,
      default: undefined,
    },

    // установит активным какое-то значение, если !value. See: emitDefaultValue()
    // если setFirst: Boolean - установит первое значение в списке
    // если setFirst: String - установит значение элементу с названием в setFirst
    setFirst: {
      type: [Boolean, String],
      default: null,
    },

    placeholder: {
      type: String,
      default: t('choose'),
    },

    // Добавит null el-option в начало списка
    needEmptyOption: Boolean,
    emptyOptionLabel: PropsTypes.String(t('all_values')),

    disabled: Boolean,

    remote: Boolean,
    remoteMethod: {
      type: Function,
      default: undefined,
    },

    valueKey: {
      type: String,
      default: undefined,
    },

    fullWidth: Boolean,
    loading: Boolean,
    multiple: Boolean,
    collapseTags: Boolean,

    popperClass: PropsTypes.String(),
  },

  computed: {
    itemTitle () {
      const item = this.items && this.items
        .find((item) => item[this.valueAttribute] === this.value)

      return item
        ? item[this.labelAttribute]
        : null
    },
  },

  watch: {
    items (to) {
      this.emitDefaultValue()
    },

    value (to) {
      if (to) { return }
      this.emitDefaultValue()
    },
  },

  methods: {
    emitDefaultValue () {
      if (!this.setFirst) { return }
      if (this.value) { return }
      if (!this.items || !this.items.length) { return }

      switch (typeof this.setFirst) {
        case 'boolean': this.emitFirstValue(); break
        case 'string': this.emitValueByName(); break
        default:
          throw new Error('This method supports Boolean and String values only')
      }
    },

    emitFirstValue () {
      this.$emit('change', this.items[0][this.valueAttribute])
    },

    emitValueByName () {
      const item = this.items
        .find((item) => item[this.labelAttribute].toLowerCase() === this.setFirst.toLowerCase())

      if (!item) { return }

      this.$emit('change', item[this.valueAttribute])
    },

    clearableResult ($event) {
      if (!this.valueKey) {
        return Utils.ternary($event !== '', $event)
      }

      if (this.multiple) {
        return Utils.ternary($event && $event.length, $event, [])
      }

      return $event || null
    },
  },
}
</script>
