<template>
  <m-popover
    :visibility.sync="popoverVisibility"
    popper-class="w-400"
    :confirm-text="popoverConfig.confirmText"
    :cancel-text="t('close')"
    :yes-type="popoverConfig.yesType"
    inherited-visibility
    @yes="updateFinals"
    @no="popoverVisibility = false;"
  >
    <template #reference>
      <slot name="reference" />
    </template>

    <template #message>
      <div class="bold">
        {{ t('checkups.documents.bladeRunnerDocument.massUpdateForm') }}
      </div>
    </template>

    <template #body>
      <div class="flex flex-column mt-indent">
        <m-input
          v-model="vmFinalCabinet"
          :label="t('checkups.documents.bladeRunnerDocument.updateForm.cabinet')"
        />
        <m-input
          v-model="vmFinalSpecialist"
          :label="t('checkups.documents.bladeRunnerDocument.updateForm.doctor')"
        />
        <m-textarea
          v-model="vmFooterData"
          :label="t('checkups.documents.bladeRunnerDocument.updateForm.footerData')"
        />
      </div>
    </template>
  </m-popover>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { BladeRunners } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunners'
import { MCheckupEntryTypePresenter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/MCheckupEntryTypePresenter'

const localBinding = (field) => ({
  get () { return this.companyBladeRunner[field] },
  set (value) { this.companyBladeRunner.setFieldValue(field, value) },
})

/**
 * АТЕНШОН
 * Finals: [finalCabinet, finalSpecialist, footerData]
 */
export default {
  name: 'CompanyBladeRunnerFinalsEditor',
  components: { MTextarea, MPopover, MInput },

  props: {
    companyBladeRunner: { type: BladeRunners, required: true },
    isNewMode: Boolean,
  },

  emits: ['finalsUpdated'],

  data () {
    return {
      popoverVisibility: false,
    }
  },

  computed: {
    popoverConfig () {
      return this.isNewMode
        ? {
            confirmText: this.t('create'),
            yesType: 'success',
          }
        : {
            confirmText: this.t('change_record'),
            yesType: 'warning',
          }
    },

    vmFinalCabinet: localBinding('finalCabinet'),
    vmFinalSpecialist: localBinding('finalSpecialist'),
    vmFooterData: localBinding('footerData'),
  },

  mounted () {
    new MCheckupEntryTypePresenter()
      .defaultSpecialistCabinet()
      .then(({ finalSpecialist, finalCabinet }) => {
        this.vmFinalSpecialist = finalSpecialist
        this.vmFinalCabinet = finalCabinet
      })
  },

  methods: {
    async updateFinals () {
      const response = await this.companyBladeRunner.updateFinals()
      if (response?.errors) { return }

      this.popoverVisibility = false

      this.$emit('finalsUpdated', this.companyBladeRunner.id)
    },
  },
}
</script>
