<template>
  <div class="sidebar__filter filter">
    <select-filter
      :label="t('egisz.components.egisz_med_records.filters.doctor')"
      :items="getDoctors"
      :value="filters[doctorId]"
      label-attribute="full_name"
      @change="vxSetFilterValue({ filter: doctorId, value: $event })"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import formatter from '@/lib/formatters/formatter'
import { CASE_FILTERS_FIELDS } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import SelectFilter from '@/vue_components/egisz/egisz_module/components/filters/SelectFilter'

export default {
  name: 'EgiszCasesFilterDoctor',
  components: { SelectFilter },

  data () {
    return {
      doctorId: CASE_FILTERS_FIELDS.DOCTOR_ID,
    }
  },

  computed: {
    ...mapGetters('medCases/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctors: 'getDoctors',
    }),
  },

  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
    }),

    getFullName (doctor) {
      return formatter.formatValue(doctor, ['user:name'])
    },
  },
}
</script>
