import {
  TAX_CERTIFICATE_ROUTE_NAMES,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/router/routeNames'
import TaxCertificate2024App
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/TaxCertificate2024App.vue'
import TaxCertificate2024Editor
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/pages/TaxCertificate2024Editor.vue'
import TaxCertificate2024Printable
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/pages/TaxCertificate2024Printable.vue'

export const taxCertificate2024Routes = {
  path: 'tax-certificate-2024',
  name: TAX_CERTIFICATE_ROUTE_NAMES.INDEX,

  component: TaxCertificate2024App,

  children: [
    {
      path: 'new',
      name: TAX_CERTIFICATE_ROUTE_NAMES.NEW,
      component: TaxCertificate2024Editor,
    },

    {
      path: ':documentId/edit',
      name: TAX_CERTIFICATE_ROUTE_NAMES.EDIT,
      component: TaxCertificate2024Editor,
    },

    {
      path: ':documentId',
      name: TAX_CERTIFICATE_ROUTE_NAMES.SHOW,
      meta: {
        title: t('taxCertificate2024'),
      },
      component: TaxCertificate2024Printable,
    },
  ],
}
