<template>
  <checkup-doctors-and-tests
    :checkup="companyCheckup"
    :readonly="isShowMode"
  >
    <template #before>
      <!--      председатель врачебной комиссии     -->
      <reusable-doctors-list
        :value="companyCheckup.responsible"
        :label="t('checkups.company.info.responsible')"
        :disabled="isShowMode"
        :current-clinic="vxClinicsIds"
        required
        validator-name="responsible"
        option-label="fullName"
        m-fixed-height
        @onDoctorChange="companyCheckup.setValue('responsible', $event)"
        @registerValidator="$registerValidator($event)"
      />
    </template>

    <template #specialistsAndTestsDescription>
      <span v-if="isNewMode" />
    </template>

    <template #specialistsAndTests>
      <span v-if="isNewMode" />
    </template>

    <template #after>
      <span
        v-if="!isNewMode"
        v-html="t('checkups.doctorsAndTests.stats', {
          total: companyCheckup.stats.total || 0,
          ready: companyCheckup.stats.ready || 0,
          inProgress: companyCheckup.stats.inProgress || 0,
          planned: companyCheckup.stats.planned || 0,
        })"
      />
    </template>
  </checkup-doctors-and-tests>
</template>

<script>
import CheckupDoctorsAndTests from '@/vue_apps/CheckupsModule/components/CheckupDoctorsAndTests.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import { CompanyCheckup } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckup'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyCheckupDoctorAndTests',
  components: { ReusableDoctorsList, CheckupDoctorsAndTests },
  mixins: [
    CheckupsPropsMixin,
  ],

  props: {
    companyCheckup: CompanyCheckup,
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxClinicsIds: 'vxGetDoctorClinicsIds',
    }),
  },
}
</script>
