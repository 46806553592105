<template>
  <div id="softphone">
    <el-row
      type="flex"
      justify="center"
    >
      <div class="free-input-icon-wrapper">
        <i
          class="free-input-icon fad fa-fw fa-calculator"
          :class="{'free-input-active': freeInput}"
          data-toggle="tooltip"
          :title="T.telephony.free_input"
          @click="onFreeInputClick"
        />
      </div>

      <el-col>
        <the-mask
          v-if="!freeInput"
          v-model="value"
          :mask="parsedMask"
          :placeholder="placeholder"
          class="softphone-masked-input"
          type="tel"
        />
        <input
          v-else
          v-model="value"
          type="text"
          class="softphone-masked-input"
        >

        <i
          class="masked-input-reset fad fa-backspace"
          @click="onMaskedInputResetClick"
        />
      </el-col>
    </el-row>

    <el-row class="softphone-number-keyboard">
      <el-col
        v-for="key in numberKeyboard"
        :key="key.val"
        :span="8"
        class="softphone-number-key-wrapper"
      >
        <button
          v-if="!(!freeInput && (key.val === '*' || key.val === '#'))"
          class="softphone-number-key"
          :class="{
            'free-input-zero-key': freeInput && key.val === '0',
            'free-input-key-pressed': key.isPressed
          }"
          @mousedown="onKeyMouseDown(key)"
          @mouseup="onKeyMouseUp(key)"
          @mouseleave="doResetPressedButton(key)"
        >
          <span>{{ key.val }}</span>
        </button>
      </el-col>
    </el-row>

    <el-row
      type="flex"
      justify="center"
    >
      <el-button
        id="softphone_call"
        :disabled="value === null && moduleIsEnabled.telephony"
        :title="T.telephony.call_out"
        size="mini"
        icon="fad fa-fw fa-phone-alt tooltip-bottom"
        circle
        @click="sendCallInfo"
      />
      <el-button
        id="softphone_sms"
        :disabled="value === null && moduleIsEnabled.sms"
        :title="T.sms.send_custom_message"
        size="mini"
        icon="fad fa-fw fa-sms tooltip-bottom"
        circle
        @click="sendCustomSms('sms')"
      />
      <m-button
        id="softphone_whatsapp"
        template="whatsappOut"
        button-style="fill"
        circle
        use-brand-icon
        :disabled="!value || !moduleIsEnabled.whatsapp"
        @click="sendCustomSms('whatsapp')"
      />
    </el-row>

    <introduction-modal
      ref="softPhoneModal"
      :modal-icon="modalWindow.icon"
      :modal-inner="modalWindow.inner"
    />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import IntroductionModal from '../../../modal_windows/introduction/introduction_modal'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

const DEFAULT_MASK = '# (###) ###-##-##'

export default {
  name: 'Softphone',
  components: {
    MButton,
    TheMask,
    IntroductionModal,
  },
  data: () => ({
    mask: DEFAULT_MASK,
    value: null,
    // 7jt3w2
    simpleKeys: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'],
    numberKeyboard: [],
    currentKeyVal: null,
    freeInput: false,
    mouseTimeout: {
      timer: null,
      result: '',
    },
    // 7jt1ev
    modalWindow: {
      icon: '',
      inner: '',
    },
    // 7jt1ev (11)
    moduleIsEnabled: {
      sms: gon.application.sms_module_enabled,
      telephony: gon.application.telephony.self,
      whatsapp: gon.application.whatsapp_module_enabled,
    },
  }),
  computed: {
    parsedMask () {
      const mask = Services.phoneMask.getMaskSettings(this.value).mask

      return `+${mask || DEFAULT_MASK}`
    },

    placeholder () {
      return '+' + this.mask.replace(/#/g, '_')
    },
  },
  mounted () {
    // получение массива значений клавиатуры
    this.numberKeyboard = this.simpleKeys.map((key) => ({ val: key, isPressed: false }))
  },
  methods: {
    // 7jt1ev
    doModalVisible () {
      this.$refs.softPhoneModal.modalShow()
    },

    // сброс эффекта наведения мыши на кнопке(key) или кнопках
    doResetPressedButton (key) {
      if (key) {
        key.isPressed = false
      } else {
        this.numberKeyboard.forEach((item) => { item.isPressed = false })
      }
    },
    sendCallInfo () {
      const canManage = Services.security.canManageCall
      if (this.moduleIsEnabled.telephony && canManage) {
        $.ajax({
          url: Routes.start_call_out_telephony_telephony_route_index_path(),
          method: 'POST',
          data: {
            outcoming_call: {
              phone_number: this.value,
            },
          },
        })
          .success(() => {
            Notificator.success(T.telephony.call_started)
          })
          .error((data) => {
            console.error(data)
            switch (gon.application.telephony.selected_provider) {
              case 'uis':
                Notificator.error(T.telephony.uis.errors[data.responseJSON])
                break
              case 'itoolabs':
                Notificator.error(T.telephony.itoolabs.errors[data.responseJSON])
                break
              default:
                Notificator.error(T.telephony.mango.errors[data.responseJSON])
                break
            }
          })
      }

      if (!canManage) Notificator.error(T.insufficient_access_rights)
      if (!this.moduleIsEnabled.telephony) {
        this.modalWindow.icon = 'fa-phone-office'
        this.modalWindow.inner = T.introduction.telephony
        this.doModalVisible()
      }
    },

    /** @param {TMessageProvider} messageProvider */
    sendCustomSms (messageProvider) {
      const canManage = this.$security.canManageMessage
      if (this.moduleIsEnabled.sms && canManage) {
        const editorData = {
          phoneNumber: this.value,
          messageProvider,
        }
        this.$pubSub.emitAsync('CUSTOM_MESSAGE_EDITOR.SHOW', editorData)
      }

      if (!canManage) Notificator.error(T.insufficient_access_rights)
      if (!this.moduleIsEnabled.sms) {
        this.modalWindow.icon = 'fa-sms'
        this.modalWindow.inner = T.introduction.sms
        this.doModalVisible()
      }
    },
    // 7jt3w2
    onFreeInputClick () {
      this.freeInput = !this.freeInput
      this.value = null
    },
    // 7jt3w2
    onMaskedInputResetClick () {
      if (!this.value && this.value !== '0') return null
      this.value = this.value.slice(0, -1)
    },
    // 7jt3w2 - onKeyMouseDown и onKeyMouseUp - реализация long click
    onKeyMouseDown (key) {
      // активация стилей зажатия
      key.isPressed = true
      // для проверки, что в mouseDown и mouseUp
      // была нажата одна и та же кнопка
      this.currentKeyVal = key.val

      // если 0, то
      // если в течении 700мс не сработает onKeyMouseUp()
      // то value += "+"
      if (this.freeInput && key.val === '0') {
        this.mouseTimeout.timer = setTimeout(() => {
          this.onNumberKeyClick('+')
          this.mouseTimeout.result = '+'
        }, 700)
      }
    },
    onKeyMouseUp (key) {
      // фикс неправильного поведения, при котором
      // mouseUp срабатывает над другой кнопкой
      if (key.val !== this.currentKeyVal) {
        return
      }

      // если кнопка отжата во freeInput и это была не кнопка 0,
      // то onNumberKeyClick,
      // если 0(+), то он уже был обработан в onKeyMouseDown
      clearTimeout(this.mouseTimeout.timer)

      if (this.freeInput) {
        if (this.mouseTimeout.result !== '+') {
          this.onNumberKeyClick(key.val)
        }
        this.mouseTimeout.result = ''
      } else {
        this.onNumberKeyClick(key.val)
      }

      // деактивация стилей зажатия
      this.doResetPressedButton(key)
    },
    // 7jt3w2
    onNumberKeyClick (val) {
      // решение проблемы с удалением лишних символов в input mask (the-mask)
      if (!this.freeInput && this.value && this.value.length >= this.parsedMask.match(/#/g).length) return null

      if (this.simpleKeys.indexOf(val) !== -1 || val === '+') {
        this.value = !this.value ? val : this.value + val
      }
    },
    // 7jt3w2 - reset data
    onRefresh () {
      this.value = null
      this.doResetPressedButton()
    },
  },

}
</script>
