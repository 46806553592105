import { getBaseRoute, MESSAGES_CONFIGURATIONS_ROUTES } from '@/vue_apps/app_configuration/messages/router/routeNames'
import CommonSettingsPage from '@/vue_apps/app_configuration/messages/pages/CommonConfigurationsPage.vue'
import AppointmentNotify from '@/vue_apps/app_configuration/messages/pages/AppointmentNotify.vue'
import AppointmentRemind from '@/vue_apps/app_configuration/messages/pages/AppointmentRemind.vue'
import AppointmentConfirmation from '@/vue_apps/app_configuration/messages/pages/AppointmentConfirmation.vue'
import ClientsBirthday from '@/vue_apps/app_configuration/messages/pages/ClientsBirthday.vue'
import ServicesStatus from '@/vue_apps/app_configuration/messages/pages/ServicesStatus.vue'
import OnlineRecord from '@/vue_apps/app_configuration/messages/pages/OnlineRecord.vue'
import ClientsFeedback from '@/vue_apps/app_configuration/messages/pages/ClientsFeedback.vue'
import CommonSmsSettings from '@/vue_apps/app_configuration/messages/pages/CommonSmsSettings.vue'
import CommonCallsSettings from '@/vue_apps/app_configuration/messages/pages/CommonCallsSettings.vue'

export const navigation = Object.freeze({
  general: {
    path: getBaseRoute('/general'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.GENERAL,
    component: CommonSettingsPage,
    meta: {
      label: t('sms.general_settings'),
    },
  },

  generalSmsSettings: {
    path: getBaseRoute('/general-sms-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.GENERAL_SMS_SETTINGS,
    component: CommonSmsSettings,
    meta: {
      label: t('sms.general_sms_settings'),
    },
  },

  generalCallsSettings: {
    path: getBaseRoute('/general-calls-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.GENERAL_CALLS_SETTINGS,
    component: CommonCallsSettings,
    meta: {
      label: t('sms.general_calls_settings'),
    },
  },

  appointmentNotifySettings: {
    path: getBaseRoute('/appointment-notify-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.APPOINTMENT_NOTIFY,
    component: AppointmentNotify,
    meta: {
      label: t('sms.appointment_notify_settings'),
    },
  },

  appointmentRemindSettings: {
    path: getBaseRoute('/appointment-remind-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.APPOINTMENT_REMIND,
    component: AppointmentRemind,
    meta: {
      label: t('sms.appointment_remind_settings'),
    },
  },

  appointmentConfirmationSettings: {
    path: getBaseRoute('/appointment-confirmation-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.APPOINTMENT_CONFIRMATION,
    component: AppointmentConfirmation,
    meta: {
      label: t('sms.appointment_confirmation_settings'),
    },
  },

  clientsBirthdaySettings: {
    path: getBaseRoute('/clients-birthday-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.CLIENTS_BIRTHDAY,
    component: ClientsBirthday,
    meta: {
      label: t('sms.clients_birthday_settings'),
    },
  },

  servicesStatusSettings: {
    path: getBaseRoute('/services-status-settings'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.SERVICES_STATUS,
    component: ServicesStatus,
    meta: {
      label: t('sms.services_status_settings'),
    },
  },

  onlineRecord: {
    path: getBaseRoute('/online-record'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.ONLINE_RECORD,
    component: OnlineRecord,
    meta: {
      label: t('sms.online_record'),
    },
  },

  clientsFeedback: {
    path: getBaseRoute('/clients-feedback'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.CLIENTS_FEEDBACK,
    component: ClientsFeedback,
    meta: {
      label: t('sms.clients_feedback'),
    },
  },

  index: {
    path: getBaseRoute('*'),
    name: MESSAGES_CONFIGURATIONS_ROUTES.INDEX,
    redirect: { name: MESSAGES_CONFIGURATIONS_ROUTES.GENERAL },
  },
})
