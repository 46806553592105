<template>
  <m-panel
    class="checkup-documents"
    :title="t('checkups.documents.title')"
  >
    <template
      v-if="isShowMode"
      #headExtra
    >
      <slot name="headExtra">
        <m-button
          size="mini"
          type="dark"
          button-style="fill"
          icon="list"
          disabled="CheckupsCompany"
          :text="t('checkups.documents.bladeRunner')"
          @click="$emit('createBladeRunner')"
        />

        <m-button
          size="mini"
          type="dark"
          button-style="fill"
          icon="document"
          disabled="CheckupsCompany"
          :text="t('checkups.documents.personalElmkMedicalAssessment.buttonText')"
          @click="$emit('createElmkMedicalAssessment')"
        />

        <m-button
          size="mini"
          type="dark"
          button-style="fill"
          icon="document"
          disabled="CheckupsCompany"
          :text="t('conclusion')"
          @click="$emit('createSimpleMedicalAssessment')"
        />
      </slot>
    </template>

    <m-si-generator
      v-if="!isNewMode"
      v-loading="checkupDocumentsList.loading"
      :items="checkupDocumentsList.data"
      :si-generator-schema="siSchema"
      :page-count="checkupDocumentsList.totalPages"
      :current-page="checkupDocumentsList.currentPage"
      nfr-small
      @update:currentPage="checkupDocumentsList.setPage($event); checkupDocumentsList.fetchAll()"
      @onItemClick="onOpenDocument"
      @onItemDelete="onDeleteDocument"
    >
      <template #add>
        <reusable-documents-tree-modal
          use-document-types
          :use-fixed-document-types="!!clientId"
          show-notice
          :excluded-nodes="excludedNodes"
          @documentItemClick="onDocumentItemClick"
        >
          <template #default="{ openModal }">
            <m-button
              icon="document"
              type="success"
              :tooltip="t('add_document')"
              disabled="CheckupsCompany"
              plus-icon
              @click="openModal"
            />
          </template>
        </reusable-documents-tree-modal>
      </template>
    </m-si-generator>

    <checkup-available-after-saving v-else />
  </m-panel>
</template>

<script lang="ts">
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import CheckupAvailableAfterSaving from '@/vue_apps/CheckupsModule/components/CheckupAvailableAfterSaving.vue'
import { CheckupDocumentsList } from '@/vue_apps/CheckupsModule/store/CheckupDocumentsList'
import { getDocumentRoute } from '@/vue_apps/CheckupsModule/router/routeHelpers'
import { defineComponent } from 'vue'
import {
  IReusableDocumentTreeDocumentItem,
} from '@/vue_present/Reuse/DocumentTree/interfaces/reusableDocumentTreeDocumentItem'
import { TDocumentOwner } from '@/vue_apps/Documents/DocumentsOwners/TDocumentOwner'
import ReusableDocumentsTreeModal from '@/vue_present/Reuse/DocumentTree/ReusableDocumentsTreeModal.vue'
import {
  DOCUMENT_OWNER_TYPES,
  DOCUMENT_OWNER_TYPES_IDS_PROPS,
} from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'
import { IDocumentsOwnerAttribute } from '@/_declarations/documents/IDocumentsOwnerAttribute'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import {
  IReusableDocumentTreeFixedDocumentItem,
} from '@/vue_present/Reuse/DocumentTree/interfaces/IReusableDocumentTreeFixedDocumentItem'
import {
  REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES,
} from '@/vue_present/Reuse/DocumentTree/store/reusableDocumentsTreeDocumentTypes'
import { IPubSubCreatedDocument } from '@/_declarations/pubSub/interfaces/IPubSubCreatedDocument'
import { PUB_SUB_EVENTS } from '@/_declarations/pubSub/pubSubEvents'
import { CHECKUP_DOCUMENT_API_MAP } from '@/vue_apps/CheckupsModule/store/checkupDocumentApiMap'
import { getDocumentsOwnersAttributes } from '@/vue_present/Reuse/DocumentTree/store/getDocumentsOwnersAttributes'
import { getCheckupDocumentsExcludedNodes } from '@/vue_apps/CheckupsModule/store/getCheckupDocumentsExcludedNodes'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'

const getSiSchema = () => new MSiSchema()
  .addPagination()
  .addTableSchema(
    new MTableSchema({
      headers: {
        date: new MTableHeader().addFixedWidth('100px'),
        title: new MTableHeader(),
      },
    })
      .addDeleteIcon(window.Services.security.canManageCheckupsCompany)
  )

export default defineComponent({
  name: 'CheckupDocuments',

  components: {
    ReusableDocumentsTreeModal,
    CheckupAvailableAfterSaving,
    MSiGenerator,
    MButton,
    MPanel,
  },

  mixins: [
    CheckupsPropsMixin,
  ],

  props: {
    checkupDocumentsList: CheckupDocumentsList,
    clientId: { type: Number, default: null },
    companyId: { type: Number, default: null },
  },

  emits: [
    'createBladeRunner',
    'createSimpleMedicalAssessment',
    'createElmkMedicalAssessment',
    'createOldDocument',
    'delete',
  ],

  data () {
    return {
      siSchema: getSiSchema() as TMSiGeneratorSchema,
      excludedNodes: getCheckupDocumentsExcludedNodes(),
    }
  },

  methods: {
    onOpenDocument (documentOwner: TDocumentOwner) {
      window.open(getDocumentRoute(documentOwner))
    },

    onDocumentItemClick (documentItem: IReusableDocumentTreeDocumentItem | IReusableDocumentTreeFixedDocumentItem) {
      switch (documentItem.documentType) {
        case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.DOCUMENT:
          return this.createDocument(
            documentItem as IReusableDocumentTreeDocumentItem,
            this.getCreateDocumentOwnerAttributes(DOCUMENT_TYPES.OLD_DOCUMENT)
          )

        case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.FIXED:
          // если появится новый фиксированный документ по типу TaxCertificate2024,
          // то в documentItem.extraAttributes.new будет true
          return this.createFixedDocument(documentItem as IReusableDocumentTreeFixedDocumentItem)

        case REUSABLE_DOCUMENTS_TREE_DOCUMENT_TYPES.PROTOCOL:
           // TODO ждём отрыва протоколов
      }
    },

    async createDocument (
      documentItem: IReusableDocumentTreeDocumentItem,
      documentsOwnersAttributes: IDocumentsOwnerAttribute[]
    ) {
      const data = await this.checkupDocumentsList
        .documentsPresenter.createDocument({
          documentTypeId: documentItem.id,

          clientId: this.clientId,
          companyId: this.companyId,

          documentsOwnersAttributes,
        })

      this.$emit('createOldDocument', {
        ...data,
        documentType: DOCUMENT_TYPES.OLD_DOCUMENT,
      })

      const payload: IPubSubCreatedDocument = {
        documentId: data.id,
        documentType: DOCUMENT_TYPES.OLD_DOCUMENT,
        documentsOwners: documentsOwnersAttributes,
      }

      this.$pubSub.emit(PUB_SUB_EVENTS.CREATED_DOCUMENT, payload, true)
    },

    async createFixedDocument (documentItem: IReusableDocumentTreeFixedDocumentItem) {
      const params = new URLSearchParams()

      params.set('fixed_doc_type', 'true')
      params.set('client_id', String(this.clientId))
      params.set(DOCUMENT_OWNER_TYPES_IDS_PROPS.PERSONAL_CHECKUP, String(this.checkupDocumentsList.filtersData.ownerId))
      params.set('path', documentItem.id)
      params.set('title', documentItem.title)

      window.Utils.openInNewTab(`${this.$routes.new_document_path()}?${params.toString()}`)
    },

    getCreateDocumentOwnerAttributes (documentType: DOCUMENT_TYPES): IDocumentsOwnerAttribute[] {
      const ownerId = this.clientId
        ? this.clientId
        : this.companyId

      const ownerIdCheckup = this.checkupDocumentsList.filtersData.ownerId

      const ownerType = this.clientId
        ? DOCUMENT_OWNER_TYPES.CLIENT
        : DOCUMENT_OWNER_TYPES.COMPANY

      const ownerTypeCheckup = ownerType === DOCUMENT_OWNER_TYPES.CLIENT
        ? DOCUMENT_OWNER_TYPES.PERSONAL_CHECKUP
        : DOCUMENT_OWNER_TYPES.COMPANY_CHECKUP

      return getDocumentsOwnersAttributes(documentType, [
        {
          ownerType,
          ownerId,
        },
        {
          ownerType: ownerTypeCheckup,
          ownerId: ownerIdCheckup,
        },
      ])
    },

    async onDeleteDocument ({ documentType, documentId }) {
      const result = await CHECKUP_DOCUMENT_API_MAP[documentType].destroy(documentId)

      if (result?.errors) { return this.errorHandler(result) }

      this.$pubSub.emit(PUB_SUB_EVENTS.REMOVE_DOCUMENTS, [{
        documentId,
        documentType,
      }], true)

      this.$emit('delete')
    },

    errorHandler (errors: Record<string, string | string[]>) {
      if (errors?.errors === 'exists_error') {
        window.Notificator.error(window.t('semds.errors.semdsExists'))
      }
    },
  },
})
</script>
