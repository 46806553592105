<template>
  <div>
    <h1>Миксины для валидации</h1>
    <div class="flex flex-wrap gap-indent-2">
      <div>
        <h2>Миксин для дочернего компонента</h2>
        <img
          loading="lazy"
          src="@/vue_present/_FrontBook/pages/FrontBookPageValidationMixins/ChildValidation.svg"
        >
        <h3>onCreate</h3>
        <p>При создании дочернего компонента, происходит <b>emit</b> события registerValidator, в нем отправляется объект следующего вида:</p>
        <pre class="width-fit-content">
const validator = {
  validatorName: this.validatorName,
  validator: this.registerValidator(),
}</pre>
        <p><b>validatorName</b> приходит от родителя, он должен быть уникальным. Если его нет, то будет выведена ошибка в консоль</p>
        <p>
          <b>validator</b> - это функция, которая будет проверять значение <b>value</b> с некоторым условием,
          которое задаст пользователь в <b>required</b>.
        </p>
        <p>Метод <b>this.registerValidator</b> возвращает функцию, которая очищает (this.__resetValidation) и валидирует (this.__validate) пропсу <b>value</b></p>
        <p>
          Если в <b>required</b> будет передано <b>Булево</b> значение, то будет произведена проверка на пустоту,
          пустые: {}, [], falsy.
        </p>
        <p>Если были найдены ошибки валидации, то они будут добавлены в список <b>validationErrors</b> ($data)</p>

        <h2>Если изменился <b>required</b></h2>
        <p>
          Для данной пропсы используется watcher, который отправляет событие <b>registerValidator</b> с объектом валидатора родителю
        </p>
      </div>

      <div class="flex-grow-1 flex-shrink-1">
        <h2>Миксин для родительского компонента</h2>
        <h3>Регистрация валидаторов</h3>
        <img
          loading="lazy"
          src="@/vue_present/_FrontBook/pages/FrontBookPageValidationMixins/ParentValidation1.svg"
        >
        <p>Каждый дочерний компонент при создании отправляет объект валидации, который сохраняется в <b>validators</b></p>
        <p><b>validators</b> - это мапа, у которой ключ - <b>validatorName</b>, значение - <b>validator</b></p>

        <h3>Валидация или this.hasErrors()</h3>
        <img
          loading="lazy"
          src="@/vue_present/_FrontBook/pages/FrontBookPageValidationMixins/ParentValidation2.svg"
        >
        <p>При вызове данной функции, произойдет вызов каждого зарегистрированного валидатора.</p>
        <p>Если хотя бы один из валидаторов вернёт ненулевое значение, то метод вернет <b>true</b> (т.е. найдены ошибки валидации)</p>

        <h3>Сброс валидации или серверная валидация</h3>
        <img
          loading="lazy"
          src="@/vue_present/_FrontBook/pages/FrontBookPageValidationMixins/ParentValidation3.svg"
        >
        <p>Отличие между сбросом валидации и серверной валидации только в передаваемых параметрах</p>
        <p>Для сброса валидации достаточно просто вызвать <b>resetValidations</b> без параметров</p>
        <p>
          Для серверной валидации требуется передать объект (например, err.responseJSON) в <b>resetValidations</b>.
          <br>Данное действие запишет тексты ошибок в validation-wrapper соответствующих дочерних компонентов
          <br><i class="fad fa-fw fa-exclamation-triangle warning" /> ключи объекта должны совпадать с <b>validatorName</b>(s)
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrontBookPageValidationMixins',
  data () {
    return {}
  },
}
</script>
