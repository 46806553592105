<template>
  <el-dropdown
    trigger="click"
    size="mini"
    :hide-on-click="false"
    @command="$emit('onSelect', $event)"
    @visible-change="resetList"
  >
    <button
      v-loading="loading"
      type="button"
      class="btn btn-sm btn-success btn-with-icon"
      @click="onButtonClick"
    >
      <i class="fad fa-plus btn-with-icon_icon" />
      <span>{{ t('egisz.steps.modal.services.add_from_history') }}</span>
    </button>

    <el-dropdown-menu
      slot="dropdown"
      class="m-dropdown m-dropdown_fixed-sizes"
    >
      <el-dropdown-item
        v-for="entry in entryHistoryList"
        :key="`entry:${entry.id}`"
        :command="entry"
      >
        <div class="bold">
          {{ entry.title }}
        </div>
        <div
          v-if="!entry.disabled"
          class="prompt-notice"
        >
          {{ t('egisz.components.entries_history.subtitle', {
            id: entry.id,
            createdAt: entry.created_at,
            fullName: getDoctor(entry.user_id),
            price: currencyFormat(entry.final_sum),
            status: t('egisz.common_statuses')[entry.state]
          })
          }}
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
/**
 * История энтрей клиента
 *
 * вход: clientId - требуемый клиент
 * выход: onSelect(existingEntry)
 */

import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { entryEndpoint } from '@/api_entities/entries/entry_endpoint'
import { mapGetters } from 'vuex'
import CurrencyFormatter from '@/lib/currency_formatter/currency_formatter'
import {
  filterByIgnoredIds,
  getLoadingItemInList,
} from '@/vue_components/egisz/egisz_module/helpers/entries_history_helpers'

export default {
  name: 'EntriesHistory',
  mixins: [SpinnerHolder],

  props: {
    clientId: {
      type: Number,
      required: true,
    },

    ignoredIds: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      entryHistoryList: getLoadingItemInList(),
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctor: 'getDoctor',
    }),

    ...mapGetters([
      'GET_LOCALIZATION_CURRENCY_PARAMS',
    ]),
  },

  watch: {
    ignoredIds (to) {
      this.entryHistoryList =
          filterByIgnoredIds(this.entryHistoryList, to)
    },
  },

  methods: {
    currencyFormat (value) {
      const { code, locale } = this.GET_LOCALIZATION_CURRENCY_PARAMS

      return new CurrencyFormatter(code, locale).format(value)
    },

    onButtonClick () {
      this.loadEntryHistory()
    },

    loadEntryHistory () {
      const params = {
        client_id: this.clientId,
        forbidden_entry_ids: this.ignoredIds,
      }

      const promise = entryEndpoint.getEntriesWithoutSteps(params)
        .then((data) => {
          this.entryHistoryList = filterByIgnoredIds(data, this.ignoredIds)
        })
        .catch((err) => {
          Utils.reportError(
            'StepEntries:loadEntryHistory()',
            t('utils_report_error.list_message', { entity: t('utils_report_error.entities.entries_history') })
          )(err)
        })

      this.withSpinner(promise)
    },

    resetList (visible) {
      if (visible) { return }
      this.entryHistoryList = getLoadingItemInList()
    },
  },
}
</script>
