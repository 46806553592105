export const paymentSpeciesList = {
  BY_CASH: 'byCash',
  BY_CARD: 'byCard',
  BY_CASHLESS: 'byCashless',
  BY_BALANCE: 'byBalance',
  WITH_OVERPAY: 'withOverpay',
  WITH_UNDERPAY: 'withUnderpay',
}

export const getPaymentSpeciesList = () => {
  return Object.values(paymentSpeciesList)
    .map((key) => ({
      id: key, title: t(key, { scope: 'finance.paymentsPaymentSpecies' }),
    }))
}

export const paymentKindsList = {
  ORDER_INITIAL: 'order_initial',
  ORDER_SUBSEQUENT: 'order_subsequent',
  ORDER_REFUND: 'order_refund',
  CASH_IN: 'cash_in',
  CASH_IN_REFUND: 'cash_in_refund',
  CASH_OUT: 'cash_out',
  ORDER_FULL: 'order_full',
  ORDER_IN_CREDIT: 'order_in_credit',
}

export const getPaymentKindsList = () => {
  return Object.values(paymentKindsList)
    .map((key) => ({
      id: key, title: t(key, { scope: 'finance.paymentsKinds' }),
    }))
}

export const NEGATIVE_PAYMENT_KINDS = [
  'order_refund',
  'cash_in_refund',
  'cash_out',
]

export const PAYMENT_KINDS_TITLES = {
  ORDER_INITIAL: t('_payment_kind.order_initial'),
  ORDER_SUBSEQUENT: t('_payment_kind.order_subsequent'),
  ORDER_REFUND: t('_payment_kind.order_refund'),
  CASH_IN: t('_payment_kind.cash_in'),
  CASH_IN_REFUND: t('_payment_kind.cash_in_refund'),
  CASH_OUT: t('_payment_kind.cash_out'),
  ORDER_FULL: t('_payment_kind.order_full'),
  ORDER_IN_CREDIT: t('_payment_kind.order_in_credit'),
}

export const PAYMENT_KINDS_ICONS = Object.freeze({
  [paymentKindsList.ORDER_INITIAL]: { tooltip: [PAYMENT_KINDS_TITLES.ORDER_INITIAL], icon: 'orderInitial', class: 'warning' },
  [paymentKindsList.ORDER_SUBSEQUENT]: { tooltip: [PAYMENT_KINDS_TITLES.ORDER_SUBSEQUENT], icon: 'orderSubsequent', class: 'purple' },
  [paymentKindsList.ORDER_REFUND]: { tooltip: [PAYMENT_KINDS_TITLES.ORDER_REFUND], icon: 'orderRefund', class: 'danger' },
  [paymentKindsList.CASH_IN]: { tooltip: [PAYMENT_KINDS_TITLES.CASH_IN], icon: 'cashIn', class: 'primary' },
  [paymentKindsList.CASH_IN_REFUND]: { tooltip: [PAYMENT_KINDS_TITLES.CASH_IN_REFUND], icon: 'cashInRefund', class: 'danger' },
  [paymentKindsList.CASH_OUT]: { tooltip: [PAYMENT_KINDS_TITLES.CASH_OUT], icon: 'cashOut', class: 'primary' },
  [paymentKindsList.ORDER_FULL]: { tooltip: [PAYMENT_KINDS_TITLES.ORDER_FULL], icon: 'orderFull', class: 'success' },
  [paymentKindsList.ORDER_IN_CREDIT]: { tooltip: [PAYMENT_KINDS_TITLES.ORDER_IN_CREDIT], icon: 'orderInCredit', class: 'brown' },
})
