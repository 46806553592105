import {
  PersonalCheckupCalculatedEntryCell,
} from '@/vue_apps/CheckupsModule/classes/CheckupCalculatedEntry/PersonalCheckupCalculatedEntryCell'
import {
  PersonalCheckupCheckupEntriesModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupCheckupEntries/PersonalCheckupCheckupEntriesModel'

export class PersonalCheckupCheckupEntriesLogic extends PersonalCheckupCheckupEntriesModel {
  constructor (props = {}) {
    super(props)
    this.patchCheckupEntries()
  }

  setCheckupEntries (checkupEntries) {
    this.checkupEntries = checkupEntries
  }

  patchCheckupEntries () {
    this.setCheckupEntries(this.checkupEntries
      .map((ce) => new PersonalCheckupCalculatedEntryCell(ce)))
  }

  updateEntriesInCheckupEntries (entries) {
    const checkupEntries = this.checkupEntries.map((checkupEntry) => {
      const entry = entries
        .find((entry) => entry.id === checkupEntry.entry?.id)

      if (entry) {
        checkupEntry.setEntry({
          checkupEntryId: checkupEntry.id,
          ...entry,
        })
      }

      return checkupEntry
    })

    this.setCheckupEntries(checkupEntries)
  }

  removeEntries (entryIds) {
    const checkupEntries = this.checkupEntries.map((checkupEntry) => {
      const isIncludes = entryIds.includes(checkupEntry.entry?.id)

      if (isIncludes) {
        checkupEntry.removeEntry()
      }

      return checkupEntry
    })

    this.setCheckupEntries(checkupEntries)
  }

  _appendEntries (entries) {
    const checkupEntries = this.checkupEntries.map((checkupEntry) => {
      const entry = entries
        .find((entry) => entry.checkupsEntryId === checkupEntry.id)

      if (entry) {
        checkupEntry.setEntry(entry)
      }

      return checkupEntry
    })

    this.setCheckupEntries(checkupEntries)
  }

  _setDestroyToCheckupEntries () {
    const destroyed = this.checkupEntries.map((checkupEntry) => ({
      ...checkupEntry,
      _destroy: true,
    }))

    this.setCheckupEntries(destroyed)
  }
}
