<template>
  <m-panel
    v-loading="waitForPerformEntries"
    :title="t('checkups.entries.title')"
    class="personal-checkup-entries"
    :class="{ 'personal-checkup-entries_edit': isEditMode }"
  >
    <m-si-generator
      :si-generator-schema="msiSchema"
      :items="items"
      @onSetSelectedItems="selectedItems = $event"
      @onItemClick="onItemClick"
      @onCellClick="onCellClick"
      @onItemDelete="onDelete([$event])"
    >
      <template #filters>
        <personal-checkup-entries-filters
          v-show="isShowMode"
          v-bind.sync="filters"
          :personal-checkup="personalCheckup"
          @onResetClick="onResetClick"
          @openModal="openModal"
        />
      </template>
    </m-si-generator>

    <div
      v-show="isShowMode"
      class="flex gap-indent-small mt-indent-small"
    >
      <!-- Изменить исполнителя -->
      <m-button
        type="warning"
        icon="change"
        :text="t('checkups.entries.changeDoctor')"
        :disabled="!selectedItems.length"
        @click="openDoctorPopup"
      />

      <!-- Изменить статус на Готов -->
      <m-button
        v-if="showChangeStateButton"
        type="warning"
        icon="ready"
        :text="t('checkups.entries.changeStatusToReady')"
        :disabled="!selectedItems.length"
        @click="onChangeState"
      />

      <m-button-delete
        use-button
        :disabled="!selectedItems.length"
        :popover-message="t('checkups.entries.deleteManyEntries')"
        @yes="onDelete(selectedItems)"
      />
    </div>

    <personal-checkup-entries-modal
      v-if="modalVisible"
      visible
      :personal-checkup="personalCheckup"
      @close="closeModal"
    />

    <reusable-doctor-popup
      ref="reusableDoctorPopup"
      :current-clinic="personalCheckup.clinic.id"
      @onDoctorChange="onDoctorChange"
    />
  </m-panel>
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { ModalMixin } from '@/vue_present/mixins/ModalMixins/ModalMixin'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import ReusableDoctorPopup from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorPopup.vue'
import PersonalCheckupEntriesModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupEntries/PersonalCheckupEntriesModal.vue'
import PersonalCheckupEntriesFilters
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupEntries/PersonalCheckupEntriesFilters.vue'
import { extractId } from '@/_medods_standart_library/msl'
import Services from '@/services/service_repository_instance'
import { ACTIONS, ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { snakeToCamel } from '@/_api/_requests/helpers'
import {
  getPersonalCheckupEntriesDefaultFilters,
} from '@/vue_apps/CheckupsModule/store/personalCheckupEntries/personalCheckupEntriesFilters'
import { GLOBAL_ENTRY_STATE } from '@/_global_scripts/GLOBAL_CONSTS'
import {
  getPersonalCheckupEntriesMsiSchemaEdit,
  getPersonalCheckupEntriesMsiSchemaShow,
} from '@/vue_apps/CheckupsModule/store/personalCheckupEntries/personalCheckupEntriesMsiSchemas'

export default {
  name: 'PersonalCheckupEntries',

  components: {
    PersonalCheckupEntriesFilters,
    PersonalCheckupEntriesModal,
    ReusableDoctorPopup,
    MButtonDelete,
    MButton,
    MPanel,
    MSiGenerator,
  },

  mixins: [
    ModalMixin,
    CheckupsPropsMixin,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
  },

  data () {
    return {
      selectedItems: [],
      filters: getPersonalCheckupEntriesDefaultFilters(),
      completedEntries: [],
      waitForPerformEntries: 0,
      changeUserForEntryId: null,
    }
  },

  computed: {
    entries () {
      return this.personalCheckup
        .checkupEntries
        .checkupEntries
        .map(({ entry }) => entry)
        .filter(Boolean)
    },

    items () {
      return this.entries.filter((entry) => {
        return !((this.filters.kind && entry.kind !== this.filters.kind.id) ||
            (this.filters.state && entry.state !== this.filters.state.id) ||
            (this.filters.user && entry.userId !== this.filters.user.id) ||
            (this.filters.mine && entry.userId !== gon.application.current_user.id))
      })
    },

    msiSchema () {
      return this.isShowMode
        ? getPersonalCheckupEntriesMsiSchemaShow()
        : getPersonalCheckupEntriesMsiSchemaEdit()
    },

    showChangeStateButton () {
      return gon.application.entry_status || gon.application.entry_analysis_status
    },
  },

  mounted () {
    Services.wsSubscriptions.egisz.connect(({ action, data, meta }) => {
      if (meta.object !== ENTITIES.ENTRY) { return }

      const isEntryExists = this.entries
        .find((entry) => entry.id === extractId(data))

      if (!isEntryExists) { return }

      if (this.waitForPerformEntries === 0) {
        this.waitForPerformEntries++
      }

      if (action === ACTIONS.DELETED) {
        this.completedEntries.push(extractId(data))

        if (this.completedEntries.length === this.waitForPerformEntries) {
          this.personalCheckup
            .checkupEntries
            .removeEntries(this.completedEntries)
        }
      }

      if (action === ACTIONS.UPDATED) {
        this.completedEntries.push((snakeToCamel(data)))

        if (this.completedEntries.length === this.waitForPerformEntries) {
          this.personalCheckup
            .checkupEntries
            .updateEntriesInCheckupEntries(this.completedEntries)
        }
      }

      if (this.waitForPerformEntries &&
          this.completedEntries.length === this.waitForPerformEntries) {
        this.completedEntries = []
        this.waitForPerformEntries = 0
      }
    })
  },

  methods: {
    onItemClick ({ id }) {
      Utils.openInNewTab(
        Routes.medical_record_entry_path(this.personalCheckup.client.medicalRecordId, id, { page: 1 })
      )
    },

    onCellClick ({ cell, item, event }) {
      switch (cell) {
        case 'edit': return Utils.openInNewTab(Routes.edit_entry_path(item.id))
        case 'changeUser': return this.openDoctorPopup(event, {
          id: item.userId,
          shortName: item.userShortName.value,
        }, item.id)
      }
    },

    onResetClick () {
      this.filters = getPersonalCheckupEntriesDefaultFilters()
    },

    openDoctorPopup (event, user = {}, entryId = null) {
      this.changeUserForEntryId = entryId
      this.$refs.reusableDoctorPopup.onOpen(event, user)
    },

    onDoctorChange ({ id }) {
      const ids = this.changeUserForEntryId
        ? [this.changeUserForEntryId]
        : this.selectedItems.map(extractId)

      this.waitForPerformEntries = ids.length

      this.personalCheckup.checkupEntries.updateEntries({
        ids,
        userId: id,
      })
    },

    onChangeState () {
      this.waitForPerformEntries = this.selectedItems.length

      this.personalCheckup.checkupEntries.updateEntries({
        ids: this.selectedItems.map(extractId),
        state: GLOBAL_ENTRY_STATE.READY,
      })
    },

    onDelete (items) {
      this.waitForPerformEntries = items.length

      this.personalCheckup.checkupEntries.destroyEntries(items.map(extractId))
    },
  },
}
</script>
