import { isEmpty } from 'lodash'

/**
 * @param addButtonConfig
 * @return {TAddButtonConfig}
 */
export const getAddButtonConfig = (addButtonConfig) => {
  if (isEmpty(addButtonConfig)) {
    return {
      canManage: { tooltip: '', disabled: false },
    }
  }

  return addButtonConfig
}
