<template>
  <div
    v-show="show"
    ref="ctxMenu"
    v-focus="true"
    class="ctx-menu"
    tabindex="0"
    :style="[coords]"
    @blur="$pubSub.emit('CONTEXT_MENU:CLOSE')"
  >
    <slot/>
  </div>
</template>

<script>

const OFFSET = 2

export default {
  name: 'CtxMenu',
  props: {
    /**
     * Позволяет вынести меню в конец body.
     *
     * Нужно для решения проблем с позиционированием, так как порталов не завезли
     * и просто так в любом месте DOM компонент отрендерить нельзя.
     * По умолчанию меню будет ренедриться внутри компонента-родителя.
     *
     * Если меню находится внутри модалки (любого элемента с position: relative),
     * то нужно использовать этот проп, чтобы координаты меню считались верно.
     */
    inBody: {
      type: Boolean,
    },
  },
  data: () => ({
    show: false,
    coords: {
      top: '0px',
      left: '0px',
    },
    timer: null,
  }),
  beforeDestroy () {
    this.$pubSub.reset('CONTEXT_MENU:CLOSE')
  },
  mounted () {
    if (this.inBody) {
      document.body.append(this.$refs.ctxMenu)
    }

    this.$pubSub.subscribe('CONTEXT_MENU:CLOSE', () => {
      this.show = false
    })
  },
  methods: {
    closeSubmenuAllChildrenExcept (exceptChild) {
      this.$children.forEach((child) => {
        if (exceptChild !== child) child.closeSubmenu()
      })
    },
    open (mouseEvent) {
      this.show = true
      const screenWidth = document.documentElement.clientWidth
      const screenHeight = document.documentElement.clientHeight
      const freeSpaceOnRight = screenWidth - mouseEvent.clientX
      const freeSpaceOnBottom = screenHeight - mouseEvent.clientY

      this.$nextTick(() => {
        const ctxCoords = this.$refs.ctxMenu.getBoundingClientRect()

        this.coords.top = mouseEvent.clientY + 'px'
        if (freeSpaceOnRight >= ctxCoords.width) {
          this.coords.left = mouseEvent.clientX + OFFSET + 'px'
        } else {
          this.coords.left = mouseEvent.clientX - ctxCoords.width + 'px'
        }

        if (freeSpaceOnBottom >= ctxCoords.height) {
          this.coords.top = mouseEvent.clientY + OFFSET + 'px'
        } else {
          this.coords.top = mouseEvent.clientY - ctxCoords.height + 'px'
        }
      })
    },
    setHighlighting () {},
    highlight () {},
  },
}
</script>
