<template>
  <div class="variables-modal">
    <m-modal
      :visible="modalVisibility"
      :dialog-title="t('choose_variable')"
      modal-title-icon="list"
      width="500px"
      @update:visible="$updateSync('modalVisibility', $event)"
      @close="$updateSync('modalVisibility', false)"
    >
      <template #body>
        <el-tree
          :data="variablesList"
          @node-click="handleNodeClick"
        />
      </template>
      <template #footer-left />
    </m-modal>
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'WhatsappVariablesModal',
  components: { MModal },

  props: {
    modalVisibility: Boolean,
    variablesList: PropsTypes.Array(),
  },

  methods: {
    handleNodeClick (data) {
      this.$emit('insertVariable', data)
    },
  },
}

</script>
