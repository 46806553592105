<template>
  <div class="lazy-tree">
    <el-tree
      ref="tree"
      lazy
      :props="treeProps"
      :load="loadNode"
      :render-content="renderFunction"
      :indent="indentLeft"
      :node-key="nodeKey"
      :default-expanded-keys="[PSEUDO_ROOT_NODE_ID]"
      :highlight-current="highlightCurrent"
      :current-node-key="currentNodeKey"
      :draggable="draggable"
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      @node-click="$emit('onNodeClick', $event)"
      @node-drop="nodeDrop"
    >
      <template #default="props">
        <slot v-bind="props" />
      </template>
    </el-tree>
  </div>
</template>

<script>
import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { PSEUDO_ROOT_NODE_ID } from '@/vue_present/Reuse/LazyTree/const'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'

const ELEMENT_UI_TREE_DEFAULT_INDENT = 16

export default {
  name: 'ReusableLazyTree',
  props: {
    tree: LazyTree,
    renderFunction: PropsTypes.Function(),
    indentLeft: PropsTypes.Number(ELEMENT_UI_TREE_DEFAULT_INDENT),
    emptyText: PropsTypes.String(t('noData')),
    nodeKey: { type: String, default: 'id' },
    categoryType: { type: [Number, String], default: undefined },

    /**
     * Отображает корневой узел дерева. По умолчанию скрыт
     * @type {{ title?: string } | boolean}
     */
    useRootNode: { type: [Boolean, Object], default: false },

    highlightCurrent: Boolean,

    currentNodeKey: { type: [String, Number], default: undefined },

    draggable: Boolean,
    allowDrag: { type: Function, default: undefined },
    allowDrop: { type: Function, default: undefined },
    nodeDrop: { type: Function, default () {} },
  },

  emits: [
    'onNodeClick',
    'setTreeRef',
    'onLoadNode',
  ],

  data () {
    return {
      treeProps: {
        label: 'title',
        isLeaf: 'isLeaf',
        children: 'children',
      },
      PSEUDO_ROOT_NODE_ID,
    }
  },

  mounted () {
    this.$emit('setTreeRef', this.$refs.tree)
  },

  methods: {
    /**
     * @param {ElTreeNode} node
     * @param resolve
     * @return {Promise<*>}
     */
    async loadNode (node, resolve) {
      if (this.useRootNode && !node?.level) {
        const pseudoRoot = new LazyTreeNode(
          this.tree,
          {
            id: PSEUDO_ROOT_NODE_ID,
            title: this.useRootNode?.title || '...',
            children: [],
          }
        )

        this.tree.setPseudoRoot(pseudoRoot)

        return resolve([pseudoRoot])
      }

      const nodeId = (node.data?.id === PSEUDO_ROOT_NODE_ID ? 0 : node.data?.id) || 0
      resolve(await this.tree.fetchNode(nodeId, this.categoryType))

      this.$emit('onLoadNode')
    },
  },
}
</script>
