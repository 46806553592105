import FIRST_PAGE_BARCODE
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/assets/barcode-26901015.svg'
import SECOND_PAGE_BARCODE
  from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/assets/barcode-26901022.svg'

export const FIRST_PAGE = 1

export const SECOND_PAGE = 2

export const PAGE_HEADER_BARCODE = {
  [FIRST_PAGE]: FIRST_PAGE_BARCODE,
  [SECOND_PAGE]: SECOND_PAGE_BARCODE,
}
