import { MListService } from '@/_api/_requests/MListService'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'

export class ClientsSearchList extends MListService {
  constructor () {
    super({}, null)
  }

  async fetchAll () {
    const { errors, data, totalItems, totalPages } = await new MClientPresenter().checkupSearch({
      searchQuery: this.searchQuery,
    })

    if (errors) { return }

    this.data = data
    this.totalItems = totalItems
    this.totalPages = totalPages
  }
}
