import { CompanyFinalActModel } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActModel'
import {
  getDefaultCompanyFinalActTableData,
} from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/CompanyFinalAct/store/getDefaultCompanyFinalActTableData'

export class CompanyFinalActLogic extends CompanyFinalActModel {
  setValueInTable (
    tableProp: string,
    { index, key, value }: {index: number; key: string; value: string}
  ) {
    this[tableProp][index][key] = value
  }

  removeRowFromTable (tableProp: string, index: number) {
    this[tableProp].splice(index, 1)
  }

  addRowToTable (tableProp: string) {
    this[tableProp]
      .push(getDefaultCompanyFinalActTableData(tableProp))
  }
}
