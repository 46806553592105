import FeedbackModule from '@/vue_apps/FeedbackModule/FeedbackModule.vue'
import EmployeesRating from '@/vue_components/client_feedbacks/employees_rating/employees_rating.vue'
import AdmissionAssessment from '@/vue_components/client_feedbacks/admission_assessment/AdmissionAssessment.vue'
import Nps from '@/vue_components/client_feedbacks/nps/nps.vue'
import { FEEDBACKS_ROUTE_NAMES } from '@/vue_components/client_feedbacks/router/routeNames'

const getDefaultRoute = (path = '*') => `/client-feedbacks${path}`

export const feedbacksRoute = {
  path: getDefaultRoute('/feedbacks'),
  name: FEEDBACKS_ROUTE_NAMES.FEEDBACKS,
  component: FeedbackModule,
  props: {
    cacheKey: 'clients-feedbacks-marketing-feedbacks',
  },
}

export const employeesRatingRoute = {
  path: getDefaultRoute('/employees-rating'),
  name: FEEDBACKS_ROUTE_NAMES.EMPLOYEES_RATING,
  component: EmployeesRating,
}

export const admissionAssessmentRoute = {
  path: getDefaultRoute('/admission-assessment'),
  name: FEEDBACKS_ROUTE_NAMES.ADMISSION_ASSESSMENT,
  component: AdmissionAssessment,
}

export const npsRoute = {
  path: getDefaultRoute('/nps'),
  name: FEEDBACKS_ROUTE_NAMES.NPS,
  component: Nps,
}

export const clientFeedbacksRoutes = [
  feedbacksRoute,
  employeesRatingRoute,
  admissionAssessmentRoute,
  npsRoute,

  {
    path: getDefaultRoute('*'),
    name: FEEDBACKS_ROUTE_NAMES.INDEX,
    redirect: { name: FEEDBACKS_ROUTE_NAMES.FEEDBACKS },
  },
]
