export enum DOCUMENT_OWNER_TYPES {
  CLIENT = 'client',
  COMPANY = 'company',
  PERSONAL_CHECKUP = 'personal_checkup',
  COMPANY_CHECKUP = 'company_checkup',
}

export enum DOCUMENT_OWNER_TYPES_IDS_PROPS {
  CLIENT = 'client_id',
  COMPANY = 'company_id',
  PERSONAL_CHECKUP = 'personal_checkup_id',
  COMPANY_CHECKUP = 'company_checkup_id',
}
