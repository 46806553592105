import FixedDocumentApp from '@/vue_apps/Documents/FixedDocuments/FixedDocumentApp.vue'
import {
  taxCertificate2024Routes,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/router/taxCertificate2024Routes'
import { FIXED_DOCUMENT_ROUTE_NAMES } from '@/vue_apps/Documents/FixedDocuments/router/routeNames'
import FixedDocumentNotFoundPage from '@/vue_apps/Documents/FixedDocuments/pages/FixedDocumentNotFoundPage.vue'

export const fixedDocumentModuleRoutes = [
  {
    path: '/fixed-document/not-found',
    name: FIXED_DOCUMENT_ROUTE_NAMES.E404,
    component: FixedDocumentNotFoundPage,
  },

  {
    path: '/fixed-document',
    name: FIXED_DOCUMENT_ROUTE_NAMES.SHOW,

    component: FixedDocumentApp,

    children: [
      taxCertificate2024Routes,
    ],
  },
]
