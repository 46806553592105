export const RECEIPT_STATUSES = {
  PRINTED: 'printed',
  NOT_PRINTED: 'not_printed',
  PRINTED_NOT_ONCE: 'printed_not_once',
}

export const getReceiptStatusLabels = () => {
  return Object.values(RECEIPT_STATUSES).map((key) => ({
    id: key, title: t(key, { scope: 'finance.receiptStatuses' }),
  }))
}

export const RECEIPT_STATUSES_ICONS = {
  [RECEIPT_STATUSES.PRINTED]: { icon: 'print', color: 'success' },
  [RECEIPT_STATUSES.NOT_PRINTED]: { icon: 'notPrinted', color: 'warning' },
  [RECEIPT_STATUSES.PRINTED_NOT_ONCE]: { icon: 'copy', color: 'danger' },
}
