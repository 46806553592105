<template>
  <si-control class="emd__controls">
    <date-range-picker
      :date-range="filters[date]"
      :format="getLocalizationDatepickerFormat"
      @change="vxSetFilterValue({ filter: date, value: $event })"
    />

    <simple-select
      :value="filters[authorId]"
      :items="getDoctorsInCurrentClinic"
      :fixed-height="false"
      :placeholder="t('doctor')"
      filterable
      clearable
      label-attribute="full_name"
      @change="vxSetFilterValue({ filter: authorId, value: $event })"
    />

    <catalog-tree-modal
      id="nsi_specialty_id"
      :tree-data="getSpecialties"
      :value="filters[nsiSpecialtyId]"
      clearable
      @update:value="vxSetFilterValue({ filter: nsiSpecialityId, value: $event })"
    />

    <el-checkbox
      class="mb-0 el-checkbox_big"
      :value="getIsForCurrentUser"
      :label="t('mine')"
      border
      size="small"
      @change="setOnlyCurrentUser"
    />

    <template #reset>
      <span class="empty-slot" />
    </template>
    <template #refresh>
      <span class="empty-slot" />
    </template>
  </si-control>
</template>

<script>
import SiControl from '@/vue_components/sort_items/si_control'
import DateRangePicker from '@/vue_components/common/select/date_range_picker'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CatalogTreeModal from '@/vue_components/egisz/egisz_user_tab/components/CatalogTreeModal'
import { MED_DOCUMENTS_FILTERS_FIELDS } from '@/vue_components/egisz/egisz_module/const/egisz_filters'

export default {
  name: 'EgiszEmdTabControls',
  components: { CatalogTreeModal, SimpleSelect, DateRangePicker, SiControl },
  data () {
    return {
      date: MED_DOCUMENTS_FILTERS_FIELDS.DATE,
      nsiSpecialityId: MED_DOCUMENTS_FILTERS_FIELDS.NSI_SPECIALTY_ID,
      authorId: MED_DOCUMENTS_FILTERS_FIELDS.AUTHOR_ID,
    }
  },

  computed: {
    ...mapGetters({
      getLocalizationDatepickerFormat: 'GET_LOCALIZATION_DATEPICKER_FORMAT',
    }),

    ...mapGetters('egiszEmd/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapGetters('medCases', {
      getSpecialties: 'GET_SPECIALTIES',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorsInCurrentClinic: 'getDoctorsInCurrentClinic',
    }),

    ...mapGetters('egiszEmd', {
      getIsForCurrentUser: 'GET_IS_FOR_CURRENT_USER',
    }),
  },

  methods: {
    ...mapActions('egiszEmd', {
      setOnlyCurrentUser: 'SET_ONLY_CURRENT_USER',
    }),

    ...mapMutations('egiszEmd/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
    }),
  },
}
</script>
