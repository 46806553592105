import { FeedbackListItem } from '@/vue_apps/FeedbackModule/entities/list/FeedbackListItem'
import { orNull, orUndefined } from '@/_medods_standart_library/msl'
import { FeedbackListTotalItem } from '@/vue_apps/FeedbackModule/entities/list/FeedbackListTotalItem'
import { DEFAULT_SELECTOR_FEEDBACKS_STATUSES } from '@/vue_apps/FeedbackModule/const/const'

export const feedbackAdapter = {
  toClient (response) {
    return {
      data: (response.data || []).map((item) => new FeedbackListItem(item)),
      total: new FeedbackListTotalItem({
        total: response.totalItems,
        totalUrlClicks: response.totalUrlClicks,
      }),
      totalItems: response.totalItems,
      totalPages: response.totalPages,
    }
  },

  toServer (data) {
    return {
      ...data,
      clinicIds: [data.clinic?.id],
      userIds: [data.doctor?.id],
      specialtyIds: [data.specialty?.id],
      generalAssessmentOfAdmission: data.doctorRate,
      netPromoterScore: data.clinicRate,
      clientId: orUndefined(data.clientId),
      statuses: data.statuses?.id
        ? [data.statuses.id]
        : DEFAULT_SELECTOR_FEEDBACKS_STATUSES,
      withClicks: data.statuses?.id === 'awaiting_client_feedback'
        ? true
        : data.withClicks?.id
          ? data.withClicks.id === 'yes'
          : null,
    }
  },

  submitFeedback (data) {
    return {
      status: data.status,
      responsibleUserId: orNull(data.responsible?.id),
      responsibleUserComment: orNull(data.responsibleUserComment),
    }
  },
}
