import { ItemBase } from '@/_api/_classes/ItemBase'

export class CheckupEntryTypeModel extends ItemBase {
  _permit = [
    'id',
    'text',
    'clinicsCabinets',
    'title',
    'kind',
  ]

  id = null

  text = ''

  /** @type {{
   *   clinicId: number,
   *   cabinetId: number[],
   *   cabineTitle: string,
   *   entryType: { id: number, title: string },
   *   entryTypesClinicsCabinetsUsers: IEntryTypesClinicsCabinetsUsers[]
   * }[]}
   */
  clinicsCabinets = []

  title = ''

  kind = null

  constructor (props) {
    super()
    this._fillProps(props)
  }
}
