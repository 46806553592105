import CheckupModuleMainPage from '@/vue_apps/CheckupsModule/pages/CheckupModuleMainPage.vue'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'
import PersonalCheckupEditor from '@/vue_apps/CheckupsModule/pages/PersonalCheckupEditor.vue'
import CompanyCheckupEditor from '@/vue_apps/CheckupsModule/pages/CompanyCheckupEditor.vue'
import Checkup404 from '@/vue_apps/CheckupsModule/pages/Checkup404.vue'
import CheckupDocumentPage from '@/vue_apps/CheckupsModule/pages/CheckupDocumentPage.vue'

const getDefaultRoute = (path = '*') => `/checkup${path}`

export const periodicCheckupsRoute = {
  path: getDefaultRoute('/periodic-checkups'),
  name: CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS,
  component: CheckupModuleMainPage,
  meta: {
    label: t('checkups.kinds.periodicCheckups'),
    icon: 'fas fa-repeat',
    tooltip: t('checkups.kinds.periodicCheckups'),
  },
}

export const priorCheckupsRoute = {
  path: getDefaultRoute('/prior-checkups'),
  name: CHECKUPS_ROUTE_NAMES.PRIOR_CHECKUPS,
  component: CheckupModuleMainPage,
  meta: {
    label: t('checkups.kinds.priorCheckups'),
    icon: 'fas fa-repeat-1',
    tooltip: t('checkups.kinds.priorCheckups'),
  },
}

export const checkupsModuleRoutes = [
  periodicCheckupsRoute,
  priorCheckupsRoute,

  {
    path: getDefaultRoute('/company-checkup/:id/edit'),
    name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_EDIT,
    component: () => import('@/vue_apps/CheckupsModule/pages/CompanyCheckupEditor.vue'),
    props: (route) => ({ id: parseInt(route.params.id) }),
  },

  {
    path: getDefaultRoute('/company-checkup/new'),
    name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_NEW,
    component: () => import('@/vue_apps/CheckupsModule/pages/CompanyCheckupEditor.vue'),
  },

  {
    path: getDefaultRoute('/company-checkup/:id'),
    name: CHECKUPS_ROUTE_NAMES.COMPANY_CHECKUP_SHOW,
    component: CompanyCheckupEditor,
    props: (route) => ({ id: parseInt(route.params.id) }),
  },

  // персональный периодический
  {
    path: getDefaultRoute('/company-checkup/:id/:personalCheckupId/edit'),
    name: CHECKUPS_ROUTE_NAMES.PERSONAL_PERIODIC_CHECKUP_EDIT,
    props: (route) => ({
      companyCheckupId: parseInt(route.params.id),
      id: parseInt(route.params.personalCheckupId),
    }),
    component: PersonalCheckupEditor,
  },

  {
    path: getDefaultRoute('/company-checkup/:id/new'),
    name: CHECKUPS_ROUTE_NAMES.PERSONAL_PERIODIC_CHECKUP_NEW,
    props: (route) => ({ companyCheckupId: parseInt(route.params.id) }),
    component: PersonalCheckupEditor,
  },

  {
    path: getDefaultRoute('/company-checkup/:id/:personalCheckupId'),
    name: CHECKUPS_ROUTE_NAMES.PERSONAL_PERIODIC_CHECKUP_SHOW,
    props: (route) => ({
      companyCheckupId: parseInt(route.params.id),
      id: parseInt(route.params.personalCheckupId),
    }),
    component: PersonalCheckupEditor,
  },

  // персональный предварительный
  {
    path: getDefaultRoute('/personal-checkup/:id/edit'),
    name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_EDIT,
    component: () => import('@/vue_apps/CheckupsModule/pages/PersonalCheckupEditor.vue'),
    props: (route) => ({ id: parseInt(route.params.id) }),
  },

  {
    path: getDefaultRoute('/personal-checkup/new'),
    name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_NEW,
    component: () => import('@/vue_apps/CheckupsModule/pages/PersonalCheckupEditor.vue'),
  },

  {
    path: getDefaultRoute('/personal-checkup/:id'),
    name: CHECKUPS_ROUTE_NAMES.PERSONAL_CHECKUP_SHOW,
    component: () => import('@/vue_apps/CheckupsModule/pages/PersonalCheckupEditor.vue'),
    props: (route) => ({ id: parseInt(route.params.id) }),
  },

  {
    path: getDefaultRoute('/documents/:documentType/:id(\\d+)/edit'),
    name: CHECKUPS_ROUTE_NAMES.CHECKUP_DOCUMENT_EDIT,
    component: CheckupDocumentPage,
    props: (route) => ({
      id: parseInt(route.params.id),
      documentType: route.params.documentType,
      isEditMode: true,
    }),
  },

  {
    path: getDefaultRoute('/documents/:documentType/:id(\\d+)'),
    name: CHECKUPS_ROUTE_NAMES.CHECKUP_DOCUMENT_SHOW,
    component: CheckupDocumentPage,
    props: (route) => ({
      id: parseInt(route.params.id),
      documentType: route.params.documentType,
      isShowMode: true,
    }),
  },

  {
    path: getDefaultRoute('404'),
    name: CHECKUPS_ROUTE_NAMES.CHECKUP_404,
    component: Checkup404,
  },

  {
    path: '/checkup^(-catalogs)*',
    name: CHECKUPS_ROUTE_NAMES.CHECKUP_404,
    component: Checkup404,
  },
]
