import Vue from 'vue/dist/vue.esm'
import { createStore } from '@/vue_components/store/store'
import router from '@/vue_components/router/router'

/**
 * @typedef {{
 *   el: String,
 *   render: Function,
 *   name: String,
 *   router?: Object,
 *   store?: Object
 * }} VueConfigCustom
 */

/**
 * @param {VueConfigCustom} vueConfig
 */
const createVueAppByConfig = (vueConfig) => {
  // eslint-disable-next-line no-new
  return new Vue({
    store: createStore(),
    router,
    ...vueConfig,
  })
}

/**
 * @param el
 * @return {boolean}
 */
const isElementExists = (el) => {
  if (!el) { return false }

  return Boolean(document.querySelector(el))
}

/**
 * @param {VueConfigCustom} vueConfig
 */
export const createVueApp = (vueConfig) => {
  if (!isElementExists(vueConfig.el)) {
    return Utils.initVueInBody(vueConfig)
  }

  return createVueAppByConfig(vueConfig)
}
