import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { DEFAULT_RESULT_TYPES } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupsConst'

export const MSelectProps = {
  /** @param {Number|String} - ключевой атрибут объекта value */
  valueKey: PropsTypes.String('id'),

  /** @param {String} - атрибут, который будет использован как текст элемента списка */
  optionLabel: PropsTypes.String('title'),

  /** @param {Object} - текущее значение в $parent */
  value: PropsTypes.Custom([Object, Array, String, Number, Boolean], null),

  /** @param {Object} - список в $parent */
  items: PropsTypes.Array(),

  /** @param {Boolean} - использование фильтрации */
  filterable: Boolean,

  /** @param {Boolean} - использование мультиселекта */
  multiple: Boolean,

  /** @param {Boolean} - использование мультиселекта */
  collapseTags: Boolean,

  /** @param {Number} - минимальное количество символов для фильтрации */
  minFilterValueLength: PropsTypes.Number(3),

  /** @param {Number} - задержка перед emit filterValue */
  debounce: PropsTypes.Number(500),

  /** @param {Boolean} - растягивание на 100% ширины */
  fullWidth: Boolean,

  /** @param {Boolean} - рисует только нижний бордер */
  useSearchStyle: Boolean,

  useCustomResult: PropsTypes.String(DEFAULT_RESULT_TYPES.OBJECT),

  /** @param {String} - класс дропдауна */
  poperClass: PropsTypes.String(),

  // дополнительный атрибут для отображения помимо label
  optionNotice: { type: String, default: null },

  /** @param {String} - отображаемый текст, когда нет items, которых можно выбрать */
  noDataCustomText: { type: String, default: null },
}
