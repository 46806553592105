<template>
  <el-upload
    ref="uploader"
    class="files-loader"
    :drag="drag"
    :multiple="multiple"
    :action="action"
    :http-request="onFileLoad"
    :disabled="disabled"
    :auto-upload="autoUpload"
    :limit="limit"
    :show-file-list="showFileList"
    :file-list="fileList"
  >
    <slot name="upload-icon">
      <i class="el-icon-upload" />
    </slot>
    <slot name="upload-text">
      <div class="el-upload__text">
        {{ t('files_loader.message') }}
        <em>{{ t('files_loader.message_em') }}</em>
      </div>
    </slot>
  </el-upload>
</template>

<script>
export default {
  name: 'FilesLoader',
  props: {
    action: {
      type: String,
      required: true,
    },

    ajaxLoader: {
      type: Function,
      required: true,
    },

    disabled: Boolean,
    autoUpload: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
    drag: Boolean,

    limit: {
      type: Number,
      default: 1,
    },

    showFileList: Boolean,

    fileList: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      uploader: null,
      uploaderData: null,
    }
  },

  mounted () {
    this.uploader = this.$refs.uploader
    this.uploaderData = this.uploader._data
    this.$watch('uploaderData', (to) => {
      this.$emit('setUploadFiles', to.uploadFiles)
    }, { deep: true })
  },

  methods: {
    onFileLoad (file) {
      return this.ajaxLoader(file)
    },

    submitUpload () {
      if (!(this.uploader._data.uploadFiles || []).length) {
        Notificator.warning(t('files_loader.not_selected'))

        return
      }

      this.uploader.submit()
    },

    clearFiles () {
      this.uploader.clearFiles()
    },
  },
}
</script>
