<template>
  <div class="guarantee-letters-editor">
    <m-modal
      :visible.sync="modalVisible"
      :dialog-title="t('finance.guarantee_letters')"
      :type="modalType"
      modal-title-icon="fileAlt"
      @close="onModalClose()"
    >
      <template #body>
        <div class="w-100 flex flex-column">
          <m-date-picker
            :value="guaranteeLetter.issueDate"
            :label="t('date_of_issue')"
            value-format="yyyy-MM-dd"
            required
            validator-name="issue_date"
            @registerValidator="onRegisterValidator"
            @change="guaranteeLetter.setValue('issueDate', $event)"
          />

          <m-input
            :value="guaranteeLetter.number"
            :label="t('common.number')"
            required
            validator-name="number"
            @registerValidator="onRegisterValidator"
            @input="guaranteeLetter.setValue('number', $event)"
          />

          <div
            v-if="isDms"
            class="flex gap-indent-small align-items-center mb-indent-validation"
          >
            <m-input
              type="number"
              :label="t('sum')"
              :value="guaranteeLetter.sum"
              :precision="2"
              :min="0"
              :required-tooltip="t('sum')"
              :m-fixed-height="false"
              controls
              @input="guaranteeLetter.setValue('sum', $event)"
            />

            <m-icon
              v-tooltip="t('isGuaranteeLetterZeroSum')"
              icon="info"
              color="gray"
            />
          </div>
        </div>
      </template>

      <template #footer-left>
        <m-button-submit
          :mode="submitType"
          :use-mode-by-route="false"
          @click="onSubmit"
        />

        <m-button-delete
          v-if="guaranteeLetter.id"
          use-button
          @yes="onDestroy"
        />
      </template>
    </m-modal>

    <slot
      name="reference"
      :open-modal="openModal"
    >
      <m-button
        v-tooltip="t('add')"
        template="add"
        icon="letter"
        plus-icon
        @click="openModal()"
      />
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { GuaranteeLetterApi } from '@/vue_apps/ClientsModule/components/MedicalPolices/entities/GuaranteeLetterApi'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'GuaranteeLettersEditor',
  components: {
    MIcon,
    MButton,
    MButtonSubmit,
    MButtonDelete,
    MInput,
    MDatePicker,
    MModal,
  },

  mixins: [ValidationParentMixin],

  props: {
    medicalPolicyId: { type: Number, required: true },
    isDms: { type: Boolean, default: false },
  },

  emits: [
    'updateList',
    'registerOpenModal',
    'onSubmit',
  ],

  data () {
    return {
      modalVisible: false,
      guaranteeLetter: new GuaranteeLetterApi(),
    }
  },

  computed: {
    modalType () {
      return this.guaranteeLetter?.id
        ? 'warning'
        : 'success'
    },

    submitType () {
      return this.guaranteeLetter?.id
        ? 'edit'
        : 'create'
    },
  },

  mounted () {
    const that = this
    this.$emit('registerOpenModal', that.openModal)
  },

  methods: {
    resetData () {
      this.resetValidations()
      this.guaranteeLetter = new GuaranteeLetterApi()
    },

    onModalClose (withUpdateList = false) {
      this.modalVisible = false
      this.$nextTick(() => { this.resetData() })
      if (withUpdateList) {
        this.$nextTick(() => { this.$emit('updateList') })
      }
    },

    /**
     * @param {IGuaranteeLetter} [guaranteeLetter]
     */
    openModal (guaranteeLetter) {
      this.modalVisible = true
      this.guaranteeLetter = new GuaranteeLetterApi(guaranteeLetter || { medicalPolicyId: this.medicalPolicyId })
    },

    async onSubmit () {
      if (this.hasValidationErrors()) { return }
      const result = await this.guaranteeLetter.save()
      if (result?.errors) { return this.resetValidations(result.errors) }

      this.onModalClose(true)
      this.$emit('onSubmit', this.guaranteeLetter.getAttributes())
    },

    async onDestroy () {
      const result = await this.guaranteeLetter.delete()
      if (result?.errors) { return }

      this.onModalClose(true)
    },
  },
})
</script>
