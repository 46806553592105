export const getCheckupByOrderFilters = () => [
  {
    value: 'true',
    title: t('checkups.personal.hasOrderFilter.true'),
  },
  {
    value: 'false',
    title: t('checkups.personal.hasOrderFilter.false'),
  },
]
