import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class CompanyCheckupListTotalCell {
  /**
   * @param {{ count: number, realWorkers: number}} data
   */
  constructor (data) {
    this.number = new MTableCell(t('total'))
    this.period = new MTableCell(data.count)
      .addTooltip(t('checkups.totals.checkups', { count: data.count }))
    this.realWorkers = new MTableCell(data.realWorkers)
      .addTooltip(t('checkups.totals.realWorkers', { count: data.realWorkers }))
  }
}
