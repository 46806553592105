<template>
  <div class="flex justify-content-between">
    <!-- Номер справки -->
    <document-square-field-labeled :gap="22">
      <template #label>
        <span>{{ t$('print.certificateNumber') }}</span>
      </template>

      <template #field>
        <document-square-field
          :value="certificate.certificateNumber"
          :line-size="12"
        />
      </template>
    </document-square-field-labeled>

    <!-- Номер корректировки -->
    <document-square-field-labeled :gap="24">
      <template #label>
        <span>{{ t$('print.correctionNumber') }}</span>
      </template>

      <template #field>
        <document-square-field
          :value="certificate.correctionNumber"
          :line-size="3"
        />
      </template>
    </document-square-field-labeled>

    <!-- Отчетный год -->
    <document-square-field-labeled>
      <template #label>
        <span>{{ t$('reportingYear') }}</span>
      </template>

      <template #field>
        <document-square-field
          :value="certificate.reportingYear"
          :line-size="4"
        />
      </template>
    </document-square-field-labeled>
  </div>
</template>

<script>
import DocumentSquareFieldLabeled
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLabeled/DocumentSquareFieldLabeled.vue'
import DocumentSquareField from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareField.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default {
  name: 'TaxCertificate2024PrintableCertificateIdentification',

  components: { DocumentSquareField, DocumentSquareFieldLabeled },

  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024')
  },
}
</script>
