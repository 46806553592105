<template>
  <panel-heading
    class="treatment__card treatment__card_steps"
    :title="t('egisz.medical_cases.form.steps')"
    icon="fad fa-fw fa-clipboard-list-check"
  >
    <si>
      <si-control>
        <button
          v-if="!readOnly"
          v-tooltip="t('egisz.steps.new')"
          type="button"
          class="btn btn-sm btn-success btn-with-icon"
          @click="stepModalOpen(null)"
        >
          <i class="fad fa-plus btn-with-icon_icon" />
          <span>{{ t('create') }}</span>
        </button>

        <template #refresh>
          <span class="empty-slot" />
        </template>

        <template #reset>
          <span class="empty-slot" />
        </template>

        <modal
          :modal-visibility="stepModalVisibility"
          modal-size="xl-1100"
          remove-footer
          @close="stepModalClose"
        >
          <template #header>
            <i class="fad fa-clipboard-list" />
            {{ t(selectedStep && selectedStep.id ? 'egisz.steps.edit' : 'egisz.steps.new') }}
          </template>
          <template #body>
            <step-form
              ref="stepForm"
              :step="selectedStep"
              :step-validations="selectedStepValidations"
              :client="client"
              :clinic-name="GET_APP_CONF_CURRENT_CLINIC_NAME"
              :read-only="readOnly"
              @applyStep="applyStep"
              @deleteStep="deleteStep"
              @resetValidations="resetValidations"
            />
          </template>
        </modal>
      </si-control>

      <si-table
        v-if="steps.length"
        class="card-steps__table"
      >
        <template #header>
          <si-field>
            <div class="col__created-at">
              {{ t('egisz.steps.table.header.created_at') }}
            </div>
            <div class="col__title">
              {{ t('egisz.steps.table.header.title') }}
            </div>
            <div class="col__user">
              {{ t('egisz.steps.table.header.user') }}
            </div>
            <div class="col__place">
              {{ t('egisz.steps.table.header.place') }}
            </div>
            <div class="col__entries">
              {{ t('egisz.steps.table.header.entries') }}
            </div>
            <div class="col__total">
              {{ t('egisz.steps.table.header.total') }}
            </div>
          </si-field>
        </template>

        <template #body>
          <si-field
            v-for="(step, index) in steps"
            :key="step.id"
            :class="{ 'invalid-background': isInvalid(step.id, index)}"
            @safe-click="stepModalOpen(step, index)"
          >
            <div class="col__created-at">
              {{ dateFormat(step.opened_at) || '-' }}
            </div>
            <div class="col__title">
              <span v-tooltip="step.title">{{ step.title }}</span>
            </div>
            <div class="col__user">
              {{ getDoctorShortName(step.doctor_id) }}
            </div>
            <div class="col__place">
              {{ GET_STEP_PLACE(step.netrika_visit_place_id) }}
            </div>
            <div class="col__entries">
              {{ step.entries_list && step.entries_list.length }}
            </div>
            <div class="col__total">
              {{ currencyFormat(getTotal(step.entries_list)) }}
            </div>
          </si-field>
        </template>
      </si-table>
      <span class="danger italic">
        {{ validationMessages.steps && validationMessages.steps[0] }}
      </span>
    </si>
  </panel-heading>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading'
import Si from '@/vue_components/sort_items/si'
import SiControl from '@/vue_components/sort_items/si_control'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import StepForm
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardSteps/StepForm'
import Modal from '@/vue_components/modal'
import { mapGetters } from 'vuex'
import CurrencyFormatter from '@/lib/currency_formatter/currency_formatter'
import { cloneDeep } from 'lodash'

export default {
  name: 'CardSteps',
  components: { Modal, StepForm, SiField, SiTable, SiControl, Si, PanelHeading },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },

    client: {
      type: Object,
      default: () => ({}),
    },

    validationMessages: {
      type: Object,
      default: () => ({}),
    },

    stepsValidations: {
      type: Object,
      default: null,
    },

    readOnly: Boolean,
  },

  data () {
    return {
      selectedStep: null,
      selectedStepValidations: null,
      selectedStepIndex: -1,
      stepModalVisibility: false,

      localStepValidations: {},
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_CURRENCY_PARAMS',
      'GET_APP_CONF_CURRENT_CLINIC_NAME',
      'GET_LOCALIZATION_DATE_FORMAT',
    ]),

    ...mapGetters('medCases', ['GET_STEP_PLACE']),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorShortName: 'getDoctorShortName',
    }),
  },

  watch: {
    stepsValidations (to) {
      this.localStepValidations = cloneDeep(to)
    },
  },

  methods: {
    isInvalid (stepId, index) {
      const byStepId = this.localStepValidations[stepId]
      const byIndex = this.localStepValidations[index]

      return (byStepId && byStepId.id) || (byIndex && !byIndex.id)
    },

    getTotal (entries = []) {
      return entries.reduce((sum, item) => sum + parseFloat(item.final_sum) || 0, 0)
    },

    currencyFormat (value) {
      const { code, locale } = this.GET_LOCALIZATION_CURRENCY_PARAMS

      return new CurrencyFormatter(code, locale).format(value)
    },

    dateFormat (value) {
      return value
        ? moment(value).format(this.GET_LOCALIZATION_DATE_FORMAT)
        : '-'
    },

    stepModalOpen (step = null, currentIndex = -1) {
      this.selectedStep = step
      this.selectedStepIndex = currentIndex

      if (step) {
        const id = step && typeof step.id === 'string'
          ? currentIndex
          : step.id
        this.selectedStepValidations = this.stepsValidations[id]
      }

      // костыль из-за особенностей ЖЦ компонента
      if (!step) {
        this.$refs.stepForm.generateTitle()
      }

      this.stepModalVisibility = true
    },

    stepModalClose () {
      this.stepModalVisibility = false
      this.resetStep()
    },

    resetStep () {
      this.selectedStep = null
      this.selectedStepIndex = -1
    },

    resetValidations () {
      delete this.localStepValidations[this.selectedStepIndex]
    },

    applyStep (step) {
      this.$emit('applyStep', step, this.selectedStepIndex)
      this.stepModalClose()
    },

    deleteStep (stepId) {
      this.$emit('deleteStep', stepId)
      this.stepModalClose()
    },
  },
}
</script>
