<template>
  <modal
    :modal-visibility="visibility"
    @close="$emit('close-modal-out')"
  >
    <template v-slot:header>
      <span class="fad fa-user-minus" />
      {{ t('_payment_kind.cash_out') }}
    </template>

    <template v-slot:body>
      <epic-spinner
        :visibility="loading"
      />
      <payments-cash-out-form
        :loading="loading"
        :customer="customer"
        :payment-spec="paymentSpec"
        :summary-input-user="summaryInputUser"
        :access-balance="accessBalance"
        :comment="comment"
        :validation-messages="validationMessages"
        :handle-reply-sum="handleReplySum"
        @update:paymentSpec="$emit('update:paymentSpec', $event)"
        @update:summaryInputUser="$emit('update:summaryInputUser', parseFloat($event))"
        @update:comment="$emit('update:comment', $event)"
      />
    </template>

    <template v-slot:footer-right>
      <button
        :disabled="disabled || loading"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click="$emit('submit-form')"
      >
        <div class="btn-with-icon_icon fad fa-money-bill" />
        <div class="btn-with-icon_text">
          {{ t('cash_out') }}
        </div>
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="$emit('close-modal-out')"
      >
        <div class="btn-with-icon_icon fad fa-times" />
        <div class="btn-with-icon_text">
          {{ t('close') }}
        </div>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import PaymentsCashOutForm from '@/vue_components/common/payments/payments_cash_out_form.vue'

export default {
  name: 'CompanyPaymentsCashOutModal',
  components: {
    Modal,
    EpicSpinner,
    PaymentsCashOutForm,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: String,
      default: '',
    },
    paymentSpec: {
      type: Array,
      required: true,
    },
    accessBalance: {
      type: Number,
      required: true,
    },
    summaryInputUser: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
    validationMessages: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleReplySum (field, replySum) {
      this.$emit(`update:${field}`, replySum)
    },
  },
}
</script>
