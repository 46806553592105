const getFormattedDate = (rawDate) => {
  return moment(rawDate).format(Utils.momentDateTimeFormat)
}

const getDate = (rawDate) => {
  return moment(rawDate).toDate()
}

export const getCertificatesAdapter = {
  toClient (data) {
    const now = new Date()

    return (data.system_certificates || [])
      .map((cert) => {
        cert.id = cert.thumbprint
        cert.title = `${cert.common_name}, ${cert.issued_by}, ${getFormattedDate(cert.expire)}`

        return cert
      })
      .filter((cert) => getDate(cert.expire) > now)
  },
}
