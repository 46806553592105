import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  personalCheckupDoctorResultsAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDoctorResultsPresenter/personalCheckupDoctorResultsAdapter'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'

export class PersonalCheckupDoctorResultsPresenter extends NestedItemsPresenter {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
      },

      adapter: personalCheckupDoctorResultsAdapter,

      singularEntity: 'personalCheckupDoctorResult',
      entity: 'personalCheckupDoctorResults',
      location: 'PersonalCheckupDoctorResultsPresenter',
    })
  }

  fetchSpecialties (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'specialties'
    )

    return super.get({
      url: Routes.api_internal_selectors_checkups_specialists_checkups_entry_types_list_path(),
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }

  fetchMedicalAssessments (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'conclusions'
    )

    return super.get({
      url: Routes.api_internal_selectors_checkups_specialists_medical_assessments_list_path(),
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
