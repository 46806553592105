<template>
  <div class="flex flex-wrap gap-indent">
    <div class="flex-grow-1">
      <h1>MButtonsGroup</h1>
      <m-buttons-group
        v-model="mBGValue1"
        :items="mBGItems"
      />

      <m-buttons-group
        v-model="mBGValue2"
        :items="mBGItems"
        buttons-type="primary"
      />

      <m-buttons-group
        v-model="mBGValue3"
        :items="mBGItems"
        buttons-type="success"
      />

      <m-buttons-group
        v-model="mBGValue4"
        :items="mBGItems"
        buttons-type="warning"
      />

      <m-buttons-group
        v-model="mBGValue5"
        :items="mBGItems"
        buttons-type="danger"
      />

      <m-buttons-group
        v-model="mBGValue6"
        :items="mBGItems"
        buttons-type="info"
      />

      <h1>Size mini</h1>
      <m-buttons-group
        v-model="mBGValue1"
        :items="mBGItems"
        size="mini"
      />

      <m-buttons-group
        v-model="mBGValue2"
        :items="mBGItems"
        buttons-type="primary"
        size="mini"
      />

      <m-buttons-group
        v-model="mBGValue3"
        :items="mBGItems"
        buttons-type="success"
        size="mini"
      />

      <m-buttons-group
        v-model="mBGValue4"
        :items="mBGItems"
        buttons-type="warning"
        size="mini"
      />

      <m-buttons-group
        v-model="mBGValue5"
        :items="mBGItems"
        buttons-type="danger"
        size="mini"
      />

      <m-buttons-group
        v-model="mBGValue6"
        :items="mBGItems"
        buttons-type="info"
        size="mini"
      />

      <h1>Максимум 3</h1>
      <pre class="width-fit-content">:max="3"</pre>
      <m-buttons-group
        v-model="mBGValue7"
        :items="mBGItems"
        :max="3"
      />

      <h1>Радио</h1>
      <p>Требуется указать пропсу <b>radio</b></p>
      <m-buttons-group
        v-model="mBGValue8"
        :items="mBGItems"
        radio
      />

      <h1>Работа со строками/Числами</h1>
      <p>Требуется указать пропсу <b>useCustomResult</b></p>
      <p>значения:<b>simple</b> и <b>object</b></p>
      <m-buttons-group
        v-model="mBGValue9"
        :items="sexes"
        use-custom-result="simple"
        use-t-scope
        radio
      />
    </div>

    <div class="flex-grow-1">
      <h1>Данные</h1>
      <h2>Основные пропсы</h2>
      <pre class="width-fit-content">
value: PropsTypes.Custom([Array, String, Number, Object]),
items: PropsTypes.Array(),
max: PropsTypes.Number(),

valueKey: PropsTypes.String('id'),
labelAttribute: PropsTypes.String('title'),

radio: Boolean, // включение режима радио

// 'primary', 'success', 'warning', 'danger', 'info', 'text', 'default'
buttonsType: PropsTypes.String('', false, customPropsValidators.typeValidator)

size: PropsTypes.String('small')

// OBJECT (по умолчанию): при radio будет объект: { id: ..., title: ...},
//                        без radio - массив объектов: [{ id: ..., title: ...}, {...}]
// SIMPLE: при radio будет значение (примитив): 1,
//         без - массив значений: [1, 2, 3]
useCustomResult: PropsTypes.String(DEFAULT_RESULT_TYPES.OBJECT)

// подставит заголовки из i18n scope, если указать без значения, то будет искать в ru
useTScope: { type: [Boolean, String], default: false }</pre>
      <h2>События</h2>
      <pre class="width-fit-content">
@change
@update:value</pre>

      <h1>Дополнительная информация</h1>
      <p>Используется <b>ValidationChildMixin</b></p>
    </div>
  </div>
</template>

<script>
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup'

export default {
  name: 'FrontBookPageMButtonsGroup',
  components: { MButtonsGroup },
  data () {
    return {
      mBGValue1: [{ id: 1, title: 'B1' }],
      mBGValue2: [{ id: 2, title: 'B2' }],
      mBGValue3: [{ id: 3, title: 'B3' }],
      mBGValue4: [{ id: 4, title: 'B4' }],
      mBGValue5: [{ id: 5, title: 'B5' }],
      mBGValue6: [{ id: 6, title: 'B6' }],

      mBGValue7: [
        { id: 1, title: 'B1' },
        { id: 5, title: 'B5' },
      ],

      mBGValue8: 1,
      mBGValue9: null,

      mBGItems: [
        { id: 1, title: 'B1' },
        { id: 2, title: 'B2' },
        { id: 3, title: 'B3' },
        { id: 4, title: 'B4' },
        { id: 5, title: 'B5' },
        { id: 6, title: 'B6' },
      ],

      sexes: ['male', 'female'],
    }
  },
}
</script>
