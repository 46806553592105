import {
  ACTIVE_PHONE_NUMBER_MODIFIER,
  CALL_NTF_CLASS,
  CALL_NTF_PRESET_NAME,
  CALL_NTF_STATUS_RESULT,
  FINISHED_CALL_STATUS,
  IN_PROGRESS_CALL_RESULT,
  PHONE_NUMBER_CLASS,
} from '../modules/telephony/const.js'

import { callNotificationsIcons } from '../modules/telephony/css_classes.js'

class Telephony {
  listenEventsList () {
    return [
      'page.specific.sort_items.finish',
      'page.specific.overpop.show',
      'page.specific.call_modal.shown',
    ]
  }

  init () {
    this.makeNumbersCallable()
    this.assignEvents()
    this.addListeners()
  }

  reset () {
    this.makeNumbersCallable()
    this.assignEvents()
  }

  addListeners () {
    this.listenEventsList().forEach((event) => {
      PubSub.on(event, () => this.reset())
    })
  }

  createAskForResultNotification (call) {
    const formattedPhone = `+${Services.phoneMask.setMask(call.phone)}`
    let message = formattedPhone

    if (call.client_id) {
      const clientFullName = `${call.client.surname} ${call.client.name} ${call.client.second_name}`

      message = `${clientFullName} \n ${formattedPhone}`

      if (call.clients_with_same_phone_number) {
        if (call.clients_with_same_phone_number.length > 0) {
          message = `${clientFullName} (+${call.clients_with_same_phone_number.length}) \n ${formattedPhone}`
        }
      }
    }

    Notify.create({
      options: {
        icon: callNotificationsIcons[CALL_NTF_STATUS_RESULT],
        message: `${t('telephony.set_refuse_result')}: ${message}`,
      },
      settings: {
        allow_dismiss: false,
        z_index: 1200,
      },
      custom_settings: {
        preset_name: CALL_NTF_PRESET_NAME,
        status: CALL_NTF_STATUS_RESULT,
        call_id: call.id,
      },
    })
  }

  createCallNotification (call) {
    const { message, title, icon, status } = this.returnNtfParams(call)
    Notify.create({
      options: {
        icon,
        title,
        message,
      },
      settings: {
        z_index: 1200,
      },
      custom_settings: {
        preset_name: CALL_NTF_PRESET_NAME,
        call_id: call.id,
        status,
        type: call.call_type,
      },
    })
  }

  removeCallNotifications (call) {
    Notify.closeNotification({
      selector: CALL_NTF_CLASS,
      filter () {
        return $(this).data('call-id') === call.id
      },
    })
  }

  returnNtfParams (call) {
    const params = Object.create(null)
    const formattedPhone = `+${Services.phoneMask.setMask(call.phone)}`

    params.message = formattedPhone
    if (call.client_id) {
      const clientFullName = `${call.client.surname} ${call.client.name} ${call.client.second_name}`

      params.message = `${clientFullName} \n ${formattedPhone}`

      if (call.clients_with_same_phone_number) {
        if (call.clients_with_same_phone_number.length > 0) {
          params.message = `${clientFullName} (+${call.clients_with_same_phone_number.length}) \n ${formattedPhone}`
        }
      }
    }

    if (call.result === IN_PROGRESS_CALL_RESULT) {
      params.title = t(`telephony.${call.call_type}_call_from`)
      params.icon = callNotificationsIcons[call.call_type]
      params.status = `${call.call_type}_call`
    } else {
      params.title = t('telephony.finished_call_from')
      params.icon = callNotificationsIcons[FINISHED_CALL_STATUS]
      params.status = FINISHED_CALL_STATUS
    }

    return params
  }

  makeNumbersCallable () {
    const phoneNumbers = document.getElementsByClassName(PHONE_NUMBER_CLASS)
    for (const phoneNumber of phoneNumbers) {
      if (!phoneNumber.classList.contains(ACTIVE_PHONE_NUMBER_MODIFIER)) {
        phoneNumber.classList.add(ACTIVE_PHONE_NUMBER_MODIFIER)
      }
    }
  }

  assignEvents () {
    this.onClickEvent()
  }

  onClickEvent () {
    const activePhoneNumberClass = [PHONE_NUMBER_CLASS, ACTIVE_PHONE_NUMBER_MODIFIER].join(' ')
    const phoneNumbers = document.getElementsByClassName(activePhoneNumberClass)
    for (const phoneNumber of phoneNumbers) {
      phoneNumber.addEventListener('click', (event) => {
        event.stopImmediatePropagation()
        event.preventDefault()

        const data = {
          phoneNumber: event.target.dataset.phone || event.target.innerText,
          clientId: event.target.dataset.clientId,
          userId: event.target.dataset.userId,
          element: event.target,
          noSms: +event.target.dataset.noSms === 1,
        }

        Services.pubSub.emitAsync('PHONE_POPOVER.SHOW', data)
      })
    }
  }
}

const instance = new Telephony()

export default {
  init: () => instance.init(),
  reset: () => instance.reset(),
  createCallNotification: (call) => instance.createCallNotification(call),
  createAskForResultNotification: (call) => instance.createAskForResultNotification(call),
  removeCallNotifications: (call) => instance.removeCallNotifications(call),
}
