<template>
  <el-pagination
    v-show="pageCount > 1"
    class="m-pagination"
    layout="prev, pager, next"
    :current-page="currentPage"
    :page-count="pageCount"
    @current-change="$updateSync('currentPage', $event)"
  />
</template>

<script>
import { MPaginationProps } from '@/vue_present/_base/Tables/MPagination/MPaginationProps'

export default {
  name: 'MPagination',
  props: {
    ...MPaginationProps,
  },

  emits: [
    'update:currentPage',
  ],
}
</script>
