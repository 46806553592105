import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  attachmentsListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/attachmentsListAdapter'

export class MClientAttachmentsPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'attachment',
      location: 'MClientAttachmentsPresenter',
      routes: {
        all () {},
        one: Routes.attachment_path,
        list: Routes.list_api_internal_medical_records_remote_files_path,
      },
    })
  }

  /**
   * @param {number} clientId
   * @returns {Promise<number>}
   */
  async count (clientId) {
    return this.get({
      url: Routes.count_for_client_api_internal_medical_records_remote_files_path(),
      data: { client_id: clientId },
    }).promise
  }

  async list (listData, config = {}) {
    return super.list(listData, {
      ...config,
      toClientAdapter: attachmentsListAdapter.toClient,
      toJson: true,
    })
  }

  /**
   * @param {number} clientId
   * @param {File} file
   * @returns {Promise<*>}
   */
  upload (clientId, file) {
    const formData = new FormData()
    formData.append('attachment[file]', file)
    formData.append('client_id', clientId)

    return this.post({
      url: Routes.create_client_attachment_path(),
      data: formData,
      contentType: false,
      processData: false,
    }).promise
  }
}
