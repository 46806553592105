import { camelToSnake } from '@/_api/_requests/helpers'
import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  checkupEntryUpdateAdapter,
} from '@/vue_apps/CheckupsModule/api/MCheckupEntryPresenter/checkupEntryUpdateAdapter'

export class MCheckupEntryPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () {},
        all () {},
        list () {},
      },
      entity: 'checkupEntry',
      location: 'MCheckupEntryPresenter',
    })
  }

  submit (data, config = {}) {
    return super.post({
      url: Routes.create_from_checkup_entries_api_internal_checkups_entries_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
  }

  update (data, config = {}) {
    return this.post({
      url: Routes.batch_update_api_internal_entries_path(),
      data: checkupEntryUpdateAdapter.toServer(data),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
  }

  destroy (data, config = {}) {
    return this.post({
      url: Routes.batch_destroy_api_internal_entries_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
  }
}
