export const CLIENT_WIDGET_ICON = 'fa-globe'
export const SMS_ICON = 'fa-sms'
export const TELEPHONY_ICON = 'fa-phone-office'
export const APP_CONF_ICON = 'fa-cog'
export const PERMISSIONS_ICON = 'fa-user-lock'
export const PERMISSIONS = 'userPermissions'

export const MODULES = {
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
  CLIENT_WIDGET: 'client_widget',
  TELEPHONY: 'telephony',
  APP_CONF: 'app_configuration',
}

export const OPTIONS = {
  SMS_REMIND: 'remind_before_date',
  SMS_NOTIFY: 'notify_after_save',
  SMS_CONFIRMATION: 'send_sms_confirmation',

  UIS_REMIND: 'remind_by_call',
  UIS_CONFIRM: 'confirm_by_call',

  CLIENT_WIDGET_AVAILABLE: 'client_widget_settings',
  APP_CONF_DIFFERENT: 'app_conf_different',
}
