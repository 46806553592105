import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { CHECKUP_STATUS_ICONS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'

export class CompanyCheckupListCellItem extends MListServiceItem {
  /**
   * @param {ICompanyCheckupListItem} rawData
   */
  constructor (rawData) {
    super()
    this.id = rawData.id
    this.number = new MTableCell(rawData.id)
    this.status = rawData.status
      ? new MTableCell()
        .addTypeIcon(CHECKUP_STATUS_ICONS[rawData.status].icon)
        .addClasses(CHECKUP_STATUS_ICONS[rawData.status].color)
        .addTooltip(t(rawData.status, { scope: 'checkups.statuses' }))
      : new MTableCell()
    this.period = new MTableCell(`${vueFilters.date(rawData.plannedStartDate)} - ${vueFilters.date(rawData.plannedEndDate)}`)
    this.company = new MTableCell(rawData.company.title)
      .addCut()
      .addTypeUrl(Routes.company_path(rawData.company.id))
      .addTooltip(rawData.company.title)
    this.checkupName = new MTableCell(rawData.title)
      .addCut()
      .addTooltip(rawData.title)
    this.responsible = new MTableCell(rawData.responsibleUserShortName)
    this.realWorkers = new MTableCell(rawData.realWorkers || '-')
    this.comment = rawData.comment
      ? new MTableCell(rawData.comment)
        .addTooltip(rawData.comment)
        .addTypeIcon('comment')
        .addClasses('primary')
      : new MTableCell()
  }
}
