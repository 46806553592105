<template>
  <div class="semd-document-attributes-container">
    <!--    Диагноз МКБ-10-->
    <div class="consultation-document-attributes__diagnosis mb-indent-small">
      <span class="bold">
        {{ t$('diagnosis') }}
      </span>
      {{ nsiMkbDiagnosisGroup?.title }}
    </div>

    <!--    Вид обращения-->
    <div class="consultation-document-attributes__visitType mb-indent-small">
      <span class="bold">
        {{ t$('visitType') }}
      </span>
      {{ nsiCaseVisitType?.title }}
    </div>

    <!--    Место оказания-->
    <div class="consultation-document-attributes__medicalCarePlace mb-indent-small">
      <span class="bold">
        {{ t$('medicalCarePlace') }}
      </span>
      {{ nsiMedicalCarePlace?.title }}
    </div>

    <!--    Состояние пациента-->
    <div class="consultation-document-attributes__patientCondition mb-indent-small">
      <span class="bold">
        {{ t$('patientCondition') }}
      </span>
      {{ nsiPatientCondition?.title }}
    </div>

    <!--    Выявленная патология-->
    <div
      v-if="nsiIdentifiedPathology?.title"
      class="consultation-document-attributes__pathology mb-indent"
    >
      <span class="bold">
        {{ t$('pathology') }}
      </span>
      {{ nsiIdentifiedPathology?.title }}
    </div>

    <!--    Анамнез заболевания-->
    <div class="consultation-document-attributes__diseaseAnamnesis mb-indent">
      <div class="bold">
        {{ t$('anamnesisOfDisease') }}
      </div>
      <div v-html="anamnesisOfDisease" />
    </div>

    <!--    Анамнез жизни-->
    <div class="consultation-document-attributes__lifeAnamnesis mb-indent">
      <div class="bold">
        {{ t$('anamnesisOfLife') }}
      </div>
      <div v-html="anamnesisOfLife" />
    </div>

    <!--    Объективно-->
    <div class="consultation-document-attributes__objective mb-indent">
      <div class="bold">
        {{ t$('objectively') }}
      </div>
      <div v-html="objectively" />
    </div>

    <!--    Заключение-->
    <div class="consultation-document-attributes__conclusion mb-indent">
      <div class="bold">
        {{ t$('conclusion') }}
      </div>
      <div v-html="conclusion" />
    </div>

    <!--    Диагноз текстом-->
    <div class="consultation-document-attributes__diagnosisByText mb-indent">
      <div class="bold">
        {{ t$('diagnosisByText') }}
      </div>

      <div v-html="diagnosisByText" />
    </div>
  </div>
</template>

<script>
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConsultationDocumentAttributes',

  mixins: [
    i18nScopeMixin,
  ],

  props: {
    nsiMkbDiagnosisGroup: { type: Object, default () {} },
    nsiCaseVisitType: { type: Object, default () {} },
    nsiMedicalCarePlace: { type: Object, default () {} },
    nsiPatientCondition: { type: Object, default () {} },
    nsiIdentifiedPathology: { type: Object, default () {} },
    anamnesisOfDisease: { type: String, default: '' },
    anamnesisOfLife: { type: String, default: '' },
    objectively: { type: String, default: '' },
    conclusion: { type: String, default: '' },
    diagnosisByText: { type: String, default: '' },
  },

  created () {
    this.setI18nScope('semds.semd119.documentAttributesTitles')
  },
})
</script>
