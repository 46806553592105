import { createValidator } from '@/lib/validators'
import { email, minLength, required } from '@/lib/validators/rules'

export const requiredValidator = createValidator([required], {
  required: t('activerecord.required.text'),
})

/**
 * Для строк и массивов
 * @type {Validator}
 */
export const notEmptyValidator = createValidator([minLength(1)], {
  minLength: t('activerecord.required.text'),
})

export const createPhoneValidator = (length, customMessage = null) => createValidator([minLength(length)], {
  minLength: customMessage || t('errors.rules.phone.length?', { length }),
})

export const emailValidator = createValidator([email], {
  email: t('errors.rules.email.valid_email?'),
})

export const mEmailValidator = (value = '') => {
  return email(value).valid
    ? []
    : [t('errors.rules.email.valid_email?')]
}

export const mPhoneValidator = (value = '') => {
  return minLength(10)(value).valid
    ? []
    : [t('telephony.uis.errors.wrong_phone_number')]
}
