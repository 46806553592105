<template>
  <span
    v-if="promptVisible"
    class="warning pointer ml-5"
    @click="$emit('refresh'); promptVisible = false"
  >
    <i class="fad fa-sync" />
    {{ t('egisz.has_updates') }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EgiszHasUpdates',
  props: {
    watchProps: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      promptVisible: false,
    }
  },

  computed: {
    ...mapGetters('egisz/egiszWs', {
      wsEntities: 'vxGetWsEntities',
    }),
  },

  created () {
    this.watchProps.forEach((watchProp) => {
      this.$watch(
        `wsEntities.${watchProp}`,
        () => { this.promptVisible = true }
      )
    })
  },
}
</script>
