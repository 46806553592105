<template>
  <m-panel
    v-loading="loading"
    :title="t('checkups.documents.bladeRunner')"
    class="company-blade-runner"
    use-print-button
  >
    <div class="checkup-document">
      <div class="prompt-notice hidden-print mb-indent-validation warning">
        {{ t('checkups.documents.printMultiList') }}
      </div>

      <template v-for="(br, index) in companyBladeRunner.bladeRunners">
        <blade-runner-view
          :key="br.id"
          :blade-runner="br"
        />

        <div
          v-if="companyBladeRunner.bladeRunners.length > 1 && index !== companyBladeRunner.bladeRunners.length - 1"
          :key="`page-break-${br.id}`"
          class="page-break mb-indent mt-indent"
        />
      </template>
    </div>

    <template #footer>
      <company-blade-runner-finals-editor :company-blade-runner="companyBladeRunner">
        <template #reference>
          <m-button
            type="warning"
            :text="t('refresh_data')"
            icon="edit"
          />
        </template>
      </company-blade-runner-finals-editor>

      <m-button-delete
        use-button
        @yes="onDestroy"
      />
    </template>
  </m-panel>
</template>

<script>
import { BladeRunners } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunners'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import BladeRunnerView from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunnerView.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import CompanyBladeRunnerFinalsEditor
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/CompanyDocuments/CompanyBladeRunner/CompanyBladeRunnerFinalsEditor.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'

export default {
  name: 'CompanyBladeRunner',
  components: { MButtonDelete, CompanyBladeRunnerFinalsEditor, MButton, MPanel, BladeRunnerView },
  mixins: [
    SpinnerHolder,
    ValidationParentMixin,
  ],

  props: {
    id: { type: Number, default: null },
  },

  emits: [
    'setSaveCallback',
    'setTitle',
    'destroy',
  ],

  data () {
    return {
      companyBladeRunner: new BladeRunners(this.id),
    }
  },

  async created () {
    if (!this.companyBladeRunner.id) { return }

    this.companyBladeRunner = await this.withSpinner(this.companyBladeRunner.load())
  },

  methods: {
    async onDestroy () {
      await this.companyBladeRunner.destroy()

      this.$emit('destroy')
    },
  },
}
</script>
