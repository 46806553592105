<template>
  <a
    class="btn btn-primary btn-xs hidden-print btn-back"
    href="/"
    @click.prevent="goBackHandler"
  >
    <slot>
      {{ t('back') }}
    </slot>
  </a>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    pattern: {
      type: String,
      required: true,
    },
  },
  methods: {
    goBackHandler (e) {
      e.stopPropagation()
      window.HistoryBrowser.goBackFrom(this.pattern)
    },
  },
}
</script>
