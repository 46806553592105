import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class CompanyCheckupCalculatedEntryCell {
  /**
   * @param {{
   *   count: number,
   *   title: string,
   *   kind: 'test' | 'specialist'
   * }} props
   */
  constructor (props) {
    this.id = Utils.newGUID()
    this.kind = props.kind

    this.title = new MTableCell(props.title)
      .addCut()
      .addTooltip(props.title)
    this.action = new MTableCell(props.count)
  }

  trigger () {}
}
