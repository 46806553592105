import { orUndefined } from '@/_medods_standart_library/msl'
import { SNILS_THE_MASK } from '@/specific/clients/methods/snils'

export const checkupClientSearchAdapter = {
  toServer (payload) {
    return {
      sortParams: {
        searchQuery: orUndefined(payload.searchQuery),
        birthdate: orUndefined(payload.birthdate),
        limit: orUndefined(payload.limit),
        offset: orUndefined(payload.offset),
      },
    }
  },

  toClient (response) {
    return {
      ...response,
      data: response.data.map((item) => ({
        ...item,
        snils: Utils.applyMask(item.snils, SNILS_THE_MASK),
      })),
    }
  },
}
