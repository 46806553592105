import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { TDocumentOwner } from '@/vue_apps/Documents/DocumentsOwners/TDocumentOwner'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'

export class DocumentOwnerItemCell extends MListServiceItem {
  documentId: number

  documentType: DOCUMENT_TYPES

  date: MTableCell

  title: MTableCell

  constructor (item: TDocumentOwner) {
    super()
    this.id = item.id
    this.documentId = item.documentId
    this.documentType = item.documentType

    this.date = new MTableCell(Utils.getFormattedDate(item.createdAt))
    this.setTitle(t(item.documentType, { scope: 'documents_owners.document_type', id: item.documentId }))
  }

  setTitle (title: string) {
    this.title = new MTableCell(title)
      .addTooltip(true)
      .addCut()
  }
}
