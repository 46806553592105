import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import {
  PersonalSimpleMedicalAssessmentApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalSimpleMedicalAssessment/PersonalSimpleMedicalAssessmentApi'
import {
  PersonalElmkMedicalAssessmentApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalElmkMedicalAssessment/PersonalElmkMedicalAssessmentApi'
import { CompanyFinalActApi } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActApi'

type TCheckupDocument = PersonalSimpleMedicalAssessmentApi |
  PersonalElmkMedicalAssessmentApi |
  CompanyFinalActApi

export const getCheckupDocument = (documentType, props): TCheckupDocument => {
  switch (documentType) {
    case DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT:
      return new PersonalSimpleMedicalAssessmentApi(props)

    case DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT:
      return new PersonalElmkMedicalAssessmentApi(props)

    case DOCUMENT_TYPES.CHECKUPS_COMPANY_FINAL_ACT:
      return new CompanyFinalActApi(props)

    default:
  }
}
