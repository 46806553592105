<template>
  <div class="fast-create-modal__client">
    <div class="fast-create-modal__row">
      <!-- Фамилия -->
      <m-input
        :value="client.surname"
        :label="t('surname')"
        :disabled="client.disabledFields.surname"
        required
        validator-name="surname"
        @registerValidator="onRegisterValidator"
        @input="client.setValue('surname', $event)"
      />

      <!-- Имя -->
      <m-input
        :value="client.name"
        :label="t('name')"
        :disabled="client.disabledFields.name"
        required
        validator-name="name"
        @registerValidator="onRegisterValidator"
        @input="client.setValue('name', $event)"
      />

      <!-- Отчество -->
      <m-input
        :value="client.secondName"
        :label="t('second_name')"
        :disabled="client.disabledFields.secondName"
        @input="client.setValue('secondName', $event)"
      />

      <!-- Дата рождения -->
      <m-date-picker
        :value="client.birthdate"
        :required="dateRangeValidator"
        :label="t('birthday')"
        :disabled="client.disabledFields.birthdate"
        value-format="yyyy-MM-dd"
        validator-name="birthdate"
        @registerValidator="onRegisterValidator"
        @change="client.setValue('birthdate', $event)"
      />

      <!-- Пол -->
      <m-select
        :value="client.sex"
        :items="sexes"
        :clearable="false"
        :label="t('sex')"
        :disabled="client.disabledFields.sex"
        required
        validator-name="sex"
        @registerValidator="onRegisterValidator"
        @change="client.setValue('sex', $event)"
      />
    </div>

    <m-button-delete
      class="fast-create-modal_delete-btn"
      @yes="$emit('onClientRemove', client.key)"
    />

    <div class="fast-create-modal__row">
      <!-- СНИЛС -->
      <m-input
        :value="client.snils"
        :label="t('snils')"
        :mask="SNILS_THE_MASK"
        :required="!!client.snils && snilsValidator"
        :placeholder="SNILS_MASK_PLACEHOLDER"
        :disabled="client.disabledFields.snils"
        validator-name="snils"
        @registerValidator="onRegisterValidator"
        @input="client.setValue('snils', $event)"
      />

      <!-- Должность/Профессия -->
      <reusable-local-n-s-i-list
        v-if="client.useNSIProfession"
        :class="{ 'fast-create-modal__long-field': !customEMK }"
        :value="client.nsiProfession"
        :label="t$('position')"
        :dictionary="NSI.PROFESSIONS"
        @change="client.setValue('nsiProfession', $event)"
      />

      <!-- Должность/Профессия -->
      <m-input
        v-else
        :class="{ 'fast-create-modal__long-field': !customEMK }"
        :value="client.position"
        :label="t$('position')"
        @input="client.setValue('position', $event)"
      />

      <!--  Факторы по приказу -->
      <m-select-lazy
        :value="client.factors"
        class="fast-create-modal__long-field"
        :label="t$('factors')"
        :fetch-method="(params) => factorsList.list(params)"
        :additional-displayed-filter="factorsCodeFilter"
        use-first-time-fetch
        option-notice="codeNotice"
        multiple
        filterable
        collapse-tags
        required
        validator-name="factors"
        @registerValidator="onRegisterValidator"
        @changeByLazy="client.setValue('factors', $event)"
      />

      <!-- № ЭМК -->
      <m-input
        v-if="customEMK"
        :value="client.patientCardNumber"
        :label="t$('emk')"
        @input="client.setValue('patientCardNumber', $event)"
      />
    </div>

    <div class="fast-create-modal__row">
      <m-icon
        v-show="showIcon"
        v-tooltip="iconTooltip"
        class="mb-indent-validation"
        :class="{ 'pointer': useCursorPointer }"
        :icon="iconType"
        :color="iconColor"
        @click="client.iconStatusClick()"
      />

      <m-icon
        v-if="isDataLoaded"
        v-tooltip="t('infocard')"
        class="mb-indent-validation pointer"
        icon="link"
        color="primary"
        @click="onLinkClick()"
      />

      <!-- Клиент -->
      <m-select-lazy
        v-if="hasDuplicate"
        :value="client.selectedClient"
        :label="t('client')"
        :fetch-method="(params) => client.searchClient(params)"
        option-label="fullName"
        use-first-time-fetch
        use-first-time-fetch-on-open
        @changeByLazy="client.setValue('selectedClient', $event)"
      />

      <!-- Дата медосмотра -->
      <m-date-picker
        :value="client.date"
        :label="t$('checkupDate')"
        style="min-width: 230px"
        type="daterange"
        value-format="yyyy-MM-dd"
        required
        validator-name="date"
        @registerValidator="onRegisterValidator"
        @change="client.setValue('date', $event)"
      />

      <!-- Следующий медосмотр -->
      <m-date-picker
        :value="client.nextDate"
        :label="t$('nextCheckup')"
        style="width: 170px"
        value-format="yyyy-MM-dd"
        required
        validator-name="nextDate"
        @registerValidator="onRegisterValidator"
        @change="client.setValue('nextDate', $event)"
      />
    </div>
  </div>
</template>

<script>
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { SNILS_MASK_PLACEHOLDER, SNILS_THE_MASK } from '@/specific/clients/methods/snils'
import { GLOBAL_GET_DEFAULT_SEXES } from '@/_global_scripts/GLOBAL_CONSTS'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { factorsCodeFilter } from '@/vue_apps/CheckupsModule/store/factorsCodeFilter'
import {
  MCheckupFactorsPresenter,
} from '@/vue_apps/CheckupsModule/api/MCheckupFactorsPresenter/MCheckupFactorsPresenter'
import { FastCreateClientLogic } from '@/vue_apps/CheckupsModule/classes/FastCreateClient/FastCreateClientLogic'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { FAST_CREATE_STATUSES } from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateStatuses'
import { getDateRangeValidator } from '@/lib/validators/getDateRangeValidator'
import {
  FAST_CREATE_MAX_AGE,
  getMinDateFromNow,
} from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateConsts'
import { snilsValidator } from '@/lib/validators/snilsValidator'

export const dateRangeValidator = getDateRangeValidator(
  getMinDateFromNow(FAST_CREATE_MAX_AGE)
)

export default {
  name: 'FastCreateModalClient',

  components: {
    MSelectLazy,
    ReusableLocalNSIList,
    MButtonDelete,
    MIcon,
    MInput,
    MSelect,
    MDatePicker,
  },

  mixins: [i18nScopeMixin, ValidationParentMixin],

  props: {
    client: FastCreateClientLogic,
  },

  emits: ['onClientRemove'],

  data () {
    return {
      NSI: NSI_DICTIONARIES,
      SNILS_THE_MASK,
      SNILS_MASK_PLACEHOLDER,
      sexes: GLOBAL_GET_DEFAULT_SEXES(),
      customEMK: gon.application.changeable_medcard_numbers,
      factorsList: new MCheckupFactorsPresenter(),
    }
  },

  computed: {
    showIcon () {
      return this.client.name &&
          this.client.surname &&
          this.client.birthdate
    },

    isDataLoaded () {
      return this.client.status === FAST_CREATE_STATUSES.DATA_LOADED
    },

    hasDuplicate () {
      return this.client.status === FAST_CREATE_STATUSES.DUPLICATE_FOUND ||
          (this.client.status === FAST_CREATE_STATUSES.DATA_FOUND &&
              !this.client.noDuplicates)
    },

    useCursorPointer () {
      return this.client.status === FAST_CREATE_STATUSES.DATA_FOUND ||
          this.client.status === FAST_CREATE_STATUSES.SEARCH_FOR_MATCHES ||
          (this.hasDuplicate && this.client.selectedClient)
    },

    iconType () {
      return this.isDataLoaded
        ? 'ready'
        : 'reset'
    },

    iconColor () {
      switch (this.client.status) {
        case FAST_CREATE_STATUSES.SEARCH_FOR_MATCHES: return 'primary'
        case FAST_CREATE_STATUSES.NO_MATCHES_FOUND: return 'gray'
        case FAST_CREATE_STATUSES.DATA_FOUND: return 'warning'
        case FAST_CREATE_STATUSES.DUPLICATE_FOUND: return 'danger'
        case FAST_CREATE_STATUSES.DATA_LOADED: return 'success'
        default: return null
      }
    },

    iconTooltip () {
      return this.client.status
        ? this.t$(this.client.status)
        : null
    },
  },

  created () {
    this.setI18nScope('checkups.fastCreate')
  },

  methods: {
    snilsValidator,
    factorsCodeFilter,
    dateRangeValidator,

    onLinkClick () {
      Utils.openInNewTab(this.$routes.client_path(this.client.id))
    },
  },
}
</script>
