<template>
  <div class="personal-checkup-entries__filters">
    <m-button
      type="success"
      icon="document"
      disabled="CheckupsCompany"
      :tooltip="t('add_entry_service')"
      plus-icon
      @click="$emit('openModal')"
    />

    <!-- Типы -->
    <m-select
      :value="kind"
      :items="kinds"
      :label="t('service_type')"
      :placeholder="t('select')"
      :m-fixed-height="false"
      @change="$updateSync('kind', $event)"
    />

    <!-- Статусы -->
    <m-select
      v-if="showState"
      :value="state"
      :items="states"
      :label="t('status')"
      :placeholder="t('select')"
      :m-fixed-height="false"
      @change="$updateSync('state', $event)"
    />

    <!--  Врач  -->
    <reusable-doctors-list
      :value="user"
      :placeholder="t('select')"
      :current-clinic="personalCheckup.clinic.id"
      @onDoctorChange="$updateSync('user', $event)"
    />

    <!-- Мои услуги -->
    <m-checkbox
      :value="mine"
      :label="t('checkups.entries.mine')"
      border
      @input="$updateSync('mine', $event)"
    />

    <m-button
      type="warning"
      :tooltip="t('reset')"
      no-use-fw
      icon="no-filter"
      @click="$emit('onResetClick')"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { GLOBAL_ENTRY_STATE, GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

const states = [
  { id: GLOBAL_ENTRY_STATE.READY, title: t('ready') },
  { id: GLOBAL_ENTRY_STATE.NOT_READY, title: t('not_ready') },
]

const kinds = [
  { id: GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS, title: t('analysis') },
  { id: GLOBAL_ENTRY_TYPES_KINDS.SERVICE, title: t('service') },
  { id: GLOBAL_ENTRY_TYPES_KINDS.COMPLEX, title: t('complex_entry') },
]

export default {
  name: 'PersonalCheckupEntriesFilters',

  components: {
    ReusableDoctorsList,
    MCheckbox,
    MSelect,
    MButton,
  },

  model: {
    prop: 'filters',
    event: 'updateFilters',
  },

  props: {
    visible: Boolean,

    kind: { type: Object, default: null },
    state: { type: Object, default: null },
    user: { type: Object, default: null },
    mine: Boolean,

    personalCheckup: PersonalCheckupApi,
  },

  emits: ['openModal', 'onResetClick'],

  data () {
    return {
      states,
      kinds,
    }
  },

  computed: {
    showState () {
      return gon.application.entry_status || gon.application.entry_analysis_status
    },
  },
}
</script>
