<template>
  <si
    show-tooltips
    :class="{'si-left': displayTablet}"
  >
    <si-control
      :disabled="ordersLoading"
      @reset="$emit('reset-default-filters')"
    >
      <search-input
        :value.sync="filterState.selectedOrderId"
        :placeholder="t('order_number')"
        :debounce="400"
        class="search"
      />

      <date-range-picker
        v-model="filterState.selectedDateRange"
        class="si-control-item"
        :disabled="ordersLoading"
        :fallback-value="[]"
        :format="GET_LOCALIZATION_DATEPICKER_FORMAT"
      />

      <base-select
        v-model="filterState.selectedOrderPaidStatus"
        class="si-control-item"
        clearable
        reset-value="all"
        :disabled="ordersLoading"
      >
        <el-option
          value="all"
          :label="t('all_statuses')"
        />

        <el-option
          v-for="(orderPaidStatusTranslate, orderPaidStatus) in orderPaidStatuses"
          :key="orderPaidStatus"
          :value="orderPaidStatus"
          :label="orderPaidStatusTranslate"
        />
      </base-select>

      <template #refresh>
        <span />
      </template>
    </si-control>
    <slot />
  </si>
</template>

<script>
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import SearchInput from '@/vue_components/common/search_input.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'CompanyOrdersRegistryWorkspace',

  components: {
    BaseSelect,
    DateRangePicker,
    SearchInput,
    Si,
    SiControl,
  },
  props: {
    ordersLoading: {
      default: false,
      type: Boolean,
    },
    filterState: {
      default () {},
      type: Object,
    },
    displayTablet: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    orderPaidStatuses: Object.fromEntries(Object.entries(t('_order_statuses'))
      .filter(([ status ]) => status !== 'unknown_paid_status')),
  }),

  computed: {
    ...mapGetters(['GET_LOCALIZATION_DATEPICKER_FORMAT']),
  },
}
</script>
