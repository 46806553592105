<template>
  <input
    ref="inputPercent"
    class="form-control"
    type="text"
    v-bind="$attrs"
    v-on="$listeners"
  >
</template>

<script>
export default {
  name: 'PercentInput',
  mounted () {
    const inputPercent = $(this.$refs.inputPercent)

    inputPercent.inputmask('percentage', {
      rightAlign: false,
      radixFocus: false,
      autoUnmask: true,
      /**
       * Фиксит положение каретки при клике по инпуту,
       * иначе нельзя вводить сразу после клика.
       */
      placeholder: ' ',
    })

    inputPercent.on('input', (e) => {
      this.$emit('input', e.target.value)
    })
  },
}
</script>
