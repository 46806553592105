<template>
  <div class="m-time-picker-page flex flex-wrap gap-indent">
    <div class="flex-grow-1">
      <h1>MTimePicker</h1>
      <h2>Время</h2>

      <m-time-picker
        :start-time.sync="startTime"
        :time-range="false"
      />

      <h2>Интервал времени</h2>
      <m-time-picker
        :start-time.sync="startTimeInterval"
        :end-time.sync="endTime"
      />
    </div>

    <div class="flex-grow-1">
      <h1>Данные</h1>
      <h2>Основные пропсы</h2>
      <pre class="width-fit-content">
startTime: PropsTypes.String()
endTime: PropsTypes.String()
start: PropsTypes.String()  // по умолчанию '00:00'
step: PropsTypes.String()  // по умолчанию '00:15'
end: PropsTypes.String()  // по умолчанию '24:00'
timeRange: PropsTypes.Boolean(true)  //по умолчанию рисуется диапазон</pre>
    </div>
  </div>
</template>

<script>
import MTimePicker from '@/vue_present/_base/inputs/MTimeSelect/MTimeSelect.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'FrontBookPageMTimePicker',
  components: { MTimePicker },
  mixins: [
    ValidationParentMixin,
  ],

  data () {
    return {
      startTime: '08:00',
      startTimeInterval: '08:00',
      endTime: '19:00',
    }
  },
}
</script>
