export const EXPENSES_SOURCES = Object.freeze({
  FROM_ORDER: 'from_order',
  MANUAL: 'manual',
})

export const getExpensesSourcesList = () => {
  return Object.values(EXPENSES_SOURCES)
    .map((key) => ({
      id: key, title: t(key, { scope: 'finance.expensesSources' }),
    }))
}

export const EXPENSES_CATEGORIES = Object.freeze({
  5: 'good',
  4: 'entry',
  1: 'analysis',
})

export const getExpensesCategoriesList = () => {
  return Object.entries(EXPENSES_CATEGORIES)
    .map((entry) => ({
      id: entry[0], title: t(entry[1], { scope: 'finance.expensesCategories' }),
    }))
}

export const EXPENSES_CONSUMABLES = [
  { id: 'false', title: t('finance.expensesConsumables.false') },
  { id: 'true', title: t('finance.expensesConsumables.true') },
]
