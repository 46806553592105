<template>
  <div class="entry-types-selector-search">
    <search-select
      class="flex-grow-1 mr-5"
      :search-query.sync="searchQuery"
      :not-results="notResults"
      :allow-search="allowSearch"
      :loading="loading"
      :disabled="false"
      :placeholder="t('print_or_select_from_catalog')"
      value=""
      @change="onSelect($event)"
    >
      <el-option
        v-for="(item, index) in searchResultArray"
        :key="index"
        class="service-item"
        :label="item.title"
        :value="item"
      >
        <div class="service-item__number">
          <p class="cut">
            {{ item.number }}
          </p>
        </div>

        <div class="service-item__block">
          <div class="service-item__info">
            <div
              v-if="item.analysis_laboratory"
              class="service-item__lab"
            >
              {{ item.analysis_laboratory.title }}
            </div>
            <div class="service-item__title">
              {{ item.title }}
            </div>
          </div>
          <div class="service-item__price">
            {{ item.price | formatNumberToFixed(currencyParams) }}
          </div>
        </div>
      </el-option>
    </search-select>

    <button
      v-tooltip="t('add_from_catalog')"
      type="button"
      class="btn btn-default hidden-print btn-sm entry_type_selector_modal entry-types-selector-search__catalog-button catalog-button"
    >
      <i class="fad fa-fw fa-list entry_type_selector_icon catalog-button__icon" />
    </button>
  </div>
</template>

<script>
import SearchSelect from '@/vue_components/common/select/search_select/search_select'
import { currencyFormatter } from '@/vue_components/mixins/formatters'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { mapGetters } from 'vuex'
import { entryTypesEndpoint } from '@/api_entities/entry_types/entryTypesEndpoint'

export default {
  name: 'EntryTypesSelectorSearch',
  components: { SearchSelect },

  mixins: [currencyFormatter, SpinnerHolder],

  data () {
    return {
      searchQuery: '',
      searchResultArray: [],
    }
  },

  computed: {
    ...mapGetters({
      currencyParams: 'GET_LOCALIZATION_CURRENCY_PARAMS',
      clinicId: 'GET_APP_CONF_CURRENT_CLINIC_ID',
    }),

    notResults () {
      return Boolean(this.searchQuery && !this.searchResultArray.length)
    },

    allowSearch () {
      return this.searchQuery.length >= 3
    },
  },

  watch: {
    searchQuery (to) {
      if (to.length < 3) { return }
      this.getEntryTypes()
    },
  },

  mounted () {
    $('.entry-types-selector-search').entryTypeSelector({
      forCurrentClinic: true,
      onSelect: this.onSelect,
    })
  },

  methods: {
    getEntryTypes () {
      const params = {
        title: this.searchQuery,
        for_current_user: true,
      }

      const promise = entryTypesEndpoint.find(params)
        .then((data) => { this.searchResultArray = data })
        .catch(Utils.reportError('EntryTypesSelectorSearch:getEntryTypes()'))

      this.withSpinner(promise)
    },

    onSelect (item, data) {
      this.$emit('onSelect', item, data)
    },
  },
}
</script>
