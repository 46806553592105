import { cloneDeep } from 'lodash'
import { FastCreateApi } from '@/vue_apps/CheckupsModule/classes/FastCreate/FastCreateApi'
import { FastCreateClientLogic } from '@/vue_apps/CheckupsModule/classes/FastCreateClient/FastCreateClientLogic'
import { FAST_CREATE_STATUSES } from '@/vue_apps/CheckupsModule/store/personalCheckupFastCreate/fastCreateStatuses'
import { IFastCreateClient } from '@/vue_apps/CheckupsModule/interfaces/IFastCreateClient'

export class FastCreateLogic extends FastCreateApi {
  appendClient (clientProps: Partial<IFastCreateClient> = {}) {
    const key = this._getNextId()

    const pc = new FastCreateClientLogic(clientProps)

    pc.key = key

    this.clientData = {
      ...this.clientData,
      ...{ [key]: pc },
    }
  }

  copyClient ({ key, fields }: { key: number; fields: string[] }) {
    const client = this.clientData[key]

    const props = fields.reduce((acc, field) => {
      acc[field] = cloneDeep(client[field])

      return acc
    }, {})

    this.appendClient(props)
  }

  removeClient (keys: string[] = []) {
    keys.forEach((key) => {
      delete this.clientData[key]
    })

    this.clientData = { ...this.clientData }
  }

  _getClientsKeysByStatus () {
    const result = {
      [FAST_CREATE_STATUSES.NO_MATCHES_FOUND]: [],
      [FAST_CREATE_STATUSES.DATA_LOADED]: [],
      [FAST_CREATE_STATUSES.DATA_FOUND]: [],
      [FAST_CREATE_STATUSES.SEARCH_FOR_MATCHES]: [],
      [FAST_CREATE_STATUSES.DUPLICATE_FOUND]: [],
    }

    Object.values(this.clientData).forEach((client) => {
      result[client.status].push(client.key)
    })

    return result
  }

  async save () {
    await this._searchClients()
    await this._saveClients()
  }

  async _saveClients () {
    const clientsByStatus = this._getClientsKeysByStatus()

    // сохранить клиентов со статусом:
    // "совпадения не найдены" (серый значок),
    // "подставить данные" (желтый значок),
    // "данные загружены" (зелый значок)
    const keys = [
      ...clientsByStatus[FAST_CREATE_STATUSES.NO_MATCHES_FOUND],
      ...clientsByStatus[FAST_CREATE_STATUSES.DATA_LOADED],
      ...clientsByStatus[FAST_CREATE_STATUSES.DATA_FOUND],
    ]

    if (!keys.length) { return }

    await this._submit(keys)

    this.removeClient(keys)
  }

  async _searchClients () {
    const clientsByStatus = this._getClientsKeysByStatus()

    // найти клиентов со статусом "поиск совпадений" (синий значок)
    const keys = clientsByStatus[FAST_CREATE_STATUSES.SEARCH_FOR_MATCHES]

    if (!keys.length) { return }

    for (const key of keys) {
      await this.clientData[key].searchForMatchesStatus()
    }
  }
}
