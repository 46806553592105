import { ItemBase } from '@/_api/_classes/ItemBase'
import {
  CERTIFICATE_TYPE,
  extractClinicFromGon,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'
import { pick } from 'lodash'
import { snakeToCamel } from '@/_api/_requests/helpers'
import {
  TaxCertificate2024Client,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024Client'

/**
 * @implements ITaxCertificate2024Base
 */
export class TaxCertificate2024Base extends ItemBase {
  _permit = [
    'id',

    // Main block
    'period',
    'clinic',
    'client',
    'type',
    'individualEntrepreneur',
    'ignorePaymentsWithoutPrintedReceipts',

    // Second block
    'title',
    'inn',
    'kpp',
    'certificateNumber',
    'correctionNumber',
    'originCorrectionNumber',
    'reportingYear',
    'availableReportingYears',

    // Performing clinic
    'individualEntrepreneurFullName',

    'sumOne',
    'sumTwo',

    'dateOfSignatureByEmployee',

    'taxpayer',
    'employee',
    'taxpayerIsClient',
    'isCertificateLoaded',
    'disableTitleGeneration',
  ]

  id = null

  isCertificateLoaded = false

  // Main block

  period = []

  clinic = extractClinicFromGon(gon.application.current_clinic)

  client = new TaxCertificate2024Client()

  taxpayer = new TaxCertificate2024Client()

  type = CERTIFICATE_TYPE.PRIMARY

  certificates = []

  individualEntrepreneur = false

  ignorePaymentsWithoutPrintedReceipts = false

  // Second block
  title = null

  certificateNumber = null

  correctionNumber = null

  originCorrectionNumber = null

  reportingYear = null

  availableReportingYears = []

  // Performing clinic

  individualEntrepreneurFullName = {
    surname: null,
    name: null,
    secondName: null,
  }

  sumOne = 0

  sumTwo = 0

  employee = snakeToCamel(
    pick(
      gon.application.current_user,
      [
        'id',
        'name',
        'surname',
        'second_name',
        'short_name',
      ]
    )
  )

  representedPersons = null

  dateOfSignatureByEmployee = null

  taxpayerIsClient = true

  disableTitleGeneration = false

  constructor (props = {}) {
    super()
    this._fillProps(props)
  }
}
