export const dateNotInFutureValidator = (value: unknown) => {
  const errors = Utils.getValidationErrors(value)

  if (errors.length) { return errors }

  const now = moment()
  const date = moment(value)

  if (!date.isBefore(now)) {
    return [t('errors.dateCannotBeInFuture')]
  }

  return []
}
