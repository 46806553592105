import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class NestedItemsPresenter extends MPresenterBase {
  /**
   * @private
   */
  singularEntity

  adapter = {
    toServer: (data) => data,
    toClient: (data) => data,
  }

  /**
   * @param {INestedItemsPresenterConfig} config
   */
  constructor ({
    singularEntity,
    entity,
    routes,
    adapter,
    location = 'NestedItemsPresenter',
  }) {
    super({
      routes: {
        one: routes.one,
        all () {},
        list () {},
      },
      location,
      entity,
    })
    this.singularEntity = singularEntity

    if (!adapter) return

    if (adapter.toServer) {
      this.adapter.toServer = adapter.toServer
    }

    if (adapter.toClient) {
      this.adapter.toClient = adapter.toClient
    }
  }

  submit (data, config = {}) {
    return this._patch(data, config, 'updateMessage')
  }

  destroy (data, config = {}) {
    return this._patch(
      data,
      config,
      'destroyMessage',
      this.singularEntity
    )
  }

  _patch (data, config = {}, action, entity = this.entity) {
    const notification = new MRequestNotification(
      this.location,
      action,
      entity
    )

    return super.patch({
      url: this.routes.one(data.ownerId),
      data: JSON.stringify(camelToSnake(this.adapter.toServer(data))),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(this.adapter.toClient, notification))
      .catch(MRequestError.onRequest(notification))
  }
}
