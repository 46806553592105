import { CLINICS_CATALOG_ROUTES } from '@/vue_apps/catalogs_root/ClinicsCatalog/routes/clinicsCatalogRouteNames'
import ClinicsCatalogEditor from '@/vue_apps/catalogs_root/ClinicsCatalog/views/ClinicsCatalogEditor.vue'

const getDefaultRoute = (path = '*') => `/clinics-catalog${path}`

export const clinicsCatalogRoutes = [
  {
    path: getDefaultRoute('/:id/edit'),
    name: CLINICS_CATALOG_ROUTES.EDIT,
    component: ClinicsCatalogEditor,
    props: (route) => ({ id: +route.params.id }),
  },

  {
    path: getDefaultRoute('/new'),
    component: ClinicsCatalogEditor,
    name: CLINICS_CATALOG_ROUTES.NEW,
  },

  {
    path: getDefaultRoute('/'),
    name: CLINICS_CATALOG_ROUTES.SHOW,
  },
]
