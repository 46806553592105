<template>
  <modal
    :modal-visibility="visible"
    @close="modalClose"
  >
    <template #header>
      <i class="fad fa-fw fa-toggle-off" />
      <span>{{ modalTitle || T.introduction.module_not_connected }}</span>
    </template>

    <template #body>
      <div class="introduction-container">
        <div class="introduction-icon-container">
          <i
            class="module-disabled fad"
            :class="modalIcon"
          />
        </div>

        <div class="introduction-description-container">
          <div
            class="introduction-description"
            v-html="modalInner"
          />
        </div>
      </div>
    </template>

    <template #footer-left>
      <div
        v-if="requestButton"
        class="introduction-container"
      >
        <div class="introduction-request-button">
          <a
            class="btn btn-primary btn-with-icon"
            :href="modalButton.url || '/module_request'"
            target="_blank"
          >
            <i class="fad fa-fw fa-toggle-off btn-with-icon_icon" />
            <span>{{ modalButton.text || T.introduction.connect_module }}</span>
          </a>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
// 7jt1ev - create component
import Modal from '../../modal'

export default {
  name: 'IntroductionModal',
  components: {
    Modal,
  },
  props: {
    modalIcon: {
      type: String,
      default: '',
    },
    modalInner: {
      type: String,
      default: '',
    },
    modalTitle: {
      type: String,
      default: '',
    },

    requestButton: {
      type: Boolean,
      default: true,
    },

    modalButton: {
      type: Object,
      default () {
        return {
          text: '',
          url: '',
        }
      },
    },
  },
  data () {
    return {
      visible: false,
    }
  },
  methods: {
    modalClose () {
      this.visible = false
    },
    modalShow () {
      this.visible = true
    },
  },
}
</script>
