import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { buildGuaranteeLetterTitle } from '@/_api/MClient/MMedicalPoliceApi/MGuaranteeLetterApi/guaranteeLettersAdapter'

export class GuaranteeLetterListItem extends MListServiceItem {
  /**
   * @param {{
   *   id: number,
   *   issueDate: string,
   *   number: string,
   *   medicalPolicyId: number
   *   sum: number
   * }} data
   */
  constructor (data) {
    super()

    this.originalIssueDate = data.issueDate

    this.id = data.id
    this.issueDate = new MTableCell(vueFilters.date(data.issueDate))
    this.number = new MTableCell(data.number)
    this.sumCell = new MTableCell((Utils.toMoney(data.sum) && vueFilters.currency(data.sum)) || '-')

    this.medicalPolicyId = data.medicalPolicyId
    this.title = buildGuaranteeLetterTitle(data)
    this.sum = Utils.toMoney(data.sum)
  }
}
