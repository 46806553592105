import { Post } from '@/_api/decorators/api/crud/post'
import { Get } from '@/_api/decorators/api/crud/get'
import { Patch } from '@/_api/decorators/api/crud/patch'
import { Delete } from '@/_api/decorators/api/crud/delete'
import { Submit } from '@/_api/decorators/api/submit'
import { ClientAdapter } from '@/_api/decorators/api/adapters/clientAdapter'
import { List } from '@/_api/decorators/api/list'
import { ServerAdapter } from '@/_api/decorators/api/adapters/serverAdapter'
import { Notify } from '@/_api/decorators/api/notify'

export const Api = {
  get: Get,
  post: Post,
  patch: Patch,
  delete: Delete,

  submit: Submit,
  list: List,

  clientAdapter: ClientAdapter,
  serverAdapter: ServerAdapter,

  notify: Notify,
}
