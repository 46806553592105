import OrderPayment from '@/lib/payments/order.js'

export default class OrdersSumDistributor {
  constructor (summary, orders, surrender, deposit, attr) {
    this.summary = summary

    this.ordersToPay = [...orders].slice(0, -1)
    this.lastOrderToPay = [...orders].pop()

    this.attrPayment = attr
    this.surrender = surrender
    this.deposit = deposit

    this.payments = []
    this.paymentsNotValid = []
    this.paymentsValid = []
  }

  distribute () {
    this.ordersToPay.map((order) => {
      const unpaid = parseFloat(order.unpaid_sum)
      this._createPayment(order, {
        ...this.attrPayment,
        summaryInputUser: this.summary < unpaid ? this.summary : unpaid,
      })

      this.summary -= unpaid
    })

    this._createPayment(this.lastOrderToPay, {
      ...this.attrPayment,
      summaryInputUser: this.summary,
      surrender: this.surrender,
      deposit: this.deposit,
    })

    this._validationPayments()

    return this.paymentsValid
  }

  validationErrorPaymentsMap () {
    let ordersErrorValid = []

    for (const payment of this.paymentsNotValid) {
      for (const error of payment.errors) {
        ordersErrorValid.push(error)
      }
    }

    return ordersErrorValid
  }

  _createPayment (order, attr) {
    this.payments.push(new OrderPayment(order, attr))
  }

  _validationPayments () {
    this.payments.map((payment) =>
      payment.errors.length > 0
        ? this.paymentsNotValid.push(payment)
        : this.paymentsValid.push(payment),
    )
  }
}
