<template>
  <div class="base-header">
    <div class="base-header__info">
      <p>{{ headerClinicLegalName }}</p>

      <p>
        <span>{{ t$('headerClinicAddressTitle') }}</span>
        <span>{{ headerClinicAddress }}</span>
      </p>

      <p>
        <span>{{ t$('headerClinicContactsTitle') }}</span>
        <span>{{ headerClinicPhone }},</span>
        <span>{{ headerClinicEmail }}</span>
      </p>

      <p>
        <span>{{ t$('headerClinicLicenseTitle') }}</span>
        <span>{{ headerClinicLicense }},</span>
        <span v-if="headerClinicLicenseIssueDate">
          {{ t('date_rage_picker.from').toLowerCase() }}
          {{ headerClinicLicenseIssueDate }}
        </span>
      </p>
    </div>

    <div class="base-header__logo">
      <img
        :src="logoPath"
        alt=""
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'BaseHeader',

  mixins: [i18nScopeMixin],

  props: {
    headerClinicLegalName: { type: String, default: '' },
    headerClinicAddress: { type: String, default: '' },
    headerClinicPhone: { type: String, default: '' },
    headerClinicEmail: { type: String, default: '' },
    headerClinicLicense: { type: String, default: '' },
    headerClinicLicenseIssueDate: { type: String, default: '' },
    headerClinicId: { type: Number, default: null },
  },

  data () {
    return {
      logoPath: Routes.logo_clinic_path(this.headerClinicId),
    }
  },

  created () {
    this.setI18nScope('checkups.documents.baseDocument')
  },
})
</script>
