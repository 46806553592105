<template>
  <m-alert
    :type="iconObj.color"
    :closable="false"
    center
    class="semd-state-block"
  >
    <div class="flex align-center gap-indent-small mb-indent-small">
      <m-icon
        v-tooltip="iconObj.title"
        :icon="iconObj.icon"
        :custom-class="iconObj.color"
      />
      <span class="font-size-13"> {{ iconObj.title }} </span>
    </div>
  </m-alert>
</template>

<script>
import { defineComponent } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'
import { findSemdState } from '@/vue_apps/Semds/SemdModal/const/const.js'

export default defineComponent({
  name: 'SemdStateBlock',
  components: {
    MAlert,
    MIcon,
  },

  props: {
    state: { type: String, default: '' },
  },

  computed: {
    /**
     * @return {{
     *   id?: string,
     *   title: string,
     *   color: string,
     *   icon: string
     * }}
     */
    iconObj () {
      return findSemdState(this.state)
    },
  },
})
</script>
