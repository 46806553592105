<template>
  <div class="sidebar-filters">
    <div class="sidebar__filter">
      <date-range-filter
        :label="t('egisz.components.egisz_med_records.filters.period')"
        :format="dtFormat"
        :date-range="filters[date]"
        @input="vxSetFilterValue({ filter: date, value: $event })"
      />
    </div>

    <div class="sidebar__filter">
      <div class="filter">
        <label
          class="filter__label"
          @click="$refs.tree.modalOpen()"
        >
          <span class="label__text">{{ t('egisz.components.egisz_med_records.filters.specialty') }}</span>
        </label>

        <catalog-tree-modal
          id="nsi_specialty_id"
          ref="tree"
          class="filter__input"
          :tree-data="getSpecialties"
          :value="filters[nsiSpecialtyId]"
          clearable
          @update:value="vxSetFilterValue({ filter: nsiSpecialtyId, value: $event })"
        />
      </div>
    </div>

    <div class="sidebar__filter">
      <div class="filter">
        <select-filter
          :label="t('egisz.components.egisz_med_records.filters.doctor')"
          :items="getDoctors"
          :value="filters[authorId]"
          label-attribute="full_name"
          @change="vxSetFilterValue({ filter: authorId, value: $event })"
        />
      </div>
    </div>

    <div class="sidebar__filter">
      <div class="filter">
        <select-filter
          :label="t('egisz.components.egisz_med_records.filters.status')"
          :items="statuses"
          :value="filters[status]"
          :filterable="false"
          value-attribute="value"
          @change="vxSetFilterValue({ filter: status, value: $event })"
        />
      </div>
    </div>

    <egisz-table-filters
      @refresh="vxRefreshFilters"
      @reset="resetFilters"
    />

    <egisz-has-updates
      :watch-props="hasUpdatesProps"
      @refresh="vxRefreshFilters"
    />
  </div>
</template>

<script>
import DateRangeFilter from '@/vue_components/egisz/egisz_module/components/filters/DateRangeFilter'
import SelectFilter from '@/vue_components/egisz/egisz_module/components/filters/SelectFilter'
import EgiszTableFilters from '@/vue_components/egisz/egisz_module/components/egisz_table_fitlers'
import CatalogTreeModal from '@/vue_components/egisz/egisz_user_tab/components/CatalogTreeModal'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  getDefaultMedDocumentsFilters,
  MED_DOCUMENTS_FILTERS_FIELDS,
} from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import { EGISZ_MED_DOCUMENTS_STATUSES_LIST } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'
import EgiszHasUpdates from '@/vue_components/egisz/egisz_module/components/filters/EgiszHasUpdates'
import { ENTITIES, TRIGGERS_MAP } from '@/vue_components/store/modules/egisz/ws/egisz_ws'

export default {
  name: 'EgiszMedDocumentsFilters',
  components: { EgiszHasUpdates, CatalogTreeModal, EgiszTableFilters, SelectFilter, DateRangeFilter },

  data () {
    return {
      date: MED_DOCUMENTS_FILTERS_FIELDS.DATE,
      nsiSpecialtyId: MED_DOCUMENTS_FILTERS_FIELDS.NSI_SPECIALTY_ID,
      authorId: MED_DOCUMENTS_FILTERS_FIELDS.AUTHOR_ID,
      status: MED_DOCUMENTS_FILTERS_FIELDS.STATUS,
    }
  },

  computed: {
    ...mapGetters('egiszEmd/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapGetters({
      dtFormat: 'GET_LOCALIZATION_DATEPICKER_FORMAT',
    }),

    ...mapGetters('medCases', {
      getSpecialties: 'GET_SPECIALTIES',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctors: 'getDoctors',
    }),

    statuses () {
      return EGISZ_MED_DOCUMENTS_STATUSES_LIST
    },

    hasUpdatesProps () {
      return [
        TRIGGERS_MAP[ENTITIES.MED_DOCUMENT],
        TRIGGERS_MAP[ENTITIES.SIGNATURE],
      ]
    },
  },

  created () {
    this.loadCatalogs()
    this.resetFilters()
  },

  methods: {
    ...mapActions('medCases', {
      loadCatalogs: 'loadCatalogs',
    }),

    ...mapMutations('egiszEmd/filtersBase', {
      vxRefreshFilters: 'vxRefreshFilters',
      vxResetFilters: 'vxResetFilters',
      vxSetFilterValue: 'vxSetFilterValue',
    }),

    resetFilters () {
      this.vxResetFilters(getDefaultMedDocumentsFilters())
    },
  },
}
</script>
