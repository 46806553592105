<template>
  <label
    ref="el"
    :title="t('upload')"
    class="upload-button"
  >
    <i class="fad fa-upload up" />
    <div style="display:none">
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  name: 'UploadButton',
  mounted () {
    Utils.updateTooltipsInContainer(this.$refs.el.parentElement)
  },
}
</script>
