import {
  ICompanyFinalActWorkerWithCompletedCheckup,
} from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorkerWithCompletedCheckup'
import { ICompanyFinalActWorker } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorker'
import {
  ICompanyFinalActWorkerWithDiagnosisStringContraindicationFactors,
} from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActWorkerWithDiagnosis'
import { ICompanyFinalActDiseases } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActDiseasis'

export const getDefaultCompanyFinalActTableData = (tableName: string) => {
  const tables = {
    workersWithCompletedCheckupTableData: companyFinalActWorkerWithCompletedCheckup,
    workersWithUncompletedCheckupTableData: companyFinalActWorker,
    workersWithoutCheckupTableData: companyFinalActWorker,
    workersWithDiagnosesTableData: companyFinalActWorkerWithDiagnosisStringContraindicationFactors,
    chronicDiseasesListTableData: companyFinalActDiseases,
    professionalDiseasesListTableData: companyFinalActDiseases,
    infectiousDiseasesListTableData: companyFinalActDiseases,
  }

  return tables[tableName]
    ? { ...tables[tableName] }
    : {}
}

const companyFinalActWorkerWithCompletedCheckup: ICompanyFinalActWorkerWithCompletedCheckup = {
  fullName: '',
  sex: '',
  birthdate: '',
  department: '',
  result: '',
}

const companyFinalActWorker: ICompanyFinalActWorker = {
  fullName: '',
  sex: '',
  birthdate: '',
  department: '',
}

const companyFinalActWorkerWithDiagnosisStringContraindicationFactors:
  ICompanyFinalActWorkerWithDiagnosisStringContraindicationFactors = {
    fullName: '',
    sex: '',
    birthdate: '',
    department: '',
    position: '',
    contraindicationFactors: '',
  }

const companyFinalActDiseases: ICompanyFinalActDiseases = {
  codeString: '',
  title: '',
  chronic: false,
  diagnosableId: 0,
  diagnosableType: '',
  diseaseId: 0,
  firstTime: false,
  id: 0,
  occupationalIllness: false,
  text: '',
}
