/**
 * @typedef {function(T): boolean} BooleanFunction<T>
 * @typedef {{valid: boolean, name: string}} ValidationResult
 * @typedef {function(T): ValidationResult} ValidationRule<T=*>
 * @typedef {function(*=): string[]} Validator
 */

/**
 * Создаёт валидатор с набором правил, который можно переиспользовать.
 *
 * @param {ValidationRule<any>[]} rules Массив правил валидации
 * @param {Record<string, string>} messages Словарь ошибок валидации
 * @return {Validator}
 */
export const createValidator = (rules, messages) => {
  return (value) => validate(value, rules, messages)
}
/**
 * Выполняет правила над переданным значением и сопоставляет найденные ошибки
 * сообщениям из messages.
 *
 * @param {any} value Обрабатываемое значение
 * @param {ValidationRule<any>[]} rules Массив правил валидации
 * @param {Record<string, string>} messages Словарь ошибок валидации
 * @return {string[]} массив сообщений
 */
export const validate = (value, rules, messages) => {
  return rules
    .map((rule) => rule(value))
    .filter((result) => !result.valid)
    .map(({ name }) => messages[name])
}

/**
 * Создаёт правило, которое можно использовать для создания валидатора.
 *
 * @template T
 * @param name
 * @param {BooleanFunction<T>} fn
 * @return {ValidationRule<T>}
 */
export const rule = (name, fn) => {
  return function (value) {
    return { name, valid: fn(value) }
  }
}
/**
 * Создаёт алиас для правила. Можно использовать для создания правила с конкретным
 * значением параметра.
 * Для правил, состоящих из нескольких других правил, нужно использовать `rule()`
 *
 * @example
 * const max = (maxValue) => rule('max', (value) => value <= max)
 * const max12 = alias('max12', max(12))
 * const validator = createValidator([max12], { max12: 'Should be less or equal to 12' })
 *
 * @param {string} newName
 * @param {ValidationRule<any>} existingRule
 */
export const alias = (newName, existingRule) => rule(newName, (value) => existingRule(value).valid)
