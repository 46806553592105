<template>
  <reusable-local-n-s-i-list
    :value="value"
    :label="t('semds.semd119.formTitles.confidentialityLevel')"
    dictionary="privacy_levels"
    :disabled="disabled"
    required
    validator-name="privacy_level"
    full-width
    @registerValidator="$emit('registerValidator', $event)"
    @changeByLazy="changeConfidentialityLevel"
    @syncItemsStored="setDefaultConfidentialityLevelIfNotSelected"
  />
</template>

<script>
import { defineComponent } from 'vue'
import ReusableLocalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableLocalNSIList.vue'
import { getDefaultConfidentialityLevel } from '@/vue_apps/Semds/SemdModal/const/getDefaultConfidentialityLevel'

export default defineComponent({
  name: 'SemdPrivacyLevel',
  components: { ReusableLocalNSIList },
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: Object, default: null },
    disabled: Boolean,
  },

  emits: [
    'change',
    'registerValidator',
    'registerSetDefaultPrivacyLevelHook',
  ],

  data () {
    return {
      privacyLevels: [],
    }
  },

  created () {
    const vm = this
    this.$emit('registerSetDefaultPrivacyLevelHook', vm.setDefaultConfidentialityLevelIfNotSelected)
  },

  methods: {
    changeConfidentialityLevel (privacyLevel) {
      this.$emit('change', privacyLevel)
    },

    setDefaultConfidentialityLevelIfNotSelected (levels = this.privacyLevels || []) {
      this.privacyLevels = levels

      if (this.value) { return }

      const defaultLevel = getDefaultConfidentialityLevel(levels)
      if (!defaultLevel) { return }

      this.changeConfidentialityLevel(defaultLevel)
    },
  },
})
</script>
