import { isArray } from '@/helpers/typeofHelpers'

// поддерживает только одномерный вложенный массив в объекте

export const objectToFormData = (obj: object) => {
  const formData = new FormData()

  Object.entries(obj).forEach(([key, value]) => {
    if (isArray(value)) {
      return appendArrayToFormData(
        formData,
        `${key}[]`,
        value
      )
    }

    formData.append(key, obj[key])
  })

  return formData
}

const appendArrayToFormData = (
  formData: FormData,
  key: string,
  arr: unknown[]
) => {
  arr
    .forEach((value) =>
      formData.append(key, value as string))
}
