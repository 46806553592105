import { ClientsListItem } from '@/vue_apps/ClientsModule/components/ClientsList/store/ClientsListItem'
import { DEFAULT_TOTAL_ITEMS, DEFAULT_TOTAL_PAGES } from '@/vue_components/store/modules/filters_base'

export const clientLegacyAdapter = {
  defaultList () {
    return {
      data: [],
      totalItems: DEFAULT_TOTAL_ITEMS,
      totalPages: DEFAULT_TOTAL_PAGES,
    }
  },

  toClient (response) {
    const data = response.data || []

    return {
      data: data.map((client) => new ClientsListItem(client)),
      totalItems: data.length,
      totalPages: response.totalPages,
    }
  },
}
