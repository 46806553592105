import {
  CheckupEntryTypeListItemRow,
} from '@/vue_apps/catalogs_root/CheckupCatalogs/classes/CheckupEntryTypeListItemRow'
import {
  EntryTypesClinicsCabinetsAttributes,
} from '@/vue_apps/catalogs_root/CheckupCatalogs/api/EntryTypesClinicsCabinetsAttributes'

export const checkupCatalogAdapter = {
  toClient (response) {
    response.data = response.data.map((item) => new CheckupEntryTypeListItemRow(item))

    return response
  },

  /**
   * @param {{
   *   id: number,
   *   text: string,
   *   clinicCabinetsMap: Record<number, { id: number, title: string }>
   *   defaultClinicCabinetsMap: Record<number, { id: number, title: string }>
   * }} data
   */
  toServer ({
    id,
    text,
    clinicCabinetsMap,
    defaultClinicCabinetsMap,
  }) {
    const entryTypesClinicsCabinetsAttributes =
        new EntryTypesClinicsCabinetsAttributes(clinicCabinetsMap, defaultClinicCabinetsMap)

    return {
      entryType: {
        text,
        entryTypesClinicsCabinetsAttributes: entryTypesClinicsCabinetsAttributes.get(),
      },
    }
  },
}
