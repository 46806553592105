<template><span /></template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ISemdSource } from '@/vue_apps/Semds/interfaces/ISemdSource'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export declare type TSemdConnectorReturn = Promise<{ cancel: boolean }>

export declare type TSemdChecker = (source: ISemdSource, semds: SemdListItem[]) => TSemdConnectorReturn

export declare type TSemdActionChecker =
    (source: ISemdSource, semds: SemdListItem[]) => TSemdConnectorReturn

export declare type TSemdsSourceConnectorAPIParams = {
  beforeChecks?: TSemdChecker
}

export declare interface ISemdsSourceConnectorAPI {
  isLastSemdOutdated: () => Boolean
  isLastSemdSigned: () => Boolean
  isLastSemdSuccessfullySent: () => Boolean
  isLastSemdRegisteredInEgisz: () => Boolean

  beforeEdit?: (params: TSemdsSourceConnectorAPIParams) => TSemdConnectorReturn
}

export default defineComponent({
  name: 'SemdsSourceConnector',
  props: {
    source: { type: Object as PropType<ISemdSource>, required: true },
    semds: { type: Array as PropType<SemdListItem[]>, required: true, default: () => [] },
  },

  emits: [
    'registerApi',
  ],

  computed: {
    lastSemd (): SemdListItem {
      return this.semds[0] || null
    },
  },

  created () {
    this.$emit('registerApi', this.__getApi.bind(this)())
  },

  methods: {
    /**
     * отловить событие: @registerApi="connectorApi = $event"
     * положить в data компонента родителя: connectorApi: {} as ISemdsSourceConnector,
     */
    __getApi (): ISemdsSourceConnectorAPI {
      return {
        isLastSemdOutdated: () => this.lastSemd?.isOutdatedVersion() || false,
        isLastSemdSigned: () => this.lastSemd?.isSigned() || false,
        isLastSemdSuccessfullySent: () => this.lastSemd?.isSuccessFullySent() || false,
        isLastSemdRegisteredInEgisz: () => this.lastSemd?.isRegisteredInEgisz() || false,
        beforeEdit: this.beforeEdit,
      }
    },

    async __confirmNewSemd (): TSemdConnectorReturn {
      const { cancel } = await MConfirm.warning(window.t('semds.confirms.successfullySent'))
      if (cancel) { return { cancel } }

      this.source.setValue('newSemdApproved', true)

      return { cancel: false }
    },

    async __confirmResetSign (): TSemdConnectorReturn {
      const { cancel } = await MConfirm.warning(window.t('semds.confirms.oldSigned'))
      if (cancel) { return { cancel } }

      this.source.setValue('newSignatureApproved', true)

      return { cancel: false }
    },

    /**
     * Базовая БЛ для SemdSource (протокол, медзаключение ЛЭМК и т.д.):
     * 1) устаревший сэмд --> пропускаем
     * 2) сэмд отправлен --> подтверждаем новый сэмд
     * 3) у сэмд есть подпись --> подтверждаем изменение source
     *
     * При необходимости подтверждения можно переопределить методами:
     * 1) lastSemdOutdatedCheck
     * 2) newSemdApprovedCheck
     * 3) newSignatureApprovedCheck
     */
    async beforeEdit (params?: TSemdsSourceConnectorAPIParams): Promise<{ cancel: boolean }> {
      if (params?.beforeChecks) {
        return await params.beforeChecks(this.source, this.semds)
      }

      if (!this.lastSemd) { return { cancel: false } }

      if (this.lastSemd.isOutdatedVersion()) {
        return { cancel: false }
      }

      if (this.lastSemd.isRegisteredInEgisz()) {
        return await this.__confirmNewSemd()
      }

      if (this.lastSemd.isSuccessFullySent()) {
        window.Notificator.warning(window.t('semds.errors.successfullySentSemdsExists'))

        return { cancel: true }
      }

      if (this.lastSemd.isSigned()) {
        return await this.__confirmResetSign()
      }

      return { cancel: false }
    },
  },
})
</script>
