/**
 * @param {String|Number|Boolean|Array|Object|Function|*} propType
 * @param {null|*} value
 * @param {Boolean} required
 * @param {Function|undefined} validator
 * @returns {{default, validator: undefined, type, required}}
 */
const propsType = (propType, value = null, required = false, validator = undefined) => ({
  type: propType,
  default: value,
  required,
  validator,
})
/**
 * @deprecated
 */
export const PropsTypes = {
  Custom: propsType,

  /**
   * @param {String} value
   * @param {Boolean} [required]
   * @param {Function} [validator]
   * @returns {{default, validator: undefined, type, required}}
   * @constructor
   * @deprecated
   */
  String: (value = null, required = false, validator = undefined) =>
    propsType(String, value, required, validator),

  /**
   * @param {Number} value
   * @param {Boolean} [required]
   * @param {Function} [validator]
   * @returns {{default, validator: undefined, type, required}}
   * @constructor
   * @deprecated
   */
  Number: (value = null, required = false, validator = undefined) =>
    propsType(Number, value, required, validator),

  /**
   * @param {Boolean} value
   * @param {Boolean} [required] -- использовать только в случае, когда default: true
   * @param {Function} [validator]
   * @returns {{default, validator: undefined, type, required}}
   * @deprecated
   * @constructor
   */
  Boolean: (value = false, required = false, validator = undefined) =>
    propsType(Boolean, value, required, validator),

  /**
   * @param {Array} value
   * @param {Boolean} [required]
   * @param {Function} [validator]
   * @returns {{default, validator: undefined, type, required}}
   * @constructor
   * @deprecated
   */
  Array: (value = [], required = false, validator = undefined) =>
    propsType(Array, () => value, required, validator),

  /**
   * @param {Object} value
   * @param {Boolean} [required]
   * @param {Function} [validator]
   * @returns {{default, validator: undefined, type, required}}
   * @constructor
   * @deprecated
   */
  Object: (value = null, required = false, validator = undefined) =>
    propsType(Object, () => value, required, validator),

  /**
   * @param {Function} value
   * @param {Boolean} [required]
   * @param {Function} [validator]
   * @returns {{default, validator: undefined, type, required}}
   * @constructor
   * @deprecated
   */
  Function: (value = null, required = false, validator = undefined) =>
    propsType(Function, value, required, validator),
}
