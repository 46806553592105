import { request } from '@/lib/transport/request'

export const mkb10Endpoint = {
  name: 'mkb10Endpoint',
  get (id) {
    const options = {
      type: 'get',
      url: Routes.catalogs_diseases_by_id_path(),
      data: { id },
    }

    return request(options).promise
  },

  find (title) {
    const options = {
      type: 'get',
      url: Routes.catalogs_diseases_path(),
      data: { title },
    }

    return request(options).promise
  },
}
