import { cloneDeep } from 'lodash'
import { camelToSnake } from '@/_api/_requests/helpers'

export const companyCreateAdapter = {
  toServer (company) {
    const __company = cloneDeep(company)

    __company.registrationDate = Utils.getBaseFormattedDate(__company.registrationDate)

    if (__company.addressMatch) {
      __company.addressFact = __company.addressFormal
    }

    __company.companyTypeIds = (__company.companyType || []).map((item) => item.id)

    __company.headOperatesBy = __company.operatesBy
    delete __company.operatesBy

    const snaked = camelToSnake(__company)

    snaked.phone1 = Services.phoneMask.removeMask(snaked.phone1)
    snaked.phone2 = Services.phoneMask.removeMask(snaked.phone2)

    return snaked
  },
}
