<template>
  <div class="feedback-filters hidden-print">
    <!-- Дата-кнопки -->
    <m-date-picker-buttons
      :value="filters.period"
      :m-fixed-height="false"
      :use-omit="['Tomorrow']"
      value-format="yyyy-MM-dd"
      @change="onFilterChange('period', $event)"
    />

    <!-- Дата-пикер -->
    <m-date-picker
      :value="filters.period"
      type="daterange"
      value-format="yyyy-MM-dd"
      :m-fixed-height="false"
      @change="onFilterChange('period', $event)"
    />

    <!-- Селектор клиник -->
    <reusable-doctor-clinics-list
      :value="filters.clinic"
      class="feedback-filters feedback-filters__filter-clinic hidden-print"
      :m-fixed-height="false"
      @onClinicChange="onFilterChange('clinic', $event)"
    />

    <!-- Селектор специальностей -->
    <reusable-specialties-list
      v-if="isMarketingPage"
      :value="filters.specialty"
      class="feedback-filters feedback-filters__filter-specialty hidden-print"
      :m-fixed-height="false"
      :placeholder="t('all_specialties')"
      filterable
      @onSpecialtyChange="onFilterChange('specialty', $event)"
    />

    <!-- Селектор врачей -->
    <reusable-doctors-list
      :value="filters.doctor"
      class="feedback-filters feedback-filters__filter_doctor hidden-print"
      :m-fixed-height="false"
      :placeholder="t('all_doctors')"
      @onDoctorChange="onFilterChange('doctor', $event)"
    />

    <!-- Селектор статусов -->
    <m-select
      v-if="isMarketingPage"
      :value="filters.statuses"
      :label="t('status')"
      class="feedback-filters feedback-filters__filter-status"
      :placeholder="t('all_statuses')"
      :items="DEFAULT_SELECTOR_FEEDBACKS_STATUSES_LABELS"
      :m-fixed-height="false"
      @change="onFilterChange('statuses', $event)"
    />

    <!-- Переходы на сторонние сайты -->
    <m-select
      v-if="isMarketingPage"
      :items="FEEDBACKS_EXTERNAL_LINK_CLIKS_FILTER_ITEMS"
      :value="filters.withClicks"
      :label="t('feedback.tableTitles.withClicks')"
      :placeholder="t('chooseValue')"
      class="feedback-filters__external-links"
      :m-fixed-height="false"
      clearable
      @change="onFilterChange('withClicks', $event)"
    />

    <!-- Оценка клиники -->
    <m-range
      :value="filters.clinicRate"
      class="feedback-filters feedback-filters__filter-clinic-rate"
      @input="onFilterChange('clinicRate', $event)"
    >
      <template #header>
        {{ t('feedback.tableTitles.clinicScore') }}
      </template>
    </m-range>

    <!-- Оценка врача -->
    <m-range
      :value="filters.doctorRate"
      class="feedback-filters feedback-filters__filter-doctor-rate"
      @input="onFilterChange('doctorRate', $event) "
    >
      <template #header>
        {{ t('feedback.tableTitles.doctorScore') }}
      </template>
    </m-range>

    <m-si-generator-filters-buttons
      @onRefreshClick="refreshData"
      @onResetClick="resetData"
    />
  </div>
</template>

<script>
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'
import ReusableDoctorClinicsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorClinicsList.vue'
import { MSpecialtyPresenter } from '@/_api/MSpecialty/MSpecialtyPresenter'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MDatePickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { MListService } from '@/_api/_requests/MListService'
import MRange from '@/vue_present/_base/MRange/MRange.vue'
import ReusableSpecialtiesList from '@/vue_present/Reuse/Lists/Specialties/ReusableSpecialtiesList.vue'
import {
  DEFAULT_SELECTOR_FEEDBACKS_STATUSES_LABELS,
  FEEDBACKS_EXTERNAL_LINK_CLIKS_FILTER_ITEMS,
} from '@/vue_apps/FeedbackModule/const/const'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'

const CLIENTS_FEEDBACKS_CONTROLLER_NAME = 'client_feedbacks/feedbacks'

export default {
  name: 'FeedbackFilters',
  components: {
    MSelect,
    ReusableSpecialtiesList,
    MRange,
    MDatePicker,
    MDatePickerButtons,
    ReusableDoctorsList,
    ReusableDoctorClinicsList,
    MSiGeneratorFiltersButtons,
  },

  props: {
    list: MListService,
    hideSpecialtyAndStatusesFilter: Boolean,
  },

  data () {
    return {
      specialtiesPresenter: new MSpecialtyPresenter(),
      DEFAULT_SELECTOR_FEEDBACKS_STATUSES_LABELS,
      FEEDBACKS_EXTERNAL_LINK_CLIKS_FILTER_ITEMS,
    }
  },

  computed: {
    filters () {
      return this.list.filtersData
    },

    isMarketingPage () {
      return gon.page.controller === CLIENTS_FEEDBACKS_CONTROLLER_NAME
    },
  },

  methods: {
    refreshData () {
      this.list.refreshWithFetch()
    },

    resetData () {
      this.list.resetWithFetch()
    },

    onFilterChange (name, value) {
      this.list.setFilterValue(name, value)
      this.$emit('onFilterChange')
    },
  },
}
</script>
