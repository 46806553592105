import { mapActions } from 'vuex'

import BalancePayment from '@/lib/payments/balance.js'
import RefundPayment from '@/lib/payments/refund.js'
import { DEFAULT_FILTER_STATE, DEFAULT_VALIDATION_FORM_CASH_IN, DEFAULT_VALIDATION_FORM_CASH_OUT } from './consts.js'
import { PAYMENT_SPECIES } from '../consts.js'
import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { PAYMENT_PARAMS } from '@/lib/payments/consts.js'
import { slicedList } from '../../helpers/sliced_list.js'
import { paymentsEndpoint } from '@/api_entities/payments/payments_endpoint'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'
import { orderRegistriesEndpoint } from '@/api_entities/companies/order_registries_endpoint'

export default {
  ...mapActions(['LOAD_COMPANY']),

  paymentsToMap (map, payments) {
    for (const payment of payments) {
      this.$set(map, payment.id, payment)
    }
  },

  mapEmpty (map) {
    return !Object.keys(map).length
  },

  objArrEqMap (array, map, mapKey = 'id') {
    return array.every((item) => item[mapKey] in map)
  },

  resetFilters () {
    this.filterState = { ...DEFAULT_FILTER_STATE }
    this.fetched.payments.paymentsMap = Object.create(null)
  },

  defaultValidationFormMassRefund () {
    return {
      paymentsErrorCodes: [],
      paymentsErrorValid: [],
    }
  },

  slicedPayments () {
    const { payments, payments: { pagination } } = this.fetched
    payments.sliced = slicedList(payments.list, pagination, PAGINATION_PAGE_SIZE)
  },

  paymentRegistryById (payment) {
    if (payment.destination_order) {
      const registry = this.fetched.registries.list.find((r) => r.id === payment.destination_order.orders_registry_id)

      return registry ? registry.title : t('without_registry')
    }

    return '-'
  },

  paymentIconKind (kind) {
    return this.kindIcons[kind]
  },

  clearSelectRegistry () {
    this.filterState.selectedRegistryId = DEFAULT_FILTER_STATE.selectedRegistryId
    this.fetched.registries.sliced = []
  },

  defaultFormCashIn () {
    return {
      byCash: 0,
      byCard: 0,
      byCashless: 0,
      comment: '',
    }
  },

  defaultFormCashOut () {
    return {
      paymentSpec: [PAYMENT_SPECIES.BY_CASH],
      summaryInputUser: 0,
      comment: '',
    }
  },

  defaultFormMassRefund () {
    return { comment: '' }
  },

  fetchPayments () {
    this.fetched.payments.error = false
    this.fetched.payments.loading = true

    companiesEndpoint.getPayments(this.$route.params.companyId, this.reqPaymentsParams)
      .then((response) => {
        const { payments } = this.fetched
        payments.error = false
        payments.list = [...response]
      })
      .catch(() => {
        this.fetched.payments.error = true
      })
      .finally(() => {
        this.fetched.payments.loading = false
      })
  },

  fetchRegistries () {
    this.fetched.registries.error = false
    this.fetched.registries.loading = true

    orderRegistriesEndpoint.find(this.$route.params.companyId, { search_query: '' })
      .then((response) => {
        this.fetched.registries.list = [...response]
      })
      .catch((e) => {
        this.fetched.registries.error = true
      })
      .finally(() => {
        this.fetched.registries.loading = false
      })
  },

  findRegistries () {
    this.fetched.registries.error = false
    this.fetched.registries.loading = true

    orderRegistriesEndpoint.find(this.$route.params.companyId, this.reqRegistriesParams)
      .then((response) => {
        this.fetched.registries.sliced = [...response]
      })
      .catch((e) => {
        this.fetched.registries.error = true
      })
      .finally(() => {
        this.fetched.registries.loading = false
      })
  },

  signPayment (number, kind) {
    const num = parseFloat(number)
    if ((kind === 'cash_out' || kind === 'order_refund') && num !== 0) return -Math.abs(num)

    return num
  },

  closeModalCashIn () {
    this.modal.paymentCashIn.visibility = false
    this.clearFormCashIn()
  },

  closeModalCashOut () {
    this.modal.paymentCashOut.visibility = false
    this.clearFormCashOut()
  },

  closeModalMassRefund () {
    this.modal.massRefund.visibility = false
    this.clearFormMassRefund()
  },

  clearFormCashIn () {
    this.modal.paymentCashIn.form = { ...this.defaultFormCashIn() }
    this.modal.paymentCashIn.validationMessages = { ...DEFAULT_VALIDATION_FORM_CASH_IN }
  },

  clearFormCashOut () {
    this.modal.paymentCashOut.form = { ...this.defaultFormCashOut() }
    this.modal.paymentCashOut.validationMessages = { ...DEFAULT_VALIDATION_FORM_CASH_OUT }
  },

  clearFormMassRefund () {
    this.modal.massRefund.form = { ...this.defaultFormMassRefund() }
    this.modal.massRefund.validationMessages = { ...this.defaultValidationFormMassRefund() }
  },

  reqFormCashInPayment () {
    const { byCash, byCard, byCashless, comment } = this.modal.paymentCashIn.form

    const payment = new BalancePayment({
      baseKind: PAYMENT_PARAMS.BASE_KIND.FUND,
      kind: PAYMENT_PARAMS.KIND_PAYMENT.CASH_IN,
      clinicId: this.GET_APP_CONF_CURRENT_CLINIC_ID,
      userId: this.GET_APP_CONF_CURRENT_USER_ID,
      payerCompanyId: this.$route.params.companyId,
      byCard,
      byCash,
      byCashless,
      comment,
    })

    return { payment }
  },

  reqFormCashOutPayment () {
    const { summaryInputUser, comment } = this.modal.paymentCashOut.form

    const payment = new BalancePayment({
      baseKind: PAYMENT_PARAMS.BASE_KIND.REFUND,
      kind: PAYMENT_PARAMS.KIND_PAYMENT.CASH_OUT,
      clinicId: this.GET_APP_CONF_CURRENT_CLINIC_ID,
      userId: this.GET_APP_CONF_CURRENT_USER_ID,
      payerCompanyId: this.$route.params.companyId,
      paymentSpec: this.paymentSpecCashOut,
      summaryInputUser,
      comment,
    })

    return {
      refund_to: this.paymentSpecCashOut,
      payment,
    }
  },

  createCashInPayment () {
    this.modal.paymentCashIn.loading = true
    paymentsEndpoint.create(this.reqFormCashInPayment())
      .then((response) => {
        this.closeModalCashIn()
        this.fetchPayments()
        this.LOAD_COMPANY(this.$route.params.companyId)
        Notificator.success(t('cash_in_balance'))
      })
      .catch(() => {
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.modal.paymentCashIn.loading = false
      })
  },

  createCashOutPayment () {
    this.modal.paymentCashOut.loading = true
    paymentsEndpoint.create(this.reqFormCashOutPayment())
      .then((response) => {
        this.closeModalCashOut()
        this.fetchPayments()
        this.LOAD_COMPANY(this.$route.params.companyId)
        Notificator.success(t('cash_out_balance'))
      })
      .catch(() => {
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.modal.paymentCashOut.loading = false
      })
  },

  generateHashMap (payments) {
    const paymentsHash = Object.create(null)

    payments.map((payment) => {
      payment.date = new Date().toISOString()
      paymentsHash[payment.refundable_id] = payment
    })

    return paymentsHash
  },

  createRefundPayments () {
    this.modal.massRefund.loading = true
    const payments = []
    this.selectedPaymentsToRefund.map((payment) => {
      const p = new RefundPayment(payment, {
        baseKind: PAYMENT_PARAMS.BASE_KIND.REFUND,
        distribution: PAYMENT_PARAMS.DISTRIBUTION.AUTO_DISTRIBUTION,
        clinicId: this.GET_APP_CONF_CURRENT_CLINIC_ID,
        userId: this.GET_APP_CONF_CURRENT_USER_ID,
        payerCompanyId: this.$route.params.companyId,
        byBalance: payment.by_balance,
        byCard: payment.by_card,
        byCash: payment.by_cash,
        byCashless: payment.by_cashless,
        byCredit: payment.by_credit,
        deposit: payment.deposit,
      })

      payments.push(p)
    })

    const paymentsHash = this.generateHashMap(payments)

    paymentsEndpoint.createBulk({
      user_id: this.GET_APP_CONF_CURRENT_USER_ID,
      payer_type: PAYMENT_PARAMS.PAYER_TYPE.COMPANY,
      payer_id: parseInt(this.$route.params.companyId),
      payments: JSON.stringify(paymentsHash),
    })
      .then((response) => {
        if (response.length === 0) {
          this.LOAD_COMPANY(this.$route.params.companyId)
          this.fetched.payments.paymentsMap = []
          this.fetchPayments()
          Notificator.success(t('payments_refund_success'))
          this.closeModalMassRefund()
        } else {
          response.map((err) => {
            this.modal.masspay.validationMessages.ordersErrorCodes.push(err.code)
            this.modal.masspay.validationMessages.ordersErrorValid.push({
              ...err.messages,
            })
          })
        }
        this.modal.massRefund.disabled = true
      })
      .catch(() => {
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.modal.massRefund.loading = false
      })
  },

  alertClose () {
    this.modal.massRefund.alert.visibility = false
    this.modal.massRefund.validationMessages = {
      ...this.modal.massRefund.validationMessages,
      paymentsErrorValid: [],
      paymentsErrorCodes: [],
    }
  },
}
