import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { MListServiceNestedItemsItem } from '@/_api/_requests/MListServiceNestedItemsItem'
import {
  IPersonalCheckupResearchResultsItem,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupResearchResults/IPersonalCheckupResearchResultsItem'
import {
  PERSONAL_CHECKUP_INSTRUMENTAL_RESEARCH_PATHOLOGY_FOUND_NSI_CODE,
  PERSONAL_CHECKUP_LABORATORY_RESEARCH_INFECTION_FOUND_NSI_CODE,
} from '@/vue_apps/CheckupsModule/store/personalCheckupResearchResults/personalCheckupResearchResultsConsts'

const RESEARCH_BAD_RESULT_NSI_CODE = [
  PERSONAL_CHECKUP_INSTRUMENTAL_RESEARCH_PATHOLOGY_FOUND_NSI_CODE,
  PERSONAL_CHECKUP_LABORATORY_RESEARCH_INFECTION_FOUND_NSI_CODE,
]

export class PersonalCheckupResearchResultsItemListItem extends MListServiceNestedItemsItem {
  date: MTableCell

  research: INsiEntry

  researchTitle: MTableCell

  medicalAssessment: INsiEntry

  medicalAssessmentTitle: MTableCell

  constructor (id: number, item: IPersonalCheckupResearchResultsItem) {
    super(id, item)

    this.date = new MTableCell(vueFilters.date(item.date))
      .addTooltip(true)

    this.research = item.research

    this.researchTitle = new MTableCell(item.research.title)
      .addTooltip(true)
      .addCut()

    this.medicalAssessment = item.medicalAssessment

    this.medicalAssessmentTitle = new MTableCell(item.medicalAssessment.title)
      .addClasses(
        RESEARCH_BAD_RESULT_NSI_CODE.includes(+this.medicalAssessment.nsiId)
          ? 'warning'
          : 'success'
      )
      .addTooltip(true)
      .addCut()
  }
}
