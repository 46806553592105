<template>
  <m-panel
    class="payments-page"
    icon="payment"
    :title="panelTitle"
  >
    <!-- Список платежей -->
    <m-si-generator
      v-if="canView"
      :items="paymentsList.data"
      :si-generator-schema="paymentsSchema"
      :current-page="paymentsList.currentPage"
      :page-count="paymentsList.totalPages"
      :search-query="paymentsList.searchQuery"
      :total="paymentsList.total"
      :search-placeholder="t('finance.searchPlaceholderPayments')"
      @onItemClick="onItemClick"
      @update:currentPage="paymentsList.fetchPage($event)"
      @update:searchQuery="paymentsList.search($event)"
    >
      <template #filters>
        <!-- Фильтры -->
        <payments-filters :list="paymentsList" />
      </template>
    </m-si-generator>

    <introduction
      v-else
      :introduction-text="t('introduction.not_authorized')"
      hide-button
      icon="fad fa-money-bill-wave-alt"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getPaymentsSchema } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getPaymentsSchema'
import PaymentsFilters from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/PaymentsFilters.vue'
import { PaymentsList } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentsList'
import {
  getDefaultPaymentsFilters,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getDefaultPaymentsFilters'
import Introduction from '@/vue_components/introduction/introduction.vue'

export default {
  name: 'PaymentsPage',
  components: { Introduction, PaymentsFilters, MSiGenerator, MPanel },

  data () {
    return {
      paymentsList: new PaymentsList(getDefaultPaymentsFilters()),
      paymentsSchema: getPaymentsSchema(),
    }
  },

  computed: {
    panelTitle () {
      return `${t('finance.payments')} (${this.paymentsList.totalItems})`
    },

    canView () {
      return Services.security.canViewPayment
    },
  },

  created () {
    this.listInit()
  },

  methods: {
    listInit () {
      this.paymentsList.fetchAll()
    },

    onItemClick ({ id }) {
      Turbolinks.visit(`/payments/${id}`)
    },

    onSearchQueryUpdate (searchQuery) {
      if (searchQuery === this.paymentsList.searchQuery) { return }

      this.paymentsList.setSearchQuery(searchQuery)
      this.paymentsList.fetchAll()
    },
  },
}
</script>
