import { REFERRALS_ROUTES_NAME } from '@/vue_apps/catalogs_root/Referrals/routes/routesNames'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'

const getBase = (src = '') => `/referrals${src}`

export const referralRoutes = [
  {
    path: getBase(''),
    name: REFERRALS_ROUTES_NAME.INDEX,
    meta: {
      mode: MODES.CREATE,
    },
  },

  {
    path: getBase('/new'),
    name: REFERRALS_ROUTES_NAME.NEW,
    meta: {
      mode: MODES.CREATE,
    },
  },

  {
    path: getBase('/:id/edit'),
    name: REFERRALS_ROUTES_NAME.EDIT,
    props: (route) => ({ id: route.params.id }),
    meta: {
      mode: MODES.EDIT,
    },
  },

  {
    path: getBase('/*'),
    name: REFERRALS_ROUTES_NAME.ROOT,
  },
]
