<template>
  <m-panel
    class="orders-page"
    icon="order"
    :title="panelTitle"
  >
    <!-- Список счетов -->
    <m-si-generator
      v-if="canView"
      :items="ordersList.data"
      :si-generator-schema="ordersSchema"
      :current-page="ordersList.currentPage"
      :page-count="ordersList.totalPages"
      :search-query="ordersList.searchQuery"
      :total="ordersList.total"
      :search-placeholder="t('finance.searchPlaceholderOrders')"
      @onItemClick="onItemClick"
      @onItemEdit="onEditClick"
      @update:currentPage="ordersList.fetchPage($event)"
      @update:searchQuery="ordersList.search($event)"
    >
      <template #filters>
        <!-- Фильтры -->
        <orders-filters :list="ordersList" />
      </template>
    </m-si-generator>

    <introduction
      v-else
      :introduction-text="t('introduction.not_authorized')"
      hide-button
      icon="fad fa-shopping-cart"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getOrdersSchema } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getOrdersSchema'
import { OrdersList } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/OrdersList'
import { getDefaultOrdersFilters } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getDefaultOrdersFilters'
import OrdersFilters from '@/vue_apps/FinanceModule/FinanceModuleIndex/components/OrdersFilters.vue'
import Introduction from '@/vue_components/introduction/introduction.vue'

export default {
  name: 'OrdersPage',
  components: { Introduction, OrdersFilters, MSiGenerator, MPanel },

  data () {
    return {
      ordersList: new OrdersList(getDefaultOrdersFilters()),
      ordersSchema: getOrdersSchema(),
    }
  },

  computed: {
    panelTitle () {
      return `${t('finance.orders')} (${this.ordersList.totalItems})`
    },

    canView () {
      return Services.security.canViewOrder
    },
  },

  created () {
    this.listInit()
  },

  methods: {
    listInit () {
      this.ordersList.fetchAll()
    },

    onItemClick ({ id }) {
      Turbolinks.visit(`/orders/${id}`)
    },

    onEditClick ({ id }) {
      Turbolinks.visit(`/orders/${id}/edit`)
    },

    onSearchQueryUpdate (searchQuery) {
      if (searchQuery === this.ordersList.searchQuery) { return }

      this.ordersList.setSearchQuery(searchQuery)
      this.ordersList.fetchAll()
    },
  },
}
</script>
