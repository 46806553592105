<template>
  <div class="clinic-egisz-tab pt-6">
    <!--    ЕГИСЗ idLPU-->
    <m-input
      :label="t('egisz.egisz_lpu_id')"
      :value="clinic.lpuId"
      :required="validationRequired"
      validator-name="lpu_id"
      :disabled="disabled"
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('lpuId', $event)"
    />

    <!--    ЕГИСЗ GUID-->
    <m-input
      :label="t('egisz.egisz_guid')"
      :value="clinic.netrikaGuid"
      :required="validationRequired"
      validator-name="netrika_guid"
      :disabled="disabled"
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('netrikaGuid', $event)"
    />

    <!--    ID клиники в ФРМО-->
    <m-input
      :label="t('egisz.clinicFrmoId')"
      :value="clinic.frmoOid"
      :required="validationRequired"
      validator-name="frmo_oid"
      :disabled="disabled"
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('frmoOid', $event)"
    />

    <!--ID структурного подразделения в ФРМО-->
    <m-prompt-notice
      :text="t('egisz.clinicFrmoDepartmentWarning')"
      class="mb-indent-mid"
    />
    <m-input
      :label="t('egisz.clinicFrmoDepartmentId')"
      :value="clinic.frmoDepartmentOid"
      validator-name="frmo_department_oid"
      :disabled="disabled"
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('frmoDepartmentOid', $event)"
    />

    <!--Номер МИС в клинике-->
    <div class="flex align-center gap-indent-small">
      <m-input
        :value="clinic.numberOfMis"
        class="clinic-egisz-tab__mis-number"
        type="number"
        :label="t('egisz.clinicMISNumber')"
        :precision="0"
        :min="1"
        :m-fixed-height="false"
        controls
        :required="validationRequired"
        validator-name="number_of_mis"
        :disabled="disabled"
        @registerValidator="$registerValidator($event)"
        @input="clinic.setValue('numberOfMis', $event)"
      />

      <m-icon
        v-tooltip="t('egisz.clinicMISTooltip')"
        icon="info"
        color="warning"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'

export default defineComponent({
  name: 'ClinicEgiszTab',
  components: { MIcon, MPromptNotice, MInput },
  props: {
    clinic: { type: Clinic, required: true },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    validationRequired () {
      return Boolean(this.clinic.netrikaGuid || this.clinic.lpuId)
    },
  },
})
</script>
