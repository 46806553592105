export const BUTTON_STYLE = [
  'fill',
  'plain',
  'round',
  'circle',
]

export const BUTTON_TYPE = [
  '',
  'primary',
  'success',
  'warning',
  'danger',
  'info',
  'text',
  'default',
  'dark',
]
