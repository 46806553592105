<template>
  <div class="common-calls-settings">
    <div
      v-if="clientWidgetModuleEnabled"
      class="common-calls-settings__module-enabled"
    >
      <div class="call-settings_clarification">
        {{ t('whatsapp.greenSmsClarification') }}
      </div>

      <div class="call-settings_notice">
        <m-icon
          class="call-settings_notice__icon"
          icon="warning"
          color="warning"
        />
        <div class="call-settings_notice__text">
          {{ t('whatsapp.greenSmsNotice') }}
        </div>
      </div>

      <!-- Логин -->
      <m-input
        :value="vxGetFlashCallsLogin"
        :label="t('whatsapp.login')"
        class="common-calls-settings__login"
        required
        validator-name="calls-login"
        @registerValidator="onRegisterValidator"
        @input="vxSetFlashCallsLogin"
      />

      <!-- Пароль -->
      <m-input
        :value="vxGetFlashCallsPassword"
        :label="t('whatsapp.password')"
        class="common-calls-settings__password"
        type="password"
        required
        validator-name="calls-password"
        @registerValidator="onRegisterValidator"
        @input="vxSetFlashCallsPassword"
      />

      <!-- Проверка подключения -->
      <div class="check-connection">
        <m-button
          :text="t('whatsapp.checkConnection')"
          class="check-connection__button"
          @click="vxFetchBalanceCallsConfigurations"
        />
        <div class="check-connection__clarification">
          {{ t('whatsapp.checkConnectionClarification') }}
        </div>
      </div>

      <!-- Баланс -->
      <div class="check-balance">
        <div class="title mb-indent-small check-balance__title">
          {{ t('whatsapp.accountBalance') }}
        </div>
        <div class="check-balance__info">
          <div class="check-balance__value">
            {{ balance }}
          </div>
          <m-button
            class="check-balance__button"
            template="short_refresh_green"
            size="mini"
            @click="vxFetchBalanceCallsConfigurations"
          />
        </div>
      </div>

      <m-button
        template="save"
        :disabled="saveButtonDisabled"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="t('introduction.client_widget')"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { MCallsConfigurationsPresenter } from '@/_api/Deliveries/MCallsPresenter'
import Introduction from '@/vue_components/introduction/introduction.vue'

export default {
  name: 'CommonCallsSettings',
  components: {
    Introduction,
    MIcon,
    MButton,
    MInput,
  },

  mixins: [ValidationParentMixin],

  computed: {
    ...mapGetters('messagesStore/flashCallsConfigurationsStore', {
      vxGetFlashCallsLogin: 'vxGetFlashCallsLogin',
      vxGetFlashCallsPassword: 'vxGetFlashCallsPassword',
      vxGetFlashCallsBalance: 'vxGetFlashCallsBalance',
    }),

    balance () {
      return this.vxGetFlashCallsBalance?.balance
        ? Utils.numberToLocaleCurrency(parseFloat(this.vxGetFlashCallsBalance?.balance))
        : t('whatsapp.pleaseAuthorise')
    },

    clientWidgetModuleEnabled () {
      return gon.application.client_widget_enabled
    },

    saveButtonDisabled () {
      return !(this.vxGetFlashCallsLogin && this.vxGetFlashCallsPassword)
    },
  },

  mounted () {
    this.vxFetchCallsConfigurations()
  },

  methods: {
    ...mapActions('messagesStore/flashCallsConfigurationsStore', {
      vxFetchCallsConfigurations: 'vxFetchCallsConfigurations',
      vxFetchBalanceCallsConfigurations: 'vxFetchBalanceCallsConfigurations',
    }),

    ...mapMutations('messagesStore/flashCallsConfigurationsStore', {
      vxSetFlashCallsLogin: 'vxSetFlashCallsLogin',
      vxSetFlashCallsPassword: 'vxSetFlashCallsPassword',
    }),

    saveForm () {
      if (this.hasErrors()) return

      const callsConfigurations = {
        login: this.vxGetFlashCallsLogin,
        password: this.vxGetFlashCallsPassword,
      }

      new MCallsConfigurationsPresenter().saveConfigurations(callsConfigurations)
    },
  },
}
</script>
