<template>
  <div
    v-loading="loading"
    class="m-table"
    :class="{
      'overflow-x-scroll': useMinWidth
    }"
  >
    <div class="m-table__caption">
      <slot name="caption" />
    </div>

    <div
      class="m-table__header"
      :style="customStyles"
    >
      <slot name="header" />
    </div>

    <div
      class="m-table__body"
      :style="customStyles"
      @click.right="onContextMenuHandler"
    >
      <slot name="body" />
    </div>

    <div
      v-if="$slots.footer"
      class="m-table__footer"
      :style="customStyles"
    >
      <slot name="footer" />
    </div>

    <el-popover
      v-show="useContextMenu"
      ref="contextMenu"
      v-click-outside="close"
      class="position-static"
      :value="contextMenuVisible"
      :popper-class="contextClass"
    >
      <div
        class="context-menu-wrapper"
        @click="close"
      >
        <slot name="contextMenu" />
      </div>
    </el-popover>
  </div>
</template>

<script>
const X_GAP = 15
const Y_GAP = 15

export default {
  name: 'MTable',
  props: {
    loading: Boolean,
    useContextMenu: Boolean,
    useMinWidth: { type: [String, Boolean], default: null },
  },

  data () {
    return {
      contextMenuVisible: false,
    }
  },

  computed: {
    contextClass () {
      return `contextMenu-${Utils.newGUID()}`
    },

    customStyles () {
      return {
        minWidth: this.useMinWidth,
      }
    },
  },

  methods: {
    /**
     * @param {HTMLElement} menu
     * @param {number} x
     * @param {number} y
     * @private
     */
    __setMenuXY (menu, x = 0, y = 0) {
      menu.style.left = `${x}px`
      menu.style.top = `${y}px`
    },

    /**
     * @param {HTMLElement} menu
     * @param {number} x
     * @param {number} y
     * @private
     */
    __calculateXY (menu, x = 0, y = 0) {
      const { clientHeight: menuHeight, clientWidth: menuWidth } = menu
      const { clientHeight, clientWidth } = document.documentElement

      const finalX = x + menuWidth + X_GAP > clientWidth
        ? clientWidth - menuWidth - X_GAP
        : x

      const finalY = y + menuHeight + Y_GAP > clientHeight
        ? clientHeight - menuHeight - Y_GAP
        : y

      return { x: finalX, y: finalY }
    },

    open (left, top) {
      const menu = document.querySelector(`.${this.contextClass}`)
      if (!menu) { return }

      this.__setMenuXY(menu)
      this.contextMenuVisible = true

      this.$nextTick(() => {
        const { x, y } = this.__calculateXY(menu, left, top)
        this.__setMenuXY(menu, x, y)
      })
    },

    close () {
      this.contextMenuVisible = false
    },

    onContextMenuHandler ($event) {
      if (!this.useContextMenu) { return }
      if ($event.target.classList.contains('m-table__body')) { return }
      $event.preventDefault()

      const { layerX, layerY } = $event
      this.open(layerX, layerY)
    },
  },
}
</script>
