<template>
  <div class="between-text">
    <div
      v-if="title"
      class="title title_unifying"
    >
      {{ title }}
    </div>
    <div class="between-text__body flex gap-indent">
      <span :class="{'title': useTextBeforeAsTitle}">
        {{ textBefore }}
      </span>
      <m-icon
        v-if="explanationIconTooltip"
        v-tooltip="explanationIconTooltip"
        icon="questionCircle"
        color="grey"
        use-solid
        class="between-text__question-icon"
      />
      <div
        :class="{
          'between-text__elem-wrapper': !removeDefaultElemStyling,
          'between-text__elem-wrapper_glued-to-right': glueElemToRight
        }"
      >
        <slot />
      </div>
      <span v-if="textAfter">
        {{ textAfter }}
      </span>
    </div>
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'BetweenText',
  components: { MIcon },

  props: {
    title: { type: String, default: '' },
    textBefore: { type: String, default: '' },
    textAfter: { type: String, default: '' },
    explanationIconTooltip: { type: String, default: '' },
    removeDefaultElemStyling: Boolean,
    glueElemToRight: Boolean,
    useTextBeforeAsTitle: Boolean,
  },
}
</script>
