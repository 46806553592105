
export const CONSULTATION_ENTITY_NAMES = Object.freeze({
  NSI_MKB_DIAGNOSIS_GROUP: 'nsiMkbDiagnosisGroup',
  NSI_CASE_VISIT_TYPE: 'nsiCaseVisitType',
  NSI_MEDICAL_CARE_PLACE: 'nsiMedicalCarePlace',
  NSI_PATIENT_CONDITION: 'nsiPatientCondition',
  NSI_IDENTIFIED_PATHOLOGY: 'nsiIdentifiedPathology',

  ANAMNESIS_OF_DISEASE: 'anamnesisOfDisease',
  ANAMNESIS_OF_LIFE: 'anamnesisOfLife',
  OBJECTIVELY: 'objectively',
  CONCLUSION: 'conclusion',
  DIAGNOSIS_BY_TEXT: 'diagnosisByText',
})
