var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-nav-container"},[_c('panel-heading-collapsed',{attrs:{"direction":"right","icon":"fad fa-address-card","show":!_vm.GET_MENU_SHOW,"title":_vm.companyTitle},on:{"panel-collapsed-toggle":_vm.showMenuHandler}}),_vm._v(" "),(_vm.GET_MENU_SHOW)?_c('panel-heading',{staticClass:"company-nav-panel",attrs:{"custom-class":"company-title-print","icon":"fad fa-address-card","show-tooltip":true,"title":_vm.companyTitle},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('span',{staticClass:"fad fa-angle-left clickable hidden-print",on:{"click":_vm.showMenuHandler}})]},proxy:true}],null,false,37409574)},[_vm._v(" "),_c('div',{staticClass:"tabs-nav hidden-print"},_vm._l((_vm.tabs),function(tab){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!tab.enabled && { text: _vm.t('insufficient_access_rights'), placement: 'right' }),expression:"!tab.enabled && { text: t('insufficient_access_rights'), placement: 'right' }"}],key:tab.name,staticClass:"tab-nav",class:{
          'is-active': tab.routes.includes(_vm.$route.name),
          'is-disabled': !tab.enabled
        },on:{"click":function($event){$event.stopPropagation();tab.enabled && _vm.redirect(tab.name)}}},[_c('div',{staticClass:"tab-ico"},[_c('span',{class:tab.ico})]),_vm._v(" "),_c('div',{staticClass:"tab-text"},[_vm._v("\n          "+_vm._s(tab.text)+"\n        ")])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{class:[
      'company-container',
      { 'company-container-collapsed': !_vm.GET_MENU_SHOW }
    ]},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }