export const filterByIgnoredIds = (data = [], ignoredIds = []) => {
  let newData = [...data]

  if (ignoredIds && ignoredIds.length) {
    newData = newData.filter((item) => !ignoredIds.includes(item.id))
  }

  if (!newData.length) {
    newData = [{
      id: null,
      title: t('egisz.components.entries_history.not_found'),
      disabled: true,
    }]
  }

  return newData
}

export const getLoadingItemInList = () => {
  return [{
    id: null,
    title: t('egisz.components.entries_history.load_from_medcard'),
    disabled: true,
  }]
}
