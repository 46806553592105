import { isEmpty } from 'lodash'
import { getTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/logic/getTableSchema'

export class MSiSchema {
  /**
   * @type {MTableSchema | TTableSchema}
   */
  tableSchema = getTableSchema()

  useSearch = false

  usePagination = false

  useAdd = false

  /**
   * Модель для Service.security
   * @type {string | null}
   * @private
   */
  _addModel = null

  useFilterButtons = false

  useCustomRow = false

  /**
   * @param {TMSiGeneratorSchema} siGeneratorSchema
   * @return {TMSiGeneratorSchema}
   */
  constructor (siGeneratorSchema = null) {
    if (isEmpty(siGeneratorSchema)) { return this }

    Object.keys(siGeneratorSchema).forEach((key) => {
      this[key] = siGeneratorSchema[key]
    })
  }

  addSearch (useSearch = true) {
    this.useSearch = useSearch

    return this
  }

  addPagination (usePagination = true) {
    this.usePagination = usePagination

    return this
  }

  addCreateButton (visibility = true, model = null) {
    this.useAdd = visibility
    this._addModel = model

    return this
  }

  addFilterButtons () {
    this.useFilterButtons = true

    return this
  }

  addUsingCustomRow () {
    this.useCustomRow = true

    return this
  }

  /** @param {TTableSchema | MTableSchema} tableSchema */
  addTableSchema (tableSchema) {
    this.tableSchema = tableSchema

    return this
  }
}
