<template>
  <div
    v-if="(isWhatsappModuleEnabled && isSmsModuleEnabled) || prioritiesVisible"
    class="channel-priority"
  >
    <div class="channel-priority__title">
      <div class="title title_unifying">
        {{ t('whatsapp.priorityTitle') }}
      </div>
      <div class="clarification explanation">
        {{ clarification }}
      </div>
    </div>
    <div class="channel-priority__buttons priority-card-container">
      <message-priority-card
        v-for="(messagesPriority, index) in switchedPriorities"
        :key="`messagesPriority:${messagesPriority.deliveryType}`"
        :index="index"
        :messages-priority="messagesPriority"
        :count-of-switched-priorities="countOfSwitchedPriorities"
        @change="onPriorityChange(messagesPriority, $event)"
        @onArrowClickUp="onArrowClick(messagesPriority, 'up', index)"
        @onArrowClickDown="onArrowClick(messagesPriority, 'down', index)"
      />

      <message-priority-card
        v-for="messagesPriority in unSwitchedPriorities"
        :key="`unSwitchedMessagesPriority:${messagesPriority.deliveryType}`"
        :messages-priority="messagesPriority"
        @change="onPriorityChange(messagesPriority, $event)"
      />
    </div>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { cloneDeep } from 'lodash'
import MessagePriorityCard
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagePriorityCard.vue'

const MINIMUM_PRIORITY_VALUE = 1
const MINIMUM_PRIORITIES_COUNT = 1

export default {
  name: 'MessagesPriorities',
  components: { MessagePriorityCard },

  props: {
    messagesPriorities: PropsTypes.Array(),
    prioritiesVisible: {
      type: Boolean,
      default: false,
    },
    clarification: {
      type: String,
      default: t('whatsapp.priorityClarification'),
    },
  },

  emits: [
    'updatePriorities',
  ],

  data () {
    return {
      /** @type {TProviderPriority[]} */
      localMessagesPriorities: [],
    }
  },

  computed: {
    isWhatsappModuleEnabled () {
      return gon.application.whatsapp_module_enabled
    },

    isSmsModuleEnabled () {
      return gon.application.sms_module_enabled
    },

    /** @type {TProviderPriority[]} */
    switchedPriorities () {
      return this.localMessagesPriorities.filter((item) => item.priority)
    },

    /** @type {TProviderPriority[]} */
    unSwitchedPriorities () {
      return this.localMessagesPriorities.filter((item) => !item.priority)
    },

    /** @return {number} */
    countOfSwitchedPriorities () {
      return this.switchedPriorities.length
    },
  },

  created () {
    this.updateMessagesPriorities(this.messagesPriorities)
  },

  methods: {
    updateMessagesPriorities (priorities) {
      if (this.localMessagesPriorities.length) { return }
      this.localMessagesPriorities = cloneDeep(priorities)
      this.reindexPriorities()
    },

    /**
     * @param {TProviderPriority} messagesPriority
     * @param {boolean} value
     */
    onPriorityChange (messagesPriority, value) {
      // нельзя отключить последний включённый
      if (!value && this.countOfSwitchedPriorities === MINIMUM_PRIORITIES_COUNT) { return }

      const lastSwitchedPriority =
          this.switchedPriorities.at(-1)?.priority || MINIMUM_PRIORITY_VALUE

      if (value) {
        this.switchedPriorities.forEach((item) => { item.priority += 1 })
      }

      messagesPriority.priority = value
        ? lastSwitchedPriority
        : 0

      this.reindexPriorities()
    },

    reindexPriorities (dontRecalculatePriorityByIndex = false) {
      const sortedSwitchedPriorities = this.switchedPriorities
        .sort((a, b) => a.priority < b.priority ? 1 : -1)

      const finalSwitchedPriorities = dontRecalculatePriorityByIndex
        ? sortedSwitchedPriorities
        : sortedSwitchedPriorities
          .map((item, index) => ({
            ...item,
            priority: this.countOfSwitchedPriorities - index,
          }))

      this.localMessagesPriorities = [
        ...finalSwitchedPriorities,
        ...this.unSwitchedPriorities,
      ]

      this.$emit('updatePriorities', cloneDeep(this.localMessagesPriorities))
    },

    /**
     * если currentIndex === 1, сосед будет с индексом 0
     * @param {TProviderPriority} messagesPriority
     * @param {number} currentIndex
     */
    __onUp (messagesPriority, currentIndex) {
      const currentPriority = messagesPriority.priority

      messagesPriority.priority = this.switchedPriorities[currentIndex - 1].priority
      this.switchedPriorities[currentIndex - 1].priority = currentPriority
    },

    /**
     * если currentIndex === 1, сосед будет с индексом 2
     * @param {TProviderPriority} messagesPriority
     * @param {number} currentIndex
     */
    __onDown (messagesPriority, currentIndex) {
      const currentPriority = messagesPriority.priority

      messagesPriority.priority = this.switchedPriorities[currentIndex + 1].priority
      this.switchedPriorities[currentIndex + 1].priority = currentPriority
    },

    /**
     * @param {TProviderPriority} messagesPriority
     * @param {TPriorityDirection} direction
     * @param {number} currentIndex
     */
    onArrowClick (messagesPriority, direction, currentIndex) {
      if (direction === 'up') {
        if (!currentIndex) { return }
        this.__onUp(messagesPriority, currentIndex)
      }

      if (direction === 'down') {
        if (currentIndex === this.countOfSwitchedPriorities - 1) { return }
        this.__onDown(messagesPriority, currentIndex)
      }

      this.reindexPriorities(true)
    },
  },
}
</script>
