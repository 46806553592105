import { ICheckupFactor } from '@/vue_apps/CheckupsModule/interfaces/ICheckupFactor'
import { defaultNumberSorting } from '@/helpers/lambda'

export const getNsiCodesFromFilteredFactorsByExistingSpecialFactors =
  (factors: ICheckupFactor[], specialFactors: readonly number[] | number[]): number[] => {
    return factors
      .filter((factor) =>
        specialFactors
          .includes(+factor.nsiCode)
      )
      .map((factor) => +factor.nsiCode)
      .sort(defaultNumberSorting)
  }
