import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  documentsListAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/documentsListAdapter'
import {
  documentMassDestroyAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/documentMassDestroyAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  documentTypesAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/documentTypesAdapter'
import {
  documentMultiPrintAdapter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/documentMultiPrintAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MListService } from '@/_api/_requests/MListService'

export class MDocumentsPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'document',
      location: 'MDocumentsPresenter',
      routes: {
        all: Routes.documents_path,
        one: Routes.document_path,
        list: Routes.list_api_internal_medical_records_documents_path,
      },
    })
  }

  list (listData, config = {}) {
    return super.list(listData, {
      toJson: true,
      toClientAdapter: documentsListAdapter.toClient,
      toServerAdapter: documentsListAdapter.toServer,
      ...config,
    })
  }

  semdDocumentsList (listData, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'documents'
    )

    return super.post({
      url: Routes.api_internal_selectors_semds_documents_list_path(),
      data: camelToSnake({ sortParams: listData }),
      ...config,
    }).promise
      .catch(MRequestError.withDefault(MListService.getDefaultList(), notification))
  }

  fetchDocumentTypes (filters, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'documentTypes'
    )

    return super.post({
      url: Routes.list_api_internal_documents_certificate_payments_document_types_path(),
      data: documentTypesAdapter.toServer(filters),
      dataType: 'json',
      contentType: 'application/json',
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }

  submit (data, config = {}) {
    return super.submit(data, config)
  }

  destroy (id, config = {}) {
    return super.destroy(id, config)
  }

  massDestroy (ids, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'destroyMessage',
      'documents'
    )

    return super.post({
      url: Routes.multicheck_actions_handler_documents_path(),
      data: documentMassDestroyAdapter.toServer(ids),
      dataType: 'text',
      contentType: false,
      processData: false,
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(() => true, notification))
      .catch(MRequestError.withDefault(false, notification))
  }

  multiPrint (ids, config = {}) {
    return super.post({
      url: Routes.multicheck_actions_handler_documents_path(),
      data: documentMultiPrintAdapter.toServer(ids),
      contentType: false,
      processData: false,
      ...config,
    }).promise
  }
}
