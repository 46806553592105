import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'
import { reportErrorText, reportSuccessText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { camelToSnake, reportErrorText as newReportErrorText, snakeToCamel } from '@/_api/_requests/helpers'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { pick } from 'lodash'
import { companyCreateAdapter } from '@/api_entities/companies/companiesAdapters'

// какой-то умник сделал offset через пагинацию (page)
const KLUDGE_OFFSET = 1

export const companiesPresenter = {
  find ({
    companyType,
    searchQuery,
    limit = DEFAULT_LIMIT,
    offset = KLUDGE_OFFSET,
  }
  ) {
    const params = camelToSnake({
      search_query: searchQuery,
      limit,
      offset: offset || KLUDGE_OFFSET,
      company_type: companyType,
    })

    return companiesEndpoint.find(params)
      .then((response) => Promise.resolve({ data: response.companies }))
      .catch(() => Promise.resolve({ data: [] }))
  },

  fetchCompanyTypes () {
    return companiesEndpoint.getCompanyTypes()
      .then((data) => Promise.resolve(snakeToCamel(data)))
      .catch((err) => {
        Utils.reportError(
          'companiesPresenter::fetchCompanyTypes()',
          reportErrorText('list_message', 'companyTypes')
        )(err)

        return Promise.resolve([])
      })
  },

  create (company) {
    const __company = companyCreateAdapter.toServer(company)

    return companiesEndpoint.create(__company)
      .then((data) => {
        Utils.reportSuccess(reportSuccessText('create_message', 'company'))()

        return Promise.resolve(pick(data, ['id', 'title']))
      })
      .catch((err) => {
        Utils.reportError(
          'companiesPresenter::create()',
          newReportErrorText('createMessage', 'company')
        )(err)

        return Promise.resolve({ errors: err.responseJSON })
      })
  },
}
