<template>
  <si class="medcase-meddocuments">
    <si-table
      :not-found="!medDocuments.length"
      class="medcase-meddocuments__table"
    >
      <template #header>
        <si-field>
          <div class="col__header">
            {{ t('egisz.components.egisz_cases.sign_register_modal.table.header') }}
          </div>
          <div class="col__sign col__sign_personal">
            <i
              v-tooltip="t('egisz.components.egisz_cases.sign_register_modal.table.personal_sign_tooltip')"
              class="fad fa-file-certificate success"
            />
          </div>
          <div class="col__sign col__sign_organization">
            <i
              v-tooltip="t('egisz.components.egisz_cases.sign_register_modal.table.organization_sign_tooltip')"
              class="fad fa-file-certificate success"
            />
          </div>
        </si-field>
      </template>
      <template #body>
        <si-field
          v-for="medDocument in medDocuments"
          :key="`medDocumentId:${medDocument.id}`"
        >
          <div class="col__header">
            {{ medDocument.header }}
          </div>
          <div class="col__sign col__sign_personal">
            <i
              class="fad fa-file-certificate"
              :class="getClassBySignatureStatus(medDocument.attachment_signature_status, 'personal_signed')"
            />
          </div>
          <div class="col__sign col__sign_organization">
            <i
              class="fad fa-file-certificate"
              :class="getClassBySignatureStatus(medDocument.attachment_signature_status, 'organization_signed')"
            />
          </div>
        </si-field>
      </template>
    </si-table>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'

export default {
  name: 'EgiszMedCaseMedDocumentsTable',
  components: { SiField, SiTable, Si },
  props: {
    medDocuments: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getClassBySignatureStatus (signatureStatusObj, signName) {
      return signatureStatusObj && signatureStatusObj[signName]
        ? 'success'
        : 'danger'
    },
  },
}
</script>
