import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  personalCheckupRemoteFilesAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupRemoteFilesPresenter/personalCheckupRemoteFilesAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class PersonalCheckupRemoteFilesPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.api_internal_checkups_personals_remote_file_path,
        all: Routes.api_internal_checkups_personals_remote_files_path,
        list: Routes.list_api_internal_checkups_personals_remote_files_path,
      },
      entity: 'attachments',
      location: 'PersonalCheckupRemoteFilesPresenter',
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toClientAdapter: personalCheckupRemoteFilesAdapter.toClient,
      ...config,
    })
  }

  submit (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'uploadMessage',
      'attachment'
    )

    return super.post({
      url: this.routes.all(),
      data: personalCheckupRemoteFilesAdapter.toServer(data),
      contentType: false,
      processData: false,
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault(null, notification))
  }
}
