<template>
  <div>
    <h1>DisabledMixin.js</h1>
    <p>Используется для универсального управления свойством <b>disabled</b></p>
    <p>Исходный код в файле:</p>
    <pre style="width: fit-content">@/vue_present/mixins/DisabledMixin.js</pre>

    <h2>Входные параметры</h2>
    <h3>Пропса типа Boolean</h3>
    <p>Элемент будет заблокирован, если <b>disabled === true</b></p>
    <m-button
      disabled
      text="Нерабочая кнопка"
    />

    <h3>Пропса null/undefined</h3>
    <p>Элемент останется незаблокированным (<b>disabled === false</b>)</p>
    <m-button text="Вполне рабочая кнопка" />

    <h3>Пропса типа Object</h3>
    <p>Требуется объект вида</p>
    <pre style="width: fit-content">
      {
        disabled: Boolean,
        tooltip: String,
      }</pre>
    <p><b>tooltip</b> - текст, который будет выводится при <b>disabled === true</b></p>
    <m-button
      :disabled="{ disabled: true, tooltip: 'Кастомный тултип' }"
      text="Наведи курсор на меня"
    />

    <h3>Пропса типа String</h3>
    <p>Требуется название модели из <b>application.security</b>, например <b>ClientGroup</b></p>
    <p>Будет отображен тултип с отсутствием прав доступа</p>
    <m-button
      disabled="ClientGroup1"
      text="Наведи курсор на меня"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton'

export default {
  name: 'FrontBookPageDisabledMixin',
  components: { MButton },
}
</script>
