<template>
  <reusable-lazy-tree
    class="diseases-app"
    :tree="tree"
    @setTreeRef="$emit('setTreeRef', $event)"
    @onNodeClick="$emit('onNodeClick', $event)"
  >
    <template #default="{ node, data }">
      <lazy-tree-node-component
        :data="data"
        :node="node"
      >
        <template #title>
          <span v-tooltip="{ tooltip: data.title, placement: 'top-start' }">
            <template v-if="!data.isLeaf">
              {{ data.title }}
            </template>

            <template v-else>
              <b>{{ getTitle(data).prefix }}</b>
              <span>{{ getTitle(data).suffix }}</span>
            </template>
          </span>
        </template>
      </lazy-tree-node-component>
    </template>
  </reusable-lazy-tree>
</template>

<script>
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import { DiseasesLazyTree } from '@/vue_apps/catalogs_root/Diseases/store/DiseasesLazyTree'
import { MDiseasePresenter } from '@/_api/catalogs/Disease/MDiseasePresenter'
import LazyTreeNodeComponent from '@/vue_present/Reuse/LazyTree/components/LazyTreeNodeComponent.vue'

export default {
  name: 'Diseases',

  components: {
    LazyTreeNodeComponent,
    ReusableLazyTree,
  },

  emits: [
    'setTreeRef',
    'onNodeClick',
  ],

  data () {
    return {
      tree: new DiseasesLazyTree(new MDiseasePresenter()),
    }
  },

  methods: {
    getTitle (data) {
      const splitter = data.title.indexOf(' ')
      const prefix = data.title.slice(0, splitter)
      const suffix = data.title.slice(splitter)

      return {
        prefix,
        suffix,
      }
    },
  },
}
</script>
