import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('checkups.diagnosesPersonal.type')

export enum PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE {
  PRELIMINARY = 'preliminary',
  CHRONIC = 'chronic',
  INFECTIOUS = 'infectious'
}

export const PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE_ITEMS = [
  {
    id: PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.PRELIMINARY,
    title: ts(PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.PRELIMINARY),
  },
  {
    id: PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.CHRONIC,
    title: ts(PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.CHRONIC),
  },
  {
    id: PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.INFECTIOUS,
    title: ts(PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE.INFECTIOUS),
  },
] as const
