import { MListService } from '@/_api/_requests/MListService'
import { MOrderPresenter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/MOrderPresenter'

export class OrdersList extends MListService {
  constructor (initialFilters) {
    super(
      initialFilters,
      new MOrderPresenter(),
      { cacheKey: 'finance-orders-list' }
    )
  }
}
