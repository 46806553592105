import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'

export const getInfectiousDiseasesMsiSchema = ({ addCreateButton = false } = {}) => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        nsiDiagnosisNsiId: new MTableHeader().addFixedWidth('70px'),
        nsiDiagnosisTitle: new MTableHeader().addFixedWidth(),
        year: new MTableHeader().addFixedWidth('60px'),
      })
      .addDeleteIcon(Services.security.canManageCheckupsCompany)
  )
  .addCreateButton(addCreateButton)
