<template>
  <m-panel
    class="clinics-catalog__editor clinics-editor"
    :title="t('clinic')"
    icon="clinics"
    body-css-classes="pe-0"
    use-close-button
    @close="onEditorClose"
  >
    <template #default>
      <el-tabs
        v-loading="loading"
        class="clinics-editor__tabs"
        :value="activeTab"
        tab-position="right"
        @tab-click="onTabClick"
      >
        <!-- Основные поля -->
        <el-tab-pane :name="CLINICS_TABS.MAIN">
          <template #label>
            <span :class="{ red: highlightedTabs[CLINICS_TABS.MAIN] }">
              {{ t('clinic') }}
            </span>
          </template>

          <clinic-main-tab
            :clinic="clinic"
            :time-zones="timeZones"
            :is-new-mode="isNewMode"
            :disabled="isReadOnlyMode"
            @registerValidator="onRegisterValidator"
          />
        </el-tab-pane>

        <!-- Адрес -->
        <el-tab-pane :name="CLINICS_TABS.ADDRESS">
          <template #label>
            <span :class="{ red: highlightedTabs[CLINICS_TABS.ADDRESS] }">
              {{ t('address') }}
            </span>
          </template>

          <clinic-address-tab
            :address="clinic.addressAttributes"
            :disabled="isReadOnlyMode"
            @registerValidator="onRegisterValidator"
          />
        </el-tab-pane>

        <!-- Лицензия -->
        <el-tab-pane :name="CLINICS_TABS.LICENSE">
          <template #label>
            <span :class="{ red: highlightedTabs[CLINICS_TABS.LICENSE] }">
              {{ t('license') }}
            </span>
          </template>

          <clinic-license-tab
            :clinic="clinic"
            :disabled="isReadOnlyMode"
            @registerValidator="onRegisterValidator"
          />
        </el-tab-pane>

        <!-- Сообщения -->
        <el-tab-pane :name="CLINICS_TABS.MESSAGES">
          <template #label>
            <span :class="{ red: highlightedTabs[CLINICS_TABS.MESSAGES] }">
              {{ t('whatsapp.messages') }}
            </span>
          </template>

          <clinic-messages-tab
            :clinic="clinic"
            :disabled="isReadOnlyMode"
            @registerValidator="onRegisterValidator"
          />
        </el-tab-pane>

        <!-- Онлайн-запись -->
        <el-tab-pane
          v-if="!clientWidgetDisabled"
          :name="CLINICS_TABS.ONLINE_ACCESS"
          :disabled="clientWidgetDisabled"
        >
          <template #label>
            <span
              v-tooltip="clientWidgetDisabled && t('insufficient_access_rights')"
              :class="{ red: highlightedTabs[CLINICS_TABS.ONLINE_ACCESS] }"
            >
              {{ t('entry_online_access') }}
            </span>
          </template>

          <clinic-online-access-tab
            v-if="!clientWidgetDisabled"
            :clinic="clinic"
            :disabled="isReadOnlyMode"
            @registerValidator="onRegisterValidator"
          />
          <span
            v-else
            class="anti-validator-crutch"
          />
        </el-tab-pane>

        <!-- Логотипы -->
        <el-tab-pane
          :name="CLINICS_TABS.LOGOTYPES"
          :label="t('clinic_logotypes')"
          lazy
        >
          <clinic-logotypes-tab
            :clinic="clinic"
            :is-new-mode="isNewMode"
          />
        </el-tab-pane>

        <!-- ЕГИСЗ -->
        <el-tab-pane
          v-if="!egiszModuleDisabled"
          :name="CLINICS_TABS.EGISZ"
          :disabled="egiszModuleDisabled"
        >
          <template #label>
            <span
              v-tooltip="egiszModuleDisabled && t('insufficient_access_rights')"
              :class="{ red: highlightedTabs[CLINICS_TABS.EGISZ] }"
            >
              {{ t('egisz.title') }}
            </span>
          </template>

          <clinic-egisz-tab
            v-if="!egiszModuleDisabled"
            :clinic="clinic"
            :disabled="isReadOnlyMode"
            @registerValidator="onRegisterValidator"
          />
          <span
            v-else
            class="anti-validator-crutch"
          />
        </el-tab-pane>
      </el-tabs>
    </template>

    <template #footer>
      <m-button-submit
        disabled="Clinic"
        @click="onSubmit"
      />
    </template>
  </m-panel>
</template>

<script>
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { CLINICS_TABS } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/const'
import ClinicMainTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicMainTab.vue'
import ClinicAddressTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicAddressTab.vue'
import ClinicLicenseTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicLicenseTab.vue'
import ClinicMessagesTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicMessagesTab.vue'
import ClinicOnlineAccessTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicOnlineAccessTab.vue'
import ClinicLogotypesTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicLogotypesTab.vue'
import ClinicEgiszTab from '@/vue_apps/catalogs_root/ClinicsCatalog/views/tabs/ClinicEgiszTab.vue'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'
import { MClinicPresenter } from '@/_api/MClinicApi/MClinicPresenter'
import { getTimeZones } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/getTimeZones'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { getValidationInfo } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/getValidationInfo'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { CLINICS_CATALOG_ROUTES } from '@/vue_apps/catalogs_root/ClinicsCatalog/routes/clinicsCatalogRouteNames'

export default defineComponent({
  name: 'ClinicsCatalogEditor',

  components: {
    ClinicEgiszTab,
    ClinicLogotypesTab,
    ClinicOnlineAccessTab,
    ClinicMessagesTab,
    ClinicLicenseTab,
    ClinicAddressTab,
    ClinicMainTab,
    MButtonSubmit,
    MPanel,
  },

  mixins: [ValidationParentMixin, SpinnerHolder],

  props: {
    id: { type: Number, default: null },
  },

  emits: [
    'onSubmit',
  ],

  data () {
    return {
      CLINICS_TABS,
      activeTab: CLINICS_TABS.MAIN,

      highlightedTabs: {
        [CLINICS_TABS.MAIN]: false,
        [CLINICS_TABS.EGISZ]: false,
        [CLINICS_TABS.ADDRESS]: false,
        [CLINICS_TABS.LICENSE]: false,
        [CLINICS_TABS.MESSAGES]: false,
        [CLINICS_TABS.ONLINE_ACCESS]: false,
      },

      clinic: new Clinic(),
      timeZones: getTimeZones(),
    }
  },

  computed: {
    isNewMode () {
      return !this.id
    },

    isReadOnlyMode () {
      return Services.security.canViewClinic && !Services.security.canManageClinic
    },

    egiszModuleDisabled () {
      return !gon.application.egisz_module_enabled
    },

    clientWidgetDisabled () {
      return !gon.application.client_widget_enabled
    },
  },

  watch: {
    id (to) {
      this.fetchClinic(to)
    },
  },

  created () {
    this.parseActiveTab()
    this.fetchClinic(this.id)
  },

  methods: {
    parseActiveTab () {
      this.activeTab =
        location.hash.replace('#', '') || CLINICS_TABS.MAIN
    },

    onTabClick (tab) {
      this.turnOffHighlightedTab(tab.name)
      if (this.activeTab === tab.name) { return }
      this.activeTab = tab.name
      window.location.hash = `#${tab.name}`
    },

    onEditorClose () {
      this.$router.push({ name: CLINICS_CATALOG_ROUTES.SHOW })
    },

    async fetchClinic (id) {
      this.turnOffHighlightedTabs()
      this.resetValidations()

      if (!id) {
        this.clinic = new Clinic()

        return
      }

      const clinic = await this.withSpinner(
        new MClinicPresenter().fetch(id)
      )
      if (clinic.errors) { return }

      this.clinic = new Clinic(clinic)
    },

    /**
     * @return {boolean}
     * @private
     */
    showFrontValidationErrors () {
      this.turnOffHighlightedTabs()
      const validators = this.hasValidationErrors(true)
      if (!validators) { return false }

      const validationInfo = getValidationInfo(Object.keys(validators))
      this.highlightTabs(validationInfo.tabs)
      Notificator.error(t('errors.has_empty_fields', [validationInfo.message]))

      return true
    },

    /**
     * @param errors
     * @return {boolean}
     */
    checkCustomClinicErrors (errors) {
      const showError = (errorField) => {
        Notificator.error(errorField.join(', '))
      }

      if (errors.accessible_from_client_widget) { showError(errors.accessible_from_client_widget) }
      if (errors.status) { showError(errors.status) }

      return Boolean(
        errors.status ||
        errors.accessible_from_client_widget
      )
    },

    async onSubmit () {
      if (this.showFrontValidationErrors()) { return }

      const result = await this.clinic.submit()
      if (Object.hasOwn(result, 'errors')) {
        if (typeof result.errors === 'string') {
          return Notificator.error(t('client_widget.number_of_active_clinics_should_not_exceed_the_number_of_licenses'))
        }

        if (this.checkCustomClinicErrors(result.errors)) { return }

        const validationInfo = getValidationInfo(Object.keys(result.errors))
        this.highlightTabs(validationInfo.tabs)
        Notificator.error(t('errors.has_bad_fields', [validationInfo.message]))

        return this.resetValidations(result.errors)
      }

      this.$emit('onSubmit')

      if (this.isNewMode) {
        this.clinic = new Clinic()
      }
    },

    highlightTabs (tabs = []) {
      tabs.forEach((tab) => { this.highlightedTabs[tab] = true })
    },

    turnOffHighlightedTab (tab) {
      this.highlightedTabs[tab] = false
    },

    turnOffHighlightedTabs () {
      Object.keys(this.highlightedTabs)
        .forEach(this.turnOffHighlightedTab)
    },
  },
})
</script>
