import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MTaxCertificate2024Presenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MTaxCertificate2024Presenter',
      entity: 'document',
      routes: {
        one: Routes.api_internal_documents_certificate_payment_path,
        all: Routes.api_internal_documents_certificate_payments_path,
        list: Routes.list_api_internal_documents_certificate_payments_path,
      },
    })
  }
}
