import Payment from './payment'
import { PAYMENT_PARAMS } from './consts'

export default class RefundPayment extends Payment {
  constructor (payment, attr) {
    super(attr)
    this.refundable_id = payment.id
    if (payment.destination_order) {
      this.destination_order_id = payment.destination_order.id
      this.destination_client_id = payment.destination_order.client_id
      this.kind = PAYMENT_PARAMS.KIND_PAYMENT.ORDER_REFUND
      this.payment_entries_attributes = payment.payment_entries.map((entry) => {
        entry.base_kind = PAYMENT_PARAMS.BASE_KIND.REFUND
        delete entry.id

        return entry
      })
    } else {
      this.kind = PAYMENT_PARAMS.KIND_PAYMENT.CASH_IN_REFUND
      this.deposit = this.totalIncome
    }
    Object.defineProperties(this, {
      _payment: {
        value: payment,
        enumerable: false,
      },
    })
  }

  get totalIncome () {
    return this._summary([this.by_cash, this.by_card, this.by_cashless])
  }
}
