export const APP_ID = '606d8bdd388cb56608db77ec'

export const SMALL_DEVICE_WIDTH = 650

export const OBSERVER_CONFIG = () => ({ attributes: true, childList: true, characterData: true })

export const INSTALLATION_TYPES = Object.freeze({
  CLOUD: 'cloud',
})

export const BANNED_EMAIL = 'admin@admin.ru'

export const FILE_EMPTY = 'empty'
export const FILE_SELECTED = 'selected'
export const FILE_VALIDATION_SUCCESS = 'success'
export const FILE_VALIDATION_ERROR = 'error'
