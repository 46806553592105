<template>
  <m-popover
    :disabled="canManage.disabled"
    :message="popoverMessage"
    @yes="$emit('yes')"
    @no="$emit('no')"
  >
    <template #reference>
      <m-button
        v-if="useButton"
        :disabled="disabled"
        :button-style="buttonStyle"
        :template="template"
        :text="buttonText"
        :size="size"
      />
      <m-icon
        v-else
        v-tooltip="canManage.tooltip || t('delete')"
        class="pointer"
        icon="delete"
        :color="iconColor"
      />
    </template>
  </m-popover>
</template>

<script>
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import { props } from '@/vue_present/_base/buttons/MButtonDelete/props'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'MButtonDelete',
  components: { MButton, MIcon, MPopover },
  mixins: [DisabledMixin],

  props,

  emits: [
    'yes',
    'no',
  ],

  computed: {
    buttonText () {
      return this.useText
        ? this.text
        : ''
    },

    iconColor () {
      return this.canManage.disabled
        ? 'grey'
        : 'danger'
    },
  },
}
</script>
