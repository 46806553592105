var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popover',{attrs:{"placement":"top-end","yes-style":"primary","cancel-text":_vm.t('egisz.components.egisz_clients_table.cancel'),"confirm-text":_vm.t('egisz.components.egisz_clients_table.send'),"disable-confirm-btn":!_vm.currentClinicId,"disabled":_vm.disabled},on:{"yes":function($event){return _vm.$emit(
    'submitClient',
    {
      client: _vm.client,
      currentClinicId: _vm.currentClinicId,
      registrationStatus: _vm.registrationStatus
    }
  )}},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{staticClass:"font-size-12"},[_vm._v("\n      "+_vm._s(_vm.t('egisz.components.egisz_clients_table.select_clinic'))+"\n    ")]),_vm._v(" "),_c('simple-select',{attrs:{"items":_vm.egiszReadyClinics,"fixed-height":false,"value":_vm.currentClinicId},on:{"change":function($event){return _vm.$updateSync('currentClinicId', $event)}}})]},proxy:true},{key:"body",fn:function(){return [_vm._t("body")]},proxy:true}],null,true)},[_vm._v(" "),_vm._v(" "),_c('template',{slot:"reference"},[_vm._t("reference")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }