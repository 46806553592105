<template>
  <el-select
    filterable
    remote
    :remote-method="updateSearchQuery"
    :loading="loading"
    :disabled="disabled"
    :loading-text="T.search"
    :class="className"
    v-bind="$attrs"
    v-on="$listeners"
    @clear="handleCleaning"
  >
    <template #prefix>
      <slot name="prefix" />
    </template>

    <el-option
      v-if="notResults"
      class="hint"
      disabled
      value="nothing"
    >
      {{ T.nothing_has_found }}
    </el-option>

    <el-option
      v-if="!allowSearch"
      class="hint"
      disabled
      value="min"
    >
      <min-string-length-hint
        :value="searchQuery"
        :min-length="minLength"
      />
    </el-option>

    <slot />
  </el-select>
</template>

<script>
import MinStringLengthHint from '@/vue_components/common/min_string_length_hint.vue'

export default {
  name: 'SearchSelect',
  components: {
    MinStringLengthHint,
  },

  props: {
    searchQuery: {
      type: String,
      required: true,
    },

    notResults: {
      type: Boolean,
      required: true,
    },

    allowSearch: {
      type: Boolean,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    disabled: {
      type: Boolean,
    },

    customClass: {
      type: String,
      default: '',
    },

    minLength: {
      type: Number,
      default: 3,
    },
  },

  computed: {
    className () {
      return ['search-select', this.customClass]
    },
  },

  methods: {
    handleCleaning () {
      this.$emit('update:searchQuery', '')
      this.$emit('clearSelect')
    },

    updateSearchQuery (searchQuery) {
      this.$emit('update:searchQuery', searchQuery)
    },
  },
}
</script>
