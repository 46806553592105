<template>
  <validation-wrapper
    :errors="validationMessages"
    :fixed-height="fixedHeight"
  >
    <el-select
      v-tooltip="valueItem && valueItem.title"
      v-loading="nonSearchLoading"
      :value="value"
      filterable
      remote
      clearable
      :remote-method="find"
      :loading="loading"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="$emit('change', $event || null); $emit('changeItem', $event || null)"
    >
      <slot
        v-for="item in list"
        name="option"
        :option="item"
      >
        <el-option
          :key="`diagnosis:${item.id}`"
          :value="item.id"
          :label="item.title"
        />
      </slot>
    </el-select>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { mkb10Endpoint } from '@/api_entities/mkb-10/mkb10'

/**
 * Компонент для работы с МКБ-10.
 * Текстовый поиск инкапсулирован здесь внутри.
 * Если есть начальное значение (id), то оно будет подгружено автоматически.
 */
export default {
  name: 'Mkb10',
  components: { ValidationWrapper },
  mixins: [SpinnerHolder],

  props: {
    validationMessages: {
      type: Array,
      default: () => [],
    },

    fixedHeight: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Number,
      default: null,
    },

    placeholder: {
      type: String,
      default: t('sort_search'),
    },

    disabled: Boolean,
  },

  data () {
    return {
      nonSearchLoading: false,
      valueItem: {},
      list: [],
    }
  },

  watch: {
    value (to, from) {
      this.setValues(this.list.find((item) => item.id === to))
      if (from !== to) { this.getById(to) }
    },

    valueItem (to) {
      this.$emit('setInfo', (to && to.title) || '-')
    },
  },

  methods: {
    getById (id) {
      if (!id) { return }
      if (this.list.find((item) => item.id === id)) { return }

      const promise = mkb10Endpoint.get(id)
        .then((data) => {
          this.setValues(data)
          this.list = [data]
        })
        .catch(Utils.reportError('Mkb10:getById()'))

      this.withSpinner(promise, 'nonSearchLoading')
    },

    find (query) {
      if (this.disabled) { return }
      if (query.length < 3) { return }

      const promise = mkb10Endpoint.find(query)
        .then((data) => {
          this.list = Object.keys(this.valueItem).length
            ? [this.valueItem, ...data]
            : data
        })
        .catch(Utils.reportError('Mkb10:find()'))

      this.withSpinner(promise)
    },

    setValues (data = null) {
      this.valueItem = data || {}
      if (data) { return }
      this.list = []
    },
  },
}
</script>
