import { createFormData } from '@/api_entities/_helpers/create_form_data'

export const fileAdapter = {
  toServer (file, { importKindSelected, importEncodingSelected, importSeparatorSelected }) {
    const payload = {
      attachment: file,
      import_kind: importKindSelected,
      file_encoding: importEncodingSelected,
    }

    return createFormData(payload)
  },
}
