<template>
  <m-button
    template="close"
    @click="$emit('close')"
  />
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'CloseButton',
  components: { MButton },
}
</script>
