<template>
  <div
    ref="panel"
    class="panel panel-primary"
    :class="{'panel-slim': slim}"
  >
    <div :class="['panel-heading', 'panel-heading-flex', customClass ? customClass : '', panelHeadingClass]">
      <div class="panel-heading-title">
        <span
          class="hidden-print"
          :class="['panel-heading-icon', icon ? icon : '']"
        />
        <h3
          ref="tooltip"
          class="panel-title cut with-icon"
          :title="showTooltip ? title : ''"
        >
          {{ title }} <span v-if="count">({{ count }})</span>
        </h3>
      </div>
      <div class="panel-heading-buttons">
        <slot name="buttons" />
      </div>
    </div>
    <div
      class="panel-body"
      :class="panelBodyClass"
    >
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'PanelHeading',
  props: {
    customClass: {
      default: null,
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    count: {
      default: null,
      type: Number,
    },
    title: {
      default: null,
      type: String,
    },
    showTooltip: {
      default: false,
      type: Boolean,
    },
    slim: {
      type: Boolean,
    },
    panelBodyClass: {
      type: Object,
      default: null,
    },
    panelHeadingClass: {
      type: [Object, Array, String],
      default: null,
    },
  },

  updated () {
    if (this.title && this.showTooltip && this.$refs.panel) {
      Utils.updateTooltips(this.$refs.panel)
    }
  },
}
</script>
