<template>
  <a
    v-if="href"
    class="m-link"
    :href="href"
    @click="onClick"
  >
    <slot>{{ text }}</slot>
  </a>
  <span v-else>
    <slot>{{ text }}</slot>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MLink',

  props: {
    text: { type: String, default: '' },
    href: { type: String, default: null },
    openInNewTab: { type: Boolean, default: true },
  },

  emits: ['click'],

  methods: {
    onClick (event: MouseEvent) {
      if (this.openInNewTab) {
        event.preventDefault()
        Utils.openInNewTab(this.href)
      }

      this.$emit('click')
    },
  },
})
</script>
