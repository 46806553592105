<template>
  <div class="medical-policy-search-create flex gap-indent-small w-100">
    <m-select-lazy
      :class="{
        'has-active-medical-policy': hasActiveMedicalPolicy
      }"
      :value="value"
      :label="t('medical_policy')"
      :fetch-method="getFetchMethod()"
      :fetch-on-open="fetchOnOpen"
      :full-width="fullWidth"
      :required="required"
      :disabled="disabled"
      :use-first-time-fetch="useFirstTimeFetch"
      use-icon
      validator-name="medical_policy"
      @syncItems="onSyncItems"
      @registerValidator="$registerValidator($event)"
      @changeByLazy="$emit('setMedicalPolicy', $event)"
    />

    <medical-polices-editor
      :client-id="clientId"
      :disabled="disabled"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MMedicalPolicesPresenter } from '@/_api/MClient/MMedicalPoliceApi/MMedicalPolicesPresenter'
import MedicalPolicesEditor
  from '@/vue_apps/ClientsModule/components/MedicalPolices/components/MedicalPoliciesEditor.vue'
import {
  buildMedicalPolicyTitle,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/const/buildMedicalPolicyTitle'
import { medicalPoliciesListAdapter } from '@/_api/MClient/MMedicalPoliceApi/medicalPoliciesListAdapter'
import { orUndefined } from '@/_medods_standart_library/msl'

export default defineComponent({
  name: 'MedicalPolicySearchCreate',
  components: { MedicalPolicesEditor, MSelectLazy },

  model: {
    event: 'setMedicalPolicy',
    prop: 'value',
  },

  props: {
    value: { type: Object, default: null },
    clientId: { type: Number, required: true },
    fullWidth: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    activeOnly: Boolean,
    fetchOnOpen: { type: Boolean, default: false },
    useFirstTimeFetch: { type: Boolean, default: false },
    policyType: { type: String, default: undefined },
  },

  emits: [
    'setMedicalPolicy',
  ],

  data () {
    return {
      presenter: new MMedicalPolicesPresenter(),
      medicalPolices: [],
    }
  },

  computed: {
    hasActiveMedicalPolicy () {
      return Boolean(
        (this.medicalPolices || [])
          .find((medicalPolicy) => medicalPolicy.isActiveAndNotExpired)
      )
    },
  },

  methods: {
    getFetchMethod () {
      const vm = this

      return (params) => vm.presenter.list(
        {
          ...params,
          clientId: vm.clientId,
          activeAndNotExpired: orUndefined(vm.activeOnly),
          policyType: this.policyType,
        },
        { toClientAdapter: medicalPoliciesListAdapter.toClient }
      )
    },

    onSubmit (medicalPolicy) {
      const title = buildMedicalPolicyTitle(medicalPolicy)
      this.$emit('setMedicalPolicy', { ...medicalPolicy, title, isNew: true })
    },

    /* поддержка легаси */
    onSyncItems (items) {
      this.medicalPolices = items

      const legacyMedicalPolicies = (items || []).map((i) => i._data)

      gon.specific.client_medical_policies = legacyMedicalPolicies
      this.$pubSub.emit('MEDICAL_POLICES.SYNC_ITEMS', legacyMedicalPolicies)
    },
  },
})
</script>
