<template>
  <el-popover
    ref="contextMenu"
    class="position-static"
    :value="popupVisibility"
    :popper-class="contextClass"
  >
    <div
      class="context-menu-wrapper"
      @click="useAutoClose && close()"
    >
      <slot />
    </div>
  </el-popover>
</template>

<script>

import { calculateXY, setMenuXY } from '@/vue_present/Reuse/ContextPopup/logic'

export default {
  name: 'ContextPopup',

  props: {
    useAutoClose: { type: Boolean, default: true },
  },

  data () {
    return {
      popupVisibility: false,
    }
  },

  computed: {
    contextClass () {
      return `contextPopup-${Utils.newGUID()}`
    },
  },

  methods: {
    open (left, top) {
      const menu = document.querySelector(`.${this.contextClass}`)
      if (!menu) { return }

      setMenuXY(menu)
      this.popupVisibility = true

      this.$nextTick(() => {
        const { x, y } = calculateXY(menu, left, top)
        setMenuXY(menu, x, y)
      })
    },

    close () {
      this.popupVisibility = false
    },

    onContextMenuHandler ($event) {
      $event.preventDefault()

      const { clientX, clientY } = $event
      this.open(clientX, clientY)
    },
  },
}
</script>
