<template>
  <a
    v-tooltip="tooltip"
    :href="url"
    target="_blank"
    :class="['m-table-cell__url', 'cut', css]"
    @click.stop=""
  >
    {{ value }}
  </a>
</template>

<script>
export default {
  name: 'TypeUrl',
  props: [
    'tooltip',
    'value',
    'css',
    'url',
  ],
}
</script>
