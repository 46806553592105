export const TAB_ADDRESS_FORMAL = 'tab-address-formal'
export const TAB_ADDRESS_FACT = 'tab-address-fact'
export const TAB_CONTACTS = 'tab-contacts'
export const TAB_COMPANY = 'tab-company'
export const TAB_HEAD = 'tab-head'

export const tabsAddress = [
  { name: 'index', label: t('index') },
  { name: 'country', label: t('country') },
  { name: 'region', label: t('region') },
  { name: 'area', label: t('area') },
  { name: 'city', label: t('city') },
  { name: 'street', label: t('street') },
  { name: 'house', label: t('house') },
  { name: 'flat', label: t('flat') },
]
