export const DEFAULT_TIME_PICKER_OPTIONS = Object.freeze({
  STEP: '00:15',
  START: '00:00',
  END: '24:00',
})

export const timePickerOptions = () => ({
  start: DEFAULT_TIME_PICKER_OPTIONS.START,
  step: DEFAULT_TIME_PICKER_OPTIONS.STEP,
  end: DEFAULT_TIME_PICKER_OPTIONS.END,
})
