<template>
  <el-popover
    v-model="popoverVisibility"
    v-bind="$attrs"
    :popper-class="`m-popover ${popperClass}`"
    :placement="placement"
    :disabled="disabled"
    v-on="$listeners"
  >
    <div
      id="m-popover__title"
      class="m-popover__title"
    >
      <slot name="title" />
    </div>

    <div class="m-popover__message">
      <slot name="message">
        <i
          class="fad fa-fw fa-question-circle"
          :class="yesStyleClasses.icon"
        />
        {{ t('delete_confirmation') }}
      </slot>
    </div>

    <slot name="body" />

    <div class="m-popover__footer">
      <div class="m-popover__footer_left">
        <slot name="footer-left" />
      </div>
      <div class="m-popover__footer_right">
        <slot name="footer-right">
          <div class="m-popover__buttons">
            <slot name="buttons">
              <button
                class="btn btn-sm btn-link margined-top"
                :disabled="disableCancelBtn"
                @click="handleClick('no')"
              >
                {{ cancelText }}
              </button>
              <button
                class="btn btn-sm margined-top"
                :class="yesStyleClasses.btn"
                :disabled="disableConfirmBtn"
                @click="handleClick('yes')"
              >
                {{ confirmText }}
              </button>
            </slot>
          </div>
        </slot>
      </div>
    </div>

    <slot />

    <template #reference>
      <slot name="reference" />
    </template>
  </el-popover>
</template>

<script>
export default {
  name: 'Popover',
  props: {
    placement: {
      type: String,
      default: 'top-start',
    },

    inheritedVisibility: Boolean,
    visibility: Boolean,

    popperClass: {
      type: String,
      default: null,
    },

    yesStyle: {
      type: String,
      default: 'danger',
    },

    cancelText: {
      type: String,
      default: t('no'),
    },

    confirmText: {
      type: String,
      default: t('yes'),
    },

    disableCancelBtn: Boolean,
    disableConfirmBtn: Boolean,

    disabled: Boolean,
  },

  data () {
    return {
      isVisible: false,
    }
  },

  computed: {
    popoverVisibility: {
      get () {
        return this.inheritedVisibility
          ? this.visibility
          : this.isVisible
      },

      set (value) {
        this.inheritedVisibility
          ? this.$updateSync('visibility', value)
          : this.isVisible = value
      },
    },

    yesStyleClasses () {
      return {
        btn: `btn-${this.yesStyle}`,
        icon: this.yesStyle,
      }
    },
  },

  methods: {
    handleClick (actionName) {
      this.$emit(actionName)
      this.isVisible = false
    },

    closePopover () {
      this.popoverVisibility = false
    },
  },
}
</script>
