<template>
  <personal-checkup-nested-items-modal-base
    class-css="personal-checkup-laboratory-research-results-modal"
    :visible="visible"
    :dialog-title="t$('title')"
    modal-title-icon="laboratories"
    nested-items-props-name="laboratoryResearchResults"
    :personal-checkup="personalCheckup"
    :msi-schema="msiSchema"
    :is-new-mode="isNewMode"
    @close="$emit('close')"
  >
    <template #body>
      <m-prompt-notice :text="t$('notice')" />

      <div class="flex gap-indent-small">
        <m-icon
          v-tooltip="questionMarkTooltip"
          class="personal-checkup-laboratory-research-results-modal__large-icon"
          icon="questionCircle"
          color="gray"
        />

        <!-- Обследование -->
        <m-select
          class="personal-checkup-laboratory-research-results-modal__research"
          :value="laboratoryResearchResults.research"
          :items="laboratoryResearchResults.researches"
          :label="t('checkups.laboratoryAndInstrumentalResearch.examination')"
          required
          validator-name="research"
          @registerValidator="onRegisterValidator($event)"
          @change="laboratoryResearchResults.setValue('research', $event)"
        />

        <!-- Дата -->
        <m-date-picker
          :value="laboratoryResearchResults.date"
          :label="t('common.date')"
          value-format="yyyy-MM-dd"
          :required="dateNotInFutureValidator"
          validator-name="date"
          @registerValidator="onRegisterValidator($event)"
          @change="laboratoryResearchResults.setValue('date', $event)"
        />

        <!-- Заключение -->
        <m-select
          :value="laboratoryResearchResults.medicalAssessment"
          :items="laboratoryResearchResults.medicalAssessments"
          :label="t('conclusion')"
          :clearable="false"
          required
          validator-name="medicalAssessment"
          @registerValidator="onRegisterValidator($event)"
          @change="laboratoryResearchResults.setValue('medicalAssessment', $event)"
        />

        <m-button
          template="add"
          :text="t('add')"
          @click="onAdd"
        />
      </div>
    </template>
  </personal-checkup-nested-items-modal-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { capitalize } from 'lodash/string'
import { getQuestionMarkTooltip } from '@/vue_apps/CheckupsModule/store/getQuestionMarkTooltip'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import { dateNotInFutureValidator } from '@/lib/validators/dateNotInFutureValidator'
import {
  getPersonalCheckupResearchResultsMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupResearchResults/personalCheckupResearchResultsConsts'
import PersonalCheckupNestedItemsModalBase
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupNestedItemsModalBase.vue'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'

export default defineComponent({
  name: 'PersonalCheckupLaboratoryResearchResultsModal',

  components: {
    PersonalCheckupNestedItemsModalBase,
    MButton,
    MDatePicker,
    MSelect,
    MPromptNotice,
    MIcon,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
  ],

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      msiSchema: getPersonalCheckupResearchResultsMsiSchema(),
    }
  },

  computed: {
    laboratoryResearchResults () {
      return this.personalCheckup
        .laboratoryResearchResults
    },

    laboratoryResearchResultsItems () {
      return this.laboratoryResearchResults.getArr()
    },

    questionMarkTooltip () {
      if (!this.hasSpecialFactors) { return null }

      const kind = capitalize(this.personalCheckup.kind)
      const items = this.availableSpecialFactors
        .map((nsiCode: number) =>
          this.t$(`questionTooltip${kind}NSICode${nsiCode}`))

      return getQuestionMarkTooltip(
        items,
        'personal-checkup-nested-item-modal__question-mark-tooltip'
      )
    },
  },

  created () {
    this.setI18nScope('checkups.laboratoryAndInstrumentalResearch.laboratory')
  },

  mounted () {
    this.laboratoryResearchResults.fetchResearches({
      checkupType: this.personalCheckup.kind,
      nsiFactorCodes: this.availableSpecialFactors,
    })

    this.laboratoryResearchResults.fetchMedicalAssessments()
  },

  methods: {
    dateNotInFutureValidator,

    onAdd () {
      if (this.hasErrors()) { return }
      this.laboratoryResearchResults.append()
    },
  },
})
</script>
