<template>
  <span
    class="document-square-field-symbol"
    :class="{ 'document-square-field-symbol--last': isLast }"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'DocumentSquareFieldSymbol',

  props: {
    value: { type: String, required: true },
    isLast: Boolean,
  },
}
</script>
