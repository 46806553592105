import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { medicalPolicyAdapter } from '@/_api/MClient/MMedicalPoliceApi/medicalPolicyAdapter'

export class MMedicalPolicesPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'medicalPolicy',
      location: 'MMedicalPolicesPresenter',
      routes: {
        all: Routes.api_internal_medical_policies_path,
        one: Routes.api_internal_medical_policy_path,
        list: Routes.list_api_internal_medical_policies_path,
      },
    })
  }

  /**
   * @param {{
   *   clientId: number,
   *   searchQuery: string,
   *   limit: number,
   *   offset: number
   * }} listData
   * @param config
   * @return {Promise<TListServiceResult>|Promise<*|RequestErrors>}
   */
  list (listData, config = {}) {
    return super.list(listData, config)
  }

  /**
   * @param {IMedicalPolicy} data
   * @param config
   * @return {Promise<*|RequestErrors>}
   */
  submit (data, config = {}) {
    return super.submit(data, {
      ...config,
      toServerAdapter: (medicalPolicy) => ({
        medicalPolicy: medicalPolicyAdapter.toServer(medicalPolicy),
      }),
      toJson: true,
    })
  }
}
