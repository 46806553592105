<template>
  <div class="flex flex-column align-items-end">
    <span class="mb-indent-small">
      {{ t$('clinicTitle') }}
    </span>

    <div class="wrapper">
      <!-- Название клинки или ИП -->
      <document-square-field
        :value="title"
        multiline
        :min-lines="4"
        :max-lines="4"
      />

      <p class="text-center no-margin w-100">
        {{ t$('clinicExplanation') }}
      </p>
    </div>
  </div>
</template>

<script>
import DocumentSquareField from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareField.vue'
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export default {
  name: 'TaxCertificate2024PrintableClinic',

  components: { DocumentSquareField },

  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
  },

  computed: {
    title () {
      if (this.certificate.individualEntrepreneur) {
        const fullName = vueFilters.fullName(this.certificate.individualEntrepreneurFullName)

        return `${this.t$('individualEntrepreneur')} "${fullName}"`
      }

      return this.certificate.clinic.legalName
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.print')
  },
}
</script>
