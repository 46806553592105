<template>
  <div
    class="upload-control"
    tabindex="0"
    @mouseenter="showControl = true"
    @mouseleave="showControl = false"
    @blur="showControl = false"
  >
    <slot />
    <div
      v-show="showControl"
      class="control"
    >
      <slot name="control" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadControl',
  data: () => ({
    showControl: false,
  }),
}
</script>
