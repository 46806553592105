<template>
  <m-panel
    :title="t('semds.semd119.formSections.metainformation')"
    :type="panelType"
    icon="infoLetter"
    :use-type-by-route="false"
  >
    <div class="patient-doctor-info">
      <!--  Пациент-->
      <div class="consultation-client position-relative">
        <m-input
          class="consultation-client__client-full-name"
          :value="semd.client?.fullName"
          :label="t('semds.semd119.formTitles.patient')"
          disabled
        />

        <m-icon
          v-if="semd.client?.id"
          v-tooltip="t('open_new_tab')"
          class="consultation-client__client-icon position-absolute pointer"
          color="primary"
          icon="link"
          style="right: 5px; top: 6px"
          @click="openClientPage(semd.client.id)"
        />
      </div>

      <!--  Врач-->
      <reusable-doctors-list
        :value="semd.metaData.doctor"
        :disabled="isShowMode"
        m-fixed-height
        use-first-time-fetch
        required
        validator-name="doctor"
        option-label="fullName"
        @registerValidator="$registerValidator($event)"
        @onDoctorChange="semd.setMetaDataValue('doctor', $event)"
      />
    </div>

    <m-input
      :value="semd.clinic?.title"
      :label="t('semds.semd119.formTitles.clinic')"
      disabled
    />

    <!--    Дата и время приёма-->
    <div class="consultation-date-time">
      <m-date-picker
        :value="semd.metaData.consultationDate"
        :label="t('semds.semd119.formTitles.consultationDate')"
        :disabled="isShowMode"
        value-format="yyyy-MM-dd"
        required
        validator-name="consultation_date"
        @registerValidator="$registerValidator($event)"
        @change="semd.setMetaDataValue('consultationDate', $event)"
      />

      <m-time-select
        :value="semd.metaData.consultationTimeStart && semd.metaData.consultationTimeEnd"
        :start-time="semd.metaData.consultationTimeStart"
        :end-time="semd.metaData.consultationTimeEnd"
        :label="t('semds.semd119.formTitles.consultationTime')"
        :disabled="isShowMode"
        time-range
        required
        validator-name="consultation_time"
        @registerValidator="$registerValidator($event)"
        @changeStartTime="semd.setMetaDataValue('consultationTimeStart', $event)"
        @changeEndTime="semd.setMetaDataValue('consultationTimeEnd', $event)"
      />
    </div>

    <!--    Источник оплаты-->
    <div class="payment-sources">
      <slot name="paymentSource" />
    </div>

    <!--    Тип документа-основания-->
    <slot name="foundationDocumentType" />

    <!--    Документ-основание-->
    <div class="foundation-document">
      <m-select-lazy
        v-if="semd.client"
        :value="semd.metaData._documentModel"
        :fetch-method="fetchFoundationDocuments"
        :label="t('semds.semd119.formTitles.foundationDocument')"
        full-width
        filterable
        fetch-on-open
        :disabled="isShowMode || disableDocumentField"
        :required="!disableDocumentField"
        validator-name="document"
        @registerValidator="$registerValidator($event)"
        @change="semd.setMetaDataValue('_documentModel', $event)"
      />

      <!--    Добавить новый документ-основание-->
      <reusable-documents-tree-modal
        :disabled="isShowMode || disableDocumentField"
        use-document-types
        @documentItemClick="onChangeDocumentTemplateClick"
      />
    </div>

    <!--    Страховой полис-->
    <div class="medical-policy">
      <medical-policy-search-create
        v-if="semd.client"
        :value="semd.metaData._medicalPolicyModel"
        :client-id="semd.client?.id"
        :policy-type="getMedicalPolicyType"
        full-width
        fetch-on-open
        :disabled="isShowMode || disableMedicalPolicyField"
        :required="!disableMedicalPolicyField"
        active-only
        validator-name="medical_policy"
        @registerValidator="$registerValidator($event)"
        @setMedicalPolicy="onSetMedicalPolicy"
      />
    </div>

    <slot name="foundationDocumentDateEnd" />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MTimeSelect from '@/vue_present/_base/inputs/MTimeSelect/MTimeSelect.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import {
  MDocumentsPresenter,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/MDocumentsPresenter'
import MedicalPolicySearchCreate from '@/vue_apps/ClientsModule/components/MedicalPolices/MedicalPolicySearchCreate.vue'
import ReusableDocumentsTreeModal from '@/vue_present/Reuse/DocumentTree/ReusableDocumentsTreeModal.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import {
  isNewInactiveMedicalPolicyNotify,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/const/isNewInactiveMedicalPolicyNotify'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { GLOBAL_MEDICAL_POLICY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { Semd119 } from '@/vue_apps/Semds/entities/Semd119/Semd119'

export default {
  name: 'ConsultationFormMetainfoArea',
  components: {
    MTimeSelect,
    ReusableDoctorsList,
    MIcon,
    ReusableDocumentsTreeModal,
    MedicalPolicySearchCreate,
    MSelectLazy,
    MDatePicker,
    MInput,
    MPanel,
  },

  props: {
    semd: Semd119,
    isCreateMode: { type: Boolean, default: false },
    isShowMode: { type: Boolean, default: false },
    panelType: { type: String, default: 'success' },
    disableDocumentField: Boolean,
    disableMedicalPolicyField: Boolean,
  },

  data () {
    return {
      foundationDocumentPresenter: new MDocumentsPresenter(),
    }
  },

  computed: {
    getMedicalPolicyType () {
      const documentType = this.semd.metaData.nsiFoundationDocumentType?.title

      if (!documentType) { return undefined }

      if (documentType.toUpperCase().includes(t('oms'))) {
        return GLOBAL_MEDICAL_POLICY_KINDS.OMS
      }

      if (documentType.toUpperCase().includes(t('dms'))) {
        return GLOBAL_MEDICAL_POLICY_KINDS.DMS
      }

      return undefined
    },
  },

  methods: {
    fetchFoundationDocuments (params) {
      return this.foundationDocumentPresenter.semdDocumentsList(
        {
          clientId: this.semd.client?.id,
          ...params,
        },
        { toClientAdapter: (data) => data })
    },

    async onChangeDocumentTemplateClick (template) {
      const document = await new MDocumentsPresenter().submit({
        documentTypeId: template.id,
        clientId: this.semd.client?.id,
      })

      if (document?.errors) { return }

      this.semd.setMetaDataValue('_documentModel', { id: document.id, title: document.title })
    },

    openClientPage (clientId) {
      window.open(this.$routes.client_path(clientId), '_blank')
    },

    /** @param {medicalPolicyListItemRaw | { isNew: boolean }} medicalPolicy */
    async onSetMedicalPolicy (medicalPolicy) {
      if (!medicalPolicy) {
        this.semd.setMetaDataValue('_medicalPolicyModel', null)

        return
      }

      const result = await isNewInactiveMedicalPolicyNotify(medicalPolicy)
      if (!result?.active) { return }

      this.semd.setMetaDataValue('_medicalPolicyModel', medicalPolicy)
    },
  },
}
</script>
