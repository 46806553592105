import { tScoped } from '@/helpers/i18nHelpers'

const $t = tScoped('semds.semdStatuses')

export const SEMD_STATE_ENUM = Object.freeze({
  NEED_DOCTOR_SIGNATURE: 'need_doctor_signature',
  NEED_CLINIC_SIGNATURE: 'need_clinic_signature',
  READY_TO_BE_SENT: 'ready_to_be_sent',
  SUCCESSFULLY_SENT: 'successfully_sent',
  REGISTERED_IN_EGISZ: 'registered_in_egisz',
  SENDING_ERROR: 'sending_error',
  ADDED_TO_QUEUE: 'added_to_queue',
  OUTDATED_VERSION: 'outdated_version',
})

export const SEMD_STATES = Object.freeze({
  [SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE]: SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE,
  [SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE]: SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE,
  [SEMD_STATE_ENUM.READY_TO_BE_SENT]: SEMD_STATE_ENUM.READY_TO_BE_SENT,
  [SEMD_STATE_ENUM.SUCCESSFULLY_SENT]: SEMD_STATE_ENUM.SUCCESSFULLY_SENT,
  [SEMD_STATE_ENUM.REGISTERED_IN_EGISZ]: SEMD_STATE_ENUM.REGISTERED_IN_EGISZ,
  [SEMD_STATE_ENUM.SENDING_ERROR]: SEMD_STATE_ENUM.SENDING_ERROR,
  [SEMD_STATE_ENUM.ADDED_TO_QUEUE]: SEMD_STATE_ENUM.ADDED_TO_QUEUE,
  [SEMD_STATE_ENUM.OUTDATED_VERSION]: SEMD_STATE_ENUM.OUTDATED_VERSION,
})

const SEMD_STATES_LIST = Object.freeze([
  {
    id: SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE,
    title: $t(SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE),
    icon: 'hospitalUser',
    color: 'warning',
  },
  {
    id: SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE,
    title: $t(SEMD_STATE_ENUM.NEED_CLINIC_SIGNATURE),
    icon: 'fileSignature',
    color: 'warning',
  },
  {
    id: SEMD_STATE_ENUM.READY_TO_BE_SENT,
    title: $t(SEMD_STATE_ENUM.READY_TO_BE_SENT),
    icon: 'ready',
    color: 'primary',
  },
  {
    id: SEMD_STATE_ENUM.SUCCESSFULLY_SENT,
    title: $t(SEMD_STATE_ENUM.SUCCESSFULLY_SENT),
    icon: 'shareSquare',
    color: 'success',
  },
  {
    id: SEMD_STATE_ENUM.REGISTERED_IN_EGISZ,
    title: $t(SEMD_STATE_ENUM.REGISTERED_IN_EGISZ),
    icon: 'fileCheck',
    color: 'success',
  },
  {
    id: SEMD_STATE_ENUM.SENDING_ERROR,
    title: $t(SEMD_STATE_ENUM.SENDING_ERROR),
    icon: 'warning',
    color: 'danger',
  },
  {
    id: SEMD_STATE_ENUM.ADDED_TO_QUEUE,
    title: $t(SEMD_STATE_ENUM.ADDED_TO_QUEUE),
    icon: 'conveyorAlt',
    color: 'primary',
  },
  {
    id: SEMD_STATE_ENUM.OUTDATED_VERSION,
    title: $t(SEMD_STATE_ENUM.OUTDATED_VERSION),
    icon: 'oldFile',
    color: 'grey-dark',
  },
])

export const getSemdStates = () => SEMD_STATES_LIST

/**
 * @param state
 * @return {{color: string, icon: string, title: string}}
 */
export const findSemdState = (state) => {
  const statusObj = SEMD_STATES_LIST.find(({ id }) => id === state)

  return statusObj || {
    icon: 'warning',
    color: 'warning',
    title: t('semds.semdSignModal.status404'),
  }
}

export const getCurrentDate = () => Utils.getBaseFormattedDate()

export const SEMDS_OWNER_TYPES = Object.freeze({
  PROTOCOL: 'Protocol',
  CHECKUP_ELMK: 'Checkups::Documents::Personals::ElmkMedicalAssessment',
})
