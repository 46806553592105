import { checkSnils } from '@/specific/clients/methods/snils/checkSnils'

export const snilsValidator = (value: unknown) => {
  const errors = Utils.getValidationErrors(value)

  if (errors.length) { return errors }

  if (!checkSnils(String(value))) {
    return [t('errors.invalidSnils')]
  }

  return []
}
