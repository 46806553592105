import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const $t = (variable) => {
  return t(variable, { scope: 'checkups.company.listTitles' })
}

export const getCheckupsCompanySchema = () => {
  return new MSiSchema()
    .addSearch()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addEditIcon(Services.security.canManageCheckupsCompany)
        .addHeaders({
          number: new MTableHeader($t('number')).addFixedWidth('60px'),
          status: new MTableHeader()
            .addTypeIcon('checkups')
            .addTooltip(t('status'))
            .addFixedWidth('30px'),
          period: new MTableHeader($t('date')).addFixedWidth('180px'),
          company: new MTableHeader($t('company')),
          checkupName: new MTableHeader($t('title')),
          responsible: new MTableHeader($t('responsible')).addFixedWidth('180px'),
          realWorkers: new MTableHeader()
            .addTypeIcon('worker')
            .addTooltip($t('workers'))
            .addFixedWidth('50px'),
          comment: new MTableHeader().addFixedWidth('40px'),
        })
        .addFooter()
    )
}
