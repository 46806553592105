import NameFormatter from '../../modules/name_formatter.js'

export default {
  filters: {
    shortName (fio) {
      return NameFormatter.toShort(fio)
    },
    fullName (fio) {
      return NameFormatter.toFull(fio)
    },
  },
}
