import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class FeedbackListTotalItem extends MListServiceItem {
  rawData: object

  client: MTableCell

  phone: MTableCell

  receivedAt: MTableCell

  doctor: MTableCell

  doctorScore: MTableCell

  clinicScore: MTableCell

  clientComment: MTableCell

  status: MTableCell

  urls: MTableCell

  constructor (data) {
    super()
    this.rawData = data

    this.status = new MTableCell()
    this.client = new MTableCell()
    this.phone = new MTableCell()
    this.receivedAt = new MTableCell()
    this.doctor = new MTableCell()
    this.doctorScore = new MTableCell()

    this.clinicScore = new MTableCell()

    this.clientComment = new MTableCell(data)
    this.urls = new MTableCell()
  }
}
