import Home from '@/vue_components/support/pages/Home.vue'
import CannyFeedback from '@/vue_components/support/pages/CannyFeedback.vue'
import CannyChangelog from '@/vue_components/support/pages/CannyChangelog.vue'
import SimpleImportValidator from '@/vue_components/support/pages/SimpleImportValidator'
import Education from '@/vue_components/support/pages/Education.vue'
import {
  CANNY_CHANGELOG,
  CANNY_FEEDBACK,
  CANNY_FEEDBACK_WITH_BOARD,
  CANNY_FEEDBACK_WITH_BOARD_WITH_POST,
  SIMPLE_IMPORT_VALIDATOR,
  SUPPORT_EDUCATION,
  SUPPORT_HOME,
} from './route_names'

export default [
  {
    path: '/support/feedback',
    name: CANNY_FEEDBACK,
    component: CannyFeedback,
    children: [
      {
        path: ':boardId',
        name: CANNY_FEEDBACK_WITH_BOARD,
        component: CannyFeedback,
      },
      {
        path: ':boardId/p/:postId',
        name: CANNY_FEEDBACK_WITH_BOARD_WITH_POST,
        component: CannyFeedback,
      },
    ],
  },

  {
    path: '/support/education',
    name: SUPPORT_EDUCATION,
    component: Education,
  },

  {
    path: '/support/changelog',
    name: CANNY_CHANGELOG,
    component: CannyChangelog,
  },

  {
    path: '/support/simple-import-validator',
    name: SIMPLE_IMPORT_VALIDATOR,
    component: SimpleImportValidator,
  },

  {
    path: '/support*',
    name: SUPPORT_HOME,
    component: Home,
  },
]
