import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

/**
 * @param {string[]} invalidEntitiesTitles
 * @param {string} title
 * @return {string}
 */
const getInvalidEntitiesHtml = (invalidEntitiesTitles = [], title) => {
  const beforeList = t('semds.errors.protocolsInvalids')
  const list = `<ul><li>${invalidEntitiesTitles.join('</li><li>')}</li></ul>`

  return `<div class="font-size-17 mb-indent">${title}</div> ${beforeList} ${list}`
}

/**
 * @param {string[]} invalidEntitiesTitles
 * @param {string} title
 * @return {Promise<*>}
 */
export const getInvalidSemdEntitiesDialog = (invalidEntitiesTitles = [], title) => {
  return MConfirm.error(getInvalidEntitiesHtml(invalidEntitiesTitles, title), {
    dangerouslyUseHTMLString: true,
    showCancelButton: false,
    confirmButtonText: 'ok',
    customClass: 'w-600px',
  })
}
