import {
  PersonalCheckupDoctorResultsLogic,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDoctorResults/PersonalCheckupDoctorResultsLogic'
import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'

export class PersonalCheckupDoctorResultsApi extends PersonalCheckupDoctorResultsLogic {
  constructor (props?: Pick<IPersonalCheckup, 'id' | 'doctorResults'>) {
    super(props)

    this.fetchSpecialties()

    this.fetchMedicalAssessments().then(() => {
      this.setDefaultMedicalAssessment()
    })
  }

  private async fetchSpecialties () {
    this.specialists = await this.presenter.fetchSpecialties()
  }

  private async fetchMedicalAssessments () {
    this.medicalAssessments = await this.presenter.fetchMedicalAssessments()
  }
}
