import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  companyCheckupAdapter,
  companyCheckupAdapter as companyCheckupListAdapter,
} from '@/vue_apps/CheckupsModule/api/MCompanyCheckupPresenter/companyCheckupAdapter'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import {
  CompanyCheckupCalculatedEntryCell,
} from '@/vue_apps/CheckupsModule/classes/CheckupCalculatedEntry/CompanyCheckupCalculatedEntryCell'

export class MCompanyCheckupPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.api_internal_checkups_company_path,
        all: Routes.checkups_company_index_path,
        list: Routes.sort_api_internal_checkups_companies_path,
      },
      entity: 'companyCheckup',
      location: 'MCompanyCheckupPresenter',
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: companyCheckupListAdapter.listFiltersToServer,
    })
      .then((res) => companyCheckupListAdapter.toClient(res))
  }

  submit (data, config = {}) {
    return super.submit(data, {
      ...config,
      toServerAdapter: companyCheckupAdapter.toServer,
    })
  }

  fetchAggregateCheckupEntries (id) {
    const notification = new MRequestNotification(
      'MCompanyCheckupPresenter:fetchAggregateCheckupEntries',
      'fetchMessage',
      'aggregateCheckupEntries'
    )

    return this.post({
      url: Routes.aggregate_entry_types_api_internal_checkups_companies_path(),
      data: { id },
    }).promise
      .then((data) => ({ data: data.map((item) => new CompanyCheckupCalculatedEntryCell(item)) }))
      .catch(MRequestError.onRequest(notification))
  }
}
