import { MSemdApi } from '@/vue_apps/Semds/entities/_SemdBase/MSemdApi'
import { ISemd224MetaData } from '@/vue_apps/Semds/entities/Semd224/ISemd224MetaData'
import {
  ISemd224SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd224/ISemd224SemdsDocumentDetailsAttributesData'
import { SEMD_TYPE } from '@/vue_apps/Semds/const/semdTypes'
import { SEMD224_ENTITY_NAMES } from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd224/semd224EntityNames'
import { Semd224MetaData } from '@/vue_apps/Semds/entities/Semd224/Semd224MetaData'
import {
  getDatesErrorMessageOrValid,
} from '@/vue_apps/Protocols/SemdProtocolEditor/entities/Semd224/semd224EntitiesValidators'

export class Semd224 extends MSemdApi<
  ISemd224MetaData,
  ISemd224SemdsDocumentDetailsAttributesData
> {
  requiredEntities = [
    SEMD224_ENTITY_NAMES.DATE_START,
    SEMD224_ENTITY_NAMES.TIME_START,
    SEMD224_ENTITY_NAMES.MEDICAL_CARE_PLACE,
    SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH,
  ]

  requiredAreas = [
    SEMD224_ENTITY_NAMES.INSTRUMENTAL_DIAGNOSTIC_RESEARCH_PROTOCOL_TEXT,
    SEMD224_ENTITY_NAMES.CONCLUSION,
  ]

  constructor ({ id }) {
    super({
      id,
      semdType: SEMD_TYPE.INSTRUMENTAL_RESEARCH_PROTOCOL,
    })

    this.setValue('metaData', new Semd224MetaData())
  }

  getCustomValidationMessageOrIsValid (): string | boolean {
    return getDatesErrorMessageOrValid(
      { value: this.semdsDocumentDetailsAttributes.data.dateStart },
      { value: this.semdsDocumentDetailsAttributes.data.dateEnd }
    )
  }

  protected generateOwnerUrlAfterLoad () {
    if (!this.owner.relatedEntryId) { return }

    this.ownerUrl = window.Routes.edit_entry_path(
      this.owner.relatedEntryId,
      { protocolId: this.owner.id }
    )
  }
}
