import { getCatalogYearsRange } from '@/vue_apps/CheckupsModule/store/getCatalogYearsRange'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  getPersonalCheckupAddButtonConfigCanManage,
} from '@/vue_apps/CheckupsModule/store/getPersonalCheckupAddButtonConfigCanManage'

const ts = tScoped('checkups.vaccinationsAndDisease')

const currentYear = moment().year()

export const getVaccinationAndInfectiousDiseasesDefaultDate = () => {
  return {
    id: `${currentYear}-01-01`,
    title: currentYear,
  }
}

export const getVaccinationAndInfectiousDiseasesDateItems = (birthDate: string) => getCatalogYearsRange(
  +Utils.getFormattedDate(birthDate, Utils.onlyYearDateFormat),
  currentYear
)

export const getVaccinationAndInfectiousDiseasesAddButtonConfig = (text: string, hasSpecialFactors: boolean) => ({
  color: 'primary',
  text: ts(text),
  tooltip: t('checkups.mandatoryForReceivingMedicalBook'),
  canManage: getPersonalCheckupAddButtonConfigCanManage(hasSpecialFactors),
})
