import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export const buildGuaranteeLetterTitle = (guaranteeLetter) => {
  const { number, issueDate, sum } = guaranteeLetter || {}

  return Utils.joinTruthy([
    number,
    vueFilters.date(issueDate),
    vueFilters.currency(Utils.toMoney(sum)) || '',
  ], ', ')
}

export const guaranteeLettersAdapter = {
  toClient (response) {
    return {
      ...response,
      data: (response.data || []).map((item) => {
        return {
          ...item,
          sum: Utils.toMoney(item.sum),
          title: buildGuaranteeLetterTitle(item),
        }
      }),
    }
  },
}
