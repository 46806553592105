<template>
  <div
    class="not-found-result-container hidden-print-fixed"
    :class="{ 'not-found-result-container_small': small }"
  >
    <div class="not-found-result__image">
      <img
        v-if="!filtered"
        src="@/../../assets/images/not_found_results/empty_folder.png"
        alt="empty_folder"
      >
      <img
        v-else
        src="@/../../assets/images/not_found_results/not_found_results_opacity_0667.png"
        alt="reset_filters"
      >
    </div>

    <div
      v-if="!filtered"
      class="not-found-result__text flex flex-column"
    >
      {{ text }}
      <slot name="action" />
    </div>
    <div
      v-else
      class="not-found-result__text"
    >
      <div>{{ t('introduction.not_found_results.filters_text_row_1') }}</div>
      <div class="not-found-result__secondary">
        {{ t('introduction.not_found_results.filters_text_row_2') }}
      </div>
      <div class="not-found-result__secondary">
        <slot name="action">
          <span>{{ t('or') }}</span>
          <button
            class="btn btn-link btn-sm not-found-result__reset"
            @click="$emit('reset-filters')"
          >
            {{ t('introduction.not_found_results.reset_filters') }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundResult',
  props: {
    filtered: Boolean,
    small: Boolean, // мини версия NotFoundResult
    text: { type: String, default: t('introduction.not_found_results.filters_text_row_1') },
  },
}
</script>
