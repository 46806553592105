
export const getTemplateBySemdErrors = (errors = {}) => {
  const list = Object.entries(errors)
    .reduce(
      (acc, [key, value]) => {
        const field = t(key, { scope: 'semds.validationErrors' })
        const validationErrors = Array.isArray(value)
          ? value.join(', ').toLowerCase() || ''
          : value?.toLowerCase() || ''

        return acc + `<li>${field} ${validationErrors}</li>`
      },
      ''
    )

  return `
    <div>${t('semds.hasValidationErrorsText')}</div>
    <ul>${list}</ul>
  `.trim()
}
