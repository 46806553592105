<template>
  <the-mask
    v-if="isEditMode"
    ref="mask"
    type="text"
    :value="value"
    v-bind="$attrs"
    :mask="mask"
    :placeholder="placeholder"
    v-on="listeners"
    @input="$emit('input', $event)"
    @blur.native="$emit('blur', $event)"
  />
  <span v-else> {{ maskedValue }}</span>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  components: {
    TheMask,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      changeFromParent: false,
    }
  },

  computed: {
    listeners () {
      return _.omit(this.$listeners, ['input'])
    },

    mask () {
      return `+${this.maskSettings.mask}`
    },

    maskSettings () {
      return Services.phoneMask.getMaskSettings(this.value)
    },

    placeholder () {
      return Services.phoneMask.getPlaceholder(this.mask)
    },

    isEditMode () {
      return !this.$attrs.disabled
    },

    maskedValue () {
      return `+${Services.phoneMask.setMask(this.value)}`
    },
  },

  watch: {
    maskSettings (newSettings) {
      this.$emit('mask-settings-change', newSettings)
    },

    // Нужно для обновления поля display в the-mask.
    value (newValue) {
      if (!this.$refs.mask) { return }
      this.$refs.mask.display = newValue
    },
  },
}
</script>
