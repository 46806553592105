import { MListServiceItem } from '@/_api/_requests/MListServiceItem'

export class MListServiceNestedItemsItem extends MListServiceItem {
  dbId: number | null = null

  _keep: boolean = false

  _destroy: boolean = false

  constructor (id: number, item: unknown) {
    super()

    this.id = id
    this.dbId = (item as any)?.id || null
  }
}
