/**
 * @param {string} url
 * @return {string}
 */
export const getBaseRoute = (url = '') => '/front-book' + url
/**
 * @param {string} name
 * @return {string}
 */
export const getBaseName = (name) => 'front-book-' + name

export const FRONT_BOOK_ROUTES = Object.freeze({
  INTRO_ALL: getBaseName('intro-all'),
  INTRO: getBaseName('intro'),
  M_INPUT: getBaseName('m-input'),
  M_TEXTAREA: getBaseName('m-textarea'),
  M_ICON: getBaseName('m-icon'),
  M_BUTTON: getBaseName('m-button'),
  M_CHECKBOX: getBaseName('m-checkbox'),
  M_DATE_PICKER: getBaseName('m-date-picker'),
  M_TIME_PICKER: getBaseName('m-time-picker'),
  M_SELECT: getBaseName('m-select'),
  M_FORM_SHOW: getBaseName('m-form-show'),
  M_SI_GENERATOR: getBaseName('m-si-generator'),

  DISABLED_MIXIN: getBaseName('disabled-mixin'),
  PAGINATION_MIXIN: getBaseName('pagination-mixin'),
  VALIDATION_MIXINS: getBaseName('validation-mixins'),
})
