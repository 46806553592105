import {
  PersonalSimpleMedicalAssessmentPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalSimpleMedicalAssessmentPresenter/PersonalSimpleMedicalAssessmentPresenter'
import {
  PersonalSimpleMedicalAssessmentModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalSimpleMedicalAssessment/PersonalSimpleMedicalAssessmentModel'

export class PersonalSimpleMedicalAssessmentApi extends PersonalSimpleMedicalAssessmentModel {
  private presenter = new PersonalSimpleMedicalAssessmentPresenter()

  async load () {
    const data = await this.presenter.fetch(this.id)

    if (data.errors) { return }

    this._fillProps(data)
  }

  update () {
    return this.presenter.update(this.getAttributes())
  }

  destroy () {
    return this.presenter.destroy(this.id)
  }

  refresh () {
    return this.presenter.refresh(this.id)
  }
}
