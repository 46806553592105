<template>
  <div
    ref="siControl"
    class="si-control"
  >
    <slot />

    <div class="print-content">
      <slot name="print" />
    </div>
    <div
      class="si-control-btns flex gap-indent-small"
      :class="siControlBtnsCustomClasses"
    >
      <slot name="refresh">
        <m-button
          template="short_refresh"
          :disabled="disabled"
          @click="handleRefresh"
        />
      </slot>

      <slot name="reset">
        <m-button
          type="warning"
          :tooltip="t('reset')"
          no-use-fw
          icon="no-filter"
          :disabled="disabled"
          @click="handleReset"
        />
      </slot>

      <slot name="customButtons" />
    </div>
    <div class="si-control-tools-footer">
      <slot name="toolsControl" />
    </div>
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default {
  name: 'SiControl',
  components: { MButton },

  props: {
    reset: {
      type: Boolean,
      default: true,
    },

    disabled: Boolean,

    siControlBtnsCustomClasses: {
      type: Array,
      default: () => [],
    },
  },

  mounted () {
    /**
     * todo: под удаление
     */
    Utils.updateTooltipsInContainer(this.$refs.siControl)
  },

  methods: {
    handleReset () {
      if (!this.disabled) { this.$emit('reset') }
    },

    handleRefresh () {
      if (!this.disabled) { this.$emit('refresh') }
    },
  },
}
</script>
