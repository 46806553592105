import { MPresenterBase } from '@/_api/_requests/MPresenterBase.js'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { semdAdapter } from '@/_api/MSemdApi/semdAdapter'
import { GLOBAL_HOLDER_SCOPES } from '@/_global_scripts/GLOBAL_CONSTS'

export class MSemdPresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MSemdPresenter',
      entity: 'semd',
      routes: {
        all: Routes.api_internal_semds_path,
        one: Routes.api_internal_semd_path,
        list: Routes.search_api_internal_semds_path,
      },
    })
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      ...config,
      toClientAdapter: semdAdapter.toClient,
    })
  }

  getLastAppointmentDateTime (clientId, config = {}) {
    return super.get({
      url: Routes.last_visit_api_internal_appointments_path(),
      data: { client_id: clientId },
      dataType: 'json',
      contentType: 'application/json',
      ...config,
    }).promise
      .then(MRequestSuccess.onResponse())
      .catch((err) => err.status >= 400 ? null : err)
  }

  /**
   * @param {SemdSignatureData} data
   * @param config
   */
  signByDoctor (data, config = {}) {
    const options = {
      url: Routes.sign_by_doctor_api_internal_semd_path(data.id),
      data: JSON.stringify(camelToSnake({
        signParams: semdAdapter.toSign(data),
      })),
      dataType: 'json',
      contentType: 'application/json',
      ...config,
    }

    const notification = new MRequestNotification(
      this.location,
      'signMessage',
      'semd'
    )

    return this.post(options)
      .promise
      .then((data) => MRequestSuccess.withNotify(snakeToCamel, notification)(data))
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @param {SemdSignatureData} data
   * @param config
   */
  signByOrganization (data, config = {}) {
    const options = {
      url: Routes.sign_by_organization_api_internal_semd_path(data.id),
      data: JSON.stringify(camelToSnake({
        signParams: semdAdapter.toSign(data),
      })),
      dataType: 'json',
      contentType: 'application/json',
      ...config,
    }

    const notification = new MRequestNotification(
      this.location,
      'signMessage',
      'semd'
    )

    return this.post(options)
      .promise
      .then((data) => MRequestSuccess.withNotify(snakeToCamel, notification)(data))
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @param {MassSemdSignatureData} data
   * @param config
   */
  async massSignByClinic (data, config = {}) {
    const options = {
      url: Routes.mass_sign_by_organization_api_internal_semds_path(),
      data: JSON.stringify(camelToSnake({
        signParams: semdAdapter.toMassSign(data),
      })),
      dataType: 'json',
      contentType: 'application/json',
      ...config,
    }

    const notification = new MRequestNotification(
      this.location,
      'addSignQueueMessage',
      'semds'
    )

    return this.post(options)
      .promise
      .then((data) => MRequestSuccess.withNotify(snakeToCamel, notification)(data))
      .catch(MRequestError.onRequest(notification))
  }

  async register (semdId) {
    const options = {
      url: Routes.egisz_iemk_add_med_record_path(),
      data: JSON.stringify(camelToSnake({ semdId })),
      dataType: 'json',
      contentType: 'application/json',
    }

    const notification = new MRequestNotification(
      this.location,
      'addSendQueueMessage',
      'semd'
    )

    return this.post(options)
      .promise
      .then((data) => MRequestSuccess.withNotify(snakeToCamel, notification)(data))
      .catch(MRequestError.onRequest(notification))
  }

  async massRegistration (semdIds) {
    const options = {
      url: Routes.egisz_iemk_add_med_records_path(),
      data: JSON.stringify(camelToSnake({ semdIds })),
      dataType: 'json',
      contentType: 'application/json',
    }

    const notification = new MRequestNotification(
      this.location,
      'addMassRegisterQueueMessage',
      'semds'
    )

    return this.post(options)
      .promise
      .then((data) => MRequestSuccess.withNotify(snakeToCamel, notification)(data))
      .catch(MRequestError.onRequest(notification))
  }

  async massDelete (semdIds) {
    const options = {
      url: Routes.batch_destroy_api_internal_semds_path(),
      data: JSON.stringify(camelToSnake({ semdIds })),
      dataType: 'json',
      contentType: 'application/json',
    }

    const notification = new MRequestNotification(
      this.location,
      'massDeleteMessage',
      'semds'
    )

    return this.post(options)
      .promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @param {number} semdId
   * @param scope
   * @return {Promise<void | { errors?: Record<string, string[]> }>}
   */
  removeSignature (semdId, scope = GLOBAL_HOLDER_SCOPES.PERSONAL) {
    const notification = new MRequestNotification(
      this.location,
      'deleteMessage',
      'signCertificate'
    )

    return super.post({
      url: Routes.remove_signature_api_internal_semd_path(semdId),
      data: { scope },
    }).promise
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @param {string} ownerType
   * @param {number} ownerId
   * @return {Promise<{ nsiPaymentSource: ICatalog | null, nsiFoundationDocumentType: ICatalog | null }>}
   */
  detectPaymentSource (ownerType, ownerId) {
    return super.get({
      url: Routes.detect_payment_source_api_internal_semds_path(),
      data: camelToSnake({ ownerType, ownerId }),
    }).promise
      .then(({ paymentSource, foundationDocumentType }) => ({
        nsiPaymentSource: paymentSource || null,
        nsiFoundationDocumentType: foundationDocumentType || null,
      }))
      .catch((MRequestError.withDefault({
        nsiPaymentSource: null,
        nsiFoundationDocumentType: null,
      }, null)))
  }

  fetchSemdPaymentSourceList (data, config = {}) {
    return super.post({
      url: Routes.api_internal_selectors_semds_payment_source_list_path(),
      data,
      ...config,
    }).promise
      .then(MRequestSuccess.onResponse())
      .catch((err) => err.status >= 400 ? null : err)
  }
}
