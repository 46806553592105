<template>
  <div class="m-date-picker-buttons">
    <m-buttons-group
      v-bind="$attrs"
      v-model="vModelValue"
      class="mt-0"
      :items="datePickerButtons"
      radio
      use-custom-result="simple"
    />
  </div>
</template>

<script>
import MButtonsGroup from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroup'
import { MButtonsGroupPropsMixin } from '@/vue_present/_base/buttons/MButtonsGroup/MButtonsGroupPropsMixin'
import { dateButtons, datePickerButtonsAll, getIdByPeriod } from '@/vue_present/_base/MDatePickerButtons/helpers'

export default {
  name: 'MDatePickerButtons',
  components: { MButtonsGroup },
  mixins: [MButtonsGroupPropsMixin],

  model: {
    event: 'change',
    prop: 'value',
  },

  props: {
    value: { type: Array, default: () => [] },
    /**
     * useOnly
     * @type {string[]}
     * Доступные значения:
     * DATE_PICKER_BUTTONS = Object.freeze({
     *   MONTH: 'month',
     *   WEEK: 'week',
     *   TODAY: 'today',
     *   YESTERDAY: 'yesterday',
     *   TOMORROW: 'tomorrow',
     * })
     */
    useOnly: { type: Array, default: () => [] },

    useOmit: { type: Array, default: () => [] },
  },

  computed: {
    datePickerButtons () {
      if (this.useOnly?.length) { return datePickerButtonsAll.filter(({ id }) => this.useOnly.includes(id)) }
      if (this.useOmit?.length) { return datePickerButtonsAll.filter(({ id }) => !this.useOmit.includes(id)) }

      return datePickerButtonsAll
    },

    vModelValue: {
      get () { return getIdByPeriod(this.value) },
      set (value) {
        const formattedDate = value
          ? dateButtons[value]?.value.map((date) => Utils.getBaseFormattedDate(date))
          : null

        this.$emit('change', formattedDate)
      },
    },
  },
}
</script>
