import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  getPersonalCheckupAddButtonConfigCanManage,
} from '@/vue_apps/CheckupsModule/store/getPersonalCheckupAddButtonConfigCanManage'

const ts = tScoped('checkups.laboratoryAndInstrumentalResearch')

export const getPersonalCheckupResearchResultsMsiSchema = ({
  addDeleteIcon = Services.security.canManageCheckupsCompany,
  addCreateButton = false,
} = {}) => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        researchTitle: new MTableHeader().addFixedWidth('450px'),
        date: new MTableHeader().addFixedWidth('100px'),
        medicalAssessmentTitle: new MTableHeader().addFixedWidth(),
      })
      .addDeleteIcon(addDeleteIcon)
  )
  .addCreateButton(addCreateButton)

export const getPersonalCheckupResearchResultsAddButtonConfig = (text: string, hasSpecialFactors: boolean) => ({
  color: 'primary',
  text: ts(text),
  tooltip: t('checkups.mandatoryForReceivingMedicalBook'),
  canManage: getPersonalCheckupAddButtonConfigCanManage(hasSpecialFactors),
})

export const PERSONAL_CHECKUP_INSTRUMENTAL_RESEARCH_PATHOLOGY_NOT_FOUND_NSI_CODE = 56

export const PERSONAL_CHECKUP_INSTRUMENTAL_RESEARCH_PATHOLOGY_FOUND_NSI_CODE = 57

export const PERSONAL_CHECKUP_LABORATORY_RESEARCH_INFECTION_NOT_FOUND_NSI_CODE = 54

export const PERSONAL_CHECKUP_LABORATORY_RESEARCH_INFECTION_FOUND_NSI_CODE = 55
