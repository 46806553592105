<template>
  <personal-checkup-nested-items-modal-base
    class-css="personal-checkup-infectious-diseases-modal"
    :visible="visible"
    :dialog-title="t$('pastInfectionDiseases')"
    modal-title-icon="virus"
    nested-items-props-name="infectiousDiseases"
    :personal-checkup="personalCheckup"
    :msi-schema="infectiousDiseasesMsiSchema"
    :is-new-mode="isNewMode"
    @close="$emit('close')"
  >
    <template #body>
      <m-prompt-notice :text="t$('notice')" />

      <div class="flex gap-indent-small">
        <m-icon
          v-tooltip="questionMarkTooltip"
          class="personal-checkup-infectious-diseases-modal__large-icon"
          icon="questionCircle"
          color="gray"
        />

        <!-- Заболевание -->
        <m-select
          class="personal-checkup-infectious-diseases-modal__nsi_diagnosis"
          :value="nsiDiagnosis"
          :items="nsiDiagnosisItems"
          option-notice="nsiId"
          :label="t('checkups.vaccinationsAndDisease.disease')"
          filterable
          :custom-filter="nsiEntrySearchFilter"
          required
          validator-name="nsiDiagnosis"
          @registerValidator="onRegisterValidator($event)"
          @change="nsiDiagnosis = $event"
        />

        <!-- Дата -->
        <m-select
          v-model="date"
          :items="infectiousDiseasesDateItems"
          :label="t('yearCapital')"
          :clearable="false"
          required
          validator-name="date"
          @registerValidator="onRegisterValidator($event)"
          @change="date = $event"
        />

        <m-button
          template="add"
          :text="t('add')"
          @click="onAdd"
        />
      </div>
    </template>
  </personal-checkup-nested-items-modal-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  getInfectiousDiseasesMsiSchema,
} from '@/vue_apps/CheckupsModule/store/InfectiousDiseases/InfectiousDiseasesConst'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import {
  getVaccinationAndInfectiousDiseasesDateItems,
  getVaccinationAndInfectiousDiseasesDefaultDate,
} from '@/vue_apps/CheckupsModule/store/personalCheckupVaccinationAndInfectiousDiseases/personalCheckupVaccinationAndInfectiousDiseasesConsts'
import PersonalCheckupNestedItemsModalBase
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupNestedItemsModalBase.vue'
import { nsiEntrySearchFilter } from '@/helpers/lambda'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'
import { personalCheckupQuestionMarkMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupQuestionMarkMixin'

export default defineComponent({
  name: 'PersonalCheckupInfectiousDiseasesModal',

  components: {
    PersonalCheckupNestedItemsModalBase,
    MButton,
    MSelect,
    MIcon,
    MPromptNotice,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
    personalCheckupQuestionMarkMixin,
  ],

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      infectiousDiseasesDateItems: [],
      infectiousDiseasesMsiSchema: getInfectiousDiseasesMsiSchema(),
      nsiDiagnosisItems: [],
      nsiDiagnosis: null,
      date: getVaccinationAndInfectiousDiseasesDefaultDate(),
    }
  },

  created () {
    this.setI18nScope('checkups.vaccinationsAndDisease.infectiousDiseases')

    this.infectiousDiseasesDateItems = getVaccinationAndInfectiousDiseasesDateItems(
      this.personalCheckup.client.birthdate || moment()
    )

    this.fetchInfectiousDiagnosesItems()
  },

  methods: {
    nsiEntrySearchFilter,

    onAdd () {
      if (this.hasErrors()) { return }

      this.personalCheckup.infectiousDiseases.nestedItems.append({
        nsiDiagnosisId: this.nsiDiagnosis.id,
        nsiDiagnosisTitle: this.nsiDiagnosis.title,
        nsiDiagnosisNsiId: this.nsiDiagnosis.nsiId,
        date: this.date.id,
      })
    },

    async fetchInfectiousDiagnosesItems () {
      this.nsiDiagnosisItems = await this
        .personalCheckup
        .infectiousDiseases
        .fetchInfectiousDiagnoses(this.availableSpecialFactors)
    },
  },
})
</script>
