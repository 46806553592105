import { request } from '@/lib/transport/request'

export const paymentsEndpoint = {
  create (params) {
    return request({
      type: 'POST',
      url: Routes.payments_path(),
      data: params,
    }).promise
  },

  createBulk (payments) {
    return request({
      type: 'POST',
      url: Routes.mass_pay_payments_path(),
      data: payments,
    }).promise
  },
}
