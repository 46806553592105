<template>
  <div class="document-bolt-dot">
    <div class="document-bolt-dot__wrapper">
      •
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentBoltDot',
}
</script>
