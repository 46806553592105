export const ModalMixin = {
  data () {
    return {
      modalVisible: false,
    }
  },

  methods: {
    openModal () {
      this.modalVisible = true
    },

    closeModal () {
      this.modalVisible = false
    },
  },
}
