<template>
  <div class="messages-configuration-section-title mb-indent-validation">
    <div>
      <span class="title title_huge">
        {{ title }}
      </span>
      <span v-if="tagText">
        <m-tag
          type="info"
          class="bold"
        >
          {{ tagText }}
        </m-tag>
      </span>
    </div>
    <div
      v-if="subtitle"
      class="messages-configuration-section-title__subtitle explanation"
    >
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import MTag from '@/vue_present/_base/MTag/MTag.vue'

export default {
  name: 'MessagesConfigurationSectionTitle',
  components: { MTag },

  props: {
    title: { type: String, default: '' },
    tagText: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
}
</script>
