<template>
  <panel-heading
    :title="`${t('info_company')} - ${t('orders')}`"
    icon="fad fa-shopping-cart"
    class="company-router-container"
  >
    <div class="company-orders">
      <si>
        <si-control
          :disabled="tableSpinner"
          @reset="resetFilters"
        >
          <search-input
            :value.sync="filterState.selectedOrderId"
            :placeholder="t('order_number')"
            :debounce="400"
            class="search"
          />

          <date-range-picker-btn
            v-model="filterState.selectedDateRange"
            class="si-control-item"
            :disabled="tableSpinner"
            :fallback-value="[]"
            :unpressed="true"
          />

          <date-range-picker
            v-model="filterState.selectedDateRange"
            class="si-control-item"
            :disabled="tableSpinner"
            :fallback-value="[]"
            :format="GET_LOCALIZATION_DATEPICKER_FORMAT"
          />

          <base-select
            v-model="filterState.selectedOrderPaidStatus"
            class="si-control-item"
            clearable
            reset-value="all"
            :disabled="tableSpinner"
          >
            <el-option
              value="all"
              :label="t('all_statuses')"
            />

            <el-option
              v-for="(orderPaidStatusTranslate, orderPaidStatus) in orderPaidStatuses"
              :key="orderPaidStatus"
              :value="orderPaidStatus"
              :label="orderPaidStatusTranslate"
            />
          </base-select>

          <template #refresh>
            <span />
          </template>
        </si-control>

        <si-table
          :spinner="tableSpinner"
          :not-found="!fetched.orders.list.length"
          :error="fetched.orders.error"
        >
          <template v-slot:header>
            <si-field>
              <div class="number">
                {{ t('common.number') }}
              </div>

              <div class="date">
                {{ t('common.date') }}
              </div>

              <div class="title">
                {{ t('client') }}
              </div>

              <div class="paid-status">
                {{ t('status') }}
              </div>

              <div class="final-sum">
                {{ t('to_pay') }}
              </div>

              <div class="paid-sum">
                {{ t('made_payment_by_bill') }}
              </div>
            </si-field>
          </template>

          <template v-slot:body>
            <si-field
              v-for="order in fetched.orders.list"
              :key="order.id"
            >
              <div class="number">
                <a
                  :href="$routes.order_path(order.id)"
                  target="_blank"
                >
                  {{ order.id }}
                </a>
              </div>

              <div class="date">
                {{ order.date | formatDate(GET_LOCALIZATION_DATE_FORMAT) }}
              </div>

              <div class="title">
                <a
                  v-if="order.client"
                  :href="$routes.client_path(order.client.id)"
                  target="_blank"
                  class="ignore-click"
                >
                  {{ order.client | shortName }}
                </a>
              </div>

              <div class="paid-status">
                <span :class="ORDER_STATUS_CLASSES[order.paid_status.toUpperCase()]">
                  {{ t(`_order_statuses.${order.paid_status}`) }}
                </span>
              </div>

              <div class="final-sum">
                {{ order.final_sum | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>

              <div class="paid-sum">
                {{ order.paid_sum | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
              </div>
            </si-field>
          </template>
        </si-table>

        <si-footer>
          <pagination
            layout="prev, pager, next"
            :page-size="pagination.pageSize"
            :page-count="pagination.pageCount"
            :current-page.sync="pagination.currentPage"
          />
        </si-footer>
      </si>
    </div>
  </panel-heading>
</template>

<script>
import BaseSelect from '@/vue_components/common/select/base_select/base_select.vue'
import DateRangePicker from '@/vue_components/common/select/date_range_picker.vue'
import DateRangePickerBtn from '@/vue_components/common/select/date_range_picker_btn.vue'
import Pagination from '@/vue_components/common/pagination.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import SearchInput from '@/vue_components/common/search_input.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'

import { mapGetters } from 'vuex'
import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { currencyFormatter, dateFormatter, mapFormatter, nameFormatter } from '@/vue_components/mixins/formatters.js'
import { DEFAULT_FILTER_STATE } from './consts.js'
import { DATE_ISO_FORMAT, ORDER_STATUS_CLASSES, STORAGE_KEY } from '../consts.js'
import { generateUID, getCurrentStorage } from '../../helpers/storage.js'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'

export default {
  name: 'CompanyOrders',

  components: {
    BaseSelect,
    DateRangePicker,
    DateRangePickerBtn,
    Pagination,
    PanelHeading,
    SearchInput,
    Si,
    SiControl,
    SiField,
    SiFooter,
    SiTable,
  },

  mixins: [dateFormatter, currencyFormatter, mapFormatter, nameFormatter],

  data () {
    const uid = generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    const localStore = getCurrentStorage(STORAGE_KEY, uid)
    const filterState = { ...DEFAULT_FILTER_STATE }

    if (localStore.ordersFilter) {
      const { ordersFilter } = localStore

      if (Array.isArray(ordersFilter.selectedDateRange)) {
        filterState.selectedDateRange = ordersFilter.selectedDateRange.map((date) => new Date(date))
      }

      if (ordersFilter.selectedOrderPaidStatus) {
        filterState.selectedOrderPaidStatus = ordersFilter.selectedOrderPaidStatus
      }

      if (ordersFilter.selectedOrderId) {
        filterState.selectedOrderId = parseInt(ordersFilter.selectedOrderId)
      }
    }

    return {
      filterState,
      fetched: {
        orders: { list: [], error: false, loading: false },
      },
      pagination: {
        pageSize: PAGINATION_PAGE_SIZE,
        pageCount: 0,
        currentPage: 1,
      },
      ORDER_STATUS_CLASSES,
    }
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_DATE_FORMAT',
      'GET_LOCALIZATION_DATEPICKER_FORMAT',
      'GET_LOCALIZATION_CURRENCY_PARAMS',
      'GET_APP_CONF_CURRENT_USER_ID',
    ]),

    currentStorage () {
      return generateUID(this.$store.getters.GET_APP_CONF_CURRENT_USER_ID, this.$route.params.companyId)
    },

    orderPaidStatuses () {
      return Object.fromEntries(Object.entries(t('_order_statuses'))
        .filter(([status]) => status !== 'unknown_paid_status'))
    },

    ordersFilters () {
      const {
        selectedOrderId,
        selectedDateRange,
        selectedOrderPaidStatus,
      } = this.filterState

      if (selectedOrderId) return { id_pattern: selectedOrderId }

      let dateParams = {
        start_date: undefined,
        end_date: undefined,
      }

      if (Array.isArray(selectedDateRange)) {
        const dateRange = selectedDateRange.map((date) => moment(date).format(DATE_ISO_FORMAT))
        dateParams = {
          start_date: !dateRange.length ? undefined : dateRange[0],
          end_date: !dateRange.length ? undefined : dateRange[1],
        }
      }

      return {
        ...dateParams,
        paid_status: selectedOrderPaidStatus === 'all' ? undefined : selectedOrderPaidStatus,
      }
    },

    reqOrdersParams () {
      return {
        ...this.ordersFilters,
        per_page: this.pagination.pageSize,
        page: this.pagination.currentPage,
      }
    },

    tableSpinner () {
      return this.GET_COMPANY_LOADING || this.fetched.orders.loading
    },
  },

  watch: {
    filterState: {
      deep: true,
      handler (newState) {
        const oldStore = window.store.get(STORAGE_KEY) || {}
        window.store.set(STORAGE_KEY, {
          ...oldStore,
          [this.currentStorage]: {
            ...oldStore[this.currentStorage],
            ordersFilter: { ...newState },
          },
        })
      },
    },

    reqOrdersParams (newParams, oldParams) {
      this.fetchOrders()
    },

    ordersFilters () {
      this.pagination.currentPage = 1
    },
  },

  mounted () {
    this.fetchOrders()
  },

  methods: {
    resetFilters () {
      this.filterState = { ...DEFAULT_FILTER_STATE }
    },

    fetchOrders () {
      this.fetched.orders.loading = true
      this.fetched.orders.error = false

      companiesEndpoint.getOrders(this.$route.params.companyId, this.reqOrdersParams)
        .then((response) => {
          const { orders, total_pages: pageCount } = response

          this.fetched.orders.list = [ ...orders ]
          this.pagination.pageCount = pageCount
        })
        .catch(() => {
          this.fetched.orders.error = true
          Notificator.error(t('abstract_error_message'))
        })
        .finally(() => {
          this.fetched.orders.loading = false
        })
    },

    directToOrderPage (orderId) {
      window.open(Routes.order_path(orderId), '_blank')
    },
  },
}

</script>
