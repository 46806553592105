import {
  PersonalCheckupInfectiousDiseasesPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInfectiousDiseasesPresenter/PersonalCheckupInfectiousDiseasesPresenter'
import { INsiEntry } from '@/_declarations/INsiEntry'
import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'
import { NestedItemsApi } from '@/_api/_classes/NestedItems/classes/NestedItemsApi'
import {
  PersonalCheckupInfectiousDiseasesListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupInfectiousDiseases/PersonalCheckupInfectiousDiseasesListItem'
import {
  IPersonalCheckupInfectiousDiseasesItem,
} from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupInfectiousDiseasesItem'

export class PersonalCheckupInfectiousDiseasesApi extends NestedItemsApi<
  PersonalCheckupInfectiousDiseasesListItem,
  IPersonalCheckupInfectiousDiseasesItem,
  PersonalCheckupInfectiousDiseasesPresenter
> {
  constructor (props?: Pick<IPersonalCheckup, 'id' | 'infectiousDiseases'>) {
    super({
      ownerId: props?.id,
      nestedItemsProps: props?.infectiousDiseases,
      nestedItemsPropName: 'infectiousDiseases',
      nestedItemsClass: PersonalCheckupInfectiousDiseasesListItem,
      presenter: new PersonalCheckupInfectiousDiseasesPresenter(),
    })
  }

  async fetchInfectiousDiagnoses (nsiFactorCodes: number[]): Promise<INsiEntry[]> {
    return this.presenter.fetchInfectiousDiagnoses({
      nsiFactorCodes,
    })
  }
}
