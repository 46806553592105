import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'
import {
  personalCheckupVaccinationAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupVaccinationPresenter/personalCheckupVaccinationAdapter'

export class PersonalCheckupVaccinationPresenter extends NestedItemsPresenter {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
      },

      adapter: personalCheckupVaccinationAdapter,

      singularEntity: 'personalCheckupVaccination',
      entity: 'personalCheckupVaccinations',
      location: 'PersonalCheckupVaccinationPresenter',
    })
  }

  fetchMibpDiseasesGroups (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'mibpDiseases'
    )

    return super.post({
      url: Routes.list_api_internal_checkups_nsi_dictionaries_mibp_diseases_groups_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
