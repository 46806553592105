<template>
  <div
    class="support-education"
    v-html="t('introduction.support_education')"
  />
</template>

<script>
export default {
  name: 'Education',
}
</script>
