<template>
  <div class="semd230-form semd-form">
    <div class="semd-form__section flex-basis-50">
      <m-panel
        class="semd230-form__document"
        :title="t('semds.semd230.document')"
        :type="modalType"
        :use-type-by-route="false"
      >
        <slot name="state" />

        <slot name="semdSource" />

        <!-- Название -->
        <m-input
          :value="semd.title"
          :label="t('title')"
          :disabled="isShowMode"
          required
          validator-name="title"
          @input="semd.setValue('title', $event)"
          @registerValidator="$emit('registerValidator', $event)"
        />

        <!-- Версия -->
        <semd-version :value="semd.version" />

        <!-- Уровень конфиденциальности документа -->
        <semd-privacy-level
          :value="semd.metaData.nsiPrivacyLevel"
          :disabled="isShowMode"
          @change="semd.setMetaDataValue('nsiPrivacyLevel', $event)"
          @registerValidator="$emit('registerValidator', $event)"
          @registerSetDefaultPrivacyLevelHook="setDefaultPrivacyLevelHook = $event"
        />

        <!-- Дата создания -->
        <semd-date
          :value="semd.date"
          :disabled="isShowMode"
          @change="semd.setValue('date', $event)"
          @registerValidator="$emit('registerValidator', $event)"
        />

        <template #footer>
          <slot name="createdUpdatedByAt" />
        </template>
      </m-panel>
    </div>

    <div class="semd-form__section flex-basis-50">
      <m-panel
        class="semd230-form__metainfo"
        :title="t('semds.semd230.metainfo')"
        :type="modalType"
        :use-type-by-route="false"
      >
        <!-- Клиент  -->
        <reusable-client-link
          v-if="semd.client"
          :id="semd.client.id"
          :value="semd.client.fullName"
        />

        <!-- Председатель ВК -->
        <m-input
          disabled
          :label="t('semds.semd230.responsible')"
          :value="semd.semdsDocumentDetailsAttributes?.data?.responsible?.fullName"
        />

        <!-- Клиника -->
        <m-input
          disabled
          :label="t('clinic')"
          :value="semd.clinic?.title"
        />

        <!-- Компания  -->
        <reusable-client-link
          v-if="semd.metaData.company"
          :id="semd.metaData.company.id"
          :value="semd.metaData.company.title"
          client-type="company"
        />

        <!-- Подразделение -->
        <m-input
          disabled
          :label="t('department')"
          :value="semd.metaData.department"
        />

        <!-- Должность -->
        <m-input
          :value="semd.metaData.nsiProfession?.title"
          :label="t('position')"
          disabled
        />
      </m-panel>

      <slot name="registrationArea" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import SemdPrivacyLevel from '@/vue_apps/Semds/SemdModal/components/shared/SemdPrivacyLevel.vue'
import SemdDate from '@/vue_apps/Semds/SemdModal/components/shared/SemdDate.vue'
import SemdVersion from '@/vue_apps/Semds/SemdModal/components/shared/SemdVersion.vue'
import ReusableClientLink from '@/vue_present/Reuse/Inputs/ReusableClientLink.vue'
import { Semd230 } from '@/vue_apps/Semds/entities/Semd230/Semd230'

export default defineComponent({
  name: 'Semd230Form',
  components: {
    ReusableClientLink,
    SemdVersion,
    SemdDate,
    SemdPrivacyLevel,
    MInput,
    MPanel,
  },

  props: {
    semd: Semd230,
    modalType: { type: String, default: 'primary' },
    isCreateMode: { type: Boolean, default: false },
    isShowMode: { type: Boolean, default: false },
    isEditMode: { type: Boolean, default: false },
  },

  emits: [
    'registerInitForm',
    'registerValidator',
    'resetValidations',
  ],

  data () {
    return {
      setDefaultPrivacyLevelHook () {},
    }
  },

  created () {
    const vm = this
    this.$emit('registerInitForm', vm.onInitForm)
  },

  methods: {
    onInitForm () {
      if (!this.semd?.id) {
        this.setDefaultPrivacyLevelHook()
      }
    },
  },
})
</script>
