<template>
  <div class="sign-popover-view">
    <span class="flex flex-column">
      <span> {{ t('egisz.components.egisz_med_documents.form.workplace') }}  <required-mark /></span>
      <workplace-list
        ref="workplaceList"
        :value="workplaceId"
        :validation-messages="validationMessages.workplaceId || []"
        class="flex"
        @change="$updateSync('workplaceId', $event)"
      />
    </span>

    <span class="flex flex-column">
      <span> {{ t('egisz.components.egisz_med_documents.form.certificate') }}  <required-mark /></span>
      <workplace-certificates-list
        :value="certificateId"
        :workplace-id="workplaceId"
        :local-machine="localMachine"
        :validation-messages="validationMessages.certificateId || []"
        class="flex"
        @change="$updateSync('certificateId', $event)"
      />
    </span>

    <el-checkbox
      v-if="useLocalMachine"
      :checked="localMachine"
      @change="$updateSync('localMachine', $event)"
    >
      {{ t('egisz.components.egisz_med_documents.form.local_machine') }}
    </el-checkbox>

    <el-checkbox
      v-if="useResign"
      :checked="resign"
      @change="$updateSync('resign', $event)"
    >
      {{ t('egisz.components.egisz_cases.sign_register_modal.resign') }}
    </el-checkbox>
  </div>
</template>

<script>
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import WorkplaceList from '@/vue_components/WorkplaceList/WorkplaceList'
import WorkplaceCertificatesList from '@/vue_components/WorkplaceCertificatesList/WorkplaceCertificatesList'

export default {
  name: 'SignPopoverView',
  components: { WorkplaceCertificatesList, WorkplaceList, RequiredMark },
  props: {
    validationMessages: {
      type: Object,
      default: () => ({}),
    },

    workplaceId: {
      type: Number,
      default: null,
    },

    certificateId: {
      type: String,
      default: null,
    },

    useLocalMachine: Boolean,
    localMachine: Boolean,

    useResign: Boolean,
    resign: Boolean,
  },

  methods: {
    loadWorkplaceList () {
      this.$refs.workplaceList.loadWorkplaceList()
    },
  },
}
</script>
