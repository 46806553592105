<template>
  <panel-heading
    class="egisz-case__card egisz-case__card_registration"
    :title="t('egisz.medical_cases.form.registration_title')"
    icon="fad fa-fw fa-clipboard-list-check"
  >
    <div class="row">
      <div class="col-xs-6">
        <div class="flex-form egisz-case__flex-form flex-form__35-65">
          <div class="form-label egisz-case__form-label reset-paddings">
            {{ t('egisz.medical_cases.form.register_in_egisz') }}
          </div>
          <div class="form-value egisz-case__form-value reset-paddings">
            <yes-no
              :read-only="readOnly"
              :value="registerInEgisz"
              @input="$updateSync('registerInEgisz', $event)"
            />
          </div>
        </div>
      </div>

      <div class="col-xs-6">
        <div class="flex-form egisz-case__flex-form flex-form__35-65">
          <div class="form-label egisz-case__form-label reset-paddings">
            {{ t('egisz.medical_cases.form.registration_status') }}
          </div>
          <div class="form-value egisz-case__form-value reset-paddings">
            {{ t(`egisz.common_statuses.${registrationStatus}`) }}
          </div>
        </div>
      </div>
    </div>
  </panel-heading>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading'
import YesNo from '@/vue_components/common/buttons/YesNo/YesNo'

export default {
  name: 'CardRegistration',
  components: { YesNo, PanelHeading },
  props: {
    registerInEgisz: {
      type: Boolean,
      required: true,
      default: false,
    },

    registrationStatus: {
      type: String,
      default: null,
    },

    readOnly: Boolean,
  },
}
</script>
