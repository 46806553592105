<template>
  <div class="sms-templates-catalogs-modal">
    <m-modal
      :visible.sync="modalVisibility"
      :dialog-title="dialogTitle"
      width="500px"
      @close="closeModal"
    >
      <template #body>
        <m-input
          v-if="mode === SMS_TEMPLATES_MODAL_MODES.CREATE"
          v-model="newTemplateName"
          required
          validator-name="template_name"
          :placeholder="t('sms.enter_template_name')"
          @registerValidator="onRegisterValidator"
        />

        <el-tree
          v-if="modalVisibility"
          :props="elTreeProps"
          :load="loadNode"
          lazy
          :render-content="treeNodeRenderFunction"
          node-key="id"
          @node-click="handleNodeClick"
        />
      </template>
      <template
        v-if="mode === SMS_TEMPLATES_MODAL_MODES.CREATE"
        #footer-right-action
      >
        <m-button
          template="save"
          @click="saveTemplate"
        />
      </template>
    </m-modal>

    <m-button
      :text="t('sms.insert_template_from_catalog')"
      type="success"
      icon="upload"
      size="mini"
      @click="openShowModal"
    />

    <m-button
      :text="t('sms.save_to_catalog')"
      type="dark"
      icon="save"
      size="mini"
      @click="openCreateModal"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import { SMS_TEMPLATES_MODAL_MODES } from '@/vue_present/VariablesEditor/consts/consts'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { MDeliveryPresenter } from '@/_api/Deliveries/MDeliveryPresenter'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { treeNodeRenderFunction } from '@/vue_present/VariablesEditor/consts/treeRenderFunction'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'SmsTemplatesCatalogsModal',
  components: { MInput, MModal, MButton },

  mixins: [ValidationParentMixin],

  props: {
    editorValue: PropsTypes.String(),
    templateType: PropsTypes.String(),
  },

  emits: ['insertTemplate'],

  data () {
    return {
      modalVisibility: false,
      mode: '',
      SMS_TEMPLATES_MODAL_MODES,
      newTemplateName: '',
      elTreeProps: {
        label: 'title',
        isLeaf: 'leaf',
      },
      currentCategoryId: 0,

      treeNodeRenderFunction,
    }
  },

  computed: {
    dialogTitle () {
      return this.mode === SMS_TEMPLATES_MODAL_MODES.SHOW
        ? t('sms.select_template')
        : t('sms.save_template')
    },
  },

  methods: {
    openShowModal () {
      this.modalVisibility = true
      this.mode = SMS_TEMPLATES_MODAL_MODES.SHOW
    },

    openCreateModal () {
      this.modalVisibility = true
      this.mode = SMS_TEMPLATES_MODAL_MODES.CREATE
    },

    closeModal () {
      this.modalVisibility = false
    },

    async loadNode (node, resolve) {
      let templatesList = await new MDeliveryPresenter().fetchSmsTemplates(node.data?.id || 0)

      templatesList = templatesList.filter((item) => !Object.hasOwn(item, 'template_type') || item.template_type === this.templateType)

      this.currentCategoryId = node.data?.id || 0

      resolve(templatesList)
    },

    async saveTemplate () {
      if (this.hasErrors()) { return }

      const newTemplate = {
        messagesTemplate: {
          title: this.newTemplateName,
          text: this.editorValue,
          categoryId: this.currentCategoryId,
          templateType: this.templateType,
        },
      }

      const smsTemplate = await new MDeliveryPresenter().createSmsTemplate(newTemplate)
      if (smsTemplate?.errors) { return }

      this.closeModal()
    },

    handleNodeClick (data) {
      if (!data.leaf) { return }

      this.$emit('insertTemplate', data.text)
      this.closeModal()
    },
  },
}
</script>
