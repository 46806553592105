import {
  DocumentListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/DocumentsTab/api/DocumentListItem'
import { orUndefined } from '@/_medods_standart_library/msl'

export const documentsListAdapter = {
  toClient (response) {
    return {
      ...response,
      data: response.data.map((item) => new DocumentListItem(item)),
    }
  },

  toServer (payload) {
    return {
      date: payload.period,
      documentTypeId: orUndefined(payload.type),
      kind: orUndefined(payload.kind),
      clientId: payload.clientId,
      withLegalRepresentatives: payload.withLegalRepresentatives,
      offset: payload.offset,
      limit: payload.limit,
    }
  },
}
