<template>
  <!-- Ячейка телефон -->
  <span
    v-tooltip="tooltip"
    class="phone_number cut"
    :class="css"
    :data-phone="value"
    :data-client-id="clientId"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'TypePhone',
  props: [
    'tooltip',
    'value',
    'css',
    'clientId',
  ],
}
</script>
