
export const getDefaultPersonalCheckupClient = () => ({
  id: null,
  medicalRecordId: null,
  surname: null,
  name: null,
  secondName: null,
  fullName: null,
  phone: null,
  birthdate: null,
  sex: null,
  age: null,
  position: null,
  nsiProfession: null,
  snils: null,
})
