<template>
  <div class="introduction-container">
    <div class="introduction-icon-container">
      <i class="fad fa-atlas" />
    </div>
    <div class="introduction-description-container">
      <div
        class="introduction-description"
        v-html="t('introduction.support')"
      />
      <div class="telegram-qr">
        <img
          src="@/../../assets/images/telegram_qr.png"
          alt="telegram_qr"
        >
      </div>
      <div
        class="introduction-description"
        v-html="t('introduction.support_contacts')"
      />
      <div class="telegram-qr">
        <img
          src="@/../../assets/images/telegram_support_qr.png"
          alt="telegram_support_qr"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
