export const DATEPICKER_TYPES = Object.freeze({
  DATE: 'date',
  DATE_RANGE: 'daterange',
})

export const setDateRange = (range) => {
  return [
    moment(range[0]).hour(0).minutes(0).seconds(0).toDate(),
    moment(range[1]).hour(23).minutes(59).seconds(59).toDate(),
  ]
}
