import { DateHelpers } from '@/helpers/DateHelpers'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { RECEIPT_STATUSES_ICONS } from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/getReceiptIcons'
import {
  NEGATIVE_PAYMENT_KINDS,
  PAYMENT_KINDS_ICONS,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/paymentsFiltersConsts'

const getOdrerNumberText = (order) => {
  return `${t('finance.orderNumberText')} ${order}`
}

const isPaymentNegative = (payment, sum) => {
  return NEGATIVE_PAYMENT_KINDS.includes(payment.kind) && sum !== '0.0'
    ? `- ${sum}`
    : sum
}

const workplaceEnabled = gon.application.workplaces_module_enabled

export class PaymentsListCellItem extends MListServiceItem {
  constructor (rawData) {
    super()
    const date = DateHelpers.getTodayTomorrowYesterday(rawData.date)

    this.id = rawData.id

    this.number = new MTableCell(rawData.id).addTypeUrl(Routes.payment_path(rawData.id))

    this.kind = new MTableCell()
      .addTypeIcon(PAYMENT_KINDS_ICONS[rawData.kind].icon)
      .addClasses(PAYMENT_KINDS_ICONS[rawData.kind].class)
      .addTooltip(PAYMENT_KINDS_ICONS[rawData.kind].tooltip)

    this.date = new MTableCell(date.formattedDate).addClasses(date.dateCss)

    this.customer = new MTableCell(rawData.payerTitle)
      .addTooltip(rawData.payerTitle)
      .addCut()

    this.byCash = new MTableCell(isPaymentNegative(rawData, rawData.byCash))

    this.byCashless = new MTableCell(isPaymentNegative(rawData, rawData.byCashless))

    this.byCard = new MTableCell(isPaymentNegative(rawData, rawData.byCard))

    this.byBalance = new MTableCell(isPaymentNegative(rawData, rawData.byBalance))

    this.byCredit = new MTableCell(isPaymentNegative(rawData, rawData.byCredit))

    this.totalIncome = new MTableCell(isPaymentNegative(rawData, rawData.totalIncome))

    this.totalPaid = new MTableCell(isPaymentNegative(rawData, rawData.totalPaid))

    this.order = rawData.orderAttributes.id && !rawData.orderAttributes.deletedAt
      ? new MTableCell(rawData.orderAttributes.finalSum)
        .addTypeUrl(Routes.order_path(rawData.orderAttributes.id))
        .addTooltip(getOdrerNumberText(rawData.orderAttributes.id))
      : rawData.orderAttributes.deletedAt
        ? new MTableCell(t('finance.orderDeleted')).addClasses('danger')
        : new MTableCell()

    if (workplaceEnabled) {
      this.receipt = new MTableCell()
        .addTypeIcon(RECEIPT_STATUSES_ICONS[rawData.receiptStatus.status].icon)
        .addClasses(RECEIPT_STATUSES_ICONS[rawData.receiptStatus.status].color)
        .addTooltip(rawData.receiptStatus.translate)
    }
  }
}
