<template>
  <div
    v-if="visible"
    class="vue-image-cropper"
  >
    <vue-cropper
      ref="cropper"
      class="cropped-image"
      preview=".preview"
      :src="imageSource"
      v-bind="settings"
    />
    <div
      v-if="showPreview"
      class="preview-box"
    >
      <div class="preview" />
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'

const DEFAULT_SETTINGS_CROPPER = Object.freeze({
  'view-mode': 3,
  'drag-mode': 'move',
  'aspect-ratio': 1,
  'auto-crop-area': 0.5,
  'min-container-width': 250,
  'min-container-height': 180,
  guides: true,
  background: true,
  rotatable: true,
})

export default {
  name: 'ImageCropper',
  components: { VueCropper },
  props: {
    settings: {
      type: Object,
      default: () => DEFAULT_SETTINGS_CROPPER,
    },
    targets: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageSource: '',
    visible: false,
  }),
  computed: {
    showPreview () {
      return (this.preview && this.visible)
    },
  },
  beforeDestroy () {
    const {
      SET_IMAGE,
      CROP_IMAGE,
    } = this.targets

    this.$pubSub.reset(SET_IMAGE)
    this.$pubSub.reset(CROP_IMAGE)
  },
  mounted () {
    const {
      SET_IMAGE,
      CROP_IMAGE,
      CROPPED_IMAGE,
    } = this.targets

    this.$pubSub.subscribe(SET_IMAGE, (file) => {
      this.visible = true
      this.setImageSource(file)
    })

    this.$pubSub.subscribe(CROP_IMAGE, () => {
      if (this.visible) {
        this.$pubSub.emitAsync(CROPPED_IMAGE, this.$refs.cropper.getCroppedCanvas())
      } else throw new Error('Missing image. Set image first')
    })
  },
  methods: {
    setImageSource (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        this.imageSource = event.target.result
        this.$refs.cropper.replace(event.target.result)
      }
      reader.readAsDataURL(file)
    },
  },
}

</script>
