import { extractId, orUndefined } from '@/_medods_standart_library/msl'

export const fastCreateAdapter = {
  toServer (payload) {
    return {
      clientsData: payload.map((client) => {
        const [dateStart, dateEnd] = client.date

        return {
          id: orUndefined(client.id),
          name: client.name,
          surname: client.surname,
          secondName: orUndefined(client.secondName),
          sexId: client.sex.id,
          birthdate: client.birthdate,
          phone: Utils.extractDigits(client.phone),
          snils: orUndefined(client.snils),
          patientCardNumber: orUndefined(client.patientCardNumber),
          position: orUndefined(client.position),
          nsiProfessionId: orUndefined(client.nsiProfession?.id),

          personalCheckupsAttributes: [{
            responsibleId: client.responsibleId,
            clinicId: client.clinicId,
            companyId: client.companyId,
            companyCheckupId: client.companyCheckupId,
            dateStart,
            dateEnd,
            nextDate: client.nextDate,
            department: client.department,
            position: orUndefined(client.position),
            nsiProfessionId: orUndefined(client.nsiProfession?.id),
            kind: client.kind,
            status: client.status,
            checkupFactorIds: client.factors.map(extractId),
          }],
        }
      }),
    }
  },
}
