import masks from './masks.json'

/**
 * @typedef {{mask: string, hidden_mask: string, length: number}} MaskSettings
 */

const MASK_SYMBOL = '#'
export const HIDE_SYMBOL = 'X'
const NOT_A_NUMBER = /\D/g

export default class MaskMatcher {
  constructor (options = {}) {
    this.defaultMask = options.defaultMask || '# (###) ###-##-##'
    this.defaultHiddenMask = options.defaultHiddenMask || '# (#XX) XXX-##-##'
    this.defaultMaskSettings = {
      mask: this.defaultMask,
      hidden_mask: this.defaultHiddenMask,
      length: this.countDigitsInMask(this.defaultMask),
    }
  }

  /**
   * @param {string} [phone]
   * @return {MaskSettings}
   */
  getMaskSettings (phone) {
    if (phone) {
      return this.getMatchingMaskSettings(phone)
    }

    return this.defaultMaskSettings
  }

  /**
   * @param {string} phone
   * @return {MaskSettings}
   */
  getMatchingMaskSettings (phone) {
    const maskSetWithLongestCode = this.findMaskSetWithLongestCode(phone)
    if (!maskSetWithLongestCode) return this.defaultMaskSettings

    const matchingSettings = maskSetWithLongestCode.find((maskSettings) => {
      return phone.length <= maskSettings.length
    })
    if (!matchingSettings) return this.defaultMaskSettings

    if (!matchingSettings.hidden_mask) {
      matchingSettings.hidden_mask = this.defaultHiddenMask
    }

    return matchingSettings
  }

  /**
   * @param {string} phone
   * @return {MaskSettings[]|undefined}
   */
  findMaskSetWithLongestCode (phone) {
    for (let i = phone.length; i > 0; i--) {
      if (masks[phone.substring(0, i)]) {
        return masks[phone.substring(0, i)]
      }
    }
  }

  /**
   * @param {string} mask
   * @return {number}
   */
  countDigitsInMask (mask) {
    return mask
      .split('')
      .reduce((count, char) => {
        return char === MASK_SYMBOL ? count + 1 : count
      }, 0)
  }

  setMask (phone, mask, hiddenMask, applySecurity = true) {
    const defaultMask = this.getMaskSettings(phone)
    const targetMask = mask || defaultMask.mask
    const targetHiddenMask = hiddenMask || defaultMask.hidden_mask

    if (!phone || !targetMask || targetMask.length < phone.length) {
      return phone
    }

    const usedMask = (!this.canViewPhone() && applySecurity) ? targetHiddenMask : targetMask
    let maskChar
    let phoneWithMask = ''
    let mappedPosition = 0

    for (let i = 0; i < usedMask.length && mappedPosition < phone.length; i++) {
      maskChar = usedMask.charAt(i)

      if (maskChar === MASK_SYMBOL) {
        phoneWithMask += phone.charAt(mappedPosition)
        mappedPosition += 1
      } else if (maskChar === HIDE_SYMBOL) {
        phoneWithMask += HIDE_SYMBOL
        mappedPosition += 1
      } else {
        phoneWithMask += maskChar
      }
    }

    return phoneWithMask
  }

  canViewPhone () {
    return Services.security.canManageClientPhone
  }

  updateMask (phone) {
    return this.setMask(this.removeMask(phone))
  }

  removeMask (phone) {
    return typeof phone === 'string' ? phone.replace(NOT_A_NUMBER, '') : ''
  }

  getPlaceholder (mask, placeholderChar = '_') {
    return mask.replace(new RegExp(MASK_SYMBOL, 'g'), placeholderChar)
  }
}
