import { DATE_ISO_FORMAT, STORAGE_KEY } from '@/vue_components/companies/components/consts'
import { NestedSerializableParams } from '@/lib/storage/nested_serializable_params'

/**
 * @class CompanyDocumentsFilters
 * @property {string} documentId
 * @property {Date[]|null} dateRange
 */
export class CompanyDocumentsFilters extends NestedSerializableParams {
  constructor (userCompanyKey) {
    super(STORAGE_KEY, `${userCompanyKey}.documentsFilter`)
  }

  getDefaults () {
    return {
      documentId: '',
      dateRange: null,
    }
  }

  get () {
    const params = {
      id_pattern: this.documentId,
    }

    if (this.dateRange) {
      const [startDate, endDate] = this.dateRange.map((date) => {
        return moment(date).format(DATE_ISO_FORMAT)
      })
      params.start_date = startDate
      params.end_date = endDate
    }

    return params
  }
}
