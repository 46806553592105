export const getDateRangeValidator = (minDate: unknown, maxDate: unknown = moment()) => { // TODO moment type
  minDate = moment(minDate)
  maxDate = moment(maxDate)

  return (value: unknown) => {
    const errors = Utils.getValidationErrors(value)

    if (errors.length) { return errors }

    const date = moment(value)

    if (!date.isAfter(minDate)) {
      return [t('errors.dateCantBeThatFarInPast')]
    }

    if (!date.isBefore(maxDate)) {
      return [t('errors.dateCannotBeInFuture')]
    }

    return []
  }
}
