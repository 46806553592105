<template>
  <div class="document-black-square">
    ■
  </div>
</template>

<script>
export default {
  name: 'DocumentBlackSquare',
}
</script>
