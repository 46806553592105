<template>
  <validation-wrapper
    :errors="validationMessages"
    :fixed-height="fixedHeight"
  >
    <div class="native-datetime-picker">
      <input
        v-model="currentDate"
        class="simple-datetime-picker__date"
        type="date"
      >
      <input
        v-model="currentTime"
        class="simple-datetime-picker__time"
        type="time"
      >
    </div>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'

const NATIVE_DATE_FORMAT = 'YYYY-MM-DD'
const NATIVE_TIME_FORMAT = 'HH:mm'
const NATIVE_TIME_ZERO = '00:00'

export default {
  name: 'NativeDateTimePicker',
  components: { ValidationWrapper },

  props: {
    value: {
      type: [Date, String],
      default: null,
    },

    validationMessages: {
      type: Array,
      default: () => [],
    },

    fixedHeight: Boolean,
  },

  computed: {
    currentDate: {
      get () {
        return !this.value
          ? null
          : moment(this.value).format(NATIVE_DATE_FORMAT)
      },

      set (value) {
        this.setDateTime(value, this.currentTime)
      },
    },

    currentTime: {
      get () {
        return !this.value
          ? moment(new Date()).format(NATIVE_TIME_FORMAT)
          : moment(this.value).format(NATIVE_TIME_FORMAT)
      },

      set (value) {
        this.setDateTime(this.currentDate, value)
      },
    },
  },

  watch: {
    currentDate (to) {
      this.setDateTime(to, this.currentTime)
    },

    currentTime (to) {
      this.setDateTime(this.currentDate, to)
    },
  },

  methods: {
    setDateTime (date, time) {
      let dateTime = null

      if (date && time) {
        const mDate = moment(date).format(NATIVE_DATE_FORMAT)
        dateTime = moment(`${mDate} ${time || NATIVE_TIME_ZERO}`).toDate()
      }

      this.$emit('input', dateTime)
    },
  },
}
</script>
