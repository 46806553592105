import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'
import {
  personalCheckupInfectiousDiseasesAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInfectiousDiseasesPresenter/personalCheckupInfectiousDiseasesAdapter'

export class PersonalCheckupInfectiousDiseasesPresenter extends NestedItemsPresenter {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
      },

      adapter: personalCheckupInfectiousDiseasesAdapter,

      singularEntity: 'personalCheckupInfection',
      entity: 'personalCheckupInfectiousDiseases',
      location: 'PersonalCheckupInfectiousDiseasesPresenter',
    })
  }

  fetchInfectiousDiagnoses (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'infectiousDiagnoses'
    )

    return super.post({
      url: Routes.api_internal_selectors_checkups_infectious_diagnoses_list_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
