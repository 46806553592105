export const DisablePrintClinicHeaderMixin = {
  created () {
    const el = document.querySelector('.print-area__clinic-header')
    if (!el) { return }
    el.classList.add('hidden-print')
  },

  beforeDestroy () {
    const el = document.querySelector('.print-area__clinic-header')
    if (!el) { return }
    el.classList.remove('hidden-print')
  },
}
