<template>
  <div class="e404 flex w-100 h-100 align-items-center justify-content-center">
    <not-found-result :text="t('pageNotFound')">
      <template #action>
        <slot name="action" />
      </template>
    </not-found-result>
  </div>
</template>

<script>
import NotFoundResult from '@/vue_components/common/not_found_result.vue'

export default {
  name: 'E404',
  components: { NotFoundResult },
}
</script>
