import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('checkups.personal.positionProfileItems')

export const PERSONAL_CHECKUP_POSITION_PROFILE_ITEMS = Object.freeze([
  {
    id: 1,
    title: ts('workersInAgriculturalOrganizations'),
  },
  {
    id: 2,
    title: ts('workersOfPointsBasesWareHousesTransportingFoodAndFoodRawMaterials'),
  },
])
