const SPLIT_SEP = '_'
const KEY_ID = 'id'
const KEY_INDEX = 'index'
const ENTITY_INDEX = 1
const VALUE_INDEX = 2
const REQUIRED_LENGTH = 3
const PERMITTED_KEYS = [KEY_ID, KEY_INDEX]

const getFalseOrId = (id) => id >= 0 && id

/**
 * Переделывает массив валидаций в объект валидаций
 *
 * [{ step_0: {...} }, { step_2: {...} }] ---> { 0: {...}, 2: {...} }
 *
 * @param stepValidations
 * @return {*}
 */
export const transformStepsValidations = (stepValidations) => {
  return stepValidations.reduce((result, item) => {
    const key = Object.keys(item)[0]
    if (!key) { return }

    const splitKey = key.split(SPLIT_SEP)
    if (splitKey.length !== REQUIRED_LENGTH) { return }
    if (!PERMITTED_KEYS.includes(splitKey[1])) { return }

    const keyEntity = splitKey[ENTITY_INDEX]
    const tempIndex = parseInt(splitKey[VALUE_INDEX])
    const index = tempIndex >= 0
      ? tempIndex
      : -1

    if (index < 0) { return }

    const id = keyEntity === KEY_ID
      ? tempIndex
      : -1

    result[index] = { ...item[key], id: getFalseOrId(id) }

    return result
  }, {})
}

export const isValid = (validationObject) => {
  for (const item in validationObject) {
    if (validationObject[item].length) { return false }
  }

  return true
}
