import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { tScoped } from '@/helpers/i18nHelpers'

const $t = tScoped('feedback.tableTitles')

export const getFeedbackTableSchema = () => {
  return new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addHeaders({
          status: new MTableHeader()
            .addFixedWidth('70px')
            .addTypeIcon('completed')
            .addTooltip($t('status')),
          client: new MTableHeader($t('client')).addFixedWidth('300px'),
          phone: new MTableHeader($t('phone')).addFixedWidth('200px'),
          receivedAt: new MTableHeader($t('date')).addFixedWidth('100px'),
          doctor: new MTableHeader($t('doctor')).addFixedWidth('300px'),
          doctorScore: new MTableHeader($t('doctorScore')).addFixedWidth('150px'),
          clinicScore: new MTableHeader($t('clinicScore')).addFixedWidth('150px'),
          clientComment: new MTableHeader($t('feedback')),
          urls: new MTableHeader()
            .addFixedWidth('70px')
            .addTypeIcon('link')
            .addTooltip($t('urls')),
        })
    )
}
