import {
  AttachmentsListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/AttachmentsListItem'

export const attachmentsListAdapter = {
  toClient (response) {
    return {
      ...response,
      data: response.data.map((item) => new AttachmentsListItem(item)),
    }
  },
}
