<template />

<script>
export default {
  name: 'ApplicationNotificator',
  props: {
    position: {
      type: String,
      default: undefined,
    },

    customClass: {
      type: String,
      default: null,
    },
  },
  methods: {
    error (message, title = '', duration = 15000) {
      this.$notify.error({
        title,
        message,
        duration,
        position: this.position,
        customClass: this.customClass,
      })
    },

    info (message, title = '', duration = 10000) {
      this.$notify.info({
        title,
        message,
        duration,
        position: this.position,
        customClass: this.customClass,
      })
    },

    warning (message, title = '', duration = 10000) {
      this.$notify.warning({
        title,
        message,
        duration,
        position: this.position,
        customClass: this.customClass,
      })
    },

    success (message, title = '', duration = 10000) {
      this.$notify.success({
        title,
        message,
        duration,
        position: this.position,
        customClass: this.customClass,
      })
    },
  },
}
</script>
