export const getDefaultExpenseItem = () => {
  return {
    number: null,
    date: null,
    title: null,
    price: null,
    additional: null,
    expenseGroup: null,
    orderId: null,
  }
}
