import { egiszMedCaseStepValidations } from '@/vue_components/egisz/egisz_module/const/egisz_validations'

export default {
  methods: {
    resetStepValidations () {
      this.tempStepValidations = egiszMedCaseStepValidations()
    },

    hasErrors () {
      this.resetStepValidations()

      let result = false
      const $t = this.tempStep
      const $v = this.tempStepValidations
      const validationText = t('egisz.messages.errors.validation.texts.required')

      if (!$t.title) {
        $v.title = [validationText]
        result = true
      }

      if (!$t.opened_at) {
        $v.opened_at = [validationText]
        result = true
      }

      if (!$t.netrika_visit_place_id) {
        $v.netrika_visit_place_id = [validationText]
        result = true
      }

      if (!$t.netrika_visit_purpose_id) {
        $v.netrika_visit_purpose_id = [validationText]
        result = true
      }

      if (!$t.doctor_id) {
        $v.doctor_id = [validationText]
        result = true
      }

      return result
    },
  },
}
