import { IDate } from '@/_declarations/IDate'

export const getParsedDate = (rawDate: string, separator = '-'): IDate => {
  const date = moment(rawDate)

  if (!date.isValid()) {
    const [year, month, day] = rawDate.split(separator)

    return { year, month, day }
  }

  return {
    day: date.date(),
    month: date.format('MMMM'),
    year: date.year(),
  }
}
