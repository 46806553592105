import { request } from '@/lib/transport/request'

export const orderRegistriesEndpoint = {
  find (companyId, params) {
    return request({
      type: 'GET',
      url: Routes.search_for_company_orders_registries_company_path(companyId),
      data: params,
    }).promise
  },

  getOrdersCount (registryId) {
    return request({
      type: 'GET',
      url: Routes.orders_count_orders_registry_path(registryId),
    }).promise
  },

  get (companyId, registryId) {
    return request({
      type: 'POST',
      url: Routes.fetch_orders_registry_company_path(companyId, registryId),
    }).promise
  },

  create (params) {
    return request({
      type: 'POST',
      url: Routes.orders_registries_path(),
      data: { orders_registry: params },
    }).promise
  },

  update (registryId, params) {
    return request({
      type: 'PATCH',
      url: Routes.orders_registry_path(registryId),
      data: { orders_registry: params },
    }).promise
  },

  setRegistryForOrders (orderIds, registryId) {
    return request({
      type: 'PATCH',
      url: Routes.update_registry_id_orders_path(),
      data: { orders_registry_id: registryId, order_ids: orderIds },
    }).promise
  },

  destroy (id) {
    return request({
      type: 'DELETE',
      url: Routes.orders_registry_path(id),
    }).promise
  },
}
