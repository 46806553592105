<template>
  <input
    ref="input"
    class="form-control suggestion-search"
    :value="value"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="$updateSync('value', $event.target.value)"
  >
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DadataSelect',

  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 5,
    },
    deferRequestBy: {
      type: Number,
      default: 300,
    },
    hint: {
      type: String,
      default: t('select_option_continue_typing'),
    },
    noSuggestionsHint: {
      type: String,
      default: undefined,
    },
  },

  data () {
    return {
      optionsList: [],
      searchQuery: '',
      loading: false,
    }
  },

  computed: {
    ...mapState({
      token: (state) => state.appConf.suggestion_api,
    }),
    noResults () {
      return this.searchQuery !== '' && !this.optionsList.length
    },
    noSuggestionsHintOptions () {
      let translation = this.noSuggestionsHint

      if (!translation) {
        translation = t(`not_${this.type.toLowerCase()}`, {
          defaultValue: t('nothing_has_found'),
        })
      }

      return translation
    },
  },

  mounted () {
    $(this.$refs.input).suggestions({
      token: this.token,
      type: this.type,
      count: this.count,
      deferRequestBy: this.deferRequestBy,
      onSelect: (suggestion) => {
        this.$emit('change', suggestion)
      },
      hint: this.hint,
      noSuggestionsHint: this.noSuggestionsHintOptions,
    })
  },
}
</script>
