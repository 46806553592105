<template>
  <div class="client-widget">
    <div
      v-if="clientWidgetModuleEnabled"
      class="client-widget__module-enabled"
    >
      <div class="client-widget__messages-limit">
        <div class="messages-limit__title">
          {{ t('whatsapp.clientWidgetMessagesLimit') }}
        </div>

        <m-input
          :value="messagesLimit"
          type="number"
          class="messages-limit__input"
          :clearable="false"
          :min="1"
          :precision="0"
          @input="vxSetClientWidgetLimitPerHour"
        />
      </div>

      <div class="client-widget__waiting-time">
        <div class="waiting-time__title">
          {{ t('whatsapp.clientWidgetWaitingTime') }}
        </div>

        <m-select
          :value="vxGetClientWidgetWaitingTime"
          :items="CLIENT_WIDGET_WAITING_TIME_VARIABLES"
          class="waiting-time__select"
          use-custom-result="simple"
          :required="true"
          validator-name="clientWidgetWaitingTime"
          @registerValidator="onRegisterValidator"
          @change="vxSetClientWidgetWaitingTime"
        />
      </div>

      <messages-priorities
        v-if="vxGetCallsPriorities.length"
        :messages-priorities="callsPriorities"
        priorities-visible
        :clarification="t('whatsapp.callsPriorityClarification')"
        @updatePriorities="vxSetCallsConfigurationsPriorities"
      />

      <whatsapp-timeout-settings
        :delivery-waiting-time.sync="deliveryWaitingTime"
        :read-waiting-time.sync="readWaitingTime"
        :delivery-waiting-time-variables="ONLINE_RECORD_DELIVERY_WAITING_TIME_VARIABLES"
        :read-waiting-time-variables="ONLINE_RECORD_READ_WAITING_TIME_VARIABLES"
        @updateDeliveryWaitingTime="vxSetClientWidgetDeliveredWaitingTime"
        @updateReadWaitingTime="vxSetClientWidgetReadWaitingTime"
      />

      <variables-editor-whatsapp
        v-if="isWabaActive"
        :editor-value="wabaPasswordTemplateText"
        :editor-title="t('whatsapp.wabaPasswordTemplate')"
        :editor-title-clarification="t('whatsapp.wabaPasswordTemplateClarification')"
        disable-editor-field
      >
        <template #templateSelector>
          <m-select
            :value="wabaPasswordTemplateId"
            :items="wazzupTemplatesList"
            option-label="title"
            value-key="templateGuid"
            :placeholder="t('whatsapp.chooseValue')"
            validator-name="password"
            :required="checkTemplateVariables(wabaPasswordTemplateText)"
            @registerValidator="onRegisterValidator"
            @change="insertTemplate($event, 'password')"
          />
        </template>
      </variables-editor-whatsapp>

      <variables-editor-whatsapp
        v-if="isWabaActive"
        :editor-value="wabaConfirmationCodeTemplateText"
        :editor-title="t('whatsapp.wabaConfirmationCodeTemplate')"
        :editor-title-clarification="t('whatsapp.wabaConfirmationCodeTemplateClarification')"
        disable-editor-field
      >
        <template #templateSelector>
          <m-select
            :value="wabaConfirmationCodeTemplateId"
            :items="wazzupTemplatesList"
            option-label="title"
            value-key="templateGuid"
            :placeholder="t('whatsapp.chooseValue')"
            validator-name="confirmationCode"
            :required="checkTemplateVariables(wabaConfirmationCodeTemplateText)"
            @registerValidator="onRegisterValidator"
            @change="insertTemplate($event, 'confirmationCode')"
          />
        </template>
      </variables-editor-whatsapp>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="introductionText"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import {
  CLIENT_WIDGET_WAITING_TIME_VARIABLES,
  ONLINE_RECORD_DELIVERY_WAITING_TIME_VARIABLES,
  ONLINE_RECORD_READ_WAITING_TIME_VARIABLES,
  WABA_PASSWORD_TEMPLATE_TEXT_SECTION,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import { cloneDeep } from 'lodash'
import { MESSAGE_DELIVERY_TYPE } from '@/vue_apps/CommunicationModule/Messages/const/const'
import Introduction from '@/vue_components/introduction/introduction.vue'
import {
  findAndExtractWabaTemplateText,
  extractWabaTemplateText,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'client_widget'

export default {
  name: 'OnlineRecord',
  components: {
    Introduction,
    MessagesPriorities,
    MButton,
    MSelect,
    VariablesEditorWhatsapp,
    WhatsappTimeoutSettings,
    MInput,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaPasswordTemplateText: '',
      wabaConfirmationCodeTemplateText: '',
      ONLINE_RECORD_DELIVERY_WAITING_TIME_VARIABLES,
      ONLINE_RECORD_READ_WAITING_TIME_VARIABLES,
      CLIENT_WIDGET_WAITING_TIME_VARIABLES,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      messagesLimit: 'vxGetClientWidgetMessagesLimit',
      vxGetClientWidgetWaitingTime: 'vxGetClientWidgetWaitingTime',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      passwordTemplateId: 'vxGetWabaPasswordTemplateId',
      confirmationCodeTemplateId: 'vxGetWabaConfirmationCodeTemplateId',
      deliveryWaitingTime: 'vxGetClientWidgetDeliveredWaitingTime',
      readWaitingTime: 'vxGetClientWidgetReadWaitingTime',
      isWabaActive: 'vxIsWabaActive',
    }),

    ...mapGetters('messagesStore/flashCallsConfigurationsStore', {
      vxGetCallsPriorities: 'vxGetCallsPriorities',
    }),

    smsModuleEnabled () {
      return gon.application.sms_module_enabled
    },

    clientWidgetModuleEnabled () {
      return gon.application.client_widget_enabled
    },

    callsPriorities () {
      if (!this.whatsappEnabled && !this.smsModuleEnabled) return []

      const priorities = cloneDeep(this.vxGetCallsPriorities)

      if (!this.whatsappEnabled) {
        return priorities.filter((item) => item.deliveryType !== MESSAGE_DELIVERY_TYPE.WHATSAPP)
      }

      if (!this.smsModuleEnabled) {
        return priorities.filter((item) => item.deliveryType !== MESSAGE_DELIVERY_TYPE.SMS)
      }

      return priorities
    },

    wabaPasswordTemplateId () {
      return { templateGuid: this.passwordTemplateId }
    },

    wabaConfirmationCodeTemplateId () {
      return { templateGuid: this.confirmationCodeTemplateId }
    },

    introductionText () {
      return this.notificationsModuleEnabled
        ? t('introduction.client_widget')
        : t('introduction.messages')
    },
  },

  created () {
    const baseRequests = [
      this.vxFetchMessagesConfigurations(),
      this.vxFetchCallsPriorities(),
    ]

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
    ]

    Promise.all([...baseRequests, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
    }),

    ...mapActions('messagesStore/flashCallsConfigurationsStore', {
      vxFetchCallsPriorities: 'vxFetchCallsPriorities',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      vxSetClientWidgetLimitPerHour: 'vxSetClientWidgetLimitPerHour',
      vxSetClientWidgetWaitingTime: 'vxSetClientWidgetWaitingTime',
    }),

    ...mapMutations('messagesStore/flashCallsConfigurationsStore', {
      vxSetCallsConfigurationsPriorities: 'vxSetCallsConfigurationsPriorities',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      vxSetPasswordWabaTemplateId: 'vxSetPasswordWabaTemplateId',
      vxSetConfirmationCodeWabaTemplateId: 'vxSetConfirmationCodeWabaTemplateId',
      vxSetClientWidgetDeliveredWaitingTime: 'vxSetClientWidgetDeliveredWaitingTime',
      vxSetClientWidgetReadWaitingTime: 'vxSetClientWidgetReadWaitingTime',
    }),

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialWabaTemplatesTexts()
    },

    setInitialWabaTemplatesTexts () {
      this.wabaPasswordTemplateText = this.passwordTemplateId
        ? findAndExtractWabaTemplateText(
          this.wazzupTemplatesList,
          this.passwordTemplateId
        )
        : ''

      this.wabaConfirmationCodeTemplateText = this.confirmationCodeTemplateId
        ? findAndExtractWabaTemplateText(
          this.wazzupTemplatesList,
          this.confirmationCodeTemplateId
        )
        : ''
    },

    setWabaTemplateText (text, field) {
      field === WABA_PASSWORD_TEMPLATE_TEXT_SECTION
        ? this.wabaPasswordTemplateText = text
        : this.wabaConfirmationCodeTemplateText = text
    },

    insertTemplate (template, field) {
      field === WABA_PASSWORD_TEMPLATE_TEXT_SECTION
        ? this.vxSetPasswordWabaTemplateId(template?.templateGuid)
        : this.vxSetConfirmationCodeWabaTemplateId(template?.templateGuid)

      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText, field)
    },

    checkTemplateVariables (text) {
      return () => {
        if (!text) { return [] }

        const variablePattern = /{{\s*\w+\s*}}/g
        const variableMatches = text.match(variablePattern) || []

        return variableMatches.length > 1
          ? [t('whatsapp.clientWidgetVariablesValidationError')]
          : []
      }
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const messagesConfigurations = {
        clientWidgetLimitPerHour: this.messagesLimit,
        clientWidgetWaitingTime: this.vxGetClientWidgetWaitingTime,
      }
      const whatsappConfigurations = {
        passwordWabaTemplateId: this.wabaPasswordTemplateId?.templateGuid,
        confirmationCodeWabaTemplateId: this.wabaConfirmationCodeTemplateId?.templateGuid,
        clientWidgetDeliveredWaitingTime: this.deliveryWaitingTime,
        clientWidgetReadWaitingTime: this.readWaitingTime,
      }
      const clientWidgetPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.vxGetCallsPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(clientWidgetPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
