import { props as baseProps } from '@/vue_present/_base/buttons/MButton/props'
import { difference } from 'lodash'
import { MEDIA_TYPES } from '@/helpers/mediaTypes'

const {
  buttonStyle,
  size,
  text,
  loading,
  tooltip,
  type,
  icon,
  iconColor,
  noUseFw,
  plusIcon,
  useBrandIcon,
  useSolidIcon,
} = baseProps

export const props = {
  buttonStyle,
  size,
  text,
  loading,
  tooltip,
  icon,
  iconColor,
  noUseFw,
  plusIcon,
  useBrandIcon,
  useSolidIcon,
  type,

  multiple: Boolean,
  template: { type: String, default: 'upload' },
  disabled: { type: [String, Boolean, Object], default: false },

  accept: {
    type: Array,
    default: () => [],
    validator: (value) =>
      difference(value, Object.values(MEDIA_TYPES)).length === 0,
  },
}
