<template>
  <m-panel
    :title="t$('title')"
    class="personal-checkup-laboratory-and-instrumental-research"
  >
    <m-si-generator
      class="personal-checkup-laboratory-and-instrumental-research__item"
      :items="instrumentalResearchResultsItems"
      :si-generator-schema="msiSchema"
      :add-button-config="instrumentalResearchResultsAddButtonConfig"
      @onAddItem="showInstrumentalResearchResultsModal = true"
      @onItemDelete="personalCheckup.instrumentalResearchResults.destroy($event.id)"
    />

    <personal-checkup-instrumental-research-results-modal
      :visible="showInstrumentalResearchResultsModal"
      :personal-checkup="personalCheckup"
      :is-new-mode="isNewMode"
      :is-show-mode="isShowMode"
      :is-edit-mode="isEditMode"
      @close="showInstrumentalResearchResultsModal = false"
    />

    <m-si-generator
      class="personal-checkup-laboratory-and-instrumental-research__item"
      :items="laboratoryResearchResultsItems"
      :si-generator-schema="msiSchema"
      :add-button-config="laboratoryResearchResultsAddButtonConfig"
      @onAddItem="showLaboratoryResearchResultsModal = true"
      @onItemDelete="personalCheckup.laboratoryResearchResults.destroy($event.id)"
    />

    <personal-checkup-laboratory-research-results-modal
      v-if="showLaboratoryResearchResultsModal"
      :visible="showLaboratoryResearchResultsModal"
      :personal-checkup="personalCheckup"
      :is-new-mode="isNewMode"
      :is-show-mode="isShowMode"
      :is-edit-mode="isEditMode"
      @close="showLaboratoryResearchResultsModal = false"
    />
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import PersonalCheckupLaboratoryResearchResultsModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupLaboratoryResearchResultsModal.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import PersonalCheckupInstrumentalResearchResultsModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupInstrumentalResearchResultsModal.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import {
  getPersonalCheckupResearchResultsAddButtonConfig,
  getPersonalCheckupResearchResultsMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupResearchResults/personalCheckupResearchResultsConsts'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'

export default defineComponent({
  name: 'PersonalCheckupLaboratoryAndInstrumentalResearch',

  components: {
    PersonalCheckupInstrumentalResearchResultsModal,
    MPanel,
    PersonalCheckupLaboratoryResearchResultsModal,
    MSiGenerator,
  },

  mixins: [
    i18nScopeMixin,
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
  },

  data () {
    return {
      msiSchema: getPersonalCheckupResearchResultsMsiSchema({ addCreateButton: true }),
      showInstrumentalResearchResultsModal: false,
      showLaboratoryResearchResultsModal: false,
    }
  },

  computed: {
    instrumentalResearchResultsItems () {
      return this.personalCheckup
        .instrumentalResearchResults
        .getArr()
    },

    laboratoryResearchResultsItems () {
      return this.personalCheckup
        .laboratoryResearchResults
        .getArr()
    },

    instrumentalResearchResultsAddButtonConfig () {
      return getPersonalCheckupResearchResultsAddButtonConfig(
        'instrumental.title',
        this.hasSpecialFactors
      )
    },

    laboratoryResearchResultsAddButtonConfig () {
      return getPersonalCheckupResearchResultsAddButtonConfig(
        'laboratory.title',
        this.hasSpecialFactors
      )
    },
  },

  created () {
    this.setI18nScope('checkups.laboratoryAndInstrumentalResearch')
  },
})
</script>
