<template>
  <m-panel
    class="company-checkups-personal-checkups"
    :title="t('checkups.company.personalCheckups.title')"
  >
    <m-si-generator
      :items="list.data"
      :si-generator-schema="siSchema"
      :search-query="list.searchQuery"
      :page-count="list.totalPages"
      :current-page="list.currentPage"
      :search-placeholder="t('checkups.searchPersonalPeriodicPlaceholder')"
      @update:currentPage="list.fetchPage($event)"
      @update:searchQuery="list.search($event)"
      @onAddItem="onAddPersonalCheckup"
      @onItemClick="onItemClick"
      @onItemEdit="onItemEdit"
      @onItemDelete="onItemDelete"
    >
      <template
        v-if="!isEditMode"
        #add
      >
        <m-button
          template="add"
          disabled="CheckupsCompany"
          @click="onAddPersonalCheckup"
        />

        <m-button
          type="success"
          icon="bolt"
          disabled="CheckupsCompany"
          :tooltip="t('checkups.fastCreate.fastCreate')"
          plus-icon
          @click="showFastCreateModal = true"
        />
      </template>

      <template #searchFilters>
        <m-select
          :value="list.filtersData.status"
          :items="vxGetStatusesWithIcons"
          :placeholder="t('checkups.company.personalCheckups.allStatusesPlaceholder')"
          :m-fixed-height="false"
          use-custom-result="simple"
          use-icon
          @change="list.setFilterValueWithFetchAll('status', $event)"
        >
          <template #prefix>
            <m-icon
              v-if="list.filtersData.status"
              :icon="CHECKUP_STATUS_ICONS[filters.status].icon"
              :color="CHECKUP_STATUS_ICONS[filters.status].color"
            />
            <m-icon
              v-else
              icon="checkups"
              color=""
            />
          </template>
        </m-select>
      </template>
    </m-si-generator>

    <fast-create-modal
      v-if="showFastCreateModal"
      visible
      :company-checkup="companyCheckup"
      @close="showFastCreateModal = false"
      @onSubmit="list.fetchAll()"
    />
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { mapGetters } from 'vuex'
import { PersonalCheckupList } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupList'
import { CheckupsComputedMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsComputedMixin'
import { CompanyCheckup } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckup'
import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { CHECKUP_STATUS_ICONS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import { getPersonalPeriodicCheckupRoute } from '@/vue_apps/CheckupsModule/router/routeHelpers'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import FastCreateModal from '@/vue_apps/CheckupsModule/components/CompanyCheckup/FastCreateModal/FastCreateModal.vue'
import {
  getCompanyCheckupPersonalCheckupsMsiSchema,
} from '@/vue_apps/CheckupsModule/store/getCompanyCheckupPersonalCheckupsMsiSchema'

export default {
  name: 'CompanyCheckupPersonalCheckups',
  components: {
    FastCreateModal,
    MButton,
    MIcon,
    MSelect,
    MSiGenerator,
    MPanel,
  },
  mixins: [
    CheckupsComputedMixin,
  ],

  props: {
    companyCheckupId: { type: Number, default: null },
    companyCheckup: CompanyCheckup,
  },

  data () {
    return {
      /**@type {PersonalCheckupList} */
      list: null,
      periodicCheckupModal: {
        visible: false,
        id: null,
      },
      CHECKUP_STATUS_ICONS,
      showFastCreateModal: false,
    }
  },

  computed: {
    ...mapGetters('checkupsStore', {
      vxGetStatusesWithIcons: 'vxGetStatusesWithIcons',
    }),

    siSchema () {
      return this.isShowMode
        ? getCompanyCheckupPersonalCheckupsMsiSchema()
        : getCompanyCheckupPersonalCheckupsMsiSchema(false, false)
    },

    filters () {
      return this.list.filtersData
    },
  },

  async created () {
    const companyCheckupId = this.companyCheckupId
    if (!companyCheckupId) { return }

    this.list = new PersonalCheckupList({ status: null, companyCheckupId })

    await this.fetchPersonalCheckups()

    this.subscribeToUpdatePersonalCheckupsList()
  },

  methods: {
    subscribeToUpdatePersonalCheckupsList () {
      this.$pubSub.subscribe(
        'broadcast:updatePersonalCheckupsList', () => {
          this.list.fetchAll()
        }
      )
    },

    async fetchPersonalCheckups () {
      if (this.isNewMode) { return }
      await this.list.fetchAll()
    },

    async onAddPersonalCheckup () {
      window.open(this.$route.path + '/new', '_blank')
    },

    onItemClick ({ id }) {
      window.open(getPersonalPeriodicCheckupRoute(this.companyCheckup.id, id))
    },

    onItemEdit ({ id }) {
      window.open(getPersonalPeriodicCheckupRoute(this.companyCheckup.id, id, true))
    },

    async onItemDelete ({ id }) {
      const { errors } = await new MPersonalCheckupPresenter().destroy(id)
      if (errors) { return this.errorsHandler(errors) }
      await this.list.fetchAll()
    },

    errorsHandler (errors) {
      if (errors === 'exists_error') {
        window.Notificator.error(window.t('semds.errors.semdsExists'))
      }
    },
  },
}
</script>
