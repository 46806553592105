import { HIDE_SYMBOL } from '@/exts/phone_mask/mask_matcher'
import { orUndefined } from '@/_medods_standart_library/msl'

export const clientAdapter = {
  // Костыль для сохранения клиента
  toServerShort (client) {
    return {
      client: {
        id: orUndefined(client.id),
        surname: client.surname,
        name: client.name,
        secondName: client.secondName,
        birthdate: client.birthdate,
        phone: client.phone?.includes(HIDE_SYMBOL)
          ? HIDE_SYMBOL
          : Services.phoneMask.removeMask(client.phone),
        sexId: client.sex.id,
        position: client.position,
        nsiProfessionId: client.nsiProfession?.id,
        snils: client.snils,
      },
    }
  },
}
