import { IPersonalCheckup } from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckup'
import {
  IPersonalCheckupDiagnosesPersonalItem,
} from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupDiagnosesPersonalItem'
import {
  PersonalCheckupDiagnosesPersonalListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDiagnosesPersonal/PersonalCheckupDiagnosesPersonalListItem'
import { NestedItemsApi } from '@/_api/_classes/NestedItems/classes/NestedItemsApi'
import {
  PersonalCheckupDiagnosesPersonalPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDiagnosesPersonalPresenter/PersonalCheckupDiagnosesPersonalPresenter'

export class PersonalCheckupDiagnosesPersonalModel extends NestedItemsApi<
  PersonalCheckupDiagnosesPersonalListItem,
  IPersonalCheckupDiagnosesPersonalItem,
  PersonalCheckupDiagnosesPersonalPresenter
> {
  constructor (props?: Pick<IPersonalCheckup, 'id' | 'diagnosesPersonal'>) {
    super({
      ownerId: props?.id,
      nestedItemsProps: props?.diagnosesPersonal,
      nestedItemsPropName: 'diagnosesPersonal',
      nestedItemsClass: PersonalCheckupDiagnosesPersonalListItem,
      presenter: new PersonalCheckupDiagnosesPersonalPresenter(),
    })
  }
}
