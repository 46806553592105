const matchedVariablesList = [
  'client_feedback.feedback_url',
  'client.full_name',
  'client.name',
  'client.surname',
  'client.second_name',
  'client.bonus_points',
  'clinic.message_title',
  'clinic.message_address',
  'clinic.formatted_phone1',
  'clinic.formatted_phone2',
  'clinic.feedback_url',
  'user.name',
  'user.surname',
  'user.short_name',
  'user.specialty',
  'user.feedback_url',
  'appointment.date_dd_mm',
  'appointment.time',
  'appointment.confirmation_url',
  'client_widget_configuration.widget_url',
  'order.id',
]

export const smsTemplateVariablesValues = matchedVariablesList.reduce((acc, item) => {
  acc[item] = `${t(`whatsapp.matchedVariablesList.${item}`)}`

  return acc
}, {})

export const smsTemplateVariablesLabels = matchedVariablesList.reduce((acc, item) => {
  acc[`${t(`whatsapp.matchedVariablesList.${item}`)}`] = item

  return acc
}, {})
