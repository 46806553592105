<template>
  <introduction
    :icon="'fad fa-browser'"
    :introduction-text="t('egisz.intro')"
    hide-button
  />
</template>

<script>
import Introduction from '@/vue_components/introduction/introduction'

export default {
  name: 'Intro',
  components: { Introduction },
}
</script>
