export const SMS_TEMPLATE_CATEGORY_TYPE = 13
export const BALANCE_REFRESH_BUTTON_MS_TIME = 15000
export const MAX_SMS_SYMBOLS_LENGTH = 67
export const PREVIEW_SMS_PATH = Routes.messages_preview_path()
export const CATEGORY_TYPE = 13
export const CATEGORY_PATH = Routes.messages_templates_categories_path()
export const SAVE_TEMPLATE_PATH = Routes.messages_templates_path()
export const REWRITE_TEMPLATE_PATH = Routes.messages_template_path
export const BY_ORDER_TRIGGER_TYPE = 'by_order'
export const BY_ENTRY_TRIGGER_TYPE = 'by_entry'

const clientWidgetEnabled = gon.application.client_widget_enabled

export const GLOBAL_VARIABLES_LIST = [
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
      { source: 'bonus_points' },
    ],
  },
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
      { source: 'feedback_url' },
    ],
  },
  {
    source: 'user',
    children: [
      { source: 'name' },
      { source: 'surname' },
      { source: 'short_name' },
      { source: 'specialty' },
      { source: 'feedback_url' },
    ],
  },
  {
    source: 'appointment',
    children: [
      { source: 'date_dd_mm' },
      { source: 'time' },
      { source: 'confirmation_url', required: true },
    ],
  },
  {
    source: 'client_widget_configuration',
    if: clientWidgetEnabled,
    children: [
      { source: 'widget_url' },
    ],
  },
  {
    source: 'client_feedback',
    if: clientWidgetEnabled,
    children: [
      {
        source: 'feedback_url',
        required: true,
      },
    ],
  },
]

export const APPOINTMENT_VARIABLES_LIST = [
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
      { source: 'bonus_points' },
    ],
  },
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
    ],
  },
  {
    source: 'user',
    children: [
      { source: 'name' },
      { source: 'surname' },
      { source: 'short_name' },
      { source: 'specialty' },
    ],
  },
  {
    source: 'appointment',
    children: [
      { source: 'date_dd_mm' },
      { source: 'time' },
    ],
  },
  {
    source: 'client_widget_configuration',
    if: clientWidgetEnabled,
    children: [
      { source: 'widget_url' },
    ],
  },
]

export const CLIENTS_DISPATCH_VARIABLES_LIST = [
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
      { source: 'bonus_points' },
    ],
  },
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
    ],
  },
  {
    source: 'client_widget_configuration',
    if: clientWidgetEnabled,
    children: [
      { source: 'widget_url' },
    ],
  },
]

export const CLIENTS_BIRTHDAY_VARIABLES_LIST = [
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
      { source: 'bonus_points' },
    ],
  },
  {
    source: 'client_widget_configuration',
    if: clientWidgetEnabled,
    children: [
      { source: 'widget_url' },
    ],
  },
]

export const SERVICES_STATUS_VARIABLES_LIST = [
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
    ],
  },
  {
    source: 'order',
    children: [
      { source: 'id' },
    ],
  },
  {
    source: 'client_widget_configuration',
    if: clientWidgetEnabled,
    children: [
      { source: 'widget_url' },
    ],
  },
]

export const CLIENTS_FEEDBACK_VARIABLES_LIST = [
  {
    source: 'client_widget_configuration',
    children: [
      { source: 'widget_url' },
    ],
  },
  {
    source: 'client_feedback',
    children: [
      { source: 'feedback_url' },
    ],
  },
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
      { source: 'bonus_points' },
    ],
  },
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
      { source: 'feedback_url' },
    ],
  },
  {
    source: 'user',
    children: [
      { source: 'name' },
      { source: 'surname' },
      { source: 'short_name' },
      { source: 'specialty' },
      { source: 'feedback_url' },
    ],
  },
  {
    source: 'appointment',
    children: [
      { source: 'date_dd_mm' },
      { source: 'time' },
    ],
  },
]

export const APPOINTMENT_CONFIRMATION_VARIABLES_LIST = [
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
    ],
  },
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
    ],
  },
  {
    source: 'user',
    children: [
      { source: 'name' },
      { source: 'surname' },
      { source: 'short_name' },
      { source: 'specialty' },
    ],
  },
  {
    source: 'appointment',
    children: [
      { source: 'date_dd_mm' },
      { source: 'time' },
      { source: 'confirmation_url', required: true },
    ],
  },
  {
    source: 'client_widget_configuration',
    if: clientWidgetEnabled,
    children: [
      { source: 'widget_url' },
    ],
  },
]
