<template>
  <div class="clinic-address-tab pt-6">
    <!--  Номер лицензии  -->
    <m-input
      :label="ts('license')"
      :value="clinic.license"
      :disabled="disabled"
      @input="clinic.setValue('license', $event)"
    />

    <!--  Дата выдачи лицензии  -->
    <m-date-picker
      :value="clinic.licenseIssueDate"
      :label="ts('license_issue_date')"
      value-format="yyyy-MM-dd"
      :disabled="disabled"
      @change="clinic.setValue('licenseIssueDate', $event)"
    />

    <!--  Дата окончания лицензии  -->
    <m-date-picker
      :value="clinic.licenseIssueDateEnd"
      :label="ts('license_issue_date_end')"
      value-format="yyyy-MM-dd"
      :disabled="disabled"
      @change="clinic.setValue('licenseIssueDateEnd', $event)"
    />

    <!--  Кем выдана  -->
    <m-input
      :label="ts('license_issuer')"
      :value="clinic.licenseIssuer"
      :disabled="disabled"
      @input="clinic.setValue('licenseIssuer', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { ClinicsI18nMixin } from '@/vue_apps/catalogs_root/ClinicsCatalog/mixins/clinicsI18nMixin'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'

export default defineComponent({
  name: 'ClinicLicenseTab',
  components: { MDatePicker, MInput },
  mixins: [ClinicsI18nMixin],
  props: {
    clinic: { type: Clinic, required: true },
    disabled: { type: Boolean, default: false },
  },
})

</script>
