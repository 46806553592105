const getFormattedDate = (date) =>
  moment(Utils.dateRubyToJS(date)).format(gon.localization.date_format.toUpperCase())

export const entryCreateAdapter = {
  toClient (data) {
    data.created_at = getFormattedDate(data.created_at)

    return data
  },
}

export const getEntriesWithoutStepsAdapter = {
  toClient (data) {
    data = data.map((entry) => {
      entry.created_at = entry.formatted_created_at

      return entry
    })

    return data
  },
}
