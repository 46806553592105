import { getAssembledEntryTypes } from '@/vue_present/Reuse/Lists/EntryTypes/api/getAssembledEntryTypes'

export const searchAdapter = {
  /**
   * @param {{ id: number, number: string, title: string, analysisLaboratory?: { title?: string }, price: string }[]} response
   * @return {{ id: number, title: string, info: string }[]}
   */
  toClient (response) {
    return response.map(getAssembledEntryTypes)
  },
}
