import { ENTRY_PACKS_ROUTES } from '@/vue_apps/catalogs_root/EntryPacks/router/routeNames'

const getDefault = (path = '*') => `/entry-packs${path}`

export const entryPacksRoutes = Object.freeze([
  {
    path: getDefault('/:id/edit'),
    name: ENTRY_PACKS_ROUTES.EDIT,
    props: (route) => ({ id: route.params.id }),
  },

  {
    path: getDefault(''),
    name: ENTRY_PACKS_ROUTES.SHOW,
  },
])
