<template>
  <div class="personal-simple-medical-assessment-view">
    <slot name="actuality" />

    <base-header v-bind="document" />

    <b
      class="flex justify-content-center center mb-indent-mid"
      v-html="t$('titleText')"
    />

    <!-- Дата "от" -->
    <div class="flex justify-content-center gap-indent-small mb-indent-mid">
      <span>{{ t('from').toLowerCase() }}</span>

      <document-field-writable-date
        :disabled="isShowMode"
        :date="document.titleDate"
        @input="document.setValue('titleDate', $event)"
      />
    </div>

    <!--  ФИО работника  -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.workerFullNameText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.workerFullName"
        fill-available-width
        @input="document.setValue('workerFullName', $event)"
      />
    </div>

    <!--  Возраст работника  -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.workerAgeText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.workerAge"
        fill-available-width
        @input="document.setValue('workerAge', $event)"
      />
    </div>

    <!--  Пол работника  -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.workerSexText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.workerSex"
        fill-available-width
        @input="document.setValue('workerSex', $event)"
      />
    </div>

    <!--  Наименование работодателя  -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.companyTitleText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        class="mb-indent-mid"
        :value="document.companyTitle"
        fill-available-width
        @input="document.setValue('companyTitle', $event)"
      />
    </div>

    <!-- Структурное подразделение работодателя -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.workerDepartmentText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.workerDepartment"
        fill-available-width
        @input="document.setValue('workerDepartment', $event)"
      />
    </div>

    <!-- Наименование должности -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.workerPositionText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.workerPosition"
        fill-available-width
        @input="document.setValue('workerPosition', $event)"
      />
    </div>

    <!-- Факторы -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.factorsText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.factorsData"
        multiline
        @input="document.setValue('factorsData', $event)"
      />
    </div>

    <!-- Результаты предварительного (периодического) осмотра -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.medicalAssessmentText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.medicalAssessmentData"
        multiline
        @input="document.setValue('medicalAssessmentData', $event)"
      />
    </div>

    <!-- Вредные факторы или виды работ -->
    <div class="checkup-document-field_column mb-0">
      <span class="checkup-document-field__title">
        {{ document.harmfulFactorsText }}
      </span>

      <document-field-writable
        :disabled="isShowMode"
        :value="document.harmfulFactorsData"
        multiline
        @input="document.setValue('harmfulFactorsData', $event)"
      />
    </div>

    <div class="flex flex-column break-inside-avoid mt-indent-mid">
      <span class="mb-indent-validation">
        {{ t$('footerText') }}
      </span>

      <div class="personal-simple-medical-assessment-view__footer">
        <div class="flex flex-column align-items-center">
          <!-- ФИО -->
          <document-field-writable
            :disabled="isShowMode"
            :value="document.footerName"
            @input="document.setValue('footerName', $event)"
          />

          <span>({{ t('full_name') }})</span>
        </div>

        <span class="personal-simple-medical-assessment-view__place-of-printing">
          {{ t('placeOfPrinting') }}
        </span>

        <div class="flex flex-column align-items-center">
          <span>_________________</span>
          <span>({{ t('signature') }})</span>
        </div>
      </div>

      <!-- Дата выдачи -->
      <div class="checkup-document-field">
        <span class="checkup-document-field__title">
          {{ t('issue_date') }}
        </span>

        <document-field-writable
          :disabled="isShowMode"
          :value="document.footerDate"
          width="80px"
          @input="document.setValue('footerDate', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  PersonalSimpleMedicalAssessmentApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalSimpleMedicalAssessment/PersonalSimpleMedicalAssessmentApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import BaseHeader from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/Base/BaseHeader.vue'
import DocumentFieldWritableDate
  from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritableDate/DocumentFieldWritableDate.vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'

export default defineComponent({
  name: 'PersonalSimpleMedicalAssessmentView',

  components: {
    DocumentFieldWritable,
    DocumentFieldWritableDate,
    BaseHeader,
  },

  mixins: [i18nScopeMixin],

  props: {
    document: PersonalSimpleMedicalAssessmentApi,
    isShowMode: Boolean,
  },

  created () {
    this.setI18nScope('checkups.documents.personalSimpleMedicalAssessment')
  },
})
</script>
