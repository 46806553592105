import Company from '@/vue_components/companies/components/company.vue'
import CompanyList from '@/vue_components/companies/components/company_list.vue'
import CompanyNew from '@/vue_components/companies/components/company_new.vue'
import CompanyEdit from '@/vue_components/companies/components/company_edit.vue'
import CompanyShow from '@/vue_components/companies/components/company_show.vue'
import CompanyOrders from '@/vue_components/companies/components/company_orders/company_orders.vue'
import CompanyOrdersRegistry
  from '@/vue_components/companies/components/company_orders_registry/company_orders_registry.vue'
import CompanyOrdersRegistries
  from '@/vue_components/companies/components/company_orders_registries/company_orders_registries.vue'
import CompanyPayments from '@/vue_components/companies/components/company_payments/company_payments.vue'

import {
  COMPANIES,
  COMPANY,
  COMPANY_DOCUMENTS,
  COMPANY_EDIT,
  COMPANY_NEW,
  COMPANY_ORDERS,
  COMPANY_ORDERS_REGISTRIES,
  COMPANY_ORDERS_REGISTRY,
  COMPANY_PAYMENTS,
  COMPANY_SHOW,
} from './route_names.js'
import CompanyDocuments from '@/vue_components/companies/components/company_documents/company_documents'

export default [
  {
    path: '/companies',
    name: COMPANIES,
    component: CompanyList,
  },

  {
    path: '/companies/new',
    name: COMPANY_NEW,
    component: CompanyNew,
  },

  {
    path: '/companies/:companyId/edit',
    name: COMPANY_EDIT,
    component: CompanyEdit,
  },

  {
    path: '/companies/:companyId',
    name: COMPANY,
    component: Company,
    children: [
      {
        path: '',
        name: COMPANY_SHOW,
        component: CompanyShow,
      },

      {
        path: 'orders',
        name: COMPANY_ORDERS,
        component: CompanyOrders,
      },

      {
        path: 'payments',
        name: COMPANY_PAYMENTS,
        component: CompanyPayments,
      },

      {
        path: 'orders_registries',
        name: COMPANY_ORDERS_REGISTRIES,
        component: CompanyOrdersRegistries,
      },

      {
        path: 'orders_registries/:registryId',
        name: COMPANY_ORDERS_REGISTRY,
        component: CompanyOrdersRegistry,
      },

      {
        path: 'documents',
        name: COMPANY_DOCUMENTS,
        component: CompanyDocuments,
      },
    ],
  },
]
