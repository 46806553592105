<template>
  <div
    ref="variablesEditor"
    class="variables-editor-whatsapp"
  >
    <div
      v-if="editorTitle"
      class="variables-editor__title"
    >
      <div class="variables-editor__editorTitle">
        {{ editorTitle }}
      </div>
      <div
        v-if="editorTitleClarification"
        class="variables-editor__titleClarification"
      >
        {{ editorTitleClarification }}
      </div>
    </div>

    <slot name="templateSelector" />

    <div
      class="variables-editor-whatsapp__editor-field"
      :disabled="disableEditorField"
    />

    <whatsapp-variables-modal
      :modal-visibility.sync="variablesModalVisibility"
      :variables-list="variablesList"
      @insertVariable="insertVariable"
    />
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import WhatsappVariablesModal from '@/vue_present/VariablesEditorWhatsapp/WhatsappVariablesModal.vue'
import {
  whatsappVariablesReplacementValue,
  WHATSAPP_VARIABLE_CSS_CLASS as VARIABLE_CSS_CLASS,
} from '@/vue_present/VariablesEditorWhatsapp/consts/consts'
import {
  MESSAGE_VARIABLES_LIST,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'

export default {
  name: 'VariablesEditorWhatsapp',
  components: { WhatsappVariablesModal },

  props: {
    editorTitle: PropsTypes.String(t('whatsapp.notificationWabaTemplate')),
    editorTitleClarification: PropsTypes.String(t('whatsapp.notificationWabaTemplateClarification')),
    editorValue: PropsTypes.String(),
    variablesList: PropsTypes.Array(MESSAGE_VARIABLES_LIST),
    disableEditorField: Boolean,
  },

  data () {
    return {
      variablesModalVisibility: false,
      replaceableSubstring: '',
    }
  },

  watch: {
    editorValue () {
      this.removeVariablesClickListener()
      this.init()
    },
  },

  mounted () {
    this.init()
  },

  beforeDestroy () {
    this.removeVariablesClickListener()
  },

  methods: {
    init () {
      const newEditorValue = this.replaceWazzupVariables(this.editorValue ?? '')
      this.$emit('changeWabaEditorValue', newEditorValue)
      this.getEditorDOMElem().innerHTML = newEditorValue
      this.bindVariablesClickListener()
    },

    getEditorDOMElem () {
      return this.$refs.variablesEditor
        .querySelector('.variables-editor-whatsapp__editor-field')
    },

    getVariablesDOMElems () {
      return this.getEditorDOMElem().querySelectorAll(`.${VARIABLE_CSS_CLASS}`)
    },

    bindVariablesClickListener () {
      if (this.disableEditorField) { return }

      this.getVariablesDOMElems().forEach((variableDOMElem) => variableDOMElem
        .addEventListener('click', this.variableClickHandler))
    },

    removeVariablesClickListener () {
      this.getVariablesDOMElems().forEach((variableDOMElem) => variableDOMElem
        .removeEventListener('click', this.variableClickHandler))
    },

    variableClickHandler (event) {
      this.replaceableSubstring = event.target.innerText

      this.variablesModalVisibility = true
    },

    replaceWazzupVariables (templateText) {
      return templateText
        .replace(/{{(\d+)}}/g, (_, number) => whatsappVariablesReplacementValue(number))
    },

    insertVariable (variable) {
      if (variable.children) { return }

      const newVariable = `{{ ${[variable.parentLabel, variable.label].join('.')} }}`
      const newValue = this.editorValue.replace(this.replaceableSubstring, newVariable)

      this.$emit('changeWabaEditorValue', newValue)
      this.variablesModalVisibility = false
    },
  },
}
</script>
