<template>
  <div class="document-square-field-multiline">
    <document-square-field-line
      v-for="(line, key) in lines"
      :key="`${line}-${key}`"
      :value="line"
      :line-size="lineSize"
      :fill-blanks-at-end="fillBlanksAtEnd"
      :fill-symbol="fillSymbol"
    />
  </div>
</template>

<script>
import { DEFAULT_FILL_SYMBOL, DEFAULT_LINE_SIZE } from '@/vue_present/DocumentBase/DocumentSquareField/const'
import DocumentSquareFieldLine
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLine/DocumentSquareFieldLine.vue'

export default {
  name: 'DocumentSquareFieldMultiline',

  components: { DocumentSquareFieldLine },

  props: {
    value: { type: String, required: true },
    lineSize: { type: Number, default: DEFAULT_LINE_SIZE },

    minLines: { type: Number, default: null },
    maxLines: { type: Number, default: null },

    fillBlanksAtEnd: Boolean,
    fillSymbol: { type: String, default: DEFAULT_FILL_SYMBOL },
  },

  computed: {
    lines () {
      return this.fitLinesToMinMax(this.splitByLine())
    },
  },

  methods: {
    splitByLine () {
      const regex = new RegExp(`.{1,${this.lineSize}}`, 'g')

      return this.value.match(regex) || []
    },

    fitLinesToMinMax (separatedByLines) {
      return this.removeLinesToMax(this.addLinesToMin(separatedByLines))
    },

    addLinesToMin (lines) {
      if (this.minLines && this.minLines > lines.length) {
        return lines.concat(new Array(this.minLines - lines.length).fill(''))
      }

      return lines
    },

    removeLinesToMax (lines) {
      if (this.maxLines && this.maxLines < lines.length) {
        return lines.slice(0, this.maxLines)
      }

      return lines
    },
  },
}
</script>
