<template>
  <div class="image-cropper-container">
    <modal
      v-if="visible"
      :modal-visibility="visible"
      :modal-class="injectClass"
      @close="$emit('close')"
    >
      <template #header>
        <slot name="header">
          {{ t('imageCropper.header') }}
        </slot>
      </template>
      <template #body>
        <image-cropper
          :preview="preview"
          :targets="targets"
        />
        <slot name="body" />
      </template>
      <template #footer-left>
        <slot name="footer-left" />
      </template>
      <template #footer-right>
        <slot name="footer-right">
          <button
            class="btn btn-success btn-with-icon moda"
            type="button"
            @click="$emit('apply')"
          >
            <div class="btn-with-icon_icon fad fa-cut" />
            <div class="btn-with-icon_text">
              {{ T.apply }}
            </div>
          </button>
        </slot>
        <button
          class="btn btn-primary btn-with-icon modal-close"
          data-dismiss="modal"
          type="button"
          @click="$emit('close')"
        >
          <div class="btn-with-icon_icon fad fa-times" />
          <div class="btn-with-icon_text">
            {{ T.close }}
          </div>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import ImageCropper from './image_cropper.vue'
import Modal from './modal.vue'

const STANDARD_CLASS = 'modal-image-cropper'

export default {
  name: 'ModalImageCropper',
  components: { Modal, ImageCropper },
  props: {
    preview: Boolean,
    visible: Boolean,
    targets: {
      type: Object,
      required: true,
    },
    modalClass: {
      type: Object,
      default () {
        return { override: true, class: STANDARD_CLASS }
      },
    },
  },
  computed: {
    injectClass () {
      if (this.modalClass.override) return this.modalClass.class

      return `${STANDARD_CLASS} ${this.modalClass.class}`
    },
  },
}
</script>
