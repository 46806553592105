import Notificator from '../vue_components/notificator/notificator.js'
import EpicSpinner from '../vue_components/epic_spinner/epic_spinner.js'
import SoftphoneHandler from '../vue_components/communications/telephony/softphone/softphone_handler.js'
import { ContactsMessagesRoot as CustomMessageEditor } from '../vue_apps/Contacts/ContactsMessages/ContactsMessages.js'
import { phonePopover as PhonePopover } from '@/vue_apps/Contacts/ContactsPopover/phonePopover'

const UI_STORAGE = 'global_ui_service_storage'

class UI {
  load () {
    this.setDefaultStoreState()
    this.setElements()
    this.setEvents()
    this.init()
    this.bindVueComponents()
  }

  setStore (stateObject) {
    store.set(UI_STORAGE, stateObject)
  }

  getStore () {
    return store.get(UI_STORAGE)
  }

  updateStore (state) {
    const oldState = this.getStore()
    this.setStore({ ...oldState, ...state })
  }

  setDefaultStoreState () {
    const currentStorage = this.getStore() || Object.create(null)
    currentStorage.catalogVisibility =
      currentStorage.catalogVisibility === undefined
        ? true
        : currentStorage.catalogVisibility
    currentStorage.scrollPosition =
      currentStorage.scrollPosition === undefined
        ? 0
        : currentStorage.scrollPosition
    this.setStore(currentStorage)
  }

  setElements () {
    this.setCatalogsListElements()
  }

  setEvents () {
    this.setCatalogsListEvents()
  }

  init () {
    const storage = this.getStore()

    this.changeCatalogsListVisibility(storage.catalogVisibility)
    $(this.$scrollContainer).scrollTop(storage.scrollPosition)
  }

  bindVueComponents () {
    this.notificator = Notificator.init()
    this.epicSpinner = EpicSpinner.init()
    this.softphoneHandler = SoftphoneHandler.init()
    this.phonePopover = PhonePopover.init()
    this.customMessageEditor = CustomMessageEditor.init()
    window.Notificator = this.notificator
  }

  setCatalogsListElements () {
    this.$catalogsList = $('#catalogs-list')
    this.$hideCatalogsList = this.$catalogsList.find('#hide-catalogs')
    this.$showCatalogsList = this.$catalogsList.find('#show-catalogs')
    this.$scrollContainer = this.$catalogsList.find('.list-index-body')
  }

  setCatalogsListEvents () {
    const god = this

    this.$hideCatalogsList.on('click', function () {
      god.changeCatalogsListVisibility(false)
    })

    this.$showCatalogsList.on('click', function () {
      god.changeCatalogsListVisibility(true)
    })

    this.$scrollContainer.on('scroll', function () {
      god.saveScrollPosition(this.scrollTop)
    })
  }

  changeCatalogsListVisibility (show) {
    this.updateStore({ catalogVisibility: show })
    if (show) this.$catalogsList.removeClass('hide-catalogs-list')
    else this.$catalogsList.addClass('hide-catalogs-list')
  }

  saveScrollPosition (scrollPosition) {
    this.updateStore({ scrollPosition })
  }
}

const instance = new UI()

export default {
  load: () => instance.load(),
  spinner: () => instance.epicSpinner,
  notificator: () => instance.notificator,
  setInputDatepicker ($input) {
    const orientation = $input.data('orientation') || 'bottom'
    $input.datepicker({
      language: gon.localization.locale,
      weekStart: gon.localization.day_week_start,
      format: gon.localization.date_format,
      orientation,
      startDate: '01.01.1900',
      todayBtn: 'linked',
      todayHighlight: true,
      autoclose: true,
    })

    $input.inputmask('date', { placeholder: gon.localization.date_format })

    return $input
  },
}
