<template>
  <m-panel
    :title="t('semds.semd')"
    class="semd-modal-wrapper"
  >
    <m-si-generator
      class="mb-indent-validation"
      :items="semdList.data"
      :si-generator-schema="semdSchema"
      :page-count="semdList.totalPages"
      :current-page="semdList.currentPage"
      @update:currentPage="semdList.fetchPage($event)"
      @onItemClick="onOpenSemd({ id: $event.id })"
    >
      <template #add>
        <semd-modal-app
          class="inline-block"
          :is-semds-empty="isSemdsEmpty"
          :is-last-semd-outdated="isLastSemdOutdated"
          :is-new-source="!source.id"
          :is-source-changed="isSourceChanged"
          :has-semd-type="hasSemdType"
          :append-to-body="appendToBody"
          @registerOpenSemd="openSemd = $event"
          @updateList="onUpdateList"
          @submitClick="onSemdSubmitClick()"
        />
      </template>
    </m-si-generator>

    <slot
      name="source-connector"
      :semds="semds"
    />
  </m-panel>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import SemdModalApp from '@/vue_apps/Semds/SemdModal/SemdModalApp.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { MListService } from '@/_api/_requests/MListService'
import { SEMDS_OWNER_TYPES } from '@/vue_apps/Semds/SemdModal/const/const'
import { MSemdPresenter } from '@/_api/MSemdApi/MSemdPresenter'
import { SemdListItem } from '@/vue_apps/Semds/entities/list/SemdListItem'
import { getSemdListSchema } from '@/vue_apps/Semds/entities/list/getSemdListSchema'
import { callAfterTimeout } from '@/helpers/ElementUI/callAfterTimeout'
import { ISemdSource } from '@/vue_apps/Semds/interfaces/ISemdSource'
import { TMSiGeneratorSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiGeneratorTypes'

export declare interface ISemdModalWrapperApi {
  updateList: () => Promise<void>
}

export default defineComponent({
  name: 'SemdModalWrapper',
  components: { MPanel, MSiGenerator, SemdModalApp },

  props: {
    source: { type: Object as PropType<ISemdSource>, required: true },
    beforeSemdSubmit: {
      type: Function as PropType< () => Promise<{ cancel: boolean }> >,
      async default () {},
    },
    isSourceChanged: { type: Boolean, required: true },
    appendToBody: Boolean,
  },

  emits: [
    'registerApi',
  ],

  data () {
    return {
      componentId: `SemdModalWrapper:${window.Utils.newGUID()}`,

      /* eslint-disable */
      /**
       * @param {number} [id]
       * @param {PersonalElmkMedicalAssessmentApi} [source]
       * @param {number} [lastSemdId]
       */
      async openSemd ({ id, source, lastSemdId }) {},
      /* eslint-enable */

      semdList: new MListService(
        { ownerType: SEMDS_OWNER_TYPES.CHECKUP_ELMK, ownerId: this.source.id },
        new MSemdPresenter(),
        { listItemEntity: SemdListItem }
      ),

      semdSchema: getSemdListSchema() as TMSiGeneratorSchema,
    }
  },

  computed: {
    hasSemdType () {
      return Boolean(this.source?.semdType)
    },

    isSemdsEmpty () {
      return !this.semdList.data.length
    },

    lastSemd (): SemdListItem {
      return this.semdList.data[0] || null
    },

    isLastSemdOutdated () {
      if (!this.lastSemd) { return false }

      return this.lastSemd.isOutdatedVersion()
    },

    semds (): SemdListItem[] {
      return this.semdList.data
    },
  },

  created () {
    const vm = this

    const api: ISemdModalWrapperApi = {
      updateList: () => vm.semdList.fetchPage(1),
    }

    this.$emit('registerApi', api)

    callAfterTimeout(() => {
      this.semdList.fetchPage(1)
    })

    this.$pubSub.subscribe(`broadcast:${location.pathname}:updateSemdsList`, (componentId) => {
      if (componentId === this.componentId) { return }
      this.semdList.fetchPage(1)
    })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll(`broadcast:${location.pathname}:updateSemdsList`)
  },

  methods: {
    onUpdateList () {
      this.semdList.fetchPage(1)
      this.$pubSub.emit(`broadcast:${location.pathname}:updateSemdsList`, this.componentId, true)
    },

    async onOpenSemd (params) {
      if (!this.$security.canViewEgisz) { return }

      await this.openSemd({
        ...params,
        source: this.source,
      })
    },

    async onSemdSubmitClick (id = this.lastSemd?.id || null) {
      if (!id) {
        const { cancel } = await this.beforeSemdSubmit()
        if (cancel) { return }
      }

      const lastSemdId = (this.lastSemd?.isOutdatedVersion() && this.lastSemd.id) || undefined

      await this.onOpenSemd({ id, lastSemdId })
    },
  },
})
</script>
