
export const SEMD224_ENTITY_NAMES = Object.freeze({
  DATE_START: 'dateStart',
  DATE_END: 'dateEnd',
  TIME_START: 'timeStart',
  TIME_END: 'timeEnd',
  RADIATION_EXPOSURE: 'radiationExposure',

  DIRECTIONAL_MKB_DIAGNOSIS_GROUP: 'nsiDirectionalMkbDiagnosisGroup',
  CASE_VISIT_TYPE: 'nsiCaseVisitType',
  MEDICAL_CARE_PLACE: 'nsiMedicalCarePlace',
  INSTRUMENTAL_DIAGNOSTIC_RESEARCH: 'nsiInstrumentalDiagnosticResearch',
  ULTRASOUND_MODE: 'nsiUltrasoundMode',
  ECHO_IMAGE_QUALITY: 'nsiEchoImageQuality',
  DETECTED_PATHOLOGY: 'nsiDetectedPathologies',

  INSTRUMENTAL_DIAGNOSTIC_RESEARCH_PROTOCOL_TEXT: 'instrumentalDiagnosticResearchProtocolText',
  CONCLUSION: 'conclusion',
  RECOMMENDATIONS: 'recommendations',
})
