import Vue from 'vue/dist/vue.esm'
import ApplicationNotificator from './notificator.vue'

export default {
  init () {
    const container = document.createElement('div')
    container.id = 'notificator_area_block'
    window.document.body.appendChild(container)

    return new Vue({
      el: '#notificator_area_block',
      components: { ApplicationNotificator },
      data: { position: 'bottom-right' },
      methods: {
        error (message, title, duration) {
          setTimeout(() => {
            this.$refs.notificator.error(message, title || t('Notificator.titles.error'), duration)
          })
        },

        info (message, title, duration) {
          setTimeout(() => {
            this.$refs.notificator.info(message, title || t('Notificator.titles.info'), duration)
          })
        },

        warning (message, title, duration) {
          setTimeout(() => {
            this.$refs.notificator.warning(message, title || t('Notificator.titles.warning'), duration)
          })
        },

        success (message, title, duration) {
          setTimeout(() => {
            this.$refs.notificator.success(message, title || t('Notificator.titles.success'), duration)
          })
        },
      },
      template: '<application-notificator ref="notificator" :position="position" custom-class="hidden-print"/>',
    })
  },
}
