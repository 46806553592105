import { ICRUDConfig } from '@/_api/decorators/api/interfaces/ICRUDConfig'
import { omit } from 'lodash'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { request } from '@/lib/transport/request'
import { objectToFormData } from '@/helpers/objectToFormData'
import { ICRUDBaseConfig } from '@/_api/decorators/api/interfaces/ICRUDBaseConfig'

export const crudBase = <Return>({
  httpMethod,
  route,
  routeArgs,
  payload = {},
  config,
}: ICRUDBaseConfig): Return => {
  const options = omit(config, [
    'toClientAdapter',
    'toJson',
    'toFormData',
  ])

  const toClientAdapter = config?.toClientAdapter ?? snakeToCamel

  return request({
    type: httpMethod,

    url: routeArgs
      ? route(...routeArgs)
      : route(),

    data: getFormattedPayload(payload, config),
    ...getExtraOptions(config),
    ...options,
  }, toClientAdapter).promise as Return
}

const getFormattedPayload = (payload: object, config: ICRUDConfig) => {
  if (config.toFormData) {
    return objectToFormData(payload)
  }

  if (config.toJson) {
    return JSON.stringify(camelToSnake(payload))
  }

  return camelToSnake(payload)
}

const getExtraOptions = (config: ICRUDConfig) => {
  if (config.toFormData) {
    return {
      contentType: false,
      processData: false,
    }
  }

  if (config.toJson) {
    return {
      contentType: 'application/json',
      dataType: 'json',
    }
  }

  return {}
}
