<template>
  <m-modal
    width="450px"
    modal-title-icon="calc"
    :dialog-title="title"
    :visible="visible"
    @close="onClose"
  >
    <template #body>
      <div class="flex flex-direction-column flex-grow-1">
        <div class="flex justify-content-between gap-indent">
          <!-- Возраст -->
          <m-select
            v-model="formData.age"
            :items="ageItems"
            :label="t('checkups.company.personalCheckups.table.age')"
            required
            validator-name="age"
            @registerValidator="onRegisterValidator"
          />

          <!-- Пол -->
          <reusable-sex
            v-model="formData.sex"
            required
            validator-name="sex"
            @registerValidator="onRegisterValidator"
          />
        </div>

        <!-- Факторы -->
        <m-select-lazy
          :value="formData.factors"
          :label="t('checkups.personal.factors29H.factors')"
          :fetch-method="(params) => factorsList.list(params)"
          :additional-displayed-filter="factorsCodeFilter"
          use-first-time-fetch
          option-notice="codeNotice"
          multiple
          filterable
          :min-filter-value-length="2"
          full-width
          required
          validator-name="factors"
          @registerValidator="onRegisterValidator"
          @changeByLazy="formData.factors = $event"
        />

        <!-- Стоимость -->
        <p :class="{ 'invisible': formData.price === null }">
          {{ checkupPrice }}
        </p>
      </div>
    </template>

    <template #footer>
      <m-button
        v-loading="loading"
        icon="calc"
        type="success"
        :text="t('checkups.doctorsAndTests.calculate')"
        @click="onCalculateClick"
      />

      <m-button
        template="close"
        @click="onClose"
      />
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { CHECKUP_AGE_ITEMS } from '@/vue_apps/CheckupsModule/store/checkupAgeItems'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import ReusableSex from '@/vue_present/Reuse/Lists/Sex/ReusableSex.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import {
  CheckupCalculatePricePresenter,
} from '@/vue_apps/CheckupsModule/api/CheckupCalculatePricePresenter/CheckupCalculatePricePresenter'
import {
  MCheckupFactorsPresenter,
} from '@/vue_apps/CheckupsModule/api/MCheckupFactorsPresenter/MCheckupFactorsPresenter'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { factorsCodeFilter } from '@/vue_apps/CheckupsModule/store/factorsCodeFilter'
import { extractId } from '@/_medods_standart_library/msl'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

const getDefaultFormData = () => ({
  sex: null,
  age: null,
  factors: null,
  price: null,
})

export default {
  name: 'CheckupCalculatePriceModal',

  components: { MSelectLazy, ReusableSex, MButton, MSelect, MModal },

  mixins: [ValidationParentMixin, SpinnerHolder],

  props: {
    visible: Boolean,
  },

  emits: ['close'],

  data () {
    return {
      presenter: new CheckupCalculatePricePresenter(),
      factorsList: new MCheckupFactorsPresenter(),
      ageItems: CHECKUP_AGE_ITEMS,
      formData: getDefaultFormData(),
    }
  },

  computed: {
    title () {
      return t('checkups.company.calculatePriceModalTitle')
    },

    checkupPrice () {
      return [
        t('checkups.company.personalCheckups.checkupPrice'),
        this.formData.price,
        gon.localization.currency.symbol_character,
      ].join(' ')
    },
  },

  methods: {
    factorsCodeFilter,

    async onCalculateClick () {
      if (this.hasErrors()) {
        this.formData.price = null

        return
      }

      const { totalPrice, errors } = await this.withSpinner(this.presenter.fetchCheckupPrice({
        sex: this.formData.sex.systemName,
        age: this.formData.age.id,
        clinicId: gon.application.current_clinic.id,
        checkupFactorIds: this.formData.factors.map(extractId),
      }))

      if (errors) { return }

      this.formData.price = totalPrice
    },

    onClose () {
      this.$emit('close')

      this.formData = getDefaultFormData()

      this.resetValidations(null)
    },
  },
}
</script>
