<template>
  <div class="logotype">
    <upload-control>
      <epic-spinner
        :visibility="epicSpinner"
      />

      <img
        :src="logoSource"
        class="img-logo"
      >

      <template
        v-if="$security.canManageClinic"
        v-slot:control
      >
        <upload-button
          :title="t('upload')"
        >
          <slot name="input">
            <input
              ref="input"
              accept="image/jpeg, image/png, image/gif"
              type="file"
              @input="handleInput"
            >
          </slot>
        </upload-button>

        <delete-button
          :title="t('delete')"
          @click="showConfirmingModal = true"
        />
      </template>
    </upload-control>

    <confirmation-modal
      :header-message="t('warning')"
      :message="t('clinic_logo_removal_warning')"
      :modal-visibility="showConfirmingModal"
      modal-size="md"
      @yes="showConfirmingModal = false; $emit('delete')"
      @no="showConfirmingModal = false"
    />
  </div>
</template>

<script>
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import UploadButton from '@/vue_components/common/upload_button.vue'
import DeleteButton from '@/vue_components/common/delete_button.vue'
import UploadControl from './upload_control.vue'
import ConfirmationModal from '@/vue_components/confirmation_modal.vue'

export default {
  name: 'Logotype',
  components: {
    UploadButton,
    UploadControl,
    DeleteButton,
    EpicSpinner,
    ConfirmationModal,
  },
  props: {
    logoSource: {
      type: String,
      required: true,
    },
    epicSpinner: Boolean,
  },
  data: () => ({
    showConfirmingModal: false,
  }),
  methods: {
    handleInput (event) {
      this.$emit('input', event)
      this.$refs.input.value = ''
    },
  },
}
</script>
