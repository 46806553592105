export const egiszMedCaseValidations = () => ({
  doctor: [],
  opened_at: [],
  closed_at: [],
  netrika_case_type_id: [],
  netrika_case_visit_type: [],
  netrika_payment_type_id: [],
  legal_representative: [],
  diagnosis: {
    comment: [],
    date: [],
    disease: [],
  },
  steps: [],
  comment: [],
})

export const egiszMedCaseStepValidations = () => ({
  title: [],
  opened_at: [],
  netrika_visit_place_id: [],
  netrika_visit_purpose_id: [],
  doctor_id: [],
})
