<template>
  <checkup-doctors-and-tests
    :checkup="personalCheckup"
    :readonly="isShowMode"
    :table-schema="tableSchema"
  >
    <template #before>
      <!--      председатель врачебной комиссии     -->
      <reusable-doctors-list
        :value="personalCheckup.responsible"
        :label="t('checkups.personal.info.responsible')"
        :disabled="isShowMode || personalCheckup.isPeriodic"
        :current-clinic="vxClinicsIds"
        m-fixed-height
        required
        validator-name="responsible"
        option-label="fullName"
        @onDoctorChange="personalCheckup.setValue('responsible', $event)"
        @registerValidator="$registerValidator($event)"
      />
    </template>

    <template #factors>
      <div class="checkup-doctors-and-tests__factors">
        <!-- Факторы -->
        <m-select-lazy
          :value="personalCheckup.factors"
          class="factors-select"
          :label="t('checkups.personal.factors29H.title')"
          :disabled="isShowMode || hasEntries"
          :fetch-method="(params) => factorsList.list(params)"
          :additional-displayed-filter="factorsCodeFilter"
          option-notice="codeNotice"
          multiple
          filterable
          :min-filter-value-length="2"
          use-first-time-fetch
          @changeByLazy="personalCheckup.setValue('factors', $event)"
        />

        <!-- Рассчитать -->
        <m-button
          v-if="isEditMode"
          class="mb-indent-validation"
          icon="calc"
          :text="t('checkups.doctorsAndTests.calculate')"
          :disabled="calculateButtonDisabled"
          @click="onCalculateClick"
        />
      </div>
    </template>

    <template #specialistsAndTests>
      <checkup-available-after-saving v-if="isNewMode" />
    </template>

    <template #addSpecialists>
      <checkup-doctors-and-tests-modal
        v-if="isEditMode"
        :personal-checkup="personalCheckup"
        use-specialist
      />
    </template>

    <template #addTests>
      <checkup-doctors-and-tests-modal
        v-if="isEditMode"
        :personal-checkup="personalCheckup"
      />
    </template>
  </checkup-doctors-and-tests>
</template>

<script>
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import CheckupDoctorsAndTests from '@/vue_apps/CheckupsModule/components/CheckupDoctorsAndTests.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import {
  MCheckupFactorsPresenter,
} from '@/vue_apps/CheckupsModule/api/MCheckupFactorsPresenter/MCheckupFactorsPresenter'
import CheckupAvailableAfterSaving from '@/vue_apps/CheckupsModule/components/CheckupAvailableAfterSaving.vue'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { mapGetters } from 'vuex'
import { factorsCodeFilter } from '@/vue_apps/CheckupsModule/store/factorsCodeFilter'
import CheckupDoctorsAndTestsModal
  from '@/vue_apps/CheckupsModule/components/CheckupDoctorsAndTests/CheckupDoctorsAndTestsModal.vue'

const siSchema = new MTableSchema({
  headers: {
    title: new MTableHeader(),
    additionalInfo: new MTableHeader().addFixedWidth('30px'),
    action: new MTableHeader().addFixedWidth('30px'),
  },
})

const siSchemaShow = new MTableSchema({
  headers: {
    title: new MTableHeader(),
    additionalInfo: new MTableHeader().addFixedWidth('30px'),
  },
})

export default {
  name: 'PersonalCheckupDoctorsAndTests',
  components: {
    CheckupDoctorsAndTestsModal,
    CheckupAvailableAfterSaving,
    ReusableDoctorsList,
    CheckupDoctorsAndTests,
    MSelectLazy,
    MButton,
  },

  mixins: [
    CheckupsPropsMixin,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
  },

  data () {
    return {
      factorsList: new MCheckupFactorsPresenter(),
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxClinicsIds: 'vxGetDoctorClinicsIds',
    }),

    tableSchema () {
      return (this.isShowMode && siSchemaShow) || siSchema
    },

    hasEntries () {
      return !!this.personalCheckup
        .checkupEntries
        .checkupEntries
        .map(({ entry }) => entry)
        .filter(Boolean)
        .length
    },

    calculateButtonDisabled () {
      if (!this.personalCheckup.canCalculateEntries) {
        return {
          disabled: true,
          tooltip: t('checkups.doctorsAndTests.calculateTooltip'),
        }
      }

      if (this.hasEntries) {
        return {
          disabled: true,
          tooltip: t('checkups.doctorsAndTests.calculateTooltipEntries'),
        }
      }

      return false
    },
  },

  methods: {
    factorsCodeFilter,
    onCalculateClick () {
      this.personalCheckup.calculateFactorsWithForceUpdate()
    },
  },
}
</script>
