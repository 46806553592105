<template>
  <div class="fixed-document-editor-header">
    <m-back-button :on-click-handler="onBackClick" />

    <m-prompt-notice
      class="fixed-document-editor-header__prompt-notice"
      type="alert"
      :text="t('fixedDocument.base.fillFieldsAndSaveDocumentThenYouCanPrintIt')"
    />
  </div>
</template>

<script>
import MBackButton from '@/vue_present/_base/buttons/MButtonBack/MButtonBack.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import { onBackClick } from '@/vue_apps/Documents/FixedDocuments/helpers/onBackClick'

export default {
  name: 'FixedDocumentEditorHeader',

  components: { MPromptNotice, MBackButton },

  methods: { onBackClick },
}
</script>
