import Vue from 'vue/dist/vue.esm'
import EpicSpinner from './epic_spinner.vue'

export default {
  init () {
    const container = document.createElement('div')
    container.id = 'epic-spinner-container'
    window.document.body.appendChild(container)
    $(container).loadSpin('start').loadSpin('stop')

    return new Vue({
      el: '#epic-spinner-container',
      components: { EpicSpinner },
      data: () => ({
        visibility: false,
        consumerStateMap: new Map(),
        consumerIdsGenerator: (function * () {
          let counter = 0
          while (true) yield ++counter
        })(),
      }),
      methods: {
        getConsumerId () {
          const id = this.consumerIdsGenerator.next().value
          this.consumerStateMap.set(id, false)

          return id
        },

        show (state, consumerId) {
          if (!consumerId) this.visibility = state
          else {
            this.consumerStateMap.set(consumerId, state)
            this.visibility = Array.from(this.consumerStateMap.values()).some((state) => state)
          }
        },
      },
      template: '<epic-spinner :visibility="visibility"/>',
    })
  },
}
