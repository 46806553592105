import { MAX_SMS_SYMBOLS_LENGTH } from './constants.js'

const shortInfoText = (sms) => {
  let text = '-'
  if (sms) {
    const time = moment(sms.updated_at).format(Utils.momentDateTimeFormat)
    text = `${time}, ${t('status')}: ${sms.status_i18n}`
  }

  return text
}

const smsCount = (text) => Math.ceil(text.length / MAX_SMS_SYMBOLS_LENGTH)

export {
  shortInfoText,
  smsCount,
}
