import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  personalCheckupLaboratoryResearchResultsAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupLaboratoryResearchResultsPresenter/personalCheckupLaboratoryResearchResultsAdapter'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'

export class PersonalCheckupLaboratoryResearchResultsPresenter extends NestedItemsPresenter {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
      },

      adapter: personalCheckupLaboratoryResearchResultsAdapter,

      singularEntity: 'personalCheckupLaboratoryResearchResult',
      entity: 'personalCheckupLaboratoryResearchResults',
      location: 'PersonalCheckupLaboratoryResearchResultsPresenter',
    })
  }

  fetchResearches (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'researches'
    )

    return super.post({
      url: Routes.api_internal_selectors_checkups_laboratory_researches_list_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }

  fetchMedicalAssessments (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'conclusions'
    )

    return super.get({
      url: Routes.api_internal_selectors_checkups_laboratory_researches_medical_assessments_list_path(),
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
