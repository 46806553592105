<template>
  <div class="checkup-document__blade-runner">
    <h1 class="blade-runner__title center">
      Маршрутный лист
    </h1>
    <span class="center mb-indent-validation">для прохождения {{ bladeRunner.checkupType }} медосмотра № {{ bladeRunner.headerNumber }}</span>

    <div class="blade-runner__date flex gap-indent-small">
      <span class="bold">Дата проведения:</span>
      <span>{{ bladeRunner.dateValue }}</span>
    </div>

    <div class="blade-runner__clinicAddressValue flex gap-indent-small">
      <span class="bold">Место проведения:</span>
      <span>{{ bladeRunner.clinicAddressValue }}</span>
    </div>

    <div class="blade-runner__worker">
      <span class="bold">Работник:</span>
      <span>{{ bladeRunner.workerName }}</span>
    </div>

    <div class="blade-runner__company mb-indent">
      <span class="bold">Работодатель:</span>
      <span>{{ bladeRunner.companyValue }}</span>
    </div>

    <table class="blade-runner__entry-types">
      <tr>
        <td colspan="2">
          Врачи и исследования
        </td>
      </tr>

      <tr
        v-for="item in bladeRunner.tableData"
        :key="item.id"
      >
        <td>{{ item.cabinet }}</td>
        <td>{{ item.title }}</td>
      </tr>
    </table>

    <div class="blade-runner__footer">
      <div>Примечание:</div>
      <document-field-writable
        :value="bladeRunner.footerData"
        :multiline="{ minRows: 2}"
        @input="bladeRunner.setFieldValue('footerData', $event)"
      />

      <table class="w-100">
        <tr>
          <td style="width: 200px">
            - завершающий кабинет
          </td>
          <td>
            <document-field-writable
              :value="bladeRunner.finalCabinet"
              class="w-100"
              @input="bladeRunner.setFieldValue('finalCabinet', $event)"
            />
          </td>
        </tr>

        <tr>
          <td class="min-content pe-2">
            - завершающий специалист
          </td>
          <td>
            <document-field-writable
              :value="bladeRunner.finalSpecialist"
              class="w-100"
              @input="bladeRunner.setFieldValue('finalSpecialist', $event)"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import { BladeRunner } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunner.js'
import { DisablePrintClinicHeaderMixin } from '@/vue_present/mixins/DisablePrintClinicHeaderMixin'

export default {
  name: 'BladeRunnerView',
  components: { DocumentFieldWritable },

  mixins: [DisablePrintClinicHeaderMixin],

  props: {
    bladeRunner: BladeRunner,
  },
}
</script>
