import { transformStepsValidations } from '@/vue_components/egisz/egisz_module/helpers/steps_validation_helpers'
import { egiszMedCaseValidations } from '@/vue_components/egisz/egisz_module/const/egisz_validations'

export default {
  data () {
    return {
      tempMedCaseValidations: egiszMedCaseValidations(),
      tempMedCaseStepsValidations: {},
    }
  },

  watch: {
    // case
    'tempMedCase.doctor_id' (to) {
      this.tempMedCaseValidations.doctor = []
    },

    'tempMedCase.opened_at' (to) {
      this.tempMedCaseValidations.opened_at = []
    },

    'tempMedCase.closed_at' (to) {
      this.tempMedCaseValidations.closed_at = []
    },

    'tempMedCase.netrika_case_type_id' (to) {
      this.tempMedCaseValidations.netrika_case_type_id = []
    },

    'tempMedCase.netrika_case_visit_type' (to) {
      this.tempMedCaseValidations.netrika_case_visit_type = []
    },

    'tempMedCase.netrika_payment_type_id' (to) {
      this.tempMedCaseValidations.netrika_payment_type_id = []
    },

    'tempMedCase.steps' (to) {
      this.tempMedCaseValidations.steps = []
    },

    'tempMedCase.comment' (to) {
      this.tempMedCaseValidations.comment = []
    },

    'tempMedCase.legal_representative_id' (to) {
      this.tempMedCaseValidations.legal_representative = []
    },

    // diagnosis
    'tempMedCase.diagnosis.comment' (to) {
      this.tempMedCaseValidations.diagnosis.comment = []
    },

    'tempMedCase.diagnosis.date' (to) {
      this.tempMedCaseValidations.diagnosis.date = []
    },

    'tempMedCase.diagnosis.disease_id' (to) {
      this.tempMedCaseValidations.diagnosis.disease = []
    },
  },

  methods: {
    prepareCaseValidations (err) {
      this.resetMedCaseValidations()

      const { responseJSON } = err
      const hasErrors = responseJSON && responseJSON.length
      if (!hasErrors) { return }

      const steps = []
      responseJSON.forEach((item) => {
        if (item.medical_case) {
          this.validateCaseFields(item.medical_case)

          return
        }

        if (item.diagnosis) {
          this.validateCaseDiagnosisFields(item.diagnosis)

          return
        }

        steps.push(item)
      })

      this.validateCaseSteps(steps)

      Utils.reportError(
        'EgiszCase:onSubmit()',
        t('egisz.messages.errors.validation.case_submit.e422')
      )(err)
    },

    /**
     * @param {Object} validationErrors
     */
    validateCaseFields (validationErrors) {
      this.tempMedCaseValidations = { ...this.tempMedCaseValidations, ...validationErrors }
    },

    /**
     * @param {Object} validationErrors
     */
    validateCaseDiagnosisFields (validationErrors) {
      this.tempMedCaseValidations = { ...this.tempMedCaseValidations, diagnosis: { ...validationErrors } }
    },

    /**
     * @param {Object[]} stepValidations
     */
    validateCaseSteps (stepValidations) {
      this.resetMedCaseStepsValidations()

      if (!stepValidations.length) { return }

      this.tempMedCaseStepsValidations = transformStepsValidations(stepValidations)
    },

    resetMedCaseValidations () {
      this.tempMedCaseValidations = egiszMedCaseValidations()
    },

    resetMedCaseStepsValidations () {
      this.tempMedCaseStepsValidations = {}
    },
  },
}
