import { camelToSnake } from '@/_api/_requests/helpers'
import { DateHelpers } from '@/helpers/DateHelpers'

export const calculatedSumAdapter = {

  /**
   * @param {ITaxCertificate2024Base} payload
   */
  toServer (payload) {
    return camelToSnake({
      date: DateHelpers.toBasePeriod(payload.period, Utils.longDateFormat).join(' - '),
      clinicId: payload.clinic.id,
      clientId: payload.taxpayer.id,
      destinationClientId: payload.taxpayerIsClient
        ? payload.taxpayer.id
        : payload.client.id,
      printedReceiptsOnly: payload.ignorePaymentsWithoutPrintedReceipts,
    })
  },

  toClient (payload) {
    return {
      paidSum: Utils.toMoney(payload.paidSum),
    }
  },
}
