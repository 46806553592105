import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getPersonalCheckupEntriesMsiSchemaShow = () => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(),
        amount: new MTableHeader().addFixedWidth('50px'),
        date: new MTableHeader().addFixedWidth('100px'),
        userShortName: new MTableHeader().addFixedWidth('200px'),
        stateIcon: new MTableHeader().addFixedWidth('40px'),
        edit: new MTableHeader().addFixedWidth('40px'),
        changeUser: new MTableHeader().addFixedWidth('40px'),
      })
      .addCheckboxes(Services.security.canManageCheckupsCompany)
      .addDeleteIcon(Services.security.canManageCheckupsCompany)
  )

export const getPersonalCheckupEntriesMsiSchemaEdit = () => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(),
        amount: new MTableHeader().addFixedWidth('50px'),
        date: new MTableHeader().addFixedWidth('100px'),
        userShortName: new MTableHeader().addFixedWidth('200px'),
        stateIcon: new MTableHeader().addFixedWidth('40px'),
        edit: new MTableHeader().addFixedWidth('40px'),
      })
  )

export const getPersonalCheckupEntriesModalMsiSchema = () => new MSiSchema()
  .addPagination()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        title: new MTableHeader(),
      })
      .addCheckboxes()
  )
