<template>
  <m-icon
    v-tooltip="tooltip"
    :icon="value"
    :color="css"
    :use-brand="useBrand"
  />
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'TypeIcon',
  components: { MIcon },
  props: [
    'tooltip',
    'value',
    'css',
    'useBrand',
  ],
}
</script>
