import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MCheckupEntryPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () {},
        all () {},
        list: Routes.sort_api_internal_checkups_entries_path,
      },
      entity: 'checkupEntry',
      location: 'MCheckupEntryPresenter',
    })
  }
}
