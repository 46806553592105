export const getDefaultFeedbackFilters = (clientId = null) => {
  const period = [Utils.getBaseFormattedDate(), Utils.getBaseFormattedDate()]

  return {
    period,
    clinic: null,
    doctor: null,
    specialty: null,
    doctorRate: [0, 10],
    clinicRate: [0, 10],
    clientId,
    statuses: null,
    withClicks: null,
  }
}
