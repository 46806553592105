<template>
  <div class="company-final-act-worker-list">
    <p class="company-final-act-worker-list__title">
      <span>{{ title }}</span>

      <document-field-writable
        :value="document[titleValueProp]"
        width="50px"
        text-center
        @input="document.setValue(titleValueProp, $event)"
      />

      <span>{{ t$('ofThem') }}</span>
    </p>

    <ul class="company-final-act-worker-list__list">
      <template v-for="(fieldTitle, idx) in fieldsTitles">
        <li
          v-if="fieldsValuesProps[idx]"
          :key="idx"
          class="company-final-act-worker-list__item"
        >
          <span>{{ fieldTitle }}</span>

          <document-field-writable
            class="min-content"
            :value="document[fieldsValuesProps[idx]]"
            width="50px"
            text-center
            @input="document.setValue(fieldsValuesProps[idx], $event)"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import { CompanyFinalActApi } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'CompanyFinalActWorkerList',

  components: { DocumentFieldWritable },

  mixins: [i18nScopeMixin],

  props: {
    document: CompanyFinalActApi,

    title: { type: String, required: true },
    titleValueProp: { type: String, required: true },
    fieldsValuesProps: { type: Array as PropType<string[]>, required: true },
  },

  data () {
    return {
      fieldsTitles: [],
    }
  },

  created () {
    this.setI18nScope('checkups.documents.companyFinalAct.list')

    this.fieldsTitles = [
      this.t$('women'),
      this.t$('underAge'),
      this.t$('permanentDegreeOfDisability'),
    ]
  },
})
</script>
