import CurrencyFormatter from '@/lib/currency_formatter/currency_formatter.js'

export default {
  filters: {
    formatNumberToFixed (number, format) {
      const formatter = new CurrencyFormatter(format.code, format.locale)

      return formatter.format(number)
    },
  },
}
