<template>
  <el-switch
    class="m-switch"
    :value="value"
    :class="customClass"
    :active-text="activeText"
    :inactive-text="inactiveText"
    :active-color="activeColor"
    :inactive-color="inactiveColor"
    :disabled="canManage.disabled"
    :active-value="activeValue"
    :inactive-value="inactiveValue"
    @focus="$emit('focus', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'

export default {
  name: 'MSwitch',

  mixins: [DisabledMixin],

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: { type: [Boolean, String, Number], default: null },
    customClass: { type: String, default: null },
    activeColor: { type: String, default: null },
    inactiveColor: { type: String, default: null },
    activeText: { type: String, default: null },
    inactiveText: { type: String, default: null },
    activeValue: { type: String, default: undefined },
    inactiveValue: { type: String, default: undefined },
  },
}
</script>
