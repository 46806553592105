import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import {
  checkupClientSearchAdapter,
} from '@/vue_apps/CheckupsModule/api/CheckupClientPresenter/CheckupClientSearchAdapter'

export class CheckupClientPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one () {},
        all () {},
        list: Routes.search_api_internal_checkups_clients_path,
      },
      entity: 'clients',
      location: 'CheckupClientPresenter',
    })
  }

  searchClient (data, config = {}) {
    return this.post({
      url: Routes.search_api_internal_checkups_clients_path(),
      data: JSON.stringify(camelToSnake(checkupClientSearchAdapter.toServer(data))),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .then((response) => checkupClientSearchAdapter.toClient(response))
  }
}
