import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import {
  documentPresenterCreateDocumentAdapter,
} from '@/_api/MDocumentPresenter/documentPresenterCreateDocumentAdapter'
import {
  documentPresenterUpdateDocumentAdapter,
} from '@/_api/MDocumentPresenter/documentPresenterUpdateDocumentAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'

export class MDocumentPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        all () {},
        one () {},
        list () {},
      },
      entity: 'document',
      location: 'DocumentsPresenter',
    })
  }

  fetchOldDocumentAttributes (data, config = {}) {
    return this.post({
      url: Routes.list_api_internal_documents_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
  }

  createDocument (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'createMessage',
      this.entity
    )

    return this.post({
      url: Routes.api_internal_documents_path(),
      data: JSON.stringify(camelToSnake(documentPresenterCreateDocumentAdapter.toServer(data))),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault(null, notification))
  }

  createFixedDocument (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'createMessage',
      this.entity
    )

    return this.post({
      url: Routes.create_fixed_api_internal_documents_path(),
      data: JSON.stringify(camelToSnake(data)),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault(null, notification))
  }

  update (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'updateMessage',
      this.entity
    )

    return super.patch({
      url: Routes.document_path(data.id),
      data: documentPresenterUpdateDocumentAdapter.toServer(data),
      contentType: false,
      processData: false,
      ...config,
    }).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault(null, notification))
  }
}
