import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  personalCheckupInstrumentalResearchResultsAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInstrumentalResearchResultsPresenter/personalCheckupInstrumentalResearchResultsAdapter'
import { NestedItemsPresenter } from '@/_api/_classes/NestedItems/api/NestedItemsPresenter/NestedItemsPresenter'

export class PersonalCheckupInstrumentalResearchResultsPresenter extends NestedItemsPresenter {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
      },

      adapter: personalCheckupInstrumentalResearchResultsAdapter,

      singularEntity: 'personalCheckupInstrumentalResearchResult',
      entity: 'personalCheckupInstrumentalResearchResults',
      location: 'PersonalCheckupInstrumentalResearchResultsPresenter',
    })
  }

  fetchResearches (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'researches'
    )

    return super.get({
      url: Routes.api_internal_selectors_checkups_instrumental_researches_list_path(),
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }

  fetchMedicalAssessments (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'conclusions'
    )

    return super.get({
      url: Routes.api_internal_selectors_checkups_instrumental_researches_medical_assessments_list_path(),
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
