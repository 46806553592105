import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class AttachmentsListItem extends MListServiceItem {
  /**
   * @param {{
   *   date: string,
   *   id: number,
   *   mimeType: string,
   *   name: string,
   * }} data
   */
  constructor (data) {
    super()
    this.id = data.id
    this.mimeType = data.mimeType
    this.printJSMimeType = this.__extractMimeTypeForPrintJS(data.mimeType)
    this.dataAttributes = {
      'data-mime-type': data.mimeType,
      'data-src': Routes.show_client_attachment_path(data.id),
      href: Routes.show_client_attachment_path(data.id),
      'data-filename': data.name,
      'data-turbolinks': 'false',
      'data-mime-type-print-js': Boolean(this.printJSMimeType).toString(),
    }

    this.date = new MTableCell(data.date)

    this.title = new MTableCell(data.name)
      .addTooltip(true)
      .addCut()
      .addClasses(this.printJSMimeType ? 'primary' : '')

    this.download = new MTableCell(data.name)
      .addTypeDownload(Routes.show_client_attachment_path(data.id))

    this.print = new MTableCell()
      .addTooltip(t('print'))
      .addPreventDefault()
    if (this.printJSMimeType) {
      this.print
        .addTypeIcon('print')
    }
  }

  __extractMimeTypeForPrintJS (mimeType) {
    switch (mimeType) {
      case 'application/pdf': return 'pdf'
      case 'image/jpeg': return 'image'
      case 'image/jpg': return 'image'
      case 'image/png': return 'image'
      default: return ''
    }
  }
}
