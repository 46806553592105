<template>
  <div class="sidebar__filter filter">
    <label
      class="filter__label"
      for="client-period"
    >
      {{ t('period') }}
    </label>

    <date-range-picker
      id="client-period"
      :date-range="filters[period]"
      class="filter__input"
      :fallback-value="[]"
      :format="dtFormat"
      @change="vxSetFilterValue({ filter: period, value: $event })"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import formatter from '@/lib/formatters/formatter'
import DateRangePicker from '@/vue_components/common/select/date_range_picker'
import { CLIENTS_FILTERS_FIELDS } from '@/vue_components/egisz/egisz_module/const/egisz_filters'

export default {
  name: 'EgiszClientsFilterPeriodSelect',
  components: { DateRangePicker },

  data () {
    return {
      period: CLIENTS_FILTERS_FIELDS.PERIOD,
    }
  },

  computed: {
    ...mapGetters('egisz/clients/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapGetters({
      dtFormat: 'GET_LOCALIZATION_DATEPICKER_FORMAT',
    }),
  },

  methods: {
    ...mapMutations('egisz/clients/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
    }),

    getFullName (doctor) {
      return formatter.formatValue(doctor, ['user:name'])
    },
  },
}
</script>
