import { ISemd119MetaData } from '@/vue_apps/Semds/entities/Semd119/ISemd119MetaData'
import { ICatalog } from '@/_declarations/ICatalog'
import { IHuman } from '@/_declarations/IHuman'
import { INsiEntry } from '@/_declarations/INsiEntry'

export class Semd119MetaData implements ISemd119MetaData {
  _documentModel: ICatalog<number> = null

  _medicalCaseModel: ICatalog<number> = null

  _medicalPolicyModel: ICatalog<number> = null

  consultationDate: string = null

  consultationTimeEnd: string = null

  consultationTimeStart: string = null

  doctor: IHuman = null

  documentId: number = null

  medicalPolicyId: number = null

  nsiFoundationDocumentType: INsiEntry = null

  nsiPaymentSource: INsiEntry = null

  nsiPrivacyLevel: INsiEntry = null

  foundationDocumentDateEnd: string = null
}
