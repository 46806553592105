<template>
  <div class="whatsapp-common-settings-page">
    <div
      v-if="whatsappEnabled"
      class="whatsapp-common-settings-page__module-enabled"
    >
      <div class="whatsapp-common-settings-page__api-key">
        <m-input
          :label="t('whatsapp.apiKey')"
          :value="apiToken"
          validator-name="apiToken"
          :placeholder="t('whatsapp.apiKeyInfo')"
          required
          @registerValidator="onRegisterValidator"
          @input="changeApiToken"
        />
      </div>

      <div class="webhook-button mb-indent-validation">
        <m-button
          :text="t('whatsapp.checkApiToken')"
          @click="setWebHookKey"
        />
        <div
          v-if="webhookStatusMessage"
          class="webhook-status"
        >
          {{ `${t('status')}: ${webhookStatusMessage}` }}
        </div>
      </div>

      <template v-if="isApiTokenValid">
        <div class="whatsapp-common-settings-page__wazzup-channel">
          <m-select
            :items="wazzupChannels"
            :value="channelId"
            option-label="name"
            value-key="channelId"
            :label="t('sms.choose_channel')"
            validator-name="wazzupChannelId"
            required
            @registerValidator="onRegisterValidator"
            @change="changeChannel"
          />
        </div>

        <messages-configuration-section-title
          :title="t('whatsapp.channelType')"
          :tag-text="channelType"
          :subtitle="t('whatsapp.channelsAdditionalInfo')"
        />

        <div class="whatsapp-common-settings-page__patient-agreement">
          <m-checkbox
            :value="newClientsDenyDefault"
            :label="t('whatsapp.newClientsDenyDefault')"
            @input="vxSetNewClientsDenyDefault"
          />
        </div>

        <div class="whatsapp-common-settings-page__sending-time">
          <m-time-picker
            :label="t('sms.message_sending_time')"
            :start-time="sendingTimeLimit.sendFromTime"
            :end-time="sendingTimeLimit.sendToTime"
            :required="isSendingTimeLimit"
            validator-name="sending-time-limit"
            @changeStartTime="changeStartTime"
            @changeEndTime="changeEndTime"
            @registerValidator="onRegisterValidator"
          />
          <div class="explanation explanation_bold explanation_small explanation_solo">
            {{ t('whatsapp.sendingTimeClarification') }}
          </div>
        </div>

        <messages-configuration-section-title
          :title="t('whatsapp.channelStatus')"
          :tag-text="channelStatus"
        />

        <!--    TODO: Временно скрыла, позже со Славой доведем до ума-->
        <!--    <div class="whatsapp-common-settings-page__failure-notification">-->
        <!--      <m-checkbox-->
        <!--        :value="channelFailuresNotifications"-->
        <!--        :label="t('sms.failure_notification')"-->
        <!--        @input="vxSetChannelFailuresNotifications"-->
        <!--      />-->
        <!--    </div>-->

        <!--    <div-->
        <!--      v-if="channelFailuresNotifications"-->
        <!--      class="whatsapp-common-settings-page__responsible"-->
        <!--    >-->
        <!--      <m-select-->
        <!--        :items="getDoctors"-->
        <!--        :value="channelFailuresUsers"-->
        <!--        :disabled="!channelFailuresNotifications"-->
        <!--        multiple-->
        <!--        option-label="short_name"-->
        <!--        @change="vxSetChannelFailuresUsers"-->
        <!--      />-->
        <!--    </div>-->

        <m-button
          template="save"
          :disabled="!whatsappEnabled"
          @click="saveForm"
        />
      </template>
    </div>
    <introduction
      v-else
      icon="fad fa-comments"
      :introduction-text="t('introduction.whatsapp')"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MTimePicker from '@/vue_present/_base/inputs/MTimeSelect/MTimeSelect.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { cloneDeep } from 'lodash'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import { MWazzupPresenter } from '@/_api/Deliveries/MWazzupPresenter'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { WABA_CHANNEL, WABA_CHANNEL_TYPE, WHATSAPP_API } from '@/vue_apps/app_configuration/messages/scripts/consts'
import Introduction from '@/vue_components/introduction/introduction.vue'
import MessagesConfigurationSectionTitle
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesConfigurationSectionTitle.vue'

export default {
  name: 'CommonSettingsPage',
  components: {
    Introduction,
    MButton,
    MCheckbox,
    MTimePicker,
    MSelect,
    MInput,
    MessagesConfigurationSectionTitle,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
  },

  data () {
    return {
      webhookStatusMessage: null,
      isApiTokenValid: null,
      apiTokenWatcher: null,
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctors: 'getDoctors',
    }),

    ...mapGetters('messagesStore', {
      messagesConfigurations: 'vxGetMessagesConfigurations',
      sendingTimeLimit: 'vxGetSendingTimeLimit',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      wazzupChannels: 'vxGetWazzupChannels',
      channelId: 'vxGetChannelId',
      apiToken: 'vxGetApiToken',
      vxGetChannelType: 'vxGetChannelType',
      newClientsDenyDefault: 'vxGetNewClientsDenyDefault',
      channelFailuresNotifications: 'vxGetChannelFailuresNotifications',
      channelFailuresUsers: 'vxGetChannelFailuresUsers',
    }),

    isSendingTimeLimit () {
      return () => {
        if (this.sendingTimeLimit.sendFromTime && this.sendingTimeLimit.sendToTime) { return [] }

        return [t('errors.filled?')]
      }
    },

    channelType () {
      if (!this.vxGetChannelType) { return }

      return this.vxGetChannelType && this.vxGetChannelType !== WABA_CHANNEL_TYPE
        ? WHATSAPP_API
        : WABA_CHANNEL
    },

    channelStatus () {
      const selectedChannel = this.wazzupChannels.find((item) => item.channelId === this.channelId?.channelId)

      return selectedChannel?.state
        ? t(`whatsapp.channel_statuses.${selectedChannel.state}`)
        : ''
    },
  },

  watch: {
    channelFailuresNotifications (newValue) {
      if (newValue) { return }
      this.vxSetChannelFailuresUsers([])
    },
  },

  created () {
    if (!this.whatsappEnabled) { return }

    this.vxSetApiToken(null)
    this.apiTokenWatcher = this.$watch('apiToken', (to) => this.apiTokenLoadedCheck(to))
  },

  mounted () {
    if (!this.whatsappEnabled) { return }

    this.vxFetchMessagesConfigurations()
    this.vxFetchWhatsappConfigurations()
    this.loadDoctors()
    this.vxFetchWazzupChannels()
  },

  methods: {
    ...mapActions('globalCatalogs/doctorStore', {
      loadDoctors: 'loadDoctors',
    }),

    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      vxFetchWazzupChannels: 'vxFetchWazzupChannels',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      vxSetSendingTimeLimit: 'vxSetSendingTimeLimit',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      vxSetChannelId: 'vxSetChannelId',
      vxSetApiToken: 'vxSetApiToken',
      vxSetChannelType: 'vxSetChannelType',
      vxSetNewClientsDenyDefault: 'vxSetNewClientsDenyDefault',
      vxSetChannelFailuresUsers: 'vxSetChannelFailuresUsers',
      vxSetChannelFailuresNotifications: 'vxSetChannelFailuresNotifications',
    }),

    apiTokenLoadedCheck (to) {
      this.isApiTokenValid = Boolean(to)

      this.apiTokenWatcher()
    },

    changeStartTime (time) {
      this.vxSetSendingTimeLimit({ sendFromTime: time, sendToTime: this.sendingTimeLimit.sendToTime })
    },
    changeEndTime (time) {
      this.vxSetSendingTimeLimit({ sendFromTime: this.sendingTimeLimit.sendFromTime, sendToTime: time })
    },

    changeApiToken (token) {
      this.isApiTokenValid = false

      this.vxSetApiToken(token)
      this.vxFetchWazzupChannels()
    },

    changeChannel (channel) {
      if (!channel) {
        this.vxSetChannelId(null)
        this.vxSetChannelType(null)
      }
      this.vxSetChannelId(channel?.channelId)
      this.vxSetChannelType(channel?.transport)
    },

    setWebHookKey () {
      const presenter = new MWazzupPresenter()
      presenter.setWebHookKey({ apiKey: this.apiToken })
        .then((data) => {
          if (data.error) {
            this.isApiTokenValid = false
            this.webhookStatusMessage = data.error

            return
          }

          this.isApiTokenValid = true
          this.webhookStatusMessage = t('whatsapp.statusOk')

          new MWhatsappConfigurationsPresenter().saveConfigurations({ apiToken: this.apiToken })
            .then((data) => {
              if (data.error) {
                this.isApiTokenValid = false

                return
              }

              this.vxFetchWhatsappConfigurations()
            })

          this.vxFetchWazzupChannels()
        })
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const presenterMessages = new MMessagesConfigurationPresenter()
      const presenterWhatsapp = new MWhatsappConfigurationsPresenter()

      const messagesConfigurations = cloneDeep(this.sendingTimeLimit)
      const whatsappConfigurations = {
        channelId: this.channelId.channelId,
        apiToken: this.apiToken,
        channelType: this.vxGetChannelType,
        sendOnlyAgreed: this.sendOnlyAgreed,
        newClientsDenyDefault: this.newClientsDenyDefault,
      }

      Promise.all([
        presenterMessages.update(messagesConfigurations),
        presenterWhatsapp.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
