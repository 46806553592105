<template>
  <m-panel
    v-loading="loading"
    :title="t('checkups.documents.bladeRunner')"
    class="personal-blade-runner"
    use-print-button
  >
    <div class="checkup-document">
      <blade-runner-view :blade-runner="bladeRunner" />
    </div>

    <template #footer>
      <m-button-submit @click="onSubmit" />

      <m-button-delete
        use-button
        @yes="onDestroy"
      />
    </template>
  </m-panel>
</template>

<script>
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { BladeRunner } from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunner'
import BladeRunnerView from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/BladeRunner/BladeRunnerView.vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'

export default {
  name: 'PersonalBladeRunner',
  components: { MButtonDelete, MButtonSubmit, MPanel, BladeRunnerView },
  mixins: [SpinnerHolder],

  props: {
    id: { type: Number, default: null },
  },

  emits: [
    'setSaveCallback',
    'setTitle',
    'destroy',
  ],

  data () {
    return {
      bladeRunner: new BladeRunner(this.id),
    }
  },

  async created () {
    if (!this.bladeRunner.id) { return }

    this.bladeRunner = await this.withSpinner(this.bladeRunner.load())

    this.$emit('setTitle', t('checkups.documents.bladeRunner'))
  },

  methods: {
    async onSubmit () {
      await this.bladeRunner.save()
    },

    async onDestroy () {
      await this.bladeRunner.destroy()

      this.$emit('destroy')
    },
  },
}
</script>
