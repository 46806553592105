import { DATE_PICKER_BUTTONS, dateButtons } from '@/vue_present/_base/MDatePickerButtons/helpers'

export const getDefaultPaymentsFilters = () => {
  return {
    paymentSpecies: null,
    user: null,
    kind: null,
    receiptStatus: null,
    workplaceId: null,
    customerType: null,
    searchString: null,
    date: dateButtons[DATE_PICKER_BUTTONS.TODAY].value,
    clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
  }
}
