<template>
  <m-table-row-cell
    class="m-table-row-cell_sortable"
    :name="name"
    :width="width"
    @click="setSorting"
  >
    <template #default>
      <slot />

      <div class="sort-state sort-state__container">
        <i
          class="sort-state sort-state_asc fad fa-caret-up"
          :class="{ 'sort-state_active': upActive }"
        />
        <i
          class="sort-state sort-state_desc fad fa-caret-down"
          :class="{ 'sort-state_active': downActive }"
        />
      </div>
    </template>
  </m-table-row-cell>
</template>

<script>
import MTableRowCell from './MTableRowCell.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

const sortStates = {
  asc: { name: 'asc', next: 'desc', order: true },
  desc: { name: 'desc', next: 'noSort', order: false },
  noSort: { name: 'noSort', next: 'asc', order: null },
}

export default {
  name: 'MTableRowCellSortable',
  components: { MTableRowCell },
  props: {
    name: {
      type: String,
      required: true,
    },

    activeSortableName: {
      type: String,
      required: true,
    },

    width: PropsTypes.String(''),
  },

  emits: [
    'sortChange',
  ],

  data () {
    return {
      order: sortStates.noSort.name,
    }
  },

  computed: {
    isActive () {
      return this.activeSortableName === this.name
    },

    upActive () {
      return this.isActive && this.order === sortStates.asc.name
    },

    downActive () {
      return this.isActive && this.order === sortStates.desc.name
    },
  },

  watch: {
    active () {
      if (!this.isActive) {
        this.order = sortStates.noSort.name
      }
    },
  },

  methods: {
    setSorting () {
      this.order = sortStates[this.order].next

      if (this.order === sortStates.noSort.name) {
        this.$emit('sortChange', {})

        return
      }

      this.$emit('sortChange', {
        name: this.name,
        order: sortStates[this.order].order,
        priority: 1, /* заготовка на будущее, если понадобится несколько сортировок */
      })
    },
  },
}
</script>
