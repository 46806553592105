import {
  IPersonalCheckupRemoteFilesToServerDTO,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupRemoteFiles/IPersonalCheckupRemoteFilesToServerDTO'
import {
  IPersonalCheckupRemoteFile,
} from '@/vue_apps/CheckupsModule/interfaces/PersonalCheckupRemoteFiles/IPersonalCheckupRemoteFile'
import { IList } from '@/_declarations/IList'
import {
  AttachmentsListItem,
} from '@/vue_apps/MedicalRecords/MedicalRecordsTabs/components/AttachmentsTab/api/AttachmentsListItem'

export const personalCheckupRemoteFilesAdapter = {
  toServer (data: IPersonalCheckupRemoteFilesToServerDTO) {
    const formData = new FormData()

    formData.append('personal_checkup_id', data.personalCheckupId.toString())
    formData.append('file', data.files[0].file)
    formData.append('original_filename', data.files[0].name)

    return formData
  },

  toClient (data: IList<IPersonalCheckupRemoteFile[]>) {
    return {
      ...data,
      data: data.data.map((item) => new AttachmentsListItem(item)),
    }
  },
}
