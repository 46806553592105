import { PAYMENT_PARAMS } from './consts'
import Payment from './payment'

export default class BalancePayment extends Payment {
  constructor (attr) {
    super(attr)
    if (this.kind === PAYMENT_PARAMS.KIND_PAYMENT.CASH_IN) {
      this.deposit = this.totalIncome
    }
  }

  get totalIncome () {
    if (this.kind === PAYMENT_PARAMS.KIND_PAYMENT.CASH_IN) {
      return this._summary([this.by_cash, this.by_card, this.by_cashless])
    }

    return super.totalIncome
  }
}
