<template>
  <div class="flex gap-indent-3">
    <!--  Штрих-код и квадратики  -->
    <div class="flex">
      <document-black-square />
      <img
        :src="PAGE_HEADER_BARCODE[page]"
        width="120px"
      >
      <document-black-square />
    </div>

    <document-square-field-labeled>
      <template #label>
        <span>{{ t('inn') }}</span>
        <span>{{ t('kpp') }}</span>
      </template>

      <template #field>
        <!--  ИНН  -->
        <document-square-field
          :value="certificate.clinic.inn"
          :line-size="12"
          fill-blanks-at-end
        />

        <!--  КПП  -->
        <div class="flex">
          <document-square-field
            :value="certificate.clinic.kpp"
            :line-size="9"
            fill-blanks-at-end
          />

          <!--  Номер страницы  -->
          <document-square-field-labeled>
            <template #label>
              <span style="margin-left: 4px">
                {{ t$('shortPage') }}
              </span>
            </template>

            <template #field>
              <document-square-field
                :value="currentPageNumber"
                :line-size="3"
              />
            </template>
          </document-square-field-labeled>
        </div>
      </template>
    </document-square-field-labeled>

    <!--  Приложение к приказу ФНС  -->
    <div
      v-if="page === FIRST_PAGE"
      class="flex flex-grow-1 justify-content-end"
      v-html="t$('fnsTitle')"
    />
  </div>
</template>

<script>
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import DocumentBlackSquare from '@/vue_present/DocumentBase/DocumentBlackSquare/DocumentBlackSquare.vue'
import DocumentSquareField from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareField.vue'
import {
  FIRST_PAGE,
  PAGE_HEADER_BARCODE,
  SECOND_PAGE,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024PrintableConsts'
import DocumentSquareFieldLabeled
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldLabeled/DocumentSquareFieldLabeled.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default {
  name: 'TaxCertificate2024PrintablePageHeader',
  components: { DocumentSquareFieldLabeled, DocumentSquareField, DocumentBlackSquare },
  mixins: [i18nScopeMixin],

  props: {
    certificate: TaxCertificate2024,
    page: { type: Number, required: true },
  },

  data () {
    return {
      FIRST_PAGE,
      SECOND_PAGE,
      PAGE_HEADER_BARCODE,
    }
  },

  computed: {
    currentPageNumber () {
      return `00${this.page}`
    },
  },

  created () {
    this.setI18nScope('fixedDocument.taxCertificate2024.print')
  },
}
</script>
