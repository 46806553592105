import { pick } from 'lodash'
import { GLOBAL_MEDICAL_POLICY_KINDS, GLOBAL_MEDICAL_POLICY_KINDS_ENUM } from '@/_global_scripts/GLOBAL_CONSTS'

export const medicalPolicyAdapter = {
  /**
   * @param {IMedicalPolicy} data
   */
  toServer (data) {
    return {
      ...pick(data, [
        'number',
        'dateStart',
        'dateEnd',
        'clientId',
        'active',
        'indefinite',
        'omsSeries',
        'program',
      ]),

      companyId: data.company?.id,
      insuranceCompanyId: data.insuranceCompany?.id,
      nsiOmsTypeId: data.policyType !== GLOBAL_MEDICAL_POLICY_KINDS.DMS
        ? data.nsiOmsType?.id
        : undefined,
      policyType: GLOBAL_MEDICAL_POLICY_KINDS_ENUM[data.policyType],
    }
  },
}
