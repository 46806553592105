import {
  PersonalElmkMedicalAssessmentPresenter,
} from '@/vue_apps/CheckupsModule/api/PersonalElmkMedicalAssessmentPresenter/PersonalElmkMedicalAssessmentPresenter'
import {
  PersonalElmkMedicalAssessmentLogic,
} from '@/vue_apps/CheckupsModule/classes/PersonalElmkMedicalAssessment/PersonalElmkMedicalAssessmentLogic'
import { Api } from '@/_api/decorators/api/Api'
import { IApiErrors } from '@/_declarations/IApiErrors'
import { SEMDS_OWNER_TYPES } from '@/vue_apps/Semds/SemdModal/const/const'

export class PersonalElmkMedicalAssessmentApi extends PersonalElmkMedicalAssessmentLogic {
  sourceType = SEMDS_OWNER_TYPES.CHECKUP_ELMK

  private presenter = new PersonalElmkMedicalAssessmentPresenter()

  async load () {
    const data = await this.presenter.fetch(this.id)

    if (data.errors) { return }

    this.fillProps(data)
  }

  update () {
    return this.presenter.update(this.getAttributes())
      .then((data) => {
        if (!data?.errors) {
          this.setValue('newSemdApproved', false)
          this.setValue('newSignatureApproved', false)
        }

        return data
      })
  }

  destroy () {
    return this.presenter.destroy(this.id)
  }

  refresh (): Promise<any | IApiErrors> {
    return this.presenter.refresh(this.id, {
      newSemdApproved: this.newSemdApproved,
      newSignatureApproved: this.newSignatureApproved,
    })
  }

  @Api.notify('', '', { success: false })
  @Api.post(window.Routes.check_semd_data_api_internal_checkups_documents_personals_elmk_medical_assessment_path)
  validateSemd (): Promise<Record<string, string[]> | IApiErrors> {
    return {
      // @ts-ignore
      routeArgs: [this.id],
    }
  }
}
