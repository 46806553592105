<template>
  <div class="checkup-filters">
    <div class="checkup-filters__selectors">
      <m-select
        :value="filters.clinic"
        class="checkup-filters__clinics"
        :label="t('clinic')"
        :placeholder="t('all_clinics')"
        :items="vxClinics"
        @change="onFilterChange('clinic', $event)"
      />

      <m-select
        :value="filters.status"
        class="checkup-filters__statuses"
        :label="t('status')"
        :placeholder="t('all_statuses')"
        :items="vxGetStatusesWithIcons"
        use-custom-result="simple"
        use-icon
        @change="onFilterChange('status', $event)"
      >
        <template #prefix>
          <m-icon
            v-if="filters.status"
            :icon="CHECKUP_STATUS_ICONS[filters.status].icon"
            :color="CHECKUP_STATUS_ICONS[filters.status].color"
          />
          <m-icon
            v-else
            icon="checkups"
            color=""
          />
        </template>
      </m-select>

      <m-date-picker
        type="daterange"
        :value="filters.period"
        class="checkup-filters__date-picker checkup-filters__date-picker-from"
        value-format="yyyy-MM-dd"
        :label="t('checkups.personal.info.date')"
        @change="onFilterChange( 'period', $event)"
      />

      <company-search-create
        :value="filters.company"
        class="checkup-filters__organization"
        :label="t('company')"
        @change="onFilterChange( 'company', $event)"
      >
        <template #button-with-modal>
          <span />
        </template>
      </company-search-create>

      <reusable-doctors-list
        :value="filters.responsible"
        class="checkup-filters__users"
        :label="t('responsible_user')"
        :placeholder="t('choose')"
        :current-clinic="vxClinicsIds"
        m-fixed-height
        @onDoctorChange="onFilterChange( 'responsible', $event)"
      />

      <m-select
        v-if="usingWithOrderFilter"
        :label="t('order')"
        :value="filters.withOrder"
        :placeholder="t('checkups.personal.hasOrderFilter.all')"
        :items="orderFilters"
        class="checkup-filters__order"
        use-custom-result="simple"
        value-key="value"
        @change="onFilterChange('withOrder', $event)"
      />
    </div>

    <div class="checkup-filters__buttons">
      <m-button
        template="refresh"
        :disabled="list.loading"
        @click="refreshData"
      />

      <m-button
        type="default"
        :tooltip="t('reset_all_filters')"
        no-use-fw
        icon="no-filter"
        icon-color="grey-dark"
        :disabled="list.loading"
        @click="resetData"
      />
    </div>
  </div>
</template>

<script>
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import CompanySearchCreate
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanySearchCreate.vue'
import { mapGetters } from 'vuex'
import { MListService } from '@/_api/_requests/MListService'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { CHECKUP_STATUS_ICONS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import { getCheckupByOrderFilters } from '@/vue_apps/CheckupsModule/store/getCheckupByOrderFilters'
import { DEFAULT_SEARCH_VALUE } from '@/vue_components/store/modules/filters_base'

export default {
  name: 'CheckupsFilters',
  components: { MIcon, CompanySearchCreate, ReusableDoctorsList, MSelect, MButton, MDatePicker },
  props: {
    list: MListService,
  },

  data () {
    return {
      CHECKUP_STATUS_ICONS,
      orderFilters: getCheckupByOrderFilters(),
    }
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxClinics: 'vxGetDoctorClinics',
      vxClinicsIds: 'vxGetDoctorClinicsIds',
    }),

    ...mapGetters('appointmentCallLogStore', {
      vxDoctors: 'vxGetDoctors',
    }),

    ...mapGetters({
      getClinicId: 'GET_APP_CONF_CURRENT_CLINIC_ID',
      getCurrentUserId: 'GET_APP_CONF_CURRENT_USER_ID',
    }),

    ...mapGetters('checkupsStore', {
      vxGetStatuses: 'vxGetStatuses',
      vxGetStatusesWithIcons: 'vxGetStatusesWithIcons',
    }),

    filters () {
      return this.list.filtersData
    },

    usingWithOrderFilter () {
      return Object.hasOwn(this.filters, 'withOrder')
    },
  },

  methods: {
    refreshData () {
      this.list.refreshFilters()

      this.resetSearchQueryAndFetch()
    },

    resetData () {
      this.list.resetFilters()

      this.resetSearchQueryAndFetch()
    },

    onFilterChange (name, value) {
      this.list.setFilterValue(name, value)

      this.resetSearchQueryAndFetch()
    },

    resetSearchQueryAndFetch () {
      this.list.setSearchQuery(DEFAULT_SEARCH_VALUE)

      this.list.fetchAll()
    },
  },
}
</script>
