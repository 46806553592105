<template>
  <div class="sidebar__filter filter">
    <label class="filter__label">
      {{ t('egisz.components.egisz_cases_filters.date_closed') }}
    </label>
    <date-range-picker
      :date-range="filters[closedAt]"
      :format="dtFormat"
      :fallback-value="[]"
      class="filter__input"
      @input="vxSetFilterValue({ filter: closedAt, value: $event })"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import DateRangePicker from '@/vue_components/common/select/date_range_picker'
import { CASE_FILTERS_FIELDS } from '@/vue_components/egisz/egisz_module/const/egisz_filters'

export default {
  name: 'EgiszCasesFilterDateClosed',
  components: { DateRangePicker },

  data () {
    return {
      closedAt: CASE_FILTERS_FIELDS.CLOSED_AT,
    }
  },

  computed: {
    ...mapGetters('medCases/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapGetters({
      dtFormat: 'GET_LOCALIZATION_DATEPICKER_FORMAT',
    }),
  },
  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
    }),
  },
}
</script>
