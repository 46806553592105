import { getDate } from '@/helpers/vanilla_date'
import { EGISZ_STATUSES_ENUM } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export const DEFAULT_SHIFT = [30, 'minutes']

export const DEFAULT_MED_CASE_COMMENT = t('egisz.medical_cases.comment_text')

export function createEmptyMedCase (injectedAttributes) {
  return {
    id: null,
    register_in_egisz: false,
    netrika_status: null,
    legal_representative_id: null,
    doctor_id: null,
    author_id: null,
    clinic_id: null,

    netrika_case_visit_type: null,
    netrika_case_type_id: null,
    netrika_payment_type_id: null,
    netrika_confidentiality: null,
    opened_at: getDate(),
    closed_at: null,
    closed: false,

    steps: [],
    medical_documents: [],
    medical_document_ids: [],

    netrika_case_result_id: null,
    diagnosis: getDefaultDiagnosis(),
    comment: DEFAULT_MED_CASE_COMMENT,

    ...injectedAttributes, // f.e.: clinic and client data
  }
}

export const getDefaultDiagnosis = () => ({
  doctor_id: null,
  diagnosis_type_id: null,
  disease_id: null,
  date: getDate(),
  comment: t('egisz.medical_cases.diagnosis_comment_text'),
  clinic_id: null,
})

export function createEmptyStep (extra = {}) {
  const now = new Date()

  return {
    id: now.getTime().toString(),
    doctor_id: null,
    title: null,
    type: null, // ?
    opened_at: now,
    closed_at: moment(now).add(...DEFAULT_SHIFT).toDate(),
    netrika_visit_place_id: null,
    netrika_visit_purpose_id: null,
    comment: null,
    entries_list: [],
    entry_ids: [],
    ...extra,
  }
}

export function createEmptyMedRecord (options = {}) {
  return {
    id: new Date().getTime().toString(),
    diagnosis_id: null,
    diagnosis_info: null,
    type: null,
    diagnosis_date: null,
    doctor_id: null,
    comment: null,
    status: EGISZ_STATUSES_ENUM.NEED_PERSONAL_SIGNATURE,
    entry_id: null,

    med_document_ids: [],

    ...options,
  }
}

export function createEmptyStandAloneMedRecord () {
  return {
    id: null,
    created_at: null,
    client_id: null,
    client_full_name: null,
    doctor_id: null,
    author_id: null,
    doctor_full_name: null,
    author_full_name: null,
    status: null,
    registration_errors: null,
  }
}

export function createEmptyMedDocument () {
  return {
    id: null,
    authorFullName: '',
    date: getDate(),
    header: '',
    typeId: null,
  }
}
