import { ItemBase } from '@/_api/_classes/ItemBase'
import { IFeedbackDTO } from '@/vue_apps/FeedbackModule/interfaces/IFeedbackDTO'

export class MFeedbackModel extends ItemBase {
  _permit = [
    'id',
    'client',
    'doctor',
    'receivedAt',
    'doctorScore',
    'clinicScore',
    'clientComment',
    'status',
    'responsible',
    'responsibleUserComment',
    'updatedAt',
    'updatedByUser',
    'urls',
  ]

  id: number = null

  client: {
    id: number
    fullName: string
    phone: string
  } = null

  doctor: {
    id: number
    fullName: string
  } = null

  receivedAt: string = null

  doctorScore: number = null

  clinicScore: number = null

  clientComment: string = null

  status: string = null

  responsible: {
    id: number
    fullName: string
  } = null

  responsibleUserComment: string = null

  updatedAt: string = null

  updatedByUser: {
    id: number
    fullName: string
  } = null

  urls: string[] = []

  constructor (data?: IFeedbackDTO) {
    super()

    this._fillProps(data)
  }
}
