<template>
  <span
    v-tooltip="tooltip"
    :class="css"
    class="flex align-center cut"
  >
    <user-avatar
      :user-id="userId"
      :params="{ version: 'thumb25', tag: avatarTag }"
      class="mr-10"
    />
    <span class="cut">{{ value }}</span>
  </span>
</template>

<script>
import UserAvatar from '@/vue_components/user_avatar.vue'

export default {
  name: 'TypeUserWithAvatar',
  components: { UserAvatar },
  props: [
    'tooltip',
    'value',
    'css',
    'userId',
    'avatarTag',
  ],
}
</script>
