import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { findSemdState, SEMD_STATE_ENUM } from '@/vue_apps/Semds/SemdModal/const/const.js'

export class SemdListItem extends MListServiceItem {
  /**
   * @param {{
   *     id: number,
   *     title: string,
   *     state: string,
   *     signed: boolean
   * }} data
   */
  constructor (data) {
    super()

    this.id = data.id

    this.rawStatus = data.state

    this.title = new MTableCell(data.title)
      .addTooltip(true)
      .addCut()

    const iconObj = findSemdState(data.state)
    this.state = new MTableCell()
      .addTypeIcon(iconObj.icon)
      .addClasses(iconObj.color)
      .addTooltip(iconObj.title)
  }

  isRegisteredInEgisz () {
    return this.rawStatus === SEMD_STATE_ENUM.REGISTERED_IN_EGISZ
  }

  isSuccessFullySent () {
    return this.rawStatus === SEMD_STATE_ENUM.SUCCESSFULLY_SENT
  }

  isOutdatedVersion () {
    return this.rawStatus === SEMD_STATE_ENUM.OUTDATED_VERSION
  }

  isSigned () {
    return this.rawStatus !== SEMD_STATE_ENUM.NEED_DOCTOR_SIGNATURE
  }
}
