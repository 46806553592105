import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class ClientsListItem {
  /** @param {TClientsListItem} client */
  constructor (client) {
    this.id = client.id
    this.emkNumber = client.emkNumber
    this.fullName = client.fullname
    this.formattedBirthdate = client.formattedBirthdate
    this.groupTitles = client.groupTitles
    this.phone = client.phone
  }

  get tablePhone () {
    return new MTableCell(this.phone).addTypePhone(this.id)
  }

  get tableGroups () {
    return new MTableCell(this.groupTitles).addTooltip(this.groupTitles)
  }

  get tableBirthdate () {
    return new MTableCell(this.formattedBirthdate)
  }

  get tableFullName () {
    return new MTableCell(this.fullName)
  }

  get tableEmk () {
    return new MTableCell(this.emkNumber).addTooltip(this.emkNumber)
  }
}
