<template>
  <panel-heading
    class="company-container"
    :title="t('companies')"
    icon="fad fa-building"
  >
    <div class="companies-list sort_items">
      <si :show-tooltips="true">
        <si-control>
          <div class="search-group">
            <router-link
              v-if="canManage"
              class="btn btn-success btn-sm hidden-print clients-options-btn btn-with-icon"
              :title="t('add_company')"
              :to="{ name: COMPANY_NEW }"
            >
              <span class="fad fa-building btn-icon-left btn-with-icon_icon" />
              <span class="fas fa-fw fa-plus" />
            </router-link>
            <div class="search-wrap">
              <search-input
                :value.sync="fetched.companies.searchQuery"
                :placeholder="`${t('title')}, ${t('inn')}`"
                :debounce="800"
              />
            </div>
          </div>

          <template #refresh>
            <span />
          </template>
          <template #reset>
            <span />
          </template>
        </si-control>
        <si-table
          :spinner="fetched.companies.loading"
          :error="fetched.companies.error"
          :not-found="!fetched.companies.list.length"
          class="table"
        >
          <template #header>
            <si-field>
              <div class="title">
                {{ t('title') }}
              </div>
              <div class="inn">
                {{ t('inn') }}
              </div>
              <div class="phone">
                {{ t('phone') }}
              </div>
              <div class="options" />
            </si-field>
          </template>
          <template #body>
            <si-field
              v-for="company in fetched.companies.list"
              :key="company.id + company.title.trim()"
              class="clickable"
              @safe-click="directToCompanyPage(company.id)"
              @contextmenu.prevent="(e) => openCtxMenu(e, company.id)"
            >
              <div
                v-tooltip="company.title"
                class="title"
              >
                {{ company.title }}
              </div>
              <div class="inn">
                {{ company.individual_tax_number | absent }}
              </div>
              <div class="phone">
                <phone
                  v-if="company.phone1"
                  class="ignore-click"
                  :security-mask="false"
                  :raw-phone="company.phone1"
                  :surrogate="t('absence')"
                />
                <phone
                  v-else-if="company.phone2"
                  class="ignore-click"
                  :security-mask="false"
                  :raw-phone="company.phone2"
                  :surrogate="t('absence')"
                />
                <span v-else>{{ '—' }}</span>
              </div>
              <router-link
                v-if="canManage"
                v-tooltip="t('edit')"
                :to="{ name: COMPANY_EDIT, params: { companyId: company.id } }"
                class="ignore-click"
              >
                <div class="options ignore-click">
                  <span
                    class="fad fa-edit ctx-icon ignore-click"
                  />
                </div>
              </router-link>
            </si-field>
          </template>
        </si-table>
        <si-footer>
          <pagination
            layout="prev, pager, next"
            :page-size="pagination.pageSize"
            :page-count="pagination.pageCount"
            :current-page.sync="pagination.currentPage"
          />
        </si-footer>
      </si>
    </div>
    <ctx-menu ref="ctx">
      <ctx-item
        :underline="true"
        @select="newTabCompany"
      >
        <template #title>
          <span class="fad fa-external-link-square ctx-icon" /> {{ t('open_new_tab') }}
        </template>
      </ctx-item>
    </ctx-menu>
  </panel-heading>
</template>

<script>
import CtxItem from '@/vue_components/common/ctx_menu/ctx_item'
import CtxMenu from '@/vue_components/common/ctx_menu/ctx_menu'
import Pagination from '@/vue_components/common/pagination.vue'
import PanelHeading from '@/vue_components/common/panel_heading.vue'
import Phone from '@/vue_components/common/phone.vue'
import SearchInput from '@/vue_components/common/search_input.vue'
import Si from '@/vue_components/sort_items/si.vue'
import SiControl from '@/vue_components/sort_items/si_control.vue'
import SiField from '@/vue_components/sort_items/si_table/si_field.vue'
import SiFooter from '@/vue_components/sort_items/si_footer.vue'
import SiTable from '@/vue_components/sort_items/si_table/si_table.vue'

import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { COMPANY_EDIT, COMPANY_NEW, COMPANY_SHOW } from '@/vue_components/router/modules/companies/route_names'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'

export default {
  name: 'CompaniesList',

  components: {
    CtxMenu,
    CtxItem,
    Pagination,
    PanelHeading,
    Phone,
    SearchInput,
    Si,
    SiControl,
    SiField,
    SiFooter,
    SiTable,
  },

  filters: {
    absent (value) {
      return value || '—'
    },
  },

  data: () => ({
    ctxSelectedCompanyId: null,
    fetched: {
      companies: {
        list: [],
        error: false,
        loading: false,
        searchQuery: '',
      },
    },

    pagination: {
      pageSize: PAGINATION_PAGE_SIZE,
      pageCount: 0,
      currentPage: 1,
    },

    COMPANY_EDIT,
    COMPANY_NEW,
  }),

  computed: {
    companiesSearchParams () {
      if (!this.allowSearch) {
        return {
          search_query: '',
          limit: this.pagination.pageSize,
          offset: this.pagination.currentPage,
        }
      }

      return {
        search_query: this.fetched.companies.searchQuery,
        limit: this.pagination.pageSize,
        offset: this.pagination.currentPage,
      }
    },

    allowSearch () {
      return this.fetched.companies.searchQuery.length >= 3
    },

    canManage () {
      return Services.security.canManageCompany
    },
  },

  watch: {
    companiesSearchParams () {
      this.findCompanies()
    },
  },

  mounted () {
    this.findCompanies()
  },

  methods: {
    findCompanies () {
      this.fetched.companies.loading = true
      this.fetched.companies.error = false

      companiesEndpoint.find(this.companiesSearchParams)
        .then((response) => {
          const { total, companies } = response
          this.fetched.companies.list = [...companies]
          this.pagination.pageCount = total
        })
        .catch(() => {
          this.fetched.companies.error = true
          Notificator.error(t('abstract_error_message'))
        })
        .finally(() => {
          this.fetched.companies.loading = false
        })
    },

    directToCompanyPage (companyId) {
      this.$router.push({ name: COMPANY_SHOW, params: { companyId } })
    },

    openCtxMenu (event, companyId) {
      this.ctxSelectedCompanyId = companyId
      this.$refs.ctx.open(event)
    },

    newTabCompany () {
      const route = this.$router.resolve({ name: COMPANY_SHOW, params: { companyId: this.ctxSelectedCompanyId } })
      window.open(route.href, '_target')
    },
  },
}
</script>
