<template>
  <div class="clinic-main-tab">
    <!-- Статус -->
    <m-switch
      :value="clinic.status"
      :active-text="t('enums.clinic.status.active')"
      class="mb-indent-validation"
      :custom-class="switchColor"
      active-value="active"
      :disabled="disabled"
      inactive-value="not_active"
      @change="clinic.setValue('status', $event)"
    />

    <!-- Наименование -->
    <m-input
      :value="clinic.title"
      :label="t('title')"
      required
      :disabled="disabled"
      validator-name="title"
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('title', $event)"
    />

    <!-- Юридические Наименование -->
    <m-input
      :value="clinic.legalName"
      :label="t('legal_name')"
      required
      :disabled="disabled"
      validator-name="legal_name"
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('legalName', $event)"
    />

    <!-- Директор -->
    <reusable-doctors-list
      :value="clinic.directorId"
      :label="t('director')"
      :placeholder="t('no_director')"
      :user-group="USER_GROUP_IDS.STAFF"
      :fetch-limit="1000"
      m-fixed-height
      full-width
      :disabled="disabled"
      option-label="fullName"
      use-custom-result="simple"
      @onDoctorChange="clinic.setValue('directorId', $event)"
    />

    <!-- ИНН -->
    <m-input
      :value="clinic.inn"
      :label="t('inn')"
      required
      :disabled="disabled"
      validator-name="inn"
      use-numbers-only
      @registerValidator="$registerValidator($event)"
      @input="clinic.setValue('inn', $event)"
    />

    <!-- ОГРН -->
    <m-input
      :label="t('ogrn')"
      :value="clinic.ogrn"
      :disabled="disabled"
      use-numbers-only
      @input="clinic.setValue('ogrn', $event)"
    />

    <!-- ОКПО -->
    <m-input
      :label="t('okpo')"
      :value="clinic.okpo"
      :disabled="disabled"
      use-numbers-only
      @input="clinic.setValue('okpo', $event)"
    />

    <!-- КПП -->
    <m-input
      :value="clinic.kpp"
      :label="t('kpp')"
      mask="## ## ## ###"
      placeholder="__ __ __ ___"
      :disabled="disabled"
      @input="clinic.setValue('kpp', $event)"
    />

    <!-- Часовой пояс -->
    <m-select
      :value="clinic.timeZone"
      :items="timeZones"
      :label="t('time_zone')"
      :custom-filter="defaultSearchFilter"
      full-width
      required
      validator-name="time_zone"
      use-custom-result="simple"
      filterable
      :disabled="disabled"
      @registerValidator="$registerValidator($event)"
      @change="clinic.setValue('timeZone', $event)"
    />

    <div class="flex gap-indent align-center">
      <m-icon
        v-tooltip="t('activerecord.explanation.text')"
        class="mb-indent-validation"
        icon="warning"
        color="warning"
        style="font-size: 20px"
      />

      <!-- Телефон 1 -->
      <m-phone-input
        class="flex-grow-1"
        :label="t('phone1')"
        :value="clinic.phone1"
        :required="mPhoneValidator"
        required-when-value
        validator-name="phone1"
        :disabled="disabled"
        disable-hidden-mask
        @input="clinic.setValue('phone1', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!-- Телефон 2 -->
      <m-phone-input
        class="flex-grow-1"
        :label="t('phone2')"
        :value="clinic.phone2"
        :required="mPhoneValidator"
        required-when-value
        validator-name="phone2"
        :disabled="disabled"
        disable-hidden-mask
        @input="clinic.setValue('phone2', $event)"
        @registerValidator="$registerValidator($event)"
      />
    </div>

    <!-- Факс -->
    <m-input
      :label="t('fax')"
      :value="clinic.fax"
      :disabled="disabled"
      @input="clinic.setValue('fax', $event)"
    />

    <!-- Сайт -->
    <m-input
      :label="t('web')"
      :value="clinic.web"
      :disabled="disabled"
      @input="clinic.setValue('web', $event)"
    />

    <!-- Email -->
    <m-input
      :label="t('email')"
      :value="clinic.email"
      :required="mEmailValidator"
      required-when-value
      validator-name="email"
      :disabled="disabled"
      @input="clinic.setValue('email', $event)"
      @registerValidator="$registerValidator($event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { defaultSearchFilter } from '@/helpers/lambda'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { mEmailValidator, mPhoneValidator } from '@/lib/validators/validators'

export default defineComponent({
  name: 'ClinicMainTab',
  components: {
    MIcon,
    MSwitch,
    ReusableDoctorsList,
    MSelect,
    MPhoneInput,
    MInput,
  },

  props: {
    clinic: { type: Clinic, required: true },
    timeZones: { type: Array, default: () => [] },
    isNewMode: Boolean,
    disabled: Boolean,
  },

  computed: {
    USER_GROUP_IDS () {
      return USER_GROUP_IDS
    },

    switchColor () {
      return this.isNewMode
        ? 'success'
        : 'warning'
    },
  },

  methods: {
    defaultSearchFilter,
    mEmailValidator,
    mPhoneValidator,
  },
})
</script>
