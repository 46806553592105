import { FNS_CODES_DICT } from '@/vue_present/Reuse/Lists/FNS/const'

export const getFNSDictEntries = () => {
  return Object.entries(FNS_CODES_DICT)
    .map(([id, title]) => ({
      id,
      title,
    }))
    .sort((a, b) => a.title > b.title ? 1 : -1)
}
