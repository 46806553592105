export const isRequired = (any, zero = false) => {
  if (Array.isArray(any) || typeof any === 'string') {
    return Boolean(any.length)
  }

  if (typeof any === 'object') {
    if (any instanceof Date) { return true }

    return Boolean(Object.keys(any || {}).length)
  }

  if (zero && any === 0) {
    return true
  }

  return Boolean(any)
}
