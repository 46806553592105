import {
  DEFAULT_VALIDATION_FORM_CASH_IN,
  DEFAULT_VALIDATION_FORM_CASH_OUT,
} from './consts.js'
import { STORAGE_KEY } from '../consts.js'
import { PAYMENT_ERRORS } from '@/lib/payments/consts.js'

export default {
  filterState: {
    deep: true,
    handler (newState) {
      const oldStore = window.store.get(STORAGE_KEY) || {}
      window.store.set(STORAGE_KEY, {
        ...oldStore,
        [this.currentStorage]: {
          ...oldStore[this.currentStorage],
          paymentsFilter: { ...newState },
        },
      })
    },
  },

  paymentsFilters () {
    this.fetched.payments.pagination.currentPage = 1
  },

  reqPaymentsParams () {
    this.fetchPayments()
  },

  reqRegistriesParams () {
    if (this.allowSearch) {
      this.findRegistries()
    }
  },

  'fetched.payments.pagination.currentPage' () {
    this.slicedPayments()
  },

  'fetched.payments.list' () {
    this.slicedPayments()
  },

  'modal.paymentCashIn.form': {
    deep: true,
    handler (newState) {
      const { paymentCashIn } = this.modal

      paymentCashIn.disabled = false
      paymentCashIn.validationMessages = {
        ...paymentCashIn.validationMessages,
        ...DEFAULT_VALIDATION_FORM_CASH_IN,
      }

      if (newState.byCash < 0) {
        paymentCashIn.disabled = true
        paymentCashIn.validationMessages = {
          ...paymentCashIn.validationMessages,
          byCash: [PAYMENT_ERRORS.MESSAGES.SUM_NEGATIVE],
        }
      }
      if (newState.byCard < 0) {
        paymentCashIn.disabled = true
        paymentCashIn.validationMessages = {
          ...paymentCashIn.validationMessages,
          byCard: [PAYMENT_ERRORS.MESSAGES.SUM_NEGATIVE],
        }
      }
      if (newState.byCashless < 0) {
        paymentCashIn.disabled = true
        paymentCashIn.validationMessages = {
          ...paymentCashIn.validationMessages,
          byCashless: [PAYMENT_ERRORS.MESSAGES.SUM_NEGATIVE],
        }
      }
    },
  },

  'modal.paymentCashOut.form': {
    deep: true,
    handler (newState) {
      const { paymentCashOut } = this.modal
      paymentCashOut.disabled = false
      paymentCashOut.validationMessages = {
        ...paymentCashOut.validationMessages,
        ...DEFAULT_VALIDATION_FORM_CASH_OUT,
      }

      if (newState.paymentSpec.length < 1) {
        paymentCashOut.validationMessages = {
          ...paymentCashOut.validationMessages,
          paymentSpec: [PAYMENT_ERRORS.MESSAGES.SPECIES_MUST_DEFINE],
        }
      }

      if (parseFloat(newState.summaryInputUser) < 0) {
        paymentCashOut.disabled = true
        paymentCashOut.validationMessages = {
          ...paymentCashOut.validationMessages,
          summaryInputUser: [PAYMENT_ERRORS.MESSAGES.SUM_NEGATIVE],
        }
      }
      if (parseFloat(newState.summaryInputUser) > this.accessBalance) {
        paymentCashOut.disabled = true
        paymentCashOut.validationMessages = {
          ...paymentCashOut.validationMessages,
          summaryInputUser: [PAYMENT_ERRORS.MESSAGES.SUM_LESS_BALANCE],
        }
      }
    },
  },

  'modal.massRefund.validationMessages.paymentsErrorValid' () {
    if (this.modal.massRefund.validationMessages.paymentsErrorValid.length > 0) {
      this.modal.massRefund.alert.visibility = true
    }
  },
}
