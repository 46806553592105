export const WHATSAPP_VARIABLE_CSS_CLASS = 'variable'

export const whatsappVariablesReplacementValue = (number) => {
  return `<span class="${WHATSAPP_VARIABLE_CSS_CLASS}">${t('variable')} ${number}</span>`
}

export const LOW_PRIORITY = [{
  id: 'sms',
  title: 'SMS',
}]

export const HIGH_PRIORITY = [{
  id: 'whatsapp',
  title: 'Whatsapp',
}]
