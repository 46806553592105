import {
  PersonalCheckupEntryModalItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupEntryModalItem'

export const personalCheckupEntryWithEntryTypesAdapter = {
  toClient (response) {
    response.data = response.data
      .map((item) => new PersonalCheckupEntryModalItem(item))

    return response
  },
}
