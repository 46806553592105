<template>
  <div class="company-final-act-view">
    <company-final-act-view-header :document="document" />

    <b class="text-center text-uppercase mb-indent-mid font-size-14">
      {{ t$('title') }}
    </b>

    <!-- Дата "от" -->
    <div class="flex justify-content-center gap-indent-small mb-indent-mid">
      <span>{{ t('from').toLowerCase() }}</span>

      <document-field-writable-date
        :date="document.titleDate"
        @input="document.setValue('titleDate', $event)"
      />
    </div>

    <p
      class="text-center font-size-14 mb-indent-mid"
      v-html="t$('documentTitle')"
    />

    <!-- Работодатель -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.companyTitle }}
      </span>

      <document-field-writable
        :value="document.companyValue"
        fill-available-width
        @input="document.setValue('companyValue', $event)"
      />
    </div>

    <!-- Структурное подразделение работодателя -->
    <div class="checkup-document-field">
      <span class="checkup-document-field__title">
        {{ document.departmentTitle }}
      </span>

      <document-field-writable
        :value="document.departmentValue"
        fill-available-width
        @input="document.setValue('departmentValue', $event)"
      />
    </div>

    <!-- Общая численность работников -->
    <company-final-act-worker-list
      :document="document"
      :title="t$('totalWorkersText')"
      title-value-prop="totalWorkersCount"
      :fields-values-props="[
        'totalFemaleWorkersCount',
        'totalUnderEighteenWorkersCount',
        'totalWorkersWithDisabilityCount'
      ]"
    />

    <!-- Численность работников, занятых на работах с вредными и (или) опасными условиями труда -->
    <div class="flex flex-column">
      <span>{{ t$('workersWithHarmfulWorkText') }}</span>

      <div class="flex justify-content-start gap-indent-small">
        <span>{{ t$('total') }}</span>

        <document-field-writable
          :value="document.workersWithHarmfulWorkCount"
          width="50px"
          text-center
          @input="document.setValue('workersWithHarmfulWorkCount', $event)"
        />
      </div>
    </div>

    <!-- Численность работников, занятых на работах, при выполнении которых обязательно проведение
      периодических медицинских осмотров -->
    <company-final-act-worker-list
      :document="document"
      :title="t$('workersWithRequiredCheckupsText')"
      title-value-prop="workersWithRequiredCheckupsCount"
      :fields-values-props="[
        'femaleWorkersWithRequiredCheckupsCount',
        'underEighteenWorkersWithRequiredCheckupsCount',
        'workersWithDisabilityWithRequiredCheckupsCount'
      ]"
    />

    <!-- Численность работников, подлежащих периодическому медицинскому осмотру -->
    <company-final-act-worker-list
      :document="document"
      :title="t$('totalCheckupWorkersText')"
      title-value-prop="totalCheckupWorkersCount"
      :fields-values-props="[
        'totalCheckupFemaleWorkersCount',
        'totalCheckupUnderEighteenWorkersCount',
        'totalCheckupWorkersWithDisabilityCount'
      ]"
    />

    <!-- Численность работников, прошедших периодический медицинский осмотр -->
    <company-final-act-worker-list
      :document="document"
      :title="t$('totalWorkersWithCompletedCheckupText')"
      title-value-prop="totalWorkersWithCompletedCheckupCount"
      :fields-values-props="[
        'totalFemaleWorkersWithCompletedCheckupCount',
        'totalUnderEighteenWorkersWithCompletedCheckupCount',
        'totalDisabledWorkersWithCompletedCheckupCount'
      ]"
    />

    <!-- Процент охвата работников периодическим медицинским осмотром -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('percentageCoverageText') }}</span>

      <document-field-writable
        :value="document.percentageCoverageValue"
        width="50px"
        text-center
        @input="document.setValue('percentageCoverageValue', $event)"
      />
    </div>

    <!-- Список лиц, прошедших периодический медицинский осмотр -->
    <div class="checkup-document-table-container">
      <span class="mb-indent-mid">{{ document.workersWithCompletedCheckupTableText }}</span>

      <document-table
        class="mb-indent-mid"
        :headers="document.workersWithCompletedCheckupTableHeader"
        :data="document.workersWithCompletedCheckupTableData"
        :center-columns="['sex', 'birthdate']"
        :multiline-columns="['fullName', 'result', 'department']"
        :fixed-width-columns="{ 'fullName': '200px' }"
        editable
        use-rows-manipulation
        @input="document.setValueInTable('workersWithCompletedCheckupTableData', $event)"
        @addRow="document.addRowToTable('workersWithCompletedCheckupTableData')"
        @removeRow="document.removeRowFromTable('workersWithCompletedCheckupTableData', $event)"
      />
    </div>

    <!-- Численность работников, не завершивших периодический медицинский осмотр -->
    <company-final-act-worker-list
      :document="document"
      :title="t$('workersWithUncompletedCheckupText')"
      title-value-prop="workersWithUncompletedCheckupValue"
      :fields-values-props="[
        'femaleWorkersWithUncompletedCheckupValue',
      ]"
    />

    <!-- Список работников, не завершивших периодический медицинский осмотр -->
    <span class="mb-indent-mid">{{ document.workersWithUncompletedCheckupTableText }}</span>

    <document-table
      :headers="document.workersWithUncompletedCheckupTableHeader"
      :data="document.workersWithUncompletedCheckupTableData"
      :center-columns="['sex', 'birthdate']"
      :multiline-columns="['fullName', 'department']"
      :fixed-width-columns="{ 'fullName': '250px' }"
      editable
      use-rows-manipulation
      @input="document.setValueInTable('workersWithUncompletedCheckupTableData', $event)"
      @addRow="document.addRowToTable('workersWithUncompletedCheckupTableData')"
      @removeRow="document.removeRowFromTable('workersWithUncompletedCheckupTableData', $event)"
    />

    <!-- Численность работников, не прошедших периодический медицинский осмотр -->
    <company-final-act-worker-list
      :document="document"
      :title="t$('workersWithoutCheckupText')"
      title-value-prop="workersWithoutCheckupValue"
      :fields-values-props="[
        'femaleWorkersWithoutCheckupValue',
      ]"
    />

    <!-- Список работников, не прошедших периодический медицинский осмотр -->
    <span class="mb-indent-mid">{{ document.workersWithoutCheckupTableText }}</span>

    <document-table
      :headers="document.workersWithoutCheckupTableHeader"
      :data="document.workersWithoutCheckupTableData"
      :center-columns="['sex', 'birthdate']"
      :multiline-columns="['fullName', 'department']"
      :fixed-width-columns="{ 'fullName': '250px' }"
      editable
      use-rows-manipulation
      @input="document.setValueInTable('workersWithoutCheckupTableData', $event)"
      @addRow="document.addRowToTable('workersWithoutCheckupTableData')"
      @removeRow="document.removeRowFromTable('workersWithoutCheckupTableData', $event)"
    />

    <!-- Численность работников, не имеющих медицинские противопоказания к работе -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.12') }}</span>

      <document-field-writable
        :value="document.workersWithoutContraindicationsCount"
        width="40px"
        text-center
        @input="document.setValue('workersWithoutContraindicationsCount', $event)"
      />
    </div>

    <!-- Численность работников, имеющих медицинские противопоказания к работе -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.13') }}</span>

      <document-field-writable
        :value="document.workersWithContraindicationsCount"
        width="40px"
        text-center
        @input="document.setValue('workersWithContraindicationsCount', $event)"
      />
    </div>

    <!-- Численность работников, нуждающихся в проведении дополнительного обследования -->
    <div class="checkup-document-inline-field_column">
      <span>{{ t$('workersCounts.14') }}</span>

      <document-field-writable
        :value="document.workersInNeedOfExtraMedicalExaminationCount"
        width="40px"
        text-center
        @input="document.setValue('workersInNeedOfExtraMedicalExaminationCount', $event)"
      />
    </div>

    <!-- Численность работников, нуждающихся в обследовании в центре профпатологии -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.15') }}</span>

      <document-field-writable
        :value="document.workersInNeedOfExaminationInOccupational"
        width="30px"
        text-center
        @input="document.setValue('workersInNeedOfExaminationInOccupational', $event)"
      />
    </div>

    <!-- Численность работников, нуждающихся в амбулаторном обследовании и лечении -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.16') }}</span>

      <document-field-writable
        :value="document.workersInNeedOfOutpatientExamination"
        width="30px"
        text-center
        @input="document.setValue('workersInNeedOfOutpatientExamination', $event)"
      />
    </div>

    <!-- Численность работников, нуждающихся в стационарном обследовании и лечении -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.17') }}</span>

      <document-field-writable
        :value="document.workersInNeedOfInpatientExamination"
        width="30px"
        text-center
        @input="document.setValue('workersInNeedOfInpatientExamination', $event)"
      />
    </div>

    <!-- Численность работников, нуждающихся в санаторно-курортном лечении -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.18') }}</span>

      <document-field-writable
        :value="document.workersInNeedOfSanatoriumExamination"
        width="40px"
        text-center
        @input="document.setValue('workersInNeedOfSanatoriumExamination', $event)"
      />
    </div>

    <!-- Численность работников, нуждающихся в диспансерном наблюдении -->
    <div class="checkup-document-inline-field">
      <span>{{ t$('workersCounts.19') }}</span>

      <document-field-writable
        :value="document.workersInNeedOfDispensary"
        width="40px"
        text-center
        @input="document.setValue('workersInNeedOfDispensary', $event)"
      />
    </div>

    <!-- Список лиц с установленным предварительным диагнозом профессионального заболевания -->
    <span class="mb-indent-mid">{{ document.workersWithDiagnosesText }}</span>

    <document-table
      :headers="document.workersWithDiagnosesTableHeader"
      :data="document.workersWithDiagnosesTableData"
      :center-columns="['sex', 'birthdate']"
      :multiline-columns="['fullName', 'department', 'position', 'contraindicationFactors']"
      editable
      use-rows-manipulation
      @input="document.setValueInTable('workersWithDiagnosesTableData', $event)"
      @addRow="document.addRowToTable('workersWithDiagnosesTableData')"
      @removeRow="document.removeRowFromTable('workersWithDiagnosesTableData', $event)"
    />

    <!-- Перечень впервые установленных хронических соматических заболеваний -->
    <span class="mb-indent-mid">{{ document.chronicDiseasesListText }}</span>

    <document-table
      :headers="diseasesListTableHeaders"
      :data="chronicDiseasesListTableData"
      :center-columns="['codeString']"
      :multiline-columns="['title']"
      :fixed-width-columns="{ 'codeString': '100px' }"
      editable
      use-rows-manipulation
      @input="document.setValueInTable('chronicDiseasesListTableData', $event)"
      @addRow="document.addRowToTable('chronicDiseasesListTableData')"
      @removeRow="document.removeRowFromTable('chronicDiseasesListTableData', $event)"
    />

    <!-- Перечень впервые установленных профессиональных заболеваний -->
    <span class="mb-indent-mid">{{ document.professionalDiseasesListText }}</span>

    <document-table
      :headers="diseasesListTableHeaders"
      :data="professionalDiseasesListTableData"
      :center-columns="['codeString']"
      :multiline-columns="['title']"
      :fixed-width-columns="{ 'codeString': '100px' }"
      editable
      use-rows-manipulation
      @input="document.setValueInTable('professionalDiseasesListTableData', $event)"
      @addRow="document.addRowToTable('professionalDiseasesListTableData')"
      @removeRow="document.removeRowFromTable('professionalDiseasesListTableData', $event)"
    />

    <!-- Перечень впервые установленных инфекционных заболеваний (отравлений), связанных с условиями труда -->
    <span class="mb-indent-mid">{{ document.infectiousDiseasesListText }}</span>

    <document-table
      :headers="diseasesListTableHeaders"
      :data="infectiousDiseasesListTableData"
      :center-columns="['codeString']"
      :multiline-columns="['title']"
      :fixed-width-columns="{ 'codeString': '100px' }"
      editable
      use-rows-manipulation
      @input="document.setValueInTable('infectiousDiseasesListTableData', $event)"
      @addRow="document.addRowToTable('infectiousDiseasesListTableData')"
      @removeRow="document.removeRowFromTable('infectiousDiseasesListTableData', $event)"
    />

    <!-- Результаты выполнения рекомендаций предыдущего заключительного акта -->
    <div class="checkup-document-field_column">
      <span class="checkup-document-field__title">
        {{ document.lastActResultText }}
      </span>

      <document-field-writable
        :value="document.lastActResultValue"
        fill-available-width
        multiline
        @input="document.setValue('lastActResultValue', $event)"
      />
    </div>

    <!-- Рекомендации работодателю по реализации комплекса оздоровительных мероприятий -->
    <div class="checkup-document-field_column mb-indent-validation-important">
      <span class="checkup-document-field__title">
        {{ document.companyRecommendationsText }}
      </span>

      <document-field-writable
        :value="document.companyRecommendationsValue"
        fill-available-width
        multiline
        @input="document.setValue('companyRecommendationsValue', $event)"
      />
    </div>

    <company-final-act-view-footer :document="document" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { CompanyFinalActApi } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActApi'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import DocumentFieldWritableDate
  from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritableDate/DocumentFieldWritableDate.vue'
import CompanyFinalActWorkerList
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/CompanyFinalAct/CompanyFinalActWorkerList.vue'
import DocumentTable from '@/vue_present/DocumentBase/DocumentTable/DocumentTable.vue'
import { ICompanyFinalActDiseases } from '@/vue_apps/CheckupsModule/interfaces/CompanyFinalAct/ICompanyFinalActDiseasis'
import CompanyFinalActViewHeader
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/CompanyFinalAct/CompanyFinalActViewHeader.vue'
import {
  COMPANY_FINAL_ACT_VIEW_DISEASES_LIST_TABLE_HEADERS,
} from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/CompanyFinalAct/store/companyFinalActViewConst'
import CompanyFinalActViewFooter
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/CompanyFinalAct/CompanyFinalActViewFooter.vue'

export default defineComponent({
  name: 'CompanyFinalActView',

  components: {
    CompanyFinalActViewFooter,
    CompanyFinalActViewHeader,
    DocumentTable,
    CompanyFinalActWorkerList,
    DocumentFieldWritableDate,
    DocumentFieldWritable,
  },

  mixins: [i18nScopeMixin],

  props: {
    document: CompanyFinalActApi,
  },

  data () {
    return {
      diseasesListTableHeaders: COMPANY_FINAL_ACT_VIEW_DISEASES_LIST_TABLE_HEADERS,
    }
  },

  computed: {
    chronicDiseasesListTableData () {
      return this.extractDiseasesListTableData(this.document.chronicDiseasesListTableData)
    },

    professionalDiseasesListTableData () {
      return this.extractDiseasesListTableData(this.document.professionalDiseasesListTableData)
    },

    infectiousDiseasesListTableData () {
      return this.extractDiseasesListTableData(this.document.infectiousDiseasesListTableData)
    },
  },

  created () {
    this.setI18nScope('checkups.documents.companyFinalAct')
  },

  methods: {
    extractDiseasesListTableData (data: ICompanyFinalActDiseases[]) {
      return data.map((item) => ({
        codeString: item.codeString,
        title: item.title,
      }))
    },
  },
})
</script>
