import { computed as baseComputed } from '@/vue_present/_base/buttons/MButton/computed'

const {
  isButtonWithIconCssRequired,
  buttonText,
} = baseComputed

export const computed = {
  isButtonWithIconCssRequired,
  buttonText,
}
