export const DEFAULT_FILTER_STATE = Object.freeze({
  selectedDateRange: [new Date(), new Date()],
  selectedPaymentKind: 'all',
  selectedRegistryId: -1,
})
export const DEFAULT_VALIDATION_FORM_CASH_IN = Object.freeze({
  byCash: [],
  byCard: [],
  byCashless: [],
})

export const DEFAULT_VALIDATION_FORM_CASH_OUT = Object.freeze({
  paymentSpec: [],
  summaryInputUser: [],
})

export const KIND_ICONS = Object.freeze({
  ORDER_INITIAL: 'fad fa-money-bill-alt orange',
  ORDER_REFUND: 'fad fa-reply red-light',
  ORDER_SUBSEQUENT: 'fad fa-cart-plus purple',
  CASH_IN: 'fad fa-user-plus',
  CASH_IN_REFUND: 'fad fa-reply red-light',
  CASH_OUT: 'fad fa-user-minus',
  ORDER_FULL: 'fad fa-shopping-cart green-light',
  ORDER_IN_CREDIT: 'fad fa-money-bill brown',
})
