import { IAddress } from '@/_declarations/IAddress'
import { ItemBase } from '@/_api/_classes/ItemBase'

export class MAddressModel extends ItemBase implements IAddress {
  _permit = [
    'additional',
    'addressType',
    'area',
    'building',
    'city',
    'country',
    'flat',
    'house',
    'housing',
    'id',
    'index',
    'nsiRussianSubjectId',
    'region',
    'street',
  ]

  additional: string

  addressType: number

  area: string

  building: string

  city: string

  country: string

  flat: string

  house: string

  housing: string

  id?: number

  index: string

  nsiRussianSubjectId: number

  region: string

  street: string

  constructor (data?: Record<string, unknown> | IAddress) {
    super()
    this._fillProps(data)
  }
}
