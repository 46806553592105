<template>
  <modal
    :modal-visibility="visibility"
    @close="$emit('close-modal-masspay')"
  >
    <template v-slot:header>
      <span class="fad fa-money-check-edit" />
      {{ t('payments_order_pay') }}
    </template>

    <template v-slot:body>
      <payments-masspay-form
        :loading="loading"
        :customer="customer"
        :sum-to-pay="sumToPay"
        :payment-spec="paymentSpec"
        :balance="balance"
        :summary-input-user="summaryInputUser"
        :credit="credit"
        :surrender="surrender"
        :deposit="deposit"
        :comment="comment"
        :validation-messages="validationMessages"
        :handle-reply-sum="handleReplySum"
        @update:paymentSpec="$emit('update:paymentSpec', $event)"
        @update:summaryInputUser="$emit('update:summaryInputUser', parseFloat($event))"
        @update:comment="$emit('update:comment', $event)"
      />
    </template>

    <template v-slot:footer-right>
      <button
        :disabled="loading || disabled"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click="$emit('submit-form')"
      >
        <div class="btn-with-icon_icon fad fa-money-check-edit" />
        <div class="btn-with-icon_text">
          {{ t('pay') }}
        </div>
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="$emit('close-modal-masspay')"
      >
        <div class="btn-with-icon_icon fad fa-times" />
        <div class="btn-with-icon_text">
          {{ t('close') }}
        </div>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'
import PaymentsMasspayForm from '@/vue_components/common/payments/payments_masspay_form.vue'

export default {
  name: 'CompanyOrdersRegistryMasspayModal',

  components: {
    Modal,
    PaymentsMasspayForm,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: String,
      default: '',
    },
    paymentSpec: {
      type: Array,
      required: true,
    },
    sumToPay: {
      type: Number,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    summaryInputUser: {
      type: Number,
      required: true,
    },
    credit: {
      type: Number,
      required: true,
    },
    surrender: {
      type: Number,
      required: true,
    },
    deposit: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
    validationMessages: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleReplySum (field, replySum) {
      if (field === 'deposit') {
        this.$emit('update:surrender', 0)
      }
      if (field === 'surrender') {
        this.$emit('update:deposit', 0)
      }
      this.$emit(`update:${field}`, replySum)
    },
  },
}
</script>
