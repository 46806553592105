import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { tScoped } from '@/helpers/i18nHelpers'
import {
  getPersonalCheckupAddButtonConfigCanManage,
} from '@/vue_apps/CheckupsModule/store/getPersonalCheckupAddButtonConfigCanManage'

const ts = tScoped('checkups.doctorResults')

export const PERSONAL_CHECKUP_DOCTOR_RESULTS_HAS_MEDICAL_CONTRAINDICATIONS_NSI_ID = 73

export const PERSONAL_CHECKUP_DOCTOR_RESULTS_NO_MEDICAL_CONTRAINDICATIONS_NSI_ID = 74

export const getPersonalCheckupDoctorResultsMsiSchema = ({
  addDeleteIcon = Services.security.canManageCheckupsCompany,
  addCreateButton = false,
} = {}) => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        specialtyTitle: new MTableHeader().addFixedWidth('150px'),
        userShortName: new MTableHeader().addFixedWidth('100px'),
        date: new MTableHeader().addFixedWidth('100px'),
        medicalAssessmentTitle: new MTableHeader(),
      })
      .addDeleteIcon(addDeleteIcon)
  )
  .addCreateButton(addCreateButton)

export const getPersonalCheckupDoctorResultsAddButtonConfig = (hasSpecialFactors: boolean) => ({
  color: 'primary',
  text: ts('title'),
  tooltip: t('checkups.mandatoryForReceivingMedicalBook'),
  canManage: getPersonalCheckupAddButtonConfigCanManage(hasSpecialFactors),
})
