<template>
  <modal
    :modal-visibility="visibility"
    @close="$emit('close-modal-in')"
  >
    <template v-slot:header>
      <span class="fad fa-user-plus" />
      {{ t('_payment_kind.cash_in') }}
    </template>

    <template v-slot:body>
      <epic-spinner
        :visibility="loading"
      />
      <payments-cash-in-form
        :loading="loading"
        :customer="customer"
        :by-cash="byCash"
        :by-card="byCard"
        :by-cashless="byCashless"
        :comment="comment"
        :total-money="totalMoney"
        :validation-messages="validationMessages"
        @update:byCash="$emit('update:byCash', parseFloat($event))"
        @update:byCard="$emit('update:byCard', parseFloat($event))"
        @update:byCashless="$emit('update:byCashless', parseFloat($event))"
        @update:comment="$emit('update:comment', $event)"
      />
    </template>

    <template v-slot:footer-right>
      <button
        :disabled="loading || disabled"
        class="btn btn-success btn-with-icon modal-save"
        type="button"
        @click="$emit('submit-form')"
      >
        <div class="btn-with-icon_icon fad fa-money-bill" />
        <div class="btn-with-icon_text">
          {{ t('cash_in') }}
        </div>
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary btn-with-icon modal-close"
        type="button"
        @click="$emit('close-modal-in')"
      >
        <div class="btn-with-icon_icon fad fa-times" />
        <div class="btn-with-icon_text">
          {{ t('close') }}
        </div>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import PaymentsCashInForm from '@/vue_components/common/payments/payments_cash_in_form.vue'

export default {
  name: 'CompanyPaymentsCashInModal',
  components: {
    Modal,
    EpicSpinner,
    PaymentsCashInForm,
  },

  props: {
    visibility: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: String,
      default: '',
    },
    byCash: {
      type: Number,
      required: true,
    },
    byCard: {
      type: Number,
      required: true,
    },
    byCashless: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
    totalMoney: {
      type: Number,
      required: true,
    },
    validationMessages: {
      type: Object,
      required: true,
    },
  },
}
</script>
