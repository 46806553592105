export const ORDERS_STATUSES = Object.freeze({
  FULLY_PAID: 'fully_paid',
  PAID_BY_CREDIT: 'paid_by_credit',
  NOT_PAID: 'not_paid',
  FREE: 'free',
})

export const MODAL_MODES = Object.freeze({
  CREATE: 'create',
  EDIT: 'edit',
})

export const ordersStatusesArray = Object.values(ORDERS_STATUSES)
  .map((key) => ({ id: key, title: t(key, { scope: 'finance.ordersStatuses' }) }))

export const ORDERS_STATUSES_ICONS = Object.freeze({
  [ORDERS_STATUSES.FULLY_PAID]: { icon: 'ready', color: 'success' },
  [ORDERS_STATUSES.PAID_BY_CREDIT]: { icon: 'orderInCredit', color: 'warning' },
  [ORDERS_STATUSES.NOT_PAID]: { icon: 'canceledCheckupStatus', color: 'danger' },
  [ORDERS_STATUSES.FREE]: { icon: 'gift', color: 'primary' },
})

export const PAYMENT_SPECIES = Object.freeze({
  BY_CASH: 'by_cash',
  BY_CARD: 'by_card',
  BY_CASHLESS: 'by_cashless',
  BY_BALANCE: 'by_balance',
  BY_CREDIT: 'by_credit',
})

export const paymentSpeciesArray = Object.values(PAYMENT_SPECIES)
  .map((key) => ({ id: key, title: t(key, { scope: 'finance.paymentSpecies' }) }))

const CUSTOMER_TYPES_VALUES = {
  CLIENT: 'client',
  COMPANY: 'company',
}

export const CUSTOMER_TYPES = [
  {
    id: CUSTOMER_TYPES_VALUES.CLIENT,
    title: t('finance.customerTypes.client'),
  },
  {
    id: CUSTOMER_TYPES_VALUES.COMPANY,
    title: t('finance.customerTypes.company'),
  },
]
