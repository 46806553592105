<template>
  <div class="flex flex-column">
    <!-- Фамилия -->
    <m-input
      :label="t('surname')"
      class="mt-6"
      :value="headSurname"
      @input="$updateSync('headSurname', $event)"
    />

    <!-- Имя -->
    <m-input
      :label="t('name')"
      :value="headName"
      @input="$updateSync('headName', $event)"
    />

    <!-- Отчество -->
    <m-input
      :label="t('second_name')"
      :value="headSecondName"
      @input="$updateSync('headSecondName', $event)"
    />

    <!-- Должность -->
    <m-input
      :label="t('position')"
      :value="headPosition"
      @input="$updateSync('headPosition', $event)"
    />

    <!-- Действует на основании -->
    <m-input
      :label="t('head_operates_by')"
      class="pb-0"
      :value="operatesBy"
      @input="$updateSync('operatesBy', $event)"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'CompanyHead',
  components: { MInput },
  props: {
    headSurname: PropsTypes.String(),
    headName: PropsTypes.String(),
    headSecondName: PropsTypes.String(),
    headPosition: PropsTypes.String(),
    operatesBy: PropsTypes.String(),
  },
}
</script>
