import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { getTemplateBySemdErrors } from '@/vue_apps/Semds/SemdModal/const/getTemplateBySemdErrors'

export const SemdErrorsDialogMixin = {
  methods: {
    /**
     * @param errors
     * @return {Promise<*>}
     */
    showErrorsDialog (errors) {
      return MConfirm.error(getTemplateBySemdErrors(errors), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: 'OK',
        showCancelButton: false,
        customClass: 'w-700px',
      })
    },
  },
}
