import { FINANCE_ROUTE_NAMES } from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/routeNames'
import Orders from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/OrdersPage.vue'
import Payments from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/PaymentsPage.vue'
import Expenses from '@/vue_apps/FinanceModule/FinanceModuleIndex/pages/ExpensesPage.vue'

const getDefaultRoute = (path = '*') => `/finance${path}`

export const ordersRoute = {
  path: getDefaultRoute('/orders'),
  name: FINANCE_ROUTE_NAMES.ORDERS,
  component: Orders,
  meta: {
    label: t('finance.orders'),
    icon: 'shopping-cart',
    tooltip: t('finance.orders'),
  },
}

export const paymentsRoute = {
  path: getDefaultRoute('/payments'),
  name: FINANCE_ROUTE_NAMES.PAYMENTS,
  component: Payments,
  meta: {
    label: t('finance.payments'),
    icon: 'money-bill-wave-alt',
    tooltip: t('finance.payments'),
  },
}

export const expensesRoute = {
  path: getDefaultRoute('/expenses'),
  name: FINANCE_ROUTE_NAMES.EXPENSES,
  component: Expenses,
  meta: {
    label: t('finance.expenses'),
    icon: 'dollar-sign',
    tooltip: t('finance.expenses'),
  },
}

export const financeRoutes = [
  ordersRoute,
  paymentsRoute,
  expensesRoute,

  {
    path: getDefaultRoute('*'),
    name: FINANCE_ROUTE_NAMES.INDEX,
    redirect: { name: FINANCE_ROUTE_NAMES.ORDERS },
  },
]
