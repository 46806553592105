import { getQuestionMarkTooltip } from '@/vue_apps/CheckupsModule/store/getQuestionMarkTooltip'

export const personalCheckupQuestionMarkMixin = {
  computed: {
    questionMarkTooltip (): string | null {
      if (!this.hasSpecialFactors) { return null }

      const items = this.availableSpecialFactors
        .map((nsiCode: number) => this.t$(`questionTooltipNSICode${nsiCode}`))

      return getQuestionMarkTooltip(
        items,
        'personal-checkup-nested-item-modal__question-mark-tooltip'
      )
    },
  },
}
