<template>
  <span
    v-if="rawPhone.length"
    :class="['vue-phone','phone_number', { callable: popover }]"
    @click="clickHandler"
  >
    {{ phone }}
  </span>
  <span
    v-else
    class="vue-phone surrogate"
  >
    {{ surrogate }}
  </span>
</template>

<script>
export default {
  name: 'Phone',

  props: {
    rawPhone: {
      type: String,
      required: true,
    },

    clientId: {
      type: Number,
      default: null,
    },

    securityMask: {
      type: Boolean,
      default: true,
    },

    popover: {
      type: Boolean,
      default: true,
    },

    surrogate: {
      type: String,
      default: t('absence'),
    },
  },

  computed: {
    phone () {
      return `+${Services.phoneMask.setMask(this.rawPhone, null, null, this.securityMask)}`
    },
  },

  methods: {
    clickHandler (event) {
      if (!this.popover) return

      this.$pubSub.emitAsync('PHONE_POPOVER.SHOW', {
        phoneNumber: this.rawPhone,
        clientId: this.clientId,
        element: event.target,
      })
    },
  },
}
</script>
