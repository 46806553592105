<template>
  <panel-heading
    :title="t('egisz.steps.modal.services.title')"
    icon="fad fa-list"
    class="step-services"
  >
    <si v-loading="loading">
      <si-control>
        <template #reset>
          <span class="empty-slot" />
        </template>

        <template #refresh>
          <span class="empty-slot" />
        </template>

        <entry-types-selector-search
          v-if="!readOnly"
          class="flex-grow-1 mr-5"
          @onSelect="$emit('ask-add', $event.id)"
        />

        <entries-history
          v-if="!readOnly"
          :client-id="client.id"
          :ignored-ids="entriesListIds"
          @onSelect="$emit('addExistingEntry', $event)"
        />
      </si-control>

      <si-table
        class="margin-top-10 step-services__table"
      >
        <template #header>
          <si-field>
            <div class="col__date">
              {{ t('egisz.steps.modal.services.date') }}
            </div>

            <div class="col__title">
              {{ t('egisz.steps.modal.services.name') }}
            </div>

            <div class="col__executor">
              {{ t('egisz.steps.modal.services.executor') }}
            </div>

            <div class="col__price">
              {{ t('egisz.steps.modal.services.price') }}
            </div>

            <div class="col__options" />
          </si-field>
        </template>
        <template #body>
          <si-field
            v-for="(entry, index) in entriesList"
            :key="`entry:${entry.id}_${index}`"
          >
            <div class="col__date">
              {{ entry.created_at }}
            </div>

            <div class="col__title">
              {{ entry.title }}
            </div>

            <div class="col__executor">
              {{ getDoctorShortName(entry.user_id) }}
            </div>

            <div class="col__price">
              {{ currencyFormat(entry.final_sum) }}
              <span v-if="entry.amount">{{ `(${entry.amount})` }}</span>
            </div>

            <div class="col__options">
              <a
                :href="$routes.entry_path(entry.id)"
                class="fad fa-fw fa-pencil"
                target="_blank"
              />

              <popover
                v-if="!readOnly"
                :ref="`popover${entry.id}_${index}`"
                popper-class="col__options-popover"
                placement="top-end"
              >
                <template #message>
                  <div class="flex options-popover__message">
                    <i class="fad fa-fw fa-question-circle red message__icon" />
                    <div>
                      <div>{{ t('egisz.components.entries_history.remove_text1') }}</div>
                      <div>{{ t('egisz.components.entries_history.remove_text2') }}</div>
                    </div>
                  </div>
                </template>

                <template #buttons>
                  <button
                    class="btn btn-sm btn-link margined-top"
                    @click="closePopover(`popover${entry.id}_${index}`)"
                  >
                    {{ t('cancel') }}
                  </button>

                  <button
                    class="btn btn-sm btn-link red margined-top"
                    @click="$emit('ask-delete', entry.id, true)"
                  >
                    {{ t('egisz.components.entries_history.remove_hard') }}
                  </button>

                  <button
                    class="btn btn-sm btn-link red margined-top"
                    @click="$emit('ask-delete', entry.id, false)"
                  >
                    {{ t('egisz.components.entries_history.remove_from_list') }}
                  </button>
                </template>

                <i
                  slot="reference"
                  class="fad fa-fw fa-trash red"
                />
              </popover>
            </div>
          </si-field>
        </template>

        <template #footer>
          <div class="step-services__total text-right bold">
            {{ t('egisz.steps.modal.services.total_template', [entriesList.length, currencyFormat(total)]) }}
          </div>
        </template>
      </si-table>
    </si>

    <el-input
      :value="comment"
      :placeholder="t('comment')"
      :rows="3"
      type="textarea"
      @input="$updateSync('comment', $event)"
    />
  </panel-heading>
</template>

<script>
import PanelHeading from '@/vue_components/common/panel_heading'
import Si from '@/vue_components/sort_items/si'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import SiControl from '@/vue_components/sort_items/si_control'
import EntryTypesSelectorSearch from '@/vue_components/common/EntryTypesSelectorSearch/EntryTypesSelectorSearch'
import CurrencyFormatter from '@/lib/currency_formatter/currency_formatter'
import { mapGetters } from 'vuex'
import EntriesHistory
  from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase/egisz_case_cards/CardSteps/StepForm/StepEntries/EntriesHistory'
import Popover from '@/vue_components/common/popover/popover'

export default {
  name: 'StepEntries',
  components: {
    Popover,
    EntriesHistory,
    EntryTypesSelectorSearch,
    SiControl,
    SiField,
    SiTable,
    Si,
    PanelHeading,
  },

  props: {
    entriesList: {
      type: Array,
      default: () => [],
    },

    comment: {
      type: String,
      default: null,
    },

    loading: Boolean,

    client: {
      type: Object,
      default: null,
    },

    readOnly: Boolean,
  },

  computed: {
    ...mapGetters([
      'GET_LOCALIZATION_CURRENCY_PARAMS',
    ]),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorShortName: 'getDoctorShortName',
    }),

    total () {
      return this.entriesList
        .reduce((sum, entry) => sum + parseFloat(entry.final_sum) || 0, 0)
    },

    entriesListIds () {
      return this.entriesList.map((entry) => entry.id)
    },
  },

  methods: {
    currencyFormat (value = 0) {
      const { code, locale } = this.GET_LOCALIZATION_CURRENCY_PARAMS

      return new CurrencyFormatter(code, locale).format(value)
    },

    closePopover (ref) {
      this.$refs[ref][0].closePopover()
    },
  },
}
</script>
