<template>
  <m-panel
    v-loading="loading"
    :class="[documentParams.cssClass, {
      'checkups-module__content_is-semd': isSemd
    }]"
    :title="documentParams.title"
    use-print-button
  >
    <div class="flex flex-grow-1 w-100 gap-indent justify-content-center">
      <component
        :is="documentParams.component"
        v-if="document"
        class="checkup-document"
        :document="document"
        :is-edit-mode="isEditMode"
        :is-show-mode="isShowMode"
      >
        <template #actuality>
          <m-prompt-notice
            v-if="documentNotActual"
            :text="t('checkups.actualityMessage')"
            type="alert"
          />
        </template>
      </component>

      <template v-if="document && isSemd">
        <div class="checkup-document-semd-stub hidden-print" />

        <semd-modal-wrapper
          v-show="isShowMode"
          class="hidden-print"
          :source="document"
          :is-source-changed="isDocumentChanged"
          :before-semd-submit="__getBeforeSemdSubmit()"
          append-to-body
          @registerApi="semdModalApi = $event"
        >
          <template #source-connector="{ semds }">
            <semds-source-connector
              :source="document"
              :semds="semds"
              @registerApi="connectorApi = $event"
            />
          </template>
        </semd-modal-wrapper>
      </template>
    </div>

    <template #footer>
      <div class="flex gap-indent-small w-100 align-items-center">
        <checkup-document-action-button
          v-if="canManageCheckups"
          :is-show-mode="isShowMode"
          :not-actual="documentNotActual"
          @onSubmit="onSubmit"
          @onEdit="onEdit"
          @onActualize="onActualize"
        />

        <m-button-delete
          v-if="canManageCheckups"
          use-button
          @yes="onDestroy"
        />

        <m-button
          v-if="isShowMode && isSemd && document && document.personalCheckupId"
          v-tooltip="t('open_new_tab')"
          type="primary"
          icon="checkups"
          :text="t('checkups.checkup')"
          @click="onOpenCheckup"
        />

        <m-button
          v-if="!isShowMode && isSemd"
          :text="t('cancel')"
          type="primary"
          class="ml-auto"
          @click="onBack"
        />
      </div>
    </template>
  </m-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import PersonalSimpleMedicalAssessmentView
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/PersonalSimpleMedicalAssessments/PersonalSimpleMedicalAssessmentView.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { DisablePrintClinicHeaderMixin } from '@/vue_present/mixins/DisablePrintClinicHeaderMixin'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import PersonalElmkMedicalAssessmentView
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/PersonalElmkMedicalAssessments/PersonalElmkMedicalAssessmentView.vue'
import CompanyFinalActView
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/Views/CompanyFinalAct/CompanyFinalActView.vue'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import SemdModalApp from '@/vue_apps/Semds/SemdModal/SemdModalApp.vue'
import { getCheckupDocument } from '@/vue_apps/CheckupsModule/components/CheckupDocument/const/getCheckupDocument'
import MButtonEdit from '@/vue_present/_base/buttons/MButtonEdit.vue'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { documentTypeConfig } from '@/vue_apps/CheckupsModule/components/CheckupDocument/const/documentTypeConfig'
import MDropDown from '@/vue_present/_base/MDropDown/MDropDown.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MDivider from '@/vue_present/_base/MDivider/MDivider.vue'
import SemdModalWrapper, { ISemdModalWrapperApi } from '@/vue_apps/Semds/SemdModal/SemdModalWrapper.vue'
import SemdsSourceConnector, {
  ISemdsSourceConnectorAPI,
} from '@/vue_apps/Semds/SemdSourceConnector/SemdsSourceConnector.vue'
import { MConfirm, MConfirmRenderList } from '@/vue_present/_base/MConfirm/MConfirm'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import CheckupDocumentActionButton
  from '@/vue_apps/CheckupsModule/components/CheckupDocument/CheckupDocumentActionButton.vue'
import { ISemdSource } from '@/vue_apps/Semds/interfaces/ISemdSource'
import MAlert from '@/vue_present/_base/MAlert/MAlert.vue'
import {
  PersonalElmkMedicalAssessmentApi,
} from '@/vue_apps/CheckupsModule/classes/PersonalElmkMedicalAssessment/PersonalElmkMedicalAssessmentApi'
import { ENTITIES } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import {
  convertDocumentClassToDocumentType,
} from '@/vue_apps/CheckupsModule/components/CheckupDocument/const/convertDocumentClassToDocumentType'
import MLink from '@/vue_present/_base/MLink/MLink.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'CheckupDocument',

  components: {
    MIcon,
    MLink,
    MAlert,
    CheckupDocumentActionButton,
    MPromptNotice,
    SemdsSourceConnector,
    SemdModalWrapper,
    MDivider,
    MSiGenerator,
    MDropDown,
    MButton,
    MButtonEdit,
    SemdModalApp,
    MButtonDelete,
    MButtonSubmit,
    PersonalSimpleMedicalAssessmentView,
    PersonalElmkMedicalAssessmentView,
    CompanyFinalActView,
    MPanel,
  },

  mixins: [
    SpinnerHolder,
    DisablePrintClinicHeaderMixin,
    i18nScopeMixin,
  ],

  props: {
    id: { type: Number, default: null },
    documentType: { type: String, required: true },
    isEditMode: Boolean,
    isShowMode: Boolean,
  },

  emits: ['destroy'],

  data () {
    return {
      document: null as ISemdSource,
      isDocumentChanged: false,

      connectorApi: {} as ISemdsSourceConnectorAPI,
      semdModalApi: {} as ISemdModalWrapperApi,

      CHECKUPS_ROUTE_NAMES,
    }
  },

  computed: {
    canManageCheckups () {
      return this.$security.canManageCheckupsCompany
    },

    documentParams () {
      return {
        component: documentTypeConfig[this.documentType]?.component || '',
        cssClass: documentTypeConfig[this.documentType]?.css || '',
        title: documentTypeConfig[this.documentType]?.title || '',
      }
    },

    isSemd () {
      return this.$m.modules.egisz &&
          this.documentType === DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT
    },

    documentNotActual () {
      return this.document?.refresh && this.document?.actual === false
    },
  },

  created () {
    this.setI18nScope('checkups.documents')
    this.loadDocument()

    window.Services.wsSubscriptions.egisz.connect(({ action, data, meta }) => {
      if (!data?.id) { return }

      if (action === ENTITIES.IS_NOT_ACTUAL) {
        if (convertDocumentClassToDocumentType(meta?.object) !== this.documentType) { return }
        if (this.id !== data.id) { return }
        this.document.setValue('actual', false)
        this.semdModalApi.updateList()
      }
    })
  },

  methods: {
    setChanged (value = true) {
      this.isDocumentChanged = value
    },

    async onDestroy () {
      const result = await this.document.destroy()
      if (result?.errors) { return this.errorHandler(result.errors) }

      this.$emit('destroy')
    },

    errorHandler (errors: string | string[]) {
      if (errors === 'exists_error') {
        window.Notificator.error(window.t('semds.errors.semdsExists'))
      }

      if (errors === 'successfully_sent_semd_exists') {
        window.Notificator.error(window.t('semds.errors.successfullySentSemdsExists'))
      }
    },

    async loadDocument () {
      this.setChanged(false)
      const props = { id: this.id }

      const document = getCheckupDocument(this.documentType, props)

      if (!document) { return }

      await this.withSpinner(document.load())

      this.document = document
    },

    async checkCanEdit () {
      if (!this.isSemd) { return { cancel: false } }

      return await this.connectorApi.beforeEdit()
    },

    async onActualize () {
      if (!this.document.refresh || !this.document.fillProps) { return }
      const { cancel } = await this.checkCanEdit()
      if (cancel) { return }

      const refreshedDocument = await this.document.refresh()
      if (refreshedDocument?.errors) { return this.errorHandler(refreshedDocument.errors) }
      this.document.fillProps(refreshedDocument)

      return this.onEdit({ skipCheck: true })
    },

    __getBeforeSemdSubmit () {
      const vm = this

      return async (): Promise<{ cancel: boolean }> => {
        const { errors } = await (vm.document as PersonalElmkMedicalAssessmentApi).validateSemd() || {}
        if (!errors) { return { cancel: false } }

        const mappedErrors = Object.keys(errors)
          .map((k) => window.t(k, { scope: 'semds.validationErrors' }))

        const message =
            window.t('checkups.documents.personalElmkMedicalAssessment.hasValidationErrors') +
            MConfirmRenderList(mappedErrors)

        const { cancel } = await MConfirm.warning(message, {
          dangerouslyUseHTMLString: true,
        })

        return { cancel }
      }
    },

    async onEdit ({ skipCheck = false } = {}) {
      if (!skipCheck) {
        const { cancel } = await this.checkCanEdit()
        if (cancel) { return }
      }

      this.setChanged()
      await this.$router.push({ name: CHECKUPS_ROUTE_NAMES.CHECKUP_DOCUMENT_EDIT })
    },

    async onSubmit () {
      const result = await this.document.update()
      if (result?.errors) { return this.errorHandler(result.errors) }

      this.setChanged(false)

      if (this.semdModalApi?.updateList) {
        await this.semdModalApi.updateList()
      }

      await this.goToShowPage()
    },

    async onBack () {
      this.setChanged(false)
      await this.goToShowPage()
    },

    async goToShowPage () {
      await this.$router.replace({ name: CHECKUPS_ROUTE_NAMES.CHECKUP_DOCUMENT_SHOW })
    },

    onOpenCheckup () {
      window.open(`/checkup/personal-checkup/${this.document.personalCheckupId}`)
    },
  },
})
</script>
