<template>
  <validation-wrapper
    :m-fixed-height="mFixedHeight"
    :errors="validationErrors"
    class="m-time-select"
  >
    <el-time-select
      :value="startTime"
      :placeholder="t('reception.time_finder.start_time')"
      :picker-options="pickerOptionsStart"
      placement="bottom-start"
      :disabled="canManage.disabled"
      @input="changeStart"
    />
    <el-time-select
      v-if="timeRange"
      :value="endTime"
      :placeholder="t('reception.time_finder.end_time')"
      :picker-options="pickerOptionsEnd"
      placement="bottom-start"
      :disabled="canManage.disabled"
      @input="changeEnd"
    />
    <m-label
      v-if="label"
      :label="label"
      :required="requiredLabel"
      class="time-select-label"
    />
  </validation-wrapper>
</template>

<script>
import { timePickerOptions } from '@/vue_present/_base/inputs/MTimeSelect/helpers'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import MLabel from '@/vue_present/_base/inputs/MLabel.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MTimeSelect',
  components: { MLabel, ValidationWrapper },
  mixins: [
    ValidationChildMixin, DisabledMixin,
  ],

  props: {
    label: { type: String, default: null },
    startTime: { type: String, default: null },
    endTime: { type: String, default: null },
    start: { type: String, default: '00:00' },
    step: { type: String, default: null },
    end: { type: String, default: '23:59' },
    timeRange: Boolean,
  },

  computed: {
    pickerOptionsStart () {
      const options = {
        ...timePickerOptions(),
        maxTime: this.endTime,
      }

      if (this.start) { options.start = this.start }
      if (this.step) { options.step = this.step }
      if (this.end) { options.end = this.end }

      return options
    },

    pickerOptionsEnd () {
      const options = {
        ...timePickerOptions(),
        minTime: this.startTime,
      }

      if (this.start) { options.start = this.start }
      if (this.step) { options.step = this.step }
      if (this.end) { options.end = this.end }

      return options
    },

    requiredLabel () {
      return Boolean(this.required)
    },
  },

  watch: {
    startTime () {
      this.pickerOptionsEnd.minTime = this.startTime
    },
  },

  methods: {
    changeStart (data) {
      this.$updateSync('startTime', data)
      this.$emit('changeStartTime', data)
    },

    changeEnd (data) {
      this.$updateSync('endTime', data)
      this.$emit('changeEndTime', data)
    },
  },
})
</script>
