import { camelToSnake } from '@/_api/_requests/helpers'
import { orUndefined } from '@/_medods_standart_library/msl'

export const checkupEntryUpdateAdapter = {
  toServer (payload) {
    return JSON.stringify(
      camelToSnake({
        updateData: {
          ids: payload.ids,
          userId: orUndefined(payload.userId),
          state: orUndefined(payload.state),
        },
      })
    )
  },
}
