<template>
  <div>
    <div class="flex flex-wrap gap-indent-2">
      <div>
        <h1>PaginationMixin.js</h1>
        <p>Брат-близнец <b>filters_base.js</b>, используется в местах где можно обойтись без Vuex</p>
        <p>Дополняет компонент следующей функциональностью:</p>
        <ul>
          <li>Данные для пагинации - лимит, количество страниц, количество элементов на странице, текущую страницу</li>
          <li>Набор методов, которыми управляются предыдущие параметры</li>
        </ul>

        <h2>Описание процесса</h2>
        <img src="@/vue_present/_FrontBook/pages/FrontBookPagePaginationMixin/paginationMixin.svg">
      </div>

      <div>
        <h2>Данные</h2>
        <h3>offset</h3>
        <p>На основе текущей страницы считает <b>offset</b>, также есть и обратный процесс</p>
        <h3>pagination</h3>
        <p>Объект, в котором указаны <b>offset</b> и <b>limit</b></p>

        <h2>Методы</h2>
        <h3>setLimit</h3>
        <p>Установит количество элементов на страницу, сбросит текущую страницу на первую</p>

        <h3>setTotals</h3>
        <p>Установит количество страниц и общее количество элементов, обычно вызывается после успешного выполнения запроса</p>

        <h3>setCurrentPage</h3>
        <p>Установит текущую страницу, вызовет пересчет <b>pagination</b></p>

        <h3>setTotalPages</h3>
        <p>Установит количество страниц</p>

        <h3>resetPagination</h3>
        <p>Вызовет <b>setLimit</b> и <b>setTotalPages</b></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrontBookPagePaginationMixin',
}
</script>
