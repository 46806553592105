import { ISemd230MetaData } from '@/vue_apps/Semds/entities/Semd230/ISemd230MetaData'
import { ICatalog } from '@/_declarations/ICatalog'
import { IHuman } from '@/_declarations/IHuman.d'

export class Semd230MetaData implements ISemd230MetaData {
  company: ICatalog<number> = null

  department: string = null

  doctor: IHuman = null

  documentId: number = null

  nsiPrivacyLevel: ICatalog<number> = null

  nsiProfession: ICatalog<number> = null
}
