<template>
  <m-modal
    class="personal-checkup-diseases-personal-modal"
    modal-title-icon="referral"
    :dialog-title="t('choose_diagnoses')"
    :visible="visible"
    width="min(80%, 1100px)"
    @close="onClose"
  >
    <template #body>
      <m-select
        :value="diagnosisType"
        :items="PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE_ITEMS"
        :label="t('type')"
        :clearable="false"
        validator-name="type"
        required
        @registerValidator="onRegisterValidator($event)"
        @change="diagnosisType = $event"
      />

      <m-select-lazy
        :value="diagnoses"
        value-key="diagnosisId"
        option-label="diagnosisTitle"
        option-notice="diagnosisCodeString"
        :fetch-method="(params) => personalCheckup.diagnosesPersonal.fetchDiseases(params)"
        :additional-displayed-filter="diagnosisCodeStringFilter"
        :label="t('diagnoses')"
        use-first-time-fetch
        use-first-time-fetch-on-open
        multiple
        filterable
        @changeByLazy="onDiagnosesChange"
      />

      <diseases
        @setTreeRef="treeRef = $event"
        @onNodeClick="onNodeClick"
      />
    </template>

    <template #footer-right-action>
      <m-button
        template="save"
        @click="onSubmit"
      />
    </template>
  </m-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Diseases from '@/vue_apps/catalogs_root/Diseases/Diseases.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import {
  PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE_ITEMS,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDiagnosesPersonalType'
import {
  IPersonalCheckupDiagnosesPersonalItem,
} from '@/vue_apps/CheckupsModule/interfaces/IPersonalCheckupDiagnosesPersonalItem'
import {
  diagnosisCodeStringFilter,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDiseasesPersonal/personalCheckupDiseasesPersonalAdditionalFilter'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import {
  extractDataFromNode,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDiseasesPersonal/personalCheckupDiseasesPersonalExtractDataFromNode'

export default defineComponent({
  name: 'PersonalCheckupDiseasesPersonalModal',

  components: {
    MSelectLazy,
    MButton,
    MSelect,
    MModal,
    Diseases,
  },

  mixins: [ValidationParentMixin, CheckupsPropsMixin],

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE_ITEMS,
      diagnosisType: PERSONAL_CHECKUP_DIAGNOSES_PERSONAL_TYPE_ITEMS[0],
      treeRef: null,
    }
  },

  computed: {
    diagnoses () {
      return this.personalCheckup
        .getDiagnosesPersonalArr()
        .filter(({ diagnosisType, _destroy }) =>
          diagnosisType === this.diagnosisType.id && !_destroy)
    },
  },

  methods: {
    diagnosisCodeStringFilter,

    onNodeClick (node: LazyTreeNode) {
      if (!node.isLeaf) { return }

      const {
        diagnosisId,
        diagnosisTitle,
        diagnosisCodeString,
      } = extractDataFromNode(node)

      const isExists = this.personalCheckup
        .diagnosesPersonal
        .isExists(diagnosisId, this.diagnosisType.id)

      if (isExists) { return }

      this.personalCheckup.diagnosesPersonal.nestedItems.append({
        diagnosisId,
        diagnosisTitle,
        diagnosisCodeString,
        diagnosisType: this.diagnosisType.id,
      })
    },

    onDiagnosesChange (diagnoses: IPersonalCheckupDiagnosesPersonalItem[]) {
      this.personalCheckup.diagnosesPersonal
        .changeTrigger(diagnoses, this.diagnosisType.id)
    },

    onSubmit () {
      if (this.hasErrors()) { return }

      if (this.isNewMode) {
        this.personalCheckup.diagnosesPersonal.setKeep()

        return this.$emit('close')
      }

      this.personalCheckup
        .diagnosesPersonal
        .submit()

      this.$emit('close')
    },

    async onClose () {
      if (this.personalCheckup.diagnosesPersonal.getUnsavedAndDestroy().length) {
        const { cancel } = await MConfirm.warning(t('closeWithoutSaving?'))

        if (cancel) { return }

        this.personalCheckup.diagnosesPersonal.removeUnsavedAndRestore()
      }

      this.$emit('close')
    },
  },
})
</script>
