import { MAX_SMS_SYMBOLS_LENGTH } from '@/vue_apps/app_configuration/messages/scripts/consts'

export const variablesParser = (str = '') => {
  const regex = /[{}]/g

  const res = (str.match(/{{(.+?)}}/g) || [])
    .map((item) => item
      .replaceAll(regex, '')
      .trim()
      .split('.')
    )

  return res
}

export const variablesParserWithChild = (str = '') => {
  const regex = /[{}]/g
  const res = (str.match(/{{(.+?)}}/g) || [])
    .map((item) => item
      .replaceAll(regex, '')
      .trim()
    )

  if (!res.length) {
    return ['']
  }

  return res
}

export const replaceVariables = (str, replaceableValues, mappedVariables) => {
  if (!str) { return }

  let newStr = str

  newStr = newStr.includes('user.short_name') //🩼🩼 - т.к. в противном случае он меняет "Врач.Фамилия ИО" на "user.surname ИО" и всё ломается к чертям
    ? newStr.replaceAll('user.short_name', t('whatsapp.matchedVariablesList.user.short_name'))
    : newStr.replaceAll(t('whatsapp.matchedVariablesList.user.short_name'), 'user.short_name')

  replaceableValues
    .forEach((variable) => {
      newStr = newStr.replaceAll(variable, mappedVariables[variable] || variable)
    })

  return newStr
}

export const smsCount = (text) => Math.ceil(text.length / MAX_SMS_SYMBOLS_LENGTH)
