<template>
  <abbr
    v-tooltip="text"
    class="required-mark"
    :class="{'text-decoration-none': !underline}"
  >
    <slot>*</slot>
  </abbr>
</template>

<script>
export default {
  name: 'RequiredMark',
  props: {
    text: {
      type: String,
      default: t('activerecord.required.text'),
    },

    underline: Boolean,
  },
}
</script>
