<template>
  <m-button
    v-bind="$props"
    template="edit"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

export default defineComponent({
  name: 'MButtonEdit',
  components: { MButton },
  extends: MButton,
})
</script>
