<template>
  <m-panel
    class="checkup-doctors-and-tests"
    :title="t('checkups.doctorsAndTests.title')"
  >
    <slot name="before" />

    <slot name="factors" />

    <slot name="specialistsAndTestsDescription">
      <div class="checkup-doctors-and-tests__description prompt-notice">
        {{ t('checkups.doctorsAndTests.description') }}
      </div>
    </slot>

    <slot name="specialistsAndTests">
      <!--    Списки врачей и исследований    -->
      <div class="checkup-doctors-and-tests__content">
        <!--      Врачи      -->
        <m-table-generator
          :items="specialists"
          :table-schema="tableSchema"
          use-hidden-scrollbars
          @onItemClick="onItemClick"
        >
          <template #header>
            <slot name="addSpecialists" />
          </template>
        </m-table-generator>

        <!--      Исследования       -->
        <m-table-generator
          :items="tests"
          :table-schema="tableSchema"
          use-hidden-scrollbars
          @onItemClick="onItemClick"
        >
          <template #header>
            <slot name="addTests" />
          </template>
        </m-table-generator>
      </div>
    </slot>

    <!--    Статистика    -->
    <div
      v-if="$slots.after"
      class="checkup-doctors-and-tests__stats"
    >
      <slot name="after" />
    </div>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MTableGenerator from '@/vue_present/_base/Tables/MTableGenerator/MTableGenerator.vue'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { CheckupBase } from '@/vue_apps/CheckupsModule/classes/CheckupBase'
import { CHECKUP_ENTRY_TYPES_KINDS } from '@/vue_apps/catalogs_root/CheckupCatalogs/const/checkupEntryTypesKinds'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'

export default {
  name: 'CheckupDoctorsAndTests',
  components: { MTableGenerator, MPanel },

  props: {
    checkup: CheckupBase,
    readonly: Boolean,
    tableSchema: {
      type: MTableSchema,
      default: () => new MTableSchema({
        headers: {
          title: new MTableHeader(),
          action: new MTableHeader().addFixedWidth('40px'),
        },
      }),
    },
  },

  computed: {
    checkupEntries () {
      // TODO: изменить если будет менять checkupEntries в companyCheckup
      return this.checkup instanceof PersonalCheckupApi
        ? this.checkup.checkupEntries.checkupEntries
        : this.checkup.checkupEntries
    },

    /**
     * @return {CheckupCalculatedEntryCellBase[]}
     */
    specialists () {
      return this.checkupEntries.filter((entry) => entry.kind === CHECKUP_ENTRY_TYPES_KINDS.SPECIALIST)
    },

    /**
     * @return {CheckupCalculatedEntryCellBase[]}
     */
    tests () {
      return this.checkupEntries.filter((entry) => entry.kind === CHECKUP_ENTRY_TYPES_KINDS.TEST)
    },
  },

  methods: {
    onItemClick (checkupEntry) {
      if (this.readonly || checkupEntry.entry) { return }
      checkupEntry.trigger()

      if (this.checkup instanceof PersonalCheckupApi) {
        this.checkup
          .checkupEntries
          .saveCheckupEntry(checkupEntry)
      }
    },
  },
}
</script>
