export const getPersonalCheckupEntriesDefaultFilters = () => ({
  kind: null,
  state: null,
  user: null,
  mine: false,
})

export const getPersonalCheckupEntriesModalDefaultFilters = (personalCheckupId, userId) => ({
  userId,
  personalCheckupId,
  clinicId: gon.application.current_clinic.id,
  isActive: true,
  withEntry: false,
  withEntryType: true,
})
