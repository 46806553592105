<template>
  <div class="m-form-show">
    <div
      v-for="[label, value] in fields"
      :key="`m-form-show-row-${label}`"
      class="m-form-show-row"
    >
      <div
        :key="`m-show-label:${label}`"
        class="m-form-show__label"
      >
        <slot :name="`m-show-label-${label}`">
          {{ t(label, { scope: 'form_show' }) }}
        </slot>
      </div>

      <div
        :key="`m-show-value:${label}`"
        class="m-form-show__value"
      >
        <slot :name="`m-show-value-${label}`">
          {{ value }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'MFormShow',

  props: {
    form: PropsTypes.Object(),
  },

  computed: {
    fields () {
      return Object.entries(this.form)
    },
  },

}
</script>
