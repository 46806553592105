<template>
  <span class="checkup-created prompt-notice">
    {{ t('checkups.created', { creator: checkup.creator.shortName, date: $filters.date(checkup.createdAt) }) }}
  </span>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'CheckupCreated',

  props: {
    /**
     * @type {{ creator: { id: number, shortName: string }, createdAt: string }}
     */
    checkup: PropsTypes.Object({
      creator: null,
      createdAt: '',
    }),
  },
}
</script>
