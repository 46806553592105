export class EntryTypesClinicsCabinetsAttributesItem {
  constructor (clinicCabinetsMap, defaultClinicCabinetsMap, clinicIdKey) {
    this.clinicIdKey = clinicIdKey
    this.clinicCabinetsMap = clinicCabinetsMap
    this.defaultClinicCabinetsMap = defaultClinicCabinetsMap

    this.clinicCabinet = this.clinicCabinetsMap[this.clinicIdKey]
    this.clinicCabinetUsers = this.clinicCabinet.users || []
    this.defaultClinicCabinet = this.defaultClinicCabinetsMap[this.clinicIdKey]

    this.destroy = Boolean(!this.clinicCabinet.cabinet?.id && this.defaultClinicCabinet.cabinet?.id)
  }

  presetDestroyToDefaultUserMap () {
    return Utils.arrayToMap(
      (this.defaultClinicCabinet.users || [])
        .map((user) => ({ ...user, _destroy: true }))
    )
  }

  preventDestroyUserIfExistUser () {
    const defaultUsersMap = this.presetDestroyToDefaultUserMap()

    this.clinicCabinetUsers.forEach((user) => {
      if (!defaultUsersMap[user.id]) {
        defaultUsersMap[user.id] = user

        return
      }

      defaultUsersMap[user.id]._destroy = false
    })

    return Object.values(defaultUsersMap)
  }

  entryTypesClinicsCabinetsUsersAttributesAdapter () {
    return this.preventDestroyUserIfExistUser()
      .map((user) => ({
        id: user.dbId,
        userId: user.id,
        _destroy: user._destroy,
      }))
  }

  get () {
    return {
      id: this.defaultClinicCabinet.id,

      clinicId: +this.clinicIdKey,

      cabinetId: this.clinicCabinet.cabinet?.id || this.defaultClinicCabinet.cabinet?.id,

      entryTypeId: this.clinicCabinet.entryType?.id || null,

      entryTypesClinicsCabinetsUsersAttributes: this.entryTypesClinicsCabinetsUsersAttributesAdapter(),

      _destroy: this.destroy,
    }
  }
}
