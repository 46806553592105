export const diseaseAdapter = {
  /**
   * @param {ILazyTreeResponseData} response
   * @return {ILazyTreeResponseData}
   */
  toClient (response) {
    return {
      catalogs: response.catalogs.map((catalog) => ({
        ...catalog,
        title: catalog.parentId
          ? `${catalog.info} ${catalog.title}`
          : catalog.title,
      })),

      current: response.current,

      items: response.items.map((item) => ({
        ...item,
        title: `${item.codeString} ${item.title}`,
      })),
    }
  },
}
