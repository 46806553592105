<template>
  <m-popover
    :visibility.sync="visible"
    :disabled="canManage.disabled"
    :confirm-text="t('egisz.components.egisz_med_documents.form.to_sign')"
    :cancel-text="t('close')"
    :disable-confirm-btn="!thumbprint"
    yes-type="primary"
    inherited-visibility
    @yes="onThumbprintSelect"
    @no="closePopover"
  >
    <template #message>
      <reusable-sign-form v-model="thumbprint" />
    </template>

    <template #reference>
      <slot
        name="reference"
        :open-popover="openPopover"
        :close-popover="closePopover"
        :color="color"
      >
        <m-icon
          v-tooltip="canManage.tooltip || t('sign')"
          class="pointer"
          icon="signature"
          :color="color"
        />
      </slot>
    </template>
  </m-popover>
</template>

<script>
import { defineComponent } from 'vue'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import { DisabledMixin } from '@/vue_present/mixins/DisabledMixin'
import ReusableSignForm from '@/vue_present/Reuse/Sign/ReusableSignForm.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default defineComponent({
  name: 'ReusableSignPopover',
  components: { MIcon, ReusableSignForm, MPopover },
  mixins: [DisabledMixin],

  emits: ['onThumbprintSelect'],

  data () {
    return {
      visible: false,
      thumbprint: null,
    }
  },

  computed: {
    color () {
      return this.canManage.disabled ? 'grey' : 'primary'
    },
  },

  methods: {
    openPopover () {
      this.visible = true
    },

    closePopover () {
      this.visible = false
    },

    onThumbprintSelect () {
      this.$emit('onThumbprintSelect', this.thumbprint)
      this.closePopover()
    },
  },
})
</script>
