import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class DocumentListItem extends MListServiceItem {
  /**
   * @param {{
   *   id: number,
   *   date: string,
   *   number: number,
   *   title: string,
   * }} data
   */
  constructor (data) {
    super(data)

    this.id = data.id

    this.date = new MTableCell(data.date)

    this.number = new MTableCell(data.number)

    this.title = new MTableCell(data.title)
      .addCut()

    this.print = new MTableCell('print')
      .addTypeIcon('print')
      .addTooltip(t('print'))
      .addPreventDefault()
  }
}
