import { ICRUDConfig } from '@/_api/decorators/api/interfaces/ICRUDConfig'
import { crudBase } from '@/_api/decorators/api/crud/crudBase'
import { TRoute } from '@/_declarations/TRoute'
import { HTTP_METHODS } from '@/_api/_requests/const'

export function Submit (createRoute: TRoute, updateRoute: TRoute, config: ICRUDConfig = {}) {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return function (this: This, ...args: Args): Return {
      const { routeArgs, payload = {} } = target.call(this, ...args) ?? {}

      const isUpdate = Boolean(routeArgs?.at(0))

      return crudBase({
        httpMethod: isUpdate
          ? HTTP_METHODS.PATCH
          : HTTP_METHODS.POST,

        payload,

        route: isUpdate
          ? updateRoute
          : createRoute,

        routeArgs: isUpdate && routeArgs,

        config: {
          toJson: true,
          ...config,
        },
      }) as Return
    }
  }
}
