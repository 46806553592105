import {
  MIN_REPORTING_YEAR,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'

export const TaxCertificate2024EditorSecondBlockValidationMixin = {
  methods: {
    reportingYearValidator (value) {
      const errors = Utils.getValidationErrors(value)

      if (errors.length) { return errors }

      if (value < MIN_REPORTING_YEAR) {
        return [
          t('validation.errors.gteq?', { num: MIN_REPORTING_YEAR }),
        ]
      }

      return []
    },
  },
}
