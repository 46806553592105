/**
 *
 * Эта миксина позволяет использовать контекст i18n в компонентах без префикса.
 * Для того, чтобы установить контекст необходимо использовать метод setI18nScope(i18nScope)
 *
 * Пример:
 *  контекст i18n: setI18nScope('fixedDocument.taxCertificate2024')
 *
 *  обычный i18n:
 *  t('fixedDocument.taxCertificate2024.informationAboutThePerformingClinic')
 *
 *  Можно сократить до:
 *  t$('informationAboutThePerformingClinic')
 *
 */
export const i18nScopeMixin = {
  data () {
    return {
      i18nScope: undefined,
    }
  },

  methods: {
    t$ (i18n: string, options: object = {}): string {
      return window.t(i18n, { scope: this.i18nScope, ...options })
    },

    setI18nScope (i18nScope: string) {
      this.i18nScope = i18nScope
    },
  },
}
