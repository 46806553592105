import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class OrdersListTotalItem {
  constructor (rawData) {
    this.number = new MTableCell(t('total'))

    this.date = new MTableCell()

    this.status = new MTableCell()

    this.client = new MTableCell()

    this.finalSum = new MTableCell(vueFilters.currency(rawData.finalSum))

    this.paidSum = new MTableCell(vueFilters.currency(rawData.paidSum))
  }
}
