<template>
  <el-input
    ref="inputCurrency"
    type="text"
    class="currency-input"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span
      v-if="icon"
      slot="prefix"
      :class="icon"
    />
    <button
      v-if="iconRight"
      slot="suffix"
      :class="iconRight"
      @click="$emit('right-btn-handler')"
    />
  </el-input>
</template>

<script>
export default {
  props: {
    icon: {
      default: '',
      type: String,
    },
    iconRight: {
      default: '',
      type: String,
    },
  },

  mounted () {
    const vm = this
    const inputCurrency = $(this.$refs.inputCurrency.$el).find('.el-input__inner')

    inputCurrency.inputmask('currency', {
      prefix: '',
      groupSeparator: ' ',
      rightAlign: false,
      jitMasking: true,
      autoUnmask: true,
    })

    inputCurrency.on('input', function () {
      vm.$emit('input', this.value)
    })
  },
}
</script>
