<template>
  <search-input
    :placeholder="t('egisz.components.egisz_cases_filters.search_ph')"
    :value="filters.search_string"
    @update:value="vxSetSearchValue"
  />
</template>
<script>
import SearchInput from '@/vue_components/common/search_input'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'EgiszCasesSearch',
  components: { SearchInput },

  computed: {
    ...mapGetters('medCases/filtersBase', {
      filters: 'vxGetFilters',
    }),
  },

  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxSetSearchValue: 'vxSetSearchValue',
    }),
  },
}
</script>
