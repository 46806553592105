import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { ordersAdapter } from '@/vue_apps/FinanceModule/FinanceModuleIndex/api/ordersAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MOrderPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'order',
      location: 'MOrderPresenter',
      routes: {
        one () {},
        all () {},
        list: Routes.api_internal_finances_orders_path,
      },
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: (data) => camelToSnake(ordersAdapter.toServer(data)),
      toJson: true,
      ...config,
    })
      .then((res) => ordersAdapter.toClient(res))
  }
}
