<template>
  <el-dialog
    class="m-modal"
    :class="[`m-modal_${type}`, {
      'hide-footer': useHiddenFooter,
      'remove-body-top-padding': useBodyZeroTopPadding,
      'hidden-print': useHiddenPrint,
      'm-modal_height-100': useMaxHeight
    }]"
    :title="dialogTitle"
    :visible="visible"
    :width="width"
    :show-close="false"
    :close-on-click-modal="closeOnClickModal"
    :fullscreen="fullscreen || fullscreenState"
    :before-close="(done) => $emit('close', done)"
    :modal-append-to-body="modalAppendToBody"
    :append-to-body="appendToBody"
    @update:visible="$updateSync('visible', $event)"
  >
    <div
      slot="title"
      class="m-modal__header cut flex flex-grow-1"
    >
      <slot name="header">
        <span class="header__title">
          <m-icon
            v-if="modalTitleIcon"
            :icon="modalTitleIcon"
            class="mr-5"
          /> {{ dialogTitle }}
        </span>
      </slot>

      <i
        v-if="canFullscreen"
        class="fad pointer m-modal__fullscreen mr-10"
        :class="{
          'fa-expand': !fullscreenState,
          'fa-compress': fullscreenState
        }"
        @click="fullscreenState = !fullscreenState"
      />

      <i
        v-if="canClose"
        class="fad fa-times pointer m-modal__close"
        @click="close"
      />
    </div>

    <slot>
      <slot name="body" />
    </slot>

    <template #footer>
      <slot name="footer">
        <div class="footer__left">
          <slot name="footer-left" />
        </div>

        <div class="footer__right">
          <slot name="footer-right-action" />
          <slot name="footer-right">
            <close-button @close="close" />
          </slot>
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import CloseButton from '@/vue_components/common/buttons/CloseButton'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'MModal',
  components: { MIcon, CloseButton },
  props: {
    dialogTitle: {
      type: String,
      default: null,
    },

    type: PropsTypes.String('primary'),

    visible: Boolean,

    width: {
      type: String,
      default: null,
    },

    modalTitleIcon: PropsTypes.String(),
    fullscreen: Boolean,
    closeOnClickModal: Boolean,
    canClose: PropsTypes.Boolean(true),
    canFullscreen: Boolean,
    useHiddenFooter: Boolean,
    useBodyZeroTopPadding: Boolean,
    destroyOnClose: Boolean,
    useHiddenPrint: { type: Boolean, default: true },
    modalAppendToBody: Boolean,
    appendToBody: Boolean,
    useMaxHeight: Boolean,
  },

  data () {
    return {
      fullscreenState: false,
    }
  },

  methods: {
    close () {
      this.$updateSync('visible', false)
      this.$emit('close')
    },
  },
}
</script>
