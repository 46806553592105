<template>
  <modal
    v-if="modalVisibility"
    id="confirmation-modal"
    :modal-class="modalClass"
    :modal-visibility="modalVisibility"
    :modal-size="modalSize"
    @close="$emit('no')"
  >
    <span slot="header">{{ headerMessage }}</span>
    <span slot="body">{{ message }}</span>
    <template slot="footer-right">
      <button
        v-if="mode === CONFIRMATION_MODES.DEFAULT"
        class="btn btn-sm btn-success btn-with-icon modal-save"
        @click.prevent.stop="$emit('yes')"
      >
        <span class="btn-with-icon_icon fad fa-check" />
        <span class="btn-with-icon_text">{{ t('yes1') }}</span>
      </button>
      <button
        v-if="mode === CONFIRMATION_MODES.DESTROY"
        class="btn btn-sm btn-with-icon modal-save"
        :class="yesClass"
        @click.prevent.stop="$emit('yes')"
      >
        <span
          class="btn-with-icon_icon fad fa-exclamation-circle"
          :class="yesIcon"
        />
        <span class="btn-with-icon_text">{{ yesText }}</span>
      </button>
      <button
        class="btn btn-sm btn-primary btn-with-icon modal-close"
        @click.prevent.stop="$emit('no')"
      >
        <span class="btn-with-icon_icon fad fa-times" />
        <span class="btn-with-icon_text">{{ noText }}</span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from './modal.vue'
import { CONFIRMATION_MODES } from '@/vue_components/mixins/modals/const'

/**
 * В основном используется в связке с миксиной `ConfirmationHolder`.
 * При изменении пропсов нужно отразить это в миксине, так как возможно использование
 * дефолтного объекта пропсов через v-bind.
 *
 * @see {ConfirmationHolder}
 */
export default {
  name: 'ConfirmationModal',

  components: {
    Modal,
  },

  props: {
    modalVisibility: Boolean,

    headerMessage: {
      type: String,
      default: '',
    },

    modalClass: {
      type: String,
      default: 'confirmation-modal',
    },

    message: {
      type: String,
      default: undefined,
    },

    modalSize: {
      type: String,
      default: 'md',
    },

    mode: {
      type: String,
      default: CONFIRMATION_MODES.DEFAULT,
    },
  },

  data () {
    return {
      CONFIRMATION_MODES,
    }
  },

  // Временное решение до введения portal-vue
  computed: {
    yesClass () {
      switch (this.mode) {
        case CONFIRMATION_MODES.DESTROY: return 'btn-danger'
        default: return 'btn-primary'
      }
    },

    yesIcon () {
      switch (this.mode) {
        case CONFIRMATION_MODES.DESTROY: return 'fa-exclamation-circle'
        default: return 'fa-check'
      }
    },

    yesText () {
      switch (this.mode) {
        case CONFIRMATION_MODES.DESTROY: return t('delete')
        default: return t('yes1')
      }
    },

    noText () {
      switch (this.mode) {
        case CONFIRMATION_MODES.DESTROY: return t('cancel')
        default: return t('no1')
      }
    },
  },
}
</script>
