<template>
  <div class="sidebar__filter filter">
    <label class="filter__label">
      {{ t('clinic') }}
    </label>
    <simple-select
      v-loading="loading"
      class="filter__input"
      :value="filters[clinicId]"
      :items="clinics"
      :fixed-height="false"
      need-empty-option
      clearable
      @change="vxSetFilterValue({ filter: clinicId, value: $event })"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { CASE_FILTERS_FIELDS } from '@/vue_components/egisz/egisz_module/const/egisz_filters'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'

export default {
  name: 'EgiszCasesFilterClinic',
  components: { SimpleSelect },
  mixins: [SpinnerHolder],

  data () {
    return {
      clinicId: CASE_FILTERS_FIELDS.CLINIC_ID,
    }
  },

  computed: {
    ...mapGetters('medCases/filtersBase', {
      filters: 'vxGetFilters',
    }),

    ...mapGetters('globalCatalogs/clinicStore', {
      clinics: 'vxGetClinicsInFrmo',
    }),
  },

  mounted () {
    this.$nextTick(() => {
      this.withSpinner(
        this.$store.dispatch('globalCatalogs/clinicStore/vxLoadClinicsInFrmo')
      )
    })
  },

  methods: {
    ...mapMutations('medCases/filtersBase', {
      vxSetFilterValue: 'vxSetFilterValue',
    }),
  },
}
</script>
