import { MRequest } from '@/lib/transport/MRequest'

const URL_ADDRESS = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
const URL_PARTY = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party'
const URL_FMS_UNIT = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit'
const URL_GEO_LOCATION = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address'

class Suggestion extends MRequest {
  constructor (token) {
    super()

    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + token,
    }
  }

  /**
   * @param {{ query: String, count: Number, deferRequestBy: Number, }} params
   * @returns {Promise<any>}}
   */
  address (params) {
    const options = {
      headers: this.headers,
      url: URL_ADDRESS,
      data: JSON.stringify(params),
    }

    return this.post(options).promise
  }

  /**
   * @param {{ query: String, count: Number, deferRequestBy: Number, }} params
   * @returns {Promise<any>}}
   */
  party (params) {
    const options = {
      headers: this.headers,
      url: URL_PARTY,
      data: JSON.stringify(params),
    }

    return this.post(options).promise
  }

  /**
   * @param {{ query: String }} params
   * @returns {Promise<any>}}
   */
  fmsUnit ({ query }) {
    const options = {
      headers: this.headers,
      url: URL_FMS_UNIT,
      data: JSON.stringify({ query }),
    }

    return this.post(options).promise
  }

  geoLocation () {
    const options = {
      headers: this.headers,
      url: URL_GEO_LOCATION,
      mode: 'cors',
    }

    return this.get(options).promise
  }
}

export const suggestionEndpoint = new Suggestion(gon.application.suggestion_api)
