<template>
  <div class="flex flex-column">
    <slot name="matching" />

    <!-- Индекс -->
    <m-input
      :label="t('index')"
      class="mt-6"
      :value="index"
      :disabled="disabled"
      @input="$updateSync('index', $event)"
    />

    <!-- Страна -->
    <m-input
      :label="t('country')"
      :value="country"
      :disabled="disabled"
      @input="$updateSync('country', $event)"
    />

    <!-- Регион -->
    <reusable-global-n-s-i-list
      v-if="useNSIRussianSubjectsDictionary"
      :value="nsiRussianSubjectId"
      :placeholder="t('selectRegion')"
      :label="t('region')"
      full-width
      m-fixed-height
      dictionary="russian_subjects"
      :disabled="disabled"
      :required="regionRequired"
      validator-name="region"
      @registerValidator="$emit('registerValidator', $event)"
      @change="$updateSync('nsiRussianSubjectId', $event)"
      @changeItem="$updateSync('region', $event && $event.title)"
    />
    <m-input
      v-else
      :label="t('region')"
      :value="region"
      :disabled="disabled"
      :required="regionRequired"
      validator-name="region"
      @registerValidator="$emit('registerValidator', $event)"
      @input="$updateSync('region', $event)"
    />

    <slot name="area">
      <!-- Район -->
      <m-input
        :label="t('area')"
        :value="area"
        :disabled="disabled"
        @input="$updateSync('area', $event)"
      />
    </slot>

    <!-- Населенный пункт/Город -->
    <m-input
      :label="t('city')"
      :value="city"
      :disabled="disabled"
      :required="cityRequired"
      validator-name="city"
      @registerValidator="$emit('registerValidator', $event)"
      @input="$updateSync('city', $event)"
    />

    <!-- Улица -->
    <m-input
      :label="t('street')"
      :value="street"
      :disabled="disabled"
      :required="streetRequired"
      validator-name="street"
      @registerValidator="$emit('registerValidator', $event)"
      @input="$updateSync('street', $event)"
    />

    <!-- Дом -->
    <m-input
      :label="t('house')"
      :value="house"
      :disabled="disabled"
      :required="houseRequired"
      validator-name="house"
      @registerValidator="$emit('registerValidator', $event)"
      @input="$updateSync('house', $event)"
    />

    <!-- Квартира / Офис -->
    <m-input
      :label="t('flat')"
      class="pb-0"
      :value="flat"
      :disabled="disabled"
      @input="$updateSync('flat', $event)"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import ReusableGlobalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableGlobalNSIList.vue'

export default {
  name: 'AddressForm',
  components: { ReusableGlobalNSIList, MInput },

  props: {
    index: { type: String, default: null },
    country: { type: String, default: null },
    region: { type: String, default: null },
    area: { type: String, default: null },
    city: { type: String, default: null },
    street: { type: String, default: null },
    house: { type: String, default: null },
    flat: { type: String, default: null },
    nsiRussianSubjectId: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    requiredFields: { type: Object, default: () => ({}) },
  },

  data () {
    return {
      useNSIRussianSubjectsDictionary: gon.application.nsi.russianSubjectsDictionary,
    }
  },

  computed: {
    regionRequired () {
      return Boolean(this.requiredFields.region)
    },

    cityRequired () {
      return Boolean(this.requiredFields.city)
    },

    streetRequired () {
      return Boolean(this.requiredFields.street)
    },

    houseRequired () {
      return Boolean(this.requiredFields.house)
    },
  },
}
</script>
