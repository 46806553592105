<template>
  <div class="clinic-logotypes-tab">
    <clinic-logotypes-new
      v-if="isNewMode || !clinic.id"
      :is-save-new-clinic="isNewMode"
    />
    <clinic-logotypes
      v-else
      :clinic-id="clinic.id"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ClinicLogotypes from '@/vue_components/clinic/clinic_logotypes.vue'
import ClinicLogotypesNew from '@/vue_components/clinic/clinic_logotypes_new.vue'
import { Clinic } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/Clinic'

export default defineComponent({
  name: 'ClinicLogotypesTab',
  components: {
    ClinicLogotypesNew,
    ClinicLogotypes,
  },

  props: {
    clinic: { type: Clinic, required: true },
    isNewMode: Boolean,
  },
})
</script>
