export const treeNodeRenderFunction = (h, { node, data }) => {
  const __class = !data.leaf
    ? node.expanded
      ? 'fa-folder-open warning'
      : 'fa-light fa-folder warning'
    : 'fa-comment-lines primary'

  return h(
    'span',
    { class: 'custom-tree-node' },
    [
      h('i', { class: 'fad fa-fw mr-5 ' + __class }),
      h('span', data.title),
    ]
  )
}
