<template>
  <div class="m-icon-page">
    <h1>MIcon</h1>
    <h2>Доступные иконки</h2>
    <div class="icons-table">
      <div
        v-for="icon in icons"
        :key="icon"
        class="icon"
      >
        <m-icon
          :icon="icon"
          color="primary"
        />
        <span>{{ icon }}</span>
      </div>
      <div class="icon">
        <m-icon
          icon="no-filter"
          color="primary"
          :no-use-fw="true"
        />
        <span>no-filter</span>
      </div>
    </div>
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon'
import { iconsMap } from '@/modules/utils/medods_ui/iconsMap'

export default {
  name: 'FrontBookPageMIcon',
  components: { MIcon },
  data () {
    return {
      iconsMap,
    }
  },

  computed: {
    icons () {
      const iconsNames = Object.keys(iconsMap)
      iconsNames.pop()

      return iconsNames
    },
  },
}
</script>
