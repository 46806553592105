
/**
 * @param {{tooltip: String, disabled: Boolean}}
 * @return {{tooltip: String, disabled: Boolean}}
 */
export const wrapDisable =
  ({ disabled = false, tooltip = null }) => ({ disabled, tooltip })

/**
 * @param {Boolean} value
 * @return {{tooltip: String, disabled: Boolean}}
 */
export const canManageBoolean =
  (value) => wrapDisable({ disabled: value })

/**
 * @return {{tooltip: String, disabled: Boolean}}
 */
export const canManageFalsy =
  () => wrapDisable({ disabled: false })

/**
 * @param {{tooltip: String, disabled: Boolean}} value
 * @return {{tooltip: String, disabled: Boolean}}
 */
export const canManageStructured =
  (value) => wrapDisable(value)

/**
 * @param {String} value - если указано :view в value, то проверяет на "Только чтение", а не "Да"
 * @return {{tooltip: String, disabled: Boolean}}
 */
export const canManageSecurity = (value) => {
  const canType = value.toLowerCase().endsWith(':view') ? 'canView' : 'canManage'
  const model = value?.split(':')[0] || value

  const disabled = !Services.security[`${canType}${model}`]

  return wrapDisable({
    disabled,
    tooltip: disabled && t('insufficient_access_rights'),
  })
}
