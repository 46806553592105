<template>
  <div class="variables-modal">
    <m-modal
      :visible.sync="modalVisibility"
      :dialog-title="t('choose_variable')"
      width="500px"
      @close="closeModal"
    >
      <template #body>
        <el-tree
          :data="variablesList"
          @node-click="handleNodeClick"
        />
      </template>
      <template #footer-left />
    </m-modal>

    <m-button
      :text="t('variables')"
      type="success"
      icon="brackets"
      size="mini"
      @click="openModal"
    />
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'

import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'VariablesModal',
  components: { MButton, MModal },

  props: {
    variablesList: PropsTypes.Array(),
  },

  data () {
    return {
      modalVisibility: false,
    }
  },

  methods: {
    openModal () { this.modalVisibility = true },

    closeModal () { this.modalVisibility = false },

    handleNodeClick (data) {
      this.$emit('insertVariable', data)
    },
  },
}

</script>
