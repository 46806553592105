import { createVueApp } from '@/vue_components/create_vue_app'
import ContactsMessages from '@/vue_apps/Contacts/ContactsMessages/ContactsMessages.vue'

export const ContactsMessagesRoot = {
  init () {
    return createVueApp({
      el: '#sms_custom_message_editor',
      name: 'ContactsMessagesRoot',
      render: (h) => h(ContactsMessages),
    })
  },
}
