import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import {
  buildMedicalPolicyTitle,
} from '@/vue_apps/ClientsModule/components/MedicalPolices/const/buildMedicalPolicyTitle'
import { isPolicyActive } from '@/vue_apps/ClientsModule/components/MedicalPolices/const/isPolicyActive'

export class MedicalPolicyListItem extends MListServiceItem {
  /**
   * @typedef {{
   *   active: boolean,
   *   dateEnd: string,
   *   dateStart: string,
   *   id: number,
   *   insuranceCompany: ICatalog,
   *   number: string,
   *   policyType: 'oms' | 'dms',
   *   nsiOmsType: INsiEntry
   * }} medicalPolicyListItemRaw
   *
   * @param {medicalPolicyListItemRaw} data
   */
  constructor (data) {
    super()

    this._data = data

    this.id = data.id

    this.title = buildMedicalPolicyTitle(data)

    this.status =
      new MTableCell(t(
        Utils.ternary(data.active, 'active', 'inactive'),
        { scope: 'activerecord.attributes.medical_policy' }
      ))

    this.number = new MTableCell(data.number)
      .addTooltip(true)

    this.dateStart = new MTableCell(vueFilters.date(data.dateStart))
    this.dateEnd = new MTableCell(vueFilters.date(data.dateEnd))

    this.insuranceCompany = new MTableCell(data.insuranceCompany?.title)
      .addTooltip(true)

    this.policyType = new MTableCell(t(data.policyType))

    const isActiveAndNotExpired = isPolicyActive({
      active: data.active,
      dateStart: data.dateStart,
      dateEnd: data.dateEnd,
      nsiOmsTypeNsiId: data.nsiOmsType?.nsiId,
    })

    this.isActiveAndNotExpired = isActiveAndNotExpired

    this.dataAttributes = {
      'data-active-and-not-expired': isActiveAndNotExpired,
    }
  }

  get isDms () {
    return this._data.policyType === 'dms'
  }
}
