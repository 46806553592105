<template>
  <div class="document-page-break">
    <span>{{ t('page_break') }}</span>
  </div>
</template>

<script>
export default {
  name: 'DocumentPageBreak',
}
</script>
