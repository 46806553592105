import { MED_DOCUMENT_HOLDER_SCOPE } from '@/api_entities/egisz/med_documents/med_document_holder_scope'

const scopeSignedString = (scope) => `${scope}_signed`

export const getAdaptedSign = (signature, scope = MED_DOCUMENT_HOLDER_SCOPE.ORGANIZATION) => ({
  workplace_id: signature.workplaceId,
  certificate_id: signature.certificateId,
  local_machine: signature.localMachine,
  holder_scope: scope,
})

export const getMedDocumentsForSign = (medDocuments, resign, scope = MED_DOCUMENT_HOLDER_SCOPE.ORGANIZATION) =>
  resign
    ? medDocuments
    : medDocuments.filter((item) =>
      !(item.attachment_signature_status && item.attachment_signature_status[scopeSignedString(scope)])
    )

export const setSuccessStatus = (
  medDocuments,
  id,
  scope = MED_DOCUMENT_HOLDER_SCOPE.ORGANIZATION,
  success = false
) => {
  const medDocument = medDocuments.find((item) => item.id === id)
  if (!medDocument) { return }

  medDocument.attachment_signature_status[scopeSignedString(scope)] = success
}

export const showResultNotification = (rejected = []) => {
  rejected.length
    ? Notificator.error(t('egisz.components.egisz_cases.sign_register_modal.sign.fail'))
    : Notificator.success(t('egisz.components.egisz_cases.sign_register_modal.sign.success'))
}
