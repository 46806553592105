export const COMPANY = 'company'
export const COMPANIES = 'companies'
export const COMPANY_NEW = 'company-new'
export const COMPANY_EDIT = 'company-edit'
export const COMPANY_SHOW = 'company-show'
export const COMPANY_ORDERS = 'company-orders'
export const COMPANY_PAYMENTS = 'company-payments'
export const COMPANY_DOCUMENTS = 'company-documents'
export const COMPANY_ORDERS_REGISTRY = 'company-orders-registry'
export const COMPANY_ORDERS_REGISTRIES = 'company-orders-registries'
