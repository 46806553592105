<template>
  <div class="tax-certificate-2024-app">
    <fixed-document-editor-header v-if="isEditor" />

    <router-view :certificate="certificate" />

    <fixed-document-print-footer
      v-if="isShow"
      @onEditClick="onEditClick"
      @onDestroyClick="onDestroyClick"
    />
  </div>
</template>

<script>
import {
  TaxCertificate2024,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/classes/TaxCertificate2024'
import FixedDocumentEditorHeader from '@/vue_apps/Documents/FixedDocuments/components/FixedDocumentEditorHeader.vue'
import {
  TAX_CERTIFICATE_ROUTE_NAMES,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/router/routeNames'
import FixedDocumentPrintFooter from '@/vue_apps/Documents/FixedDocuments/components/FixedDocumentPrintFooter.vue'
import { scrollToTop } from '@/vue_apps/Documents/FixedDocuments/helpers/scrollToTop'

export default {
  name: 'TaxCertificate2024App',
  components: {
    FixedDocumentPrintFooter,
    FixedDocumentEditorHeader,
  },

  props: {
    clientId: { type: Number, default: null },
    documentId: { type: Number, default: null },
  },

  emits: ['notFound'],

  data () {
    return {
      certificate: new TaxCertificate2024(),
      isShowAfterEdit: false,
    }
  },

  computed: {
    isShow () {
      return this.$route.name.endsWith('-show')
    },

    isEditor () {
      return !this.isShow
    },
  },

  created () {
    this.certificate.setValue('representedPersons', this.clientId)
    this.certificate.fetchCertificates(this.clientId)

    this.documentId
      ? this.initEditMode()
      : this.initNewMode()
  },

  methods: {
    initNewMode () {
      this.certificate.setValue('taxpayer', { id: this.clientId })
      this.certificate.certificateNumberHandler()
    },

    async initEditMode () {
      const result = await this.certificate.fetchDocument({
        id: this.documentId,
        isEditMode: true,
      })

      if (result?.errors) { return this.$emit('notFound') }
    },

    onEditClick () {
      scrollToTop()

      this.$router.push({
        name: TAX_CERTIFICATE_ROUTE_NAMES.EDIT,
        query: this.$route.query,
      })
    },

    async onDestroyClick () {
      const response = await this.certificate.destroy()

      if (response?.errors) { return }

      HistoryBrowser.goBackFrom('fixedDocumentDestroy')
    },
  },
}
</script>
