<template>
  <div class="m-range">
    <div class="header">
      <slot name="header" />
    </div>

    <el-slider
      :value="value"
      :disabled="disabled"
      :step="step"
      :show-stops="showStops"
      :show-input="showInput"
      :min="min"
      :max="max"
      range
      @input="$emit('input', $event)"
    />

    <slot name="range">
      <div class="range">
        {{ value[0] }}...{{ value[1] }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'MRange',

  props: {
    value: { type: Array, required: true },
    disabled: Boolean,
    step: { type: Number, default: 1 },
    showStops: Boolean,
    showInput: Boolean,
    min: { type: Number, default: 0 },
    max: { type: Number, default: 10 },
  },
}
</script>
