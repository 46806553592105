import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export const buildMedicalPolicyTitle = (data) => {
  return [
    data.number,
    data.insuranceCompany?.title,
    vueFilters.date(data.dateEnd),
    t(data.policyType),
  ].filter(Boolean).join(', ')
}
