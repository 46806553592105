import { mapActions } from 'vuex'

import OrdersSumDistributor from './orders_sum_distributor.js'
import { BREAKPOINT_TRANSFER_ORDER } from './consts.js'
import { DATE_ISO_FORMAT, PAYMENT_SPECIES } from '../consts.js'
import { PAGINATION_PAGE_SIZE } from '@/vue_components/sort_items/consts.js'
import { PAYMENT_ERRORS, PAYMENT_PARAMS } from '@/lib/payments/consts.js'
import { slicedList } from '../../helpers/sliced_list.js'
import { paymentsEndpoint } from '@/api_entities/payments/payments_endpoint'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'
import { orderRegistriesEndpoint } from '@/api_entities/companies/order_registries_endpoint'

export default {
  ...mapActions(['LOAD_COMPANY']),

  collapsedUnassociated () {
    this.showWindowState.unassociated = true
  },

  collapsedAssociated () {
    this.showWindowState.associated = true
  },

  associateOrders () {
    if (this.selectedUnassociatedOrdersNum === 0) return

    this.fetched.orders.associated.updating = true
    orderRegistriesEndpoint.setRegistryForOrders(this.selectedUnassociatedOrderIds, this.$route.params.registryId)
      .then(() => {
        this.fetchOrders()
        this.fetched.orders.unassociated.input.ordersMap = Object.create(null)
      })
      .catch(() => {
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.fetched.orders.associated.updating = false
      })
  },

  unassociateOrders () {
    if (this.selectedAssociatedOrdersNum === 0) return

    this.fetched.orders.unassociated.updating = true
    orderRegistriesEndpoint.setRegistryForOrders(this.selectedAssociatedOrderIds, null)
      .then(() => {
        this.fetchOrders()
        this.fetched.orders.associated.input.ordersMap = Object.create(null)
      })
      .finally(() => {
        this.fetched.orders.unassociated.updating = false
      })
  },

  rollUpUnassociatedOrders () {
    this.showWindowState.unassociated = false
  },

  rollUpAssociatedOrders () {
    this.showWindowState.associated = false
  },

  ordersToMap (map, orders) {
    for (let order of orders) {
      this.$set(map, order.id, order)
    }
  },

  mapEmpty (map) {
    return !Object.keys(map).length
  },

  objArrEqMap (array, map, mapKey = 'id') {
    return array.every((item) => item[mapKey] in map)
  },

  defaultForm () {
    return {
      comment: '',
      summary: 0,
      paymentSpec: [PAYMENT_SPECIES.BY_CASHLESS],
      deposit: 0,
      credit: 0,
      surrender: 0,
    }
  },

  defaultValidationForm () {
    return {
      paymentSpec: [],
      summaryInputUser: [],
      ordersErrorCodes: [],
      ordersErrorValid: [],
    }
  },

  newOrdersDefaultInput () {
    return {
      selectedOrderId: '',
      selectedDateRange: [],
      selectedOrderPaidStatus: 'all',
      ordersMap: Object.create(null),
    }
  },

  fetchOrders () {
    this.fetchAssociatedOrders()
    this.fetchUnassociatedOrders()
  },

  fetchUnassociatedOrders () {
    this.fetched.orders.unassociated.loading = true
    companiesEndpoint.getOrders(this.$route.params.companyId, this.unassociatedOrdersReqParams)
      .then((response) => {
        const { unassociated } = this.fetched.orders
        unassociated.error = false
        unassociated.list = [ ...response.orders ]
        this.slicedUnassociated()
      })
      .catch(() => {
        this.fetched.orders.unassociated.error = true
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.fetched.orders.unassociated.loading = false
      })
  },

  fetchAssociatedOrders () {
    this.fetched.orders.associated.loading = true
    companiesEndpoint.getOrders(this.$route.params.companyId, this.associatedOrdersReqParams)
      .then((response) => {
        this.fetched.orders.associated.error = false
        this.fetched.orders.associated.list = [ ...response.orders ]
        this.slicedAssociated()
      })
      .catch(() => {
        this.fetched.orders.associated.error = true
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.fetched.orders.associated.loading = false
      })
  },

  fetchOrdersRegistry () {
    const { companyId, registryId } = this.$route.params
    this.fetched.ordersRegistry.loading = true

    orderRegistriesEndpoint.get(companyId, registryId)
      .then((response) => {
        this.fetched.ordersRegistry.data = { ...response }
      })
      .catch(() => {
        this.fetched.ordersRegistry.error = true
      })
      .finally(() => {
        this.fetched.ordersRegistry.loading = false
      })
  },

  slicedUnassociated () {
    const { unassociated, unassociated: { pagination } } = this.fetched.orders
    unassociated.sliced = slicedList(unassociated.list, pagination, PAGINATION_PAGE_SIZE)
  },

  slicedAssociated () {
    const { associated, associated: { pagination } } = this.fetched.orders
    associated.sliced = slicedList(associated.list, pagination, PAGINATION_PAGE_SIZE)
  },

  onResize () {
    if (window.innerWidth > BREAKPOINT_TRANSFER_ORDER) {
      this.displayMedium = false
    } else {
      this.displayMedium = true
    }
  },

  distributionFundsOrders  () {
    const attrPayments = {
      baseKind: PAYMENT_PARAMS.BASE_KIND.FUND,
      clinicId: this.GET_APP_CONF_CURRENT_CLINIC_ID,
      userId: this.GET_APP_CONF_CURRENT_USER_ID,
      payerCompanyId: this.$route.params.companyId,
      distribution: PAYMENT_PARAMS.DISTRIBUTION.AUTO_DISTRIBUTION,
      comment: this.modal.masspay.form.comment,
      paymentSpec: this.paymentSpec,
    }

    const sumDistributor = new OrdersSumDistributor(
      this.modal.masspay.form.summary,
      this.selectedOrdersArrayToPay,
      this.modal.masspay.form.surrender,
      this.modal.masspay.form.deposit,
      attrPayments,
    )

    const payments = sumDistributor.distribute()

    const errorsMap = sumDistributor.validationErrorPaymentsMap()
    const ordersErrorValid = []

    errorsMap.map(({ code, ...message }) => {
      this.modal.masspay.validationMessages.ordersErrorCodes.push(code)
      ordersErrorValid.push(message)
    })

    this.modal.masspay.validationMessages = {
      ...this.modal.masspay.validationMessages,
      ordersErrorValid,
    }

    return this.generateHashMap(payments)
  },

  generateHashMap (payments) {
    const paymentsHash = Object.create(null)

    payments.map((payment) => {
      payment.date = moment().format(DATE_ISO_FORMAT)
      paymentsHash[payment.destination_order_id] = payment
    })

    return paymentsHash
  },

  createPayments () {
    this.modal.masspay.loading = true
    const payments = this.distributionFundsOrders()
    if (this.hasValidationOrderErrors) {
      this.modal.masspay.error = true
      this.modal.masspay.loading = false

      return
    }
    paymentsEndpoint.createBulk({
      user_id: this.GET_APP_CONF_CURRENT_USER_ID,
      payer_type: PAYMENT_PARAMS.PAYER_TYPE.COMPANY,
      payer_id: parseInt(this.$route.params.companyId),
      payments: JSON.stringify(payments),
    })
      .then((response) => {
        if (response.length === 0) {
          Notificator.success(t('order_success_pay'))
          this.fetchAssociatedOrders()
          this.fetched.orders.associated.input.ordersMap = Object.create(null)
          this.closeModalMasspay()
        } else {
          response.map((err) => {
            this.modal.masspay.validationMessages.ordersErrorCodes.push(err.code)
            this.modal.masspay.validationMessages.ordersErrorValid.push({
              ...err.messages,
            })
          })
        }
      })
      .catch(() => {
        Notificator.error(t('abstract_error_message'))
      })
      .finally(() => {
        this.modal.masspay.loading = false
      })
  },

  validationInputUser (sum) {
    const validationMessages = {
      ...this.modal.masspay.validationMessages,
      summaryInputUser: [],
    }

    if (sum < 0) {
      validationMessages.summaryInputUser = [PAYMENT_ERRORS.MESSAGES.SUM_NEGATIVE]
    } else if (this.paymentSpec === PAYMENT_SPECIES.BY_BALANCE) {
      if (sum > this.balance) {
        validationMessages.summaryInputUser = [PAYMENT_ERRORS.MESSAGES.SUM_LESS_BALANCE_MASSPAY]
      } else if (sum > this.sumToPay) {
        validationMessages.summaryInputUser = [PAYMENT_ERRORS.MESSAGES.SUM_BY_BALANCE_LESS_ORDER_SUM]
      }
    }

    this.modal.masspay.validationMessages = validationMessages
  },

  calcDifferenceSum (sum) {
    const difference = this.sumToPay - sum
    this.modal.masspay.deficitPay = difference.toString()
    this.modal.masspay.surplusPay = Math.abs(difference).toString()
  },

  alertClose () {
    this.modal.masspay.alert.visibility = false
    this.modal.masspay.validationMessages = {
      ...this.modal.masspay.validationMessages,
      ordersErrorCodes: [],
      ordersErrorValid: [],
    }
  },

  closeModalMasspay () {
    this.modal.masspay.visibility = false
    this.clearForm()
  },

  clearForm () {
    this.modal.masspay.form = { ...this.defaultForm() }
    this.modal.masspay.validationMessages = { ...this.defaultValidationForm() }
    this.modal.masspay.disabled = false
  },
}
