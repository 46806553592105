import { DEFAULT_MED_CASE_COMMENT, getDefaultDiagnosis } from '@/vue_components/egisz/egisz_module/const/egisz_entities'
import { getDate } from '@/helpers/vanilla_date'

const KEY = 'ambulatory'

export const egiszCasesSubmitAdapter = {
  toServer (medCase) {
    if (!medCase.id) {
      return {
        [KEY]: medCase,
      }
    }

    medCase.author_id = medCase.doctor_id
    medCase.authenticator_id = medCase.doctor_id
    medCase.netrika_history_number = medCase.client_id
    medCase.medical_document_ids = medCase.medical_documents.map((item) => item.id)

    const diagnosis = {
      ...medCase.diagnosis,
      doctor_id: medCase.doctor_id,
      clinic_id: medCase.clinic_id,
      date: Utils.getBaseFormattedDate(medCase.diagnosis.date),
    }
    delete medCase.diagnosis

    const steps = medCase.steps
    delete medCase.steps
    steps.forEach((step) => {
      delete step.entries_list

      if (typeof step.id === 'string') {
        delete step.id
      }

      // step.type нужны будут потом
      delete step.type
    })

    return {
      [KEY]: medCase,
      steps,
      diagnosis,
    }
  },
}

export const egiszCaseGetAdapter = {
  toClient (data) {
    data.steps = data.steps.map((item) => {
      item.opened_at = Utils.dateRubyToJS(item.opened_at)
      item.closed_at = Utils.dateRubyToJS(item.closed_at)

      if (item.entries_list) {
        item.entry_ids = item.entries_list.map((entry) => entry.id)
      }

      return item
    })

    if (data.diagnosis) {
      if (data.diagnosis.diagnosis_type_id) {
        data.diagnosis.diagnosis_type_id = data.diagnosis.diagnosis_type_id.toString()
      }

      if (data.diagnosis.date) {
        data.diagnosis.date = Utils.dateRubyToJS(data.diagnosis.date)
      }
    } else {
      data.diagnosis = getDefaultDiagnosis()
    }

    if (!data.comment) {
      data.comment = DEFAULT_MED_CASE_COMMENT
    }

    if (!data.opened_at) {
      data.opened_at = getDate()
    }

    return data
  },
}

const extractDates = (date) => {
  return [
    date.opened_at_formatted,
    date.closed_at_formatted,
  ].filter(Boolean)
    .join(' - ')
}

const extractTitle = (item) => {
  return [
    extractDates(item),
    item.doctor_full_name,
    t(item.netrika_status, { scope: 'egisz.common_statuses' }),
  ].filter(Boolean)
    .join(', ')
}

export const egiszCaseGetAllAdapter = {
  toServer (filters) {
    if (filters.opened_at && filters.opened_at.length) {
      filters.opened_at = [
        Utils.getBaseFormattedDate(filters.opened_at[0]),
        Utils.getBaseFormattedDate(filters.opened_at[1]),
      ]
    }

    if (filters.closed_at && filters.closed_at.length) {
      filters.closed_at = [
        Utils.getBaseFormattedDate(filters.closed_at[0]),
        Utils.getBaseFormattedDate(filters.closed_at[1]),
      ]
    }

    return filters
  },

  toClient (response) {
    const notFinishedText = t('egisz.components.med_card_med_case_list.table_body.active')

    response.data = response.data.map((item) => {
      item.closed_at_formatted = item.closed_at
        ? Utils.getFormattedDate(item.closed_at, Utils.shortDateFormat)
        : notFinishedText

      item.opened_at_formatted = item.opened_at
        ? Utils.getFormattedDate(item.opened_at, Utils.shortDateFormat)
        : notFinishedText

      item.title = extractTitle(item)

      return item
    })

    return response
  },
}
