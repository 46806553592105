<template>
  <div class="date-picker">
    <el-date-picker
      v-mask="'##.##.####'"
      type="date"
      v-bind="$attrs"
      :format="dateFormat"
      :picker-options="mergedPickerOptions"
      placement="bottom-start"
      :popper-class="popperClass"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'DatePicker',

  directives: { mask },

  props: {
    dateFormat: {
      type: String,
      default: Utils.elDateFormat,
    },

    pickerOptions: {
      type: Object,
      default: () => ({}),
    },

    popperClass: PropsTypes.String(),
  },

  data () {
    const firstDayOfWeek = this.$store
      ? this.$store.getters.GET_LOCALIZATION_DAYS_WEEK_START
      : gon.localization.day_week_start

    return {
      mergedPickerOptions: Object.assign(this.pickerOptions, { firstDayOfWeek }),
    }
  },
}
</script>
