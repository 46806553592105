import { createStore } from '@/vue_components/store/store'
import { FNS_CODES_DICT, NSI_TO_FNS_DICT } from '@/vue_present/Reuse/Lists/FNS/const'

export const getFNSByNSI = async (nsiDocumentId) => {
  const store = createStore()
  await store.dispatch('globalCatalogs/nsiStore/idCardTypesDictionaryStore/vxLoadNSIIdCardTypes')

  const nsiDocuments =
    store.state.globalCatalogs.nsiStore.idCardTypesDictionaryStore.idCardTypes || []

  const nsiCode = nsiDocuments
    .find((idCardType) => idCardType.id === nsiDocumentId)?.nsiId

  if (!nsiCode) { return }
  const id = NSI_TO_FNS_DICT[nsiCode]
  const title = FNS_CODES_DICT[id]

  if (!title) { return }

  return { id, title }
}
