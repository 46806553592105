import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { checkupFactorsAdapter } from '@/vue_apps/CheckupsModule/api/MCheckupFactorsPresenter/checkupFactorsAdapter'

export class MCheckupFactorsPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one () {}, all: Routes.sort_api_internal_checkups_factors_path, list: Routes.sort_api_internal_checkups_factors_path },
      entity: 'checkupFactor',
      location: 'MCheckupFactorsPresenter',
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toClientAdapter: checkupFactorsAdapter.toClient,
    })
  }
}
