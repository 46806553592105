import { ISemd230MetaData } from '@/vue_apps/Semds/entities/Semd230/ISemd230MetaData'
import { getDocumentRoute } from '@/vue_apps/CheckupsModule/router/routeHelpers'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { MSemdApi } from '@/vue_apps/Semds/entities/_SemdBase/MSemdApi'
import {
  ISemd230SemdsDocumentDetailsAttributesData,
} from '@/vue_apps/Semds/entities/Semd230/ISemd230SemdsDocumentDetailsAttributesData'
import { SEMD_TYPE } from '@/vue_apps/Semds/const/semdTypes'
import { Semd230MetaData } from '@/vue_apps/Semds/entities/Semd230/Semd230MetaData'
import { ISemdSource } from '@/vue_apps/Semds/interfaces/ISemdSource'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import {
  PersonalElmkMedicalAssessmentModel,
} from '@/vue_apps/CheckupsModule/classes/PersonalElmkMedicalAssessment/PersonalElmkMedicalAssessmentModel'

type TSemd230Source = PersonalElmkMedicalAssessmentModel | ISemdSource
type TSemd230SDDAData = ISemd230SemdsDocumentDetailsAttributesData

const getEmptyNsiProfessionDefault = (title) => ({ id: -1, title })

export class Semd230 extends MSemdApi<ISemd230MetaData, TSemd230SDDAData> {
  constructor ({ id }) {
    super({
      id,
      semdType: SEMD_TYPE.ELMK_MEDICAL_ASSESSMENT,
    })

    this.setValue('metaData', new Semd230MetaData())
  }

  fillBySource (source: TSemd230Source) {
    super.fillBySource(source as ISemdSource)

    const detailsData: TSemd230SDDAData = source.semdsDocumentDetailsAttributes.data

    this.setValue('client', detailsData?.client)
    this.setValue('clinic', detailsData?.clinic)
    this.setValue('date', detailsData?.date)

    this.setMetaDataValue('company', detailsData.company)
    this.setMetaDataValue('doctor', detailsData.responsible)
    this.setMetaDataValue(
      'nsiProfession',
      detailsData.nsiProfession || getEmptyNsiProfessionDefault((source as PersonalElmkMedicalAssessmentModel).position)
    )
    this.setMetaDataValue('department', detailsData.department)
  }

  protected setTitle (source: TSemd230Source) {
    const detailsData: TSemd230SDDAData = source.semdsDocumentDetailsAttributes.data

    const title = window.Utils.joinTruthy([
      window.t('semds.semd230.title'),
      `№ ${source.id}`,
      detailsData?.date && vueFilters.date(detailsData.date),
    ], ', ')

    this.setValue('title', title)
  }

  protected generateOwnerUrlAfterLoad () {
    this.ownerUrl = getDocumentRoute({
      documentId: this.owner.id,
      documentType: DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT,
    })
  }
}
