<template>
  <context-popup
    ref="contextPopup"
    v-click-outside="onClose"
    class="reusable-doctor-popup"
  >
    <reusable-doctors-list
      :value="user"
      :clearable="clearable"
      :current-clinic="currentClinic"
      :user-group="userGroup"
      @onDoctorChange="onDoctorChange"
    />
  </context-popup>
</template>

<script>
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import { USER_GROUP_IDS } from '@/_global_scripts/GLOBAL_CONSTS'

export default {
  name: 'ReusableDoctorPopup',

  components: { ReusableDoctorsList, ContextPopup },

  props: {
    clearable: Boolean,
    currentClinic: { type: [Boolean, Number, Array], default: false },
    userGroup: { type: [Array, Number], default: () => USER_GROUP_IDS.MEDICAL_GROUP },
  },

  emits: ['onDoctorChange'],

  data () {
    return {
      user: {},
    }
  },

  methods: {
    onOpen ({ clientX, clientY }, user = {}) {
      setTimeout(() => {
        this.user = user

        this.$refs.contextPopup.open(clientX, clientY)
      })
    },

    onClose () {
      this.$refs.contextPopup.close()
    },

    onDoctorChange (user) {
      this.$emit('onDoctorChange', user)

      this.onClose()
    },
  },
}
</script>
