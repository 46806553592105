import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import {
  personalSimpleMedicalAssessmentAdapter,
} from '@/vue_apps/CheckupsModule/api/PersonalSimpleMedicalAssessmentPresenter/personalSimpleMedicalAssessmentAdapter'

export class PersonalSimpleMedicalAssessmentPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.api_internal_checkups_documents_personals_simple_medical_assessment_path,
        all: Routes.api_internal_checkups_documents_personals_simple_medical_assessments_path,
        list () {},
      },
      entity: 'personalSimpleMedicalAssessment',
      location: 'PersonalSimpleMedicalAssessmentPresenter',
    })
  }

  fetch (id, config = {}) {
    return super.fetch(id, {
      toClientAdapter: personalSimpleMedicalAssessmentAdapter.toClient,
      ...config,
    })
  }

  create (personalCheckupId, config = {}) {
    return super.create({
      document: {
        personalCheckupId,
      },
    },
    {
      toJson: true,
      ...config,
    })
  }

  update (data, config = {}) {
    return super.update(data, {
      toServerAdapter: personalSimpleMedicalAssessmentAdapter.toServer,
      toJson: true,
      ...config,
    })
  }

  refresh (id) {
    if (!id) { return }

    return super.post({
      url: Routes.refresh_api_internal_checkups_documents_personals_simple_medical_assessment_path(id),
    })
      .promise
      .then(personalSimpleMedicalAssessmentAdapter.toClient)
  }
}
