<template>
  <m-panel
    class="flex-grow-1"
    :title="panelTitle"
    icon="doctors"
  >
    <m-si-generator
      :loading="loading"
      :items="clientsList.data"
      :si-generator-schema="siGeneratorSchema"
      :add-button-config="addButtonConfig"
      :page-count="clientsList.totalPages"
      :current-page="clientsList.currentPage"
      :search-query="clientsList.searchQuery"
      :search-min-length="1"
      :search-placeholder="t('full_name_or_phone_or_emk_or_card')"
      :nfr-filtered="clientsList.isFiltered"
      :nfr-can-reset-filters="false"
      use-context-menu
      @update:searchQuery="onSearchQueryChange"
      @update:currentPage="clientsList.setPage($event);"
      @onItemClick="onItemClick"
      @onItemEdit="onEditClick"
      @onAddItem="onAddClientClick"
      @onItemContextMenuClick="onItemContextMenuClick"
    >
      <template #filters>
        <clients-list-filters
          :filters="filters"
          @setFilter="onSetFilter"
          @filtersUpdated="fetchList"
        />
      </template>

      <template #contextMenu>
        <a
          v-if="lastContextClickedItem"
          :href="$routes.client_path(lastContextClickedItem.id)"
          target="_blank"
          class="flex align-center text-decoration-none"
        >
          <m-icon
            icon="link"
            color="primary"
            class="mr-5"
          />
          <span class="hover-underline">
            {{ t('open_new_tab') }}
          </span>
        </a>
      </template>
    </m-si-generator>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { ClientsList } from '@/vue_apps/ClientsModule/components/ClientsList/store/ClientsList'
import ClientsListFilters from '@/vue_apps/ClientsModule/components/ClientsList/components/ClientsListFilters.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { MClientPresenter } from '@/_api/MClient/MClientPresenter'
import { getClientsListSchema } from '@/vue_apps/ClientsModule/components/ClientsList/store/getClientsListSchema'
import { DEFAULT_FILTER_LETTER } from '@/vue_apps/ClientsModule/components/ClientsList/const/const'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'ClientsList',
  components: { MIcon, ClientsListFilters, MSiGenerator, MPanel },

  mixins: [SpinnerHolder],

  data () {
    return {
      clientsCount: null,
      clientsList: new ClientsList(),
      lastLetter: '',
      filtersTimeout: null,

      /** @type {TTableSchemaItem} */
      lastContextClickedItem: null,
    }
  },

  computed: {
    /** @return {TMSiGeneratorSchema} */
    siGeneratorSchema () {
      return getClientsListSchema()
    },

    /** @return {TAddButtonConfig}*/
    addButtonConfig () {
      return {
        canManage: 'Client',
        tooltip: t('add_client'),
        icon: 'client',
      }
    },

    panelTitle () {
      return this.clientsCount
        ? `${t('clients')} (${this.clientsCount})`
        : t('clients')
    },

    filters () {
      return {
        ...this.clientsList.filtersData,
        searchQuery: this.clientsList.searchQuery,
        offset: this.clientsList.offset,
        limit: this.clientsList.limit,
      }
    },
  },

  created () {
    this.__resetLetterFilter()
    this.fetchList()
    this.fetchClientsCount()
  },

  methods: {
    /**
     * Сброс фильтра буквы, если кэш пустой
     * @private
     */
    __resetLetterFilter () {
      this.lastLetter = this.clientsList.filters.letter || DEFAULT_FILTER_LETTER
      if (this.clientsList.filters.searchQuery || this.clientsList.filters.letter) { return }
      this.clientsList.setFilterValue('letter', DEFAULT_FILTER_LETTER)
    },

    /**
     * БЛ: Если буква, то сброс поиска
     * @param {{ field, value }} filterPayload
     */
    onSetFilter (filterPayload) {
      this.clientsList.searchQuery = ''
      this.clientsList.setFilterValue(filterPayload.field, filterPayload.value)

      if (filterPayload.field === 'letter') { this.lastLetter = filterPayload.value }
    },

    /**
     * БЛ: если поиск, то сброс буквы
     * @param {string} searchQuery
     */
    onSearchQueryChange (searchQuery) {
      this.clientsList.searchQuery = searchQuery
      this.clientsList.setFilterValue('letter', searchQuery ? '' : this.lastLetter)
    },

    async fetchList () {
      await this.withSpinner(this.clientsList.fetchAll())
      Services.telephony.reset()
    },

    async fetchClientsCount () {
      const { errors, count } = await new MClientPresenter().count()
      if (errors) { return }
      this.clientsCount = count
    },

    onItemClick ({ id }) {
      Turbolinks.visit(this.$routes.client_path(id))
    },

    onEditClick ({ id }) {
      Turbolinks.visit(this.$routes.edit_client_path(id))
    },

    onAddClientClick () {
      Turbolinks.visit(this.$routes.new_client_path())
    },

    /** @param {TTableSchemaItem} item */
    onItemContextMenuClick (item) {
      this.lastContextClickedItem = item
    },
  },
}
</script>
