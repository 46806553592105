<template>
  <div class="clients-feedback">
    <div
      v-if="moduleEnabled"
      class="clients-feedback__module-enabled"
    >
      <div class="clients-feedback__switches mb-25">
        <m-switch
          :value="clientsFeedbackSwitch"
          :active-text="t('whatsapp.clientsFeedback')"
          @change="setClientsFeedbackSwitch"
        />
      </div>

      <div
        v-if="clientsFeedbackSwitch"
        class="clients-feedback__settings"
      >
        <messages-priorities
          v-if="messagesPriorities.length"
          :messages-priorities="messagesPriorities"
          @updatePriorities="setMessagesPriorities"
        />

        <whatsapp-timeout-settings
          :delivery-waiting-time="deliveryWaitingTime"
          :read-waiting-time="readWaitingTime"
          :delivery-waiting-time-variables="BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES"
          :read-waiting-time-variables="BIRTHDAY_READ_WAITING_TIME_VARIABLES"
          @updateDeliveryWaitingTime="setClientsFeedbackDeliveredWaitingTime"
          @updateReadWaitingTime="setClientsFeedbackReadWaitingTime"
        />

        <div class="clients-feedback__clients-feedback-sms-template">
          <variables-editor
            :editor-value="smsTemplateValue"
            :editor-title="t('whatsapp.notificationTemplate')"
            :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
            :preview-request="fetchTemplatePreview"
            :variables-list="CLIENTS_FEEDBACK_VARIABLES_LIST_FIRST_MESSAGE"
            :required="smsTemplateValueValidation"
            validator-name="smsTemplate"
            template-type="clients_feedback"
            @registerValidator="onRegisterValidator"
            @changeEditorValue="setClientsFeedbackSmsTemplate"
          />
        </div>

        <variables-editor-whatsapp
          v-if="isWabaActive"
          :editor-value="wabaTemplateText"
          :variables-list="CLIENTS_FEEDBACK_VARIABLES_LIST_FIRST_MESSAGE"
          @changeWabaEditorValue="setWabaTemplateText"
        >
          <template #templateSelector>
            <m-select
              :value="wabaTemplateId"
              :items="wazzupTemplatesList"
              option-label="title"
              value-key="templateGuid"
              :placeholder="t('whatsapp.chooseValue')"
              @change="insertTemplate"
            />
          </template>
        </variables-editor-whatsapp>

        <between-text
          :title="t('whatsapp.dispatchTime')"
          :text-before="t('whatsapp.sendAfter')"
          :text-after="t('whatsapp.hoursAfterAppointment')"
        >
          <m-input
            :value="clientsFeedbackSendingDelay"
            :clearable="false"
            type="number"
            :precision="0"
            :min="MIN_CLIENTS_FEEDBACK_SENDING_DELAY_IN_HOURS"
            @input="setClientsFeedbackSendingDelay"
          />
        </between-text>

        <between-text
          :title="t('whatsapp.dispatchFrequency')"
          :text-before="t('whatsapp.sendNoMore')"
          :text-after="t('whatsapp.days')"
        >
          <m-input
            :value="clientsFeedbackSendingFrequency"
            :clearable="false"
            type="number"
            :precision="0"
            :min="MIN_CLIENTS_FEEDBACK_SENDING_FREQUENCY_IN_DAYS"
            @input="setClientsFeedbackSendingFrequency"
          />
        </between-text>

        <div class="clients-feedback__deny-multiple-messages mb-indent-validation">
          <div class="deny-multiple-messages__input">
            <m-checkbox
              :value="denySeveralMessages"
              :label="t('whatsapp.denyMultipleMessages')"
              @input="setDenySeveralMessages"
            />
          </div>
        </div>

        <hr>

        <aggregator-feedback-settings
          @registerTemplateValidator="onRegisterValidator"
          @registerInitialAggregatorWabaTemplateTextSetter="setInitialAggregatorWabaTemplateText = $event"
          @wabaTemplateTextChanged="aggregatorWabaTemplateText = $event"
        />

        <hr class="mt-0">
      </div>

      <m-button
        template="save"
        @click="saveForm"
      />
    </div>

    <introduction
      v-else
      :introduction-text="introductionText"
      icon="fad fa-comments"
    />
  </div>
</template>

<script>
import MessagesPriorities
  from '@/vue_apps/app_configuration/messages/pages/components/MessagesPriorities/MessagesPriorities.vue'
import WhatsappTimeoutSettings from '@/vue_apps/app_configuration/messages/pages/components/WhatsappTimeoutSettings.vue'
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import { variablesParserWithChild } from '@/vue_present/VariablesEditor/consts/variables'
import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MWhatsappConfigurationsPresenter } from '@/_api/Deliveries/MWhatsappConfigurationsPresenter'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import {
  CLIENTS_FEEDBACK_VARIABLES_LIST_FIRST_MESSAGE,
  CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import {
  BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES,
  BIRTHDAY_READ_WAITING_TIME_VARIABLES,
  MIN_CLIENTS_FEEDBACK_SENDING_DELAY_IN_HOURS,
  MIN_CLIENTS_FEEDBACK_SENDING_FREQUENCY_IN_DAYS,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import Introduction from '@/vue_components/introduction/introduction.vue'
import AggregatorFeedbackSettings
  from '@/vue_apps/app_configuration/messages/pages/components/AggregatorFeedbackSettings.vue'
import BetweenText from '@/vue_apps/app_configuration/messages/pages/components/BetweenText.vue'
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import {
  extractWabaTemplateText,
  findAndExtractWabaTemplateTextWithVariablesLabels,
  replaceVariablesLabelsByValues,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

const CURRENT_PRIORITY_TYPE = 'clients_feedback'

export default {
  name: 'ClientsFeedback',
  components: {
    Introduction,
    MCheckbox,
    MInput,
    MButton,
    MSelect,
    MSwitch,
    VariablesEditorWhatsapp,
    VariablesEditor,
    WhatsappTimeoutSettings,
    MessagesPriorities,
    AggregatorFeedbackSettings,
    BetweenText,
  },

  mixins: [ValidationParentMixin],

  props: {
    whatsappEnabled: Boolean,
    notificationsModuleEnabled: Boolean,
  },

  data () {
    return {
      wabaTemplateText: '',
      CLIENTS_FEEDBACK_VARIABLES_LIST_FIRST_MESSAGE,
      CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE,
      BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES,
      BIRTHDAY_READ_WAITING_TIME_VARIABLES,
      MIN_CLIENTS_FEEDBACK_SENDING_FREQUENCY_IN_DAYS,
      MIN_CLIENTS_FEEDBACK_SENDING_DELAY_IN_HOURS,
      fetchTemplatePreview,
      setInitialAggregatorWabaTemplateText () {},
      aggregatorWabaTemplateText: '',
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      clientsFeedbackSwitch: 'vxGetClientsFeedbackSwitch',
      clientsFeedbackSmsTemplate: 'vxGetClientsFeedbackSmsTemplate',
      clientsFeedbackSendingDelay: 'vxGetClientsFeedbackSendingDelay',
      clientsFeedbackSendingFrequency: 'vxGetClientsFeedbackSendingFrequency',

      aggregatorFeedbackSwitch: 'vxGetAggregatorFeedbackSwitch',
      aggregatorFeedbackRequiredScore: 'vxGetAggregatorFeedbackRequiredScore',
      aggregatorFeedbackRequiredTextLength: 'vxGetAggregatorFeedbackRequiredTextLength',
      aggregatorFeedbackSendingFrequency: 'vxGetAggregatorFeedbackSendingFrequency',
      aggregatorFeedbackTemplate: 'vxGetAggregatorFeedbackTemplate',

      messagesPriorities: 'vxGetMessagesPriorities',
      denySeveralMessages: 'vxGetDenySeveralMessages',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      clientsFeedbackWabaTemplateId: 'vxGetClientsFeedbackWabaTemplateId',
      aggregatorFeedbackWabaTemplateId: 'vxGetAggregatorFeedbackWabaTemplateId',
      aggregatorFeedbackWabaTemplateVariables: 'vxGetAggregatorFeedbackWabaTemplateVariables',
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      wabaTemplateVariables: 'vxGetClientsFeedbackWabaTemplateVariables',
      deliveryWaitingTime: 'vxGetClientsFeedbackDeliveredWaitingTime',
      readWaitingTime: 'vxGetClientsFeedbackReadWaitingTime',
      isWabaActive: 'vxIsWabaActive',
    }),

    moduleEnabled () {
      return this.notificationsModuleEnabled && gon.application.client_widget_enabled
    },

    smsTemplateValue () {
      return this.clientsFeedbackSmsTemplate
    },

    wabaTemplateId () {
      return { templateGuid: this.clientsFeedbackWabaTemplateId }
    },

    introductionText () {
      return this.notificationsModuleEnabled
        ? t('introduction.client_widget')
        : t('introduction.messages')
    },
  },

  created () {
    const baseRequest = this.vxFetchMessagesConfigurations()

    if (!this.whatsappEnabled) { return }

    const whatsappRequests = [
      this.fetchWazzupTemplatesList(),
      this.vxFetchWhatsappConfigurations(),
      this.vxFetchMessagesPriorities(CURRENT_PRIORITY_TYPE),
    ]

    Promise.all([baseRequest, ...whatsappRequests])
      .then(this.initWaba)
  },

  methods: {
    ...mapActions('messagesStore', {
      vxFetchMessagesConfigurations: 'vxFetchMessagesConfigurations',
      vxFetchMessagesPriorities: 'vxFetchMessagesPriorities',
    }),

    ...mapActions('messagesStore/whatsappConfigurationsStore', {
      fetchWazzupTemplatesList: 'vxFetchWazzupTemplatesList',
      vxFetchWhatsappConfigurations: 'vxFetchWhatsappConfigurations',
    }),

    ...mapMutations('messagesStore', {
      setClientsFeedbackSwitch: 'vxSetClientsFeedbackSwitch',
      setClientsFeedbackSmsTemplate: 'vxSetClientsFeedbackSmsTemplate',
      setClientsFeedbackSendingDelay: 'vxSetClientsFeedbackSendingDelay',
      setClientsFeedbackSendingFrequency: 'vxSetClientsFeedbackSendingFrequency',
      setMessagesPriorities: 'vxSetMessagesConfigurationsPriorities',
      setDenySeveralMessages: 'vxSetDenySeveralMessages',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setClientsFeedbackWabaTemplateId: 'vxSetClientsFeedbackWabaTemplateId',
      setClientsFeedbackReadWaitingTime: 'vxSetClientsFeedbackReadWaitingTime',
      setClientsFeedbackDeliveredWaitingTime: 'vxSetClientsFeedbackDeliveredWaitingTime',
    }),

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
    },

    initWaba () {
      if (!this.isWabaActive) { return }

      this.setInitialAggregatorWabaTemplateText()
      this.setInitialWabaTemplateText()
    },

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.clientsFeedbackWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.clientsFeedbackWabaTemplateId,
          templateVariablesValues: this.wabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setClientsFeedbackWabaTemplateId(template?.templateGuid)
    },

    smsTemplateValueValidation () {
      return this.smsTemplateValue
        ? this.smsTemplateValue.indexOf('client_feedback.feedback_url') === -1
          ? [t('errors.missingVariableClientFeedback')]
          : []
        : [t('errors.filled?')]
    },

    saveForm () {
      if (this.hasErrors()) { return }

      const whatsappEditorValueToServer = replaceVariablesLabelsByValues(this.wabaTemplateText || '')
      const whatsappEditorAggregatorValueToServer = replaceVariablesLabelsByValues(this.aggregatorWabaTemplateText || '')

      const messagesConfigurations = {
        clientsFeedback: this.clientsFeedbackSwitch,
        clientsFeedbackSendingDelay: this.clientsFeedbackSendingDelay,
        clientsFeedbackSendingFrequency: this.clientsFeedbackSendingFrequency,
        clientsFeedbackTemplate: this.smsTemplateValue,
        clientsFeedbackDenySeveralMessages: this.denySeveralMessages,

        aggregatorFeedback: this.aggregatorFeedbackSwitch,
        aggregatorFeedbackRequiredScore: this.aggregatorFeedbackRequiredScore,
        aggregatorFeedbackRequiredTextLength: this.aggregatorFeedbackRequiredTextLength,
        aggregatorFeedbackSendingFrequency: this.aggregatorFeedbackSendingFrequency,
        aggregatorFeedbackTemplate: this.aggregatorFeedbackTemplate,
      }
      const whatsappConfigurations = {
        clientsFeedbackWabaTemplateVariables: variablesParserWithChild(whatsappEditorValueToServer),
        clientsFeedbackWabaTemplateId: this.clientsFeedbackWabaTemplateId,
        clientsFeedbackDeliveredWaitingTime: this.deliveryWaitingTime,
        clientsFeedbackReadWaitingTime: this.readWaitingTime,

        aggregatorFeedbackWabaTemplateId: this.aggregatorFeedbackWabaTemplateId,
        aggregatorFeedbackWabaTemplateVariables: variablesParserWithChild(whatsappEditorAggregatorValueToServer),
      }
      const channelsPriorities = {
        priorityType: CURRENT_PRIORITY_TYPE,
        priorities: this.messagesPriorities,
      }

      const messagesConfigurationsPresenter = new MMessagesConfigurationPresenter()
      const whatsappConfigurationsPresenter = new MWhatsappConfigurationsPresenter()

      Promise.all([
        messagesConfigurationsPresenter.update(messagesConfigurations),
        messagesConfigurationsPresenter.savePriorities(channelsPriorities),
        whatsappConfigurationsPresenter.saveConfigurations(whatsappConfigurations),
      ])
    },
  },
}
</script>
