
export const MESSAGE_PROVIDERS = Object.freeze({
  SMS: 'sms',
})

export const propsForModalWindow = Object.freeze({
  messagesModuleDisabled: {
    icon: 'fa-comments',
    inner: t('introduction.messages'),
  },
  messagesModuleNoPermission: {
    icon: 'fa-comments',
    inner: t('introduction.module_connected_by_not_authorized'),
    title: t('no_permission'),
    requestButton: false,
  },
  telephonyModuleDisabled: {
    icon: 'fa-phone',
    inner: t('introduction.telephony'),
  },
  telephonyModuleNoPermission: {
    icon: 'fa-phone',
    inner: t('introduction.module_connected_by_not_authorized'),
    title: t('no_permission'),
    requestButton: false,
  },
})
