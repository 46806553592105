<template>
  <div class="medical-case-select-add">
    <m-select-lazy
      :fetch-method="fetchMedicalCases"
      :value="value"
      use-first-time-fetch
      :disabled="disabled"
      required
      validator-name="medical_case"
      :label="t('semds.semd119.formTitles.medicalCase')"
      @registerValidator="$registerValidator($event)"
      @change="onChange"
      @syncItemsStored="$emit('syncItemsStored', $event)"
      @firstTimeFetched="$emit('firstTimeFetched', $event)"
    />

    <!--    Добавить новый курс-->
    <m-button
      v-if="!disabled"
      v-tooltip="t('semds.semd119.formTitles.addMedicalCase')"
      plus-icon
      icon="file"
      type="success"
      @click="onAddMedicalCase"
    />
  </div>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { egiszCasesEndpoint } from '@/api_entities/egisz/cases/egisz_cases_endpoint'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { getDate } from '@/helpers/vanilla_date'
import { egiszCaseGetAllAdapter } from '@/api_entities/egisz/cases/egisz_cases_adapters'
import { reportSuccessText } from '@/_api/_requests/helpers'

export default {
  name: 'MedicalCaseSelectAdd',
  components: { MSelectLazy, MButton },

  props: {
    value: { type: Object, default: null },
    clientId: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
  },

  emits: [
    'change',
    'firstTimeFetched',
    'syncItemsStored',
  ],

  methods: {
    fetchMedicalCases () {
      return egiszCasesEndpoint.getAll({ client_id: this.clientId })
    },

    onChange (value) {
      this.$emit('change', value)
    },

    async onAddMedicalCase () {
      const medCaseData = {
        clinic_id: gon.application.current_clinic.id,
        client_id: this.clientId,
        doctor_id: gon.application.current_user.id,
        author_id: gon.application.current_user.id,
        opened_at: getDate(),
      }

      const result = await egiszCasesEndpoint.create(medCaseData)
      if (result?.errors) { return }

      Utils.reportSuccess(reportSuccessText('createMessage', 'medCase'))()

      const calculatedData = egiszCaseGetAllAdapter.toClient({ data: [result] })
      this.$emit('change', calculatedData.data[0])
    },
  },
}
</script>
