import { STORAGE_KEY, PAYMENT_SPECIES } from '../consts.js'
import { PAYMENT_ERRORS } from '@/lib/payments/consts.js'

export default {
  showWindowState: {
    deep: true,
    handler (newState) {
      const oldStore = window.store.get(STORAGE_KEY) || {}
      window.store.set(STORAGE_KEY, {
        ...oldStore,
        [this.currentStorage]: {
          ...oldStore[this.currentStorage],
          [this.$route.params.registryId]: {
            showWindow: { ...newState },
          },
        },
      })
    },
  },

  unassociatedOrdersReqParams () {
    this.fetchUnassociatedOrders()
  },

  associatedOrdersReqParams () {
    this.fetchAssociatedOrders()
  },

  'fetched.orders.unassociated.pagination.currentPage' () {
    this.slicedUnassociated()
  },

  'fetched.orders.associated.pagination.currentPage' () {
    this.slicedAssociated()
  },

  unassociatedOrdersFilter () {
    this.fetched.orders.unassociated.pagination.currentPage = 1
  },

  associatedOrdersFilter () {
    this.fetched.orders.associated.pagination.currentPage = 1
  },

  'modal.masspay.form.paymentSpec' (newValue) {
    this.modal.masspay.validationMessages = {
      ...this.modal.masspay.validationMessages,
      ...this.defaultValidationForm(),
    }

    this.validationInputUser(this.modal.masspay.form.summary)

    if (this.modal.masspay.form.paymentSpec.length < 1) {
      this.modal.masspay.validationMessages = {
        ...this.modal.masspay.validationMessages,
        paymentSpec: [PAYMENT_ERRORS.MESSAGES.SPECIES_MUST_DEFINE],
      }

      return
    }

    this.summaryInputUser = this.modal.masspay.form.summary

    if (this.paymentSpec === PAYMENT_SPECIES.BY_CASH) {
      this.surrender = this.deposit
    } else {
      this.deposit = this.deposit > 0 ? this.deposit : this.surrender
      this.surrender = 0
    }
  },

  summaryInputUser (newValue) {
    this.validationInputUser(newValue)
    this.calcDifferenceSum(newValue)
  },

  'modal.masspay.deficitPay' (newValue) {
    if (newValue > 0) {
      this.credit = newValue
    } else {
      this.credit = 0
    }
  },

  'modal.masspay.surplusPay' (newValue) {
    if (this.modal.masspay.deficitPay < 0) {
      if (this.paymentSpec === PAYMENT_SPECIES.BY_CASH) {
        this.surrender = newValue
      } else {
        this.deposit = newValue
      }
    } else {
      this.surrender = 0
      this.deposit = 0
    }
  },

  'modal.masspay.validationMessages': {
    deep: true,
    handler (newState) {
      this.modal.masspay.disabled = false

      if (
        newState.ordersErrorValid.length ||
        newState.ordersErrorCodes.length ||
        newState.paymentSpec.length ||
        newState.summaryInputUser.length
      ) {
        this.modal.masspay.disabled = true
      }
    },
  },

  'modal.masspay.validationMessages.ordersErrorValid' () {
    if (this.hasValidationOrderErrors) {
      this.modal.masspay.alert.visibility = true
    }
  },
}
