<template>
  <div class="company-final-act-view__footer">
    <div class="flex flex-column gap-indent-2">
      <span>{{ t$('footerTitle') }}</span>

      <span class="company-final-act-view__place-of-printing">
        {{ t('placeOfPrinting') }}
      </span>
    </div>

    <div class="flex flex-column">
      <span>____________</span>
      <span class="text-center">({{ t('signature') }})</span>
    </div>

    <div class="checkup-document-field_column">
      <document-field-writable
        :value="document.responsibleFullName"
        width="250px"
        text-center
        @input="document.setValue('responsibleFullName', $event)"
      />

      <span class="checkup-document-field__title text-center">
        {{ t$('transcriptSignature') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DocumentFieldWritable from '@/vue_present/DocumentBase/DocumentFieldWritable/DocumentFieldWritable.vue'
import { CompanyFinalActApi } from '@/vue_apps/CheckupsModule/classes/CompanyFinalAct/CompanyFinalActApi'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default defineComponent({
  name: 'CompanyFinalActViewFooter',

  components: { DocumentFieldWritable },

  mixins: [i18nScopeMixin],

  props: {
    document: CompanyFinalActApi,
  },

  created () {
    this.setI18nScope('checkups.documents.companyFinalAct')
  },

})
</script>
