import { STYLE_NUMBER_FORMATTER } from './consts'

export default class CurrencyFormatter {
  constructor (code = 'RUB', locale = 'ru-RU') {
    this.code = code
    this.locale = locale
  }

  get _formatter () {
    return this._formatterFn()
  }

  _formatterFn () {
    return new Intl.NumberFormat(this.locale, {
      style: STYLE_NUMBER_FORMATTER,
      currency: this.code,
    })
  }

  format (num) {
    return this._formatter.format(num).replace(',', '.')
  }
}
