import { DateHelpers } from '@/helpers/DateHelpers'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'
import {
  MAX_DATE_RANGE_IN_YEARS,
} from '@/vue_apps/Documents/FixedDocuments/_documents/TaxCertificate2024/consts/taxCertificate2024EditorConsts'

export const TaxCertificate2024EditorMainBlockValidationMixin = {
  methods: {
    /**
     * @param {string[]} period
     * @return {string[]}
     */
    periodValidator (period) {
      const errors = Utils.getValidationErrors(period)

      if (errors.length) { return errors }

      const minDate = moment(period.at(0))
      const maxDate = moment(period.at(1))

      const durationBetweenDatesInDays = moment
        .duration(maxDate.diff(minDate))
        .asDays()

      const oneYearInDays = moment
        .duration(GLOBAL_TIMES.ONE_YEAR)
        .asDays() - 1

      const leapDaysNumber = DateHelpers.getCountOfLeapDaysBetweenDates(minDate, maxDate)

      const yearsLimitExceeded =
        (durationBetweenDatesInDays - leapDaysNumber) > oneYearInDays

      if (yearsLimitExceeded) {
        return [
          t('errors.maxDateRange',
            {
              maxDateRange: `${MAX_DATE_RANGE_IN_YEARS} ${t('year')}`,
            }
          ),
        ]
      }

      return []
    },

  },
}
