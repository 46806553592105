<template>
  <m-modal
    class="personal-checkup-entries-modal"
    :visible="visible"
    modal-title-icon="service"
    :dialog-title="t('checkups.entries.modalTitle')"
    @close="$emit('close')"
  >
    <template #body>
      <m-checkbox
        :value="showOnlyMine"
        :label="t('checkups.entries.showOnlyMine')"
        border
        @input="onMineChange"
      />

      <m-si-generator
        :items="list.data"
        :current-page="list.currentPage"
        :page-count="list.totalPages"
        :si-generator-schema="msiSchema"
        :search-query="list.searchQuery"
        @update:currentPage="list.fetchPage($event)"
        @onSetSelectedItems="selectedItems = $event"
      />
    </template>

    <template #footer-left>
      <m-button
        template="save"
        :text="t('create')"
        :disabled="!selectedItems.length"
        @click="onCreate"
      />
    </template>
  </m-modal>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MListService } from '@/_api/_requests/MListService'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { extractId } from '@/_medods_standart_library/msl'
import { CheckupEntryPresenter } from '@/vue_apps/CheckupsModule/api/CheckupEntryPresenter/CheckupEntryPresenter'
import {
  getPersonalCheckupEntriesModalMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupEntries/personalCheckupEntriesMsiSchemas'
import {
  getPersonalCheckupEntriesModalDefaultFilters,
} from '@/vue_apps/CheckupsModule/store/personalCheckupEntries/personalCheckupEntriesFilters'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'

export default {
  name: 'PersonalCheckupEntriesModal',

  components: { MCheckbox, MButton, MSiGenerator, MModal },

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      msiSchema: getPersonalCheckupEntriesModalMsiSchema(),
      selectedItems: [],
      list: new MListService(
        getPersonalCheckupEntriesModalDefaultFilters(),
        new CheckupEntryPresenter()
      ),
      showOnlyMine: false,
    }
  },

  mounted () {
    this.list.setFilterValueWithFetchAll(
      'personalCheckupId',
      this.personalCheckup.id
    )
  },

  methods: {
    onCreate () {
      this.personalCheckup
        .checkupEntries
        .appendEntries(this.selectedItems.map(extractId))

      this.$emit('close')
    },

    onMineChange (value) {
      this.showOnlyMine = value

      const userId = this.showOnlyMine
        ? gon.application.current_user.id
        : undefined

      this.list.setFilterValueWithFetchAll('userId', userId)
    },
  },
}
</script>
