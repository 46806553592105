import { orUndefined } from '@/_medods_standart_library/msl'
import { CompanyCheckupListCellItem } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckupListCellItem'
import { pick } from 'lodash'
import { CHECKUP_STATUS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import {
  CompanyCheckupListTotalCell,
} from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckupListTotalCell'

export const companyCheckupAdapter = {
  /**
   * @param {ICompanyCheckup} cc
   */
  toServer (cc) {
    return {
      companyCheckup: {
        title: cc.title,
        status: cc.status || CHECKUP_STATUS.PLANNED,
        responsibleId: cc.responsible.id,
        companyId: cc.company.id,
        clinicId: orUndefined(cc.clinic?.id),
        plannedStartDate: cc.plannedStartDate,
        plannedEndDate: cc.plannedEndDate,

        id: orUndefined(cc.id),
        department: cc.department || '',
        agreementNumber: orUndefined(cc.agreementNumber),
        agreementStartDate: orUndefined(cc.agreementStartDate),
        agreementEndDate: orUndefined(cc.agreementEndDate),
        comment: cc.comment || '',
      },
    }
  },

  listFiltersToServer (filtersData) {
    return {
      ...pick(filtersData, 'offset', 'limit', 'searchQuery'),
      clinicId: orUndefined(filtersData.clinic?.id),
      status: orUndefined(filtersData.status),
      period: orUndefined(filtersData.period),
      companyId: orUndefined(filtersData.company?.id),
      responsibleId: orUndefined(filtersData.responsible?.id),
    }
  },

  toClient (response) {
    response.data = response.data.map((item) => new CompanyCheckupListCellItem(item))
    response.total = new CompanyCheckupListTotalCell({ count: response.totalItems, realWorkers: response.totalWorkers })

    return response
  },
}
