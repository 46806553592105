<template>
  <m-panel
    :title="checkupEntryType.title"
    :icon="panelCaption.icon"
    class="checkup-entry-types-editor"
  >
    <template v-if="kind === CHECKUP_ENTRY_TYPES_KINDS.TEST">
      <div class="prompt-notice">
        {{ t('checkups.checkupCatalogs.entryTypeDescription') }}
      </div>

      <m-textarea
        :value="checkupEntryType.text"
        @input="checkupEntryType.setValue('text', $event)"
      />
    </template>

    <m-panel
      body-css-classes="mb-indent-validation flex-grow-0"
      icon="clinics"
      :title="t('checkups.checkupCatalogs.cabinetsConnection')"
      class="flex-grow-0"
    >
      <template v-for="clinic in allowedClinics">
        <checkup-entry-type-clinic-cabinet
          v-if="checkupEntryType.clinicCabinetsMap[clinic.id]"
          :key="clinic.id"
          :clinic="clinic"
          :clinics-cabinets.sync="checkupEntryType.clinicCabinetsMap[clinic.id]"
          class="mb-indent-validation"
          @update:clinicsCabinets="$forceUpdate()"
        />
      </template>
    </m-panel>

    <div class="checkup-entry-types-editor__buttons">
      <m-button-submit
        disabled="CheckupsEntryType"
        @click="onSubmit"
      />
    </div>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { CheckupEntryType } from '@/vue_apps/catalogs_root/CheckupCatalogs/classes/CheckupEntryType'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { mapGetters } from 'vuex'
import CheckupEntryTypeClinicCabinet
  from '@/vue_apps/catalogs_root/CheckupCatalogs/components/CheckupEntryTypeClinicCabinet.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { CHECKUP_ENTRY_TYPES_KINDS } from '@/vue_apps/catalogs_root/CheckupCatalogs/const/checkupEntryTypesKinds'

export default {
  name: 'CheckupCatalogsListEditorPage',
  components: { MButtonSubmit, CheckupEntryTypeClinicCabinet, MTextarea, MPanel },

  props: {
    id: { type: Number, default: null },
    kind: { type: String, required: true },
  },

  data () {
    return {
      checkupEntryType: new CheckupEntryType({ }),
    }
  },

  computed: {
    CHECKUP_ENTRY_TYPES_KINDS () {
      return CHECKUP_ENTRY_TYPES_KINDS
    },

    ...mapGetters('globalCatalogs/doctorStore', {
      vxGetDoctorClinics: 'vxGetDoctorClinics',
    }),

    panelCaption () {
      return {
        title: t(this.kind, { scope: 'checkups.checkupCatalogs.caption.title' }),
        icon: this.kind === CHECKUP_ENTRY_TYPES_KINDS.TEST ? 'flask' : 'doctor',
      }
    },

    allowedClinics () {
      return this.vxGetDoctorClinics
    },
  },

  watch: {
    id: {
      immediate: true,
      handler (to) {
        this.fetchCheckupEntryType(to)
      },
    },
  },

  methods: {
    fetchCheckupEntryType (id) {
      this.checkupEntryType = new CheckupEntryType({ id })
      this.checkupEntryType.load(this.allowedClinics)
    },

    onSubmit () {
      if (!this.$security.canManageCheckupsEntryType) { return }
      this.checkupEntryType.save()
    },
  },
}
</script>
