<template>
  <div
    v-if="isWhatsappModuleEnabled"
    class="whatsapp-timeout-settings"
  >
    <div class="title title_unifying whatsapp-timeout-settings__title">
      {{ t('whatsapp.whatsappTimeoutSettingsTitle') }}
    </div>

    <between-text
      :text-before="t('deliveryWaitingTime', {scope: WHATSAPP_TIMEOUT_SETTINGS_SCOPE})"
      :explanation-icon-tooltip="t('deliveryWaitingTimeTooltip', {scope: WHATSAPP_TIMEOUT_SETTINGS_SCOPE})"
      use-text-before-as-title
      glue-elem-to-right
      remove-default-elem-styling
    >
      <m-select
        :value="deliveryWaitingTime"
        :items="deliveryWaitingTimeVariables"
        :clearable="false"
        use-custom-result="simple"
        @change="$emit('updateDeliveryWaitingTime', $event)"
      />
    </between-text>

    <between-text
      :text-before="t('readWaitingTime', {scope: WHATSAPP_TIMEOUT_SETTINGS_SCOPE})"
      :explanation-icon-tooltip="t('readWaitingTimeTooltip', {scope: WHATSAPP_TIMEOUT_SETTINGS_SCOPE})"
      use-text-before-as-title
      glue-elem-to-right
      remove-default-elem-styling
    >
      <m-select
        :value="readWaitingTime"
        :items="readWaitingTimeVariables"
        :clearable="false"
        use-custom-result="simple"
        @change="$emit('updateReadWaitingTime', $event)"
      />
    </between-text>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import BetweenText from './BetweenText.vue'
import { WHATSAPP_TIMEOUT_SETTINGS_SCOPE } from '@/vue_apps/app_configuration/messages/scripts/consts'

export default {
  name: 'WhatsappTimeoutSettings',
  components: {
    MSelect,
    BetweenText,
  },

  props: {
    deliveryWaitingTime: PropsTypes.String(),
    deliveryWaitingTimeVariables: PropsTypes.Array(),
    readWaitingTime: PropsTypes.String(),
    readWaitingTimeVariables: PropsTypes.Array(),
  },

  data () {
    return {
      WHATSAPP_TIMEOUT_SETTINGS_SCOPE,
    }
  },

  computed: {
    isWhatsappModuleEnabled () {
      return gon.application.whatsapp_module_enabled
    },
  },
}
</script>
