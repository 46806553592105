import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { DocumentOwnerItemCell } from '@/vue_apps/Documents/DocumentsOwners/DocumentOwnerItemCell'

export class MDocumentsOwnerPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.api_internal_documents_owner_path,
        all () {},
        list: Routes.sort_api_internal_documents_owners_path,
      },
      entity: 'documentsOwner',
      location: 'MDocumentsOwnerPresenter',
    })
  }

  list (data, config = {}) {
    return super.list(data, config)
      .then((list) => {
        list.data = (list.data || []).map((item) => new DocumentOwnerItemCell(item))

        return Promise.resolve(list)
      })
  }
}
