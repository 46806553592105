<template>
  <div class="expenses-filters flex flex-wrap gap-indent-small">
    <!-- Дата-кнопки -->
    <m-date-picker-buttons
      :value="filters.date"
      :m-fixed-height="false"
      :use-only="useOnly"
      value-format="yyyy-MM-dd"
      @change="onFilterChange('date', $event)"
    />

    <!-- Дата-пикер -->
    <m-date-picker
      v-if="usePicker"
      :value="filters.date"
      type="daterange"
      value-format="yyyy-MM-dd"
      :m-fixed-height="false"
      @change="onFilterChange('date', $event)"
    />

    <!--    Источники затрат -->
    <m-select
      :value="filters.source"
      :items="expensesSources"
      :placeholder="t('finance.allExpensesSources')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('source', $event)"
    />

    <!--    Группы расходов -->
    <m-select
      :value="filters.expenseGroupIds"
      :items="groups"
      :placeholder="t('finance.allExpensesGroups')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('expenseGroupIds', $event)"
    />

    <!--    Типы затрат -->
    <m-select
      :value="filters.category"
      :items="expensesCategories"
      :placeholder="t('finance.allExpensesTypes')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('category', $event)"
    />

    <!--    Товары -->
    <m-select
      :value="filters.consumable"
      :items="expensesConsumables"
      :placeholder="t('finance.allConsumables')"
      :m-fixed-height="false"
      use-custom-result="simple"
      @change="onFilterChange('consumable', $event)"
    />

    <!-- Кнопки обновления и сброса -->
    <m-si-generator-filters-buttons
      @onRefreshClick="refreshData"
      @onResetClick="resetData"
    />
  </div>
</template>

<script>
import MDatePickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import { MListService } from '@/_api/_requests/MListService'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MSiGeneratorFiltersButtons
  from '@/vue_present/_base/Tables/MSiGenerator/components/MSiGeneratorFiltersButtons.vue'
import {
  EXPENSES_CONSUMABLES,
  getExpensesCategoriesList,
  getExpensesSourcesList,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/scripts/expensesFiltersConsts'

export default {
  name: 'ExpensesFilters',
  components: { MSiGeneratorFiltersButtons, MSelect, MDatePicker, MDatePickerButtons },

  props: {
    list: MListService,
    groups: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      expensesSources: getExpensesSourcesList(),
      expensesCategories: getExpensesCategoriesList(),
      expensesConsumables: EXPENSES_CONSUMABLES,
    }
  },

  computed: {
    filters () {
      return this.list.filtersData
    },

    useOnly () {
      return gon.application.security.current_user_permissions.Finance
    },

    usePicker () {
      return gon.application.security.current_user_permissions.Finance
        .includes('Period')
    },
  },

  methods: {
    refreshData () {
      this.list.refreshFilters()
      this.list.fetchAll()
    },

    resetData () {
      this.list.resetFilters()
      this.list.fetchAll()
    },

    onFilterChange (name, value) {
      this.list.setFilterValue(name, value)
      this.list.fetchAll()
    },
  },
}
</script>
