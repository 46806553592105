import formatter from '@/lib/formatters/formatter'

export const clientSearchAdapter = {
  toClient (response) {
    response.data = (response.data || []).map((client) => ({
      ...client,
      shortInfo: formatter.formatValue(client, ['client:shortInfo']),
    }))

    return response
  },
}
