export const getFastCreateModalFieldItems = () => [
  { id: 'sex', title: t('sex') },
  { id: `${getPositionField()}`, title: t('checkups.fastCreate.position') },
  { id: 'factors', title: t('checkups.fastCreate.factors') },
  { id: 'date', title: t('checkups.fastCreate.checkupDate') },
  { id: 'nextDate', title: t('checkups.fastCreate.nextCheckup') },
]

const getPositionField = () => gon.application.egisz_module_enabled
  ? 'nsiProfession'
  : 'position'
