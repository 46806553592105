<template>
  <div class="flex flex-column">
    <!-- Телефон 1-->
    <m-phone-input
      :label="t('phone1')"
      class="mt-6"
      disable-hidden-mask
      :value="phone1"
      validator-name="phone1"
      @registerValidator="$registerValidator($event)"
      @input="$updateSync('phone1', $event)"
    />

    <!-- Телефон 2-->
    <m-phone-input
      :label="t('phone2')"
      class="mt-6"
      disable-hidden-mask
      :value="phone2"
      validator-name="phone2"
      @registerValidator="$registerValidator($event)"
      @input="$updateSync('phone2', $event)"
    />

    <!-- WEB -->
    <m-input
      :label="t('web')"
      :value="web"
      @input="$updateSync('web', $event)"
    />

    <!-- Email -->
    <m-input
      :label="t('email')"
      class="pb-0"
      :value="email"
      @input="$updateSync('email', $event)"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'

export default {
  name: 'CompanyContacts',
  components: { MPhoneInput, MInput },
  props: {
    phone1: PropsTypes.String(),
    phone2: PropsTypes.String(),
    web: PropsTypes.String(),
    email: PropsTypes.String(),
  },
}
</script>
