<template>
  <filter-base :label="label">
    <date-range-picker
      class="filter__input"
      :date-range="dateRange"
      :format="format"
      :fallback-value="fallBackValue"
      @input="$emit('input', $event)"
    />
  </filter-base>
</template>

<script>
import FilterBase from '@/vue_components/egisz/egisz_module/components/filters/FilterBase'
import DateRangePicker from '@/vue_components/common/select/date_range_picker'

export default {
  name: 'DateRangeFilter',
  components: { DateRangePicker, FilterBase },
  props: {
    label: {
      type: String,
      required: true,
    },

    dateRange: {
      type: Array,
      default: null,
    },

    format: {
      type: String,
      required: true,
    },

    fallBackValue: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
