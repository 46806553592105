
export const CHECKUP_KINDS = Object.freeze({
  PRIOR: 'prior',
  PERIODIC: 'periodic',
})

export const CHECKUP_KINDS_ICONS = Object.freeze({
  [CHECKUP_KINDS.PRIOR]: 'priorCheckup',
  [CHECKUP_KINDS.PERIODIC]: 'periodicCheckup',
})
