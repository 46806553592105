import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getGuaranteeLettersSchema = (useSum = false) => {
  const schema = new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addHeaders({
          issueDate: new MTableHeader(t('common.date')).addFixedWidth('100px'),
          number: new MTableHeader(t('common.number')),
        })
        .addDeleteIcon()
    )

  if (useSum) {
    schema.tableSchema.appendHeaders({
      sumCell: new MTableHeader(t('sum')).addFixedWidth('100px'),
    })
  }

  return schema
}
