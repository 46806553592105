<template>
  <div class="sidebar-filters pt-6">
    <!-- Период -->
    <div class="sidebar__filter sidebar__filter_period mb-indent-small">
      <m-date-picker
        v-model="filters.period"
        type="daterange"
        :m-fixed-height="false"
        :label="t('egisz.components.egisz_med_records.filters.period')"
        value-format="yyyy-MM-dd"
        @change="refreshFilters"
      />
    </div>

    <!-- Врач -->
    <div class="sidebar__filter mb-indent-small">
      <reusable-doctors-list
        v-model="filters.doctor"
        :m-fixed-height="false"
        :placeholder="t('all_doctors')"
        full-width
        @onDoctorChange="refreshFilters"
      />
    </div>

    <!-- Специальность -->
    <div class="sidebar__filter mb-indent-small">
      <catalog-tree-modal
        id="nsi_specialty_id"
        ref="tree"
        class="filter__input"
        :tree-data="getSpecialties"
        :value.sync="filters.nsiSpecialityId"
        :placeholder="t('egisz.components.egisz_med_records.filters.specialty')"
        clearable
        @update:value="refreshFilters"
      />
    </div>

    <!-- Тип сэмда -->
    <div class="sidebar__filter mb-indent-small">
      <m-select
        v-model="filters.semdType"
        full-width
        :items="semdTypes"
        :label="t('semds.semdTypeLabel')"
        :m-fixed-height="false"
        :placeholder="t('all_kinds')"
        use-icon
        @change="refreshFilters"
      />
    </div>

    <!-- Статус -->
    <div class="sidebar__filter mb-indent-small">
      <m-select
        v-model="filters.status"
        full-width
        :items="semdStatuses"
        :label="t('status')"
        :m-fixed-height="false"
        :placeholder="t('all_statuses')"
        use-icon
        @change="refreshFilters"
      />
    </div>

    <egisz-table-filters
      :loading="loading"
      @refresh="refreshFilters"
      @reset="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import CatalogTreeModal from '@/vue_components/egisz/egisz_user_tab/components/CatalogTreeModal.vue'
import { mapActions, mapGetters } from 'vuex'
import ReusableDoctorsList from '@/vue_present/Reuse/Lists/Doctors/ReusableDoctorsList.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { getDefaultFilters } from '@/vue_components/egisz/egisz_module/components/EgiszSemds/getDefaultFilters'
import EgiszTableFilters from '@/vue_components/egisz/egisz_module/components/egisz_table_fitlers.vue'
import { cloneDeep } from 'lodash'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { GLOBAL_DOUBLE_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import { getSemdStates } from '@/vue_apps/Semds/SemdModal/const/const.js'
import { orUndefined } from '@/_medods_standart_library/msl'
import { SemdsUtils } from '@/vue_apps/Semds/entities/SemdsUtils'

export default defineComponent({
  name: 'EgiszSemdsFilters',
  components: {
    EgiszTableFilters,
    MSelect,
    ReusableDoctorsList,
    CatalogTreeModal,
    MDatePicker,
  },

  mixins: [SpinnerHolder],

  props: {
    additionalFilters: { type: Object, default: () => ({}) },
  },

  data () {
    return {
      filters: getDefaultFilters(),
      semdStatuses: getSemdStates(),
      semdTypes: SemdsUtils.getSemdTypesList(),
    }
  },

  computed: {
    ...mapGetters('medCases', {
      getSpecialties: 'GET_SPECIALTIES',
    }),
  },

  created () {
    this.loadCatalogs()
    setTimeout(() => {
      this.resetFilters()
    })

    this.$pubSub.subscribe('egiszSemdsResetFilters', () => {
      this.resetFilters()
    })
  },

  beforeDestroy () {
    this.$pubSub.unsubscribeAll('egiszSemdsResetFilters')
  },

  methods: {
    ...mapActions('medCases', {
      loadCatalogs: 'loadCatalogs',
    }),

    refreshFilters () {
      this.filters = cloneDeep(this.filters)
      this.sendUpdateSignal()
    },

    resetFilters () {
      this.filters = getDefaultFilters()
      this.sendUpdateSignal()
    },

    sendUpdateSignal () {
      const promise = new Promise((resolve) => {
        this.$pubSub.emit('egiszSemdsUpdateFilters', {
          ...this.additionalFilters,
          period: orUndefined(this.filters.period),
          nsiSpecialityIds: this.filters.nsiSpecialityId ? [this.filters.nsiSpecialityId] : undefined,
          doctorIds: this.filters.doctor ? [this.filters.doctor.id] : undefined,
          states: this.filters.status ? [this.filters.status.id] : undefined,
          semdTypes: this.filters.semdType ? [this.filters.semdType.id] : undefined,
        })
        resolve()
      })

      this.$nextTick(() => {
        this.withSpinner(promise, undefined, GLOBAL_DOUBLE_DEBOUNCE_DEFAULT)
      })
    },
  },
})
</script>
