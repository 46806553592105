<template>
  <m-panel
    class="personal-checkup-conclusion"
    :title="t('checkups.personal.conclusion.title')"
  >
    <div class="personal-checkup-conclusion__left-side">
      <!-- Заключение профпатолога -->
      <m-select
        :value="personalCheckup.medicalAssessment"
        :items="medicalAssessmentItems"
        :label="t('checkups.personal.conclusion.conclusion')"
        :disabled="isShowMode"
        :placeholder="t('enums.unselected.it')"
        @change="personalCheckup.setValue('medicalAssessment', $event)"
      />

      <m-si-generator
        :items="doctorResultsItems"
        :si-generator-schema="doctorResultsMsiSchema"
        :add-button-config="doctorResultsAddButtonConfig"
        @onAddItem="showDoctorResultModal = true"
        @onItemDelete="personalCheckup.doctorResults.destroy($event.id)"
      />

      <personal-checkup-doctor-results-modal
        :visible="showDoctorResultModal"
        :personal-checkup="personalCheckup"
        :is-new-mode="isNewMode"
        :is-show-mode="isShowMode"
        :is-edit-mode="isEditMode"
        @close="showDoctorResultModal = false"
      />
    </div>

    <div class="personal-checkup-conclusion__right-side">
      <!-- Противопоказания -->
      <m-select
        :value="personalCheckup.contraindicationsFactors"
        :items="personalCheckup.factors"
        :label="t('checkups.personal.conclusion.factors')"
        :disabled="isShowMode"
        :placeholder="t('enums.unselected.they')"
        multiple
        @change="personalCheckup.setValue('contraindicationsFactors', $event)"
      />

      <!--      Рекоммендации      -->
      <m-select
        :value="personalCheckup.recommendations"
        :items="vxGetRecommendations"
        :label="t('checkups.personal.conclusion.recommendations')"
        :placeholder="t('checkups.personal.recommendations')[0]"
        :disabled="isShowMode"
        multiple
        clearable
        @change="personalCheckup.setValue('recommendations', $event)"
      />

      <!--      Группа здоровья     -->
      <m-select
        :value="personalCheckup.healthGroup"
        :label="t('checkups.personal.conclusion.healthGroup')"
        :items="healthGroups"
        :disabled="isShowMode"
        :placeholder="t('enums.unselected.she')"
        use-custom-result="simple"
        @change="personalCheckup.setValue('healthGroup', $event)"
      />

      <m-si-generator
        :items="diagnosesPersonalItems"
        :si-generator-schema="diseasesPersonalMsiSchema"
        :add-button-config="diseasesPersonalAddButtonConfig"
        @onAddItem="showDiseasesPersonalModal = true"
        @onItemDelete="personalCheckup.diagnosesPersonal.destroy($event.id)"
      />

      <personal-checkup-diseases-modal
        v-if="showDiseasesPersonalModal"
        :visible="showDiseasesPersonalModal"
        :personal-checkup="personalCheckup"
        :is-new-mode="isNewMode"
        :is-show-mode="isShowMode"
        :is-edit-mode="isEditMode"
        @close="showDiseasesPersonalModal = false"
      />
    </div>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { getHealthGroups } from '@/vue_apps/CheckupsModule/store/healthGroups'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { mapActions, mapGetters } from 'vuex'
import {
  getPersonalCheckupDoctorResultsAddButtonConfig,
  getPersonalCheckupDoctorResultsMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDoctorResults/personalCheckupDoctorResultsConst'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import PersonalCheckupDoctorResultsModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupDoctorResultsModal.vue'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import PersonalCheckupDiseasesModal
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupDiseasesPersonalModal.vue'
import {
  getPersonalCheckupDiseasesPersonalAddButtonConfig,
  getPersonalCheckupDiseasesPersonalMsiSchema,
} from '@/vue_apps/CheckupsModule/store/personalCheckupDiseasesPersonal/personalCheckupDiseasesPersonalConst'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'

export default {
  name: 'PersonalCheckupConclusion',

  components: {
    PersonalCheckupDiseasesModal,
    PersonalCheckupDoctorResultsModal,
    MSiGenerator,
    MSelect,
    MPanel,
  },

  mixins: [
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
  ],

  props: {
    personalCheckup: PersonalCheckupApi,
  },

  data () {
    return {
      medicalAssessmentItems: [],
      healthGroups: getHealthGroups(),

      showDoctorResultModal: false,
      doctorResultsMsiSchema: getPersonalCheckupDoctorResultsMsiSchema({ addCreateButton: true }),

      showDiseasesPersonalModal: false,
      diseasesPersonalMsiSchema: getPersonalCheckupDiseasesPersonalMsiSchema({ addCreateButton: true }),
      diseasesPersonalAddButtonConfig: getPersonalCheckupDiseasesPersonalAddButtonConfig(),
    }
  },

  computed: {
    ...mapGetters('checkupsStore', {
      vxGetRecommendations: 'vxGetRecommendations',
    }),

    doctorResultsItems () {
      return this.personalCheckup
        .doctorResults
        .getArr()
    },

    diagnosesPersonalItems () {
      return this.personalCheckup.getDiagnosesPersonalArr()
    },

    doctorResultsAddButtonConfig () {
      return getPersonalCheckupDoctorResultsAddButtonConfig(this.hasSpecialFactors)
    },
  },

  created () {
    this.vxFetchRecommendations()
    this.fetchMedicalAssessmentItems()
  },

  methods: {
    ...mapActions('checkupsStore', {
      vxFetchRecommendations: 'vxFetchRecommendations',
    }),

    async fetchMedicalAssessmentItems () {
      this.medicalAssessmentItems = await this.personalCheckup
        ._presenter
        .fetchPathologistMedicalAssessments()
    },
  },
}
</script>
