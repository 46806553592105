<template>
  <div class="payment-masspay-form">
    <epic-spinner
      :visibility="loading"
    />

    <flex-form
      :fields="flexForms.summary"
    >
      <template #customer-value>
        {{ customer }}
      </template>

      <template #access-balance-value>
        {{ balance | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
      </template>

      <template #summary-value>
        {{ sumToPay | formatNumberToFixed(GET_LOCALIZATION_CURRENCY_PARAMS) }}
      </template>
    </flex-form>
    <flex-form
      :fields="flexForms.actions"
    >
      <template #payment-species-value>
        <validation-wrapper
          :errors="validationMessages.paymentSpec"
        >
          <items-list
            id-item-field="key"
            displayed-item-field="title"
            :items="paymentSpecies"
            :min-selected-items="1"
            :multi-select="false"
            :selected-items-ids="paymentSpec"
            :disabled="loading"
            :class="['company-types-list', {loading: loading}]"
            @change="update('paymentSpec', $event)"
          />
        </validation-wrapper>
      </template>

      <template #pay-in-value>
        <validation-wrapper
          :errors="validationMessages.summaryInputUser"
        >
          <currency-input
            :value="summaryInputUser"
            icon="fad fa-money-bill"
            icon-right="fad fa-reply reply-button"
            @right-btn-handler="handleReplySum('summaryInputUser', sumToPay)"
            @input="update('summaryInputUser', $event)"
          />
        </validation-wrapper>
      </template>

      <template #credit-value>
        <currency-input
          :value="credit"
          disabled
          icon="fad fa-money-check-alt"
        />
      </template>
      <template #surrender-value>
        <currency-input
          :value="surrender"
          disabled
          icon="fad fa-hand-holding-usd"
          :icon-right="surrenderReply"
          @right-btn-handler="handleReplySum('surrender', deposit)"
        />
      </template>

      <template #to-balance-value>
        <currency-input
          :value="deposit"
          disabled
          icon="fad fa-user-plus"
          :icon-right="depositReply"
          @right-btn-handler="handleReplySum('deposit', surrender)"
        />
      </template>
      <template #comment-value>
        <el-input
          type="textarea"
          :rows="2"
          :placeholder="T.comment"
          :value="comment"
          @input="update('comment', $event)"
        />
      </template>
    </flex-form>
  </div>
</template>

<script>
import CurrencyInput from '@/vue_components/common/currency_input.vue'
import EpicSpinner from '@/vue_components/epic_spinner/epic_spinner.vue'
import FlexForm from '@/vue_components/common/flex_form.vue'
import ItemsList from '@/vue_components/items_list.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

import { mapGetters } from 'vuex'
import { PAYMENT_SPECIES } from './consts.js'
import { currencyFormatter } from '@/vue_components/mixins/formatters.js'

export default {
  name: 'PaymentsMasspayForm',
  components: {
    CurrencyInput,
    EpicSpinner,
    FlexForm,
    ItemsList,
    ValidationWrapper,
  },

  mixins: [currencyFormatter],

  props: {
    paymentSpec: {
      type: Array,
      required: true,
    },
    customer: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    summaryInputUser: {
      type: Number,
      required: true,
    },
    sumToPay: {
      type: Number,
      required: true,
    },
    credit: {
      type: Number,
      required: true,
    },
    surrender: {
      type: Number,
      required: true,
    },
    deposit: {
      type: Number,
      required: true,
    },
    comment: {
      type: String,
      default: '',
    },
    validationMessages: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    handleReplySum: {
      type: Function,
      default () {},
    },
  },

  data: () => ({
    paymentSpecies: PAYMENT_SPECIES,
    surrenderReply: '',
    depositReply: '',
    flexForms: {
      summary: Object.freeze([
        { name: 'customer', label: t('cuctomer_signature') },
        { name: 'access-balance', label: t('client_finance') },
        { name: 'summary', label: t('to_pay') },
      ]),
      actions: Object.freeze([
        { name: 'payment-species', label: t('payment_species') },
        { name: 'pay-in', label: t('sum_price') },
        { name: 'credit', label: t('by_credit') },
        { name: 'surrender', label: t('surrender') },
        { name: 'to-balance', label: t('_payment_species.to_balbance') },
        { name: 'comment', label: t('comment') },
      ]),
    },
  }),

  computed: {
    ...mapGetters(['GET_LOCALIZATION_CURRENCY_PARAMS']),
  },

  watch: {
    paymentSpec () {
      if (this.deposit > 0 && this.paymentSpec[0] === 'by_cash') {
        this.surrenderReply = 'fad fa-reply reply-button'
      } else {
        this.surrenderReply = ''
      }

      if (this.surrender > 0) {
        this.depositReply = 'fad fa-reply reply-button'
      } else {
        this.depositReply = ''
      }
    },
  },

  methods: {
    update (prop, value) {
      this.$emit(`update:${prop}`, value)
    },
  },
}
</script>
