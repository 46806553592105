import Intro from '@/vue_components/egisz/egisz_module/pages/Intro'
import {
  BASE,
  CASES,
  CLIENTS,
  EGISZ_MED_DOCUMENTS,
  EGISZ_SEMDS,
  INTRO,
} from '@/vue_components/egisz/egisz_module/router/route_names'
import EgiszClients from '@/vue_components/egisz/egisz_module/pages/EgiszClients'
import EgiszCases from '@/vue_components/egisz/egisz_module/pages/EgiszCases'
import EgiszClientsControls from '@/vue_components/egisz/egisz_module/components/EgiszClients/EgiszClientsFilters'
import EgiszCasesFilters from '@/vue_components/egisz/egisz_module/components/EgiszCases/EgiszCasesFilters'
import EgiszCase from '@/vue_components/egisz/egisz_medical_cases_tab/components/EgiszCase'
import EgiszStandAloneMedRecords from '@/vue_components/egisz/egisz_module/pages/EgiszMedDocuments'
import EgiszStandAloneMedRecordsFilters
  from '@/vue_components/egisz/egisz_module/components/EgiszMedDocuments/EgiszMedDocumentsFilters'
import EgiszSemds from '@/vue_components/egisz/egisz_module/pages/EgiszSemds.vue'
import EgiszSemdsFilters from '@/vue_components/egisz/egisz_module/components/EgiszSemds/EgiszSemdsFilters.vue'

/**
 * meta: { menuItem: MENU_ITEM_NAME } даст понять навигации, какой элемент активный и подсветит его
 */
export default [
  {
    path: BASE + '/clients',
    name: CLIENTS.TABLE,
    components: {
      default: EgiszClients,
      filters: EgiszClientsControls,
    },
    meta: {
      menuItem: CLIENTS.INDEX,
      title: t('clients'),
      icon: 'fad fa-fw fa-user',
    },
  },

  {
    path: BASE + '/medical_cases',
    name: CASES.TABLE,
    components: {
      default: EgiszCases,
      filters: EgiszCasesFilters,
    },
    meta: {
      menuItem: CASES.INDEX,
      title: t('egisz.medical_cases.title'),
      icon: 'fad fa-fw fa-notes-medical',
    },
  },

  {
    path: BASE + '/medical_cases/new',
    name: CASES.NEW,
    component: EgiszCase,
    meta: {
      menuItem: CASES.INDEX,
      title: t('egisz.medical_cases.new'),
      icon: 'fad fa-fw fa-notes-medical',
      disableFilters: true,
    },
  },

  {
    path: BASE + '/medical_cases/:caseId/edit',
    name: CASES.EDIT,
    component: EgiszCase,
    props: true,
    meta: {
      menuItem: CASES.INDEX,
      title: t('egisz.medical_cases.edit'),
      icon: 'fad fa-fw fa-notes-medical',
      disableFilters: true,
    },
  },

  {
    path: BASE + '/medical_documents',
    name: EGISZ_MED_DOCUMENTS.TABLE,
    components: {
      default: EgiszStandAloneMedRecords,
      filters: EgiszStandAloneMedRecordsFilters,
    },
    meta: {
      menuItem: EGISZ_MED_DOCUMENTS.INDEX,
      title: t('egisz.med_records.title'),
      icon: 'fad fa-fw fa-files-medical',
    },
  },

  {
    path: BASE + '/semds',
    name: EGISZ_SEMDS.TABLE,
    components: {
      default: EgiszSemds,
      filters: EgiszSemdsFilters,
    },
    meta: {
      menuItem: EGISZ_SEMDS.INDEX,
      title: t('egisz.semds.title'),
      icon: 'fad fa-fw fa-files-medical',
    },
  },

  {
    path: BASE + '*',
    name: INTRO,
    component: Intro,
  },
]
