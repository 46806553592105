<template>
  <input
    :value="value"
    type="text"
    class="v-search-input"
    v-bind="$attrs"
    @keyup="search"
  >
</template>

<script>
const VALUE_MIN_LENGTH = 3

export default {
  name: 'SearchInput',
  props: {
    debounce: {
      type: Number,
      default: 400,
    },

    value: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      timeout: null,
    }
  },

  methods: {
    search ({ target }) {
      if (this.debounce > 0) {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
          if (target.value && target.value.length < VALUE_MIN_LENGTH) { return }
          this.$emit('update:value', target.value)
        }, this.debounce)
      } else {
        this.$emit('update:input', target.value)
      }
    },
  },
}
</script>
