import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

const $t = (variable) => {
  return t(variable, { scope: 'finance.ordersTableTitles' })
}

export const getOrdersSchema = () => {
  return new MSiSchema()
    .addSearch()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addFooter()
        .addEditIcon(Services.security.canManageOrder)
        .addHeaders({
          number: new MTableHeader($t('number')).addFixedWidth('80px'),
          date: new MTableHeader($t('date')).addFixedWidth('100px'),
          status: new MTableHeader()
            .addTypeIcon('ready')
            .addFixedWidth('40px')
            .addTooltip($t('status')),
          client: new MTableHeader($t('client')),
          finalSum: new MTableHeader($t('toPay')),
          paidSum: new MTableHeader(`${$t('paid')}*`).addTooltip(t('payments_by_order_without_credit')),
        })
    )
}
