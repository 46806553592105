import { ItemBase } from '@/_api/_classes/ItemBase'
import { IMedicalPolicy } from '@/_declarations/IMedicalPolicy'
import { ICatalog } from '@/_declarations/ICatalog'

export class MMedicalPolicyModel extends ItemBase implements IMedicalPolicy {
  _permit = [
    'active',
    'company',
    'dateEnd',
    'dateStart',
    'id',
    'indefinite',
    'insuranceCompany',
    'nsiOmsType',
    'number',
    'omsSeries',
    'policyType',
    'program',
    'clientId',
  ]

  active: boolean = true

  company: ICatalog = null

  dateEnd: string = null

  dateStart: string = null

  id: number = null

  indefinite: boolean = false

  insuranceCompany: ICatalog = null

  nsiOmsType: ICatalog = null

  number: string = null

  omsSeries: string = null

  policyType: 'oms' | 'dms' = 'dms'

  program: string = null

  clientId: number = null
}
