export const INPUT_TYPES = Object.freeze({
  INSERT_TEXT: 'insertText',
  BACKSPACE_CONTENT: 'deleteContentBackward',
  DELETE_CONTENT_FORWARD: 'deleteContentForward',
  PASTE: 'insertFromPaste',
})

export const ALLOWED_INPUT_TYPES = Object.freeze(Object.values(INPUT_TYPES))
export const isInsertTextType = (inputType) => inputType === INPUT_TYPES.INSERT_TEXT
export const isAllowedInputType = (inputType) => ALLOWED_INPUT_TYPES.includes(inputType)

export const getAddTo = (char1, char2) => {
  const DEFAULT_CHAR_VALUE = 1
  const MINUS_CHAR_VALUE = 1
  const ROUND_BRACKET_CHAR_VALUE = 1
  const SPACE_CHAR_VALUE = 1

  switch (char1) {
    case '-': return DEFAULT_CHAR_VALUE + MINUS_CHAR_VALUE
    case ')': return DEFAULT_CHAR_VALUE + ROUND_BRACKET_CHAR_VALUE + SPACE_CHAR_VALUE
    case ' ': return DEFAULT_CHAR_VALUE + SPACE_CHAR_VALUE + ROUND_BRACKET_CHAR_VALUE
    default: return DEFAULT_CHAR_VALUE
  }
}

export const PREFIX = '+'
export const PREFIX_LEN = PREFIX.length

export const getRawPhone = (phone) => Services.phoneMask.removeMask(phone)

export const getDefaultCaretPos = () => ({ start: 0, end: 0 })
export const getCaretPos = (start = 0, end = 0) => ({ start, end })
