import { MMedicalPolicyModel } from '@/_baseModels/MMedicalPolicyModel'
import { IMedicalPolicy } from '@/_declarations/IMedicalPolicy'

export class MedicalPolicyLogic extends MMedicalPolicyModel {
  get isDms () {
    return this.policyType === 'dms'
  }

  constructor (data?: IMedicalPolicy | { clientId: number }) {
    super()
    this._fillProps(data)
  }

  setIndefinite () {
    this.setValue('dateStart', null)
    this.setValue('dateEnd', null)
    this.setValue('indefinite', true)
  }
}
