/**
 * @returns {{
 *   clinicId: number,
 *   status: string,
 *   period: object,
 *   companyId: number,
 *   responsibleId: number,
 * }}
 */
export const getDefaultCheckupsFilters = () => {
  return {
    clinic: gon.application.current_clinic,
    status: null,
    period: [
      Utils.getBaseFormattedDate(moment().subtract(89, 'days').startOf('day').toDate()),
      Utils.getBaseFormattedDate(moment().endOf('day').toDate()),
    ],
    company: null,
    responsible: null,
    companyCheckupId: null,
  }
}

export const getDefaultPersonalCheckupsFilters = (overrides = {}) => {
  return {
    ...getDefaultCheckupsFilters(),
    kind: null,
    withOrder: null,

    ...overrides,
  }
}
