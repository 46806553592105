import { MListService } from '@/_api/_requests/MListService'
import { MDocumentsOwnerPresenter } from '@/_api/MDocumentsOwner/MDocumentsOwnerPresenter'
import { MDocumentPresenter } from '@/_api/MDocumentPresenter/MDocumentPresenter'
import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'
import { DocumentOwnerItemCell } from '@/vue_apps/Documents/DocumentsOwners/DocumentOwnerItemCell'
import { IOldDocumentAttributes } from '@/_declarations/documents/IOldDocumentAttributes'
import { OLD_DOCUMENT_KIND } from '@/_declarations/documents/OldDocumentKind'

export class CheckupDocumentsList extends MListService {
  readonly documentsPresenter = new MDocumentPresenter()

  constructor () {
    super(
      { ownerId: null, ownerType: null },
      new MDocumentsOwnerPresenter()
    )
  }

  setFilters ({ ownerId, ownerType }) {
    this.setFilterValue('ownerId', ownerId)
    this.setFilterValue('ownerType', ownerType)
  }

  async fetchAll () {
    await super.__withLoading((async () => {
      await super.fetchAll(false)
      await this.updateOldDocumentsAttributes()
    })())
  }

  private async updateOldDocumentsAttributes () {
    const documentIds = this.data.reduce((acc, item) =>
      item.documentType === DOCUMENT_TYPES.OLD_DOCUMENT
        ? [...acc, item.documentId]
        : acc,
    [])

    if (!documentIds.length) { return }

    const oldDocumentsAttributes: IOldDocumentAttributes[] = await this.documentsPresenter
      .fetchOldDocumentAttributes({
        documentIds,
      })

    this.data.forEach((item: DocumentOwnerItemCell) => {
      if (item.documentType !== DOCUMENT_TYPES.OLD_DOCUMENT) { return }

      const oldDocumentAttributes = oldDocumentsAttributes
        .find(({ id }) => id === item.documentId)

      const title = oldDocumentAttributes.kind === OLD_DOCUMENT_KIND.FIXED
        ? oldDocumentAttributes.title
        : `${oldDocumentAttributes.title} № ${oldDocumentAttributes.number}`

      item.setTitle(title)
    })
  }
}
