<template>
  <m-table-row
    :use-ignore-click="useIgnoreClick"
    :is-active="item && item.id === activeItemId"
    :data-attributes="item.dataAttributes"
    @rightClick="$emit('onRightClick')"
  >
    <!-- Чекбокс -->
    <m-table-row-cell
      v-if="useCheckboxes"
      name="tableCheckbox"
    >
      <m-checkbox
        v-model="itemTyped.__selected"
        :disabled="!itemTyped.__selectable"
      />
    </m-table-row-cell>

    <!-- Колонки -->

    <template v-for="col in columns">
      <m-table-row-cell
        v-if="tableHeaders[col] && item[col] && item[col].drawable"
        :key="`rowHeader:${col}:${item.id}`"
        :name="col"
        :width="tableHeaders[col].width"
        :use-cut="item[col] && item[col].useCut"
        @click="onRowCellClick(col, item, $event)"
      >
        <slot
          :name="`tr-${col}`"
          :[col]="item[col]"
        >
          <component
            :is="extractType(col)"
            v-bind="item[col]"
            @update:value="$updateSyncGC(col, $event)"
          />
        </slot>
      </m-table-row-cell>
    </template>

    <!-- Дополнительные опции -->

    <!-- Редактирование -->
    <m-table-row-cell
      v-if="useEdit"
      name="optionEdit"
    >
      <m-icon
        v-tooltip="t('edit')"
        color="warning"
        icon="edit"
        class="pointer"
        @click.stop="$emit('onEditClick', $event)"
      />
    </m-table-row-cell>

    <!-- Удаление -->
    <m-table-row-cell
      v-if="useDelete"
      name="optionDelete"
    >
      <m-popover
        :disabled="!item.__deletable"
        @yes="$emit('onDeleteClick', $event)"
      >
        <template #reference>
          <m-icon
            v-tooltip="item.__deletable ? t('delete') : item.__notDeletableReason || t('unDeletableText')"
            class="pointer"
            :color="item.__deletable ? 'danger' : 'grey'"
            icon="delete"
            @click.stop="() => {}"
          />
        </template>
      </m-popover>
    </m-table-row-cell>
  </m-table-row>
</template>

<script>

import MTableRow from '@/vue_present/_base/Tables/MTable/MTableRow.vue'
import MTableRowCell from '@/vue_present/_base/Tables/MTable/MTableRowCell.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { MTableRowSharedProps } from '@/vue_present/_base/Tables/MTableGenerator/props/MTableRowSharedProps'
import MPopover from '@/vue_present/_base/MPopover/MPopover.vue'
import dateTime from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeDateTime.vue'
import icon from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeIcon.vue'
import phone from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypePhone.vue'
import struct from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeStruct.vue'
import url from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeUrl.vue'
import userWithAvatar from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeUserWithAvatar.vue'
import number from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeNumber.vue'
import download from '@/vue_present/_base/Tables/MTableGenerator/TableRowComponents/TypeDownload.vue'

export default {
  name: 'MTableGeneratorTableRow',
  components: {
    userWithAvatar,
    url,
    struct,
    phone,
    icon,
    dateTime,
    number,
    download,

    MPopover,
    MIcon,
    MCheckbox,
    MTableRowCell,
    MTableRow,
  },

  props: {
    ...MTableRowSharedProps,

    /** @type {TTableSchemaHeaders} items */
    tableHeaders: {
      type: Object,
      required: true,
    },

    /** @type {TTableSchemaItem} item */
    item: {
      type: Object,
      required: true,
    },

    activeItemId: { type: [String, Number], default: null },
  },

  emits: [
    'onRowClick',
    'onRightClick',
    'onEditClick',
    'onDeleteClick',
    'onCellClick',
  ],

  computed: {
    /** @returns {string[]} */
    columns () {
      return Object.keys(this.tableHeaders)
    },

    /** @returns {TTableSchemaItem} */
    itemTyped () {
      return this.item
    },

    tableGetters () {
      return this.columns.reduce((acc, col) => {
        acc[col] = this.item[col]

        return acc
      }, {})
    },
  },

  methods: {
    extractType (col) {
      return this.item[col]?.type || null
    },

    onRowCellClick (cell, item, event) {
      if (item[cell].usePreventDefault) {
        return this.$emit('onCellClick', { cell, item, event })
      }

      this.$emit('onRowClick')
    },
  },
}
</script>
