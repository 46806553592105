import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'

export const getDefaultVaccinationNumber = () => ({
  id: 1,
  title: 1,
})

export const VACCINATION_NUMBER_ITEMS = Object.freeze(
  Array.from(
    { length: 10 },
    (_, i) => ({
      id: i + 1,
      title: i + 1,
    })
  )
)

export const getVaccinationMsiSchema = ({ addCreateButton = false } = {}) => new MSiSchema()
  .addTableSchema(
    new MTableSchema()
      .addHeaders({
        mibpDiseasesGroupTitle: new MTableHeader().addFixedWidth(),
        year: new MTableHeader().addFixedWidth('60px'),
      })
      .addDeleteIcon(Services.security.canManageCheckupsCompany)
  )
  .addCreateButton(addCreateButton)

export const WORK_PROFILE_NSI_CODE = 211
