import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { feedbackAdapter } from '@/_api/MFeedback/feedbackAdapter'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MFeedbackPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.api_internal_client_feedbacks_feedback_path,
        all () {},
        list: Routes.list_api_internal_client_feedbacks_feedbacks_path,
      },
      entity: 'feedback',
      location: 'MFeedbackPresenter',
    })
  }

  async list (data, config) {
    return super.list(data, {
      ...config,
      toServerAdapter: feedbackAdapter.toServer,
    })
      .then((response) => Promise.resolve(feedbackAdapter.toClient(response)))
  }

  submit (data, config = {}) {
    const options = {
      url: Routes.client_feedbacks_feedback_path(data.id),
      data: camelToSnake(feedbackAdapter.submitFeedback(data)),
    }

    const notification = new MRequestNotification(
      'MFeedbackPresenter:submit',
      'submitMessage',
      'feedback'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }
}
