<template>
  <div class="document-square-field-line">
    <document-square-field-symbol
      v-for="(symbol, key) in line"
      :key="`${symbol}-${key}`"
      :value="symbol"
      :is-last="key === line.length - 1"
    />
  </div>
</template>

<script>
import DocumentSquareFieldSymbol
  from '@/vue_present/DocumentBase/DocumentSquareField/DocumentSquareFieldSymbol/DocumentSquareFieldSymbol.vue'
import {
  DEFAULT_FILL_SYMBOL,
  DEFAULT_LINE_SIZE,
  EMPTY_FILL_SYMBOL,
} from '@/vue_present/DocumentBase/DocumentSquareField/const'

export default {
  name: 'DocumentSquareFieldLine',
  components: { DocumentSquareFieldSymbol },

  props: {
    value: { type: String, required: true },

    lineSize: { type: Number, default: DEFAULT_LINE_SIZE },

    fillBlanksAtEnd: Boolean,

    fillSymbol: { type: String, default: DEFAULT_FILL_SYMBOL },
  },

  computed: {
    line () {
      if (this.value.length > this.lineSize) {
        return this.value.slice(0, this.lineSize)
      }

      return this.fillBlanks(this.fillBlanksAtEnd
        ? this.fillSymbol
        : EMPTY_FILL_SYMBOL)
    },
  },

  methods: {
    fillBlanks (symbol) {
      const needToAdd = this.lineSize - this.value.length

      return needToAdd > 0
        ? this.value + symbol.repeat(needToAdd)
        : this.value
    },
  },
}
</script>
