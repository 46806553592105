<template>
  <panel-heading
    :title="t('egisz.medical_cases.form.information_title')"
    class="egisz-case__card egisz-case__card_information"
    icon="fad fa-fw fa-clipboard-list-check"
  >
    <div class="row">
      <div class="col-xs-6">
        <div class="flex-form flex-form__35-65 egisz-case__flex-form">
          <div class="form-label egisz-case__form-label reset-paddings">
            {{ t('patient') }}
          </div>
          <div class="form-value egisz-case__form-value reset-paddings">
            <a
              :href="$routes.client_path(client.id)"
              target="_blank"
            >
              {{ client.fullname }}
            </a>
            {{ client.age ? `, ${client.age}` : '' }}
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('legal_representative') }}
          </div>
          <div class="form-value egisz-case__form-value">
            <div class="egisz-legal-representative">
              <simple-select
                :items="extendedLegalRepresentatives"
                :value="legalRepresentativeId"
                clearable
                label-attribute="full_name_with_relation"
                value-attribute="legal_representative_id"
                :validation-messages="validationMessages.legal_representative"
                @change="$updateSync('legalRepresentativeId', $event)"
              />

              <popover
                v-if="legalRepresentativeId"
                placement="top-end"
                :cancel-text="t('cancel')"
                yes-style="primary"
              >
                <template #message>
                  <span class="font-size-13">
                    {{ t('egisz.change_relation_type') }}
                  </span>
                  <simple-select
                    :value="relationTypeId"
                    :fixed-height="false"
                    :items="getRelativeTypes"
                    clearable
                    filterable
                    @change="$emit('setRelationTypeId', $event)"
                  />
                </template>

                <template #buttons>
                  <span />
                </template>

                <i
                  slot="reference"
                  v-tooltip="relationName || relationAddTooltip"
                  class="fad fa-chart-network egisz-legal-representative__info pointer"
                  :class="{
                    red: !relationName,
                    success: relationName
                  }"
                />
              </popover>
            </div>
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.medical_cases.form.netrika_payment_type_id') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <simple-select
              :items="getPaymentTypes"
              :value="netrikaPaymentTypeId"
              :validation-messages="validationMessages.netrika_payment_type_id || []"
              :set-first="DEFAULT_FIRST.PAYMENT_TYPE"
              @change="$updateSync('netrikaPaymentTypeId', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.medical_cases.form.opened_at') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <validation-wrapper
              :errors="validationMessages.opened_at || []"
              fixed-height
            >
              <date-picker
                :value="openedAt"
                :date-format="getLocalizationDatepickerFormat"
                clearable
                @input="$updateSync('openedAt', $event)"
              />
            </validation-wrapper>
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.medical_cases.form.closed_at') }}
          </div>
          <div class="form-value egisz-case__form-value">
            <validation-wrapper
              :errors="validationMessages.closed_at || []"
              fixed-height
            >
              <date-picker
                :value="closedAt"
                :date-format="getLocalizationDatepickerFormat"
                clearable
                @input="$updateSync('closedAt', $event)"
              />
            </validation-wrapper>
          </div>
        </div>
      </div>

      <div class="col-xs-6">
        <div class="flex-form flex-form__35-65 egisz-case__flex-form">
          <div class="form-label egisz-case__form-label reset-paddings">
            {{ t('egisz.medical_cases.form.med_case') }}
          </div>
          <div class="form-value egisz-case__form-value reset-paddings">
            {{ finishedTitle() }}
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('treating_doctor') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <simple-select
              :value="doctorId"
              :items="getDoctorsInCurrentClinic"
              :validation-messages="validationMessages.doctor || []"
              label-attribute="full_name"
              filterable
              @change="$updateSync('doctorId', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.medical_cases.form.netrika_case_visit_type') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <simple-select
              :items="getVisitTypes"
              :value="netrikaVisitTypeId"
              :validation-messages="validationMessages.netrika_case_visit_type || []"
              @change="$updateSync('netrikaVisitTypeId', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.medical_cases.form.netrika_case_type_id') }}
            <required-mark />
          </div>
          <div class="form-value egisz-case__form-value">
            <simple-select
              :items="getCaseTypes"
              :value="netrikaCaseTypeId"
              :validation-messages="validationMessages.netrika_case_type_id || []"
              :set-first="DEFAULT_FIRST.CASE_TYPE"
              @change="$updateSync('netrikaCaseTypeId', $event)"
            />
          </div>

          <div class="form-label egisz-case__form-label">
            {{ t('egisz.medical_cases.form.netrika_confidentiality') }}
          </div>
          <div class="form-value egisz-case__form-value">
            <simple-select
              :items="getConfidentialityLevels"
              :value="netrikaConfidentialityId"
              set-first
              @change="$updateSync('netrikaConfidentialityId', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </panel-heading>
</template>

<script>
import DatePicker from '@/vue_components/common/select/date_picker'
import PanelHeading from '@/vue_components/common/panel_heading'
import { mapGetters } from 'vuex'
import SimpleSelect from '@/vue_components/common/select/base_select/SimpleSelect'
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import RequiredMark from '@/vue_components/common/RequiredMark/RequiredMark'
import { DEFAULT_FIRST } from '@/vue_components/egisz/egisz_module/const/set_first_const'
import Popover from '@/vue_components/common/popover/popover'

export default {
  name: 'CardInformation',
  components: { Popover, RequiredMark, ValidationWrapper, SimpleSelect, PanelHeading, DatePicker },
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },

    legalRepresentativeId: {
      type: Number,
      default: null,
    },

    extendedLegalRepresentatives: {
      type: Array,
      default: () => [],
    },

    netrikaPaymentTypeId: {
      type: String,
      default: null,
    },

    openedAt: {
      type: [String, Date],
      default: null,
    },

    closedAt: {
      type: [String, Date],
      default: null,
    },

    doctorId: {
      type: Number,
      default: null,
    },

    netrikaVisitTypeId: {
      type: String,
      default: null,
    },

    netrikaCaseTypeId: {
      type: String,
      default: null,
    },

    netrikaConfidentialityId: {
      type: String,
      default: null,
    },

    validationMessages: {
      type: Object,
      default: null,
    },

    readOnly: Boolean,
    closed: Boolean,
  },

  data () {
    return {
      DEFAULT_FIRST,
    }
  },

  computed: {
    ...mapGetters({
      getLocalizationDatepickerFormat: 'GET_LOCALIZATION_DATEPICKER_FORMAT',
    }),

    ...mapGetters('globalCatalogs/doctorStore', {
      getDoctorsInCurrentClinic: 'getDoctorsInCurrentClinic',
    }),

    ...mapGetters('medCases', {
      getPaymentTypes: 'GET_PAYMENT_TYPES',
      getVisitTypes: 'GET_VISIT_TYPES',
      getConfidentialityLevels: 'GET_CONFIDENTIALITY_LEVELS',
      getCaseTypes: 'GET_CASE_TYPES',
      getRelativeTypes: 'GET_RELATIVE_TYPES',
    }),

    relation () {
      return this.extendedLegalRepresentatives
        .find((item) => item.legal_representative_id === this.legalRepresentativeId)
    },

    relationName () {
      return this.relation
        ? this.relation.relation_type_title
        : ''
    },

    relationTypeId () {
      return this.relation && this.relation.relation_type_id
    },

    relationAddTooltip () {
      return t('egisz.relation_add_tooltip')
    },
  },

  methods: {
    finishedTitle () {
      return this.closed
        ? t('egisz.common_statuses.finished')
        : t('egisz.common_statuses.false')
    },
  },
}
</script>
