<template>
  <div class="clients-feedback__aggregator-feedback">
    <div class="aggregator-feedback__switch-wrapper flex align-center">
      <m-switch
        :active-text="t('whatsapp.aggregatorFeedback.sendFeedback')"
        :value="aggregatorFeedbackSwitch"
        @change="setAggregatorFeedbackSwitch"
      />

      <m-icon
        v-tooltip="t('whatsapp.aggregatorFeedback.clarification')"
        icon="questionCircle"
        color="grey"
        use-solid
        class="aggregator-feedback__question-icon"
      />
    </div>

    <div
      v-if="aggregatorFeedbackSwitch"
      class="aggregator-feedback-settings"
    >
      <div class="aggregator-feedback-settings__feedback-params-wrapper mb-indent">
        <between-text
          :text-before="t('whatsapp.aggregatorFeedback.requiredScore')"
          use-text-before-as-title
          glue-elem-to-right
          remove-default-elem-styling
        >
          <m-select
            class="aggregator-feedback-settings__required-score"
            :value="aggregatorFeedbackRequiredScore"
            :items="CLIENTS_FEEDBACK_SCORES_VARIABLES"
            :clearable="false"
            use-custom-result="simple"
            @change="setAggregatorFeedbackRequiredScore"
          />
        </between-text>

        <between-text
          :text-before="t('whatsapp.aggregatorFeedback.requiredTextLength')"
          :explanation-icon-tooltip="t('whatsapp.aggregatorFeedback.requiredTextLengthClarification')"
          use-text-before-as-title
          glue-elem-to-right
          remove-default-elem-styling
        >
          <m-input
            class="aggregator-feedback-settings__required-text-length"
            type="number"
            :precision="0"
            :min="MIN_AGGREGATOR_FEEDBACK_REQUIRED_TEXT_LENGTH"
            :value="aggregatorFeedbackRequiredTextLength"
            @input="setAggregatorFeedbackRequiredTextLength"
          />
        </between-text>
      </div>

      <between-text
        class="mb-indent"
        :title="t('whatsapp.dispatchFrequency')"
        :text-before="t('whatsapp.sendNoMore')"
        :text-after="t('whatsapp.days')"
      >
        <m-input
          :value="aggregatorFeedbackSendingFrequency"
          :clearable="false"
          type="number"
          :precision="0"
          :min="MIN_AGGREGATOR_FEEDBACK_SENDING_FREQUENCY_IN_DAYS"
          @input="setAggregatorFeedbackSendingFrequency"
        />
      </between-text>

      <variables-editor
        :editor-value="aggregatorFeedbackTemplate"
        :editor-title="t('whatsapp.aggregatorFeedback.messageTemplate')"
        :editor-title-clarification="t('whatsapp.notificationTemplateClarification')"
        :preview-request="fetchTemplatePreview"
        :variables-list="CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE"
        required
        validator-name="aggregatorFeedbackTemplate"
        template-type="clients_feedback"
        @registerValidator="$emit('registerTemplateValidator', $event)"
        @changeEditorValue="setAggregatorFeedbackTemplate"
      />

      <variables-editor-whatsapp
        v-if="isWabaActive"
        :editor-value="wabaTemplateText"
        :variables-list="CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE"
        @changeWabaEditorValue="setWabaTemplateText"
      >
        <template #templateSelector>
          <m-select
            :value="wabaTemplateId"
            :items="wazzupTemplatesList"
            option-label="title"
            value-key="templateGuid"
            :placeholder="t('whatsapp.chooseValue')"
            @change="insertTemplate"
          />
        </template>
      </variables-editor-whatsapp>
    </div>
  </div>
</template>

<script>
import MSwitch from '@/vue_present/_base/inputs/MSwitch/MSwitch.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import VariablesEditor from '@/vue_present/VariablesEditor/VariablesEditor.vue'
import VariablesEditorWhatsapp from '@/vue_present/VariablesEditorWhatsapp/VariablesEditorWhatsapp.vue'
import BetweenText from '@/vue_apps/app_configuration/messages/pages/components/BetweenText.vue'

import {
  CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE,
} from '@/vue_components/app_configuration/messages/_messages_configurations_base/consts/messageVariablesList'
import {
  CLIENTS_FEEDBACK_SCORES_VARIABLES,
  MIN_AGGREGATOR_FEEDBACK_REQUIRED_TEXT_LENGTH,
  MIN_AGGREGATOR_FEEDBACK_SENDING_FREQUENCY_IN_DAYS,
} from '@/vue_apps/app_configuration/messages/scripts/consts'
import { mapGetters, mapMutations } from 'vuex'
import { fetchTemplatePreview } from '@/vue_apps/app_configuration/messages/scripts/fetchTemplatePreview'
import {
  extractWabaTemplateText,
  findAndExtractWabaTemplateTextWithVariablesLabels,
} from '@/vue_apps/app_configuration/messages/scripts/wabaHelpers'

export default {
  name: 'AggregatorFeedbackSettings',
  components: {
    MSwitch,
    MIcon,
    MSelect,
    MInput,
    BetweenText,
    VariablesEditor,
    VariablesEditorWhatsapp,
  },

  data () {
    return {
      wabaTemplateText: '',
      CLIENTS_FEEDBACK_VARIABLES_LIST_SECOND_MESSAGE,
      CLIENTS_FEEDBACK_SCORES_VARIABLES,
      MIN_AGGREGATOR_FEEDBACK_SENDING_FREQUENCY_IN_DAYS,
      MIN_AGGREGATOR_FEEDBACK_REQUIRED_TEXT_LENGTH,
      fetchTemplatePreview,
    }
  },

  computed: {
    ...mapGetters('messagesStore', {
      aggregatorFeedbackSwitch: 'vxGetAggregatorFeedbackSwitch',
      aggregatorFeedbackRequiredScore: 'vxGetAggregatorFeedbackRequiredScore',
      aggregatorFeedbackRequiredTextLength: 'vxGetAggregatorFeedbackRequiredTextLength',
      aggregatorFeedbackSendingFrequency: 'vxGetAggregatorFeedbackSendingFrequency',
      aggregatorFeedbackTemplate: 'vxGetAggregatorFeedbackTemplate',
    }),

    ...mapGetters('messagesStore/whatsappConfigurationsStore', {
      aggregatorFeedbackWabaTemplateId: 'vxGetAggregatorFeedbackWabaTemplateId',
      aggregatorFeedbackWabaTemplateVariables: 'vxGetAggregatorFeedbackWabaTemplateVariables',
      wazzupTemplatesList: 'vxGetWazzupTemplatesList',
      isWabaActive: 'vxIsWabaActive',
    }),

    wabaTemplateId () {
      return { templateGuid: this.aggregatorFeedbackWabaTemplateId }
    },
  },

  created () {
    this.$emit('registerInitialAggregatorWabaTemplateTextSetter', this.setInitialWabaTemplateText)
  },

  methods: {
    ...mapMutations('messagesStore', {
      setAggregatorFeedbackSwitch: 'vxSetAggregatorFeedbackSwitch',
      setAggregatorFeedbackRequiredScore: 'vxSetAggregatorFeedbackRequiredScore',
      setAggregatorFeedbackRequiredTextLength: 'vxSetAggregatorFeedbackRequiredTextLength',
      setAggregatorFeedbackSendingFrequency: 'vxSetAggregatorFeedbackSendingFrequency',
      setAggregatorFeedbackTemplate: 'vxSetAggregatorFeedbackTemplate',
    }),

    ...mapMutations('messagesStore/whatsappConfigurationsStore', {
      setAggregatorFeedbackWabaTemplateId: 'vxSetAggregatorFeedbackWabaTemplateId',
      setAggregatorFeedbackWabaTemplateVariables: 'vxSetAggregatorFeedbackWabaTemplateVariables',
    }),

    setInitialWabaTemplateText () {
      const initialWabaTemplateText = this.aggregatorFeedbackWabaTemplateId
        ? findAndExtractWabaTemplateTextWithVariablesLabels({
          wabaTemplates: this.wazzupTemplatesList,
          templateGuid: this.aggregatorFeedbackWabaTemplateId,
          templateVariablesValues: this.aggregatorFeedbackWabaTemplateVariables,
        })
        : ''

      this.setWabaTemplateText(initialWabaTemplateText)
    },

    setWabaTemplateText (value) {
      this.wabaTemplateText = value
      this.$emit('wabaTemplateTextChanged', this.wabaTemplateText)
    },

    insertTemplate (template) {
      const newWabaTemplateText = template
        ? extractWabaTemplateText(template)
        : ''

      this.setWabaTemplateText(newWabaTemplateText)
      this.setAggregatorFeedbackWabaTemplateId(template?.templateGuid)
    },
  },
}
</script>
