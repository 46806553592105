
export const checkupFactorsAdapter = {
  toClient (response) {
    response.data = (response.data || []).map((item) => ({
      ...item,
      codeNotice: `${t('code')}: ${item.code}`,
    }))

    return response
  },
}
