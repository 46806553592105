<template>
  <m-button
    v-bind="$props"
    :template="modifiedTemplate"
    :disabled="disabled"
    @click="$emit('click', $event)"
  />
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { props } from '@/vue_present/_base/buttons/MButton/props'

export default {
  name: 'MButtonSubmit',
  components: { MButton },
  props: {
    ...props,
    mode: { type: String, default: MODES.CREATE },
    disabled: { type: [String, Boolean, Object], default: false },
    useModeByRoute: { type: Boolean, default: true },
  },

  computed: {
    modifiedTemplate () {
      if (this.useModeByRoute && this.$route?.path.endsWith('new')) {
        return 'save'
      }

      if (this.useModeByRoute && this.$route?.path.endsWith('edit')) {
        return 'save_orange'
      }

      const mode = this.$route?.meta?.mode ?? this.mode

      return mode === MODES.CREATE || mode === MODES.SUCCESS
        ? 'save'
        : 'save_orange'
    },
  },
}
</script>
