<template>
  <m-input
    v-tooltip="tooltip"
    :m-fixed-height="false"
    type="number"
    :value="value"
    :min="min"
    :max="max"
    :class="css"
    :precision="precision"
    :size="size"
    controls
    @input="$emit('update:value', $event)"
  />
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'TypeNumber',
  components: { MInput },

  props: {
    tooltip: PropsTypes.String(),
    value: PropsTypes.Custom([Number, String, Boolean]),
    css: PropsTypes.String(),
    min: PropsTypes.Number(),
    max: PropsTypes.Number(),
    precision: PropsTypes.Number(),
    size: PropsTypes.String(),
  },

  emits: [
    'update:value',
  ],
}
</script>
