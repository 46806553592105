<template>
  <el-alert
    class="m-alert"
    :class="{
      italic
    }"
    :custom-type="type"
    v-bind="$props"
    v-on="$listeners"
  >
    <slot>
      <span class="font-size-13">{{ descriptionText }}</span>
    </slot>
  </el-alert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MAlert',
  props: {
    description: { type: String, default: '' },
    title: { type: String, default: '' },
    type: { type: String as PropType<'success' | 'warning' | 'info' | 'error' | 'grey-dark'>, default: 'info' },
    closable: Boolean,
    center: Boolean,
    showIcon: Boolean,
    closeText: { type: String, default: undefined },
    effect: { type: String as PropType<'light' | 'dark'>, default: 'light' },

    text: { type: String, default: '' },
    italic: Boolean,
  },

  computed: {
    descriptionText () {
      return this.description || this.text || ''
    },
  },
})
</script>
