<template>
  <el-radio-group
    :disabled="readOnly"
    :value="value"
    class="yes-no"
    size="mini"
    @input="$emit('input', $event)"
  >
    <el-radio-button
      :label="true"
      class="yes-no-radio yes-no-radio_yes"
    >
      {{ t('yes') }}
    </el-radio-button>
    <el-radio-button
      :label="false"
      class="yes-no-radio yes-no-radio_no"
    >
      {{ t('no') }}
    </el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  name: 'YesNo',
  props: {
    value: Boolean,
    readOnly: Boolean,
  },
}
</script>
