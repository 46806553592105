<template>
  <si-footer>
    <pagination
      layout="prev, pager, next"
      :page-count="totalPages"
      :current-page="currentPage"
      @update:currentPage="vxSetPage"
    />
  </si-footer>
</template>

<script>
import Pagination from '@/vue_components/common/pagination'
import SiFooter from '@/vue_components/sort_items/si_footer'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'EgiszEmdTabFooter',
  components: {
    SiFooter,
    Pagination,
  },

  computed: {
    ...mapGetters('egiszEmd/filtersBase', {
      totalPages: 'vxGetTotalPages',
      currentPage: 'vxGetCurrentPage',
    }),
  },

  methods: {
    ...mapMutations('egiszEmd/filtersBase', {
      vxSetPage: 'vxSetPage',
    }),
  },
}
</script>
