<template>
  <personal-checkup-nested-items-modal-base
    class-css="personal-checkup-vaccination-modal"
    :visible="visible"
    :dialog-title="t$('title')"
    modal-title-icon="syringe"
    nested-items-props-name="vaccinations"
    :personal-checkup="personalCheckup"
    :msi-schema="vaccinationMsiSchema"
    :is-new-mode="isNewMode"
    @close="$emit('close')"
  >
    <template #body>
      <m-prompt-notice :text="t$('notice')" />

      <div class="flex gap-indent-small">
        <m-icon
          v-tooltip="questionMarkTooltip"
          class="personal-checkup-vaccination-modal__large-icon"
          icon="questionCircle"
          color="gray"
        />

        <!-- Наименование -->
        <m-select
          class="personal-checkup-vaccination-modal__mibp-diseases-group"
          :value="mibpDiseasesGroup"
          :items="mibpDiseasesGroupItems"
          :label="t('checkups.vaccinationsAndDisease.disease')"
          required
          validator-name="mibpDiseasesGroup"
          @registerValidator="onRegisterValidator($event)"
          @change="mibpDiseasesGroup = $event"
        />

        <!-- Дата -->
        <m-select
          v-model="date"
          :items="vaccinationDateItems"
          :label="t('yearCapital')"
          :clearable="false"
          required
          validator-name="date"
          @registerValidator="onRegisterValidator($event)"
        />

        <m-select
          v-model="number"
          :items="VACCINATION_NUMBER_ITEMS"
          :label="t('common.number')"
          :clearable="false"
          required
          validator-name="number"
          @registerValidator="onRegisterValidator($event)"
        />

        <!-- Признак ревакцинации -->
        <m-icon
          v-tooltip="t$(revaccinationTooltip)"
          class="personal-checkup-vaccination-modal__large-icon"
          icon="syringe"
          :color="revaccinationIconColor"
        />

        <m-button
          template="add"
          :text="t('add')"
          @click="onAdd"
        />
      </div>
    </template>
  </personal-checkup-nested-items-modal-base>
</template>

<script>
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MPromptNotice from '@/vue_present/_base/MPromptNotice/MPromptNotice.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import {
  getDefaultVaccinationNumber,
  getVaccinationMsiSchema,
  VACCINATION_NUMBER_ITEMS,
} from '@/vue_apps/CheckupsModule/store/vaccination/vaccinationConst'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { PersonalCheckupApi } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupApi'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import {
  getVaccinationAndInfectiousDiseasesDateItems,
  getVaccinationAndInfectiousDiseasesDefaultDate,
} from '@/vue_apps/CheckupsModule/store/personalCheckupVaccinationAndInfectiousDiseases/personalCheckupVaccinationAndInfectiousDiseasesConsts'
import PersonalCheckupNestedItemsModalBase
  from '@/vue_apps/CheckupsModule/components/PersonalCheckup/PersonalCheckupNestedItemsModalBase.vue'
import { personalCheckupSpecialFactorsMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupSpecialFactorsMixin'
import { personalCheckupQuestionMarkMixin } from '@/vue_apps/CheckupsModule/mixins/personalCheckupQuestionMarkMixin'

export default {
  name: 'PersonalCheckupVaccinationModal',

  components: {
    PersonalCheckupNestedItemsModalBase,
    MIcon,
    MPromptNotice,
    MSelect,
    MButton,
  },

  mixins: [
    i18nScopeMixin,
    ValidationParentMixin,
    CheckupsPropsMixin,
    personalCheckupSpecialFactorsMixin,
    personalCheckupQuestionMarkMixin,
  ],

  props: {
    visible: Boolean,
    personalCheckup: PersonalCheckupApi,
  },

  emits: ['close'],

  data () {
    return {
      vaccinationMsiSchema: getVaccinationMsiSchema(),
      VACCINATION_NUMBER_ITEMS,
      vaccinationDateItems: [],
      mibpDiseasesGroupItems: [],

      mibpDiseasesGroup: null,
      number: getDefaultVaccinationNumber(),
      date: getVaccinationAndInfectiousDiseasesDefaultDate(),
    }
  },

  computed: {
    hasSignOfRevaccination () {
      return this.number.id !==
          getDefaultVaccinationNumber().id
    },

    revaccinationIconColor () {
      return this.hasSignOfRevaccination
        ? 'primary'
        : 'gray'
    },

    revaccinationTooltip () {
      return this.hasSignOfRevaccination
        ? 'revaccinationTooltip'
        : 'noRevaccinationTooltip'
    },
  },

  created () {
    this.setI18nScope('checkups.vaccinationsAndDisease.vaccination')

    this.vaccinationDateItems = getVaccinationAndInfectiousDiseasesDateItems(
      this.personalCheckup.client.birthdate || moment()
    )

    this.fetchMibpDiseasesGroups()
  },

  methods: {
    onAdd () {
      if (this.hasErrors()) { return }

      this.personalCheckup.vaccinations.nestedItems.append({
        mibpDiseasesGroupId: this.mibpDiseasesGroup.id,
        mibpDiseasesGroupTitle: this.mibpDiseasesGroup.title,
        date: this.date.id,
        number: this.number.id,
      })
    },

    async fetchMibpDiseasesGroups () {
      this.mibpDiseasesGroupItems = await this.personalCheckup
        .vaccinations
        .fetchMibpDiseasesGroups(this.availableSpecialFactors)
    },
  },
}
</script>
