<template>
  <m-panel
    :title="panelCaption.title"
    :icon="panelCaption.icon"
    :use-type-by-route="false"
    body-css-classes="p-0 pb-indent"
  >
    <m-si-generator
      :items="list.data"
      :current-page="list.currentPage"
      :page-count="list.totalPages"
      :si-generator-schema="mSiSchema"
      :search-query="list.searchQuery"
      :active-item-id="id"
      @onItemClick="onItemClick"
      @update:currentPage="list.fetchPage($event)"
      @update:searchQuery="list.search($event)"
    />
  </m-panel>
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MListService } from '@/_api/_requests/MListService'
import { MCheckupEntryTypePresenter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/MCheckupEntryTypePresenter'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { getDefaultRoute } from '@/vue_apps/catalogs_root/CheckupCatalogs/router/checkupCatalogsRoutes'

const mSiSchema = Object.freeze(
  new MSiSchema()
    .addPagination()
    .addTableSchema(
      new MTableSchema({
        headers: {
          title: new MTableHeader(),
        },
      })
    )
)

export default {
  name: 'CheckupCatalogsListPage',
  components: { MPanel, MSiGenerator },
  props: {
    id: { type: Number, default: null },
    kind: { type: String, required: true },
  },

  data () {
    return {
      mSiSchema,
      list: new MListService(
        { kind: this.kind },
        new MCheckupEntryTypePresenter(),
        { limit: 50, cacheKey: 'checkup-catalogs-list-page', useCacheOffset: true }
      ),
    }
  },

  computed: {
    panelCaption () {
      return {
        title: t(this.kind, { scope: 'checkups.checkupCatalogs.caption.title' }),
        icon: this.kind === 'test' ? 'flask' : 'doctor',
      }
    },
  },

  watch: {
    kind (to) {
      this.list.setFilterValueWithFetchAll('kind', to)
    },
  },

  created () {
    this.list.setFilterValueWithFetchAll('kind', this.kind)
  },

  methods: {
    onItemClick ({ id }) {
      this.$router.push(`${getDefaultRoute(`/${this.kind}`)}/${id}/edit`)
    },
  },
}
</script>
