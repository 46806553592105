import { DOCUMENT_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentTypes'

const documentClassToDocumentType = {
  'Checkups::Documents::Personals::ElmkMedicalAssessment': DOCUMENT_TYPES.CHECKUPS_PERSONAL_ELMK_MEDICAL_ASSESSMENT,
  'Checkups::Documents::Personals::SimpleMedicalAssessment': DOCUMENT_TYPES.CHECKUPS_PERSONAL_SIMPLE_MEDICAL_ASSESSMENT,
} as const

export const convertDocumentClassToDocumentType = (documentClass: string): string => {
  return documentClassToDocumentType[documentClass] || null
}
