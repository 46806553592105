import {
  EntryTypesClinicsCabinetsAttributesItem,
} from '@/vue_apps/catalogs_root/CheckupCatalogs/api/EntryTypesClinicsCabinetsAttributesItem'

export class EntryTypesClinicsCabinetsAttributes {
  constructor (clinicCabinetsMap = [], defaultClinicCabinetsMap) {
    this.clinicCabinetsMap = clinicCabinetsMap
    this.defaultClinicCabinetsMap = defaultClinicCabinetsMap
  }

  get () {
    return Object.keys(this.clinicCabinetsMap)
      .map((clinicIdKey) => {
        const item = new EntryTypesClinicsCabinetsAttributesItem(
          this.clinicCabinetsMap,
          this.defaultClinicCabinetsMap,
          clinicIdKey
        )

        return item.get()
      })
      .filter((item) => item.cabinetId)
  }
}
