<template>
  <m-panel
    class="company-checkup-info"
    :title="t('checkups.company.info.title')"
  >
    <!--    Левая часть    -->
    <div class="company-checkup-info__left-side">
      <!--  Наименование  -->
      <m-input
        v-tooltip="companyCheckup.title"
        :label="t('checkups.company.info.name')"
        :value="companyCheckup.title"
        :disabled="isShowMode"
        :placeholder="t('enums.unspecified.it')"
        @input="companyCheckup.setValue('title', $event)"
      />

      <div class="position-relative mb-indent-validation">
        <!--  Организация - работодатель* -->
        <company-search-create
          :value="companyCheckup.company"
          :label="t('checkups.company.info.company')"
          required
          validator-name="company"
          :disabled="isShowMode"
          @change="companyCheckup.setValue('company', $event)"
          @onCreateCompany="companyCheckup.setValue('company', $event)"
          @registerValidator="$registerValidator($event)"
        />

        <m-link
          v-if="companyCheckup.company"
          class="company-checkup-info__link-container"
          :text="t('infoCompanyShort')"
          :href="$routes.company_path(companyCheckup.company.id)"
          open-in-new-tab
        />
      </div>

      <!--    Структурное подразделение*    -->
      <m-input
        :value="companyCheckup.department"
        :label="t('checkups.company.info.department')"
        :disabled="isShowMode"
        :placeholder="t('enums.unspecified.it')"
        @input="companyCheckup.setValue('department', $event)"
      />

      <!--    Даты проведения*    -->
      <m-date-picker
        v-model="plannedPeriod"
        type="daterange"
        required
        validator-name="period"
        value-format="yyyy-MM-dd"
        :label="t('checkups.company.info.period')"
        :disabled="isShowMode"
        @registerValidator="$registerValidator($event)"
      />

      <!--    Статус    -->
      <m-select
        :label="t('checkups.company.info.status')"
        :value="companyCheckup.status"
        :items="vxGetStatuses"
        use-custom-result="simple"
        :disabled="isShowMode"
        @change="companyCheckup.setValue('status', $event)"
      />

      <!--    Клиника    -->
      <m-select
        :label="t('checkups.company.info.clinic')"
        :value="companyCheckup.clinic"
        :items="vxGetDoctorClinics"
        :disabled="isShowMode"
        @change="companyCheckup.setValue('clinic', $event)"
      />
    </div>

    <!--    Правая часть    -->
    <div class="company-checkup-info__right-side">
      <!--      Номер договора с работодателем*     -->
      <m-input
        :value="companyCheckup.agreementNumber"
        :label="t('checkups.company.info.agreementNumber')"
        validator-name="agreement_number"
        required
        :disabled="isShowMode"
        :placeholder="t('enums.unspecified.he')"
        @input="companyCheckup.setValue('agreementNumber', $event)"
        @registerValidator="$registerValidator($event)"
      />

      <!--      Срок действия договора*     -->
      <m-date-picker
        v-model="agreementPeriod"
        type="daterange"
        required
        validator-name="agreement_period"
        :label="t('checkups.company.info.agreementPeriod')"
        value-format="yyyy-MM-dd"
        :disabled="isShowMode"
        @registerValidator="$registerValidator($event)"
      />

      <!--      Дополнительно     -->
      <m-textarea
        :value="companyCheckup.comment"
        :label="t('checkups.company.info.comment')"
        :autosize="{ minRows: 3 }"
        :m-fixed-height="false"
        :disabled="isShowMode"
        @input="companyCheckup.setValue('comment', $event)"
      />
    </div>
  </m-panel>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import CompanySearchCreate
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanySearchCreate.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import { CompanyCheckup } from '@/vue_apps/CheckupsModule/classes/CompanyCheckup/CompanyCheckup'
import { mapGetters } from 'vuex'
import { CheckupsPropsMixin } from '@/vue_apps/CheckupsModule/mixins/CheckupsPropsMixin'
import MLink from '@/vue_present/_base/MLink/MLink.vue'

export default {
  name: 'CompanyCheckupInfo',
  components: {
    MLink,
    MTextarea,
    MSelect,
    MDatePicker,
    CompanySearchCreate,
    MInput,
    MPanel,
  },

  mixins: [CheckupsPropsMixin],

  props: {
    companyCheckup: CompanyCheckup,
  },

  computed: {
    ...mapGetters('globalCatalogs/doctorStore', {
      vxGetDoctorClinics: 'vxGetDoctorClinics',
    }),

    ...mapGetters('checkupsStore', {
      vxGetStatuses: 'vxGetStatuses',
    }),

    plannedPeriod: {
      get () {
        if (!this.companyCheckup.plannedStartDate) { return null }

        return [this.companyCheckup.plannedStartDate, this.companyCheckup.plannedEndDate]
      },
      set (value) {
        if (!value) {
          this.companyCheckup.setValue('plannedStartDate', null)
          this.companyCheckup.setValue('plannedEndDate', null)

          return
        }

        this.companyCheckup.setValue('plannedStartDate', value[0])
        this.companyCheckup.setValue('plannedEndDate', value[1])
      },
    },

    agreementPeriod: {
      get () {
        if (!this.companyCheckup.agreementStartDate) { return null }

        return [this.companyCheckup.agreementStartDate, this.companyCheckup.agreementEndDate]
      },

      set (value) {
        if (!value) {
          this.companyCheckup.setValue('agreementStartDate', null)
          this.companyCheckup.setValue('agreementEndDate', null)

          return
        }

        this.companyCheckup.setValue('agreementStartDate', value[0])
        this.companyCheckup.setValue('agreementEndDate', value[1])
      },
    },
  },
}
</script>
