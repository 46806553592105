import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { checkupCatalogAdapter } from '@/vue_apps/catalogs_root/CheckupCatalogs/api/checkupCatalogAdapter'
import { MRequestError } from '@/_api/_requests/MRequestError'
import {
  defaultCheckupEntryTypeAdapter,
} from '@/vue_apps/catalogs_root/CheckupCatalogs/api/defaultCheckupEntryTypeAdapter'

export class MCheckupEntryTypePresenter extends MPresenterBase {
  constructor () {
    super({
      location: 'MCheckupEntryTypePresenter',
      entity: 'checkupEntryType',
      routes: {
        one: Routes.api_internal_checkups_entry_type_path,
        all () {},
        list: Routes.list_api_internal_checkups_entry_types_path,
      },
    })
  }

  list (listData, config = {}) {
    return super.list(listData, {
      toClientAdapter: checkupCatalogAdapter.toClient,
      ...config,
    })
  }

  defaultSpecialistCabinet () {
    return super.get({
      url: Routes.default_specialist_api_internal_checkups_entry_types_path(),
    }).promise
      .then((data) => defaultCheckupEntryTypeAdapter.toClient(data))
      .catch(MRequestError.withDefault({ finalCabinet: '', finalSpecialist: '' }, null))
  }
}
