import { PaymentsListCellItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentsListCellItem'
import { PaymentsListTotalItem } from '@/vue_apps/FinanceModule/FinanceModuleIndex/classes/PaymentsListTotalItem'
import { orUndefined } from '@/_medods_standart_library/msl'
import { DateHelpers } from '@/helpers/DateHelpers'

export const paymentsAdapter = {
  toServer (data) {
    if (data.searchQuery) {
      return {
        searchString: data.searchQuery,
        clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
        limit: data.limit,
        offset: data.offset,
      }
    }

    return {
      paymentSpecies: orUndefined(data.paymentSpecies),
      userId: orUndefined(data.user?.id),
      kind: orUndefined(data.kind),
      receiptStatus: orUndefined(data.receiptStatus),
      workplaceId: orUndefined(data.workplaceId),
      customerType: orUndefined(data.customerType),
      date: orUndefined(DateHelpers.toBasePeriod(data.date), true),
      clinicIds: gon.application.current_clinic.id && [gon.application.current_clinic.id],
      limit: data.limit,
      offset: data.offset,
    }
  },

  toClient (response) {
    response.data = response.data.map((item) => new PaymentsListCellItem(item))

    response.total = new PaymentsListTotalItem(response.totals)

    return response
  },
}
