import { pick } from 'lodash'
import {
  MPersonalBladeRunnerDocumentPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalBladeRunnerDocumentPresenter/MPersonalBladeRunnerDocumentPresenter'
import { set } from 'lodash/object'

/**
 * @implements IBladeRunner
 */
export class BladeRunner {
  _presenter = new MPersonalBladeRunnerDocumentPresenter()

  _permit = [
    'clinicAddressValue',
    'companyValue',
    'dateValue',
    'workerName',
    'finalCabinet',
    'finalSpecialist',
    'footerData',
    'headerNumber',
    'id',
    'personalCheckupId',
  ]

  clinicAddressValue = ''

  companyValue = ''

  dateValue = ''

  finalCabinet = '-'

  workerName = ''

  finalSpecialist = t('checkups.documents.bladeRunnerDocument.defaultFinalSpecialist')

  footerData = '- при себе иметь: паспорт РФ (или другой удостоверяющий личность документ), СНИЛС, полис ОМС/ДМС, направление от работодателя'

  headerNumber = ''

  id = null

  personalCheckupId = null

  checkupType = ''

  /**
   * @param {Number} id
   * @param {Number} personalCheckupId
   */
  constructor (id = null, personalCheckupId = null) {
    this.id = id
    this.personalCheckupId = personalCheckupId
  }

  _fillAttributes (bladeRunnerRaw) {
    this.headerNumber = bladeRunnerRaw.headerNumber
    this.dateValue = Utils.getFormattedDate(bladeRunnerRaw.dateValue)
    this.clinicAddressValue = bladeRunnerRaw.clinicAddressValue
    this.companyValue = bladeRunnerRaw.companyValue
    this.finalCabinet = bladeRunnerRaw.finalCabinet
    this.finalSpecialist = bladeRunnerRaw.finalSpecialist
    this.footerData = bladeRunnerRaw.footerData
    this.tableData = bladeRunnerRaw.tableData
    this.personalCheckupId = bladeRunnerRaw.personalCheckupId
    this.workerName = bladeRunnerRaw.workerName
    this.checkupType = bladeRunnerRaw.checkupType
    this.personalCheckupId = bladeRunnerRaw.personalCheckupId

    return this
  }

  setFieldValue (field, value) {
    set(this, field, value)
  }

  async load () {
    await this._presenter.fetch(this.id)
      .then((data) => { this._fillAttributes(data) })

    return this
  }

  async save (pickedAttributes = this._permit) {
    const data = pick(this, ['id', ...pickedAttributes])

    return this._presenter.submit(data)
  }

  async create (personalCheckupId) {
    this.personalCheckupId = personalCheckupId

    return this.save([
      'personalCheckupId',
      'finalCabinet',
      'finalSpecialist',
      'footerData',
    ])
  }

  async destroy (id = this.id) {
    return this._presenter.destroy(id)
  }

  async updateFinals ({ finalCabinet, finalSpecialist, footerData }) {
    this.finalCabinet = finalCabinet
    this.finalSpecialist = finalSpecialist
    this.footerData = footerData
  }
}
