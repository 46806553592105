import { isEqual } from 'lodash/lang'

/**
 * Автоматически сохраняет хранимый объект в localStorage при его изменении.
 * Пример:
 * ```
 * class Params extends SerializableParams {
 *   constructor () {
 *     super('some_key')
 *   }
 *
 *   getDefaults () {
 *     return {
 *       foo: 42,
 *       bar: 'baz',
 *     }
 *   }
 * }
 *
 * ```
 * Для параметров, которые лежат в localStorage внутри других объектов см. NestedSerializableParams
 * @see {NestedSerializableParams}
 * @class SerializableParams
 * @abstract
 */
export class SerializableParams {
  constructor (localStorageKey) {
    /**
     * @protected
     */
    this.localStorageKey = localStorageKey
    this.state = {}
    this.defineFields()
  }

  set (params) {
    this.state = params
    this.save()
  }

  get () {
    return { ...this.state }
  }

  save () {
    store.set(this.localStorageKey, this.state)
  }

  restore () {
    const defaults = this.getDefaults()
    const stored = this.getStored()

    this.state = Object.assign(defaults, stored)
  }

  reset (state = this.getEmpty()) {
    this.set(state)
  }

  /**
   * @abstract
   */
  getDefaults () {
    return {}
  }

  getStored () {
    return store.get(this.localStorageKey)
  }

  getEmpty () {
    return this.getDefaults()
  }

  isEmpty () {
    return !isEqual(this.state, this.getEmpty())
  }

  /**
   * Объявляет геттеры и сеттеры для параметров.
   * По умолчанию использует имена параметров из объекта,
   * возвращаемого `getDefaults()`.
   * @protected
   */
  defineFields () {
    Object.keys(this.getDefaults())
      .forEach(this.field.bind(this))
  }

  /**
   * Вызовы этого метода должны производиться в `defineFields()`
   * @protected
   * @param {string} name
   */
  field (name) {
    const paramsInstance = this
    Object.defineProperty(this, name, {
      get () {
        return paramsInstance.state[name]
      },
      set (value) {
        paramsInstance.state[name] = value
        paramsInstance.save()
      },
      enumerable: true,
      configurable: true,
    })
  }
}
